import { type Account } from '../../Commons/cache/Account';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Order } from '../../Commons/cache/Order';
import { MarginInfoParameters } from '../../Commons/UtilsClasses/MarginInfo/MarginInfoParameters';
import { MarginInfoWrapper } from '../../Commons/UtilsClasses/MarginInfo/MarginInfoWrapper';
import { GeneralSettings } from '../GeneralSettings/GeneralSettings';
import { type ProductType } from '../Instruments/ProductType';
import { QuoteValid } from '../Quotes/QuoteValid';
import { OperationType } from './OperationType';
import { OrderType } from './OrderType';
import { OrderUtils } from './OrderUtils';
import { Quantity } from './Quantity';

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class OrderEditBaseUtils {
    public static LIMIT_PRICE_PARAM = 'limitPrice';
    public static STOP_PRICE_PARAM = 'stopPrice';
    public static SLTP_PARAM = 'sltp';
    public static TRAILING_STOP_PARAM = 'trailingStop';
    public static POSITION_SIZING_PARAM = 'positionSizing';
    public static REAL_TRSTOP_PRICE = 'RealTrStopPrice';
    public static PRODUCT_TYPE_AND_MQ = 'ModifyQuantity'; // Target product type + Modify quantity
    public static ATTENTION_MSG = 'attention';
    public static LIMIT_PRICE_PARAM_CHANGE_FROM_LAST_PRICE = 'limitPriceChangeFromLast'; // https://tp.traderevolution.com/entity/108864
    public static SLTP_TRIGGER = 'sltpTrigger'; // for position with sltp & triggers modify #109386
    public static AFTER_TRADE_CASH = 'afterTradeCash';
};

export class OrderEditRequestData {
    public orderTypeId: any;
    public instrument: Instrument | null = null;
    public account: Account | null = null;
    public quote: any = null;
    public side: any = null;
    public tif: any = null;
    public quantity: any = null;
    // TODO. Rename.
    public parameterDict: any = {};
    public placedFrom: any = null;
    public disclosedQuantity: any = null;
    public productType: ProductType | null = null;
    public leverageValue: any = null;
    public comment: any = null;
    public order: any = null;
    public useStopLimitInsteadStop: any = null;
    public limitPriceForStopLimitOrder: any = null;
    public ocoCustomOrdersData: any = null;

    // Тільки в PositionEdit використовує:
    public position: any = null;
    public canEditSLOrTrailingStop: any = null;
    public canEditTP: any = null;
    public isSLchanged: any = null;
    public isTPchanged: any = null;
    public UseSkipNoChange: any = null;

    public TryGetProductType (): ProductType | null {
        if (this.productType !== null) {
            return this.productType;
        } else if (!isNullOrUndefined(this.order) && this.order.ProductType !== null) {
            return this.order.ProductType;
        } else if (!isNullOrUndefined(this.position) && this.position.ProductType !== null) {
            return this.position.ProductType;
        } else {
            return null;
        }
    }

    public static GetDataFromOrderDict (orderDict: OrderEditRequestData): OrderEditRequestData {
        const result = new OrderEditRequestData();
        const orders = Object.values(orderDict);
        const order: Order = orders[0];

        if (order != null) {
            result.instrument = order.Instrument;
            result.account = order.Account;
            result.side = order.BuySell;
            result.orderTypeId = order.OrderType;
            result.order = order;
        }

        return result;
    }

    public async MarginRequest (): Promise<[number, number]> {
        const productType = this.TryGetProductType();
        const marginParameters = new MarginInfoParameters();
        marginParameters.account = this.account;
        marginParameters.instrument = this.instrument;
        marginParameters.orderType = this.orderTypeId;
        marginParameters.amountLots = Quantity.toLots(this.quantity, this.instrument, this.account, productType);
        marginParameters.limitPrice = this.getLimitPrice();
        marginParameters.stopPrice = this.getStopPrice();
        marginParameters.productType = productType;
        marginParameters.isLong = this.side === OperationType.Buy;

        const marginInfoWrapper = new MarginInfoWrapper();
        const totalFee = await marginInfoWrapper.GetTotalFeeFromMarginRequest(marginParameters);
        const afterTradeCash = await marginInfoWrapper.GetAfterTradeCashFromMarginRequest(marginParameters);

        return [totalFee, afterTradeCash];
    }

    private getLimitPrice (): number {
        const orderType = this.orderTypeId;
        if (orderType === OrderType.Stop) {
            return this.getStopPrice();
        }

        if (orderType === OrderType.TrailingStop) {
            return this.getTrailingStopPrice();
        }

        if (orderType === OrderType.Limit || orderType === OrderType.StopLimit || orderType === OrderType.OCO) {
            return this.parameterDict?.limitPrice;
        }

        return null;
    };

    private getStopPrice (): number {
        const orderType = this.orderTypeId;
        if (orderType === OrderType.Stop || orderType === OrderType.StopLimit) {
            return this.parameterDict?.stopPrice;
        }

        return null;
    };

    private getTrailingStopPrice (): number {
        const offsetTrStop = this.parameterDict?.trailingStop;
        const ins = this.instrument;
        const isBuy = this.side === OperationType.Buy;

        const lastQuote = ins ? ins.GetLastQuote(QuoteValid.Last) : null;

        if (!lastQuote) { return offsetTrStop; }

        const price = isBuy ? lastQuote.Ask : lastQuote.Bid;
        const sign = isBuy ? 1 : -1;

        const rawTicks = OrderUtils.toRawTicks(offsetTrStop, GeneralSettings.TradingDefaults.ShowOffsetIn, ins);

        return OrderUtils.ConvertTickOffset(ins, null, price, rawTicks * sign);
    }
}

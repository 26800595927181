// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../Utils/CustomEvents';

class _KeyEventProcessor {
    public lastPressedButton: any = null;
    public currentButton: any = null;
    public ControlPressed = false;
    public ShiftPressed = false;
    public AltPressed = false;
    public OnKeyDown = new CustomEvent();
    public OnKeyUp = new CustomEvent();
    public OnKeyPress = new CustomEvent();

    constructor () {
        this._initialization();
    }

    public _initialization (): void {
        const doc = document;
        doc.addEventListener('keydown', this.keyDown.bind(this));
        doc.addEventListener('keyup', this.keyUp.bind(this));
        doc.addEventListener('keypress', this.keyPress.bind(this));
    }

    public keyDown (event): void {
        this.setShiftAltCtrlState(event);
        this.setBtnCodes(event);
        this.OnKeyDown.Raise(this.currentButton, this.createModificatorStruct(), event);
    }

    public keyUp (event): void {
        this.setShiftAltCtrlState(event);
        this.setBtnCodes(event);
        this.OnKeyUp.Raise(this.currentButton, this.createModificatorStruct());
    }

    public keyPress (event): void {
        this.setShiftAltCtrlState(event);
        this.setBtnCodes(event);
        this.OnKeyPress.Raise(this.currentButton, this.createModificatorStruct());
    }

    public setShiftAltCtrlState (event): void {
        this.ControlPressed = event.ctrlKey;
        this.ShiftPressed = event.shiftKey;
        this.AltPressed = event.altKey;
    }

    public setBtnCodes (event): void {
        this.currentButton = event.which;
    }

    public createModificatorStruct () {
        return { CTRL: this.ControlPressed, ALT: this.AltPressed, SHIFT: this.ShiftPressed };
    }

    public isCtrlModOnly (): boolean {
        return this.ControlPressed && !this.AltPressed && !this.ShiftPressed;
    }

    public isShiftModOnly (event?): boolean {
        return this.ShiftPressed && !this.ControlPressed && !this.AltPressed;
    }

    public WithModButtons (): boolean {
        return this.ControlPressed || this.AltPressed || this.ShiftPressed;
    }
}

export const KeyEventProcessor = new _KeyEventProcessor();

export function GetDigit (keyCode): number {
    const zeroCode = keyCode >= KeyCode.NUM_ZERO ? KeyCode.NUM_ZERO : KeyCode.ZERO;
    const digit = keyCode - zeroCode;
    return digit >= 0 && digit <= 9 ? digit : NaN;
}

export function IsPasteEvent (event): boolean {
    return (event.ctrlKey === true || event.metaKey === true) && event.keyCode === KeyCode.V;
}

export enum KeyCode {
    ENTER = 13,
    SHIFT = 16,
    CTRL = 17,
    ESC = 27,
    PAGE_UP = 33,
    PAGE_DOWN = 34,
    END = 35,
    HOME = 36,
    LEFT = 37,
    UP = 38,
    RIGHT = 39,
    DOWN = 40,
    DELETE = 46,
    ZERO = 48,
    A = 65,
    L = 76,
    V = 86,
    NUM_ZERO = 96
}

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { TradingViewChartPanelTemplate } from '../../templates.js';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { CurrentLang, Resources } from '../../Commons/properties/Resources';
import { DataCache } from '../../Commons/DataCache';
import { Periods } from '../../Utils/History/TFInfo';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { LinkedSystem } from '../misc/LinkedSystem';
import { DynProperty } from '../../Commons/DynProperty';
import { TvDataFeed } from '../../Commons/TradingViewDataFeed/TvDataFeed';
import { type ResolutionString, type IChartingLibraryWidget, type LanguageCode, type MouseEventParams, type ThemeName, type UndoRedoState } from '../../Commons/TradingViewDataFeed/charting_library';
import { type Account } from '../../Commons/cache/Account';
import { TvInteriorIdCache, TvInteriorIdCacheMode } from '../../Commons/TradingViewDataFeed/Caches/TvInteriorIdCache';
import { Instrument } from '../../Commons/cache/Instrument';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type TerceraChartMVCModel } from '../../Chart/TerceraChartMVC';
import { type TerceraChartCashItemSeriesSettings } from '../../Chart/Series/TerceraChartCashItemSeriesSettings';
import { DockSystemInstance } from '../DockSystem';
import { TvHistoryResolutionHelper } from '../../Commons/TradingViewDataFeed/Helpers/TvHistoryResolutionHelper';
import { TvHistoryAggregationsHelper } from '../../Commons/TradingViewDataFeed/Helpers/TvHistoryAggregationsHelper';

const TRADINGVIEW_WIDGET_CONTAINER_ID_PREFIX = '-TV-'; // Reference to an attribute of a DOM element
const CURRENT_TIME_ZONE_PASSED_TO_TRADINGVIEW = 'Europe/Bucharest';
const DEFAULT_PERIOD = Periods.DAY;

declare const TradingView: any;

export class TradingViewChartPanel extends ApplicationPanelWithTable<any> {
    private tvWidget: IChartingLibraryWidget | null = null;
    private tvDataFeed: TvDataFeed;
    private language: LanguageCode = 'en';
    private readonly theme: ThemeName = 'dark';
    private tvWidgetDefferedProps: object | null;
    private tVWChartReady: boolean = false;
    private onTvFeedReadyCount: number = 0;
    private isMousePressedOutside = false;
    private isMouseInsideChart = false;
    private skipApplyInstrument = false;
    private tvPropsBeforeResize: any;

    model: TerceraChartMVCModel;
    cashItemSeriesSettings: TerceraChartCashItemSeriesSettings;
    currentBarWithMaxTime: null;

    constructor () {
        super();

        this.Name = 'TradingViewChartPanel';

        this.headerLocaleKey = 'panel.tradingViewChart.header';
        this.NeedCalculateRowCount = false;
        this.tvWidget = null; // TradingView Widget Holder
        this.language = 'en';
        this.theme = 'dark';

        this.model = null; // TerceraChartMVCModel instance
        this.cashItemSeriesSettings = null; // TerceraChartCashItemSeriesSettings instance
        this.currentBarWithMaxTime = null;
    }

    getType (): PanelNames { return PanelNames.TradingViewChartPanel; }

    oninit (): void {
        super.oninit();

        TvInteriorIdCache.mode = TvInteriorIdCacheMode.TvChart;

        this.onUndoRedoStateChanged = this.onUndoRedoStateChanged.bind(this);
        this.onTvChartMouseDown = this.onTvChartMouseDown.bind(this);
        this.onTvChartMouseUp = this.onTvChartMouseUp.bind(this);
        this.onTvChartMouseEnter = this.onTvChartMouseEnter.bind(this);
        this.onTvChartMouseLeave = this.onTvChartMouseLeave.bind(this);

        super.on('onTvChartMouseEnter', this.onTvChartMouseEnter);
        super.on('onTvChartMouseLeave', this.onTvChartMouseLeave);

        document.addEventListener('mousedown', this.handleGlobalMouseDown.bind(this));
        document.addEventListener('mouseup', this.handleGlobalMouseUp.bind(this));
    }

    oncomplete (): void {
        super.oncomplete();

        this.tvDataFeed = new TvDataFeed();
        this.tvDataFeed.onReadyEvent.Subscribe(this.onReadyEventCalled, this);
        super.observe('account', this.onAccountItemChanged);

        this.initTradingViewWidget();

        super.observe('instrumentItem', this.onInstrumentItemChanged);
        // super.observe('width', (nW) => { this.Controls.ToolsContainer.set('panelWidth', nW); });

        DockSystemInstance.dockRebuildEvent.Subscribe(this.callbackBeforeResize, this);
    }

    onteardown (): void {
        this.tvDataFeed.onReadyEvent.UnSubscribe(this.onReadyEventCalled, this);
        this.tvDataFeed.destroy();
        document.removeEventListener('mousedown', this.handleGlobalMouseDown);
        document.removeEventListener('mouseup', this.handleGlobalMouseUp);
    }

    public callbackBeforeResize (): void {
        if (!this.tVWChartReady) {
            return;
        }

        this.tvWidget?.save((obj) => { this.tvPropsBeforeResize = obj; });
    }

    initTradingViewWidget (): void {
        if (this.tvWidget != null) { this.deleteTradingViewWidget(); }

        TvHistoryAggregationsHelper.parseKeys();

        const defaultResoultion = TvHistoryResolutionHelper.convertPeriodToResolution(DEFAULT_PERIOD);

        const enabled_features = [
            // 'clear_bars_on_series_error',
            'show_symbol_logos',
            'show_symbol_logo_in_legend',
            'hide_resolution_in_legend',
            'pre_post_market_sessions'
        ];

        if (TvHistoryAggregationsHelper.hasTicks()) {
            enabled_features.push('tick_resolution');
        }

        if (TvHistoryAggregationsHelper.hasSeconds()) {
            enabled_features.push('seconds_resolution');
        }

        if (TvHistoryAggregationsHelper.isCustomAggregationsAllowed()) {
            enabled_features.push('custom_resolutions');
        }

        this.tvWidget = new TradingView.widget({
            symbol: this.getTVSymbol(), // Default symbol pair
            locale: this.language,
            timezone: CURRENT_TIME_ZONE_PASSED_TO_TRADINGVIEW,
            autosize: true, // A Boolean value showing whether the chart should use all the available space in the container and resize when the container itself is resized.
            container: this.getWidgetContainerID(), // Reference to an attribute of a DOM element
            datafeed: this.tvDataFeed,

            debug: false,
            enabled_features,
            disabled_features: [
                /* 'widget_logo', */
                'timeframes_toolbar',
                'use_localstorage_for_settings',
                'adaptive_logo',
                'symbol_search_hot_key'
            ], //, 'symbol_info'],//, 'header_symbol_search', 'header_compare', 'header_interval_dialog_button'],

            // timeframe: '1M',                                        // Selected data timeframe
            interval: defaultResoultion,

            // supported_resolutions: TvChartSupportedResolutionArray,

            custom_font_family: 'Trebuchet MS',
            library_path: '../../scripts/Libs/TradingViewChart/charting_library/',

            // context_menu: {     // hide after anal's comment https://tp.traderevolution.com/entity/117411
            //     items_processor: (items, actionsFactory) =>
            //     {
            //         this.setFocus();// for accWidget and lookupDropdown form close when click on iframe
            //         return TradingViewUtils.addChangeThemeContextMenuItem(this.theme, items, actionsFactory, this.onChangeThemeContextMenuClick.bind(this));
            //     },
            // },

            custom_css_url: 'current_theme/tvchart.css',
            theme: this.theme,
            // custom_css_url: '../../css/custom_style.css',        // blob:http://192.168.10.52:5055/6cebf6e1-505d-4909-9f82-3dfa7f9f998f:1 Refused to apply style from 'http://192.168.10.52:5055/scripts/Libs/TradingViewChart/css/custom_style.css' because its MIME type ('text/html') is not a supported stylesheet MIME type, and strict MIME checking is enabled.
            // fullscreen: false,                                   // A Boolean value showing whether the chart should use all the available space in the window.

            studies_access: {
                type: 'black',
                tools: [
                    {
                        name: 'Correlation - Log'
                        // grayed: true
                    },
                    {
                        name: 'Correlation Coefficient'
                    },
                    {
                        name: 'Spread'
                    },
                    {
                        name: 'Ratio'
                    }
                ]
            }
        });

        this.applyOverrides();
        this.tvWidget.onChartReady(this.onTVWChartReady.bind(this));
    }

    onTVWChartReady (): void {
        // BrowserUtils.log({ text: 'onChartReady started ', color: 'gray' });

        const tvW = this.tvWidget;

        this.setWatermarkVisibility();

        // Отключить торговлю и алерты на TV чарте:
        // this.Controls.ToolsContainer.set('instrument', this.GetInstrument());

        this.subscribeTvWidgetEvents();

        if (tvW != null) {
            // tvW.changeTheme(this.theme);

            if (this.tvWidgetDefferedProps != null) {
                tvW.load(this.tvWidgetDefferedProps);
                this.tvWidgetDefferedProps = null;
            }

            if (this.tvPropsBeforeResize != null) {
                tvW.load(this.tvPropsBeforeResize);
                this.tvPropsBeforeResize = null;
            }
        }

        this.tVWChartReady = true;
        this.ApplySymbolForTvWidget();
        this.updatePanelHeader();
        // BrowserUtils.log({ text: 'chartIsReady', color: '#74c489' });
    }

    async onInstrumentItemChanged (newValue, oldValue): Promise<void> {
        if (this.skipApplyInstrument) {
            this.skipApplyInstrument = false;
            return;
        }

        if (newValue === oldValue) { return; }
        let instrument: Instrument = newValue;
        if (instrument?.IsFakeNonFixed()) {
            instrument = await DataCache.getInstrumentByInstrumentTradableID_NFL(instrument.InstrumentTradableID,
                instrument.Route,
                instrument.GetInteriorID());
        }

        if (instrument != null) {
            this.updatePanelHeader();
            this.ApplySymbolForTvWidget();
            this.symbolLink_Out(false, instrument);
        }
    }

    GetInstrument (): Instrument {
        return this.get('instrumentItem');
    }

    SetInstrument (instrument: Instrument): void {
        void this.set('instrumentItem', instrument);
    }

    private onAccountItemChanged (): void {
        const account: Account = this.get('account') ?? DataCache.MainAccountNew;
        this.tvDataFeed.setCurrentAccount(account);

        if (this.tvWidget != null && this.tVWChartReady) {
            this.tvWidget.activeChart().resetData();
        }
    }

    async onReadyEventCalled (): Promise<void> {
        this.onTvFeedReadyCount++;
        // когда другая панель перекрыает чарт, то он перегружается
        // и при этом onChartReady не срабатывает повторно
        // понять что чарт перегрузился мы можем только по этому событию
        if (this.onTvFeedReadyCount === 1) {
            return;
        }

        // и нужно подождать пока чарт не прогрузится
        let isTvChartReady = false;
        while (!isTvChartReady) {
            try {
                if (this.tvWidget?.activeChart() != null) {
                    isTvChartReady = true;
                }
            } catch {
                isTvChartReady = false;
            }
            await new Promise((resolve) => setTimeout(resolve, 100));
        }

        this.onTVWChartReady();
    }

    ApplySymbolForTvWidget (): void {
        if (this.tVWChartReady) {
            const symbolName = this.getTVSymbol();
            this.tvWidget?.setSymbol(symbolName, this.getTvResoultion(), () => {
                // BrowserUtils.log({ text: 'widget setSymbol ' + this.getTVSymbol() + ' ' + DataCache.Instruments[this.getTVSymbol()] })
            });
        }
    }

    // onMouseMove (event) {
    //     this.Controls.ToolsContainer.onMouseMove(event);
    // }

    // onMouseLeave (event) {
    //     this.Controls.ToolsContainer.onMouseUp(event); // ??
    // }

    updatePanelHeader (): void {
        if (!this.tVWChartReady) { return; }

        const activeChart = this.tvWidget?.activeChart();

        if (activeChart == null) { return; }

        let header = Resources.getResource(this.headerLocaleKey) + ' ';

        const ins = this.GetInstrument();
        if (Instrument.IsWorkingInstrument(ins)) header += ins.DisplayName() + ' ';

        const resolution = this.getTvResoultion();
        const period = TvHistoryResolutionHelper.convertResolutionToPeriod(resolution);

        header +=
            Resources.getResource('chart.timeframeselector.time') +
            ' - ' +
            Periods.ToLocalizedShortPeriod(period);

        void this.set('header', header);
    }

    // onChangeThemeContextMenuClick(subitem)
    // {
    //     if (subitem && this.tvWidget) {
    //         this.theme = subitem.getLabel().toLowerCase();
    //         this.tvWidget.changeTheme(this.theme);
    //     }
    // }

    localize (): void {
        super.localize();

        this.changeTradingViewWidgetLanguage();
    }

    changeTradingViewWidgetLanguage (): void {
        if (this.language === CurrentLang) {
            return;
        }

        // BrowserUtils.log({ text: 'entered. Current language = ' + this.language + ' in panel ' + this.getWidgetContainerID() + '\n trying to change to new language ' + CurrentLang, color: '#8439ed' });

        const oldLang = this.language;
        this.language = CurrentLang.split('_')[0] as LanguageCode; // convert language from Resources (e.g. Chinese) to TradingView language format

        if (oldLang !== this.language && !isNullOrUndefined(this.tvDataFeed)) {
            this.initTradingViewWidget();
        } // Create a new widget with the updated language
    }

    // TickAsync () {
    //     if (!super.get('visible')) { return; }

    //     this.Controls.ToolsContainer.TickAsync();
    // }

    symbolLink_In (symbolInteriorID): void {
        const newInstr = DataCache.getInstrumentByName(symbolInteriorID);
        const oldIns = this.GetInstrument();
        if (newInstr != null && oldIns?.GetInteriorID() !== symbolInteriorID) { this.SetInstrument(newInstr); }
    }

    symbolLink_Out (newSubscriber, instrument): void {
        if (instrument == null) {
            const ins = this.GetInstrument();
            if (ins == null) return;
            instrument = ins;
        }

        const color = super.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) { LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber); }
    }

    dispose (): void {
        this.tvDataFeed.onReadyEvent.UnSubscribe(this.onReadyEventCalled, this);
        DockSystemInstance.dockChangedEvent.UnSubscribe(this.callbackBeforeResize, this);

        this.deleteTradingViewWidget();
        // this.setFocusOnIframeEventSubscriptions(false);
        this.unsubscribeTvWidgetEvents();
        super.dispose();
        // BrowserUtils.log({ color: '#2deb7c', background: '#ed1a2f' });
    }

    deleteTradingViewWidget (): void {
        const container = document.getElementById(this.getWidgetContainerID()); // Get the container element

        while (container?.firstChild != null) { // Remove the existing widget
            container.removeChild(container.firstChild);
        }

        this.tvWidget = null;

        // BrowserUtils.log({ text: 'this.tvWidget was removed', color: 'gray', background: '#800f20' });
    }

    getTVSymbol (): string {
        const ins = this.GetInstrument();
        if (ins == null) { return null; }
        const ticker = ins.GetInteriorID(); // ins.DisplayName();
        return ticker;
    }

    private getTvResoultion (): ResolutionString {
        const activeChart = this.tvWidget?.activeChart();

        if (activeChart == null) {
            return TvHistoryResolutionHelper.convertPeriodToResolution(DEFAULT_PERIOD);
        }

        return activeChart.resolution();
    }

    getWidgetContainerID (): string {
        let containerID = this.get('widgetContainerID');
        if (containerID == null) {
            containerID = TRADINGVIEW_WIDGET_CONTAINER_ID_PREFIX + this._guid;
            void this.set('widgetContainerID', containerID);
        }
        return containerID;
    }

    // setFocusOnIframeEventSubscriptions(needSubscribe = true)   // unsubscribe if needSubscribe == false
    // {
    //     if (this.tvWidget == null) return;

    //     const events_list: (keyof SubscribeEventsMap)[] = ['mouse_down', 'indicators_dialog', 'undo_redo_state_changed', 'onSelectedLineToolChanged', /*'legend_action',*/ 'toggle_sidebar', 'edit_object_dialog'/*, 'double_click price scale'*/];   //, 'chart_header toolbar'
    //     const method = needSubscribe ? 'subscribe' : 'unsubscribe';

    //     const widget = this.tvWidget;

    //     events_list.forEach((event) => widget[method](event, () => { this.setFocus(); })); // for accWidget and lookupDropdown form close when click on iframe
    // }

    subscribeTvWidgetEvents (): void {
        this.subscribeTVTimeIntervalChanged();
        this.subscribeTvSymbolChanged();
        this.subscribeUndoRedoStateChanged();
        this.subscribeMouseEvents();
    }

    unsubscribeTvWidgetEvents (): void {
        this.unsubscribeTVTimeIntervalChanged();
        this.unsubscribeTvSymbolChanged();
        this.unsubscribeUndoRedoStateChanged();
        this.unsubscribeMouseEvents();
    }

    subscribeTVTimeIntervalChanged (): void {
        if (this.tvWidget == null) return;
        this.tvWidget.activeChart().onIntervalChanged().subscribe(null, (interval, timeframeObj) => { this.onIntervalChanged(interval, timeframeObj); });
    }

    unsubscribeTVTimeIntervalChanged (): void {
        if (this.tvWidget == null) return;
        this.tvWidget.activeChart().onIntervalChanged().unsubscribe(null, (interval, timeframeObj) => { this.onIntervalChanged(interval, timeframeObj); });
    }

    onIntervalChanged (interval, timeframeObj): void {
        this.updatePanelHeader();
    }

    subscribeTvSymbolChanged (): void {
        if (this.tvWidget == null) return;
        this.tvWidget.activeChart().onSymbolChanged().subscribe(null, () => { this.onTvSymbolChanged(); });
    }

    unsubscribeTvSymbolChanged (): void {
        if (this.tvWidget == null) return;
        this.tvWidget.activeChart().onSymbolChanged().unsubscribe(null, () => { this.onTvSymbolChanged(); });
    }

    onTvSymbolChanged (): void {
        this.updatePanelHeader();
    }

    subscribeUndoRedoStateChanged (): void {
        if (this.tvWidget == null) return;

        this.tvWidget.subscribe('undo_redo_state_changed', this.onUndoRedoStateChanged);
    }

    unsubscribeUndoRedoStateChanged (): void {
        if (this.tvWidget == null) return;

        this.tvWidget.unsubscribe('undo_redo_state_changed', this.onUndoRedoStateChanged);
    }

    subscribeMouseEvents (): void {
        if (this.tvWidget == null) return;

        this.tvWidget.subscribe('mouse_down', this.onTvChartMouseDown);
        this.tvWidget.subscribe('mouse_up', this.onTvChartMouseUp);
    }

    unsubscribeMouseEvents (): void {
        if (this.tvWidget == null) return;

        this.tvWidget.unsubscribe('mouse_down', this.onTvChartMouseDown);
        this.tvWidget.unsubscribe('mouse_up', this.onTvChartMouseUp);
    }

    onUndoRedoStateChanged (state: UndoRedoState): void {
        if (!this.tVWChartReady || this.tvWidget == null) return;

        const symbolFromChart = this.tvWidget.activeChart().symbol();
        const symbolFromLookup = this.getTVSymbol();
        if (symbolFromChart !== symbolFromLookup) {
            // какая-то странная ситуация, когда делаешь Undo - то TV чарт возвращает InteriorID
            // для Redo - возвращает имя инструмента, поэтому такой двойственный поиск
            let instrument = DataCache.getInstrumentByName(symbolFromChart);
            if (instrument == null) {
                instrument = this.tvDataFeed.getSymbolByName(symbolFromChart);
            }

            if (instrument == null) return;

            this.skipApplyInstrument = true; // update only in lookup
            this.SetInstrument(instrument);
        }
    }

    Properties (): DynProperty[] {
        const props = super.Properties();

        let TVProps = '';
        this.tvWidget?.save((obj) => TVProps = (JSON.stringify(obj)));

        if (TVProps != null) { props.push(new DynProperty('TradingViewProperties', TVProps, DynProperty.STRING, DynProperty.HIDDEN_GROUP)); }

        // props.push(new DynProperty("theme", this.theme, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        const instrument = this.GetInstrument();
        if (instrument != null) {
            props.push(new DynProperty('symbol', instrument.GetInteriorID(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        }

        return props;
    }

    callBack (newProperties: DynProperty[]): void {
        super.callBack(newProperties);

        const tvWidgetPropsStr = DynProperty.getPropValue(newProperties, 'TradingViewProperties');
        if (tvWidgetPropsStr != null) {
            const tvWidgetProps = JSON.parse(tvWidgetPropsStr);
            if (this.tvWidget != null) {
                this.tvWidget?.load(tvWidgetProps);
            } else {
                this.tvWidgetDefferedProps = tvWidgetProps;
            }
        }

        // const theme = DynProperty.getPropValue(newProperties, "theme");
        // if (theme)
        //     this.theme = theme;

        this.SetInstrument(super.getCallBackInstrument(newProperties, 'symbol'));
    }

    private onTvChartMouseEnter (): void {
        this.isMouseInsideChart = true;
        this.validateMousePressed();
    }

    private onTvChartMouseLeave (): void {
        this.isMouseInsideChart = false;
        this.validateMousePressed();
    }

    private handleGlobalMouseDown (): void {
        this.isMousePressedOutside = !this.isMouseInsideChart;
        this.validateMousePressed();
    }

    private handleGlobalMouseUp (): void {
        this.isMousePressedOutside = false;
        this.validateMousePressed();
    }

    private onTvChartMouseDown (params: MouseEventParams): void {
        this.isMousePressedOutside = false;
        this.validateMousePressed();

        const container = document.getElementById(this.getWidgetContainerID());
        if (container != null) {
            const evt = new Event('mousedown', { bubbles: true, cancelable: true });
            container.dispatchEvent(evt);
        }
    }

    private onTvChartMouseUp (params: MouseEventParams): void {
        this.isMousePressedOutside = false;
        this.validateMousePressed();

        const container = document.getElementById(this.getWidgetContainerID());
        if (container != null) {
            const evt = new Event('mouseup', { bubbles: true, cancelable: true });
            container.dispatchEvent(evt);
        }
    }

    private validateMousePressed (): void {
        const prveDisablePointerEvents = this.get('disablePointerEvents');
        const disablePointerEvents = this.isMousePressedOutside;

        if (disablePointerEvents !== prveDisablePointerEvents) {
            void this.set('disablePointerEvents', disablePointerEvents);
        }
    }

    private setWatermarkVisibility (): void {
        if (this.tvWidget == null) return;
        this.tvWidget.watermark().visibility().setValue(true, true);
    }

    private applyOverrides (): void {
        if (this.tvWidget == null) { return; }

        const OVERRIDES = {
            // "paneProperties.background": "#020024",
            // "paneProperties.vertGridProperties.color": 'gold',
            // "scalesProperties.axisHighlightColor": "#FFFFFF",

            // 'mainSeriesProperties.sessionId': 'extended',

            'mainSeriesProperties.style': 1, // candle chart style

            'mainSeriesProperties.candleStyle.borderUpColor': 'rgb(47,152,236)',
            'mainSeriesProperties.candleStyle.upColor': 'rgb(47,152,236)',
            'mainSeriesProperties.candleStyle.wickUpColor': 'rgb(47,152,236)',

            'mainSeriesProperties.candleStyle.borderDownColor': 'rgb(180,61,61)',
            'mainSeriesProperties.candleStyle.downColor': 'rgb(180,61,61)',
            'mainSeriesProperties.candleStyle.wickDownColor': 'rgb(180,61,61)',

            // Main series price line
            'mainSeriesProperties.priceLineColor': 'rgb(47,152,236)',
            'mainSeriesProperties.priceLineWidth': 1,

            'scalesProperties.showSymbolLabels': false, // is need show instrument's name near price scale
            'scalesProperties.fontSize': 10
        };

        this.tvWidget.applyOverrides(OVERRIDES);
    }
}

ApplicationPanelWithTable.extendWith(TradingViewChartPanel, {
    data: function () {
        return {
            widgetContainerID: null, // id of div to set TradingView widget in

            isSymbolLinkShow: true,

            instrumentItem: null,
            disablePointerEvents: false
        };
    },

    partials: { bodyPartial: TradingViewChartPanelTemplate }
});

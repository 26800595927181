// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraDateRangeDropDownFormTemplate } from '../../../templates.js';
import { Resources } from '../../../Commons/properties/Resources';
import { TerceraWindowBase } from '../../screen/TerceraWindowBase';
import { BOTTOM_WINDOWS_MARGIN } from '../../UtilsClasses/SizeConstants';
import { ControlEvents } from '../Control';

export class TerceraDateRangeDropDownForm extends TerceraWindowBase {
    constructor () { super(); }

    public override oncomplete (): void {
        super.oncomplete();

        const pickerFrom = this.Controls.dtPickerFrom.picker;
        const pickerTo = this.Controls.dtPickerTo.picker;

        pickerFrom.selectDate(this.get('dateFrom'));
        pickerTo.selectDate(this.get('dateTo'));

        this.on('applyBtnClick', this.close);

        pickerFrom.update('onRenderCell', this.datePickerOnRenderCell.bind(this));
        pickerFrom.update('onSelect', this.onSelectDateFrom.bind(this));

        pickerTo.update('onRenderCell', this.datePickerOnRenderCell.bind(this));
        pickerTo.update('onSelect', this.onSelectDateTo.bind(this));

        this.on(ControlEvents.LostFocus, function () { this.close(); }.bind(this));
        this.setFocus();
    }

    public onSelectDateFrom (): void {
        const dtPickerFrom = this.Controls.dtPickerFrom.picker;
        const dateFromArr = dtPickerFrom.selectedDates;
        const dateFrom = dateFromArr.length ? dateFromArr[0] : dtPickerFrom.currentDate;
        const dateTo = this.get('dateTo');

        this.setDateFrom(dateFrom);

        if (dateFrom > dateTo) {
            this.Controls.dtPickerTo.picker.selectDate(dateFrom);
        }
    }

    public onSelectDateTo (): void {
        const dtPickerTo = this.Controls.dtPickerTo.picker;
        const dateToArr = dtPickerTo.selectedDates;
        const dateTo = dateToArr.length ? dateToArr[0] : dtPickerTo.currentDate;
        const dateFrom = this.get('dateFrom');

        this.setDateTo(dateTo);

        if (dateTo < dateFrom) {
            this.Controls.dtPickerFrom.picker.selectDate(dateTo);
        }
    }

    public setDateFrom (date: Date): void {
        void this.set('dateFrom', date);
    }

    public setDateTo (date: Date): void {
        void this.set('dateTo', date);
    }

    public datePickerOnRenderCell (date: Date, cellType: string): { classes: string } {
        if (cellType === 'day' && date >= this.get('dateFrom') && date <= this.get('dateTo')) {
            return { classes: 'datepicker--days-range' };
        }
    }

    public setCorrectLocation (x: number, y: number): void {
        const width = this.get('width'); const height = this.get('height');

        if (x + width > window.innerWidth) {
            x = window.innerWidth - width - 2;
        }
        if (y + height > window.innerHeight - BOTTOM_WINDOWS_MARGIN) {
            y = window.innerHeight - BOTTOM_WINDOWS_MARGIN - height - 2;
        }

        void this.set({ left: x, top: y });
    }
}

TerceraWindowBase.extendWith(TerceraDateRangeDropDownForm, {
    data: function () {
        return {
            resizable: false,
            width: 438,
            height: 258,
            showHeader: false,
            showFooter: false,
            dateFrom: null,
            dateTo: null,
            applyBtnText: Resources.getResource('screen.MonthCalendarPopupForm.Apply'),
            labelTextFrom: Resources.getResource('screen.reports.reportHeaders.from'),
            labelTextTo: Resources.getResource('screen.reports.reportHeaders.to')
        };
    },
    partials: { bodyPartial: TerceraDateRangeDropDownFormTemplate }
});

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type AdditionalSymbolInfoField, type LibrarySubsessionInfo, type LibrarySymbolInfo, type Timezone } from '../charting_library';
import { TvHistoryAggregationsHelper } from '../Helpers/TvHistoryAggregationsHelper';
import { TvUtcTimeZone } from './TvConstants';
import { type SeriesFormatEnum, VisiblePlotsSetEnum } from './TvEnums';
import { type TvSymbolInfoPriceSource } from './TvSymbolInfoPriceSource';

export class TvSymbol implements LibrarySymbolInfo {
    public ticker = 'symbol name';
    public name = 'name';
    public description = 'description';
    public type = 'type string';
    public pricescale = 10; // Math.pow(10, presicion);
    public volume_precision = 1;
    public exchange = 'trading exchange name';
    public listed_exchange = 'market exchange name';
    public currency_code = ''; // the currency in which the instrument is traded or some other currency if currency conversion is enabled. It is displayed in the Symbol Info dialog and on the price axes
    public original_currency_code = ''; // the currency in which the instrument is traded

    public sector = 'sector';
    public industry = 'industry';

    public session = 'session string'; // '24x7'
    public session_holidays = '';
    public corrections = '';
    public subsession_id = '';
    public subsessions: LibrarySubsessionInfo[];

    public minmov = 1;
    public minmove2 = 0;

    // supported_resolutions = [...TvChartSupportedResolutionArray] as ResolutionString[];

    public seconds_multipliers: string[] = TvHistoryAggregationsHelper.getSecondsMultipliers();
    public intraday_multipliers: string[] = TvHistoryAggregationsHelper.getIntradayMultipliers();
    public daily_multipliers: string[] = TvHistoryAggregationsHelper.getDailyMultipliers();
    public weekly_multipliers = TvHistoryAggregationsHelper.getWeeklyMultipliers();
    public monthly_multipliers = TvHistoryAggregationsHelper.getMonthlyMultipliers();

    public has_ticks = TvHistoryAggregationsHelper.hasTicks();
    public has_seconds = TvHistoryAggregationsHelper.hasSeconds();
    public has_intraday = TvHistoryAggregationsHelper.hasIntraday();
    public has_daily = TvHistoryAggregationsHelper.hasDaily();
    public has_weekly_and_monthly = TvHistoryAggregationsHelper.hasWeeklyAndMonthly();

    public has_empty_bars = false;

    public logo_urls;

    public timezone = TvUtcTimeZone as Timezone;
    public visible_plots_set = VisiblePlotsSetEnum.ohlcv;
    public data_status: 'streaming' | 'endofday' | 'delayed_streaming' = 'streaming';
    public delay: number = 0;

    public expired;
    public expiration_date;

    public variable_tick_size: string;

    public price_source_id?: string;
    public price_sources?: TvSymbolInfoPriceSource[];

    public format: SeriesFormatEnum;

    public additional_symbol_info_fields?: AdditionalSymbolInfoField[];
}

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Factory } from '../panels/Factory';
import { type TerceraAccountLookupDropDownForm } from '../elements/Lookup/TerceraAccountLookupDropDownForm';
import { type TerceraInstrumentLookupDropDownForm } from '../elements/Lookup/TerceraInstrumentLookupDropDownForm';
import { type FullLogsPanel } from '../panels/FullLogsPanel';
import { type TerceraChangePasswordScreen } from '../screen/TerceraChangePasswordScreen';
import { type TypesManagerScreen } from '../screen/TypesManagerScreen';
import { type TerceraBrokerMessageScreen } from '../screen/TerceraBrokerMessageScreen';
import { FullScreenManager } from './FullScreenManager';
import { ControlsUtils } from './ControlsUtils';
import { type TerceraIndicatorLookupDropDownForm } from '../elements/Lookup/TerceraIndicatorLookupDropDownForm';
import { type InvestingChangePasswordScreen } from '../../Investing/Screen/InvestingChangePasswordScreen';
import { type InvestingLoginScreen } from '../../Investing/Screen/InvestingLoginScreen';
import { type TerceraLoginScreen } from './../screen/TerceraLoginScreen';
import { type NewAccountDetailsPanel } from '../panels/VerticalPanels/NewAccountDetailsPanel';

class _MainWindowManager {
    public Factory: null | typeof Factory = null;
    public FullLogPanel: FullLogsPanel | null = null;
    public TypesManagerScreen: TypesManagerScreen | null = null;
    public AsyncCloseObject: TerceraBrokerMessageScreen | null = null;
    public AccountDetailsPanel: NewAccountDetailsPanel | null = null;
    public portfolioWindow: WindowProxy | null = null;

    private mainWindow: any = null;
    private terceraAccountLookupDropDownForm: null | TerceraAccountLookupDropDownForm = null;
    private terceraInstrumentLookupDropDownForm: null | TerceraInstrumentLookupDropDownForm = null;
    private terceraIndicatorLookupDropDownForm: null | TerceraIndicatorLookupDropDownForm = null;
    private changePasswordScreen: TerceraChangePasswordScreen | InvestingChangePasswordScreen | null = null;

    public constructor () {
        document.addEventListener('fullscreenchange', (event: Event) => {
            FullScreenManager.HandleFullscreenChange(event);
        });
    }

    public get MainWindow (): any {
        return FullScreenManager.IsFullScreenMode
            ? ControlsUtils.getRactiveFromHTMLElement(document.fullscreenElement as HTMLElement)
            : this.mainWindow;
    }

    public set MainWindow (value) { this.mainWindow = value; }

    public get TerceraAccountLookupDropDownForm (): TerceraAccountLookupDropDownForm | null {
        return FullScreenManager.IsFullScreenMode ? FullScreenManager.AccountLookupDropDownForm : this.terceraAccountLookupDropDownForm;
    }

    public set TerceraAccountLookupDropDownForm (form: TerceraAccountLookupDropDownForm | null) {
        this.terceraAccountLookupDropDownForm = form;
    }

    public get TerceraInstrumentLookupDropDownForm (): TerceraInstrumentLookupDropDownForm | null {
        return FullScreenManager.IsFullScreenMode ? FullScreenManager.InstrumentLookupDropDownForm : this.terceraInstrumentLookupDropDownForm;
    }

    public set TerceraInstrumentLookupDropDownForm (form: TerceraInstrumentLookupDropDownForm | null) {
        this.terceraInstrumentLookupDropDownForm = form;
    }

    public get TerceraIndicatorLookupDropDownForm (): TerceraIndicatorLookupDropDownForm | null {
        return FullScreenManager.IsFullScreenMode ? FullScreenManager.IndicatorLookupDropDownForm : this.terceraIndicatorLookupDropDownForm;
    }

    public set TerceraIndicatorLookupDropDownForm (form: TerceraIndicatorLookupDropDownForm | null) {
        this.terceraIndicatorLookupDropDownForm = form;
    }

    public get LoginScreen (): TerceraLoginScreen | InvestingLoginScreen | null {
        return this.MainWindow?.Controls?.loginScreen;
    }

    public get ChangePasswordScreen (): TerceraChangePasswordScreen | InvestingChangePasswordScreen | null {
        return this.changePasswordScreen;
    }

    public set ChangePasswordScreen (screen: TerceraChangePasswordScreen | InvestingChangePasswordScreen | null) {
        this.changePasswordScreen = screen;
    }
}

export const MainWindowManager = new _MainWindowManager();

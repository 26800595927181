// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../Commons/properties/Resources';
import { UTCOffset } from './UTCOffset';
import { DateTime } from 'luxon';

export class TimeZoneInfo {
    public id: any;
    public baseUtcOffset: UTCOffset;
    public sortIndex: number;

    constructor (TimeZoneInfoObject) {
        this.id = TimeZoneInfoObject.value;
        this.baseUtcOffset = new UTCOffset(TimeZoneInfoObject.offset);
    }

    public compareTo (tzi): number {
        const utcComparisonResult = this.baseUtcOffset.compareTo(tzi.baseUtcOffset);
        if (utcComparisonResult === 0) {
            return this.toLocalizedString().localeCompare(tzi.toLocalizedString());
        }

        return utcComparisonResult;
    }

    public toLocalizedString (): string {
        const locPattern = Resources.getResource('screen.TimeZoneSelector.ZoneId.' + this.id);
        return locPattern.replace(/\{1\}/, this.baseUtcOffset.toString());
    }

    public static compare (tzInfo1: TimeZoneInfo, tzInfo2: TimeZoneInfo): number {
        return tzInfo1.compareTo(tzInfo2);
    }

    public static serializeToIdsString (timeZoneInfoIdsSet): string {
        let res = '';

        if (!timeZoneInfoIdsSet) {
            return res;
        }

        const keys = Object.keys(timeZoneInfoIdsSet);
        const len = keys.length;
        for (let i = 0; i < len; i++) {
            const id = keys[i];

            if (id) res += id + ';';
        }

        return res;
    }

    public static deserializeToIdsSet (idsString: string): Record<string, boolean> {
        if (!idsString) return null;

        const ids = idsString.split(';');

        const idsSet: Record<string, boolean> = {};

        const len = ids.length;
        for (let i = 0; i < len; i++) {
            const id = ids[i];

            if (id) idsSet[id] = true;
        }

        return idsSet;
    }

    public static ConvertTimeFromUtc (date: Date, destinationTimeZoneId: string): Date {
        if (isNullOrUndefined(date) || !isValidString(destinationTimeZoneId)) {
            return date;
        }
        const dateTimeInLocalTimeZone: DateTime = DateTime.fromMillis(date.getTime());
        const dateTimeInDestinationTimeZone: DateTime = DateTime.fromMillis(date.getTime(), { zone: destinationTimeZoneId });
        const offsetDifference = dateTimeInDestinationTimeZone.offset - dateTimeInLocalTimeZone.offset;
        const adjustedTime = date.getTime() + offsetDifference * 60 * 1000;
        return new Date(adjustedTime);
    }

    public static LOCAL = 'local';
    public static SERVER = 'server';
    public static SERVER_TIME_ZONE = '';

    // TODO. System timezone changes won't affect LocalOffsetMinutes.
    public static LocalOffsetMinutes = (new Date()).getTimezoneOffset() / -60;

    public static Local = new TimeZoneInfo({
        value: TimeZoneInfo.LOCAL,
        offset: TimeZoneInfo.LocalOffsetMinutes
    });

    public static readonly ListOfTimeZone =
        {
            'Etc/GMT+12': -12,
            'Etc/GMT+11': -11,
            'Pacific/Midway': -11,
            'Pacific/Niue': -11,
            'Pacific/Pago_Pago': -11,
            'Pacific/Samoa': -11,
            'US/Samoa': -11,
            'America/Adak': -10,
            'America/Atka': -10,
            'Etc/GMT+10': -10,
            HST: -10,
            'Pacific/Fakaofo': -10,
            'Pacific/Honolulu': -10,
            'Pacific/Johnston': -10,
            'Pacific/Rarotonga': -10,
            'Pacific/Tahiti': -10,
            'SystemV/HST10': -10,
            'US/Aleutian': -10,
            'US/Hawaii': -10,
            'Pacific/Marquesas': -9.5,
            AST: -9,
            'America/Anchorage': -9,
            'America/Juneau': -9,
            'America/Nome': -9,
            'America/Sitka': -9,
            'America/Yakutat': -9,
            'Etc/GMT+9': -9,
            'Pacific/Gambier': -9,
            'SystemV/YST9': -9,
            'SystemV/YST9YDT': -9,
            'US/Alaska': -9,
            'America/Dawson': -8,
            'America/Ensenada': -8,
            'America/Los_Angeles': -8,
            'America/Metlakatla': -8,
            'America/Santa_Isabel': -8,
            'America/Tijuana': -8,
            'America/Vancouver': -8,
            'America/Whitehorse': -8,
            'Canada/Pacific': -8,
            'Canada/Yukon': -8,
            'Etc/GMT+8': -8,
            'Mexico/BajaNorte': -8,
            PST: -8,
            PST8PDT: -8,
            'Pacific/Pitcairn': -8,
            'SystemV/PST8': -8,
            'SystemV/PST8PDT': -8,
            'US/Pacific': -8,
            'US/Pacific-New': -8,
            'America/Boise': -7,
            'America/Cambridge_Bay': -7,
            'America/Chihuahua': -7,
            'America/Dawson_Creek': -7,
            'America/Denver': -7,
            'America/Edmonton': -7,
            'America/Hermosillo': -7,
            'America/Inuvik': -7,
            'America/Mazatlan': -7,
            'America/Ojinaga': -7,
            'America/Phoenix': -7,
            'America/Shiprock': -7,
            'America/Yellowknife': -7,
            'Canada/Mountain': -7,
            'Etc/GMT+7': -7,
            MST: -7,
            MST7MDT: -7,
            'Mexico/BajaSur': -7,
            Navajo: -7,
            PNT: -7,
            'SystemV/MST7': -7,
            'SystemV/MST7MDT': -7,
            'US/Arizona': -7,
            'US/Mountain': -7,
            'America/Bahia_Banderas': -6,
            'America/Belize': -6,
            'America/Cancun': -6,
            'America/Chicago': -6,
            'America/Costa_Rica': -6,
            'America/El_Salvador': -6,
            'America/Guatemala': -6,
            'America/Indiana/Knox': -6,
            'America/Indiana/Tell_City': -6,
            'America/Knox_IN': -6,
            'America/Managua': -6,
            'America/Matamoros': -6,
            'America/Menominee': -6,
            'America/Merida': -6,
            'America/Mexico_City': -6,
            'America/Monterrey': -6,
            'America/North_Dakota/Beulah': -6,
            'America/North_Dakota/Center': -6,
            'America/North_Dakota/New_Salem': -6,
            'America/Rainy_River': -6,
            'America/Rankin_Inlet': -6,
            'America/Regina': -6,
            'America/Resolute': -6,
            'America/Swift_Current': -6,
            'America/Tegucigalpa': -6,
            'America/Winnipeg': -6,
            CST: -6,
            CST6CDT: -6,
            'Canada/Central': -6,
            'Canada/East-Saskatchewan': -6,
            'Canada/Saskatchewan': -6,
            'Chile/EasterIsland': -6,
            'Etc/GMT+6': -6,
            'Mexico/General': -6,
            'Pacific/Easter': -6,
            'Pacific/Galapagos': -6,
            'SystemV/CST6': -6,
            'SystemV/CST6CDT': -6,
            'US/Central': -6,
            'US/Indiana-Starke': -6,
            'America/Atikokan': -5,
            'America/Bogota': -5,
            'America/Cayman': -5,
            'America/Coral_Harbour': -5,
            'America/Detroit': -5,
            'America/Fort_Wayne': -5,
            'America/Grand_Turk': -5,
            'America/Guayaquil': -5,
            'America/Havana': -5,
            'America/Indiana/Indianapolis': -5,
            'America/Indiana/Marengo': -5,
            'America/Indiana/Petersburg': -5,
            'America/Indiana/Vevay': -5,
            'America/Indiana/Vincennes': -5,
            'America/Indiana/Winamac': -5,
            'America/Indianapolis': -5,
            'America/Iqaluit': -5,
            'America/Jamaica': -5,
            'America/Kentucky/Louisville': -5,
            'America/Kentucky/Monticello': -5,
            'America/Lima': -5,
            'America/Louisville': -5,
            'America/Montreal': -5,
            'America/Nassau': -5,
            'America/New_York': -5,
            'America/Nipigon': -5,
            'America/Panama': -5,
            'America/Pangnirtung': -5,
            'America/Port-au-Prince': -5,
            'America/Thunder_Bay': -5,
            'America/Toronto': -5,
            'Canada/Eastern': -5,
            Cuba: -5,
            EST: -5,
            EST5EDT: -5,
            'Etc/GMT+5': -5,
            IET: -5,
            Jamaica: -5,
            'SystemV/EST5': -5,
            'SystemV/EST5EDT': -5,
            'US/East-Indiana': -5,
            'US/Eastern': -5,
            'US/Michigan': -5,
            'America/Caracas': -4.5,
            'America/Anguilla': -4,
            'America/Antigua': -4,
            'America/Argentina/San_Luis': -4,
            'America/Aruba': -4,
            'America/Asuncion': -4,
            'America/Barbados': -4,
            'America/Blanc-Sablon': -4,
            'America/Boa_Vista': -4,
            'America/Campo_Grande': -4,
            'America/Cuiaba': -4,
            'America/Curacao': -4,
            'America/Dominica': -4,
            'America/Eirunepe': -4,
            'America/Glace_Bay': -4,
            'America/Goose_Bay': -4,
            'America/Grenada': -4,
            'America/Guadeloupe': -4,
            'America/Guyana': -4,
            'America/Halifax': -4,
            'America/Kralendijk': -4,
            'America/La_Paz': -4,
            'America/Lower_Princes': -4,
            'America/Manaus': -4,
            'America/Marigot': -4,
            'America/Martinique': -4,
            'America/Moncton': -4,
            'America/Montserrat': -4,
            'America/Port_of_Spain': -4,
            'America/Porto_Acre': -4,
            'America/Porto_Velho': -4,
            'America/Puerto_Rico': -4,
            'America/Rio_Branco': -4,
            'America/Santiago': -4,
            'America/Santo_Domingo': -4,
            'America/St_Barthelemy': -4,
            'America/St_Kitts': -4,
            'America/St_Lucia': -4,
            'America/St_Thomas': -4,
            'America/St_Vincent': -4,
            'America/Thule': -4,
            'America/Tortola': -4,
            'America/Virgin': -4,
            'Antarctica/Palmer': -4,
            'Atlantic/Bermuda': -4,
            'Atlantic/Stanley': -4,
            'Brazil/Acre': -4,
            'Brazil/West': -4,
            'Canada/Atlantic': -4,
            'Chile/Continental': -4,
            'Etc/GMT+4': -4,
            PRT: -4,
            'SystemV/AST4': -4,
            'SystemV/AST4ADT': -4,
            'America/St_Johns': -3.5,
            CNT: -3.5,
            'Canada/Newfoundland': -3.5,
            AGT: -3,
            'America/Araguaina': -3,
            'America/Argentina/Buenos_Aires': -3,
            'America/Argentina/Catamarca': -3,
            'America/Argentina/ComodRivadavia': -3,
            'America/Argentina/Cordoba': -3,
            'America/Argentina/Jujuy': -3,
            'America/Argentina/La_Rioja': -3,
            'America/Argentina/Mendoza': -3,
            'America/Argentina/Rio_Gallegos': -3,
            'America/Argentina/Salta': -3,
            'America/Argentina/San_Juan': -3,
            'America/Argentina/Tucuman': -3,
            'America/Argentina/Ushuaia': -3,
            'America/Bahia': -3,
            'America/Belem': -3,
            'America/Buenos_Aires': -3,
            'America/Catamarca': -3,
            'America/Cayenne': -3,
            'America/Cordoba': -3,
            'America/Fortaleza': -3,
            'America/Godthab': -3,
            'America/Jujuy': -3,
            'America/Maceio': -3,
            'America/Mendoza': -3,
            'America/Miquelon': -3,
            'America/Montevideo': -3,
            'America/Paramaribo': -3,
            'America/Recife': -3,
            'America/Rosario': -3,
            'America/Santarem': -3,
            'America/Sao_Paulo': -3,
            'Antarctica/Rothera': -3,
            BET: -3,
            'Brazil/East': -3,
            'Etc/GMT+3': -3,
            'America/Noronha': -2,
            'Atlantic/South_Georgia': -2,
            'Brazil/DeNoronha': -2,
            'Etc/GMT+2': -2,
            'America/Scoresbysund': -1,
            'Atlantic/Azores': -1,
            'Atlantic/Cape_Verde': -1,
            'Etc/GMT+1': -1,
            'Africa/Abidjan': 0,
            'Africa/Accra': 0,
            'Africa/Bamako': 0,
            'Africa/Banjul': 0,
            'Africa/Bissau': 0,
            'Africa/Casablanca': 0,
            'Africa/Conakry': 0,
            'Africa/Dakar': 0,
            'Africa/El_Aaiun': 0,
            'Africa/Freetown': 0,
            'Africa/Lome': 0,
            'Africa/Monrovia': 0,
            'Africa/Nouakchott': 0,
            'Africa/Ouagadougou': 0,
            'Africa/Sao_Tome': 0,
            'Africa/Timbuktu': 0,
            'America/Danmarkshavn': 0,
            'Atlantic/Canary': 0,
            'Atlantic/Faeroe': 0,
            'Atlantic/Faroe': 0,
            'Atlantic/Madeira': 0,
            'Atlantic/Reykjavik': 0,
            'Atlantic/St_Helena': 0,
            Eire: 0,
            'Etc/GMT': 0,
            'Etc/GMT+0': 0,
            'Etc/GMT-0': 0,
            'Etc/GMT0': 0,
            'Etc/Greenwich': 0,
            'Etc/UCT': 0,
            'Etc/UTC': 0,
            'Etc/Universal': 0,
            'Etc/Zulu': 0,
            'Europe/Belfast': 0,
            'Europe/Dublin': 0,
            'Europe/Guernsey': 0,
            'Europe/Isle_of_Man': 0,
            'Europe/Jersey': 0,
            'Europe/Lisbon': 0,
            'Europe/London': 0,
            GB: 0,
            'GB-Eire': 0,
            GMT: 0,
            GMT0: 0,
            Greenwich: 0,
            Iceland: 0,
            Portugal: 0,
            UCT: 0,
            UTC: 0,
            Universal: 0,
            WET: 0,
            Zulu: 0,
            'Africa/Algiers': 1,
            'Africa/Bangui': 1,
            'Africa/Brazzaville': 1,
            'Africa/Ceuta': 1,
            'Africa/Douala': 1,
            'Africa/Kinshasa': 1,
            'Africa/Lagos': 1,
            'Africa/Libreville': 1,
            'Africa/Luanda': 1,
            'Africa/Malabo': 1,
            'Africa/Ndjamena': 1,
            'Africa/Niamey': 1,
            'Africa/Porto-Novo': 1,
            'Africa/Tunis': 1,
            'Africa/Windhoek': 1,
            'Arctic/Longyearbyen': 1,
            'Atlantic/Jan_Mayen': 1,
            CET: 1,
            ECT: 1,
            'Etc/GMT-1': 1,
            'Europe/Amsterdam': 1,
            'Europe/Andorra': 1,
            'Europe/Belgrade': 1,
            'Europe/Berlin': 1,
            'Europe/Bratislava': 1,
            'Europe/Brussels': 1,
            'Europe/Budapest': 1,
            'Europe/Copenhagen': 1,
            'Europe/Gibraltar': 1,
            'Europe/Ljubljana': 1,
            'Europe/Luxembourg': 1,
            'Europe/Madrid': 1,
            'Europe/Malta': 1,
            'Europe/Monaco': 1,
            'Europe/Oslo': 1,
            'Europe/Paris': 1,
            'Europe/Podgorica': 1,
            'Europe/Prague': 1,
            'Europe/Rome': 1,
            'Europe/San_Marino': 1,
            'Europe/Sarajevo': 1,
            'Europe/Skopje': 1,
            'Europe/Stockholm': 1,
            'Europe/Tirane': 1,
            'Europe/Vaduz': 1,
            'Europe/Vatican': 1,
            'Europe/Vienna': 1,
            'Europe/Warsaw': 1,
            'Europe/Zagreb': 1,
            'Europe/Zurich': 1,
            MET: 1,
            Poland: 1,
            ART: 2,
            'Africa/Blantyre': 2,
            'Africa/Bujumbura': 2,
            'Africa/Cairo': 2,
            'Africa/Gaborone': 2,
            'Africa/Harare': 2,
            'Africa/Johannesburg': 2,
            'Africa/Kigali': 2,
            'Africa/Lubumbashi': 2,
            'Africa/Lusaka': 2,
            'Africa/Maputo': 2,
            'Africa/Maseru': 2,
            'Africa/Mbabane': 2,
            'Africa/Tripoli': 2,
            'Asia/Amman': 2,
            'Asia/Beirut': 2,
            'Asia/Damascus': 2,
            'Asia/Gaza': 2,
            'Asia/Hebron': 2,
            'Asia/Istanbul': 2,
            'Asia/Jerusalem': 2,
            'Asia/Nicosia': 2,
            'Asia/Tel_Aviv': 2,
            CAT: 2,
            EET: 2,
            Egypt: 2,
            'Etc/GMT-2': 2,
            'Europe/Athens': 2,
            'Europe/Bucharest': 2,
            'Europe/Chisinau': 2,
            'Europe/Helsinki': 2,
            'Europe/Istanbul': 2,
            'Europe/Kiev': 2,
            'Europe/Mariehamn': 2,
            'Europe/Nicosia': 2,
            'Europe/Riga': 2,
            'Europe/Simferopol': 2,
            'Europe/Sofia': 2,
            'Europe/Tallinn': 2,
            'Europe/Tiraspol': 2,
            'Europe/Uzhgorod': 2,
            'Europe/Vilnius': 2,
            'Europe/Zaporozhye': 2,
            Israel: 2,
            Libya: 2,
            Turkey: 2,
            'Africa/Addis_Ababa': 3,
            'Africa/Asmara': 3,
            'Africa/Asmera': 3,
            'Africa/Dar_es_Salaam': 3,
            'Africa/Djibouti': 3,
            'Africa/Juba': 3,
            'Africa/Kampala': 3,
            'Africa/Khartoum': 3,
            'Africa/Mogadishu': 3,
            'Africa/Nairobi': 3,
            'Antarctica/Syowa': 3,
            'Asia/Aden': 3,
            'Asia/Baghdad': 3,
            'Asia/Bahrain': 3,
            'Asia/Kuwait': 3,
            'Asia/Qatar': 3,
            'Asia/Riyadh': 3,
            EAT: 3,
            'Etc/GMT-3': 3,
            'Europe/Kaliningrad': 3,
            'Europe/Minsk': 3,
            'Indian/Antananarivo': 3,
            'Indian/Comoro': 3,
            'Indian/Mayotte': 3,
            'Asia/Riyadh87': 3.1177777778,
            'Asia/Riyadh88': 3.1177777778,
            'Asia/Riyadh89': 3.1177777778,
            'Mideast/Riyadh87': 3.1177777778,
            'Mideast/Riyadh88': 3.1177777778,
            'Mideast/Riyadh89': 3.1177777778,
            'Asia/Tehran': 3.5,
            Iran: 3.5,
            'Asia/Baku': 4,
            'Asia/Dubai': 4,
            'Asia/Muscat': 4,
            'Asia/Tbilisi': 4,
            'Asia/Yerevan': 4,
            'Etc/GMT-4': 4,
            'Europe/Moscow': 4,
            'Europe/Samara': 4,
            'Europe/Volgograd': 4,
            'Indian/Mahe': 4,
            'Indian/Mauritius': 4,
            'Indian/Reunion': 4,
            NET: 4,
            'W-SU': 4,
            'Asia/Kabul': 4.5,
            'Antarctica/Mawson': 5,
            'Asia/Aqtau': 5,
            'Asia/Aqtobe': 5,
            'Asia/Ashgabat': 5,
            'Asia/Ashkhabad': 5,
            'Asia/Dushanbe': 5,
            'Asia/Karachi': 5,
            'Asia/Oral': 5,
            'Asia/Samarkand': 5,
            'Asia/Tashkent': 5,
            'Etc/GMT-5': 5,
            'Indian/Kerguelen': 5,
            'Indian/Maldives': 5,
            PLT: 5,
            'Asia/Calcutta': 5.5,
            'Asia/Colombo': 5.5,
            'Asia/Kolkata': 5.5,
            IST: 5.5,
            'Asia/Kathmandu': 5.75,
            'Asia/Katmandu': 5.75,
            'Antarctica/Vostok': 6,
            'Asia/Almaty': 6,
            'Asia/Bishkek': 6,
            'Asia/Dacca': 6,
            'Asia/Dhaka': 6,
            'Asia/Qyzylorda': 6,
            'Asia/Thimbu': 6,
            'Asia/Thimphu': 6,
            'Asia/Yekaterinburg': 6,
            BST: 6,
            'Etc/GMT-6': 6,
            'Indian/Chagos': 6,
            'Asia/Rangoon': 6.5,
            'Indian/Cocos': 6.5,
            'Antarctica/Davis': 7,
            'Asia/Bangkok': 7,
            'Asia/Ho_Chi_Minh': 7,
            'Asia/Hovd': 7,
            'Asia/Jakarta': 7,
            'Asia/Novokuznetsk': 7,
            'Asia/Novosibirsk': 7,
            'Asia/Omsk': 7,
            'Asia/Phnom_Penh': 7,
            'Asia/Pontianak': 7,
            'Asia/Saigon': 7,
            'Asia/Vientiane': 7,
            'Etc/GMT-7': 7,
            'Indian/Christmas': 7,
            VST: 7,
            'Antarctica/Casey': 8,
            'Asia/Brunei': 8,
            'Asia/Choibalsan': 8,
            'Asia/Chongqing': 8,
            'Asia/Chungking': 8,
            'Asia/Harbin': 8,
            'Asia/Hong_Kong': 8,
            'Asia/Kashgar': 8,
            'Asia/Krasnoyarsk': 8,
            'Asia/Kuala_Lumpur': 8,
            'Asia/Kuching': 8,
            'Asia/Macao': 8,
            'Asia/Macau': 8,
            'Asia/Makassar': 8,
            'Asia/Manila': 8,
            'Asia/Shanghai': 8,
            'Asia/Singapore': 8,
            'Asia/Taipei': 8,
            'Asia/Ujung_Pandang': 8,
            'Asia/Ulaanbaatar': 8,
            'Asia/Ulan_Bator': 8,
            'Asia/Urumqi': 8,
            'Australia/Perth': 8,
            'Australia/West': 8,
            CTT: 8,
            'Etc/GMT-8': 8,
            Hongkong: 8,
            PRC: 8,
            Singapore: 8,
            'Australia/Eucla': 8.75,
            'Asia/Dili': 9,
            'Asia/Irkutsk': 9,
            'Asia/Jayapura': 9,
            'Asia/Pyongyang': 9,
            'Asia/Seoul': 9,
            'Asia/Tokyo': 9,
            'Etc/GMT-9': 9,
            JST: 9,
            Japan: 9,
            'Pacific/Palau': 9,
            ROK: 9,
            ACT: 9.5,
            'Australia/Adelaide': 9.5,
            'Australia/Broken_Hill': 9.5,
            'Australia/Darwin': 9.5,
            'Australia/North': 9.5,
            'Australia/South': 9.5,
            'Australia/Yancowinna': 9.5,
            AET: 10,
            'Antarctica/DumontDUrville': 10,
            'Asia/Yakutsk': 10,
            'Australia/ACT': 10,
            'Australia/Brisbane': 10,
            'Australia/Canberra': 10,
            'Australia/Currie': 10,
            'Australia/Hobart': 10,
            'Australia/Lindeman': 10,
            'Australia/Melbourne': 10,
            'Australia/NSW': 10,
            'Australia/Queensland': 10,
            'Australia/Sydney': 10,
            'Australia/Tasmania': 10,
            'Australia/Victoria': 10,
            'Etc/GMT-10': 10,
            'Pacific/Chuuk': 10,
            'Pacific/Guam': 10,
            'Pacific/Port_Moresby': 10,
            'Pacific/Saipan': 10,
            'Pacific/Truk': 10,
            'Pacific/Yap': 10,
            'Australia/LHI': 10.5,
            'Australia/Lord_Howe': 10.5,
            'Antarctica/Macquarie': 11,
            'Asia/Sakhalin': 11,
            'Asia/Vladivostok': 11,
            'Etc/GMT-11': 11,
            'Pacific/Efate': 11,
            'Pacific/Guadalcanal': 11,
            'Pacific/Kosrae': 11,
            'Pacific/Noumea': 11,
            'Pacific/Pohnpei': 11,
            'Pacific/Ponape': 11,
            SST: 11,
            'Pacific/Norfolk': 11.5,
            'Antarctica/McMurdo': 12,
            'Antarctica/South_Pole': 12,
            'Asia/Anadyr': 12,
            'Asia/Kamchatka': 12,
            'Asia/Magadan': 12,
            'Etc/GMT-12': 12,
            Kwajalein: 12,
            NST: 12,
            NZ: 12,
            'Pacific/Auckland': 12,
            'Pacific/Fiji': 12,
            'Pacific/Funafuti': 12,
            'Pacific/Kwajalein': 12,
            'Pacific/Majuro': 12,
            'Pacific/Nauru': 12,
            'Pacific/Tarawa': 12,
            'Pacific/Wake': 12,
            'Pacific/Wallis': 12,
            'NZ-CHAT': 12.75,
            'Pacific/Chatham': 12.75,
            'Etc/GMT-13': 13,
            MIT: 13,
            'Pacific/Apia': 13,
            'Pacific/Enderbury': 13,
            'Pacific/Tongatapu': 13,
            'Etc/GMT-14': 14,
            'Pacific/Kiritimati': 14
        };
}

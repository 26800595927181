// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class RulesSet {
    public static readonly ALLOWED = '1';
    public static readonly NOT_ALLOWED = '0';
    public static readonly ALLOWED_FOR_ALL = '$ALL$';

    // #region Rules constants

    public static readonly FUNCTION_SHOW_TIME_FOR_GTD = 'FUNCTION_SHOW_TIME_FOR_GTD';
    public static readonly VISIBILITY_INSTRUMENT_TYPE = 'VISIBILITY_INSTRUMENT_TYPE';
    public static readonly VISIBILITY_ROUTE = 'VISIBILITY_ROUTE';
    public static readonly FUNCTION_INSTRUMENT_PORTFOLIO = 'FUNCTION_INSTRUMENT_PORTFOLIO';
    public static readonly FUNCTION_ORDER_BOOK = 'FUNCTION_ORDER_BOOK';
    public static readonly FUNCTION_LEVEL2 = 'FUNCTION_LEVEL2';
    public static readonly FUNCTION_SUPER_DOM = 'FUNCTION_SUPER_DOM'; // Tv Trading Platform
    public static readonly FUNCTION_SCALPER = 'FUNCTION_SCALPER';
    public static readonly FUNCTION_OE2014 = 'FUNCTION_OE2014';
    public static readonly FUNCTION_CHART = 'FUNCTION_CHART';
    public static readonly FUNCTION_CHAT = 'FUNCTION_CHAT';
    public static readonly FUNCTION_NEWS = 'FUNCTION_NEWS';
    // public static readonly FUNCTION_RISK_CALCULATOR = "FUNCTION_RISK_CALCULATOR"; // TODO CHECK SERVER NAME
    public static readonly FUNCTION_SHOW_ORDERS = 'FUNCTION_SHOW_ORDERS';
    public static readonly FUNCTION_SHOW_POSITIONS = 'FUNCTION_SHOW_POSITIONS';
    public static readonly FUNCTION_ASSETS = 'FUNCTION_ASSETS';
    public static readonly FUNCTION_ALERT = 'FUNCTION_ALERT';
    public static readonly FUNCTION_ALERTS_EMAIL = 'FUNCTION_ALERTS_EMAIL';
    public static readonly FUNCTION_ALERTS_PUSH = 'FUNCTION_ALERTS_PUSH';
    public static readonly FUNCTION_ALERTS_PLACE_ORDER = 'FUNCTION_ALERTS_PLACE_ORDER';
    public static readonly VALUE_TOTAL_MAX_ALERTS = 'VALUE_TOTAL_MAX_ALERTS';
    public static readonly FUNCTION_ACCOUNTS = 'FUNCTION_ACCOUNTS';
    public static readonly FUNCTION_ACCOUNTPERFOMANCE = 'FUNCTION_ACCOUNTPERFOMANCE';
    public static readonly FUNCTION_WATCHLIST = 'FUNCTION_WATCHLIST';
    public static readonly FUNCTION_LEVEL3 = 'FUNCTION_LEVEL3';
    public static readonly FUNCTION_MATRIX = 'FUNCTION_MATRIX';
    public static readonly FUNCTION_EVENT_LOG = 'FUNCTION_EVENT_LOG';
    public static readonly FUNCTION_POSITION_BALANCE = 'FUNCTION_POSITION_BALANCE';
    public static readonly FUNCTION_CUR_EXP = 'FUNCTION_CUR_EXP';
    public static readonly FUNCTION_BUILDER = 'FUNCTION_BUILDER';
    public static readonly FUNCTION_BASKET = 'FUNCTION_BASKET';
    public static readonly FUNCTION_SCRIPT_BUILDER = 'FUNCTION_SCRIPT_BUILDER';
    public static readonly FUNCTION_SYMBOL_INFO = 'FUNCTION_SYMBOL_INFO';
    public static readonly FUNCTION_GRID = 'FUNCTION_GRID';
    public static readonly FUNCTION_OPTION_TRADER = 'FUNCTION_OPTION_TRADER';
    public static readonly FUNCTION_OPTION_TRADER_ANALYZER = 'FUNCTION_OPTION_TRADER_ANALYZER';
    public static readonly FUNCTION_OPTION_TRADER_VOLATILITY_LAB = 'FUNCTION_OPTION_TRADER_VOLATILITY_LAB';
    public static readonly FUNCTION_OPTION_TRADER_POSITIONS = 'FUNCTION_OPTION_TRADER_POSITIONS';
    public static readonly FUNCTION_OPTION_TRADER_WORKING_ORDERS = 'FUNCTION_OPTION_TRADER_WORKING_ORDERS';
    public static readonly FUNCTION_OPTION_TRADER_TEST_ON_PAPER = 'FUNCTION_OPTION_TRADER_TEST_ON_PAPER';
    public static readonly FUNCTION_OPTION_TRADER_STRATEGIES = 'FUNCTION_OPTION_TRADER_STRATEGIES';
    public static readonly FUNCTION_MARGIN_INFO = 'FUNCTION_MARGIN_INFO';
    public static readonly FUNCTION_HEATMAP = 'FUNCTION_HEATMAP';
    public static readonly SELF_TRADING = 'SELF_TRADING';
    public static readonly FUNCTION_TRADING = 'FUNCTION_TRADING';
    public static readonly FUNCTION_TRADING_CENTRAL = 'FUNCTION_TRADING_CENTRAL';
    public static readonly FUNCTION_TRADING_IDEAS = 'FUNCTION_MARKET_SIGNALS';
    public static readonly FUNCTION_REPORT = 'FUNCTION_REPORT';
    public static readonly FUNCTION_PRODUCTS = 'FUNCTION_PRODUCTS';
    public static readonly FUNCTION_MUTUAL_CLOSE = 'FUNCTION_MUTUAL_CLOSE';
    public static readonly FUNCTION_PARTIAL_CLOSE = 'FUNCTION_PARTIAL_CLOSE';
    public static readonly FUNCTION_SLTP = 'FUNCTION_SLTP';
    public static readonly FUNCTION_TRAILING_STOP = 'FUNCTION_TRAILING_STOP';
    public static readonly FUNCTION_BINDEDORDERS = 'FUNCTION_BINDEDORDERS';
    public static readonly FUNCTION_TRADES = 'FUNCTION_TRADES';
    public static readonly FUNCTION_MODIFY_ORDERTYPE = 'FUNCTION_MODIFY_ORDERTYPE';
    public static readonly FUNCTION_MODIFY_AMOUNT = 'FUNCTION_MODIFY_AMOUNT';
    public static readonly FUNCTION_MODIFY_PRICE = 'FUNCTION_MODIFY_PRICE';
    public static readonly FUNCTION_MODIFY_TIF = 'FUNCTION_MODIFY_TIF';
    public static readonly FUNCTION_AUTO_POPULATE_WATCHLIST = 'FUNCTION_AUTO_POPULATE_WATCHLIST';
    public static readonly FUNCTION_ADVANCED_OCO_MODE = 'FUNCTION_ADVANCED_OCO_MODE';
    public static readonly FUNCTION_TRAILING_SLTP_INOFFSET = 'FUNCTION_TRAILING_SLTP_INOFFSET';
    public static readonly FUNCTION_DONT_USE_ROUTE_SETTING_FOR_CLOSE_TS_ORDER = 'FUNCTION_DONT_USE_ROUTE_SETTING_FOR_CLOSE_TS_ORDER';
    public static readonly FUNCTION_UPDATE_TRAILING_STOP_ON_NEWQUOTE = 'FUNCTION_UPDATE_TRAILING_STOP_ON_NEWQUOTE';
    public static readonly FUNCTION_USE_ONE_ROUTE = 'FUNCTION_USE_ONE_ROUTE';
    public static readonly FUNCTION_BASE_CURRENCY = 'FUNCTION_BASE_CURRENCY';
    public static readonly FUNCTION_ALLOW_ORDERS_HISTORY = 'FUNCTION_ALLOW_ORDERS_HISTORY';
    public static readonly FUNCTION_ALLOW_TRADES_HISTORY = 'FUNCTION_ALLOW_TRADES_HISTORY';
    public static readonly VALUE_EXPOSITION_IN_IMSTRUMENT_CURRENCY = 'VALUE_EXPOSITION_IN_IMSTRUMENT_CURRENCY';
    public static readonly FUNCTION_NON_FIXED_LIST = 'FUNCTION_NON_FIXED_LIST';
    public static readonly VALUE_THROTLING_PANEL_LIMIT_AGGRESSOR_BALANCE = 'VALUE_THROTLING_PANEL_LIMIT_AGGRESSOR_BALANCE_COUNT';
    public static readonly VALUE_THROTLING_PANEL_LIMIT_CHART_COUNT = 'VALUE_THROTLING_PANEL_LIMIT_CHART_COUNT';
    public static readonly VALUE_THROTLING_PANEL_LIMIT_MARKETDEPTH_COUNT = 'VALUE_THROTLING_PANEL_LIMIT_MARKETDEPTH_COUNT';
    public static readonly VALUE_THROTLING_PANEL_LIMIT_TIMEANDSALES_COUNT = 'VALUE_THROTLING_PANEL_LIMIT_TIMEANDSALES_COUNT';
    public static readonly VALUE_THROTLING_PANEL_LIMIT_WATCHLIST_COUNT = 'VALUE_THROTLING_PANEL_LIMIT_WATCHLIST_COUNT';
    public static readonly VALUE_THROTLING_PANEL_LIMIT_WATCHLIST_ROWS = 'VALUE_THROTLING_PANEL_LIMIT_WATCHLIST_ROWS';
    public static readonly VALUE_THROTLING_PANEL_LIMIT_SAVEDORDERS_COUNT = 'VALUE_THROTLING_PANEL_LIMIT_SAVEDORDERS_COUNT';
    public static readonly VALUE_THROTLING_PANEL_LIMIT_SAVEDORDERS_ROWS = 'VALUE_THROTLING_PANEL_LIMIT_SAVEDORDERS_ROWS';
    public static readonly VALUE_THROTLING_PANEL_LIMIT_GRID_COUNT = 'VALUE_THROTLING_PANEL_LIMIT_GRID_COUNT';
    public static readonly VALUE_THROTLING_PANEL_LIMIT_OPTIONMASTER_COUNT = 'VALUE_THROTLING_PANEL_LIMIT_OPTIONMASTER_COUNT';
    public static readonly VALUE_PANEL_LIMIT_CHART_TV_COUNT = 'VALUE_PANEL_LIMIT_CHART_TV_COUNT';
    public static readonly FUNCTION_USE_PRODUCT_TYPE = 'FUNCTION_USE_PRODUCT_TYPE';
    public static readonly VALUE_DAILY_PROFIT_TARGET = 'VALUE_DAILY_PROFIT_TARGET';
    public static readonly VALUE_INSTRUMENT_TYPE_LIMIT = 'VALUE_INSTRUMENT_TYPE_LIMIT';
    public static readonly ALLOW_CHANGE_PASSWORD = 'ALLOW_CHANGE_PASSWORD';
    public static readonly FUNCTION_RESERVER_WITHDRAWAL = 'FUNCTION_RESERVER_WITHDRAWAL';
    public static readonly FUNCTION_PORTFOLIO_MANAGEMENT = 'FUNCTION_PORTFOLIO_MANAGEMENT';
    public static readonly FUNCTION_PIE = 'FUNCTION_PIE';
    public static readonly FUNCTION_DISPLAY_FEES_ON_CONFIRMATIONS = 'FUNCTION_DISPLAY_FEES_ON_CONFIRMATIONS';
    // список POA пользователей для клента
    public static readonly VALUE_POWER_OF_ATTORNEY = 'VALUE_POWER_OF_ATTORNEY';
    /// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    public static readonly FUNCTION_ALERTS = 'FUNCTION_ALERTS';
    public static readonly VALUE_RISK_ID = 'VALUE_RISK_ID';

    /// <summary>
    /// not allow close positions manualy, only orders used(for stocks mostly)
    /// </summary>
    // [RulesDecomposer(RulesDecomposer.AllAccounts)]
    public static readonly FUNCTION_CLOSE_POSITIONS_DISABLE = 'FUNCTION_CLOSE_POSITIONS_DISABLE';
    // [RulesDecomposer(RulesDecomposer.MyUser)]
    public static readonly TERMINAL_HTML = 'TERMINAL_HTML';
    /// <summary>
    /// Fifo positions (заведено под интеграции)
    /// </summary>
    // [RulesDecomposer(RulesDecomposer.MyUser)]
    public static readonly FUNCTION_FIFO_POSITIONS = 'FUNCTION_FIFO_POSITIONS';
    // [RulesDecomposer(RulesDecomposer.MyUser)]
    public static readonly FUNCTION_CHART_VOLUME_BARS = 'FUNCTION_CHART_VOLUME_BARS';

    public static readonly FUNCTION_USER_ALLOW_CUSTOM_SLTP_TRIGGER_PRICE = 'FUNCTION_USER_ALLOW_CUSTOM_SLTP_TRIGGER_PRICE'; // #109798
    public static readonly ALLOW_CHANGES_LOGGING = 'ALLOW_CHANGES_LOGGING'; // #111072

    public static readonly FUNCTION_POSITIONSADMIN = 'FUNCTION_POSITIONSADMIN';
    public static readonly FUNCTION_LOCKED_BY_BROKER = 'FUNCTION_LOCKED_BY_BROKER';

    // #endregion

    public static readonly FUNCTION_USE_ONE_TIME_PASSWORD = 'USE_ONE-TIME_PASSWORD';

    public static readonly FUNCTION_PAMM_ACCOUNTS = 'FUNCTION_PAMM_ACCOUNTS';
    public static readonly FUNCTION_SLAVE = 'FUNCTION_SLAVE';
    public static readonly FUNCTION_EXT_RISK = 'FUNCTION_EXT_RISK';
    public static readonly FUNCTION_TRADING_OUT_OF_SESSION = 'FUNCTION_TRADING_OUT_OF_SESSION';

    /// Максимальное количество ордеров в день
    public static readonly VALUE_MAX_DAY_ORDERS = 'VALUE_MAX_DAY_ORDERS';

    // Доступность калькулятора Position Sizing в Order Entry
    public static readonly FUNCTION_POSITION_SIZING = 'FUNCTION_POSITION_SIZING';
    // for Get Snapshot #100287
    public static readonly AVAILABLE_NUMBER_OF_SNAPSHOT_REQUESTS = 'AVAILABLE_NUMBER_OF_SNAPSHOT_REQUESTS';
    public static readonly SNAPSHOT_REQUESTS_LIMIT = 'SNAPSHOT_REQUESTS_LIMIT';
    public static readonly VALUE_MARKET_DATA_SUBSCRIBER_STATUS = 'VALUE_MARKET_DATA_SUBSCRIBER_STATUS';

    /// <summary>
    /// # 103539 Send Trailing stop price
    /// </summary>
    public static readonly FUNCTION_TRAILING_STOP_BY_PRICE = 'FUNCTION_TRAILING_STOP_BY_PRICE';

    /// <summary>
    /// #101641
    /// </summary>
    public static readonly FUNCTION_TRADING_MODE_MODIFICATION = 'FUNCTION_TRADING_MODE_MODIFICATION';

    /// <summary>
    /// #92710
    /// </summary>
    public static readonly VALIDATE_MAX_LOT_CLOSE_ORDER = 'VALIDATE_MAX_LOT_CLOSE_ORDER';

    // #108200 -> https://tp.traderevolution.com/entity/107651
    public static readonly PROTECTIVE_ORDERS = 'PROTECTIVE_ORDERS';
    // AlgorithmMarketConsensus
    public static readonly FUNCTION_CONSENSUS_ALGORITHM = 'FUNCTION_CONSENSUS_ALGORITHM';

    public static readonly FUNCTION_GAINERS_LOSERS_ALGORITHM = 'FUNCTION_GAINERS_LOSERS_ALGORITHM'; // #118023

    public static readonly FUNCTION_HIGH_LOW_ALGORITHM = 'FUNCTION_HIGH_LOW_ALGORITHM'; // #118026

    public static readonly FUNCTION_NOTIFICATIONS = 'FUNCTION_NOTIFICATIONS'; // #110710 <- #110484

    public static readonly FUNCTION_ORDERS_WITH_NEGATIVE_PRICE = 'FUNCTION_ORDERS_WITH_NEGATIVE_PRICE'; // #109599 поки що не як не використовуємо, додав заради інформативності

    public static readonly FUNCTION_PASSWORD_BLACKLIST_CHECK = 'FUNCTION_PASSWORD_BLACKLIST_CHECK'; // #112524 -> #113076

    public static readonly FUNCTION_VIDEO = 'FUNCTION_VIDEO'; // #114288

    public static readonly FUNCTION_DISPLAY_RESULTS = 'FUNCTION_DISPLAY_RESULTS'; // #112617

    public static readonly FUNCTION_MANAGED_STATISTICS = 'FUNCTION_MANAGED_STATISTICS'; // #112617

    public static readonly FUNCTION_TRADINGVIEW_CHART = 'FUNCTION_CHART_TV'; // #116014

    public static readonly FUNCTION_CLOSE_ACCOUNT = 'FUNCTION_CLOSE_ACCOUNT'; // #116085
    public static readonly FUNCTION_INVESTING_CLOSE_ACCOUNT = 'FUNCTION_INVESTING_CLOSE_ACCOUNT'; // #118106

    public static readonly ACCOUNT_LINKED_WORKSPACE = 'ACCOUNT_LINKED_WORKSPACE'; // #118235 -> #119101

    public static readonly FUNCTION_STATEMENT_PANEL = 'FUNCTION_STATEMENT_PANEL'; // #119782

    public static readonly USER_SESSION_TIMEOUT = 'USER_SESSION_TIMEOUT';

    public static readonly LEVERAGE_USAGE_WARN = 'LEVERAGE_USAGE_WARN';
}

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum AccountFeature {
    Balance = 0,
    BlockedBalance = 1,
    ProjectedBalance = 2,
    MarginUsedPercent = 3,
    RiskLevelPercent = 4,
    MarginWarningLevel = 5,
    AvailableFunds = 6,
    WarningMargin = 7,
    TotalMaintReq = 8,
    WithdrawalAvailable = 9,
    CashBalance = 10,
    MarginUsed = 11,
    BalancePlusAllRisks = 12,
    MarginAvailable = 13,
    CreditValue = 14,
    EstimateValue = 15,
    InterestRatePercent = 16,
    BlockedForStocks = 17,
    UnsettledCash = 18,
    StockValue = 19,
    StocksOrdersReq = 20,
    OptionValue = 21,
    StocksLiquidity = 22,
    // VolumeLimitForEquities = 23, // reserved for VIP AccountFeature (replaced with VolumeLimitForEQUITIES: 42)
    // VolumeLimitForFutures = 24,  // вакантне місце
    // VolumeLimitForOptions = 25,  // свободная касса
    PowerOfAttorney = 26,
    WarnMarginReq = 27,
    WarnMarginReqPercent = 28,
    MarginBeforeWarning = 29,
    InitialMarginWithoutWaived = 30,
    BuyingPower = 31,
    DayTraderPatternProtection = 32,
    AvailableDayTrades = 33,
    GivenLeverage = 34,
    OpenBalance = 35,
    MaxRelativeDrawDownLevel = 36,
    StopOut = 37,
    LossLimitPerTrade = 38,
    IncomingFunds = 39,
    VolumeLimitForALL = 40, // #112150,112966
    VolumeLimitForFOREX = 41,
    VolumeLimitForEQUITIES = 42,
    VolumeLimitForFUTURES = 43,
    VolumeLimitForOPTIONS = 44,
    VolumeLimitForEQUITIES_CFD = 45,
    VolumeLimitForFORWARD = 46,
    VolumeLimitForCFD_FUTURES = 47,
    VolumeLimitForINDICIES = 48,
    VolumeLimitForCRYPTO = 49,
    VolumeLimitForSPREADBET = 50,
    VolumeLimitForBOND = 51,
    VolumeLimitForETF = 52,
    VolumeLimitForTBILL = 53,
    VolumeLimitForSPOT = 54,
    VolumeLimitForCORPORATE = 55,
    OpenNetPL = 56,
    WaivedMargin = 57,
    OptionPremiumReq = 58,
    OpenGrossPL = 59,
    OpenPostionsNumber = 61,
    OpenOrdersNumber = 62,
    TodaysNetProfit = 63,
    TodaysFee = 64,
    TodayGrossPNL = 65,
    TodayVolume = 66,
    TodayTrades = 67,
    EODTrailingDrawdownLevel = 68,
    TodaysPnLPercent = 69,
    RelativeDailyLossLimit = 70,
    LiveLeverage = 71,
    AvailableCash = 72,
    TotalPositionsValue = 73,
    CurrentDailyLoss = 74,
}

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DateFormater } from './DateFormater';

export class DateTimeUtils {
    public static DateTimeUtcNow (): Date {
        if (!DateTimeUtils._TimeNow) {
            DateTimeUtils._TimeNow = new Date();
        }

        return DateTimeUtils._TimeNow;
    }

    public static DateTimeNow (): Date {
        if (!DateTimeUtils._TimeNow) {
            DateTimeUtils._TimeNow = new Date();
        }

        return DateTimeUtils._TimeNow;
    }

    public static DateTimeUtcNowTicks (): number {
        if (!DateTimeUtils._TimeNowTicks) {
            DateTimeUtils._TimeNow = new Date();
            DateTimeUtils._TimeNowTicks = +DateTimeUtils._TimeNow;
        }

        return DateTimeUtils._TimeNowTicks;
    }

    public static SetDateTimeNow (): void {
        DateTimeUtils._TimeNow = new Date();
        DateTimeUtils._TimeNowTicks = +DateTimeUtils._TimeNow;
    }

    public static _TimeNow: Date | null = null;
    public static _TimeNowTicks: number | null = null;
    public static _ZeroTime = new Date(0);

    public static TIME_UTC_OFFSET = new Date().getTimezoneOffset() * 60000;

    public static DateTimeToUTC (dt): Date {
        return new Date(dt.getTime() + DateTimeUtils.TIME_UTC_OFFSET);
    }

    public static toLocalUnixMillis (utcUnixMillis): number {
        return utcUnixMillis + (-1 * DateTimeUtils.TIME_UTC_OFFSET);
    }

    public static dateFormater = new DateFormater();

    public static formatDate (date, format): string {
        return this.dateFormater.formatMoment(date, format);
    }

    public static convertTicksToHHMMSS (ticks): string // ticks -> HH:MM:SS
    {
        const difTime = new Date(ticks);
        const utsShiftTime = difTime.getTimezoneOffset() * 60000;
        const resultTime = new Date(ticks + utsShiftTime);
        return DateTimeUtils.formatDate(resultTime, 'HH:mm:ss');
    }

    public static LeapYear (year): boolean {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    }

    // TODO. Refactor.
    public static FormatToTime (date, hideSeconds?): string {
        return DateTimeUtils.formatDate(
            date,
            hideSeconds ? NewDateFormater.TimeHideSecondsFormat : NewDateFormater.TimeFormat);
    }

    public static FormatToDate (date): string {
        return DateTimeUtils.formatDate(date, NewDateFormater.DateFormat);
    }

    public static FormatToDateAndTime (date): string {
        return DateTimeUtils.formatDate(date, NewDateFormater.TimeAndDateFormat);
    }

    public static getDateFromYYYYMMDD (dateS): Date // 'YYYYMMDD' -> Date (object)
    {
        const year = dateS.substr(0, 4);
        const month = dateS.substr(4, 2);
        const day = dateS.substr(6, 2);

        const res = new Date();
        res.setFullYear(year);
        res.setMonth(month - 1);
        res.setDate(day);

        return res;
    }

    public static IsCurrentTimeInInterval (startTimeTicks: number, endTimeTicks: number): boolean {
        const currentTime = Date.now();
        return startTimeTicks <= currentTime && endTimeTicks >= currentTime;
    }

    public static getDateWithoutTime (date: Date): Date {
        date.setHours(0, 0, 0, 0);

        return date;
    }

    public static StartDateTimeJavascriptYear = 1970;
}

export class NewDateFormater {
    public static readonly TimeFormat = 'HH:mm:ss';
    public static readonly TimeHideSecondsFormat = 'HH:mm';
    public static readonly DateFormat = 'DD.MM.YYYY';
    public static readonly TimeAndDateFormat = 'DD.MM.YYYY HH:mm:ss';
}

export async function delay (ms: number): Promise<void> {
    await new Promise(resolve => setTimeout(resolve, ms));
}

import { RevenueCommissionType } from '../../Utils/Enums/Constants';
import { DataCache } from '../DataCache';

export class WithdrawalCalculator {
    public static CalculateFeeByWithdraw (account, commissionPlan, amountValue): number {
        if (!commissionPlan) {
            return 0;
        }

        if (commissionPlan.WithdrawalCommisionType === RevenueCommissionType.None) {
            return 0;
        }

        const selectedAccount = account;
        const asset = DataCache.GetAssetById(commissionPlan.WithdrawalCurrencyId);
        const crossRate = asset !== null ? DataCache.CrossRateCache.GetCrossPriceExp1Exp2(asset.Name, selectedAccount.BaseCurrency) : 1;

        let fee = 0;
        if (commissionPlan.WithdrawalCommisionType === RevenueCommissionType.FixedFee) {
            fee = commissionPlan.WithdrawalFixedFee * crossRate;
        } else {
            let percentageFee = commissionPlan.WithdrawalPercentage * 0.01 * amountValue;
            if (commissionPlan.WithdrawalCommisionType === RevenueCommissionType.FixedAndPercentage) {
                percentageFee += commissionPlan.WithdrawalFixedFee * crossRate;
            }

            fee = Math.max(percentageFee, commissionPlan.WithdrawalMinFee * crossRate);

            if (commissionPlan.WithdrawalMaxFee !== 0) {
                fee = Math.min(fee, commissionPlan.WithdrawalMaxFee * crossRate);
            }
        }

        return fee;
    }

    public static GetAmountByRemain (account, commissionPlan, withdrawalAvailable, remainValue): number {
        if (!commissionPlan) {
            return 0;
        }

        let amountResult = withdrawalAvailable - remainValue;
        if (commissionPlan.WithdrawalCommisionType === RevenueCommissionType.None) {
            return amountResult;
        }

        const selectedAccount = account;
        const asset = DataCache.GetAssetById(commissionPlan.WithdrawalCurrencyId);
        const crossRate = asset != null ? DataCache.CrossRateCache.GetCrossPriceExp1Exp2(asset.Name, selectedAccount.BaseCurrency) : 1;

        let fixedFee = 0;
        if (commissionPlan.WithdrawalCommisionType === RevenueCommissionType.FixedFee || commissionPlan.WithdrawalCommisionType === RevenueCommissionType.FixedAndPercentage) {
            fixedFee = commissionPlan.WithdrawalFixedFee * crossRate;
        }

        let percentageFee = commissionPlan.WithdrawalPercentage * 0.01;
        if (commissionPlan.WithdrawalCommisionType !== RevenueCommissionType.Percentage && commissionPlan.WithdrawalCommisionType !== RevenueCommissionType.FixedAndPercentage) {
        // для надежности вдруг сервер пришлет не ноль
            percentageFee = 0;
        }

        amountResult = (amountResult - fixedFee) / (1 + percentageFee);

        const fee = amountResult * percentageFee + fixedFee * crossRate;
        const maxFee = commissionPlan.WithdrawalMaxFee * crossRate;
        const minFee = commissionPlan.WithdrawalMinFee * crossRate;

        if (fee < minFee) {
            amountResult = withdrawalAvailable - remainValue - minFee;
        } else
            if (maxFee !== 0 && fee > maxFee) {
                amountResult = withdrawalAvailable - remainValue - maxFee;
            }

        return amountResult;
    }
}

// Don't rename properties of classes as they are used in serialization/deserialization
export class WorkspaceInfoResult {
    public active_ws_id: string | undefined;
    public ws_data_array: WorkspaceDataInfo[];
}

export class WorkspaceDataInfo {
    public ws_id: string | undefined = '';
    public name: string = '';
    public updateTimeSpan: number = 0;
}

export class WorkspaceData {
    public ws_id: string;
    public isactive: boolean;
    public name?: string;
    public panels_amount: number;
    public locked?: boolean;
    public data: any;
    public tools: any;
    public updateTimeSpan?: number;
}

export class GetWorkspaceData extends WorkspaceData {
    public ws_id: string | undefined;
    public isactive: boolean;
}

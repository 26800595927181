// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export const pl =
{
    'accountDetailsWidget.DropdownButton': 'Rachunek',
    'accountDetailsWidget.DropdownButton.Tooltip': 'Rachunek',
    'additional.verification': 'Wymagana dodatkowa autoryzacja kodem SMS',
    'AdditionalProperty.Message': 'Wiadomość',
    'AdditionalProperty.Position closed because the loss limit was reached': 'Zamknięto pozycję, ponieważ przekroczono limit maksymalnej straty',
    'AdditionalProperty.enum_StopOutType_AutoClose': 'Zamknięcie automatyczne',
    'AdditionalProperty.enum_StopOutType_CloseAllByInstruments': 'Zamknij wszystkie pozycje na danym instrumencie',
    'AdditionalProperty.enum_StopOutType_CloseAllPositions': 'Zamknij wszystkie pozycje',
    'AdditionalProperty.enum_StopOutType_CloseLagestLoss': 'Zamknij wszystkie pozycje (największe straty jako pierwsze)',
    'AdditionalProperty.enum_StopOutType_ClosePositionsByMarginExcess': 'Close positions by margin excess',
    'AdditionalProperty.enum_StopOutType_DoNotClosePositions': 'Nie zamykaj pozycji',
    'AdditionalProperty.enum_StopOutType_ExternalLossLimit': 'Daily loss limit',
    'AdditionalProperty.enum_StopOutType_FIFO': 'Zamknij wszystkie pozycje (FIFO)',
    'AdditionalProperty.enum_StopOutType_LargestFirst': 'Zamknij wszystkie pozycje (największe wolumeny jako pierwsze)',
    'AdditionalProperty.enum_StopOutType_LIFO': 'Zamknij wszystkie pozycje (LIFO)',
    'AdditionalProperty.User': 'Login',
    'AdditionalProperty.Account': 'Rachunek',
    'AdditionalProperty.Projected balance': 'Saldo bieżące',
    'AdditionalProperty.Margin used': 'Depozyt utrzymania',
    'AdditionalProperty.Time': 'Czas',
    'AdditionalProperty.MarginCallMessage.Message': 'Uzupełnij środki na rachunku!',
    'AdditionalProperty.StopOutType': 'Typ Stop out',
    'AdditionalProperty.MarginCallMessage.header.MarginWarning': 'Margin Call',
    'AdditionalProperty.MarginCallMessage.header.MarginCallReached': 'Osiągnięto poziom Stop Out',
    'AdditionalProperty.Reverse': 'Reverse',
    Alligator: 'Alligator',
    'Average Directional Index': 'Average directional index',
    'Average True Range': 'Average true range',
    'Bollinger Band': 'Bollinger Band',
    'Bollinger Bands': 'Bollinger bands',
    'bonds.couponCycle.1m': '1 miesiąc',
    'bonds.couponCycle.1y': '1 rok',
    'bonds.couponCycle.3m': '3 miesiące',
    'bonds.couponCycle.6m': '6 miesięcy',
    'BrokerMessage.Cancel': 'Usuń',
    'BrokerMessage.OK': 'OK',
    'BrokerMessage.Text': 'Wiadomość od Brokera',
    'BusinessRejectMessage.28': 'Nieprawidłowe hasło - spróbuj ponownie.',
    'BusinessRejectMessage.31': 'Nieprawidłowe nowe hasło: nie możesz użyć obecnego hasła',
    'BusinessRejectMessage.201': 'Nowe hasło zawiera niedozwolone znaki',
    'BusinessRejectMessage.202': 'Hasło i Login nie mogą być takie same',
    'BusinessRejectMessage.203': 'Wpisz nowe hasło',
    'BusinessRejectMessage.221': 'Nieprawidłowe nowe hasło: hasło musi zawierać co najmniej jedną liczbę i jedną dużą literę',
    'BusinessRejectMessage.222': 'Nieprawidłowe nowe hasło: hasło musi zawierać co najmniej osiem liter/cyfr',
    'BusinessRejectMessage.223': 'Nieprawidłowe nowe hasło: hasło musi zawierać co najmniej osiem znaków w tym:litery, liczby i znaki specjalne',
    'BusinessRejectMessage.338': 'Nie można odblokować hasła handlowego',
    'BusinessRejectMessage.339': 'Nieprawidłowe hasło handlowe',
    'BusinessRejectMessage.453': 'Zlecenie zostało odrzucone z powodu ujemnej ceny.',
    'BusinessRejectMessage.461': 'Powód odrzucenia: Nie wprowadzono żadnych zmian.',
    'reports.Reason for rejection: Nothing to change.': 'Powód odrzucenia: Nie wprowadzono żadnych zmian.',
    'Chande Momentum Oscillator': 'Chande Momentum oscillator',
    'chart.agregationType.Button': 'Okres',
    'chart.agregationType.Button.ToolTip': 'Użyj domyślnych okresów',
    'chart.AllowedPeriods.15M': '15m',
    'chart.AllowedPeriods.1D': '1D',
    'chart.AllowedPeriods.1H': '1H',
    'chart.AllowedPeriods.1M': '1m',
    'chart.AllowedPeriods.1S': '1S',
    'chart.AllowedPeriods.1T': '1 tick',
    'chart.AllowedPeriods.1W': '1W',
    'chart.AllowedPeriods.1Y': '1Y',
    'chart.AllowedPeriods.30M': '30m',
    'chart.AllowedPeriods.4H': '4H',
    'chart.AllowedPeriods.5M': '5m',
    'chart.AllowedPeriods.Month': '1M',
    'chart.AllowedPeriods.Range': 'Zakres',
    'chart.AllowedPeriods.Ticks': 'Tick',
    'chart.ChartPanel.accountLookup.ToolTip': 'Wybierz rachunek',
    'chart.ChartPanel.symbolLookup.ToolTip': 'Wybierz instrument',
    'chart.chartScroll.toBegin': 'Do ostatniego kwotowania',
    'chart.chartScroll.zoomInButton': 'Przybliż',
    'chart.chartScroll.zoomOutButton': 'Oddal',
    'chart.ChartSymbolSelector.addIndicator.ButtonToolTip': 'Dodaj wskaźnik',
    'chart.ChartSymbolSelector.drawingButton': 'Narzędzia rysowania',
    'chart.ChartSymbolSelector.drawingButton.ToolTip': 'Pokaż/ukryj narzędzia rysowania',
    'chart.ChartSymbolSelector.mouseTradeButton': 'Handel przy użyciu myszy',
    'chart.ChartSymbolSelector.mouseTradeButton.ToolTip': 'Handel przy użyciu myszy',
    'chart.ChartSymbolSelector.oeButton': 'Złóż zlecenie',
    'chart.ChartSymbolSelector.oeButton.ToolTip': 'Handel z wykresu',
    'chart.ChartSymbolSelector.OverlayTooltip': 'Dodaj warstwę',
    'Chart.chartWindow.auto': 'Auto',
    'Chart.chartWindow.collapsed': 'Zminimalizowane',
    'Chart.chartWindow.manual': 'Manual',
    'chart.chartWindowManager.SetAuto': 'Przełącz na skalę automatyczną',
    'chart.chartWindowManager.SetManual': 'Przełącz na skalę manualną',
    'chart.head.addOverlay': 'Dodaj warstwę...',
    'chart.head.charttype': 'Typ wykresu',
    'chart.head.line': 'Narzędzia do rysowania',
    'chart.head.overlayRemoveAll': 'Usuń wszystkie',
    'chart.head.overlays': 'Warstwy',
    'chart.indicator': 'Wskaźnik',
    'chart.indicatorAdd': 'Dodaj wskaźnik',
    'chart.indicatorRemoveAll': 'Usuń wszystkie',
    'chart.indicators': 'Wskaźniki',
    'chart.Infoline.days': 'dni',
    'chart.Infoline.hours': 'godzin',
    'chart.Infoline.min': 'minut',
    'chart.Infoline.price': 'cena',
    'chart.Infoline.sec': 'sekund',
    'chart.Infoline.time': 'czas',
    'chart.infoWindow.Items.Ask': 'Ask:',
    'chart.infoWindow.Items.Bars': '#Słupek:',
    'chart.infoWindow.Items.Bid': 'Bid:',
    'chart.infoWindow.Items.Change': 'Zmiana,%',
    'chart.infoWindow.Items.Close': 'Zamknięcie:',
    'chart.infoWindow.Items.Date': 'Data:',
    'chart.infoWindow.Items.High': 'Max:',
    'chart.infoWindow.Items.Low': 'Min:',
    'chart.infoWindow.Items.Open': 'Otwarcie:',
    'chart.infoWindow.Items.Ticks': 'Tick:',
    'chart.infoWindow.Items.Time': 'Czas:',
    'chart.infoWindow.Items.Volume': 'Wolumen:',
    'chart.instrumentsLookup': 'Instrumenty',
    'chart.layerManager': 'Ustawienia',
    'chart.loading': 'Ładowanie danych',
    'chart.loadingData': 'Ładowanie danych',
    'chart.mainlayer.gridPrice': 'Linie ceny',
    'chart.mainlayer.gridTime': 'Linie czasu',
    'chart.mainlayer.volume': 'Wolumen',
    'chart.menu.cancelByAccount': 'Usuń wszystkie dla rachunku',
    'chart.menu.cancelByInstrument': 'Usuń wszystkie dla instrumentu',
    'chart.menu.cancelOrder': 'Usuń zlecenie',
    'chart.menu.close': 'Zamknij pozycję',
    'chart.menu.closeall': 'Zamknij wszystkie pozycje',
    'chart.menu.InfoWindowDock': 'Dokuj',
    'chart.menu.InfoWindowHide': 'Ukryj',
    'chart.menu.InfoWindowSettings': 'Ustawienia',
    'chart.menu.InfoWindowShortMode': 'Short mode',
    'chart.menu.modify': 'Modyfikuj',
    'chart.menu.PlateIndicatorDublicate': 'Duplikuj wskaźnik',
    'chart.menu.PlateIndicatorRemove': 'Zamknij',
    'chart.menu.PlateIndicatorSettings': 'Ustawienia',
    'chart.menu.PlateIndicatorVisible': 'Widoczny',
    'chart.menu.PlateOverlay.Overlay': 'Warstwa',
    'chart.menu.PlateOverlayRemove': 'Usuń',
    'chart.menu.PlateOverlaySettings': 'Ustawienia',
    'chart.menu.PlateOverlayVisible': 'Widoczny',
    'chart.menu.rollback': 'Storno pozycji',
    'chart.noAvailableData': 'Brak danych do wyświetlenia',
    'chart.NoData': 'Brak danych',
    'chart.priceScaleContextMenu.AutoScale': 'Auto',
    'chart.priceScaleContextMenu.FitAlerts': 'Dopasuj alarmy',
    'chart.priceScaleContextMenu.FitDay': 'Dopasuj dzienne Min/Max',
    'chart.priceScaleContextMenu.FitDrawings': 'Dopasuj rysunki',
    'chart.priceScaleContextMenu.FitIndicators': 'Dopasuj wskaźniki',
    'chart.priceScaleContextMenu.FitOrdersPositions': 'Dopasuj zlecenia/pozycje',
    'chart.priceScaleContextMenu.ManualScale': 'Skala manualna',
    'chart.refresh': 'Odśwież',
    'chart.Remove': 'Usuń',
    'chart.shortPeriodName.m': 'm',
    'chart.shortPeriodName.ticks': 'tick',
    'chart.ShowHighLow.Close': 'Zamknięcie',
    'chart.ShowHighLow.High': 'Max',
    'chart.ShowHighLow.Low': 'Min',
    'chart.StyleButton.ToolTip': 'Styl',
    'chart.templates.tooltip': 'Projekty wykresu',
    'chart.templates.visibility': '#not hidden#',
    'chart.tic.short.15min.name': '15m',
    'chart.tic.short.1day.name': '1D',
    'chart.tic.short.1hour.name': '1H',
    'chart.tic.short.1min.name': '1m',
    'chart.tic.short.1tic.name': 'Tick',
    'chart.tic.short.30min.name': '30m',
    'chart.tic.short.4hour.name': '4H',
    'chart.tic.short.5min.name': '5m',
    'chart.tic.short.month.name': '1M',
    'chart.tic.short.range.name': 'Zakres',
    'chart.tic.short.second.name': 's',
    'chart.tic.short.week.name': '1W',
    'chart.tic.short.year.name': '1Y',
    'chart.timeframeselector.rangeButton': 'Zakres danych',
    'chart.timeframeselector.rangeButton.ToolTip': 'Zakres danych',
    'chart.timeframeselector.time': 'Czas',
    'chart.timeScaleContextMenu.ShowAllSessions': 'Pokaż rozszerzone sesje',
    'chart.timeScaleContextMenu.ShowDaySeparator': 'Pokaż separator dni',
    'chart.timeScaleContextMenu.ShowEmptyBars': 'Pokaż przerwy w kwotowaniach',
    'chart.timeScaleContextMenu.ShowMonthSeparator': 'Pokaż seperator miesięcy',
    'chart.timeScaleContextMenu.ShowTimePeriodsSeparator': 'Pokaż seperator okresów czasu',
    'chart.timeScaleContextMenu.ShowWeeksSeparator': 'Pokaż seperator tygodni',
    'chart.timeScaleContextMenu.ShowYearSeparator': 'Pokaż seperator lat',
    'chart.tool.bar': 'Słupkowy',
    'chart.tool.candle': 'Świecowy',
    'chart.tool.clone': 'Duplikuj',
    'chart.tool.Dash': 'Styl',
    'chart.tool.delete': 'Usuń',
    'chart.tool.deleteAll': 'Usuń wszystko',
    'chart.tool.dot': 'Kropkowy',
    'chart.tool.dotline': 'Kropkowo-liniowy',
    'chart.tool.DottedLine': 'Kropkowa',
    'chart.tool.forest': 'Histogram',
    'chart.tool.IsoDot': 'Iso Dot',
    'chart.tool.line': 'Linia',
    'chart.tool.properties': 'Ustawienia',
    'chart.tool.ShapedLine': 'Shaped line',
    'chart.tool.solid': 'Obszar',
    'chart.tools.ABCPatern': 'Formacja ABC',
    'chart.tools.AndrewPitchfork': "Andrew's pitchfork",
    'chart.tools.backcolor': 'Kolor tła',
    'chart.tools.ButterflyGartley': 'Gartley butterfly',
    'chart.tools.Circle': 'Okrąg',
    'chart.tools.default': 'Strzałka',
    'chart.tools.Ellipse': 'Elipsa',
    'chart.tools.ElliottImpulse': 'Impulsy (12345)',
    'chart.tools.ElliottCorrection': 'Korekty (ABC)',
    'chart.tools.ElliottTriangle': 'Trójkąty (ABCDE)',
    'chart.tools.ElliottTripleCombo': 'Potrójne zygzaki (WXYXZ)',
    'chart.tools.ElliottDoubleCombo': 'Podwójne zygzaki (WXY)',
    'chart.tools.eraser': 'Gumka',
    'chart.tools.FibArc': 'Łuk Fibonacciego',
    'chart.tools.FibEllipses': 'Elipsa Fibonacciego',
    'chart.tools.FibExp': 'Ekspansja Fibonacciego',
    'chart.tools.FibFan': 'Wachlarze Fibonacciego',
    'chart.tools.FibPhiChannel': 'Kanał-phi Fibonacciego',
    'chart.tools.Fibretr': 'Poziomy Fibonacciego',
    'chart.tools.FibSpiral': 'Spirala Fibonacciego',
    'chart.tools.FibTga': 'Analiza Time Goal Fibonacciego',
    'chart.tools.FibTz': 'Strefy Fibonacciego',
    'chart.tools.GannFan': 'Wachlarz Ganna',
    'chart.tools.GannGrid': 'Siatka Ganna',
    'chart.tools.GannLine': 'Linie Ganna',
    'chart.tools.GeometryTools': 'Geometria',
    'chart.tools.ElliottTools': 'Fale Elliotta',
    'chart.tools.SecondaryTools': 'Pozostałe',
    'chart.tools.horizontal': 'Linia pozioma',
    'chart.tools.infoline': 'Linijka',
    'chart.tools.Line': 'Linia',
    'chart.tools.mirrored': 'Lustrzane odbicie',
    'chart.tools.points': 'Liczba wierzchołków',
    'chart.tools.Polygon': 'Wielokąt',
    'chart.tools.PPZ': 'PPZ (Price pivot zone)',
    'chart.tools.PriceChannel': 'Kanał cenowy',
    'chart.tools.Rectangle': 'Kwadrat',
    'chart.tools.RegressionChanel': 'Kanał regresji',
    'chart.tools.ScalingByRect': 'Skalowanie do kwadratu',
    'chart.tools.selection': 'Zaznacz',
    'chart.tools.snapToCandle': 'Przypnij do świecy',
    'chart.tools.StayDrawingMode': 'Pozostań w trybie rysowania',
    'chart.tools.symbol': 'Dodaj symbol',
    'chart.tools.text': 'Tekst',
    'chart.tools.TopBottomPattern': 'Szczyty/Dołki',
    'chart.tools.Triangle': 'Trójkąt',
    'chart.tools.vertical': 'Linia pionowa',
    'chart.tools.ABCPatern.deleteByType': 'Wszystkie formacje ABC',
    'chart.tools.AndrewPitchfork.deleteByType': "Wszystkie: Andrew's pitchfork",
    'chart.tools.ButterflyGartley.deleteByType': "Wszystkie: Gartley's butterflies",
    'chart.tools.Circle.deleteByType': 'Wszystkie: okręgi',
    'chart.tools.Ellipse.deleteByType': 'Wszystkie: elipsy',
    'chart.tools.ElliottImpulse.deleteByType': 'Wszystkie: Impulsy (12345)',
    'chart.tools.ElliottCorrection.deleteByType': 'Wszystkie: Korekty (ABC)',
    'chart.tools.ElliottTriangle.deleteByType': 'Wszystkie: Trójkąty (ABCDE)',
    'chart.tools.ElliottTripleCombo.deleteByType': 'Wszystkie: Potrójne zygzaki (WXYXZ)',
    'chart.tools.ElliottDoubleCombo.deleteByType': 'Wszystkie: Podwójne zygzaki (WXY)',
    'chart.tools.FibArc.deleteByType': 'Wszystkie: Łuki Fibonacciego',
    'chart.tools.FibEllipses.deleteByType': 'Wszystkie: Elipsy Fibonacciego',
    'chart.tools.FibExp.deleteByType': 'Wszystkie: Ekspansje Fibonacciego',
    'chart.tools.FibFan.deleteByType': 'Wszystkie: Wachlarze Fibonacciego',
    'chart.tools.FibPhiChannel.deleteByType': 'Wszystkie: Kanały-phi Fibonacciego',
    'chart.tools.Fibretr.deleteByType': 'Wszystkie: Poziomy Fibonacciego',
    'chart.tools.FibSpiral.deleteByType': 'Wszystkie: Spirale Fibonacciego',
    'chart.tools.FibTga.deleteByType': 'Wszystkie: Analizy Time Goal Fibonacciego',
    'chart.tools.FibTz.deleteByType': 'Wszystkie: Strefy Fibonacciego',
    'chart.tools.GannFan.deleteByType': 'Wszystkie: Wachlarze Ganna',
    'chart.tools.GannGrid.deleteByType': 'Wszystkie: Siatki Ganna',
    'chart.tools.GannLine.deleteByType': 'Wszystkie: Linie Ganna',
    'chart.tools.horizontal.deleteByType': 'Wszystkie: Linie poziome',
    'chart.tools.infoline.deleteByType': 'Wszystkie: Linijka',
    'chart.tools.Line.deleteByType': 'Wszystkie: Linie',
    'chart.tools.Polygon.deleteByType': 'Wszystkie: wielokąty',
    'chart.tools.PPZ.deleteByType': 'Wszystkie: PPZ',
    'chart.tools.PriceChannel.deleteByType': 'Wszystkie kanały cenowe',
    'chart.tools.Rectangle.deleteByType': 'Wszystkie: kwadraty',
    'chart.tools.RegressionChanel.deleteByType': 'Wszystkie: Kanały regresji',
    'chart.tools.symbol.deleteByType': 'Wszystkie: symbole',
    'chart.tools.text.deleteByType': 'Wszystkie: komentarze',
    'chart.tools.TopBottomPattern.deleteByType': 'Wszystkie: analizy dołków i szczytów',
    'chart.tools.Triangle.deleteByType': 'Wszystkie: trójkąty',
    'chart.tools.vertical.deleteByType': 'Wszystkie: linie pionowe',
    'chart.tools.VolumeProfile.deleteByType': 'Wszystkie: Profile Wolumenu',
    'chart.view': 'Widok',
    'chart.view.analyseGroupMenu': 'Narzędzia do analizy',
    'chart.view.analysetoolbar': 'Narzędzia do analizy',
    'chart.view.barsToRight': 'Słupki w prawo...',
    'chart.view.colors': 'Ustawienia',
    'chart.view.dataInfo': 'Okno informacji',
    'chart.view.Day_HighLow': 'Dzienny Max/Min',
    'chart.view.drawingtoolbar': 'Pasek narzędzi do rysowania',
    'chart.view.font': 'Czcionka',
    'chart.view.landscapequestion': 'Czy dostosować układ strony?',
    'chart.view.merge': 'Linie kontynuacyjne',
    'chart.view.orderEntry': 'Handel z wykresu',
    'chart.view.periods': 'Interwały...',
    'chart.view.previousCloseLine': 'Linia poprzedniego zamknięcia',
    'chart.view.PrevSettlementPriceLine': 'Poprzednia cena rozliczenia',
    'chart.view.scroller': 'Przesuń',
    'chart.view.setOrder': 'Utwórz zlecenie',
    'chart.view.SettlementPriceLine': 'Cena rozliczenia',
    'chart.view.show_cross': 'Śledź kursor',
    'chart.view.show_history': 'Pokaż historię transakcji',
    'chart.view.show_set_zero': 'Pokaż puste słupki',
    'chart.view.showEvents': 'Pokaż transakcje',
    'chart.view.showNews': 'Pokaż informacje',
    'chart.view.showOrders': 'Pokaż Zlecenia',
    'chart.view.showPositions': 'Pokaż pozycje',
    'chart.view.source': 'Typ danych',
    'chart.view.source0': 'Instrument',
    'chart.view.source1': 'Bid',
    'chart.view.source1000': 'Ask',
    'chart.view.source1001': 'Średnia Bid/Ask',
    'chart.view.source1002': 'Min (Bid) i Max (Ask)',
    'chart.view.source4': 'Ostatnia cena',
    'chart.view.sourceBID/ASK': 'Po Bid/Ask',
    'chart.view.sourceTick1': 'Po Bid/Ask',
    'chart.view.TimeToNextBar': 'Czas do następnej świecy',
    'chart.view.toolbar': 'Pasek narzędzi',
    'chart.view.volume': 'Wolumen',
    'chart.visualTrading.Invalid price': 'Błędna cena',
    'chart.volumeColoringMode.ByBar': 'Słupek',
    'chart.volumeColoringMode.ByDifference': 'Różnica',
    'chart.volumeColoringMode.Fixed': 'Stały',
    'chart.quickTrading.Buy limit': 'Kup Limit',
    'chart.quickTrading.Buy stop': 'Kup Stop',
    'chart.quickTrading.Buy stop limit': 'Kup Stop limit',
    'chart.quickTrading.Invalid price': 'Błędna cena',
    'chart.quickTrading.Sell limit': 'Sprzedaj Limit',
    'chart.quickTrading.Sell stop': 'Sprzedaj Stop',
    'chart.quickTrading.Sell stop limit': 'Sprzedaj Stop limit',
    'Commodity Channel Index': 'Commodity channel index',
    'connect.connection.connect.error': 'Błędne parametry połączenia',
    'connect.connection.tradestream.error': 'Błąd podłączenia sesji handlowej',
    'connect.connection.unknown.error': 'Nieznany błąd!',
    'contextMenu.Totals.visibility': '#not hidden#',
    Custom: 'Własne',
    'dayTimeLabel.days': 'dni',
    'dayTimeLabel.hours': 'godziny',
    'dayTimeLabel.minutes': 'minuty',
    'dayTimeLabel.seconds': 'sekundy',
    'dayTimeLabel.day': 'dzień',
    'dayTimeLabel.hour': 'godzina',
    'dayTimeLabel.minute': 'minuta',
    'dayTimeLabel.second': 'sekunda',
    'dayTimeLabel.lifeTime': 'od początku',
    'dayTimeLabel.lifeTimeExpired': 'wygasło',
    Day: 'Dzień',
    'dealticket.RiskRuleWarning.Header': 'Uwaga - przekroczono ustawiony poziom ryzyka',
    'dealticket.RiskRuleWarning.AccountName': 'Rachunek',
    'dealticket.RiskRuleWarning.UserName': 'Login',
    Deutsch: '#hidden#',
    'enum.AccountType.ClosedFund': 'Zamknięty fundusz',
    'enum.AccountType.MultiAsset': 'Multi asset',
    'enum.AccountType.OpenedFund': 'Otwarty fundusz',
    'enum.AccountType.SingleCCY': 'Pojedyncza waluta',
    'enum.AssetType.COMMODITIES': 'Towary',
    'enum.AssetType.CRYPTO_CCY': 'Kryptowaluty',
    'enum.AssetType.CURRENCY': 'Waluta',
    'enum.AssetType.SHARES': 'Akcje',
    'editableComboBox.CreateNew': 'Nowa lista',
    'editableComboBox.CreateNewTemplate': 'Nowy projekt',
    'editableComboBox.CreateNew.tooltip': 'Kliknij, aby utworzyć nową listę instrumentów',
    'editableComboBox.CreateNewTemplate.tooltip': 'Kliknij, aby utworzyć nowy projekt wykresu',
    'editableComboBox.defaultList': 'Lista domyślna',
    'editableComboBox.tooltip': 'Zapisana lista',
    'ExchangeId.All': 'Wszystkie giełdy',
    'Exponential Moving Average': 'Wykładniczna średnia ruchoma (EMA)',
    FOK: 'FOK',
    'FullLogs.Cluster': 'Serwer',
    'FullLogs.DateTime': 'Czas',
    'FullLogs.Fields': 'Pola',
    'FullLogs.Message name': 'Nazwa wiadomości',
    'FullLogs.Session': 'Sesja',
    'general.Instrument': 'Instrument',
    'general.Calculating': 'Obliczam...',
    'general.connection.restErrorMsg': 'Wystąpił problem z zapisaniem pulpitu. Skontaktuj się z Infolinią - 19 503',
    'general.connection.CancelConnectRequested': 'Anuluj proces łączenia',
    'general.connection.fullInfo': 'Pełne informacje',
    'general.connection.history': 'History server',
    'general.connection.is not valid. Attempt': 'niepoprawne. Próba',
    'general.connection.lastMessage': 'Ostatnia wiadomość',
    'general.connection.Login/password combination is not valid.': 'Błędny login lub hasło.',
    'general.connection.Login/password combination is not valid. Attempt': 'Błędny login lub hasło. Próba {0}.',
    'general.connection.order': 'Serwer zleceń',
    'general.connection.quote': 'Serwer kwotowań',
    'general.connection.server': 'Serwer',
    'general.connection.status': 'Status',
    'general.connection.status.Connected': 'Połączono',
    'general.connection.status.Disconnected': 'Brak połączeń',
    'general.connection.status.Reconnecting': 'Łączenie...',
    'general.connection.User/password combination for user': 'Login/Hasło Klienta ',
    'general.connection.User/password combination is not valid.': 'Błędny login lub hasło.',
    'general.connection.User/password combination is not valid. Atemp': 'Błędny login lub hasło. Próba',
    'general.CUSTOM': 'Własne',
    'general.Friday': 'Piątek',
    'general.messageBox.cancel': 'Anuluj',
    'general.messageBox.no': 'Nie',
    'general.messageBox.ok': 'OK',
    'general.messageBox.showNextTime': 'Pokaż potwierdzenia',
    'general.messageBox.yes': 'Tak',
    'general.Monday': 'Poniedziałek',
    'general.day1.Mo': 'Pon',
    'general.day2.Tu': 'Wt',
    'general.day3.We': 'Śr',
    'general.day4.Th': 'Czw',
    'general.day5.Fr': 'Pt',
    'general.day6.Sa': 'Sob',
    'general.day7.Su': 'Nd',
    'general.month.1': 'St',
    'general.month.10': 'Paź',
    'general.month.11': 'Lis',
    'general.month.12': 'Gru',
    'general.month.2': 'Lut',
    'general.month.3': 'Mar',
    'general.month.4': 'Kwi',
    'general.month.5': 'Maj',
    'general.month.6': 'Cze',
    'general.month.7': 'Lip',
    'general.month.8': 'Sie',
    'general.month.9': 'Wrz',
    'general.monthFullName.1': 'Styczeń',
    'general.monthFullName.10': 'Październik',
    'general.monthFullName.11': 'Listopad',
    'general.monthFullName.12': 'Grudzień',
    'general.monthFullName.2': 'Luty',
    'general.monthFullName.3': 'Marzec',
    'general.monthFullName.4': 'Kwiecień',
    'general.monthFullName.5': 'Maj',
    'general.monthFullName.6': 'Czerwiec',
    'general.monthFullName.7': 'Lipiec',
    'general.monthFullName.8': 'Sierpień',
    'general.monthFullName.9': 'Wrzesień',
    'general.N_A': 'Brak',
    'general.OvernightMarginNotificationMessage': 'Pokaż wiadomość o marginesie overnight',
    'general.Saturday': 'Sobota',
    'general.Settings': 'Ustawienia',
    'general.Sunday': 'Niedziela',
    'general.Thursday': 'Wtorek',
    'general.trading.at': 'na',
    'general.trading.Buy': 'Kup',
    'general.trading.confirmation': 'Potwierdzenie',
    'general.trading.confirmation.prefix': '',
    'general.trading.confirmationString': '{1} {2} {3} {4} {5} {6} {7} {8} {9} {10} {14} {13} {14} {15} {11} {12} {16}',
    'general.trading.for': 'dla',
    'general.trading.limitBuyLessAsk': 'Limit ceny w zleceniu Kupna powienien być niższy niż rynkowa cena Ask',
    'general.trading.limitSellMoreBid': 'Limit ceny w zleceniu Sprzedaży powinien być wyższy niż rynkowa cena Bid',
    'general.trading.marketCloseFor': 'Zamykające Market',
    'general.trading.pips': 'ticki',
    'general.trading.points': 'punkty',
    'general.trading.position': 'Pozycja',
    'general.trading.position.Buy': 'Kupno',
    'general.trading.position.Sell': 'Sprzedaż',
    'general.trading.priceNotAvailable': 'Cena niedostępna',
    'general.trading.refused': 'Odrzucono',
    'general.trading.Sell': 'Sprzedaj',
    'general.trading.stopBuyMoreAsk': 'Cena w zleceniu Stop Buy powinna być wyższa niż rynkowa cena Ask',
    'general.trading.stopSellLessBid': 'Cena w zleceniu Stop Sell powinna być niższa niż rynkowa cena Bid',
    'general.trading.with offset': 'z marginesem',
    'general.Tuesday': 'Wtorek',
    'property.general.WarnIfWrongOrderParam': 'Ostrzegaj, jeżeli występują błędne paramtery zlecenia',
    'general.WarnIfOrderRepeat': 'not hidden',
    'property.general.WarnIfIdenticalOrderTime': '',
    'property.general.WarnIfIdenticalOrder': 'Ostrzegaj jeżeli identyczne zlecenie (sek)',
    'general.WarnIfIdenticalOrdertText': 'Próbujesz złożyć identyczne zlecenie w ciągu ostatnich {1} sekund. Kontynuować? ',
    'general.Wednesday': 'Środa',
    GTC: 'GTC',
    'helpPage.link': 'https://inwestycje.aliorbank.pl/forex/platformy/alior-trader-web/',
    'homePage.link': 'http://www.aliorbank.pl',
    Ichimoku: 'Ichimoku',
    'Instrument.Futures.contract': 'kontrakt',
    'Instrument.Level1.ExtendedFields.NormalMarketSize': 'Zwykła wielkość rynkowa',
    'Instrument.Spreadbet.bet': 'bets',
    'Instrument.Corporate.Securities': 'papiery wartościowe',
    'Instrument.ExerciseStyle.EUROPEAN': 'europejskie',
    'Instrument.ExerciseStyle.AMERICAN': 'amerykańskie',
    'InstrumentDetailsPanel.(Indicative symbol)': '(Instrument)',
    'InstrumentDetailsPanel.1.GeneralInfo': 'Informacje ogólne',
    'InstrumentDetailsPanel.2.TradingInfo': 'Informacje handlowe',
    'InstrumentDetailsPanel.3.MarginReg': 'Wymagany depozyt',
    'InstrumentDetailsPanel.4.Fees': 'Opłaty',
    'InstrumentDetailsPanel.5.Rebates': 'Rabaty',
    'InstrumentDetailsPanel.6.SessionInfo': 'Informacja o sesji',
    'InstrumentDetailsPanel.7.Plans': 'Plany',
    'InstrumentDetailsPanel.AccruedInterest': 'Accrued interest',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot': 'Rabat dla zlecenia na lot',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot': 'Rabat na lot',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume': 'Rabat dla wolumenu',
    'InstrumentDetailsPanel.AFTER_MARKET': 'After market',
    'InstrumentDetailsPanel.Allow short positions': 'Tak',
    'InstrumentDetailsPanel.AllowedOperations': 'Dozwolone operacje',
    'InstrumentDetailsPanel.AllowedOrderTypes': 'Dozwolone rodzaje zleceń',
    'InstrumentDetailsPanel.AllowShortPositions': 'Dozwolone pozycje krótkie',
    'InstrumentDetailsPanel.AllowOvernightTrading': 'Handel overnight',
    'InstrumentDetailsPanel.Amount': 'Ilość',
    'InstrumentDetailsPanel.AssetClass': 'Typ instrumentu',
    'InstrumentDetailsPanel.BEFORE_MARKET': 'Pre- market',
    'InstrumentDetailsPanel.Cash': 'Gotówka',
    'InstrumentDetailsPanel.Close': 'Zamknęcie',
    'InstrumentDetailsPanel.Closed': 'Zamknięty',
    'InstrumentDetailsPanel.CloseOutDeadline': 'Data wygasania instrumentu',
    'InstrumentDetailsPanel.Commisions': 'Prowizje',
    'InstrumentDetailsPanel.ContactMonth': 'Miesiąc kontraktu',
    'InstrumentDetailsPanel.ContractMultiplier': '#hidden#',
    'InstrumentDetailsPanel.ContractSize': 'Wielkość kontraktu',
    'InstrumentDetailsPanel.CouponCycle': 'Okres odsetkowy',
    'InstrumentDetailsPanel.CouponRate': 'Odsetki, %',
    'InstrumentDetailsPanel.CurrentSession': 'Bieżąca sesja handlowa',
    'InstrumentDetailsPanel.DeliveryMethod': 'Metoda dostawy',
    'InstrumentDetailsPanel.DeliveryStatus': 'Status dostawy',
    'InstrumentDetailsPanel.Depend from account': 'Zależy od rachunku',
    'InstrumentDetailsPanel.Description': 'Opis',
    'InstrumentDetailsPanel.Exchange': 'Giełda',
    'InstrumentDetailsPanel.ExchangeTradeSession': 'Sesja giełdowa',
    'InstrumentDetailsPanel.Exp1': 'Aktywa',
    'InstrumentDetailsPanel.FaceValue': 'Wartość nominalna',
    'InstrumentDetailsPanel.FirstTradeDate': 'Data pierwszej transakcji',
    'InstrumentDetailsPanel.Futures': 'Futures',
    'InstrumentDetailsPanel.FuturesClass': 'Klasa Futures',
    'InstrumentDetailsPanel.HiLimit': 'Górny limit',
    'InstrumentDetailsPanel.Holiday': 'Święto',
    'InstrumentDetailsPanel.HolidayDate': 'Data',
    'InstrumentDetailsPanel.HolidayName': 'Nazwa święta',
    'InstrumentDetailsPanel.Holidays list': 'Lista dni świątecznych',
    'InstrumentDetailsPanel.HolidayType': 'Lista dni bez handlu',
    'InstrumentDetailsPanel.immediate': 'natychmiast',
    'InstrumentDetailsPanel.KEYNextHoliday': 'Następne święto',
    'InstrumentDetailsPanel.LastTradeDate': 'Data ostatniej transakcji',
    'InstrumentDetailsPanel.LotSize': 'Wielkość lot',
    'InstrumentDetailsPanel.LotStep': 'Krok notowań',
    'InstrumentDetailsPanel.LowLimit': 'Dolny limit',
    'InstrumentDetailsPanel.MAIN': 'Sesja główna',
    'InstrumentDetailsPanel.Margin': 'Depozyt utrzymania pozycji',
    'InstrumentDetailsPanel.Margin_ByAccount': 'Margin dla rachunku',
    'InstrumentDetailsPanel.MarginBuy': 'Pozycje kupna',
    'InstrumentDetailsPanel.MarginDay': 'Dzień',
    'InstrumentDetailsPanel.MarginDayBuy': 'Kupno, ważność: dzień',
    'InstrumentDetailsPanel.MarginDaySell': 'Sprzedaż, ważność: dzień',
    'InstrumentDetailsPanel.MarginBuy.Intraday': 'Kupno intraday',
    'InstrumentDetailsPanel.MarginSell.Intraday': 'Sprzedaż Intraday',
    'InstrumentDetailsPanel.MarginBuy.Delivery': 'Kupno z dostawą',
    'InstrumentDetailsPanel.MarginSell.Delivery': 'Sprzedaż z dostawą',
    'InstrumentDetailsPanel.MarginInAccountCurrency': '#hidden#',
    'InstrumentDetailsPanel.MarginOvernight': 'Overnight',
    'InstrumentDetailsPanel.MarginOvernightBuy': 'Kupno overnight',
    'InstrumentDetailsPanel.MarginOvernightSell': 'Sprzedaż overnight',
    'InstrumentDetailsPanel.MarginSell': 'Pozycje sprzedaży',
    'InstrumentDetailsPanel.MaturityDate': 'Data wygasania',
    'InstrumentDetailsPanel.MaximumLot': 'Max lot',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol': 'Max wolumen pozycji',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday': 'Max wolumen pozycji-Intraday',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery': 'Maksymalna wielkość dostawy',
    'InstrumentDetailsPanel.MinimalLot': 'Min Lot',
    'InstrumentDetailsPanel.NextPaymentDate': 'Następna płatność yield',
    'InstrumentDetailsPanel.No': 'Nie',
    'InstrumentDetailsPanel.non trading day': 'Rynek zamknięty',
    'InstrumentDetailsPanel.None': 'Brak',
    'InstrumentDetailsPanel.NormalMarketSize': '#hidden#',
    'InstrumentDetailsPanel.Not allow short positions': 'Nie',
    'InstrumentDetailsPanel.NoticeDate': 'Dzień powiadomienia',
    'InstrumentDetailsPanel.onlyCloseOrders': 'Tylko zlecenia zamykające',
    'InstrumentDetailsPanel.Open': 'Otwarty',
    'InstrumentDetailsPanel.OptionExercise': 'Option exercise fee',
    'InstrumentDetailsPanel.OptionExercise1': 'Option exercise fee (Long)',
    'InstrumentDetailsPanel.OptionExercise3': 'Option exercise fee (Short)',
    'InstrumentDetailsPanel.OrderPerLot': 'Zlecenie na lot',
    'InstrumentDetailsPanel.PerFill': 'Dla wykonania',
    'InstrumentDetailsPanel.PerLot': 'Wykonanie na lot',
    'InstrumentDetailsPanel.PerOrder': 'Dla zlecenia',
    'InstrumentDetailsPanel.PerOrderVolume': 'Wolumen zlecenia, %',
    'InstrumentDetailsPanel.PerPhoneTransaction': 'Dla transakcji przez infolinię',
    'InstrumentDetailsPanel.PerTransaction': 'Dla transakcji',
    'InstrumentDetailsPanel.PerVolume': 'Wolumen wykonania, %',
    'InstrumentDetailsPanel.Physically': 'Fizyczna',
    'InstrumentDetailsPanel.POSTCLOSE': 'Post-market',
    'InstrumentDetailsPanel.PREOPEN': 'Pre-market',
    'InstrumentDetailsPanel.PreviousPaymentDate': 'Poprzednia płatność yield',
    'InstrumentDetailsPanel.Price': 'Cena',
    'InstrumentDetailsPanel.ProductType': 'Typ instrumentu',
    'InstrumentDetailsPanel.QuotiongCurrency': 'Waluta kwotowana',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot': 'Usuń rabat płynnościowy dla zlecenia na lot',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot': 'Usuń rabat płynnościowy na lot',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume': 'Usuń rabat płynnościowy dla wolumenu',
    'InstrumentDetailsPanel.SessionStatus': '#hidden#',
    'InstrumentDetailsPanel.SettlementDate': 'Data rozliczenia',
    'InstrumentDetailsPanel.shortened': 'Skrócona sesja',
    'InstrumentDetailsPanel.ShortPostionInterest': 'Odsetki za pozycję krótką',
    'InstrumentDetailsPanel.StrikePrice': 'Cena strike',
    'InstrumentDetailsPanel.SwapBuy': 'Swap dla kupna',
    'InstrumentDetailsPanel.SwapSell': 'Swap dla sprzedaży',
    'InstrumentDetailsPanel.Latest historical long swap': 'Ostatni swap - kupno',
    'InstrumentDetailsPanel.Latest historical short swap': 'Ostatni swap - sprzedaż',
    'InstrumentDetailsPanel.Historical swap last updated on': 'Ostatnia zmiana swap',
    'InstrumentDetailsPanel.Symbol': 'Instrument',
    'InstrumentDetailsPanel.T+0': 'T + 0 (dziś)',
    'InstrumentDetailsPanel.TickCoast': 'Wartość tik',
    'InstrumentDetailsPanel.TickSize': 'Krok notowań',
    'InstrumentDetailsPanel.Tier': 'Poziom',
    'InstrumentDetailsPanel.TradindBlockedOnSession': 'Handel zablokowany na sesji',
    'InstrumentDetailsPanel.TradingBalance': 'System rozliczenia',
    'InstrumentDetailsPanel.TradingHalt': 'Wstrzymano handel',
    'InstrumentDetailsPanel.TradingStatus': 'Handel na instrumencie',
    'InstrumentDetailsPanel.Underlier': 'Instrument bazowy',
    'InstrumentDetailsPanel.VAT': 'VAT',
    'InstrumentDetailsPanel.Volume': 'Wolumen wykonania',
    'InstrumentDetailsPanel.VolumeWithMinPD': 'Wolumen wykonania %, z min c/d',
    'InstrumentDetailsPanel.working': 'Otwórz',
    'InstrumentDetailsPanel.Yes': 'Tak',
    'InstrumentDetailsPanel.Yield': 'Yield',
    'InstrumentDetailsPanel.YTM': 'YTM, %',
    'InstrumentDetailsPanel.YTM.RefreshLink': 'Odśwież',
    'InstrumentDetailsPanel.HistoricalSwap.points': 'punkty',
    IOC: 'IOC',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders': 'Wykonanie z ceną Market nie jest możliwe dla zleceń powiązanych',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp': 'Wykonanie z ceną Market nie jest możliwe dla zleceń SL/TP',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType': 'Zmiana rodzaju zlecenia lyb wykonanie po cenie Market nie jest możliwe',
    'IsAllowedResponceReason.InstrumentForAccountDelivery': 'Instrument z dostawą na rachunek',
    'IsAllowedResponceReason.InstrumentTradingMode': 'Handel na instrumencie jest zablokowany',
    'IsAllowedResponceReason.LockTrading': 'Handel zablokowany',
    'IsAllowedResponceReason.LockTradingByPassword': 'Wpisz hasło na dole terminala',
    'IsAllowedResponceReason.NoLastQuote': 'Brak ostatniego kwotowania',
    'IsAllowedResponceReason.NotAllowedByOrderStaus': 'Operacje zabronione przez status zlecenia',
    'IsAllowedResponceReason.NotAllowedPositionCloseOnly': 'Tylko zlecenia zamykające',
    'IsAllowedResponceReason.NotAllowedRouteOrderType': 'Order cannot be created, since selected validity and Order type are not supported.',
    'IsAllowedResponceReason.NotClosablePosition': 'Brak możliwości zamknięcia pozycji',
    'IsAllowedResponceReason.NotLoadCache': 'Nie wczytano pamięci podręcznej',
    'IsAllowedResponceReason.NotVisibleInstrumentRoute': 'Instrument lub route niewidoczny',
    'IsAllowedResponceReason.OperationNotAllowedByOrderType': 'Operacja zabroniona przez typ zlecenia',
    'IsAllowedResponceReason.OperationNotAllowedBySession': 'Operacja zabroniona przez sesję',
    'IsAllowedResponceReason.OptionPendingExerciseStatus': 'Position for contract is waiting for exercise. Сancel the exercise possible during the trading session.',
    'IsAllowedResponceReason.OrderTypeNotAllowedBySession': 'Rodzaj zlecenia zabroniony przez sesję',
    'IsAllowedResponceReason.RouteNotRunning': 'Handel na instrumencie jest zabroniony.',
    'IsAllowedResponceReason.RuleAdministration': 'Rola administracyjna',
    'IsAllowedResponceReason.RuleClosePositionDisable': 'Zamknięcie pozycji jest zabronione',
    'IsAllowedResponceReason.RuleFunctionSelfTrading': 'Rule function self trading',
    'IsAllowedResponceReason.RuleFunctionTrading': 'Handel zabroniony',
    'IsAllowedResponceReason.RuleOCO': 'Reguła OCO',
    'IsAllowedResponceReason.RuleSlTpNotAllowed': 'Zlecenia SL/TP są zabronione',
    'IsAllowedResponceReason.RuleTrStop': 'Reguła Tr. stop',
    'IsAllowedResponceReason.Unknown': 'Nieznany powód',
    'Keltner Channel': 'Keltner channel',
    Limit: 'Limit',
    'Linear Weighted Moving Average': 'Linear weighted moving average',
    LOC: 'LOC',
    LOO: 'LOO',
    Manual: 'Manual',
    Market: 'Market',
    'MarketDepthPanel.ColoringMethod.ByPriceLVL': 'Poziom cen',
    'MarketDepthPanel.ColoringMethod.RelativeToVolume': 'Relatywnie do wolumenu',
    'MarketDepthPanel.ColoringMethod.SizeHistogram': 'Wielkość - histogram',
    'McGinley Dynamic': 'McGinley Dynamic',
    'MDLotsMode.General': 'Domyślne ({1})',
    'MDLotsMode.Lots': 'Loty',
    'MDLotsMode.RealSize': 'Faktyczna wielkość',
    'MessageBox.ThemeChanged.Title': 'Ostrzeżenie',
    MOC: 'MOC',
    'Modified Moving Average': 'Modified Moving Average',
    Momentum: 'Momentum',
    MOO: 'MOO',
    'Moving Average Envelope': 'Moving average envelope',
    'Moving Average/Convergence Divergence': 'Moving average/Convergence Divergence',
    'NetTrader.SaveWorkspace.Save': 'Zapisz pulpit',
    'NumericComboCtrl.Edit': 'Ustaw jako domyślne',
    OCO: 'OCO',
    'OEProductTypeSelector.tooltip': 'Typ instrumentu',
    'On Balance Volume': 'On Balance volume',
    OnClose: 'Na zamknięcie',
    OnOpen: 'Na otwarcie',
    'Order.ExecutionType.ACCEPTED': 'Zaakceptowano',
    'Order.ExecutionType.ACTIVATED': 'Aktywowano',
    'Order.ExecutionType.CANCELED': 'Usunięto',
    'Order.ExecutionType.FILLED': 'Wykonano',
    'Order.ExecutionType.NEW': 'Utworzono',
    'Order.ExecutionType.PART_FILLED': 'Częsciowo wykonano',
    'Order.ExecutionType.REFUSED': 'Odrzucono',
    'Order.ExecutionType.REPLACED': 'Zmodyfikowano',
    'Order.ExecutionType.RESTATED': 'Restated',
    'Order.ExecutionType.UNKNOWN': 'Nieznane',
    'OrderEntry.InfoBlock.1.Risks': 'Ryzyko',
    'OrderEntry.InfoBlock.2.Fees': 'Opłaty',
    'OrderEntry.InfoBlock.After trade funds': 'Środki dostępne po transakcji',
    'OrderEntry.InfoBlock.Availible funds': 'Dostępne środki',
    'OrderEntry.InfoBlock.Balance': 'Saldo',
    'OrderEntry.InfoBlock.Blocked for Stocks': 'Zablokowane pod akcje',
    'OrderEntry.InfoBlock.Fee': 'Opłata',
    'OrderEntry.InfoBlock.Gross': 'Brutto',
    'OrderEntry.InfoBlock.Impact on portfolio': 'Wpływ na portfolio',
    'OrderEntry.InfoBlock.Init. margin': 'Depozyt wstępny',
    'OrderEntry.InfoBlock.Maint. margin': 'Depozyt utrzymania',
    'OrderEntry.InfoBlock.Warn. margin': 'Margin Call',
    'OrderEntry.InfoBlock.Margin availible': 'Dostępny margin',
    'OrderEntry.InfoBlock.Net': 'Netto',
    'OrderEntry.InfoBlock.Spread in loss': 'Spread w wyniku',
    'OrderEntry.InfoBlock.P/L per Tick': 'Z/S na tick',
    'OrderEntry.InfoBlock.Allow short positions': 'Zezwól na pozycje krótkie',
    'OrderEntry.InfoBlock.FillPerLot': 'Wykonanie na lot',
    'OrderEntry.InfoBlock.OrderPerLot': 'Zlecenia na lot',
    'OrderEntry.InfoBlock.FillVolume': 'Wolumen wykonania (Kupno/Sprzedaż/Short/Otwierające/Zamykające), %',
    'OrderEntry.InfoBlock.FillVolumeWithMinPD': 'Wolumen wykonania (Kupno/Sprzedaż/Otwierające/Zamykające) z min p/d, %',
    'OrderEntry.InfoBlock.PerFill': 'Dla wykonania',
    'OrderEntry.InfoBlock.PerTransaction': 'Dla transakcji',
    'OrderEntry.InfoBlock.PerPhoneTransaction': 'Dla transakcji przez telefon',
    'OrderEntry.InfoBlock.VAT': 'VAT',
    'OrderEntry.InfoBlock.OrderVolume': 'Wolumen zlecenia % (Kupno/Sprzedaż/Otwierające/Zamykające)',
    'OrderEntry.InfoBlock.LongSwap': 'Swap dla K',
    'OrderEntry.InfoBlock.refreshBtnTooltip': 'Kliknij, aby odświeżyć informacje o margin lub poczekaj 30 sek.',
    'OrderEntry.InfoBlock.ShortSwap': 'Swap dla S',
    'OrderEntry.InfoBlock.TotalFee': 'Łączne opłaty',
    'OrderEntry.InfoBlock.Visibility': '#not hidden#',
    'OverlayScreen.barsBodyLabel': 'Korpus',
    'OverlayScreen.barsBorderLabel': 'Ramka',
    'OverlayScreen.barsHiLowLabel': 'Knot',
    'OverlayScreen.cancelButton': 'Anuluj',
    'OverlayScreen.dataLabel': 'Typ danych',
    'OverlayScreen.dojiLabel': 'Doji',
    'OverlayScreen.histogramLineLabel': 'Histogram',
    'OverlayScreen.instrumentLabel': 'Instrument',
    'OverlayScreen.Main scale': 'Główna skala',
    'OverlayScreen.name': 'Nowa warstwa',
    'OverlayScreen.numberOverlaysError': 'Nie można dodać więcej niż 5 warstw.',
    'OverlayScreen.okButton': 'OK',
    'OverlayScreen.Overlays': 'Warstwy',
    'OverlayScreen.priceLabel': 'Skalowanie cen',
    'OverlayScreen.priceMarkerCheckbox': 'Pokaż znacznik cenowy',
    'OverlayScreen.Separate scale': 'Osobna skala',
    'OverlayScreen.solidPriceLabel': 'Obszar',
    'OverlayScreen.styleLabel': 'Styl',
    'OverlayScreen.wickLabel': 'Knot',
    'panel.AccountDetailsPanel': 'Szczegóły rachunku',
    'panel.AccountDetailsPanel.InfoTooltip': 'Zmień pole w nagłówku - przeciągnij i upuść z tabeli.',
    'panel.accountDetails.Groups.Rico risks': 'Szczegóły rachunku',
    'panel.accountDetails.Groups.Kyrrex risks': 'Szczegóły rachunku',
    'panel.accountDetails.Groups.AccountActivity': '2. Bieżący stan rachunku',
    'panel.accountDetails.Groups.General': '1. Ogólne',
    'panel.accountDetails.Groups.Info': '5. Informacje',
    'panel.accountDetails.Groups.RiskManagement': '4. Zarządzanie ryzykiem',
    'panel.accountDetails.Groups.TodayResults': '3. Dzisiejsze wyniki',
    'panel.accountDetails.Kyrrex risks.BlockedBalance': 'Zablokowane saldo',
    'panel.accountDetails.Rico risks.BLNC': 'Saldo',
    'panel.accountDetails.Rico risks.NTEQ': 'Net equity',
    'panel.AccountLink.SymbolLink.ToolTip': 'Połączenie instrumentu',
    'panel.AccountLink.ToolTip': 'Połączenie rachunku',
    'panel.AccountLink.CanFilterToolTip': 'Dane w tym oknie są filtrowane ze względu na rachunek',
    'panel.AccountLinkButton.ToolTip': 'Ta funkcja filtruje pozycje i zlecenia w odniesieniu do poszczególnych rachunków.',
    'panel.accounts.AccountLink.ToolTip': 'Włączone połączenie rachunku',
    'panel.accounts': 'Rachunki',
    'panel.accounts.AccountDescription': '#hidden#',
    'panel.accounts.accountDetails.RiskManagement.MaxOrderCapital.Unlimited': 'Brak limitu',
    'panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached': 'Osiągnięto dzienny limit straty',
    'panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached': 'Osiągnięto dzienny limit wolumenu',
    'panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)': 'Osiągnięto dzienny limit zleceń',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled': 'Wyłączony',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled': 'Włączony',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Active': 'Aktywny',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed': 'Wyłączony automatycznie',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed': 'Zamknięty',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules': 'Handel zabroniony',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules.maxOrdersPerDay': 'Handel zabroniony. Osiągnięto max liczbę zleceń',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.FinraDayTraderStatus': 'FINRA Day trader pattern',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.MarginWarning': 'Margin call',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached': 'Osiągnięto max drawdown',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend': 'Zawieszony',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached': 'Osiągnięto limit straty',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached': 'Osiągnięto tygodniowy limit straty',
    'panel.accounts.AccountMode': 'Rodzaj rachunku',
    'panel.accounts.AccountType': 'Typ rachunku',
    'panel.accounts.AccountValue': 'Wartość rachunku',
    'panel.accounts.AllowOvernightTrading': '#hidden#',
    'panel.accounts.AllowTradingPrePost': 'Handel on pre/post  markets',
    'panel.accounts.Asset': 'Aktywo',
    'panel.accounts.AssetDescription': 'Opis aktywa',
    'panel.accounts.AssetType': 'Typ aktywa',
    'panel.accounts.AvailableMargin': 'Wolne środki',
    'panel.accounts.Balance': 'Saldo rachunku',
    'panel.accounts.BalancePlusAllRisks': 'Saldo rachunku i wszystkie ryzyka',
    'panel.accounts.BeginBalance': 'Wynik netto',
    'panel.accounts.BlockedByOrders': 'Blokowane pod zlecenia',
    'panel.accounts.BlockedByPamm': 'Zablokowane pod depozyt',
    'panel.accounts.BlockedForStocks': '#hidden#',
    'panel.accounts.BlockedForStocksNew': 'Zablokowane pod akcje',
    'panel.accounts.CashBalance': 'Saldo gotówki',
    'panel.accounts.ClientType': 'Typ terminala',
    'panel.accounts.Commissions': 'Prowizje',
    'panel.accounts.CreditValue': 'Wartość kredytu',
    'panel.accounts.CurBalance': 'Saldo bieżące',
    'panel.accounts.CurMargin': 'Bieżący depozyt, %',
    'panel.accounts.CurrentDailyLoss': 'Dzisiejsza strata',
    'panel.accounts.CurrentFundCapital': 'Bieżący kapitał funuszu',
    'panel.accounts.CurrentStatus': 'Status handlu',
    'panel.accounts.CurrentStatus.descr': 'Status handlu',
    'panel.accounts.CurrentWeeklyLoss': 'Tygodniowa strata',
    'panel.accounts.Email': 'Email',
    'panel.accounts.Estimate value': 'Oczekiwana wartość',
    'panel.accounts.FundCapitalGain': 'Wynik na kapitale funduszu',
    'panel.accounts.FundsUsed': 'Użyte środki',
    'panel.accounts.FundsUsedPercent': 'Użyte środki, %',
    'panel.accounts.DayTraderPatternProtection': 'Ochrona day trader',
    'panel.accounts.AvailableDayTrades': 'Dostęone transakcje day-trading',
    'panel.accounts.GrossPNL': 'Wynik brutto',
    'panel.accounts.InsertOnBalance': 'Insert on balance',
    'panel.accounts.InterestRate': 'Stopa procentowa, %',
    'panel.accounts.IntradayRiskManagement': 'Zarządzanie ryzykiem',
    'panel.accounts.InitialMarginWithoutWaived': '#hidden#',
    'panel.accounts.InitialMarginWithoutWaived.descr': 'Initial margin requirement without waived',
    'panel.accounts.Leverage': 'Dźwignia finansowa',
    'panel.accounts.LockedBalance': 'Saldo blokowane',
    'panel.accounts.LockedBy': 'Zablokowane',
    'panel.accounts.Login': 'Rachunek',
    'panel.accounts.MaintMargin': 'Wymagany depozyt %',
    'panel.accounts.MarginAndRisks': 'Margin i ryzyka',
    'panel.accounts.MarginAvailableReal': 'Dostępny margin',
    'panel.accounts.MarginDeficiency': 'Brak Margin',
    'panel.accounts.MarginForOthers': '#hidden#',
    'panel.accounts.MarginRequirement': 'Wymagana suma depozytów',
    'panel.accounts.MarginStopOut': 'Wymagany depozyt',
    'panel.accounts.MarginStopTrade': 'Stop trade',
    'panel.accounts.MarginSurplus': 'Nadwyżka Margin',
    'panel.accounts.MarginUsedNew': 'Wykorzystany Margin',
    'panel.accounts.MarginWarning': 'Margin Call',
    'panel.accounts.marginWarnPercent': 'Margin Call %',
    'panel.accounts.MarketDataCharging': 'Opłata za dane rynkowe',
    'panel.accounts.MaxDailyLossLimit': 'Maksymalna dzienna strata',
    'panel.accounts.MaxDailyProfit': 'Cel dziennego zysku',
    'panel.accounts.MaxDayVolume': '#hidden#',
    'panel.accounts.MaximumLot': 'Maksymalna wielkość zlecenia',
    'panel.accounts.MaxOrderAmount': 'Maksymalna wielkość zlecenia',
    'panel.accounts.MaxOrderCapital': 'Maksymalna wartość zlecenia',
    'panel.accounts.MaxOrdersPerDay': 'Maksymalna dzienna ilość zleceń',
    'panel.accounts.MaxPendingOrders': '# Maksymalna ilość zleceń oczekujących',
    'panel.accounts.MaxPendingOrdersValue': 'Maksymalna wartość zleceń oczekujących',
    'panel.accounts.MaxPositionQtyPerSymbol': 'Maksymalna wartość pozycji dla instrumentu',
    'panel.accounts.MaxPositions': '# Maksymalna ilość pozycji',
    'panel.accounts.MaxWeekLostLimit': 'Maksymalna tygodniowa strata',
    'panel.accounts.MinMargin': 'Depozyt wniesiony',
    'panel.accounts.Murkups': 'Mark-upy',
    'panel.accounts.N/A_TelephoneNumber': 'Brak',
    'panel.accounts.OptionPremiumReq': 'Option​ ​premium​ ​req.',
    'panel.accounts.OptionValue': 'Wartość opcji',
    'panel.accounts.Orders': 'Aktywne zlecenia',
    'panel.accounts.Owner': 'Właściciel',
    'panel.accounts.Positions': 'Otwarte pozycje',
    'panel.accounts.PowerOfAttorney': 'Pełnomocnictwo',
    'panel.accounts.Profit': 'Otwarty Z/S Brutto',
    'panel.accounts.ProfitNet': 'Otwarty Z/S Netto',
    'panel.accounts.Role': 'Pakiet',
    'panel.accounts.Spreads': 'Spready',
    'panel.accounts.Status': 'Status',
    'panel.accounts.Collateral': '#hidden#',
    'panel.accounts.BuyingPower': '#hidden#',
    'panel.accounts.StocksLiquidity': 'Płynność akcji',
    'panel.accounts.StocksOrdersReq': 'Wymagania dla zleceń na akcje',
    'panel.accounts.StocksValue': 'Wartość akcji',
    'panel.accounts.StopOut': 'Stop Out',
    'panel.accounts.stopOutPercent': 'Stop Out %',
    'panel.accounts.stopTradingPercent': 'Poziom stop trading',
    'panel.accounts.Swap': 'Swap',
    'panel.accounts.TelephoneNumber': 'Numer telefonu',
    'panel.accounts.TodayFees': 'Prowizja',
    'panel.accounts.TodayRebates': 'Dzisiejsze rabaty',
    'panel.accounts.TodaySwaps': '#hidden#',
    'panel.accounts.TodayTrades': '# Liczba transakcji',
    'panel.accounts.TodayTurnover': 'Wartość obrotu',
    'panel.accounts.TodayVolume': 'Wolumen',
    'panel.accounts.TotalMaxPositionsQty': 'Maksymalna wartość w lotach',
    'panel.accounts.TrailingDrawdownLevel': 'Obsunięcie kapitału',
    'panel.accounts.UnrealizedLoss': 'Niezrealizowana strata',
    'panel.accounts.UnrealizedLossLimit': 'Niezrealizowany limit straty',
    'panel.accounts.UnsettledCash': 'Nierozliczona gotówka',
    'panel.accounts.UserGroup': 'Grupa Klientów',
    'panel.accounts.UserID': 'Nr Klienta',
    'panel.accounts.UserLogin': 'Login',
    'panel.accounts.VolumeLimitForEquities': 'Limit wolumenu - akcje',
    'panel.accounts.VolumeLimitForFutures': 'Limit wolumenu - futures',
    'panel.accounts.VolumeLimitForOptions': 'Limit wolumenu -opcje',
    'panel.accounts.WithdrawalAvailable': 'Dostępne do wypłaty',
    'panel.accounts.WaivedMargin': 'Waived margin',
    'panel.accounts.WaivedMargin.descr': 'Waived margin for the account',
    'panel.accounts.WarningMarginReq': 'Poziom Margin Call',
    'panel.accounts.WarningMarginReq.descr': 'Poziom Margin Call',
    'panel.accounts.WarningMarginReqPercent': 'Poziom Margin Call %',
    'panel.accounts.WarningMarginReqPercent.descr': 'Poziom Margin Call w odniesieniu do salda rachunku',
    'panel.accounts.MarginBeforeWarning': 'Depozyt ponad Margin Call',
    'panel.accounts.MarginBeforeWarning.descr': 'Depozyt dostępny do handlu, powyżej Margin Call',
    'panel.assetBalances': 'Rachunki',
    'panel.backoffice': 'Backoffice',
    'panel.backoffice.You need to add connection!': 'Dodaj połączenie!',
    'panel.caption.NotAllowedMode': 'Bieżący panel nie jest dostępny',
    'panel.caption.showHideToolbar': 'Pokaż pasek narzędzi',
    'panel.chart': 'Wykres',
    'panel.close': 'Zamknij',
    'panel.closePosition.Gross_P/L_ToClose': 'Z/S brutto do zamknięcia',
    'panel.closePosition.QunatityToClose': 'Ilość do zamknięcia',
    'panel.closePosition.productTypeText': 'Typ produktu',
    'panel.copypanel': 'Duplikuj okno',
    'panel.detail': 'Dokładne kwotowanie',
    'panel.detail.AuctionEndTime': '#hidden#',
    'panel.detail.CBAuctionStart': '#hidden#',
    'panel.detail.change': 'Zmiana',
    'panel.detail.changePr': 'Zmiana, %',
    'panel.detail.CurrentSession': '#hidden#',
    'panel.detail.high': 'Max',
    'panel.detail.last': 'Ostatnia',
    'panel.detail.Limit.High': '#hidden#',
    'panel.detail.Limit.Low': '#hidden#',
    'panel.detail.low': 'Min',
    'panel.detail.NormalMarketSize': '#hidden#',
    'panel.detail.OffExchangeValue': '#hidden#',
    'panel.detail.OffExchangeVolume': '#hidden#',
    'panel.detail.open': 'Otwarcie',
    'panel.detail.RemainingQty': '#hidden#',
    'panel.detail.RemainingQtySide': '#hidden#',
    'panel.detail.TradedValue': '#hidden#',
    'panel.detail.TradingStatus': '#hidden#',
    'panel.detail.TotalBuyQty': '#hidden#',
    'panel.detail.TotalSellQty': '#hidden#',
    'panel.detail.LastTradedTime': '#hidden#',
    'panel.detail.LastUpdateTime': '#hidden#',
    'panel.detail.AvgTradedPrice': '#hidden#',
    'panel.detail.NSEValue': '#hidden#',
    'panel.detail.DPR': '#hidden#',
    'panel.detail.FiftyTwoWeekHighPrice': '#hidden#',
    'panel.detail.FiftyTwoWeekLowPrice': '#hidden#',
    'panel.detail.volume': 'Wolumen',
    'panel.EventLog.Action': 'Wydarzenie',
    'panel.EventLog.Date': 'Data',
    'panel.EventLog.eventType.alerts': 'Alarmy',
    'panel.EventLog.eventType.comment': 'Komentarz',
    'panel.EventLog.eventType.disconnect': 'Rozłączenie',
    'panel.EventLog.eventType.exception': 'Wyjątek',
    'panel.EventLog.eventType.info': 'Info',
    'panel.EventLog.eventType.none': 'Brak',
    'panel.EventLog.eventType.system': 'System',
    'panel.EventLog.eventType.trading': 'Handel',
    'panel.EventLog.eventType.updater': 'Updater',
    'panel.EventLog.menu.Clear': 'Wyczyść',
    'panel.EventLog.Message': 'Szczegóły',
    'panel.EventLog.NamePanel': 'Log',
    'panel.EventLog.Roundtrip': 'Roundtrip, ms',
    'panel.EventLog.Type': 'Typ',
    'panel.external.Attach': 'Dokuj',
    'panel.external.Dettach': 'Odłącz',
    'panel.GridPanel.listButton.ToolTip': 'Wybierz instrument',
    'panel.GridPanel.refreshAllCharts.ToolTip': 'Odśwież wszystkie wykresy',
    'panel.GridPanel.resetMI.Text': 'Resetuj rozmiar okna',
    'panel.informer': 'Lista instrumentów',
    'panel.Ideas': 'Pomysły',
    'panel.Ideas.riskDisclosure': 'Informacja o ryzyku',
    'panel.Ideas.riskDisclosure.agreeBtnTxt': 'Zgadzam się',
    'panel.Ideas.productTypeChangeToIntraday': 'The idea was suggested with the product type - Delivery. You changed the product type on Intraday. Are you sure?',
    'panel.Ideas.productTypeChangeToDelivery': 'The idea was suggested with the product type - Intraday. You changed the product type on Delivery. Are you sure?',
    'panel.Ideas.AttachmentFile': 'Załącznik',
    'panel.Ideas.AttachmentScreen': 'Ekran',
    'panel.Ideas.orderButtonTextBuy': 'Złóż zlecenie kupna',
    'panel.Ideas.orderButtonTextSell': 'Złóż zlecenie sprzedaży',
    'panel.Ideas.orderButtonVisibility': 'Widoczność',
    'panel.Ideas.quantityVisiblility': 'Widoczność',
    'panel.Ideas.DealTicketHeader': 'Pomysły',
    'panel.Ideas.DealTicketText': 'Otrzymałeś nowy pomysł handlowy od ',
    'panel.Ideas.SubscribeDealTicketText': 'Subskrybowałeś ',
    'panel.Ideas.UnsubscribeDealTicketText': 'You have unsubscribed from ',
    'panel.Ideas.FilteringByInstrumentTypes': '#hidden#',
    'panel.Ideas.FilteringAllTypes': 'Wszystkie',
    'screen.Alerts.Settings.Screen': 'Ustawienia alarmów',
    'screen.Alerts.Settings.ContextMenu': 'Ustawienia...',
    'screen.Alerts.Ask': 'Ask',
    'screen.Alerts.Bid': 'Bid',
    'screen.Alerts.Last': 'Ostatni',
    'screen.Alerts.ChangePr': '[%]',
    'screen.Alerts.Volume': 'Wolumen',
    'property.highlightExtendedSessionsColor': 'Podświetlenie - rozszerzone sesje',
    'property.FitAlerts': 'Dopasuj alarmy',
    'screen.Alerts.Action.PlaceOrder': 'Złóż zlecenie + wiadomość',
    'screen.Alerts.Action.Notify': 'Wiadomość',
    'screen.Alerts.Action.Edit': 'Edytuj',
    'screen.Alerts.Condition.Greater': 'Większa',
    'screen.Alerts.Condition.Less': 'Mniejsza',
    'screen.Alerts.Condition.Greater or equal': 'Większa lub równa',
    'screen.Alerts.Condition.Less or equal': 'Mniejsza lub równa',
    'screen.Alerts.Notify.Popup': 'Pop-up',
    'screen.Alerts.Notify.Sound': 'Dźwięk',
    'screen.Alerts.Notify.Push': 'Wiadomość',
    'screen.Alerts.Notify.Email': 'Email',
    'screen.Alerts.Action.Start': 'Start',
    'screen.Alerts.AddAlert': 'Nowy alarm',
    'screen.Alerts.AddAlert.Tooltip': 'Kliknij, aby utowrzyć nowy alarm',
    'screen.Alerts.AddAlert.Disabled.Tooltip': 'Osiągnięto maksymalną liczbę alarmów',
    'screen.Alerts.noDataText': 'Brak alarmów',
    'screen.Alerts.AfterExecute.Remove': 'Usuń',
    'screen.Alerts.AfterExecute.Stop': 'Stop',
    'screen.Alerts.Alert': 'Alarm',
    'screen.Alerts.Button.Apply.Tooltip': 'Kliknij, aby zapisać zmiany',
    'screen.Alerts.Button.Create': 'Utwórz',
    'screen.Alerts.Button.Create.Tooltip': 'Pole nie może być puste',
    'screen.Alerts.Header.Create': 'Utwórz alarm',
    'screen.Alerts.Header.Edit': 'Edycja',
    'screen.Alerts.Importance.High': 'Wysoki',
    'screen.Alerts.Importance.Low': 'Niski',
    'screen.Alerts.Importance.Medium': 'Średni',
    'screen.Alerts.Label.Action': 'Wykonaj',
    'screen.Alerts.Label.Action.InfoTooltip': 'Alarm będzie aktywny tylko, jeżeli aplikacja będzie włączona',
    'screen.Alerts.Label.Notify': 'Typ powiadomienia',
    'screen.Alerts.Label.Notify.InfoTooltip': '',
    'screen.Alerts.Label.OrderParameters': 'Parametry zlecenia',
    'screen.Alerts.Label.OrderParameters.Tooltip': 'Kliknij, aby ustawić paramtery zlecenia',
    'screen.Alerts.Label.AfterExecute.Tooltip': 'Wybierz, co ma zrobić alarm po tym, gdy się wykona',
    'screen.Alerts.Label.AfterExecute': 'Po wykonaniu',
    'screen.Alerts.Label.AlertType.Tooltip': 'Ustaw typ alarmu',
    'screen.Alerts.Label.AlertType': 'Typ alarmu',
    'screen.Alerts.Label.Condition.Tooltip': 'Ustaw warunek alarmu',
    'screen.Alerts.Label.Condition': 'Warunek',
    'screen.Alerts.Label.Importance': 'Priorytet',
    'screen.Alerts.Label.Symbol': 'Symbol',
    'screen.Alerts.Label.Account': 'Rachunek',
    'screen.Alerts.Label.Value': 'Wartość',
    'screen.Alerts.Label.Expiration': 'Wygasanie',
    'screen.Alerts.Label.Expiration.Tooltip': 'Data i czas wygaśnięcia alarmu',
    'screen.Alerts.Label.Expiration.InfoTooltip': 'Po wykonaniu, alarm zostanie usunięty',
    'screen.Alerts.Message': 'Wiadomość',
    'screen.Alerts.Notify.All': 'Wszystkie',
    'screen.Alerts.RemoveAlertMessage': 'Alarm zostanie usunięty. Kontynuować?',
    'screen.Alerts.TableItem.Condition': 'Warunek',
    'screen.Alerts': 'Alarmy',
    'screen.Alerts.visibility': '#not hidden#',
    'screen.Alerts.WarningHeader': 'Uwaga',
    'screen.Alerts.WarningText': 'Uwaga! Alarm będzie aktywny tylko, jeżeli aplikacja będzie włączona',
    'screen.Alerts.SendOeAlert': 'Zlecenie {0} zostało wysłane',
    'screen.Alerts.item.stopped': 'Nieaktywny',
    'screen.Alerts.item.active': 'Aktywny',
    'panel.instrumentInfo.AvgPrice': 'Średnia cena:',
    'panel.instrumentInfo.P/L': 'Z/S:',
    'panel.instrumentInfo.Qty': 'Ilość:',
    'panel.instruments': 'Instrumenty',
    'panel.instrumentsInfo': 'Informacja o instrumencie',
    'panel.knowledgebase': 'Baza wiedzy',
    'panel.assets': 'Aktywa',
    'panel.Assets.Asset type': 'Rodzaj aktywa',
    'panel.Assets.Description': 'Opis',
    'panel.Assets.Interest rate, %': 'Stopa procentowa, %',
    'panel.Assets.Minimal change': 'Minimalna zmiana',
    'panel.Assets.Name': 'Nazwa',
    'panel.Assets.NoAssetsAvailable': 'Brak dostępnych aktywów',
    'panel.Assets.QuickTable.Column.Account': 'Rachunek',
    'panel.Assets.QuickTable.Column.AssetName': 'Nazwa instrumentu',
    'panel.Assets.QuickTable.Column.AvailableQtySell': 'Dostępna ilość - sprzedaż',
    'panel.Assets.QuickTable.Column.CurrentPrice': 'Cena',
    'panel.Assets.QuickTable.Column.CurrentQty': '#hidden#',
    'panel.Assets.QuickTable.Column.CurrentValue': '#hidden#',
    'panel.Assets.QuickTable.Column.CurrentValueSell': 'Dostępna wartość - sprzedaż',
    'panel.Assets.QuickTable.Column.DefaultSymbol': 'Domyślny instrument',
    'panel.Assets.QuickTable.Column.ExchangeTrading': 'Giełda',
    'panel.Assets.QuickTable.Column.LiquidityRate': 'Poziom płynności, %',
    'panel.Assets.QuickTable.Column.SellAsset.cellsTooltip': 'Sprzedaj instrument',
    'panel.Assets.QuickTable.Column.SellAsset': 'Sprzedaj',
    'panel.Assets.QuickTable.Column.StartDayQty': 'Ilość - poczatek dnia',
    'panel.Assets.QuickTable.Column.StartDayQtyMargin': 'Ilość - poczatek dnia - dostępny margin',
    'panel.Assets.QuickTable.Column.TodayTradedQty.descr': 'Wolumen netto dla handlu dziś',
    'panel.Assets.QuickTable.Column.TodayTradedQty': 'Wolumen transakcji - dziś',
    'panel.Assets.QuickTable.Column.SellExchange': 'Sprzedaj na giełdzie',
    'panel.Assets.Menu.SellAsset': 'Sprzedaj aktywo',
    'panel.Assets.Menu.SellAssetOn': 'Sprzedaj aktywo na',
    'IsAllowedResponceReason.NoAssetsSelected': 'Nie wybrano aktywów',
    'IsAllowedResponceReason.MoreThanOneAssetIsSelected': 'Wybrano więcej niż jeden instrument',
    'panel.level2': 'Głębokość rynku',
    'panel.Level2.Age': '#hidden#',
    'panel.level2.Auction': ' AUC',
    'panel.Level2.AvgPrice': 'Średnia cena',
    'panel.Level2.Contr. CCY Total value': 'Wartość waluty kwotowanej razem',
    'panel.Level2.Contr. CCY value': 'Wartość waluty kwotowanej',
    'panel.Level2.DayTradeVolume': 'Wolumen',
    'panel.Level2.menu.View': 'Widok',
    'panel.Level2.menu.View.InstrInfo': 'Pozycja',
    'panel.Level2.menu.View.Level1': 'Poziom 1',
    'panel.Level2.menu.View.Order Entry': 'Złóż zlecenie',
    'panel.Level2.MPID': 'MPID',
    'panel.Level2.Number': 'Zlecenie',
    'panel.Level2.oe.BuyButton': 'Kup',
    'panel.Level2.oe.SellButton': 'Sprzedaj',
    'panel.Level2.Price': 'Cena',
    'panel.Level2.Size': 'Ilość',
    'panel.Level2.Source': 'Źródło',
    'panel.Level2.Time': 'Czas',
    'panel.Level2.TotalVol': 'Łączna wielkość',
    'panel.Level2.NumberOfOrders': '#hidden#',
    'panel.log': 'Log',
    'panel.menu.GroupBy': 'Grupuj',
    'panel.menu.new': 'Nowy',
    'panel.menu.Remove': 'Usuń',
    'panel.menu.resetToDefault': 'Przywróć domyślne',
    'panel.menu.renameList': 'Kliknij, aby zmienić nazwę listy instrumentów',
    'panel.menu.removeList': 'Kliknij, aby usunąć listę instrumentów',
    'panel.menu.renameTemplate': 'Kliknij, aby zmienić nazwę szablonu',
    'panel.menu.removeTemplate': 'Kliknij, aby usunąć szablon',
    'panel.menu.saveList': 'Kliknij, aby zapisać szablon',
    'panel.menu.symbolWasNotFound': 'Nie znaleziono instrumentu',
    'panel.neworder': 'Złóż zlecenie',
    'panel.newOrderEntry.accountLabel': 'Rachunek',
    'panel.newOrderEntry.AddOrder': 'Złóż zlecenie',
    'panel.newOrderEntry.AddAskButton': 'Sprzedaj Ask',
    'panel.newOrderEntry.AddBitButton': 'Kup Bid',
    'panel.newOrderEntry.amountLabel': 'Ilość',
    'panel.newOrderEntry.BuyAskButton': 'Kup Ask',
    'panel.newOrderEntry.BuyMarketButton': 'Kup Market',
    'panel.newOrderEntry.buySideCheckBox': 'Kup',
    'panel.newOrderEntry.delayedText': 'Opóźnione dane',
    'panel.newOrderEntry.disclosedLabel': '#hidden#',
    'panel.newOrderEntry.disclosedLabel.tt': 'Ustaw ilość',
    'panel.newOrderEntry.instrumentLabel': 'Instrument',
    'panel.newOrderEntry.limitPricePanel': 'Cena limit',
    'panel.newOrderEntry.placeOrderButton': 'Złóż zlecenie',
    'panel.newOrderEntry.ProductType': 'Typ instrumentu',
    'panel.newOrderEntry.RefreshMarginReq': 'Kliknij, aby odświeżyć wymagania depozytu',
    'panel.newOrderEntry.riskDetailsLabel': 'Margin',
    'panel.newOrderEntry.SellBidButton': 'Sprzedaj Bid',
    'panel.newOrderEntry.SellMarketButton': 'Sprzedaj Market',
    'panel.newOrderEntry.sellSideCheckBox': 'Sprzedaj',
    'panel.newOrderEntry.Side': 'Strona',
    'panel.newOrderEntry.singleOrderRadio': 'Pojedyncze',
    'panel.newOrderEntry.slPriceRisk': 'SL cena',
    'panel.newOrderEntry.slPriceRiskOffset': 'SL dystans',
    'panel.newOrderEntry.snapshotText': 'Zrzut ekranu',
    'panel.newOrderEntry.snapshotBtn.enabled.tt': 'Zapisz zrzut ekranu',
    'panel.newOrderEntry.snapshotBtn.disabled.tt': 'Osiągnięto limit',
    'panel.newOrderEntry.stopLossCheckBox': 'Stop Loss',
    'panel.newOrderEntry.stopLossCheckBox.offset': 'SL dystans',
    'panel.newOrderEntry.stopPricePanel': 'Cena Stop',
    'panel.newOrderEntry.stopLimitLimitPrice': 'Cena SL Limit',
    'panel.newOrderEntry.stopLimitLimitPrice.tt': 'Ustaw cenę SL Limit',
    'panel.newOrderEntry.stopLimitLimitOffset': 'SL Limit dystans',
    'panel.newOrderEntry.stopLimitLimitOffset.tt': 'Ustaw dystans SL Limit',
    'panel.newOrderEntry.stopInfoBtn.tt': 'Dodatkowa cena limit jest dostępna ze względu na aktywną opcję “Stop limit zamiast Stop”',
    'panel.newOrderEntry.takeProfitCheckBox': 'Take Profit',
    'panel.newOrderEntry.takeProfitCheckBox.offset': 'TP dystans',
    'panel.newOrderEntry.tifPanel': 'Ważność',
    'panel.newOrderEntry.tpPriceRisk': 'TP cena',
    'panel.newOrderEntry.tpPriceRiskOffset': 'TP dystans',
    'panel.newOrderEntry.trstopOffset': 'Tr. stop dystans',
    'panel.newOrderEntry.typePanel': 'Rodzaj zlecenia',
    'panel.newOrderEntry.stopLossNumeric.ToolTip.offset': 'Ustawiono Stop Loss jako dystans. Aby zmienić - wybierz Opcje\\Domyślne',
    'panel.newOrderEntry.stopLossNumeric.ToolTip': 'Ustawiono Stop Loss jako cena. Aby zmienić - wybierz Opcje\\Domyślne',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip.offset': 'Ustawiono Take Profit jako dystans. Aby zmienić - wybierz Opcje\\Domyślne',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip': 'Ustawiono Take Profit jako cena. Aby zmienić - wybierz Opcje\\Domyślne',
    'panel.NewTrades.Account': 'Rachunek',
    'panel.NewTrades.Amount': 'Ilość',
    'panel.NewTrades.Bought': 'Kupiono',
    'panel.NewTrades.Commission': 'Prowizja',
    'panel.NewTrades.Commission.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Date': 'Data/Czas',
    'panel.NewTrades.Exchange': '#hidden#',
    'panel.NewTrades.ExchangeTrading': 'Rynek',
    'panel.NewTrades.ExecutionVenue': 'Miejsce wykonania',
    'panel.NewTrades.ExpirationDate': 'Data wygasania',
    'panel.NewTrades.ExternalOrderId': '#hidden#',
    'panel.NewTrades.ExternalPrice': 'Zewnętrzna cena',
    'panel.NewTrades.ExternalTradeId': '#hidden#',
    'panel.NewTrades.instrType': 'Typ instrumentu',
    'panel.NewTrades.Instrument': 'Instrument',
    'panel.NewTrades.Login': 'Login',
    'panel.NewTrades.Net_PL': 'Z/S netto',
    'panel.NewTrades.Net_PL.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Operation': 'Strona',
    'panel.NewTrades.OrderId': 'Nr zlecenia',
    'panel.NewTrades.OrderType': 'Rodzaj zlecenia',
    'panel.NewTrades.Price': 'Cena',
    'panel.NewTrades.ProductType': 'Typ produktu',
    'panel.NewTrades.PurchasePrice': 'Ekspozycja',
    'panel.NewTrades.RealizedProfitLoss': 'Z/S brutto',
    'panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Rebates': 'Rabaty',
    'panel.NewTrades.Route': 'Route',
    'panel.NewTrades.Sold': 'Sprzedano',
    'panel.NewTrades.StrikePrice': 'Cena strike',
    'panel.NewTrades.Time': 'Czas',
    'panel.NewTrades.TradeID': 'Trade ID',
    'panel.NewTrades.TradeIDLabel': '#hidden#',
    'panel.NewTrades.Description': 'Opis',
    'panel.news': 'Informacje',
    'panel.news.feed': 'Strumień',
    'panel.news.feedComboBox.tt': 'Wybierz strumień informacji',
    'panel.news.all': 'Wszystkie',
    'panel.news.received': 'Otrzymane',
    'panel.news.source': 'Źródło',
    'panel.news.subject': 'Temat',
    'panel.news.context': 'Treść',
    'panel.news.category': 'Kategoria',
    'panel.news.symbol': 'Instrument',
    'panel.news.priority': 'Priorytet',
    'panel.news.Priority_1': 'Wysoki',
    'panel.news.Priority_10': 'Niski',
    'panel.news.Priority_5': 'Średni',
    'panel.news.Priority_All': 'Wszystkie',
    'panel.news.menu.clearAll': 'Wyczyść tabelę',
    'panel.news.menu.removeOneRow': 'Usuń',
    'panel.news.noData': 'Brak danych do wyświetlenia',
    'panel.OrderBook.Account': 'Rachunek',
    'panel.OrderBook.Action': 'Strona',
    'panel.OrderBook.Bought': 'Kupiono',
    'panel.OrderBook.Date': 'Data/Czas',
    'panel.OrderBook.DislocedQty': '#hidden#',
    'panel.OrderBook.Event': 'Zdarzenie',
    'panel.OrderBook.ExpirationDate': 'Data wygasania',
    'panel.OrderBook.ExternalOrderID': '#hidden#',
    'panel.OrderBook.FilledQuantity': '#hidden#',
    'panel.OrderBook.instrType': 'Typ instrumentu',
    'panel.OrderBook.Instrument': 'Instrument',
    'panel.OrderBook.Login': 'Login',
    'panel.OrderBook.Message': 'Wiadomość',
    'panel.OrderBook.OrderNumber': 'Nr zlecenia',
    'panel.OrderBook.Price': 'Cena',
    'panel.OrderBook.ProductType': 'Typ produktu',
    'panel.OrderBook.Quantity': 'Ilość',
    'panel.OrderBook.ReminingQuantity': '#hidden#',
    'panel.OrderBook.RemoteID': 'Remote ID',
    'panel.OrderBook.Route': 'Route',
    'panel.OrderBook.Sold': 'Sprzedano',
    'panel.OrderBook.StopPrice': 'Cena stop',
    'panel.OrderBook.StrikePrice': 'Cena strike',
    'panel.OrderBook.TIF': 'Ważność',
    'panel.OrderBook.Time': 'Czas',
    'panel.OrderBook.TraderID': 'Zmodyfikowano przez użytkownika',
    'panel.OrderBook.Type': 'Rodzaj',
    'panel.OrderBook.ExchangeTrading': 'Giełda',
    'panel.OrderBook.Description': 'Opis',
    'panel.orderEntry': 'Złóż zlecenie',
    'panel.orderHistory': 'Historia zleceń',
    'panel.orderHistory.print': '#hidden#',
    'panel.orders': 'Zlecenia bieżące',
    'panel.orders.Account': 'Rachunek',
    'panel.orders.action': 'Strona',
    'panel.orders.AvgFilledPrice': '#hidden#',
    'panel.orders.BindTo': 'Przywiązane do',
    'panel.orders.Cancel': 'Anuluj',
    'panel.orders.Comment': '#hidden#',
    'panel.orders.Comments': 'Notatka',
    'panel.orders.Ct_Ahead': 'Ct. ahead',
    'panel.orders.CurrentPrice': 'Cena rynkowa',
    'panel.orders.date': 'Data',
    'panel.orders.date_time': 'Data/Czas',
    'panel.orders.disclosedQty': '#hidden#',
    'panel.orders.Event': 'Zdarzenie',
    'panel.orders.Exchange': 'Giełda',
    'panel.orders.ExchangeTrading': 'Giełda',
    'panel.orders.ExpirationDate': 'Data wygasania',
    'panel.orders.ExternalOrderId': '#hidden#',
    'panel.orders.ExternalOrderStatus': '#hidden#',
    'panel.orders.ExternalOrderStatus_0': 'Nowe',
    'panel.orders.ExternalOrderStatus_1': 'Przyjęte',
    'panel.orders.ExternalOrderStatus_2': 'Częściowo wykonane',
    'panel.orders.ExternalOrderStatus_3': 'Wykonane',
    'panel.orders.ExternalOrderStatus_4': 'Odrzucone',
    'panel.orders.ExternalOrderStatus_5': 'Anulowane',
    'panel.orders.ExternalOrderStatus_6': 'Usunięte w celu modyfikacji',
    'panel.orders.ExternalOrderStatus_7': 'Oczekująca modyfikacja',
    'panel.orders.ExternalOrderStatus_8': 'Oczekująca modyfikacja',
    'panel.orders.ExternalOrderStatus_9': 'Zamrożone',
    'panel.orders.ExternalOrderStatus_10': 'Oczekujące',
    'panel.orders.ExternalOrderStatus_11': 'Nie złożone',
    'panel.orders.ExternalOrderStatus_12': 'Zawieszone',
    'panel.orders.GroupId': 'MAM ID',
    'panel.orders.InitReq': 'Depozyt wstępny',
    'panel.orders.Instr_Type': 'Typ instrumentu',
    'panel.orders.Login': 'Login',
    'panel.orders.Market': 'Rynek',
    'panel.orders.menu.CancelAllByInstrument': 'Anuluj wszystkie wg. instrumentu',
    'panel.orders.menu.CancelAllForAccount': 'Anuluj wszystkie wg. rachunku',
    'panel.orders.menu.CancelAllOrders': 'Anuluj wszystkie zlecenia',
    'panel.orders.menu.CancelOrder': 'Anuluj zlecenie',
    'panel.orders.menu.ExecuteAsMarket': 'Wykonaj po cenie market',
    'panel.orders.menu.ExecuteAsMarket.question': 'Czy chcesz wykonać zlecenie po najlepszej cenie market?',
    'panel.orders.menu.ModifyOrder': 'Modyfikuj zlecenie',
    'panel.orders.menu.MoreOneSymbolInfoDisabled.tt': 'Wybrano więcej niż jedno zlecenie',
    'panel.orders.menu.NoSymbolInfoDisabled.tt': 'Brak zaznaczonych zleceń',
    'panel.orders.menu.Remove': 'Usuń zlecenie',
    'panel.orders.OCOGrouID': 'Numer grupy OCO',
    'panel.orders.price': 'Cena',
    'panel.orders.ProductType': 'Typ instrumentu',
    'panel.orders.quantity': 'Ilość',
    'panel.orders.QuantityFilled': 'Wykonana ilość',
    'panel.orders.QuantityRemaining': 'Pozostało do wykonania',
    'panel.orders.Reference': 'Nr zlecenia',
    'panel.orders.RemoteID': 'Remote ID',
    'panel.orders.Route': 'Route',
    'panel.orders.SL': 'SL',
    'panel.orders.SL_Offset': 'SL dystans',
    'panel.orders.SL_Price': 'Cena Stop Loss',
    'panel.orders.SLL_Price': 'Cena SL limit',
    'panel.orders.SLL_Offset': 'Dystans SL limit',
    'panel.orders.Status': 'Status',
    'panel.orders.StopPrice': 'Cena Stop',
    'panel.orders.StrikePrice': 'Strike price',
    'panel.orders.symbol': 'Instrument',
    'panel.orders.symbolDescription': 'Opis instrumentu',
    'panel.orders.TIF': 'Ważność',
    'panel.orders.time': 'Czas',
    'panel.orders.Token': 'Nr zlecenia',
    'panel.orders.TP': 'TP',
    'panel.orders.TP_Offset': 'TP dystans',
    'panel.orders.TP_Price': 'TP cena',
    'panel.orders.TraderID': 'Trader ID',
    'panel.orders.type': 'Rodzaj',
    'panel.orders.UserGroup': 'Grupa użytkowników',
    'panel.orders.showChartMI': '#hidden#',
    'panel.orders.showMDMI': '#hidden#',
    'panel.orders.showTSMI': '#hidden#',
    'panel.orders.showOEMI': '#hidden#',
    'panel.positions': 'Pozycje',
    'panel.positions.Account': 'Rachunek',
    'panel.positions.AvailableForSell': '#hidden#',
    'panel.positions.Basis': 'Cena otwarcia',
    'panel.positions.Close': 'Zamknij',
    'panel.positions.comments': 'Komentarz',
    'panel.positions.commissions': 'Prowizja',
    'panel.positions.commissions.InstrumentCurrency': '#hidden#',
    'panel.positions.confirmation.Reverse': 'Odwróć',
    'panel.positions.confirmation.ReverseAllPositions': 'Czy na pewno chcesz odwrócić pozycje?',
    'panel.positions.confirmation.ReverseByInstrument': 'Czy napewno chcesz odwrócić wszystkie pozycje na instrumencie?',
    'panel.positions.confirmation.ReverseSelectedPositions': 'Czy napewno chcesz odwrócić wybraną pozycję?',
    'panel.positions.confirmation.ModifyProductType': 'Zmień typ produktu z {0} do {1} dla {2}, {3}, {4} ?',
    'panel.positions.cur_price': 'Cena rynkowa',
    'panel.positions.date_time': 'Data/Czas',
    'panel.positions.Delta': 'Delta',
    'panel.positions.ExpDate': 'Data wygaśnięcia',
    'panel.positions.exposition_usd': 'Ekspozycja',
    'panel.positions.Gamma': 'Gamma',
    'panel.positions.GroupId': 'MAM ID',
    'panel.positions.InitReq': 'Depozyt wstępny',
    'panel.positions.IV': 'IV',
    'panel.positions.Login': 'Login',
    'panel.positions.MaintReq': 'Depozyt utrzymania',
    'panel.positions.MarginSuplus': 'Nadwyżka/Niedobór Margin',
    'panel.positions.menu.Close': 'Zamknij pozycję',
    'panel.positions.menu.ExercisePosition': 'Wykonaj pozycję',
    'panel.positions.menu.ExerciseCancel': 'Anuluj wykonanie',
    'panel.positions.menu.ExercisePositionDisabled.tt': 'Zaznaczono więcej niż jedną pozycję',
    'panel.positions.menu.CancelExerciseDisabledCuzTrSession.tt': 'Operacja jest niedozwolona',
    'panel.positions.menu.ModifyProductType': 'Zmodyfikuj typ produktu',
    'panel.positions.menu.Modify': 'Modyfikuj pozycję',
    'panel.positions.menu.MoreOneSymbolInfoDisabled.tt': 'Wybrano więcej niż jedną pozycję',
    'panel.positions.menu.NoSymbolInfoDisabled.tt': 'Nie zaznaczono żadnej pozycji',
    'panel.positions.menu.ShowToolbar': 'Pasek narzędzi',
    'panel.positions.menu.ShowTotals': 'Pokaż sumy',
    'panel.positions.menu.DisplayTrades': 'Pokaż transakcje',
    'panel.positions.menu.ShowGroupQtyAs': '#hidden#',
    'panel.positions.menu.ShowGroupQtyAs.locKey': 'Pokaż ilość jako',
    'panel.positions.menu.ShowGroupQtyAs.Total': 'Suma',
    'panel.positions.menu.ShowGroupQtyAs.Net': 'Ilość netto',
    'panel.positions.menu.View': 'Widok',
    'panel.positions.Net_PL': 'Z/S netto',
    'panel.positions.Net_PL.InstrumentCurrency': '#hidden#',
    'panel.positions.Operation': 'Strona',
    'panel.positions.PL_ticks': 'Z/S, dystans',
    'panel.positions.PL_ticks.points': 'punkty',
    'panel.positions.PL_ticks.ticks': 'ticki',
    'panel.positions.pos_number': 'Nr pozycji',
    'panel.positions.PositionValue': 'Wartość pozycji',
    'panel.positions.ProductType': 'Typ instrumentu',
    'panel.positions.profit_usd': 'Z/S brutto',
    'panel.positions.profit_usd.InstrumentCurrency': '#hidden#',
    'panel.positions.Qunatity': 'Ilość',
    'panel.positions.RealizedPnL': '#hidden#',
    'panel.positions.RealizedPnL.InstrumentCurrency': '#hidden#',
    'panel.positions.Rho': 'Rho',
    'panel.positions.route': 'Route',
    'panel.positions.SL': 'Cena SL',
    'panel.positions.SL_Value': 'Wartość SL',
    'panel.positions.SLL': 'Cena SL limit',
    'panel.positions.strike': 'Strike price',
    'panel.positions.swaps': 'Swapy',
    'panel.positions.Symbol': 'Instrument',
    'panel.positions.SymbolDescription': 'Opis instrumentu',
    'panel.positions.Theta': 'Theta',
    'panel.positions.TP': 'Cena TP',
    'panel.positions.type': 'Typ instr.',
    'panel.positions.UsedMargin': 'Wykorzystany margin',
    'panel.positions.UserGroup': 'Grupa użytkownika',
    'panel.positions.Vega': 'Vega',
    'panel.positions.ExchangeTrading': 'Giełda',
    'panel.positions.showChartMI': '#hidden#',
    'panel.positions.showMDMI': '#hidden#',
    'panel.positions.showTSMI': '#hidden#',
    'panel.positions.showOEMI': '#hidden#',
    'panel.positionsBalance': 'Saldo pozycji',
    'panel.positionsBalance.Symbol': 'Instrument',
    'panel.positionsBalance.NetPositionQty': 'Ilość pozycji netto',
    'panel.positionsBalance.BreakEvenPoint': 'Break-even',
    'panel.positionsBalance.BreakEven': 'Break-even',
    'panel.positionsBalance.CurrentPrice': 'Cena rynkowa',
    'panel.positionsBalance.GrossPL': 'Z/S Brutto',
    'panel.positionsBalance.LongQty': 'Kupno ilość',
    'panel.positionsBalance.AverageLong': 'Kupno - średnia',
    'panel.positionsBalance.ShortQty': 'Sprzedaż ilość',
    'panel.positionsBalance.AverageShort': 'Sprzedaż - średnia',
    'panel.positionsBalance.GrossExposure': 'Ekspozycja brutto',
    'panel.positionsBalance.NetExposure': 'Ekspozycja netto',
    'panel.positionsBalance.StrikePrice': 'Strike price',
    'panel.positionsBalance.ExpDate': 'Data wygasania',
    'panel.positionsBalance.PositionValue': 'Wartość pozycji',
    'panel.positionsBalance.SymbType': 'Typ instrumentu',
    'panel.positionsBalance.ProductType': 'Typ produktu',
    'panel.positionsBalance.Symbol.tt': 'Instrument',
    'panel.positionsBalance.NetPositionQty.tt': 'Ilość pozycji netto',
    'panel.positionsBalance.BreakEvenPoint.tt': 'Break-even',
    'panel.positionsBalance.CurrentPrice.tt': 'Cena rynkowa',
    'panel.positionsBalance.GrossPL.tt': 'Z/S w walucie rachunku',
    'panel.positionsBalance.LongQty.tt': 'Ilość pozycji kupna',
    'panel.positionsBalance.AverageLong.tt': 'Średnia cena pozycji kupna',
    'panel.positionsBalance.ShortQty.tt': 'Ilość pozycji sprzedaży',
    'panel.positionsBalance.AverageShort.tt': 'Średnia cena pozycji sprzedaży',
    'panel.positionsBalance.GrossExposure.tt': 'Ekspozycja brutto otwartych pozycji',
    'panel.positionsBalance.NetExposure.tt': 'Ekspozycja netto otwartych pozycji',
    'panel.positionsBalance.StrikePrice.tt': 'Cena strike',
    'panel.positionsBalance.ExpDate.tt': 'Data wygasania',
    'panel.positionsBalance.PositionValue.tt': 'Wartość pozycji',
    'panel.positionsBalance.SymbType.tt': 'Typ instrumentu',
    'panel.positionsBalance.menu.MoreOneSymbolInfoDisabled.tt': 'Wybrano więcej niż jeden instrument',
    'panel.positionsBalance.menu.NoSymbolInfoDisabled.tt': 'Nie wybrano żadnego instrumentu',
    'panel.settings.Information': 'Informacja',
    'panel.settings.saveDefault': 'Pomyślnie zapisano ustawienia!',
    'panel.statement': 'Stan rachunku',
    'panel.statement.print': 'not hidden',
    'panel.statement.Account': 'Rachunek',
    'panel.statement.Instrument': 'Nazwa instrumentu',
    'panel.statement.Date': 'Data',
    'panel.statement.Operation_type': 'Typ operacji',
    'panel.statement.Operation_ID': 'Nr operacji',
    'panel.statement.Amount': 'Wielkość',
    'panel.statement.Open_price': 'Cena otwarcia',
    'panel.statement.Close_price': 'Cena zamknięcia',
    'panel.statement.Quantity': 'Ilość',
    'panel.statement.Currency': 'Waluta',
    'panel.statement.Trade_opening_date': 'Data otwarcia',
    'panel.statement.FX_rate': 'Cena FX',
    'panel.statement.searchBtn.tt': 'Szukaj',
    'panel.statement.noData': 'Brak historii rachunku',
    'panel.terceraChart': 'Wykres',
    'panel.TerceraSymbolLookupDropDownForm.Add': 'Dodaj',
    'panel.TerceraSymbolLookupDropDownForm.All': 'Wszystkie',
    'panel.TerceraSymbolLookupDropDownForm.Bonds': 'Obligacje',
    'panel.TerceraSymbolLookupDropDownForm.Cancel': 'Anuluj',
    'panel.TerceraSymbolLookupDropDownForm.CFDs': 'CFD',
    'panel.TerceraSymbolLookupDropDownForm.collapceAllButton': 'Minimalizuj wszystko',
    'panel.TerceraSymbolLookupDropDownForm.crypto': 'Krypto',
    'panel.TerceraSymbolLookupDropDownForm.Equities': 'Akcje',
    'panel.TerceraSymbolLookupDropDownForm.ETFs': 'ETF',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges': 'Wszystkie giełdy',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges': 'giełdy',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges': 'Brak giełdy',
    'panel.TerceraSymbolLookupDropDownForm.expandExchangesButton': 'Pokaż giełdy',
    'panel.TerceraSymbolLookupDropDownForm.expandTypesButton': 'Pokaż typy',
    'panel.TerceraSymbolLookupDropDownForm.expandGroupsButton': 'Pokaż grupy',
    'panel.TerceraSymbolLookupDropDownForm.Forex': 'Forex',
    'panel.TerceraSymbolLookupDropDownForm.Forward': 'Forward',
    'panel.TerceraSymbolLookupDropDownForm.Futures': 'Futures',
    'panel.TerceraSymbolLookupDropDownForm.indices': 'Indeksy',
    'panel.TerceraSymbolLookupDropDownForm.Options': 'Opcje',
    'panel.TerceraSymbolLookupDropDownForm.Portfolio': 'Synthetic',
    'panel.TerceraSymbolLookupDropDownForm.Select': 'Wybierz',
    'panel.TerceraSymbolLookupDropDownForm.Select all': 'Wybierz wszystkie',
    'panel.TerceraSymbolLookupDropDownForm.Show additionl info': 'Pokaż dodatkowe info',
    'panel.TerceraSymbolLookupDropDownForm.Spot': 'Spot',
    'panel.TerceraSymbolLookupDropDownForm.Spreadbet': 'Spreadbet',
    'panel.TerceraSymbolLookupDropDownForm.TBill': 'T-bill',
    'panel.TerceraSymbolLookupDropDownForm.Corporate': 'Corporate',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types': 'Wszystkie typy',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types': 'Brak',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.types': 'typy',
    'panel.timeSales': 'Time & Sales',
    'panel.timeSales.Agressor flag': 'Strona',
    'panel.timeSales.AgressorFlag.Auction': 'Auction',
    'panel.timeSales.AgressorFlag.Buy': 'Kupno',
    'panel.timeSales.AgressorFlag.CrossTrade': 'Cross Trade',
    'panel.timeSales.AgressorFlag.None': 'Brak',
    'panel.timeSales.AgressorFlag.Sell': 'Sprzedaż',
    'panel.timeSales.AgressorFlag.RLP': 'RLP',
    'panel.timeSales.ask': 'Ask',
    'panel.timeSales.AskBidSize': 'BidxAsk size',
    'panel.timeSales.AskSource': 'Asks MPID',
    'panel.timeSales.bid': 'Bid',
    'panel.timeSales.BidSource': 'Bids MPID',
    'panel.timeSales.BuyerSource': 'Kupujący',
    'panel.timeSales.Exchange': 'Źródło',
    'panel.timeSales.Instrument': 'Instrument',
    'panel.timeSales.MenuName': 'Time & Sales',
    'panel.timeSales.Price': 'Cena',
    'panel.timeSales.SellerSource': 'Sprzedawca',
    'panel.timeSales.Size': 'Wielkość',
    'panel.timeSales.Time': 'Czas',
    'panel.timeSales.Type': 'Typ instr.',
    'panel.tooltiper.System': 'System',
    'panel.trades': 'Transakcje',
    'panel.trades.print': '#hidden#',
    'panel.tradingCentral': 'Trading central',
    'panel.tradingCentral.screenBtn.text': 'Screen',
    'panel.tradingCentral.chartBtn.text': 'Chart',
    'panel.tradingCentral.ScreenButton': 'not hidden',
    'panel.tradingCentral.settings': 'Settings...',
    'panel.tradingCentral.settings.technicalAnalysis.term': 'Term',
    'panel.tradingCentral.settings.technicalAnalysis.days': 'Days',
    'panel.tradingCentral.settings.technicalAnalysis.lastOnly': 'Last only',
    'panel.tradingCentral.settings.alerts.lastOnly': 'Last only',
    'panel.tradingCentral.settings.alerts.days': 'Days',
    'panel.tradingCentral.settings.candlestick.lastOnly': 'Last only',
    'panel.tradingCentral.settings.candlestick.days': 'Days',
    'panel.tradingCentral.term.All': 'All',
    'panel.tradingCentral.term.Intraday': 'Intraday',
    'panel.tradingCentral.term.Short term': 'Short term',
    'panel.tradingCentral.term.Middle term': 'Middle term',
    'panel.tradingCentral.chart.resistance': 'Resistance',
    'panel.tradingCentral.chart.support': 'Support',
    'panel.tradingCentral.chart.pivot': 'Pivot',
    'panel.watchlist': 'Instrumenty',
    'panel.watchlist.Ask': 'Ask',
    'panel.watchlist.AskSize': 'Wolumen Ask',
    'panel.watchlist.AuctionEndTime': '#hidden#',
    'panel.watchlist.Bid': 'Bid',
    'panel.watchlist.BidSize': 'Wolumen Bid',
    'panel.watchlist.Buy': 'Kupno',
    'panel.watchlist.CBAuctionStart': '#hidden#',
    'panel.watchlist.cell.cancel': 'Usuń',
    'panel.watchlist.cell.modify': 'Modyfikuj',
    'panel.watchlist.cell.Not allow': 'Nie zezwalaj',
    'panel.watchlist.cell.sending': 'Nowa',
    'panel.watchlist.cell.ticks': 'ticki',
    'panel.watchlist.Change': 'Zmiana',
    'panel.watchlist.ChangeFromOpen': 'Zmiana dzień',
    'panel.watchlist.ChangeFromOpen%': 'Zmiana dzień, %',
    'panel.watchlist.ChangePr': 'Zmiana, %',
    'panel.watchlist.Close': 'Zamknięcie',
    'panel.watchlist.ClosePostMarket': 'Cena zamknięcia post-market',
    'panel.watchlist.Comment': 'Komentarz',
    'panel.watchlist.Company': 'Firma',
    'panel.watchlist.ContractSize': 'Wielkość zlecenia',
    'panel.watchlist.CurrentSession': 'Bieżąca sesja',
    'panel.watchlist.Date': 'Ostatnia zmiana',
    'panel.watchlist.Descr': 'Opis',
    'panel.watchlist.Exchange': 'Giełda',
    'panel.watchlist.ExpDate': 'Data wygaśnięcia',
    'panel.watchlist.High': 'Max',
    'panel.watchlist.High13': '#hidden#',
    'panel.watchlist.High26': '#hidden#',
    'panel.watchlist.High52': '#hidden#',
    'panel.watchlist.IndicativeAuctionPrice': 'Cena aukcji',
    'panel.watchlist.Instrument': 'Instrument',
    'panel.watchlist.Last': 'Cena',
    'panel.watchlist.LastSize': 'Wolumen ostatniej transakcji',
    'panel.watchlist.Limit.High': '#hidden#',
    'panel.watchlist.Limit.Low': '#hidden#',
    'panel.watchlist.Low': 'Min',
    'panel.watchlist.Low13': '#hidden#',
    'panel.watchlist.Low26': '#hidden#',
    'panel.watchlist.Low52': '#hidden#',
    'panel.watchlist.Main Volume': 'Wolumen',
    'panel.watchlist.Market': 'Route',
    'panel.watchlist.MarketCap': 'Kapitalizacja rynkowa',
    'panel.watchlist.Match Volume': 'Wolumen',
    'panel.watchlist.menu.AddSymbol': 'Dodaj instrument',
    'panel.watchlist.menu.clearAll': 'Wyczyść wszystkie',
    'panel.watchlist.menu.SymbolLists': 'Lista instrumentów',
    'panel.watchlist.menu.RemoveInstrument': 'Usuń wybrane instrumenty',
    'panel.watchlist.menu.SymbolInfo': 'Informacje o instrumencie',
    'panel.watchlist.menu.Chart': '#hidden#',
    'panel.watchlist.menu.MarketDepth': '#hidden#',
    'panel.watchlist.menu.TimeSales': '#hidden#',
    'panel.watchlist.menu.OrderEntry': '#hidden#',
    'panel.watchlist.menu.NoSymbolSelected.tt': 'Brak wybranych instrumentów',
    'panel.watchlist.menu.MoreThanOneSymbolSelected.tt': 'Wybrano więcej niż jeden instrument',
    'panel.watchlist.NA': 'Brak',
    'panel.watchlist.noQuotes': 'Ostatnia zmiana',
    'panel.watchlist.NormalMarketSize': '#hidden#',
    'panel.watchlist.OffExchangeValue': '#hidden#',
    'panel.watchlist.OffExchangeVolume': '#hidden#',
    'panel.watchlist.Open': 'Otwarcie',
    'panel.watchlist.OpenInterest': '#hidden#',
    'panel.watchlist.OpenPreMarket': 'Cena pre-market',
    'panel.watchlist.Order': '#hidden#',
    'panel.watchlist.Order.tt': 'Złóż zlecenie Limit',
    'panel.watchlist.Trade': '#hidden#',
    'panel.watchlist.Trade.tt': 'Złóż zlecenie market',
    'panel.watchlist.ITChartAdvanced': 'Zaawansowany wykres',
    'panel.watchlist.ITChartAdvanced.tt': 'Zaawansowany wykres IT-Finance',
    'panel.watchlist.PreMarketChange': 'Zmiana pre-market',
    'panel.watchlist.PreMarketChange%': 'Zmiana pre-market, %',
    'panel.watchlist.PrevClose': 'Poprzednie zamknięcie',
    'panel.watchlist.PrevSettlementPrice': 'Poprzednia cena rozliczenia',
    'panel.watchlist.ReferencePrice': 'Cena referencyjna',
    'panel.watchlist.RemainingQty': '#hidden#',
    'panel.watchlist.RemainingQtySide': '#hidden#',
    'panel.watchlist.Sell': 'Sprzedaż',
    'panel.watchlist.SettlementPrice': 'Cena rozliczenia',
    'panel.watchlist.Spread': 'Spread',
    'panel.watchlist.SpreadPr': 'Spread, %',
    'panel.watchlist.Ticks': 'Ticki',
    'panel.watchlist.TicksPostMarket': 'Post-market ticki',
    'panel.watchlist.TicksPreMarket': 'Pre-market ticki',
    'panel.watchlist.Time': 'Ostatnia zmiana',
    'panel.watchlist.TradedValue': '#hidden#',
    'panel.watchlist.Type': 'Typ instr.',
    'panel.watchlist.Volume': 'Wolumen',
    'panel.watchlist.VolumePostMarket': 'Wolumen post-market',
    'panel.watchlist.VolumePreMarket': 'Wolumen pre-market',
    'panel.watchlist.AvgTradedPrice': '#hidden#',
    'panel.watchlist.TotalBuyQuantity': '#hidden#',
    'panel.watchlist.TotalSellQuantity': '#hidden#',
    'panel.watchlist.HighOpenInterest': '#hidden#',
    'panel.watchlist.LowOpenInterest': '#hidden#',
    'panel.watchlist.InitiatorPrice': '#hidden#',
    'panel.watchlist.InitiatorQuantity': '#hidden#',
    'panel.watchlist.InitiatorType': '#hidden#',
    'panel.watchlist.LastTradedTime': '#hidden#',
    'panel.watchlist.AuctionNumber': '#hidden#',
    'panel.watchlist.AuctionStatus': '#hidden#',
    'panel.watchlist.FiftyTwoWeekHighPrice': '#hidden#',
    'panel.watchlist.FiftyTwoWeekLowPrice': '#hidden#',
    'panel.watchlist.NSEValue': '#hidden#',
    'panel.watchlist.DPR': '#hidden#',
    'panel.watchlist.Code': '#hidden#',
    'panel.watchlist.InstrumentType': '#hidden#',
    'panel.watchlist.MarketType': '#hidden#',
    'panel.watchlist.Symbol': '#hidden#',
    'panel.watchlist.Series': '#hidden#',
    'panel.watchlist.ExchangeMarketData': 'Giełda',
    'panel.watchlist.ExchangeTrading': 'Giełda',
    'Panels.MarketDepth.CancelAll': 'Usuń wszystkie',
    'panels.orders.buttons.AsMarket': 'Zmień na zlecenie Market',
    'panels.orders.buttons.CancelAllButton': 'Anuluj wszystkie',
    'panels.orders.buttons.CancelBuyButton': 'Anuluj zlecenia Kupna',
    'panels.orders.buttons.CancelByInstrumentButton': 'Anuluj zlecenia dla instrumentu',
    'panels.orders.buttons.CancelDayButton': 'Anuluj zlecenia Dzień',
    'panels.orders.buttons.CancelGTCButton': 'Anuluj zlecenia GTC',
    'panels.orders.buttons.CancelLimitsButton': 'Anuluj zlecenia Limit',
    'panels.orders.buttons.CancelSelectedButton': 'Anuluj zaznaczone',
    'panels.orders.buttons.CancelSellButton': 'Anuluj zlecenia Sprzedaży',
    'panels.orders.buttons.CancelStopsButton': 'Anuluj zlecenia Stop',
    'panels.orders.buttons.ModifyOrder': 'Modyfikuj zlecenie',
    'panels.positions.buttons.closeAll': 'Zamknij wszystkie',
    'panels.positions.buttons.closeForInstrument': 'Zamknij',
    'panels.positions.buttons.closeLong': 'Zamk.kupno',
    'panels.positions.buttons.closeNegative': 'Zamknij stratne',
    'panels.positions.buttons.closePositive': 'Zamknij zyskowne',
    'panels.positions.buttons.closeSelected': 'Zamk. zaznaczone',
    'panels.positions.buttons.closeShort': 'Zamk. sprzedaż',
    'panels.positions.buttons.clxAll': 'Zamk. poz. i zlec.',
    'panels.positions.buttons.clxForInstrument': 'Zamk. dla instr.',
    'panels.positions.ButtonsPanel.helpLabel': 'Kliknij prawym przyciskiem myszy, aby pokazać/ukryć',
    'panels.positions.buttons.reverse': 'Odwróć',
    'panels.positions.buttons.reverseSelected': 'Odwróć zaznaczone',
    'panel.rangeSelect.daily': 'Dzień',
    'panel.rangeSelect.24h': '#hidden#',
    'panel.rangeSelect.7d': '#hidden#',
    'panel.rangeSelect.30d': '#hidden#',
    'panel.rangeSelect.90d': '#hidden#',
    'panel.rangeSelect.range': 'Zakres',
    'panel.rangeSelect.tooltip': 'Zakres dat',
    'PanelSettingsScreen.Text': '- Ustawienia',
    'PanelSettingsScreen.TextSmall': '- Ustawienia',
    'Parabolic Time/Price System': 'Paraboliczny system Czas/Cena',
    'period.Day': 'Dzień',
    'period.Days': 'Dni',
    'period.Hour': 'Godzina',
    'period.Hours': 'Godziny',
    'period.Min': 'Minuta',
    'period.Mins': 'Minuty',
    'period.Month': 'Miesiąc',
    'period.Months': 'Miesiące',
    'period.Week': 'Tydzień',
    'period.Weeks': 'Tygodnie',
    'period.Year': 'Rok',
    'period.Years': 'Lata',
    'Pivot Point Moving Average': 'Pivot Point Moving Average',
    PivotPoint: 'Pivot point',
    'PopupMultiCkeckBox.All': 'Zaznacz wszystko',
    'portfolio.performance.allocation.title': 'Wynik',
    'portfolio.returns.title': 'Wyniki',
    'portfolio.returns.tableHeader.date': 'Data',
    'portfolio.returns.tableHeader.return': 'Zwrot na portfolio',
    'portfolio.returns.tableHeader.balance': 'Saldo rachunku',
    'portfolio.returns.tableHeader.monthPercent': 'Portfolio:',
    'portfolio.returns.infoWindow.dateHeader': 'Data:',
    'portfolio.returns.contextMenu.View': 'Widok',
    'portfolio.returns.contextMenu.Refresh': 'Odśwież',
    'portfolio.returns.contextMenu.Settings': 'Ustawienia',
    'portfolio.returns.contextMenu.GridTime': 'Siatka - czas',
    'portfolio.returns.contextMenu.GridPercent': 'Siatka - procent',
    'portfolio.returns.contextMenu.Scrollbar': 'Scroll',
    'portfolioReturns.InfoWindowMode.SeparateWindow': 'Osobne okno',
    'portfolioReturns.InfoWindowMode.None': 'None',
    Português: 'Português (Br)',
    'positionSizeCalculator.header': 'Kalkulator ryzyka',
    'positionSizeCalculator.infoText': 'Kalkulator pomaga określić wielkość pozycji w oparciu o ryzyko, które jesteś gotów podjąć.',
    'positionSizeCalculator.infoText.linkPart': 'Więcej w artykule dotyczącym {0}Kalkulatora pozycji{0}.',
    'positionSizeCalculator.helpLink': '#hidden#',
    'positionSizeCalculator.accountRisk': 'Ryzyko rachunku',
    'positionSizeCalculator.accountRisk.tt': 'Wpisz maksymalne dopuszczalne ryzyko w formie procentowej lub wartości bezwzględnej.',
    'positionSizeCalculator.cashRiskLabel': 'Wielkość straty (waluta rachunku)',
    'positionSizeCalculator.percentRiskLabel': 'Wiekość straty (procent)',
    'positionSizeCalculator.PercentMenuItem': 'Procent',
    'positionSizeCalculator.FixedMenuItem': 'Wartość bezwzględna',
    'positionSizeCalculator.UnableToCalc': 'Nie można dokonać obliczeń',
    'positionSizeCalculator.button.tt': 'Wyświetla dodatkowe pola do automatycznej kalkulacji wielkości pozycji',
    'positionSizeCalculator.buttonDisabled.tt': 'SL jest zabroniony - nie można użyć kalkulatora',
    'Price Channel': 'Kanał cenowy',
    'Price Oscillator': 'Price oscillator',
    'ProductType.CarryForward': 'Carry forward',
    'ProductType.Delivery': 'Dostawa',
    'ProductType.General': 'Ogólne',
    'ProductType.Intraday': 'Intraday',
    'property.%': '%',
    'property.Additional': 'Dodatkowe',
    'property.AllPrice': 'Statystyki ceny',
    'property.appearance': 'Wygląd',
    'property.Ask': 'Ask',
    'property.Author': 'Autor',
    'property.autosize': 'Auto-rozmiar kolumn',
    'property.autoYScale.Auto': 'Skala auto',
    'property.autoYScale.Manual': 'Skala manualna',
    'property.Average': 'Średnia',
    'property.Backgr': 'Kolor tła',
    'property.Backgr. top': 'Kolor tła',
    'property.BarPattern': 'Formacja świecowa',
    'property.barsHiLowColor': 'Linia',
    'property.BarStatistics': 'Statytsyki świecy',
    'property.Bid': 'Bid',
    'property.bodyColor': 'Korpus',
    'property.borderColor': 'Ramka',
    'property.BottomCenter': 'Wyśrodkowane',
    'property.BottomLeft': 'W lewo',
    'property.BottomRight': 'W prawo',
    'property.Alerts': 'Alarmy',
    'property.Action': 'Wykonaj',
    'property.Notification': 'Typ powiadomienia',
    'property.AlertType': 'Typ alarmu',
    'property.Condition': 'Warunek',
    'property.Importance': 'Ważność',
    'property.AfterExecute': 'Po wykonaniu',
    'property.Browser': 'Przeglądarka',
    'property.Buy': 'Kup',
    'property.BuyColor': 'Kup',
    'property.cAsk': 'Wielkość Ask',
    'property.cASK': 'W Ask',
    'property.cASKHI': 'Ponad Ask',
    'property.cBid': 'Wielkość Bid',
    'property.cBID': 'W Bid',
    'property.cBIDLO': 'Poniżej Bid',
    'property.cellscin.useSellBuy': 'Odwróć przyciski K i S',
    'property.Change': 'Zmiana',
    'property.ChangePercent': 'Zmiana, %',
    'property.Chart': 'Wykres',
    'property.Chart OE': 'Złóż zlecenie',
    'property.chart.isBackground': 'Ustaw jako tło',
    'property.chart.line': 'Linia',
    'property.chart.ray': 'Promień',
    'property.chart.rayStyle': 'Styl',
    'property.chart.showAngle': 'Pokaż kąt',
    'property.chart.showingOE': 'Widoczny',
    'property.chart.stickTo': 'Przypnij do świecy',
    'property.chart.tools.back': 'Wypełnij rysunek',
    'property.chart.tools.fibretr.font': 'Czcionka',
    'property.chart.tools.fibretr.fontcolor': 'Kolor czcionki',
    'property.ChartAnalyseToolsToolBar': 'Narzędzia analizy',
    'property.ChartDrawingToolsToolBar': 'Narzędzia do rysowania',
    'property.cLimit': 'Zlecenie Limit',
    'property.Close': 'Zamknij',
    'property.Cluster.ClusterUpDownColors': 'Obszar',
    'property.Cluster.ColorScheme': 'Schemat kolorów',
    'property.Cluster.ColorScheme.ByDelta': 'Po delcie',
    'property.Cluster.ColorScheme.ByDelta.Colors': 'Kolor',
    'property.Cluster.ColorScheme.ByDelta.Colors.BuyColor': 'Kupno',
    'property.Cluster.ColorScheme.ByDelta.Colors.SellColor': 'Sprzedaż',
    'property.Cluster.ColorScheme.ByTrades': 'Po transakcjach',
    'property.Cluster.ColorScheme.ByVolume': 'Po wolumenie',
    'property.Cluster.ColorScheme.Color': 'Kolor',
    'property.Cluster.ColorScheme.None': 'Brak',
    'property.Cluster.CustomStep': 'Krok',
    'property.Cluster.Down': 'W doł',
    'property.Cluster.FilterValue': 'Wartość filtra',
    'property.Cluster.Font': 'Czcionka',
    'property.Cluster.FontColor': 'Kolor czcionki',
    'property.Cluster.HighlightMax': 'Podświetl Max',
    'property.Cluster.HighlightMax.Colors': 'Kolory',
    'property.Cluster.Operator': 'Operator',
    'property.Cluster.PriceStep': 'Krok ceny',
    'property.Cluster.PriceStep.Custom': 'Własny',
    'property.Cluster.PriceStep.MinTick': 'Min tick',
    'property.Cluster.ShowOnlySimilarDelta': 'Pokaż tylko podobną deltę',
    'property.Cluster.ShowValueFilter': 'Filtr wartości',
    'property.Cluster.Up': 'Do góry',
    'property.cMIDLO': 'Between spread',
    'property.Color': 'Kolor',
    'property.ColoringMode': 'Tryb kolorów',
    'property.Colors': 'Kolory',
    'property.colors_param': 'Kolory',
    'property.colorScheme': 'Styl',
    'property.ColorsSeparatorGroup': 'Kolory',
    'property.ColouringMode': 'Tryb kolorwania',
    'property.Comments': 'Komentarze',
    'property.Company': 'Company',
    'property.Confirmations': 'Potwierdzenia',
    'property.connection': 'Połączenie',
    'property.Connection': 'Połączenie',
    'property.ContentAlignment': 'Alignment',
    'property.Copyrights': 'Prawa autorskie',
    'property.cQUOTE': 'Kwotowania',
    'property.CreateColor': 'Utwórz',
    'property.crossHairFontColor': 'Kolor czcionki',
    'property.cStop': 'Zlecenie Stop',
    'property.currency': 'Waluta',
    'property.CustomYMarkingValue': 'Min odległość',
    'property.Data Box': 'Okno informacji',
    'property.Data Style': 'Styl danych',
    'property.Day': 'Dzień',
    'property.DaySeparatorsVisibility': 'Separator dni',
    'property.DefaultProductType': 'Domyślny typ produktu',
    'property.Defaults': 'Domyślne',
    'property.deviation': 'odchylenie',
    'property.DisableStartEndSounds': '#hidden#',
    'property.dK': 'dK',
    'property.Doji': 'Doji',
    'property.Downband deviation in %': 'Downband deviation in %',
    'property.email': 'Adresy e-mail',
    'property.Emulator': 'Emulator',
    'property.enable': 'Włącz',
    'property.Enable Sounds': 'Włącz dźwięki',
    'property.Exponential': 'Exponential',
    'property.fib.Arc': 'Fib. Arc',
    'property.Fibonacci': 'Fibonacci',
    'property.Filters': 'Filtry',
    'property.FitDrawings': 'Dopasuj rysunki',
    'property.FitHighLow': 'Dopasuj dzienne Min/Max',
    'property.FitIndicator': 'Dopasuj wskaźniki',
    'property.FitOrders': 'Dopasuj  zlecenia/pozycje',
    'property.FOK': 'FOK',
    'property.Font': 'Font',
    'property.Forex': 'Forex',
    'property.Forwards': 'Forwards',
    'property.Futures': 'Futures',
    'property.FX Cell': 'Komórka FX',
    'property.G': 'G',
    'property.General': 'Ogólne',
    'property.General.ToolTip': 'Ustawienia',
    'property.general.defaultSymbol': 'Instrument',
    'property.general.OvernightMarginNotificationMessage': '#hidden#',
    'property.general.showLotsFX': 'Ilość w lotach',
    'property.gr_type': 'Styl',
    'property.Gradient': 'Gradient',
    'property.GraphicType.Absolute': 'Całkowity',
    'property.GraphicType.Log': 'Logarytmiczny',
    'property.GraphicType.Relative': 'Relatywny',
    'property.GridPrice': 'Cena (pozioma)',
    'property.GridPriceHighLight': 'Podświetl kolor siatki',
    'property.GridTime': 'Czas (pionowa)',
    'property.GTC': 'GTC',
    'property.GTD': 'GTD',
    'property.HeaderFont': 'Czcionka',
    'property.HeaderText': 'Nagłówek',
    'property.High': 'Max',
    'property.HighlightMarkingsStep': 'Podświetl krok siatki',
    'property.HistogramLineColor': 'Linia - Histogram',
    'property.HistoryType': 'Typ danych',
    'property.HorizontalLine.LeftRay': 'Przedłuż w lewo',
    'property.hostName': 'Nazwa',
    'property.hotkeyManager.ShowVolume': 'Pokaż wolumen',
    'property.infowindow_font': 'Czcionka',
    'property.infoWindow_fore_back_color': 'Kolor',
    'property.infowindow_visible': 'Okno informacji',
    'property.Instrument Types': 'Domyślne dla zlecenia (rodzaj)',
    'property.Intrinsic': 'Wewnętrzny',
    'property.IOC': 'IOC',
    'property.IsBackground': 'Jako tło',
    'property.IsBarsVisible': '#Słupki',
    'property.IsChangeVisible': 'Zmiana, %',
    'property.IsCloseVisible': 'Zamknięte',
    'property.IsDateVisible': 'Data',
    'property.IsHighVisible': 'Max',
    'property.IsLowVisible': 'Min',
    'property.IsHighLowVisible': 'Max - Min',
    'property.IsOpenVisible': 'Otwarte',
    'property.IsRoundedAvgPrice': 'Średnia cena otwarcia',
    'property.isShowToolTips': 'Pasek narzędzi',
    'property.isShowToolTipsGrop': 'Narzędzia',
    'property.ConfirmationSubgroup.Other': 'Inne',
    'property.dealTickets.openOnWorkspace': 'Pokaż potwierdzenia transakcji na pulpicie',
    'property.dealTickets.showMarginDealTicketsOnWorkspace': 'Pokaż komunikaty Margin Call oraz Stop Out na pulpicie',
    'property.dealTickets.showMarginDealTicketsOnWorkspace.ToolTip': 'Jeżeli ta opcja jest zaznaczona to komunikaty Margin Call oraz Stop Out będą pojawiać się na pulpicie',
    'property.IsSyncActive': 'Synchronizuj z wykresami',
    'property.IsTicksVisible': 'Ticki',
    'property.IsTimeVisible': 'Czas',
    'property.IsVolumeVisible': 'Wolumen',
    'property.LabelColors': 'Kolory',
    'property.Language': 'Język',
    'property.Last': 'Ostatnia',
    'property.lastPrice_AskIndicatorColor': 'Ask',
    'property.lastPrice_BidIndicatorColor': 'Bid',
    'property.lastPrice_LastIndicatorColor': 'Ostatni',
    'property.lastPrice_SpreadIndicatorColor': 'Kolor obszaru spreadu',
    'property.Left ray': 'Przedłuż w lewo',
    'property.Level': 'Poziom',
    'property.Limit': 'Limit',
    'property.limitOffsetTicks': 'Dystans Stop Limit, tiki',
    'property.Line': 'Linia',
    'property.line.lineName': 'Nazwa linii',
    'property.line.plateVisible': 'Pokaż znacznik {0}',
    'property.line.timeShift': 'Zmiana czasu',
    'property.line.value': 'Wartość',
    'property.line.Visible': 'Widoczne',
    'property.line.yFactor': 'Współczynnik Y',
    'property.Linear Weighted': 'Linear weighted',
    'property.lineProperties': 'Ustawienia',
    'property.LOC': 'LOC',
    'property.logBase': 'Log base',
    'property.LOO': 'LOO',
    'property.LotsMode': 'Pokaz wielkość w',
    'property.Low': 'Niski',
    'property.Mail Server': 'Mail server',
    'property.Manual': 'Manualny',
    'property.Market': 'Rynek',
    'property.MarketCap': 'Kapitalizacja rynkowa',
    'property.MarketDepth.ColoringMethod': 'Sposób kolorowania',
    'property.MarketProfile.ColorScheme': 'Schemat kolorów',
    'property.MarketProfile.ColorScheme.Color': 'Kolor',
    'property.MarketProfile.ColorScheme.GradientColor': 'Obszar',
    'property.MarketProfile.ColorScheme.PairColor.Color': 'Kolor',
    'property.MarketProfile.ColorScheme.VolumeColor': 'Kolor',
    'property.MarketProfile.Font': 'Czcionka',
    'property.MarketProfile.PointOfControl': 'Punkt kontroli',
    'property.MarketProfile.PointOfControlStyle': 'Punkt kontroli - styl',
    'property.MarketProfile.ShowSingles': 'Pokaż pojedyncze',
    'property.MarketProfile.SinglesStyle': 'Pojedyncze - styl',
    'property.MarketProfile.ValueArea': 'Obszar wartości',
    'property.MarketProfile.ValueAreaStyleHorisontal': 'Linia pozioma - styl',
    'property.MarketProfile.ValueAreaStyleVertical': 'Linia pionowa - styl',
    'property.MarketProfile.ValuePercents': 'Wartość, %',
    'property.Medium': 'Średni',
    'property.message': 'Wiadomość',
    'property.method': 'metoda',
    'property.MirrorShow': 'Widok lustrzany',
    'property.MOC': 'MOC',
    'property.Mode': 'Tryb',
    'property.Mode.Trades': 'Transakcje',
    'property.Modified': 'Zmodyfikowano',
    'property.MonthSeparatorsVisibility': 'Separator miesięcy',
    'property.MOO': 'MOO',
    'property.name': 'Nazwa',
    'property.Name': 'Nazwa',
    'property.News': 'Informacje',
    'property.None': 'Brak',
    'property.Note': 'Notatka',
    'property.NotSet': 'Nie ustawiono',
    'property.Number': 'Liczba',
    'property.OCO': 'OCO',
    'property.OE_AddBidAddAsk': 'Kup Bid/Sprzedaj Ask',
    'property.OE_ShowBuyMarketSellMarket': 'Kup Market/Sprzedaj Market',
    'property.OE_ShowSellBidBuyASk': 'Sprzedaj Bid/Kup Ask',
    'property.OE_ShowSLTP': 'Zamknij zlecenia (SL/TP)',
    'property.OE_Small': 'Złóż zlecenie',
    'property.OE_Small.ToolTip': "Zaznacz, aby panel 'złóż zlecenie' pojawiał się w wąskim oknie",
    'property.oe.confirmClosePosition': 'Potwierdź zamknięcie pozycji',
    'property.oe.confirmOrderCancel': 'Potwierdź anulowanie zlecenia',
    'property.oe.confirmOrderChange': 'Potwierdź modyfikację zlecenia/pozycji',
    'property.oe.confirmOrderCreate': 'Potwierdź złożenie zlecenia',
    'property.oe.confirmOrderCreateAlert': 'Potwierdź złożenie zlecenia',
    'property.oe.confirmAlertRemove': 'Potwierdź usunięcie alarmu',
    'property.oe.confirmReversePosition': 'Potwierdź  odwrócenie pozycji',
    'property.OrderCreateAlertGroup': 'Alarmy',
    'property.OffsetMode': 'Pokaż dystans w',
    'property.OffsetMode.Points': 'Punkty',
    'property.OffsetMode.Ticks': 'Ticki',
    'property.OffsetMode.TicksFractionalForForex': 'Ticki (częściowe ticki dla Forex)',
    'property.OnClose': 'Na zamknięciu',
    'property.OnOpen': 'Na otwarciu',
    'property.Open': 'Otwórz',
    'property.Order Entry': 'Złóż zlecenie',
    'property.OrderBuyStyleWidth': 'Kupno',
    'property.OrderSellStyleWidth': 'Sprzedaż',
    'property.OverlayVisible': 'Widoczna warstwa',
    'property.Panel name': 'Nazwa okna',
    'property.panel.accountDetails.Groups.1.General': 'Podsumowanie rachunku',
    'property.panel.accountDetails.Groups.2.Margin': 'Ryzyko',
    'property.panel.accountDetails.Groups.3.AccountActivity': 'Bieżący stan rachunku',
    'property.panel.accountDetails.Groups.4.TodayResults': 'Dzisiejszy Z/S',
    'property.panel.accountDetails.Groups.5.RiskManagement': 'Zarządzanie ryzykiem',
    'property.panel.accountDetails.Groups.6.Info': 'Informacje',
    'property.Panels': 'Okna',
    'property.Parameters': 'Parametery',
    'property.password': 'Hasło',
    'property.period': 'okres',
    'property.Period of indicator': 'Okres wskaźnika',
    'property.Period of indicator:': 'Okres wskaźnika',
    'property.Period of Linear Regression': 'Okres regresji liniowej',
    'property.Period of MA for envelopes': 'Okres MA dla kopert',
    'property.Period of MA1': 'Okres dla MA1',
    'property.Period of MA2': 'Okres dla MA2',
    'property.Period of momentum:': 'Okres dla momentum',
    'property.Period of Moving Average': 'Okres dla Moving Average',
    'property.Period of price channel': 'Okres dla kanału ceny',
    'property.Period of Simple Moving Average': 'Okres dla Simple Moving Average',
    'property.Period of Smoothed Moving Average': 'Okres dla Smoothed Moving Average',
    'property.Periods': 'Okresy',
    'property.Calсulation cycle, bars': 'Cykl kalkulacji, słupki',
    'property.Calculation price': 'Cena kalkulacji',
    'property.pin': 'PIN',
    'property.pips': 'Pipsy',
    'property.POCTypes.Line': 'Linia',
    'property.POCTypes.Marker': 'Marker',
    'property.POCTypes.None': 'Brak',
    'property.points': 'Punkty',
    'property.Points': 'Punkty',
    'property.PositionBuyStyleWidth': 'Kupno',
    'property.PositionSellStyleWidth': 'Sprzedaż',
    'property.Portfolio return': 'Zwrot na portfolio',
    'property.Asset return': 'Zwrot aktywów',
    'property.Percent scale': 'Skala procentowa',
    'property.AssetReturnVisibility': 'Widoczne',
    'property.Previous': 'Poprzedni',
    'property.price': 'cena',
    'property.Price Scale': 'Skala ceny',
    'property.PriceIndicatorDrawingType': 'Wskaźnik ceny',
    'property.PriceIndicatorDrawingType.None': 'Brak',
    'property.PriceIndicatorDrawingType.ScaleMarker': 'Znacznik',
    'property.PriceIndicatorDrawingType.ScaleMarkerWithLine': 'Znacznik z linią',
    'property.PriceScaleAxisColor': 'Oś',
    'property.PriceScaleBackColor': 'Tło osi',
    'property.PriceScaleFont': 'Czcionka',
    'property.PriceScaleTextColor': 'Kolor tekstu',
    'property.PriceType': 'Typ ceny',
    'property.ProfileColoringTypes.DeltaBars': 'Delta bars',
    'property.ProfileColoringTypes.DeltaPrice': 'Delta price',
    'property.ProfileColoringTypes.DeltaProfile': 'Delta profile',
    'property.ProfileColoringTypes.Single': 'Pojedynczy kolor',
    'property.ProfileColoringTypes.UpdownBars': 'Wzrostowe/spadkowe słupki',
    'property.ProfileColoringTypes.UpdownProfile': 'Wzrostowy/spadkowy profil',
    'property.ProfileColoringTypes.VolumeBars': 'Wolumen - słupki',
    'property.ProfileColoringTypes.VolumePrice': 'Wolumen - cena',
    'property.protrader.showOrders': 'Zlecenia',
    'property.Range': 'Zakres',
    'property.Ray style': 'Styl przedłużenia',
    'property.Relative': 'Relatywny',
    'property.relativeCalcType': 'Start price',
    'property.RelativeCalculationType.BeginOfTheData': 'Zacznij od daty',
    'property.RelativeCalculationType.BeginOfTheDay': 'Zacznij od dnia',
    'property.RelativeCalculationType.BeginOfTheScreen': 'Zacznij od widocznego zakresu',
    'property.relativeScaleType': 'Typ skali',
    'property.Reserve': 'Rezerwa',
    'property.Reverse': 'Odwróć',
    'property.Right ray': 'Przedłużenie w prawo',
    'property.routing.account': 'Rachunek',
    'property.routing.orderType': 'Rodzaj zlecenia',
    'property.RSI Method:': 'Metoda RSI',
    'property.RSI Period:': 'Okres RSI',
    'property.SCRIPTS_SUBGROUP': 'Skrypty',
    'property.ScrollBar': 'Scroll',
    'property.secure': 'Bezpieczne',
    'property.Secure': 'Bezpieczne',
    'property.Selection': 'Wybór',
    'property.Sell': 'Sprzedaj',
    'property.SellColor': 'Sprzedaj',
    'property.SeparatorGroup.ActiveOrders': 'Zlecenia bieżące',
    'property.SeparatorGroup.additional': 'Dodatkowy',
    'property.SeparatorGroup.Additional': 'Dodatkowy',
    'property.SeparatorGroup.Appearance': 'Wygląd',
    'property.SeparatorGroup.CrossHair': 'Krzyżyk',
    'property.SeparatorGroup.Watermark': 'Znak wodny',
    'property.SeparatorGroup.ExecutedOrders': 'Zrealizowane zlecenia',
    'property.SeparatorGroup.Grid': 'Siatka',
    'property.SeparatorGroup.HighlightDate': 'Podkreśl datę',
    'property.SeparatorGroup.HighlightPercent': 'Podkreśl procent',
    'property.SeparatorGroup.HighlightPrices': 'Podkreśl ceny',
    'property.SeparatorGroup.OpenPositions': 'Otwarte pozycje',
    'property.SeparatorGroup.ScaleMarkings': 'Skala wykresu',
    'property.SeparatorGroup.ScaleType': 'Typ skali',
    'property.SeparatorGroup.SeparatorOEWidth': 'Widok',
    'property.SeparatorGroup.Show': 'Pokaż',
    'property.SeparatorGroup.TimeToNextBar': 'Czas do kolejnej świecy',
    'property.SeparatorGroup.WindowColors': 'Kolory tła',
    'property.SeparatorGroup.Zoom': 'Przybliż',
    'property.SeparatorViewButtons': 'Szybki handel',
    'property.Sesonality': 'Sezonowość',
    'property.shift': 'przesunięcie',
    'property.ShowAlerts': 'Pokaż alarmy',
    'property.showAllSessions': 'Pokaż wydłużone sesje',
    'property.ShowEmptyBars': 'Przerwy w kwotowaniach',
    'property.showEvents': 'Widoczny',
    'property.ShowFullArc': 'Pokaż cały łuk',
    'property.ShowHighLow': 'Max/Min dzień',
    'property.showOrders': 'Widoczne',
    'property.showPositions': 'Widoczne',
    'property.ShowPrevCloseLine': 'Poprzednie zamknięcie',
    'property.ShowTimeToNextBar': 'Widoczny',
    'property.ShowTotal': 'Suma',
    'property.ShowVisualTradingOnLeftSide': 'Przypisz aktywne pozycje/zlecenia do lewej strony',
    'property.showVolume': 'Widoczny',
    'property.showVolumeMarker': 'Pokaż znacznik wartości na skali',
    'property.Simple': 'Prosta',
    'property.sltpOffsetMode': 'SL/TP jako dystans',
    'property.Smoothing': 'Smoothing',
    'property.solidPriceColor': 'Obszar',
    'property.sounds_param': 'Dźwięki',
    'property.Sources prices for MA': 'Źródło cen dla średnich ruchomych',
    'property.Specific': 'Dokładny',
    'property.SpreadType': 'Typ spreadu',
    'property.SpreadType.Area': 'Obszar',
    'property.SpreadType.Line': 'Linia',
    'property.SpreadType.LineWithLabel': 'Linia z ceną',
    'property.SpreadType.None': 'Brak',
    'property.Step of parabolic SAR system': 'Krok systemu parabolic SAR',
    'property.Stick to': 'Przywiąż do',
    'property.stickTo': 'Przywiąż do:',
    'property.Stocks': 'Akcje',
    'property.Stop': 'Stop',
    'property.Stop Limit': 'Stop limit',
    'property.Stop-loss': 'Stop loss',
    'property.Stop-loss-SLL': 'Stop loss/Stop loss limit',
    'property.style': 'Styl:',
    'property.Sum': 'Suma',
    'property.Symbols': 'Instrumenty',
    'property.T': 'T',
    'property.Take-profit': 'Take profit',
    'property.text': 'Tekst',
    'property.TIF': 'Ważność zlecenia Market',
    'property.TIF_Limit_And_Stop_Limit': 'Ważność Stop Limit',
    'property.TIF_Limit': 'Ważność zlecenia Limit',
    'property.TIF_Stop': 'Ważność zlecenia Stop',
    'property.Time Scale': 'Skala czasu',
    'property.TimeLine': 'Linia czasu',
    'property.TimelinePreview': 'Widok wykresu na pasku',
    'property.TimeScaleAxisColor': 'Osie',
    'property.TimeScaleBackColor': 'Tło osi',
    'property.TimeScaleFont': 'Czcionka',
    'property.TimeScaleImportentFont': 'Czcionka ważnych dni',
    'property.TimeScaleTextColor': 'Kolor czcionki',
    'property.TimeToNextBarColor': 'Kolor',
    'property.TimeToNextBarFont': 'Czcionka',
    'property.TopCenter': 'Wyśrodkowany',
    'property.TopLeft': 'W lewo',
    'property.TopRight': 'W prawo',
    'property.Tr. stop loss': 'Tr. stop loss',
    'property.trackShowCrossHair': 'Widoczny',
    'property.Trading': 'Handel',
    'property.Trading Defaults': 'Handel',
    'property.Type of moving average': 'Typ średniej ruchomej',
    'property.Type of Moving Average': 'Typ średniej ruchomej',
    'property.Typical': 'Typowy',
    'property.Upband deviation in %': 'Upband deviation w %',
    'property.Update': 'Aktualizuj',
    'property.UseCustomYMarkings': 'Własna siatka',
    'property.useStopLimitInsteadStop': 'Stop limit zamiast Stop',
    'property.user': 'Login',
    'property.useSSL': 'Bezpieczny',
    'property.Value of confidence interval': 'Przedział ufności',
    'property.ValueColors': 'Kolory wartości',
    'property.VATypes.Horizontal': 'Pozioma',
    'property.VATypes.None': 'Brak',
    'property.VATypes.ToNextProfile': 'Następny profil',
    'property.VATypes.Vertical': 'Pionowa',
    'property.vendorName': 'Vendor',
    'property.View': 'Widok',
    'property.ViewSeparatorGroup': 'Widok',
    'property.Visibility': 'Widoczność',
    'property.VisibilitySeparatorGroup': 'Widoczność',
    'property.Visible': 'Widoczne',
    'property.Visual Trading': 'Handel z wykresu',
    'property.Volume': 'Wolumen - słupki',
    'property.Volume.Font': 'Czcionka',
    'property.Volume.FontColor': 'Kolor czcionki',
    'property.Volume.UseTicksData': 'Wolumen tick',
    'property.VolumeColoringMode': 'Schemat kolorów',
    'property.VolumeDownColor': 'Spadek',
    'property.VolumeFixedColor': 'Równy',
    'property.VolumeHeightPercent': 'Wysokość, %',
    'property.VolumeMode.BuyVolume': 'Wolumen kupna',
    'property.VolumeMode.Delta': 'Delta',
    'property.VolumeMode.MaxTickVolume': 'Wolumen - max tick',
    'property.VolumeMode.SellVolume': 'Wolumen sprzedaży',
    'property.VolumeMode.TotalVolume': 'Wolumen - suma',
    'property.VolumeMode.Trades': 'Transakcje',
    'property.VolumeOpacityPercent': 'Transparentność',
    'property.VolumeProfile': 'Profil wolumenu',
    'property.VolumeUpColor': 'Wzrost',
    'property.VWAP': 'VWAP',
    'property.Warnings': 'Ostrzeżenia',
    'property.WatermarkColor': 'Kolor - znak wodny',
    'property.WatermarkMode': 'Typ znaku wodnego',
    'watermarkMode.None': 'Brak',
    'watermarkMode.Symbol': 'Instrument',
    'watermarkMode.Description': 'Opis',
    'watermarkMode.SymbolAndDescription': 'Instrument + Opis',
    'property.WeekSeparatorsVisibility': 'Separator tygodni',
    'property.Weighted': 'Ważone',
    'property.wickColor': 'Knot',
    'property.width': 'Szerokość:',
    'property.Win num': 'Wybór okna dla wskaźnika',
    'property.Win num.addWin': 'Sub-okno',
    'property.Win num.main': 'Główne',
    'property.Y factor': 'Czynnik Y',
    'property.YearSeparatorsVisibility': 'Separator lat',
    'property.ZeroLine': 'Zero line',
    'property.ZoomValue': 'Krok skali',
    'propery.MainPriceRenderer.Down': 'W dół',
    'propery.MainPriceRenderer.Negative': 'Negatywny',
    'propery.MainPriceRenderer.Positive': 'Pozytywny',
    'propery.MainPriceRenderer.Up': 'W górę',
    'Relative Strength Index': 'Relative strength index',
    'reports.#subgroup.Revenue plan.0': 'Prowizje',
    'reports.#subgroup.Revenue plan.1': 'Spread',
    'reports.#subgroup.Revenue plan.2': 'Swapy',
    'reports.#subgroup.Revenue plan.3': 'Rabaty',
    'reports.#subgroup.Revenue plan.4': 'Części instrumentu',
    'reports.$broker': 'broker',
    'reports.$broker_create_order': 'broker utworzył zlecenie',
    'reports.$broker_refuse_with_answer': 'odmowa brokera',
    'reports.$client': 'Klient',
    'reports.$client_request': 'dyspozycja Klienta',
    'reports.$close_position': 'zamknięcie pozycji',
    'reports.$confirm_order_removed': 'potwierdź usunięcie zlecenia',
    'reports.$enter_system': 'logowanie',
    'reports.$executed': 'wykonano',
    'reports.$leave_system': 'wylogowanie',
    'reports.$open_position': 'ootwarcie pozycji',
    'reports.$order': 'zlecenie',
    'reports.$request_order_removed': 'dyspozycja usunięcia zlecenia',
    'reports.4CastNews': '4CastNews',
    'reports.Accepted': 'Zaakceptowano',
    'reports.accname': 'Nazwa rachunku',
    'reports.accoperreqstatus': 'Status',
    'reports.account': 'Rachunek',
    'reports.Account': 'Rachunek',
    'reports.Account Name': 'Nazwa rachunku',
    'reports.Account operation confirmation.': 'Account operation confirmation',
    'reports.Account Operation Report': 'Account Operation Report',
    'reports.Account operation report (settled)': 'Account operation report (settled)',
    'reports.Account Operation Report Summary': 'Summary account operation report',
    'reports.Account operations report': 'Account operations report',
    'reports.Account Operations Report': 'Account operations report',
    'reports.Account Performance Report': 'Raport zysków/strat',
    'reports.Account Statement': 'Stan rachunku',
    'reports.Account statement in defined currency report': 'Account statement in defined currency report',
    'reports.Account Statement on chosen moment report': 'Account statement on chosen moment report',
    'reports.Account Statement Report': 'Stan rachunku',
    'reports.account_ID': 'Nr rachunku',
    'reports.account.balance': 'Saldo',
    'reports.account.crossinstrument': 'Instrument krzyżowy',
    'reports.account.currency': 'Waluta',
    'reports.account.ismaster': 'Is Master',
    'reports.account.mirroraccounts': 'Mirror accounts',
    'reports.account.name': 'Nazwa rachunku',
    'reports.account.status': 'Status',
    'reports.account.type': 'Typ',
    'reports.account.userid': 'Nr Użytkownika',
    'reports.account.visibility.editforsuper': 'Edit for super group',
    'reports.account.visibility.editforthis': 'Edit for this group',
    'reports.account.visibility.tradeforsuper': 'Trade for super group',
    'reports.account.visibility.tradeforthis': 'Trade for this group',
    'reports.account.visibility.viewforsuper': 'View for super group',
    'reports.account.visibility.viewforthis': 'View for this group',
    'reports.accountcurrency': 'Waluta',
    'reports.AccountId': 'Rachunek',
    'reports.accountname': 'Rachunek',
    'reports.AccountOperationAmount': 'Wielkość',
    'reports.accountoperationid': 'Nr operacji na rachunku',
    'reports.accountPassword': 'Hasło do rachunku',
    'reports.accounts': 'Rachunki',
    'reports.accountUsername': 'Login',
    'reports.AccruedInterest': 'Accrued interest',
    'reports.ACM Feed': 'ACM Feed',
    'reports.action': 'Strona',
    'reports.actionname': 'Nazwa zadania',
    'reports.Activated': 'Aktywowano',
    'reports.Active Orders Report': 'Raport aktywnych zleceń',
    'reports.actualprice': 'Cena rynkowa',
    'reports.actualtime': 'Czas',
    'reports.addliquidityrebate': 'Add liquidity rebate',
    'reports.address': 'Adres',
    'reports.Adjusment': 'Dostosowanie',
    'reports.Adjustment': 'Dostosowanie',
    'reports.ADMINISTRATION_RULES': 'Administration rules',
    'reports.AK Equity Algo': 'AK Equity Algo',
    'reports.AK Genex Equity': 'AK Genex Equity',
    'reports.AK Genex Turdex': 'AK Genex Turdex',
    'reports.alertNotify': 'Powiadomienie o alarmie',
    'reports.All brokers': 'Wszyscy brokerzy',
    'reports.All data received.': 'Wszystkie otrzymane dane.',
    'reports.ALLOW_AUTO_APPROVE_TRANSFER_REQUEST': 'Allow auto approve transfer request',
    'reports.ALLOW_AUTO_ORDER_TYPE': 'Automatically set order type (Limit/Stop/Market)',
    'reports.ALLOW_AUTO_RESET_PWD': 'Allow password auto reset',
    'reports.ALLOW_CLOSE_POSITIONS_ON_STOP_OUT': 'Close positions on Stop out',
    'reports.ALLOW_DEMO_AUTO_REGISTRATION': 'Allow demo user auto registration',
    'reports.ALLOW_GENERATE_L3': 'Allow generate L3',
    'reports.ALLOW_GENERATE_PRICE_FROM_SPOT': 'Allow generating price from spot',
    'reports.ALLOW_HEDGE_BY_SPOT': 'Hedge by spot',
    'reports.ALLOW_HEDGE_MARGIN': 'Allow hedge margin',
    'reports.ALLOW_JOIN_POSITIONS': 'Join one-side positions on hedging',
    'reports.ALLOW_LAZY_LOAD_QUOTES': 'Allow lazy quotes load',
    'reports.ALLOW_LIVE_AUTO_REGISTRATION': 'Allow live auto registration',
    'reports.ALLOW_ONE_BROKER_FOR_ALL_OPERATION': 'Allow one broker for all operations',
    'reports.ALLOW_ORDER_AUTOEXECUTION_IS_MARKET_PRICE': 'Wykonaj po cenie market',
    'reports.ALLOW_PRICE_EXECUTION_MAPPING': 'Price execution mapping',
    'reports.ALLOW_PWD_VERIFICATION': 'Weryfikacja hasła sms',
    'reports.ALLOW_REFUSE_IF_BROKER_OFFLINE': 'Allow refuse if Broker is offline',
    'reports.ALLOW_REMOVE_ORDERS_ON_STOP_OUT': 'Remove orders on stop out',
    'reports.ALLOW_SAVE_HISTORY': 'Zapisz historię kwotowań dla instrumentu',
    'reports.ALLOW_SAVE_QUOTE_LOGS': 'Allow save quote logs',
    'reports.ALLOW_SAVE_QUOTE_TICKS': 'Allow quote ticks saving',
    'reports.ALLOW_SAVE_QUOTES': 'Allow quotes saving',
    'reports.ALLOW_SAVE_TRADE_LOGS': 'Allow save trade logs',
    'reports.ALLOW_SHORT_POSITIONS': 'Allow short positions',
    'reports.ALLOW_SKIP_REVERSED_PRICE': "Skip all 'reversed' quotes",
    'reports.ALLOW_SPIKE_FILTER': 'Allow spike filter',
    'reports.ALLOW_STOP_AVARE_MARGIN': 'Use stop aware margin',
    'reports.ALLOW_STORE_USERS_FILES': 'Allow storage of user files',
    'reports.ALLOW_USE_CERT': 'Allow usage of sign in request',
    'reports.ALLOW_USER_LEVERIGE': 'Allow user leverage',
    'reports.ALLOW_USERS_EMAIL_DUPLICATE': 'Allow duplicating e-mails for demo accounts',
    'reports.ALLOW_VISIBLITY': 'Allow visibility',
    'reports.allowLimitOrders': 'Zlecenia Limit dozwolone',
    'reports.allowStopOrders': 'Zlecenia Stop dozwolone',
    'reports.already in processing': 'w trakcie procesowania...',
    'reports.amount': 'Ilość',
    'reports.Amount': 'Ilość',
    'reports.Amount filled': 'Wykonana ilość',
    'reports.amountperside': 'Ilość dla strony',
    'reports.An error occurred during sending mail using user settings. Will use default settings.': 'Błąd podczas wysłania e-mail',
    'reports.Aphelion': 'Aphelion',
    'reports.APPLICATION.NAME': 'APPLICATION NAME',
    'reports.Approved': 'Zaakceptowano',
    'reports.asset': 'Aktywo',
    'reports.AuditReport': 'Backoffice changes report',
    'reports.Authorization Error: Operation is nor allowed': 'Błąd autoryzacji',
    'reports.autotrade': 'Auto trade',
    'reports.Average filled price': 'Cena',
    'reports.Back Office Changes Report': 'Back office changes report',
    'reports.balance': 'Saldo',
    'reports.Balance': 'Bilans',
    'reports.Balance by Instrument Report': 'Balance by Instrument report',
    'reports.Balance By Instrument Report': 'Balance by Instrument report',
    'reports.Balance Operations Report': 'Balance operations report',
    'reports.Balance Report': 'Balance report',
    'reports.Balance Report(copy)': 'Bilans(kopia)',
    'reports.Balance Report (settled)': 'Bilans',
    'reports.Account performance report[Alior Trader 2]': '[Alior Trader 2] Raport zysków/strat',
    'reports.Balance reports[Alior Trader 2]': '[Alior Trader 2] Bilans',
    'reports.Cash deposit and withdrawal report[Alior Trader 2]': '[Alior Trader 2] Wpłaty/wypłaty',
    'reports.Commision report[Alior Trader 2]': '[Alior Trader 2] Prowizje',
    'reports.Trades Report[Alior Trader 2]': '[Alior Trader 2] Transakcje',
    'reports.Balance Summary report': 'Balance summary report',
    'reports.Balance Summary Report': 'Balance summary report',
    'reports.balance_after': 'Saldo po',
    'reports.balance_before': 'Saldo przed',
    'reports.balance.amount': 'Ilość',
    'reports.balance.buy': 'Kupno',
    'reports.balance.everbuy': 'Średnia cena zakupu',
    'reports.balance.eversell': 'Średnia cena sprzedaży',
    'reports.balance.exposure': 'Ekspozycja',
    'reports.balance.exposurenet': 'Ekspozycja netto',
    'reports.balance.grossexposure': 'Ekspozycja brutto',
    'reports.balance.grosspl': 'Z/S brutto',
    'reports.balance.grossvalue': 'Wartość pozycji brutto',
    'reports.balance.net': 'Netto',
    'reports.balance.netexposure': 'Ekspozycja netto',
    'reports.balance.netvalue': 'Wartość pozycji netto',
    'reports.balance.profit': 'Zysk',
    'reports.balance.sell': 'Sprzedaż',
    'reports.Barclays': 'Barclays',
    'reports.basis': 'Komentarz',
    'reports.Basis': 'Szczegóły',
    'reports.because Not enoght margin to create Order': 'brak wystarczających środków, aby utworzyć zlecenie',
    'reports.because Price for Stop limit order is incorrect.': 'ponieważ cena zlecenia Stop limit jest błędna',
    'reports.BeginString': 'Begin string',
    'reports.blocked_funds': 'Środki zablokowane pod depozyt',
    'reports.blockedbalance': 'Blokowane saldo',
    'reports.Blocking': 'Blocking',
    'reports.bmr_brokermessageid': 'Message ID',
    'reports.bmr_brokermessagename': 'Message name',
    'reports.bmr_confirmtime': 'Target time',
    'reports.bmr_targetuserid': 'ID Klienta',
    'reports.bmr_targetuserlogin': 'Klient',
    'reports.Bound To': 'Przywiązane do',
    'reports.Bound to order': 'Przywiązane do zlecenia',
    'reports.boundtoorderid': 'Bound order ID',
    'reports.BRANDING_ADMIN_HELP': 'Admin help URL',
    'reports.BRANDING_ANONYM_GROUP': 'Place auto registered anonymous users into',
    'reports.BRANDING_ANONYM_GROUP_SUPPORT': 'Support group for anonymous users',
    'reports.BRANDING_CLIENT_MINIMAL_VERSION': 'Minimalna wersja aplikacji',
    'reports.BRANDING_COMMON_RULES': 'Common',
    'reports.BRANDING_COMPANY_DESC': 'Opis',
    'reports.BRANDING_COMPANY_INFO_RULES': 'BM Alior Banku',
    'reports.BRANDING_COMPANY_NAME': 'BM Alior Banku',
    'reports.BRANDING_COMPANY_URL': 'Link',
    'reports.BRANDING_DEMO_GROUP': 'Place auto registered demo users into',
    'reports.BRANDING_DEMOREGISTRATION_URL': 'Demo registration (empty-this server; 0-disable; url-web page)',
    'reports.BRANDING_DEPOSIT_URL': 'Deposit URL (empty to disable, url for page)',
    'reports.BRANDING_DISCLAMER': 'Disclaimer (showed in login skin 0)',
    'reports.BRANDING_DOWJONES_ENCRYPTED_TOKEN': 'Dow Jones encrypted token',
    'reports.BRANDING_EMAIL': 'Email',
    'reports.BRANDING_EXT_LINK_RULES': 'Linki zewnętrzne',
    'reports.BRANDING_FORGOTPWD_URL': 'Forgot password URL',
    'reports.BRANDING_HTML_FOOTER': 'Footer image',
    'reports.BRANDING_HTML_LOGINSCREEN_LOGO': 'Login screen image',
    'reports.BRANDING_HTML_LOGO': 'Logo image',
    'reports.BRANDING_HTML_NAME': 'Application name',
    'reports.BRANDING_HTML_STATION_RULES': 'Back office',
    'reports.BRANDING_INFO_MESSAGE': 'Popup message (opened in station after login)',
    'reports.BRANDING_KEY': 'Branding Key(used to link installators to it)',
    'reports.BRANDING_KNOWLEDGE_BASE_URL': 'Knowledge base URL(-1 to disable)',
    'reports.BRANDING_LANG_SET': 'Language resources',
    'reports.BRANDING_LIVE_GROUP': 'Place auto registered live users into',
    'reports.BRANDING_LOGIN_MESSAGE': 'Login screen attention message',
    'reports.BRANDING_MAIL_CONFIGURATION': 'Mail configuration',
    'reports.BRANDING_MAIL_PATTERN': 'Pattern',
    'reports.BRANDING_NET_BACKGROUND': 'Background image (optional/.bmp any size)',
    'reports.BRANDING_NET_BANNER': 'Banner image (.bmp 500x36)',
    'reports.BRANDING_NET_BROKERIMAGE': 'Report image (.bmp any size)',
    'reports.BRANDING_NET_CONFIGURAION': 'Default configuration (program.ini file)',
    'reports.BRANDING_NET_DEFWORKSPACE': 'Default workspace (*.wrk file)',
    'reports.BRANDING_NET_FX_STYLE': 'FX cell skin (enter code)',
    'reports.BRANDING_NET_HELP': 'Help URL',
    'reports.BRANDING_NET_ICON': 'Icon image (.ico 16x16+32x32)',
    'reports.BRANDING_NET_INSTALLER_BANNER_IMAGE': 'Installer banner image(500*59)',
    'reports.BRANDING_NET_INSTALLER_DIALOG_IMAGE': 'Installer dialog image(500*316)',
    'reports.BRANDING_NET_LINK': 'Application link',
    'reports.BRANDING_NET_LOADING_IMAGE': 'Loading image *.gif',
    'reports.BRANDING_NET_LOGIN_STYLE': 'Login screen skin (enter code)',
    'reports.BRANDING_NET_LOGO': 'Logo image (.bmp 500x35)',
    'reports.BRANDING_NET_NAME': 'Application name (for app data, short-cuts)',
    'reports.BRANDING_NET_OE_STYLE': 'Order entry skin (enter code)',
    'reports.BRANDING_NET_RIBBONICON': 'Ribbon icon image (.ico 32x32)',
    'reports.BRANDING_NET_STATION_RULES': '.Net station',
    'reports.BRANDING_NET_USE_FINGERPRINT': 'Użyj odcisku palca',
    'reports.BRANDING_PHONE': 'Company phone',
    'reports.BRANDING_POWERED_BY': 'Powered by (company or person name)',
    'reports.BRANDING_PROPERTIES_RULES': 'Branding properties',
    'reports.BRANDING_RULES': 'Branding visibility',
    'reports.BRANDING_SCRIPT_URL': 'Script URL',
    'reports.BRANDING_SERVER_URL': 'Branded server URL (URL or ip to link first page, before login)',
    'reports.BRANDING_STATUS_MESSAGE': 'Status bar message (showed in station status bar)',
    'reports.BRANDING_THEME': 'Look & Feel',
    'reports.BRANDING_TOOLS_RULES': 'External web links in Tools menu',
    'reports.BRANDING_TTP_FILE': 'Panel Templates',
    'reports.BRANDING_USERS_RULES': 'User rules',
    'reports.BRANDING_WITHDRAW_URL': 'Withdraw URL (empty to disable, url for page, 1 -win form)',
    'reports.branding.name': 'Nazwa',
    'reports.Broker Message Report': 'Broker message report',
    'reports.Brokerage': 'Brokerage',
    'reports.brokerCancel': 'Broker cancel',
    'reports.brokerlogin': 'Broker login',
    'reports.Buy': 'Kupno',
    'reports.buy trade amount': 'Wielkość transakcji kupna',
    'reports.buy trades': 'Transakcje kupna',
    'reports.buyselltype': 'Kupno/Sprzedaż',
    "reports.Can't allocate route for trade using hadget": "Can't allocate route for trade using hadget",
    "reports.Can't close entry": 'Nie można zamknąć otwarcia',
    'reports.Cancel order:': 'Anuluj zlecenie:',
    'reports.Cancel request accepted by broker': 'Dyspozycja anulaty przyjęta przez brokera',
    'reports.Cancel request received by STP': 'Dyspozycja anulaty przyjęta przez STP',
    'reports.Canceled': 'Anulowano',
    'reports.Canceling': 'Anulowano',
    'reports.Cancelled': 'Anulowano',
    'reports.Cancelling': 'Anulowano',
    'reports.CantorFX': 'CantorFX',
    'reports.Cash Deposit And Withdrawal Report': 'Wpłaty/wypłaty',
    'reports.cash_posted': 'Cash posted',
    'reports.Champ stats report': 'Champ statistics report',
    'reports.change': 'Zmień',
    'reports.Change password error, new password must differ from old one': 'Błąd - nowe hasło musi się różnic od poprzedniego',
    'reports.CHART_OE': 'Wykres (wbudowany w OE)',
    'reports.DESKTOP_ALERT': 'Alarm',
    'reports.REST': 'Alarm',
    'reports.MAC_ALERT': 'Alarm',
    'reports.ANDROID_ALERT': 'Alarm',
    'reports.IOS_ALERT': 'Alarm',
    'reports.CHART_VISUAL': 'Wykres (visual trading)',
    'reports.Chat History Report': 'Chat history report',
    'reports.CheckCompID': 'Check comp ID',
    'reports.CheckLatency': 'Check latency',
    'reports.ChoiceFX': 'ChoiceFX',
    'reports.CIBC': 'CIBC',
    'reports.Citi': 'Citi',
    'reports.ClearingVmTransfer': 'Clearing VM transfer',
    'reports.clfr_close_date': 'Data zamknięcia',
    'reports.clfr_fund_name': 'Fund name',
    'reports.clientid': 'Nr Klienta',
    'reports.clienttype': 'Client type',
    'reports.Close Limit order created': 'Utworzono zlecenie zamykające Limit',
    'reports.Close Limit order created is off market': 'Zlecenie zamykające Limit - off market',
    'reports.Close Limit order created is unplaced': 'Zlecenie zamykające Limit nie zostało złożone na giełdzie',
    'reports.Close Limit order received by broker': 'Zlecenie zamykające Limit zostało otrzymane przez brokera',
    'reports.Close Market order created': 'Zlecenie zamykające Market zostało utworzone',
    'reports.Close Market Order Created': 'Zlecenie zamykające Market zostało utworzone',
    'reports.Close Market order created is off market': 'Zlecenie zamykające Market - off market',
    'reports.Close Market order created is unplaced': 'Zlecenie zamykające Market nie zostało złożone na giełdzie',
    'reports.Close Market order received by broker': 'Zlecenie zamykające Market zostało otrzymane przez brokera',
    'reports.Close OCO order created': 'Zlecenie zamykające OCO zostało utworzone',
    'reports.Close OCO order created is off market': 'Zlecenie zamykające OCO - off market',
    'reports.Close OCO order created is unplaced': 'Zlecenie zamykające OCO nie zostało złożone na giełdzie',
    'reports.Close OCO order received by broker': 'Zlecenie zamykające OCO zostało otrzymane przez brokera',
    'reports.Close order type': 'Typ zlecenia zamykającego',
    'reports.Close position:': 'Zamknij pozycję:',
    'reports.Close Sell': 'Zamknij sprzedaż',
    'reports.Close SL order created': 'Zlecenie zamykające SL zostało utworzone',
    'reports.Close SL order created is off market': 'Zlecenie zamykające SL - off market',
    'reports.Close SL order created is unplaced': 'Zlecenie zamykające SL nie zostało złożone na giełdzie',
    'reports.Close SL order received by broker': 'Zlecenie zamykające SL zostało otrzymane przez brokera',
    'reports.Close SL tr. stop order created': 'Zlecenie zamykające TR. Stop zostało utworzone',
    'reports.Close SL tr. stop order created is off market': 'Close SL Tr. stop order is off market',
    'reports.Close SL tr. stop order created is unplaced': 'Zlecenie zamykające TR. Stop nie zostało złożone na giełdzie',
    'reports.Close SL tr. stop order received by broker': 'Zlecenie zamykające TR. Stop zostało otrzymane przez brokera',
    'reports.Close Stop Limit order created': 'Zlecenie zamykające Stop limit zostało utworzone',
    'reports.Close Stop Limit order created is off market': 'Zlecenie zamykające Stop Limit - off market',
    'reports.Close Stop Limit order created is unplaced': 'Zlecenie zamykające Stop limit nie zostało złożone na giełdzie',
    'reports.Close Stop Limit order received by broker': 'Zlecenie zamykające Stop limit zostało otrzymane przez brokera',
    'reports.Close Stop order created': 'Zlecenie zamykające Stop zostało utworzone',
    'reports.Close Stop order created is off market': 'Zlecenie zamykające Stop - off market',
    'reports.Close Stop order created is unplaced': 'Zlecenie zamykające Stop nie zostało złożone na giełdzie',
    'reports.Close Stop order received by broker': 'Zlecenie zamykające Stop zostało otrzymane przez brokera',
    'reports.Close TP order created': 'Zlecenie zamykające TP zostało utworzone',
    'reports.Close TP order created is off market': 'Zlecenie zamykające TP - off market',
    'reports.Close TP order created is unplaced': 'Zlecenie zamykające TP nie zostało złożone na giełdzie',
    'reports.Close TP order received by broker': 'Zlecenie zamykające TP zostało otrzymane przez brokera',
    'reports.Close Tr. stop order created': 'Zlecenie zamykające TR. Stop zostało utworzone',
    'reports.Close Tr. stop order created is off market': 'Zlecenie zamykające TR Stop - off market',
    'reports.Close Tr. stop order created is unplaced': 'Zlecenie zamykające TR. Stop nie zostało złożone na giełdzie',
    'reports.Close Tr. stop order received by broker': 'Zlecenie zamykające TR. Stop zostało otrzymane przez brokera',
    'reports.CLOSE_ALL': 'Zamknij wszystkie',
    'reports.CLOSE_ALL_ORDERS': 'Anuluj wszystkie zlecenia',
    'reports.CLOSE_ALL_POSITIONS': 'Zamknij wszystkie pozycje',
    'reports.CLOSE_FIFO': 'Close positions while not enough margin (FIFO)',
    'reports.CLOSE_LAGES': 'Close positions while not enough margin (Largest first)',
    'reports.CLOSE_LIFO': 'Close positions while not enough margin (LIFO)',
    'reports.closebytradeid': 'Zamknij po numerze pozycji',
    'reports.Closed funds report': 'Closed funds report',
    'reports.Closed Positions': 'Closed positions report',
    'reports.closedat': 'Zamknij',
    'reports.closedbyorderid': 'Close by order ID',
    'reports.ClosedResendInterval': 'Closed resend interval',
    'reports.CloseOptionPremium': 'Close option premium',
    'reports.Closeposition': 'Close position',
    'reports.closeprice': 'Cena zamknięcia',
    'reports.closetime': 'Czas zamknięcia',
    'reports.comission': 'Prowizja',
    'reports.Comment': 'Komentarz',
    'reports.comments': 'Komentarz',
    'reports.comments.CancelOperation': 'Cancel operation #',
    'reports.COMMISIONSPLAN_RULES': 'Revenue plans visibility',
    'reports.commission': 'Prowizja',
    'reports.commission_out': 'Prowizja za zamknięcie',
    'reports.commissionpertrade': 'Prowzja dla transakcji',
    'reports.commissions': 'Prowizje',
    'reports.Commissions': 'Prowizje',
    'reports.Commissions report': 'Commissions report',
    'reports.Commissions Report': 'Commissions report',
    'reports.Commissions Report(copy)': 'Commissions report(copy)',
    'reports.commissiontotal': 'Commission total',
    'reports.COMMON_RULES': 'Common rules',
    'reports.Community': 'Community',
    'reports.ConfigFilePath': 'Configure file path',
    'reports.Connected': 'Połączono',
    'reports.Connection established': 'Połączono',
    'reports.Connections Report': 'Raport połączeń',
    'reports.ConnectionType': 'Typ połączenia',
    'reports.connrp_conntime': 'Próba połączenia',
    'reports.connrp_fullname': 'Imię i Nazwisko',
    'reports.connrp_ip': 'IP',
    'reports.connrp_lastpwdchange': 'Ostatnia zmiana hasła',
    'reports.connrp_login': 'Login',
    'reports.connrp_success': 'Połączono!',
    'reports.ContinueInitializationOnError': 'Kontynuuj uruchamianie pomimo błędu',
    'reports.Contract Specification Report': 'Contract specification report',
    'reports.Counter Account Name': 'Counter account name',
    'reports.Counter Operation Id': 'Counter operation ID',
    'reports.COUNTERACCOUNTS_RULES': 'Counter accounts',
    'reports.Created': 'Utworzono',
    'reports.Created by': 'Utworzono przez',
    'reports.createdat': 'Created at',
    'reports.Creating': 'Created',
    'reports.creator': 'Creator',
    'reports.credit': 'Credit',
    'reports.Credit': 'Credit',
    'reports.crossprice': 'Cena krossowa',
    'reports.crosstype': 'Cross type',
    'reports.currency': 'Waluta',
    'reports.Currency Exposure Report': 'Currency exposure report',
    'reports.Currency summary exposure': 'Currency summary exposure',
    'reports.currency.balance_interest': 'Balance interest(%)',
    'reports.currency.interest_buy_rate': 'Interest on positive(%)',
    'reports.currency.interest_sell_rate': 'Interest on negative(%)',
    'reports.currency.leverage_negative': 'Negative exposure leverage',
    'reports.currency.leverage_positive': 'Positive exposure leverage',
    'reports.currency.min_change': 'Minimal change',
    'reports.currency.name': 'Name',
    'reports.Currenex': 'Currenex',
    'reports.CurrenexFIX': 'CurrenexFIX',
    'reports.Current Balance': 'Saldo',
    'reports.currentprice': 'Cena Market',
    'reports.CUSTOM_COLUMN_BTN_SCRIPT': 'Własna kolumna',
    'reports.customReport': 'Custom reports',
    'reports.DataDictionary': 'Data dictionary',
    'reports.DateTime': 'Data/Czas',
    'reports.days': 'dni',
    'reports.daystillexpiration': 'Days till expiration',
    'reports.DBFX': 'DBFX',
    'reports.dealercommission': 'Dealer commission',
    'reports.debit': 'Debit',
    'reports.Default settings failed': 'Błąd ustawień domyślnych',
    'reports.Delayed Limit Order Created': 'Utworzono zlecenie oczekujące Limit',
    'reports.Delayed Limit Order Modified': 'Zmodyfikowano zlecenie oczekujące Limit',
    'reports.Delayed Limit Order Removed': 'Usunięto zlecenie oczekujące Limit',
    'reports.Delayed OCO Order Created': 'Utworzono zlecenie oczekujące OCO',
    'reports.Delayed Stop Limit Order Created': 'Utworzono zlecenie oczekujące Stop Limit',
    'reports.Delayed Stop Limit Order Modified': 'Zmodyfikowano zlecenie oczekujące Stop Limit',
    'reports.Delayed Stop Limit Order Removed': 'Usunięto zlecenie Stop Limit',
    'reports.Delayed Stop Order Created': 'Utworzono zlecenie oczekujące Stop',
    'reports.Delayed Stop Order Modified': 'Zmodyfikowano zlecenie oczekujące Stop',
    'reports.Delayed Stop Order Removed': 'Usunięto zlecenie oczekujące Stop',
    'reports.Delayed Trailing Stop Order Created': 'Utworzono zlecenie Trailing Stop',
    'reports.Delayed Trailing Stop Order Modified': 'Zmodyfikowano zlecenie Trailing Stop',
    'reports.Delayed Trailing Stop Order Removed': 'Usunięto zlecenie Trailing Stop',
    'reports.DelayedCharging': 'DelayedCharging',
    'reports.DelayedDataCharging': 'Delayed data charging',
    'reports.delivered': 'Delivered',
    'reports.DeliveredForward': 'Delivered forward',
    'reports.Delivery': 'Delivery',
    'reports.demoMode': 'Demo mode',
    'reports.deposit': 'Deposit',
    'reports.Deposit': 'Deposit',
    'reports.Detail': 'Detail',
    'reports.Deutsche Bank': 'Deutsche Bank',
    'reports.DeutscheBankFIXFeed': 'DeutscheBankFIXFeed',
    'reports.DGCX': 'DGCX',
    'reports.Dictionary': 'Słownik',
    'reports.discount': 'Discount',
    'reports.dividends': 'Dywidendy',
    'reports.DO_NOT_CLOSE': 'Nie zamykaj pozycji',
    'reports.DukasCopy': 'DukasCopy',
    'reports.DWT': 'DWT',
    'reports.email': 'Email',
    'reports.EMAIL_NOTIFICATION': 'E-mail notification pattern visibility',
    'reports.EMAIL_RULES': 'Email notification pattern visibility',
    'reports.EndDay': 'End day',
    'reports.EndTime': 'End time',
    'reports.entityId': 'Entity ID',
    'reports.enum_StopOutType_AutoClose': 'Auto zamknięcie',
    'reports.enum_StopOutType_CloseAllByInstruments': 'Zamknij wszystkie dla instrumentu',
    'reports.enum_StopOutType_CloseAllPositions': 'Zamknij wszystkie pozycje',
    'reports.enum_StopOutType_CloseLagestLoss': 'Close positions while not enough margin (Largest loss first)',
    'reports.enum_StopOutType_ClosePositionsByMarginExcess': 'Close positions by margin excess',
    'reports.enum_StopOutType_DoNotClosePositions': 'Do not close positions',
    'reports.enum_StopOutType_FIFO': 'Close positions while not enough margin (FIFO)',
    'reports.enum_StopOutType_LargestFirst': 'Close positions while not enough margin (Largest first)',
    'reports.enum_StopOutType_LIFO': 'Close positions while not enough margin (LIFO)',
    'reports.Error occured during downloading update:': 'Błąd podczas pobierania aktualizacji:',
    'reports.Error sending email': 'Błąd podczas wysyłania email',
    'reports.Error: Not enough Margin to open position': 'Brak wystarczającyh środków do otwarcia pozycji',
    'reports.error.certificate_corrupted': 'Błąd certyfikatu.',
    'reports.error.certificate_not_found': 'Nie znaleziono certyfikatu.',
    'reports.error.error_during_saving_route': 'An error occurred while saving Route. Please contact your administrator.',
    'reports.error.fill_mandatory': 'Uzupełnij wszystkie wymagane pola.',
    'reports.error.name_is_in_use': 'Ta nazwa już istnieje!',
    'reports.error.not_valid_email': 'Błędny E-mail.',
    'reports.error.not_valid_firstname': 'Błędne imię.',
    'reports.error.not_valid_instrumentname_capital': 'Błędna nazwa instrumentu.',
    'reports.error.not_valid_instrumentname_exist': 'Instrument o tej nazwie już istnieje',
    'reports.error.not_valid_instrumentname_length': "Invalid Name. Name can't be longer than 32 characters.",
    'reports.error.not_valid_instrumenttypename': 'Invalid instrument type. Please specify correct instrument type.',
    'reports.error.not_valid_lastname': 'Błędne nazwisko.',
    'reports.error.not_valid_login': 'Błędny login.',
    'reports.error.not_valid_name': 'Błędna nazwa.',
    'reports.error.not_valid_name_exist': 'Invalid Name. This name already exists.',
    'reports.error.not_valid_phone_number': 'Invalid phone number. Enter only digital data.',
    'reports.error.photo_not_found': 'Nie znaleziono zdjęcia',
    'reports.error.too_short_phone_password': 'Too short phone password',
    'reports.error.wrong_percenage': 'Błędny procent',
    'reports.ESignal': 'ESignal',
    'reports.event': 'Zdarzenie',
    'reports.Event History Report': 'Event history report',
    'reports.Event History Report with IP': 'Event history report with IP',
    'reports.Exception': 'Wyjątek',
    'reports.exchange': 'Giełda',
    'reports.Exchange': 'Giełda',
    'reports.Executed': 'Wykonane',
    'reports.Executed Orders Report': 'Executed orders report',
    'reports.executedat': 'Wykonane',
    'reports.Executing': 'Wykonane',
    'reports.executionfee': 'Execution fee',
    'reports.executionprice': 'Execution price',
    'reports.expiredate': 'Expiration date',
    'reports.expiresat': 'Expire',
    'reports.external_order_id': 'External order id',
    'reports.externalid': 'External ID',
    'reports.externalorderid': 'External order ID',
    'reports.extfield': 'Ext. field',
    'reports.EXTFIELDS_RULES': 'Extended fields visibility',
    'reports.extprice': 'Ext. price',
    'reports.False': 'False',
    'reports.fee': 'Fee',
    'reports.Fee: [addliquidityrebate]': 'Fee: [addliquidityrebate]',
    'reports.Fee: [Brokerage]': 'Fee: [Brokerage]',
    'reports.Fee: [Exchange]': 'Fee: [Exchange]',
    'reports.Fee: [InvestorProtectionLevy]': 'Fee: [InvestorProtectionLevy]',
    'reports.Fee: [removeliquidityrebate]': 'Fee: [removeliquidityrebate]',
    'reports.Fee: [SecuritiesTransferTax]': 'Fee: [SecuritiesTransferTax]',
    'reports.Fee: [SettlementFee]': 'Fee: [SettlementFee]',
    'reports.Fee: [ValueAddedTax]': 'Fee: [ValueAddedTax]',
    'reports.Feed Data': 'Feed data',
    'reports.Fees Report': 'Opłaty',
    'reports.FFastFillFIXFeed': 'FFastFillFIXFeed',
    'reports.fiahr_action': 'Action',
    'reports.fiahr_amount': 'Amount',
    'reports.fiahr_capital': 'Capital',
    'reports.fiahr_count': 'Share Count',
    'reports.fiahr_date': 'Date',
    'reports.fiahr_fee': 'Reward',
    'reports.fiahr_grosspl': 'Gross P/L',
    'reports.fiahr_investor_id': 'Investor ID',
    'reports.fiahr_investor_name': 'Investor',
    'reports.fiahr_netpl': 'Net P/L',
    'reports.fiahr_pamm_id': 'Fund ID',
    'reports.fiahr_pamm_name': 'Fund',
    'reports.fiahr_price': 'Price',
    'reports.fiahr_requestid': 'Request ID',
    'reports.Fidessa': 'Fidessa',
    'reports.FileIncludeMilliseconds': 'File includes milliseconds',
    'reports.FileIncludeTimeStampForMessages': 'File includes time stamp for messages',
    'reports.FileLogHeartbeats': 'File log heartbeats',
    'reports.Filled': 'Filled',
    'reports.Filled close Limit order': 'Wykonano zlecenie zamykające Limit',
    'reports.Filled close Limit order (Mutual)': 'Wykonano zlecenie zamykające Limit (łącznie)',
    'reports.Filled close Market order': 'Wykonano zlecenie zamykające Market',
    'reports.Filled close Market order (Mutual)': 'Wykonano zlecenie zamykające Market (łącznie)',
    'reports.Filled close OCO order': 'Wykonano zlecenie zamykające OCO',
    'reports.Filled close OCO order (Mutual)': 'Wykonano zlecenie zamykające OCO (łącznie)',
    'reports.Filled close SL order': 'Wykonano zlecenie zamykające SL',
    'reports.Filled close SL order (Mutual)': 'Wykonano zlecenie zamykające SL(łącznie)',
    'reports.Filled close SL tr. stop order': 'Wykonano zlecenie zamykające Tr. Stop',
    'reports.Filled close SL tr. stop order (Mutual)': 'Wykonano zlecenie zamykające Tr. Stop (łącznie)',
    'reports.Filled close Stop Limit order': 'Wykonano zlecenie zamykające Stop Limit',
    'reports.Filled close Stop Limit order (Mutual)': 'Wykonano zlecenie zamykające Stop Limit (łącznie)',
    'reports.Filled close Stop order': 'Wykonano zlecenie zamykające Stop',
    'reports.Filled close Stop order (Mutual)': 'Wykonano zlecenie zamykające Stop (łącznie)',
    'reports.Filled close TP order': 'Wykonano zlecenie zamykające TP',
    'reports.Filled close TP order (Mutual)': 'Wykonano zlecenie zamykające TP (łącznie)',
    'reports.Filled close Tr. stop loss order': 'Wykonano zlecenie zamykające TR. Stop',
    'reports.Filled close Tr. stop order': 'Wykonano zlecenie zamykające TR. Stop',
    'reports.Filled close Tr. stop order (Mutual)': 'Wykonano zlecenie zamykające TR. Stop (łącznie)',
    'reports.Filled Limit order': 'Wykonano zlecenie Limit',
    'reports.Filled Limit order (Mutual)': 'Wykonano zlecenie Limit (łącznie)',
    'reports.Filled Market order': 'Wykonano zlecenie Market',
    'reports.Filled Market order (Mutual)': 'Wykonano zlecenie Market (łącznie)',
    'reports.Filled OCO order': 'Wykonano zlecenie OCO',
    'reports.Filled OCO order (Mutual)': 'Wykonano zlecenie OCO (łącznie)',
    'reports.Filled SL order': 'Wykonano zlecenie SL',
    'reports.Filled SL order (Mutual)': 'Wykonano zlecenie SL (łącznie)',
    'reports.Filled SL tr. stop order': 'Wykonano zlecenie TR. Stop',
    'reports.Filled SL tr. stop order (Mutual)': 'Wykonano zlecenie TR. Stop (łącznie)',
    'reports.Filled Stop Limit order': 'Wykonano zlecenie Stop Limit',
    'reports.Filled Stop Limit order (Mutual)': 'Wykonano zlecenie Stop Limit (łącznie)',
    'reports.Filled Stop order': 'Wykonano zlecenie Stop',
    'reports.Filled Stop order (Mutual)': 'Wykonano zlecenie Sto (łącznie)',
    'reports.Filled TP order': 'Wykonano zlecenie TP',
    'reports.Filled TP order (Mutual)': 'Wykonano zlecenie TP (łącznie)',
    'reports.Filled Tr. stop order': 'Wykonano zlecenie TR. Stop',
    'reports.Filled Tr. stop order (Mutual)': 'Wykonano zlecenie TR. Stop (łącznie)',
    'reports.filledamount': 'Zrealizowana ilość',
    'reports.fir_exit_capital': 'Current capital',
    'reports.fir_exit_price': 'Current share price',
    'reports.fir_investor_id': 'Investor ID',
    'reports.fir_investor_name': 'Investor name',
    'reports.fir_join_capital': 'Start capital',
    'reports.fir_join_date': 'Join date',
    'reports.fir_join_price': 'Join price',
    'reports.fir_pamm_id': 'Fund id',
    'reports.fir_pamm_name': 'Fund name',
    'reports.fir_reinvest_cycles': 'Reinvest cycles',
    'reports.fir_share_count': '#Shares',
    'reports.FirstDerivatives': 'FirstDerivatives',
    'reports.firstname': 'First name',
    'reports.FixedFee': 'Fixed Commission',
    'reports.Custodial': 'Custodial',
    'reports.Foreks': 'Foreks',
    'reports.Forex balances report': 'Forex balances report',
    'reports.Formal Dialog Report': 'Formal dialog report',
    'reports.FORTEX': 'FORTEX',
    'reports.fpghr_count': 'Shares',
    'reports.fpghr_exit_amount': 'Exit amount',
    'reports.fpghr_exit_price': 'Exit price',
    'reports.fpghr_fee': 'Fee',
    'reports.fpghr_first_join_date': 'Join date',
    'reports.fpghr_grosspl': 'Gross P/L',
    'reports.fpghr_investor_id': 'Investor ID',
    'reports.fpghr_investor_name': 'Investor name',
    'reports.fpghr_join_amount': 'Join amount',
    'reports.fpghr_join_price': 'Join price',
    'reports.fpghr_last_exit_date': 'Exit date',
    'reports.fpghr_netpl': 'Net P/L',
    'reports.fpghr_pamm_id': 'Fund ID',
    'reports.fpghr_pamm_name': 'Fund name',
    'reports.fpghr_period': 'Period',
    'reports.fpghr_period_end_date': 'End date',
    'reports.fpghr_period_start_date': 'Start date',
    'reports.fprhr_date': 'Last update',
    'reports.fprhr_details': 'Details',
    'reports.fprhr_entryid': 'Entry ID',
    'reports.fprhr_event': 'Event',
    'reports.fprhr_investor_id': 'Investor ID',
    'reports.fprhr_investor_name': 'Investor',
    'reports.fprhr_pamm_id': 'Fund ID',
    'reports.fprhr_pamm_name': 'Fund',
    'reports.fprhr_requestid': 'Request ID',
    'reports.from': 'Od',
    'reports.From.SERVER': 'Serwer',
    'reports.fromDate': 'Od',
    'reports.fromDateConnAttempt': 'Od',
    'reports.fromDatePwdChange': 'Od',
    'reports.FUNCTION_ACCOUNT_GROUP': 'Account group',
    'reports.FUNCTION_ACCOUNT_OPERATIONS': 'Funding operations visibility',
    'reports.FUNCTION_ACCOUNTS': 'Rachunki',
    'reports.FUNCTION_ALERTS': 'Alarmy',
    'reports.FUNCTION_ARBITRAGER': 'Arbitrager',
    'reports.FUNCTION_AUTOBROKET_LIST': 'Autobroker list',
    'reports.FUNCTION_BACK_TESTER': 'Backtester',
    'reports.FUNCTION_BARS_FROM': 'Make bars from',
    'reports.FUNCTION_BASE_CURRENCY': 'System base currency (you might need to change instrument crosses)',
    'reports.FUNCTION_BASKET': 'Basket',
    'reports.FUNCTION_BETTING': 'Betting',
    'reports.FUNCTION_BINDEDORDERS': 'Allow OCO orders',
    'reports.FUNCTION_BLOCKED_SUMM_FOR_ORDERS': 'Blocked fund for orders',
    'reports.FUNCTION_BRANDING': 'Brandings',
    'reports.FUNCTION_BRANDINGADMIN': 'Branding admin',
    'reports.FUNCTION_BUILDER': 'Builder',
    'reports.FUNCTION_CACHEADMIN': 'Cache admin',
    'reports.FUNCTION_CHART': 'Wykres',
    'reports.FUNCTION_CHAT': 'Chat',
    'reports.FUNCTION_CLOSE_POSITIONS_DISABLE': 'Not to allow  close positions',
    'reports.FUNCTION_COMMUNITY': 'Community',
    'reports.FUNCTION_CONFIGADMIN': 'Configuration and branding',
    'reports.FUNCTION_CONNECTIONSSADMIN': 'Connections admin',
    'reports.FUNCTION_CROSS_PAIR': 'Cross price',
    'reports.FUNCTION_CUR_EXP': 'Currency exposure',
    'reports.FUNCTION_DISABLE_BUY': 'Disable buy orders',
    'reports.FUNCTION_DISABLE_OPTION_TRADING': 'Disable option orders',
    'reports.FUNCTION_DISABLE_SELL': 'Disable sell orders',
    'reports.FUNCTION_DO_NOT_MARGIN': 'Not to use margin',
    'reports.FUNCTION_EVENT_LOG': 'Event log',
    'reports.FUNCTION_FUNDING': 'Operations admin',
    'reports.FUNCTION_GENERATE_OPTIONS': 'Generate options from level1',
    'reports.FUNCTION_HEDGE_RULE_TYPE': 'Hedge rule list:',
    'reports.FUNCTION_INSTRUMENT_LOOKUP': 'Symbol lookup',
    'reports.FUNCTION_INSTRUMENT_PORTFOLIO': 'Portfolio',
    'reports.FUNCTION_INSTRUMENTSADMIN': 'Symbols admin',
    'reports.FUNCTION_INTERSERVER': 'Interserver',
    'reports.FUNCTION_LEVEL2': 'Level2 data and orders',
    'reports.FUNCTION_LEVEL3': 'Time & Sales',
    'reports.FUNCTION_LOCKED_BY_BROKER': 'Locked by broker',
    'reports.FUNCTION_MANUAL': 'Allow manual orders',
    'reports.FUNCTION_MARGIN_MODE': 'Margin mode',
    'reports.FUNCTION_MASTER': 'Master account management',
    'reports.FUNCTION_MATRIX': 'Matrix',
    'reports.FUNCTION_MULTI_CONNECTION': 'Allow multi-connection',
    'reports.FUNCTION_MUTUAL_CLOSE': 'Allow mutual closing',
    'reports.FUNCTION_NEWS': 'Informacje',
    'reports.FUNCTION_NEWSADMIN': 'News admin',
    'reports.FUNCTION_NOT_ALLOW_OPEN_POSITION': 'Tylko zlecenia zamykające',
    'reports.FUNCTION_NOT_ALLOW_SHORT_STOCK': 'Not to allow short stocks',
    'reports.FUNCTION_ONE_COUNTERACCOUNT': 'One counter account',
    'reports.FUNCTION_ONE_POSITION': 'One position per symbol (hedging)',
    'reports.FUNCTION_ONE_QUOTECACHECOUNT': 'One cache size for all',
    'reports.FUNCTION_OPTIONS': 'Options',
    'reports.FUNCTION_ORDER_BOOK': 'Orders history',
    'reports.FUNCTION_PARTIAL_CLOSE': 'Allow partial closing',
    'reports.FUNCTION_POSITION_BALANCE': 'Saldo pozycji',
    'reports.FUNCTION_POSITIONSADMIN': 'Positions admin',
    'reports.FUNCTION_QUOTE_HISTORY': 'Quote history',
    'reports.FUNCTION_QUOTES': 'Quotes',
    'reports.FUNCTION_REPORT': 'Report',
    'reports.FUNCTION_REPORTADMIN': 'Report admin',
    'reports.FUNCTION_RESERVER_WITHDRAWAL': 'Withdrawal',
    'reports.FUNCTION_PRODUCTS': 'Products',
    'reports.FUNCTION_RFQ': 'RFQ Panel',
    'reports.FUNCTION_ROLESADMIN': 'User groups admin',
    'reports.FUNCTION_ROUTESADMIN': 'Routes admin',
    'reports.FUNCTION_SCHEDULERADMIN': 'Scheduler admin',
    'reports.FUNCTION_SCRIPT_BUILDER': 'Script builder',
    'reports.FUNCTION_SERVICESADMIN': 'Services admin',
    'reports.FUNCTION_SETTINGSADMIN': 'Settings admin',
    'reports.FUNCTION_SHOW_ORDERS': 'Orders',
    'reports.FUNCTION_SHOW_POSITIONS': 'Positions',
    'reports.FUNCTION_SLAVE': 'Slave',
    'reports.FUNCTION_SLTP': 'Allow SL/TP orders',
    'reports.FUNCTION_SMS_SERVICE_DEST': 'SMS Service destination',
    'reports.FUNCTION_SN': 'License number',
    'reports.FUNCTION_STRATEGIESADMIN': 'Strategies admin',
    'reports.FUNCTION_SUPPORT_EMAIL': 'E-mail of support Team',
    'reports.FUNCTION_TABLESADMIN': 'Tables admin',
    'reports.FUNCTION_THREADEDSAVER': 'Threaded saver enabled',
    'reports.FUNCTION_TRADES': 'Filled orders',
    'reports.FUNCTION_TRADING': 'Trading',
    'reports.FUNCTION_TRADING_MODE': 'Trading mode',
    'reports.FUNCTION_TRAILING_STOP': 'Allow trailing stop',
    'reports.FUNCTION_USER_CONNECTION_POLICY': 'User multi-connection policy',
    'reports.FUNCTION_USERSADMIN': 'Users admin',
    'reports.FUNCTION_VALIDATE_SSL_CERTIFICATE': 'Need validation of SSL certificate',
    'reports.FUNCTION_VENDOR_NAME': 'Vendor name',
    'reports.FUNCTION_WATCHLIST': 'WatchList',
    'reports.FUNCTION_ALERT': 'Alert',
    'reports.FUNCTION_ASSETS': 'Assets',
    'reports.Fund Gain History Report': 'Fund gain history report',
    'reports.Fund Investments Report': 'Fund investments report',
    'reports.Fund Investor Action History Report': 'Fund investor action history report',
    'reports.Fund Investor History Report': 'Fund investor history report',
    'reports.Fund Request History Report': 'Fund request history report',
    'reports.Fund Transfer': 'Fund Transfer',
    'reports.fund_type.closed': 'Closed-End',
    'reports.fund_type.open': 'Open-End',
    'reports.fundID': 'Fund ID',
    'reports.FUNDING_RULES': 'Funding operations visibility',
    'reports.fxintegrate.cert.host.verify': 'fx integrate cert host verify',
    'reports.fxintegrate.cert.root.ca.file': 'fx integrate cert root ca file',
    'reports.fxintegrate.host': 'fx integrate host',
    'reports.fxintegrate.logging.file': 'fx integrate logging file',
    'reports.fxintegrate.logging.maxfilecount': 'fx integrate logging maximum file count',
    'reports.fxintegrate.logging.maxfilesize': 'fx integrate logging maximum file size',
    'reports.fxintegrate.logging.output': 'fx integrate logging output',
    'reports.fxintegrate.logging.priority': 'fx integrate logging priority',
    'reports.fxintegrate.port': 'fx integrate port',
    'reports.fxintegrate.protocol': 'fxintegrate protocol',
    'reports.fxintegrate.usessl': 'fx integrate use ssl',
    'reports.GENERAL_COMMON_RULES': 'General common rules',
    'reports.GENERAL_USER_RULES': 'General rules',
    'reports.GFT': 'GFT',
    'reports.gi.available_margin': 'Margin available',
    'reports.gi.avaliable_funds': 'Available funds',
    'reports.gi.balance': 'Balance',
    'reports.gi.balance_blocked_by_orders': 'Blocked by orders',
    'reports.gi.cur_balance': 'Projected balance',
    'reports.gi.cur_margin': 'Cur. margin, %',
    'reports.gi.fund_capital': 'Fund capital',
    'reports.gi.fund_used': 'Funds used',
    'reports.gi.maintain_margin': 'Maintenance margin',
    'reports.gi.margin_orders': 'Margin for orders',
    'reports.gi.margin_positions': 'Margin for positions',
    'reports.gi.margin_stopout': 'Stop out',
    'reports.gi.margin_stoptrade': 'Stop trade',
    'reports.gi.margin_used': 'Margin used',
    'reports.gi.margin_warning': 'Margin warning',
    'reports.gi.min_margin': 'Min. margin',
    'reports.gi.pos_maintenance_req': 'Pos. maint. req.',
    'reports.gi.risk_lvl': 'Risk level',
    'reports.gi.stock_value': 'Stock',
    'reports.gi.today_released_pnl': 'Realized P/L',
    'reports.gi.withdrawal_available': 'Withdrawal available',
    'reports.Goldman Sachs': 'Goldman Sachs',
    'reports.grosspl': 'Gross P/L',
    'reports.groupid': 'Group ID',
    'reports.groupname': 'Group',
    'reports.GTL': 'GTL',
    'reports.HeartBtInt': 'Heartbeat interval',
    'reports.hedged': 'STP (Straight-Through processing)',
    'reports.helpIndex': 'http://protrader.org/en/knowledge-base/pt3/desktop/account/reports',
    'reports.host': 'Host',
    'reports.HOT_BUTTON': 'Hotkey',
    'reports.HotSpot': 'HotSpot',
    'reports.hours': 'hours',
    'reports.HTTP.SERVER.HOST': 'HTTP SERVER HOST',
    'reports.HTTP.SERVER.PORT': 'HTTP SERVER PORT',
    'reports.HTTP.SERVER.PROTOCOL': 'HTTP SERVER PROTOCOL',
    'reports.HTTP.URL.HEARTBEAT': 'HTTP URL HEARTBEAT',
    'reports.HTTP.URL.LOGIN': 'HTTP URL LOGIN',
    'reports.HTTP.URL.LOGOUT': 'HTTP URL LOGOUT',
    'reports.HTTP.URL.PASSWORD.VALIDATION': 'HTTP URL PASSWORD VALIDATION',
    'reports.HTTP.URL.PRELOGIN': 'HTTP URL PRELOGIN',
    'reports.HTTP.URL.QUERY': 'HTTP URL QUERY',
    'reports.HTTP.URL.WORKFLOW': 'HTTP URL WORKFLOW',
    'reports.IB Trades Report': 'IB trades report',
    'reports.IGIndex': 'IGIndex',
    'reports.IndexingQuoteFrequency(sec)': 'Indexing quote frequency (sec)',
    'reports.Information': 'Information',
    'reports.Informational message': 'Informational message',
    'reports.Init margin req.': 'Depozyt wstępny',
    'reports.Init. margin req.': 'Depozyt wstępny',
    'reports.INSTALLER_SERVICE_URL': 'Service installer URL',
    'reports.InstrRouteMap': 'Route map',
    'reports.instrtype': 'Grupa instrumentów',
    'reports.INSTRTYPES_RULES': 'Widoczność grupy instrumentów',
    'reports.instrument': 'Instrument',
    'reports.Instrument': 'Instrument',
    'reports.instrument group': 'Grupa instrumentów',
    'reports.Instrument Specification': 'Specyfikacja instrumentów',
    'reports.Instrument Statistic Report': 'Instrument statistic report',
    'reports.Instrument Usage By Signal Report': 'Symbol usage by signal report',
    'reports.Instrument Visibility Report': 'Symbol visibility report',
    'reports.INSTRUMENT_DERIVATIVES': 'Derywaty',
    'reports.INSTRUMENT_GENERAL': 'Ogólne',
    'reports.INSTRUMENT_MARGIN': 'Margin',
    'reports.INSTRUMENT_TRADING': 'Trading',
    'reports.INSTRUMENT_TYPE_DERIVATIVES': 'Derywaty',
    'reports.instrument.group': 'Grupa instrumentów',
    'reports.instrument.name': 'Nazwa instrumentu',
    'reports.instrumentgroup': 'Grupa instrumentów',
    'reports.InstrumentId': 'Instrument',
    'reports.instrumentname': 'Instrument',
    'reports.instruments': 'Instrumenty',
    'reports.instrumenttype': 'Typ instrumentu',
    'reports.instrumenttype.duplicatename': 'Grupa instrumentów o tej nazwie już istnieje',
    'reports.instrumenttype.inheritanceerror': 'Error in types inheritance',
    'reports.instrumentType.name': 'Nazwa grupy instrumentów',
    'reports.instrumentType.superId': 'Nazwa super-typu instrumentu',
    'reports.instrumentVisibilityReport': 'Symbol visibility report',
    'reports.Integral': 'Integral',
    'reports.IntegralFIX': 'IntegralFIX',
    'reports.InteractiveData': 'InteractiveData',
    'reports.IntererstOnAvailableFundsAndUnsettledCash': 'Interest on Available funds+Unsettled cash',
    'reports.interest': 'Odsetki',
    'reports.InterestOnAvailableFunds': 'Interest on Available funds',
    'reports.InterestOnCash': 'Interest on Cash',
    'reports.InterestOnMarginAvailable': 'Interest on Margin available',
    'reports.InterestOnMarginAvailableAndUnsettledCash': 'Interest on Margin available and Unsettled cash',
    'reports.Invalid installer hash.': 'Invalid installer hash.',
    'reports.Invalid installer size.': 'Invalid installer size.',
    'reports.Invalid installer URL.': 'Błędny URL.',
    'reports.Invalid password:New password should have at least 1 upper case character, 1 lower case character, 1 number, 1 special character (e.g. @#$%) and contain 8 characters at least. Please try again.': 'New password should have at least 1 upper case character, 1 lower case character, 1 number, 1 special character (e.g. @#$%) and contain 8 characters at least. Please try again.',
    'reports.Invalid price for the order.': 'Invalid price for the order.',
    'reports.Investor Action History Report': 'Investor action history report',
    'reports.investorID': 'Investor ID',
    'reports.InvestorProtectionLevy': 'Investor protection levy',
    'reports.ip': 'IP',
    'reports.IP': 'IP',
    'reports.IQFeed': 'IQFeed',
    'reports.irdnumber': 'IR number',
    'reports.is out of range': 'jest poza zakresem',
    'reports.IsOnlyLastPeriod': 'Last period',
    'reports.isPriceStream': 'is price stream',
    'reports.isShowLots': 'Pokaż ilość w lotach',
    'reports.IsShowLots': 'Pokaż ilość w lotach',
    'reports.IsShowPamm': 'Show fund',
    'reports.issingle': 'Is single',
    'reports.JdbcDriver': 'Jdbc driver',
    'reports.JdbcPassword': 'Jdbc password',
    'reports.JdbcSessionIdDefaultPropertyValue': 'JDBC session ID default property value',
    'reports.JdbcURL': 'JdbcURL',
    'reports.JdbcUser': 'Jdbc user',
    'reports.JMS.INIT.QUEUE': 'JMS INITIAL QUEUE',
    'reports.JMS.SERVER.HOST': 'JMS SERVER HOST',
    'reports.JMS.SERVER.PASSWORD': 'JMS SERVER PASSWORD',
    'reports.JMS.SERVER.PORT': 'JMS SERVER PORT',
    'reports.JMS.SERVER.PROTOCOL': 'JMS SERVER PROTOCOL',
    'reports.JMS.SERVER.USER': 'JMS SERVER USER',
    'reports.JPMorganTrade': 'JPMorganTrade',
    'reports.keystoreAlias': 'Key store Alias',
    'reports.keystoreFile': 'Key store file',
    'reports.KyteFIX': 'KyteFIX',
    'reports.L1port': 'L1port',
    'reports.L2port': 'L2port',
    'reports.LADDER_VIEW': 'Super DOM',
    'reports.LADDER_VIEW_OE': 'Super DOM (build in OE)',
    'reports.Last fill price': 'Last fill price',
    'reports.lastname': 'Last name',
    'reports.Level1Charging': 'Level1Charging',
    'reports.Level1DataCharging': 'Level 1 data charging',
    'reports.Level2Charging': 'Level2Charging',
    'reports.Level2DataCharging': 'Level 2 data charging',
    'reports.Level3Charging': 'Level3Charging',
    'reports.Level3DataCharging': 'Level 3 data charging',
    'reports.Limit accepted by broker': 'Zlecenie Limit przyjęte przez brokera',
    'reports.Limit accepted by STP': 'Zlecenie Limit przyjęte przez STP',
    'reports.Limit order accepted': 'Zlecenie Limit zostało zaakceptowane',
    'reports.Limit order activated': 'Zlecenie Limit zostało aktywowane',
    'reports.Limit order cancel request': 'Dyspozycja anulowania zlecenia Limit',
    'reports.Limit order canceled': 'Zlecenie Limit zostało anulowane',
    'reports.Limit order created': 'Zlecenie Limit zostało utowrzone',
    'reports.Limit Order Created': 'Zlecenie Limit zostało utworzone',
    'reports.Limit order created is off market': 'Zlecenie Limit - off-market',
    'reports.Limit order created is unplaced': 'Zlecenie Limit nie zostało złożone na giełdzie',
    'reports.Limit order is awaiting market': 'Zlecenie Limit oczekuje na wykonanie',
    'reports.Limit Order Modified': 'Zlecenie Limit zostało zmodyfikowane',
    'reports.Limit order partially reject': 'Zlecenie Limit zostało częściowo anulowane',
    'reports.Limit order placing request': 'Dyspozycja złożenia zlecenia Limit',
    'reports.Limit order received by broker': 'Zlecenie Limit przyjęte przez brokera',
    'reports.Limit order rejected': 'Zlecenie Limit zostało odrzucone',
    'reports.Limit Order Removed': 'Zlecenie Limit zostało anulowane',
    'reports.Limit order replace request': 'Dyspozycja modyfikacji zlecenia Limit',
    'reports.Limit order replaced': 'Zlecenie Limit zostało zmodyfikowane',
    'reports.Limit order restated': 'Zlecenie Limit zostało ponownie złożone',
    'reports.Linked Users': 'Połączeni użytkownicy',
    'reports.LMAX': 'LMAX',
    'reports.LMAX FIX': 'LMAX FIX',
    'reports.Local': 'Local',
    'reports.locked': 'Locked',
    'reports.LogFileMaxSize': 'Log file maximum size',
    'reports.login': 'Rachunek',
    'reports.Login': 'Login',
    'reports.LOGIN.USER.NAMESPACE': 'LOGIN USER NAMESPACE',
    'reports.Login/password combination is not valid': 'Błędny Login lub hasło',
    'reports.LogonTimeout': 'Przekroczono limit czasu logowania',
    'reports.Logout': 'Wylogowano',
    'reports.Lots to open': 'Lots to open',
    'reports.lotsize': 'Wielkość lot',
    'reports.Mail Settings': 'Ustawienia email',
    'reports.mail.mailsettings': 'Ustawienia email',
    'reports.mailPattern.ChatDuplicating': 'Chat duplicating',
    'reports.mailPattern.DemoUserNotify': 'Demo-user expiration date notification',
    'reports.mailPattern.DemoUserRegistration': 'Demo-user registration confirmation',
    'reports.mailPattern.DemoUserRemove': 'Demo-user removing confirmation',
    'reports.mailPattern.DepositWithdrawalNotification': 'Deposit/Withdrawal email notification',
    'reports.mailPattern.MarginCall': 'Margin call notification',
    'reports.mailPattern.NewsCreated': 'News were created',
    'reports.mailPattern.OrderPlaced': 'The Stop/Limit order was placed',
    'reports.mailPattern.OrderRemoved': 'The Stop/Limit order was removed',
    'reports.mailPattern.PositionClosed': 'The confirmation of position closing',
    'reports.mailPattern.PositionOpened': 'The confirmation of position placement',
    'reports.mailPattern.SignalDuplicating': 'Signal duplicating',
    'reports.mailPattern.TransactionStatementNotifyBroker': 'Daily report for broker',
    'reports.mailPattern.TransactionStatementNotifyUser': 'Daily report for User',
    'reports.mailPattern.UserEmailConfirmation': 'Confirmation email',
    'reports.mailPattern.UserPasswordRecovery': 'User password recovering',
    'reports.mailPattern.UserPasswordReset': 'User password reset',
    'reports.mailPattern.UserRegistration': 'User registration confirmation',
    'reports.mailPattern.UserUnlocking': 'User unlocking after brute force',
    'reports.Main': 'Główne',
    'reports.MAM trades report': 'MAM trades report',
    'reports.MAM_OE': 'MAM (build in OE)',
    'reports.MamManagementFee': 'MAM management fee',
    'reports.MamPerfomanceFee': 'MamPerfomanceFee',
    'reports.MamPerformanceFee': 'MAM performance fee',
    'reports.Manual order cancel request': 'Dyspozycja anulowania zlecenia manualnego',
    'reports.Manual order placing request': 'Dyspozycja złożenia zlecenia manualnego',
    'reports.Manual order replace request': 'Dyspozycja modyfikacji zlecenia manualnego',
    'reports.ManufacturedDividends': 'Manufactured dividends',
    'reports.margin': 'Margin',
    'reports.Margin Call': 'Margin call',
    'reports.Margin Call Reached': 'Stop Out',
    'reports.Margin Call Warning.': 'Margin call',
    'reports.Margin used': 'Wykorzystany Margin',
    'reports.MARGIN_RULES_ACC': 'Margin rules(account)',
    'reports.MARGIN_RUSLES': 'Margin rules',
    'reports.Margincall': 'Margin call',
    'reports.MarginCallMessage.header.MarginCallReached': 'Stop Out',
    'reports.MarginCallMessage.header.MarginWarning': 'Margincall',
    'reports.MarginCallMessage.Message': 'Wpłać dodatkowe środki',
    'reports.Market accepted by broker': 'Zlecenie Market przyjęte przez brokera',
    'reports.Market accepted by STP': 'Zlecenie Market przyjęte przez STP',
    'reports.Market Close Order Removed': 'Usunięto zlecenie zamykające Market',
    'reports.Market data charging report': 'Market data charging report',
    'reports.Market order accepted': 'Zlecenie Market zostało przyjęte',
    'reports.Market order activated': 'Zlecenie Market zostało aktywowane',
    'reports.Market order cancel request': 'Dyspozycja anulowania zlecenia Market',
    'reports.Market order canceled': 'Zlecenie Market zostało anulowane',
    'reports.Market order created': 'Utworzono zlecenie Market',
    'reports.Market Order Created': 'Utworzono zlecenie Market',
    'reports.Market order created is off market': 'Zlecenie Market jest off market',
    'reports.Market order created is unplaced': 'Zlecenie Market nie zostało przyjęte przez giełdę',
    'reports.Market Order Modified': 'Zmodyfikowano zlecenie Market',
    'reports.Market order partially reject': 'Zlecenie Market Zostało częściowo anulowane',
    'reports.Market order placing request': 'Dyspozycja złożenia zlecenia Market',
    'reports.Market order received by broker': 'Zlecenie Market przyjęte przez brokera',
    'reports.Market order rejected': 'Zlecenie Market zostało odrzucone',
    'reports.Market Order Removed': 'Zlecenie Market zostało usunięte',
    'reports.Market order replace request': 'Dyspozycja modyfikcji zlecenia Market',
    'reports.Market order replaced': 'Zlecenie Market zostało zmodyfikowane',
    'reports.Market order restated': 'Zlecenie Market zostało złożone ponownie',
    'reports.Market price': 'Cena Market',
    'reports.MARKETDEPTH': 'Głębokość rynku',
    'reports.MarketFactory Feed': 'MarketFactory Feed',
    'reports.Markup': 'Markup',
    'reports.MATRIX': 'Matrix',
    'reports.MATRIX_OE': 'Matrix (okno złóż zlecenie)',
    'reports.MaxLogsBackups': 'Maximum logs backups',
    'reports.MBT': 'MBT',
    'reports.message': 'Message',
    'reports.Message': 'Message',
    'reports.messageid': 'Message ID',
    'reports.MFGlobal': 'MFGlobal',
    'reports.MillisecondsinTimeStamp': 'Milliseconds in time stamp',
    'reports.MillisecondsInTimeStamp': 'Milliseconds in time stamp',
    'reports.minchange': 'Min. change',
    'reports.MinFeeAdjEquity': 'Min Fee adj equity',
    'reports.Minimum Open Margin': 'Minimalny depozyt otwarcia',
    'reports.minutes': 'minuty',
    'reports.MLFIX': 'MLFIX',
    'reports.MOB_CANCEL_ALL_ORDERS': 'Usuń wszystkie zlecenia',
    'reports.MOB_CHART_OE': 'Wykres',
    'reports.MOB_CLOSE_ALL_POSITIONS': 'Zamknij wszystkie (Zamknij wszystkie pozycje)',
    'reports.MOB_MARKETDEPTH': 'Głębokość rynku',
    'reports.MOB_ORDERS_HOT_BTN': 'Zlecenia bieżące',
    'reports.MOB_OTHER': 'Inne',
    'reports.MOB_POSITIONS_HOT_BTN': 'Pozycje',
    'reports.MOB_TAB_CHART_VISUAL_HOT_BTN': 'Wykres',
    'reports.MOB_WL_OE': 'Lista instrumentów',
    'reports.Modified': 'Zmodyfikowano',
    'reports.Modifing': 'Zmodyfikowano',
    'reports.Modifyied by': 'Zmodyfikowano przez',
    'reports.Modifying': 'Zmodyfikowano',
    'reports.months': 'miesiące',
    'reports.Morgan Stanley': 'Morgan Stanley',
    'reports.MorganStanleyCFD': 'MorganStanleyCFD',
    'reports.Mutual close request': 'Zamknij łącznie',
    'reports.name': 'Nazwa',
    'reports.NAMESPACE': 'NAMESPACE',
    'reports.NEED_EMAIL_CONFIRM_ON_REGISTRATION': 'Account registration confirmation by e-mail',
    'reports.NeoNet': 'NeoNet',
    'reports.Net Open Positions': 'Otwarte pozycje netto',
    'reports.netpl': 'Net P/L',
    'reports.New Back Office Changes Report': 'New Backoffice changes report',
    'reports.New password must differ from several previous passwords.': 'Nowe hasło musi się różnic od poprzednich',
    'reports.New Users Report': 'New users report',
    'reports.news.feed.type': 'News feed type',
    'reports.news.reciving': 'News receiving',
    'reports.news.save': 'news save',
    'reports.news.sendEmail': 'news send e-mail',
    'reports.NewsCharging': 'News charging',
    'reports.NewsFeed': 'NewsFeed',
    'reports.newvalue': 'New value',
    'reports.no such position, no such order.': 'brak pozycji i zlecenia.',
    'reports.Nomura': 'Nomura',
    'reports.Nomura-Lehman': 'Nomura-Lehman',
    'reports.None': 'None',
    'reports.Not enoght margin to create Order': 'Brak środków do otwarcia zlecenia',
    'reports.Not enough margin to open order': 'Brak środków do otwarcia zlecenia',
    'reports.Not enough Margin to open position': 'Brak środków do otwarcia pozycji',
    'reports.Not enough offer': 'brak ofert',
    'reports.Not Filled Orders Report': 'Not filled orders report',
    'reports.Not Successful': 'Not successful',
    'reports.notShowMam': "Don't show MAM",
    'reports.NotSuccessful': 'Not successful',
    'reports.number': 'number',
    'reports.objectid': 'Object ID',
    'reports.ObjectId': 'Order ID',
    'reports.ObjectID': 'Object ID',
    'reports.OCO accepted by broker': 'Zlecenie OCO zaakceptowane przez brokera',
    'reports.OCO accepted by STP': 'Zlecenie OCO zaakceptowane przez STP',
    'reports.OCO order activated': 'Zlecenie OCO zostało aktywowane',
    'reports.OCO order cancel request': 'Dyspozycja anulowania zlecenia OCO',
    'reports.OCO order canceled': 'Zlecenie OCO zostało anulowane',
    'reports.OCO order created': 'Zlecenie OCO zostało utworzone',
    'reports.OCO Order Created': 'Zlecenie OCO zostało utworzone',
    'reports.OCO order created is off market': 'Zlecenie OCO - off market',
    'reports.OCO order created is unplaced': 'Zlecenie OCO nie zostało przyjęte przez giełdę',
    'reports.OCO order partially reject': 'Zlecenie OCO zostało częściowo odrzucone',
    'reports.OCO order placing request': 'Dyspozycja złożenia zlecenia OCO',
    'reports.OCO order received by broker': 'Zlecenie OCO otrzymane przez brokera',
    'reports.OCO order rejected': 'Zlecenie OCO zostało odrzucone',
    'reports.OCO order replace request': 'Dyspozycja modyfikacji zlecenia OCO',
    'reports.OCO order replaced': 'Zlecenie OCO zostało zmodyfikowane',
    'reports.OCO order restated': 'Zlecenie OCO zostało złożone ponownie',
    'reports.OE': 'Złóż zlecenie',
    'reports.Off market': 'Off market',
    'reports.ONE_MARGIN_FOR_USER_ACC': 'One margin for users account',
    'reports.Open': 'Otwórz',
    'reports.Open Buy': 'Kupno',
    'reports.Open date': 'Data otwarcia',
    'reports.Open lots': 'Otwarty wolumen',
    'reports.Open order is forbidden by trade session': 'Open order is forbidden by trade session',
    'reports.Open order type': 'Open order type',
    'reports.Open Positions By Signal Report': 'Open positions by signal report',
    'reports.Open Positions Report': 'Raport otwartych pozycji',
    'reports.Open price': 'Cena otwarcia',
    'reports.Open Sell': 'Sprzedaż',
    'reports.OPEN_SPOT_ON_OPTION_EXP': 'Open Spot position on option expire',
    'reports.openclosebuysell': 'Operation type',
    'reports.opengrosspnl': 'Otwarte Z/S brutto',
    'reports.OpenOptionPremium': 'Open option premium',
    'reports.openorderid': 'Numer zlecenia otwierającego',
    'reports.openprice': 'Cena otwarcia',
    'reports.opentime': 'Czas otwarcia',
    'reports.operation': 'Operacja',
    'reports.Operation': 'Operation',
    'reports.Operation Id': 'Operation ID',
    'reports.Operation type': 'Operation type',
    'reports.Operation Type': 'Operation type',
    'reports.operation.not_enough_margin': 'Brak środków',
    'reports.operation.wrong_length': 'Zbyt duża ilość',
    'reports.operation.wrong_length;': 'Too big quantity',
    'reports.operation.wrong_sum': 'Wrong sum',
    'reports.OperationID': 'Operation ID',
    'reports.OperationInDisconnectedMode': 'Operacja niemożliwa w trybie off-line!',
    'reports.operationtype': 'Operation type',
    'reports.OPTION_MASTER': 'Option master',
    'reports.option_value': 'Option value',
    'reports.OrcFIX': 'OrcFIX',
    'reports.order': 'Order',
    'reports.Order': 'Order',
    'reports.Order Book History': 'Order book history',
    'reports.Order Book Report': 'Order book report',
    'reports.Order cancel is forbidden by trade session': 'Order cancel is forbidden by trade session',
    'reports.Order cancel request': 'Dyspozycja anulowania zlecenia',
    'reports.Order canceled successfully': 'Anulowano zlecenie',
    'reports.Order cannot be created since order amount is forbidden.': 'Nieprawidłowa wielkość zlecenia.',
    'reports.Order cannot be created since route is not active.': 'Route nie jest aktywny',
    'reports.Order cannot be created, because position is in executing': 'Pozycja jest w trakcie wykonania',
    'reports.Order cannot be created, because route is not active': 'Route nie jest aktywny',
    "reports.Order cannot be created, because short positions are forbidden. You don't have enough rights.": 'Zlecenia sprzedaży są zabronione',
    'reports.Order cannot be created, because short positions are forbidden. You donґt have enough rights.': 'Zlecenia sprzedaży są zabronione',
    'reports.Order cannot be created, because short positions are forbidden.Or yor already have Limit/Stop orders for cover all your positions.': 'Zlecenia sprzedaży są zabronione',
    'reports.Order cannot be placed in non-trading day. Please, check the trading calendar.': 'Zlecenie nie może być utworzone - brak kwotowań dla instrumentu z powodu Święta',
    'reports.Order groupID': 'Order groupID',
    'reports.Order History Report': 'Historia zleceń',
    'reports.Order modification is forbidden by trade session': 'Nie można modyfikować zlecenia',
    'reports.Order number': 'Nr zlecenia',
    'reports.Order placing request': 'Dyspozycja złożenia zlecenia',
    'reports.Order price': 'Cena',
    'reports.Order Price': 'Cena',
    'reports.Order received by STP': 'Zlecenie otrzymane przez STP',
    'reports.Order replace request': 'Dyspozycja modyfikacji zlecenia',
    'reports.Order replacing confirmed': 'Zmodyfikowano zlecenie',
    'reports.Order TIF is forbidden by trade session': 'Błędna ważność zlecenia',
    'reports.Order type': 'Rodzaj zlecenia',
    'reports.Order type is forbidden by trade session': 'Zabroniony rodzaj zlecenia',
    'reports.Order was rejected due to invalid price!': 'Błędna cena zlecenia',
    'reports.order_book_account': 'Rachunek',
    'reports.order_book_datetime': 'Data',
    'reports.order_book_event': 'Zdarzenie',
    'reports.order_book_login': 'Login',
    'reports.order_book_modified_by': 'Modified by user',
    'reports.order_book_orderid': 'Nr zlecenia',
    'reports.order_book_price': 'Cena',
    'reports.order_book_quantity': 'Ilość',
    'reports.order_book_route': 'Route',
    'reports.order_book_side': 'Strona',
    'reports.order_book_stop_price': 'Cena Stop',
    'reports.order_book_symbol': 'Instrument',
    'reports.order_book_symbol_type': 'Typ instrumentu',
    'reports.order_book_tif': 'Ważność',
    'reports.order_book_type': 'Typ',
    'reports.orderCreate': 'Złóż zlecenie',
    'reports.orderExecute': 'Wykonanie zlecenia',
    'reports.OrderExternalConfirm': 'Order external confirm',
    'reports.orderid': 'Nr zlecenia',
    'reports.orderId': 'Nr zlecenia',
    'reports.OrderId': 'Nr zlecenia',
    'reports.orderModify': 'Modyfikuj zlecenie',
    'reports.orderMutural': 'order mutual',
    'reports.orderRemoved': 'Order removed',
    'reports.ORDERS_PANEL_HOT_BTN': 'Zlecenia bieżące',
    'reports.ordertype': 'Rodzaj zlecenia',
    'reports.Oreder Number': 'Nr zlecenia',
    'reports.originalvalue': 'Original value',
    'reports.OTHER': 'Inne',
    'reports.other_account_operations': 'Other account operations',
    'reports.Out of session trading is forbidden': 'Handel poza godzinami sesji jest zabroniony',
    'reports.Overnight trading is forbidden for account': 'Handel Overnight jest zabroniony',
    'reports.PAMM account can not be removed during investment period.': 'Fund cannot be removed during investment period',
    'reports.PAMM join': 'Fund join',
    'reports.pamm.OrderByPnL.max_loss': 'Biggest  loss',
    'reports.pamm.OrderByPnL.max_profit': 'Biggest  profit',
    'reports.pamm.OrderByPnL.min_loss': 'Smallest loss',
    'reports.pamm.OrderByPnL.min_profit': 'Smallest profit',
    'reports.pamm.OrderByPnL.none': 'None',
    'reports.pamm.OrderBySize.max': 'Biggest position',
    'reports.pamm.OrderBySize.min': 'Smallest position',
    'reports.pamm.OrderByTime.first': 'First open',
    'reports.pamm.OrderByTime.last': 'Last open',
    'reports.pammState.0': 'New',
    'reports.pammState.1': 'Investment',
    'reports.pammState.2': 'End of investment',
    'reports.pammState.3': 'Сlearing',
    'reports.pammState.4': 'Wait for investors',
    'reports.pammState.6': 'Start of investment',
    'reports.pammState.clearing': 'Сlearing',
    'reports.pammState.confirmed': 'confirmed',
    'reports.pammState.end_of_investment': 'End of investment',
    'reports.pammState.investment': 'Investment',
    'reports.pammState.Investment': 'Investment',
    'reports.pammState.new': 'New',
    'reports.pammState.pending': 'pending',
    'reports.pammState.start_of_investment': 'Start of investment',
    'reports.pammState.wait_for_investors': 'Wait for investors',
    'reports.PAMMTransfer': 'Fund transfer',
    'reports.Partially filled': 'Częściowo wykonane',
    'reports.Partially filled close Limit order': 'Zlecenie zamykające Limit zostało częściowo wykonane',
    'reports.Partially filled Close Limit Order': 'Zlecenie zamykające Limit zostało częściowo wykonane',
    'reports.Partially filled close Limit order (Mutual)': 'Zlecenie zamykające Limit zostało częściowo wykonane (łącznie)',
    'reports.Partially filled close Market order': 'Zlecenie zamykające Market zostało częściowo wykonane',
    'reports.Partially filled Close Market Order': 'Zlecenie zamykające Market zostało częściowo wykonane',
    'reports.Partially filled close Market order (Mutual)': 'Zlecenie zamykające Market zostało częściowo wykonane (łącznie)',
    'reports.Partially filled close OCO order': 'Zlecenie zamykające OCO zostało częściowo wykonane',
    'reports.Partially filled close OCO order (Mutual)': 'Zlecenie zamykające OCO zostało częściowo wykonane (łącznie)',
    'reports.Partially filled close SL order': 'Zlecenie SL zostało częściowo wykonane',
    'reports.Partially filled close SL order (Mutual)': 'Zlecenie SL zostało częściowo wykonane (łącznie)',
    'reports.Partially filled close SL tr. stop order': 'Zlecenie TR. Stop zostało częściowo wykonane',
    'reports.Partially filled close SL tr. stop order (Mutual)': 'Zlecenie TR. Stop zostało częściowo wykonane (łącznie)',
    'reports.Partially filled close Stop Limit order': 'Zlecenie zamykające Stop Limit zostało częściowo wykonane',
    'reports.Partially filled close Stop Limit order (Mutual)': 'Zlecenie zamykające Stop Limit zostało częściowo wykonane (łącznie)',
    'reports.Partially filled close Stop order': 'Zlecenie zamykające Stop zostało częściowo wykonane',
    'reports.Partially filled Close Stop Order': 'Zlecenie zamykające Stop zostało częściowo wykonane',
    'reports.Partially filled close Stop order (Mutual)': 'Zlecenie zamykające Stop zostało częściowo wykonane (łącznie)',
    'reports.Partially filled close TP order': 'Zlecenie TP zostało częściowo wykonane',
    'reports.Partially filled close TP order (Mutual)': 'Zlecenie TP zostało częściowo wykonane (łącznie)',
    'reports.Partially filled close Tr. stop order': 'Zlecenie TR. Stop zostało częściowo wykonane',
    'reports.Partially filled close Tr. stop order (Mutual)': 'Zlecenie TR. Stop zostało częściowo wykonane (łącznie)',
    'reports.Partially filled Limit order': 'Zlecenie Limit zostało częściowo wykonane',
    'reports.Partially filled Limit order (Mutual)': 'Zlecenie Limit zostało częściowo wykonane (łącznie)',
    'reports.Partially filled Market order': 'Zlecenie Market zostało częściowo wykonane',
    'reports.Partially filled Market Order': 'Zlecenie Market zostało częściowo wykonane',
    'reports.Partially filled Market order (Mutual)': 'Zlecenie Market zostało częściowo wykonane (łącznie)',
    'reports.Partially filled OCO order': 'Zlecenie OCO zostało częściowo wykonane',
    'reports.Partially filled OCO order (Mutual)': 'Zlecenie OCO zostało częściowo wykonane (łącznie)',
    'reports.Partially filled SL order': 'Zlecenie SL zostało częściowo wykonane',
    'reports.Partially filled SL order (Mutual)': 'Zlecenie SL zostało częściowo wykonane (łącznie)',
    'reports.Partially filled SL tr. stop order': 'Zlecenie TR. Stop zostało częściowo wykonane',
    'reports.Partially filled SL tr. stop order (Mutual)': 'Zlecenie TR. Stop zostało częściowo wykonane (łącznie)',
    'reports.Partially filled Stop Limit order': 'Zlecenie Stop Limit zostało częściowo wykonane',
    'reports.Partially filled Stop Limit order (Mutual)': 'Zlecenie Stop Limit zostało częściowo wykonane (łącznie)',
    'reports.Partially filled Stop order': 'Zlecenie Stop zostało częściowo wykonane',
    'reports.Partially filled Stop order (Mutual)': 'Zlecenie Stop zostało częściowo wykonane (łącznie)',
    'reports.Partially filled TP order': 'Zlecenie TP zostało częściowo wykonane',
    'reports.Partially filled TP order (Mutual)': 'Zlecenie TP zostało częściowo wykonane (łącznie)',
    'reports.Partially filled Tr. stop order': 'Zlecenie TR. Stop zostało częściowo wykonane',
    'reports.Partially filled Tr. stop order (Mutual)': 'Zlecenie TR. Stop zostało częściowo wykonane (łącznie)',
    'reports.Partially filled Traling Stop Order': 'Zlecenie TR. Stop zostało częściowo wykonane',
    'reports.Password required with level - 1': 'Błędne hasło - nowe hasło powinno zawierać co najmniej 1 wielką literę, 1 małą literę i 1 cyfrę. Spróbuj ponownie.',
    'reports.Password required with level - 2': 'Błędne hasło - nowe hasło powinno zawierać co najmniej 8 znaków, w tym: 1 wielką literę, 1 małą literę i 1 cyfrę. Spróbuj ponownie.',
    'reports.Password required with level - 3': 'Błędne hasło - nowe hasło powinno zawierać co najmniej 8 znaków, w tym: 1 wielką literę, 1 małą literę, 1 cyfrę i jeden znak specjalny. Spróbuj ponownie.',
    'reports.PatsystemsFIXFeed': 'PatsystemsFIXFeed',
    'reports.Pending': 'Oczekujące',
    'reports.pendingorderscount': 'Pending orders count',
    'reports.PeresysFIX': 'PeresysFIX',
    'reports.Perfomance By Signal Report': 'Performance by signal report',
    'reports.Perform': 'Perform',
    'reports.Performance history report': 'Performance history report',
    'reports.PFixFeed': 'PFixFeed',
    'reports.phone': 'Phone',
    'reports.phonepassword': 'Phone password',
    'reports.pin': 'Pin',
    'reports.pl_unrealized': 'Niezrealizowany Z/S',
    'reports.Placed': 'Placed',
    'reports.Please deposit on your account.': 'Please deposit your account',
    'reports.pnl': 'Z/S',
    'reports.port': 'port',
    'reports.posId': 'Nr pozycji',
    'reports.Position': 'Pozycja',
    'reports.Position close request': 'Dyspozycja zamknięcia pozycji',
    'reports.Position Closed': 'Zamknięcie pozycji',
    'reports.Position Closed (Mutual)': 'Zamknięcie pozycji (łącznie)',
    'reports.Position Closed (SL)': 'Zamknięcie pozycji (SL)',
    'reports.Position Closed (TP)': 'Zamknięcie pozycji (TP)',
    'reports.Position closed successfully': 'Zamknięcie pozycji',
    'reports.POSITION MODIFIED': 'Modyfikacja pozycji',
    'reports.Position modify request': 'Dyspozycja modyfikacji pozycji',
    'reports.Position number': 'Nr pozycji',
    'reports.positionOpened': 'Otwarte pozycje',
    'reports.Position Opened (Limit Order)': 'Position opened (Limit order)',
    'reports.Position Types Ratio By Instrument': 'Position types ratio by symbol',
    'reports.Position: {1} closed successfully': 'Pozycja: {1} została zamknięta',
    'reports.positionamount': 'Wielkość pozycji',
    'reports.positionid': 'Nr pozycji',
    'reports.positionId': 'Nr pozycji',
    'reports.PositionId': 'Nr pozycji',
    'reports.Positions History Report': 'Historia pozycji',
    'reports.Positions On Chosen Moment Report': 'Pozycje na dany moment',
    'reports.POSITIONS_PANEL_HOT_BTN': 'Pozycje',
    'reports.positionstatus': 'Status pozycji',
    'reports.price': 'Cena',
    'reports.Price': 'Cena',
    'reports.Price is too close to current Ask price': 'Cena jest za blisko aktualnej ceny Ask',
    'reports.Price is too close to current Bid price': 'Cena jest za blisko aktualnej ceny Bid',
    'reports.priceExchange': 'Price exchange',
    'reports.priceExternal': 'Price external',
    'reports.product type': 'Product type',
    'reports.productType': 'Product type',
    'reports.PROFILE_LANGUAGE': 'Język',
    'reports.profit': 'Profit',
    'reports.Projected balance': 'Projected balance',
    'reports.protocol': 'Protocol',
    'reports.ProTrader': 'Protrader',
    'reports.ProTraderFIX': 'ProTraderFIX',
    'reports.Purchase And Sales Report': 'Purchase and sales report',
    'reports.purchaseprice': 'Exposure',
    'reports.quantity': 'Quantity',
    'reports.QueueMaxSize': 'Queue maximum size',
    'reports.quicktable.noData': 'Brak danych do wyświetlenia',
    'reports.Quote is too old': 'Quote is too old',
    'reports.QuoteDelay(sec)': 'Quote delay (sec)',
    'reports.quoteticker': 'Quote ticker',
    'reports.raor_administrator': 'Broker',
    'reports.raor_approvetime': 'Approve time',
    'reports.raor_balance': 'From account balance',
    'reports.raor_basis': 'Comments',
    'reports.raor_commission_percent': 'Commission in percent',
    'reports.raor_counterbalance': 'To account balance',
    'reports.raor_countersum': 'To account sum',
    'reports.raor_crossprice': 'Cross price',
    'reports.raor_fromaccount': 'From account',
    'reports.raor_operationtime': 'Operation time',
    'reports.raor_operationtype': 'Operation type',
    'reports.raor_requestid': 'Operation ID',
    'reports.raor_state': 'Approval status',
    'reports.raor_sum': 'From account sum',
    'reports.raor_sum_without_commission': 'Sum without commission',
    'reports.raor_toaccount': 'To account',
    'reports.rate': 'rate',
    'reports.Rates Report': 'Rates report',
    'reports.Realised profit/loss': 'Realized Profit/Loss',
    'reports.Reason for rejection: Trading disabled by risk rules. .': 'Handel zabroniony',
    'reports.receiveBestBidOfferOnly': 'Receive best Bid offer only',
    'reports.recipient': 'Recipient',
    'reports.ReconnectInterval': 'Reconnect interval',
    'reports.refreshInterval': 'refresh interval',
    'reports.RefreshonLogon': 'Refresh on logon',
    'reports.RefreshOnLogon': 'Refresh on logon',
    'reports.Refused': 'Refused',
    'reports.Refused Amount': 'Quantity cancelled',
    'reports.Rejected': 'Rejected',
    'reports.remoteid': 'Remote ID',
    'reports.Remove SL for': 'Remove SL for',
    'reports.Removed Orders Report': 'Anulowane zlecenia',
    'reports.removedtime': 'Removed time',
    'reports.removeliquidityrebate': 'Remove liquidity rebate',
    'reports.Replace request received by STP': 'Replacement request received by STP',
    'reports.REPORT_RULES': 'Report visibility',
    'reports.report.currency': 'Currency',
    'reports.report.route': 'Route',
    'reports.reportAccountSummaryReport': 'Stan rachunku',
    'reports.reportAllActiveOrdersReport': 'Raport aktywnych zleceń',
    'reports.reportAllNotActiveOrdersReport': 'Filled orders report',
    'reports.reportAllRemovedOrdersReport': 'Anulowane zlecenia',
    'reports.reportBalanceByInstrument': 'Balance by symbol report',
    'reports.reportBalanceReport': 'Bilans',
    'reports.reportFormalDialogReport': 'Formal dialog report',
    'reports.reportInformalDialogReport': 'Chat history report',
    'reports.reportNewUsersReport': 'New users report',
    'reports.reportOpenedPositionReport': 'Opened positions report',
    'reports.reportPerfomanceReport': 'Raport zysków/strat',
    'reports.reportPurchaseAndSalesReport': 'Purchase and sales report',
    'reports.reportSummaryNewReport': 'Summary report',
    'reports.reportSummaryReport': 'Balance summary report',
    'reports.reportTradeOperationReport': 'Trade operation report',
    'reports.reportTradesReport': 'Transakcje',
    'reports.reportUsersReport': 'Users report',
    'reports.Request Account Operation Report': 'Request account operation report',
    'reports.Request is not allowed for this account': 'Request is not allowed for this account',
    'reports.Request not supported': 'Request not supported',
    'reports.RequestAccountOperationReport': 'Request account operation report',
    'reports.Required Balance': 'Required balance',
    'reports.REQUIRED_STRONG_PASSWORDS': 'Strong Password is required',
    'reports.ResetOnDisconnect': 'Reset on disconnect',
    'reports.ResetOnLogon': 'Reset on logon',
    'reports.ResetOnLogout': 'Reset on logout',
    'reports.Restated': 'Restated',
    'reports.Reuters': 'Reuters',
    'reports.Revenue plan': 'Revenue plan',
    'reports.REVENUE_PLAN_GENERAL': 'Ogólne',
    'reports.revenue.name': 'Revenue plan name',
    'reports.revenue.speccrossinstrument': 'Spec. cross symbol',
    'reports.revenue.speccurrency': 'Spec. currency',
    'reports.Reverse': 'Reverse',
    'reports.reward_type.capital_percent': 'Capital, %',
    'reports.reward_type.fixed': 'Fixed',
    'reports.reward_type.fixed_and_percent': 'Fixed + Inv. period growth, %',
    'reports.reward_type.no': 'No',
    'reports.reward_type.percent': 'Inv. period growth, %',
    'reports.role': 'Role',
    'reports.Rollback': 'Rollback',
    'reports.Rollback position': 'Rollback position',
    'reports.Rollback position request': 'Rollback position request',
    'reports.route': 'Route',
    'reports.Route': 'Route',
    'reports.Route Trading Statistics Report': 'Route Trading Statistics Report',
    'reports.Route Visibility': 'Route visibility report',
    'reports.Route Visibility Report': 'Route visibility report',
    'reports.RouteInstrMap': 'symbol map',
    'reports.routename': 'Route',
    'reports.routes': 'Routes',
    'reports.routes.account': 'Linked feed account',
    'reports.routes.allow_day': 'DAY',
    'reports.routes.allow_foc': 'FOK',
    'reports.routes.allow_gtc': 'GTC',
    'reports.routes.allow_ioc': 'IOC',
    'reports.routes.allow_market': 'Market',
    'reports.routes.allow_moc': 'Allow MOC',
    'reports.routes.allow_moo': 'Allow MOO',
    'reports.routes.allow_stop': 'Stop',
    'reports.routes.allow_stoplimit': 'Limit',
    'reports.routes.allow_stoptolimit': 'Stop limit',
    'reports.routes.route_comment': 'Comment',
    'reports.routes.route_external_feed': 'Liquidity provider',
    'reports.routes.route_isactive': 'Is Active',
    'reports.routes.route_isinfo': 'Is Info',
    'reports.routes.route_istrade': 'Is Trade',
    'reports.routes.route_login': 'Login',
    'reports.routes.route_name': 'Name',
    'reports.routes.route_password': 'Password',
    'reports.routes.route_saveQuotes': 'Save separate history',
    'reports.routes.route_status': 'Status',
    'reports.ROUTESVIS_RULES': 'Routes visibility',
    'reports.RssNews': 'RSSNews',
    'reports.RTS': 'RTS',
    'reports.rules.error.restriction.max': 'Wartość powinna być mniejsza',
    'reports.rules.error.restriction.min': 'Wartość powinna być większa',
    'reports.SAVED_ORDERS': 'Zapisane zlecenia',
    'reports.saveLogs': 'Zapisz logi',
    'reports.saveNews': 'Zapisz',
    'reports.Saxo Bank': 'Saxo Bank',
    'reports.SCALPER': 'Scalper',
    'reports.Scheduler Log Report': 'Scheduler log report',
    'reports.scheduler.accountoperation_archive': 'Archive account operations',
    'reports.scheduler.dailyreport': 'Send report',
    'reports.scheduler.dividentsservice': 'Run dividends on positions',
    'reports.scheduler.lastexcecuted': 'Executed at',
    'reports.scheduler.modifyRuleTask': 'Modifying task',
    'reports.scheduler.movepositiontask': 'Move position task',
    'reports.scheduler.openclosetradingfor': 'Open/Close trading',
    'reports.scheduler.opentradingfor': 'Open trading',
    'reports.scheduler.parameter.backup': 'Backup level',
    'reports.scheduler.parameter.calculateByClosePrice': 'Use current closing price',
    'reports.scheduler.parameter.calculateStrategy': 'Calculating type',
    'reports.scheduler.parameter.coeff1': 'Part 1',
    'reports.scheduler.parameter.coeff2': 'Part 2',
    'reports.scheduler.parameter.comment': 'Account operation comment',
    'reports.scheduler.parameter.counteraccount': 'Counter account',
    'reports.scheduler.parameter.days_before_exp': 'Days before expiration',
    'reports.scheduler.parameter.divident': 'Dividends quantity',
    'reports.scheduler.parameter.dividentmode': 'Dividends type',
    'reports.scheduler.parameter.email': 'E-mails (separated by comma)',
    'reports.scheduler.parameter.inheritUserGroups': 'Include sub groups',
    'reports.scheduler.parameter.instrtype': 'Grupa instrumentów',
    'reports.scheduler.parameter.instrument': 'Instrument',
    'reports.scheduler.parameter.isopen': 'Open/Close trading',
    'reports.scheduler.parameter.isupdateall': 'Update all instruments in group',
    'reports.scheduler.parameter.margincoeff': 'Margin coefficient (time based)',
    'reports.scheduler.parameter.max': 'Maximum (%)',
    'reports.scheduler.parameter.message': 'Message',
    'reports.scheduler.parameter.min': 'Minimum (%)',
    'reports.scheduler.parameter.operations': 'Operation type',
    'reports.scheduler.parameter.owner': 'Object',
    'reports.scheduler.parameter.ownerType': 'Object type',
    'reports.scheduler.parameter.putinaleerts': 'Put into Alerts',
    'reports.scheduler.parameter.reconciliation.ClientId': 'Client ID for reconciliation',
    'reports.scheduler.parameter.reconciliation.Direction': 'Direction',
    'reports.scheduler.parameter.reconciliation.FromDate': 'Start reconciliation from date',
    'reports.scheduler.parameter.reconciliation.Route': 'Route',
    'reports.scheduler.parameter.reconciliation.SendItemName': 'Sending file name',
    'reports.scheduler.parameter.reconciliation.Storage': 'Storage type',
    'reports.scheduler.parameter.reconciliation.Transport': 'Transport type',
    'reports.scheduler.parameter.reconciliation.transportDestination': 'Destination',
    'reports.scheduler.parameter.reconciliation.transportLogin': 'Authentication login',
    'reports.scheduler.parameter.reconciliation.transportPassword': 'Authentication password',
    'reports.scheduler.parameter.reconciliation.Type': 'Reconciliation type',
    'reports.scheduler.parameter.reports': 'Select report',
    'reports.scheduler.parameter.revenueplan': 'Select revenue plan',
    'reports.scheduler.parameter.routes': 'Each active route must be',
    'reports.scheduler.parameter.routesName': 'Include routes',
    'reports.scheduler.parameter.rule': 'Rule',
    'reports.scheduler.parameter.ruleType': 'Rule type',
    'reports.scheduler.parameter.ruleView': 'Value',
    'reports.scheduler.parameter.sum': 'Ilość w walucie systemu',
    'reports.scheduler.parameter.swapCoefKey': 'Swap coefficient',
    'reports.scheduler.parameter.userGroup': 'User group',
    'reports.scheduler.parameter.usergroups': 'Select users group',
    'reports.scheduler.removedo': 'Run day order removing service',
    'reports.scheduler.rolloverservice': 'Run futures Roll-Over',
    'reports.scheduler.runinterestservice': 'Run interest on balance service',
    'reports.scheduler.runsignalsubscribtion': 'Run daily charge commission for signal',
    'reports.scheduler.runswaptask': 'Run swap',
    'reports.scheduler.splitinstrument': 'Split symbol',
    'reports.scheduler.type.accountoperation': 'Operacje na rachunku',
    'reports.scheduler.type.backup': 'Create backup',
    'reports.scheduler.type.beginbalance': 'Begin balance',
    'reports.scheduler.type.broadcastmessage': 'Online broadcast message',
    'reports.scheduler.type.changemargincoeff': 'Margin coefficient (time based)',
    'reports.scheduler.type.changerevenue': 'Change revenue plan',
    'reports.scheduler.type.closeoptions': 'Ukryj wygasłe instrumenty',
    'reports.scheduler.type.deletedemousers': 'Delete expr. demo users',
    'reports.scheduler.type.deletequotes': 'Delete old quotes',
    'reports.scheduler.type.fixswapplan': 'Calculate fixed swap plan',
    'reports.scheduler.type.futuresrollover': 'Run futures roll-over',
    'reports.scheduler.type.insertlastquotestask': 'Insert last quotes',
    'reports.scheduler.type.lockbybroker': 'Lock by broker',
    'reports.scheduler.type.message': 'Message',
    'reports.scheduler.type.modifyobject': 'Modify object',
    'reports.scheduler.type.pamm': 'Fund task',
    'reports.scheduler.type.pendingpayment': 'Pending payment',
    'reports.scheduler.type.reconciliation': 'Reconciliation task',
    'reports.scheduler.type.reconnect_routes': 'Active routes start/stop',
    'reports.scheduler.type.subscribe': 'Get subscribe payments',
    'reports.scheduler.type.unknown': 'Unknown type',
    'reports.scheduler.type.updatespikefilter': 'Update spike filter',
    'reports.scheduler.type.updateuserstradinginfo': 'Update users trade statistic info',
    'reports.ScreenLogEvents': 'Screen log events',
    'reports.ScreenLogShowHeartBeats': 'Screen log show heartbeats',
    'reports.ScreenLogShowIncoming': 'Screen log show incoming',
    'reports.ScreenLogShowOutgoing': 'Screen log show outgoing',
    'reports.Script': 'Script',
    'reports.SDK': 'SDK',
    'reports.seconds': 'seconds',
    'reports.SecuritiesTransferTax': 'Securities transfer tax',
    'reports.Self trading is not allowed.': 'Self trading is not allowed.',
    'reports.Sell': 'Sell',
    'reports.sell trade amount': 'Sell trade amount',
    'reports.sell trades': 'Sell trades',
    'reports.Send from': 'Sent from',
    'reports.Send_Rej': 'Reject',
    'reports.sender': 'Sender',
    'reports.SenderCompID': 'Sender Computer ID',
    'reports.senderip': 'Sender IP',
    'reports.SendResetSeqNumFlag': 'Send reset sequent number flag',
    'reports.sendTag200': 'send Tag200',
    'reports.server': 'server',
    'reports.server_url': 'Server URL',
    'reports.SERVER.AUTO.RECONNECT': 'SERVER AUTO RECONNECT',
    'reports.SERVER.POLLING.INTERVAL': 'SERVER POLLING INTERVAL',
    'reports.serviceName': 'Service name',
    'reports.sessionid': 'Session ID',
    'reports.sessiontype': 'Session type',
    'reports.SETTINGS_FILTER': 'Filters',
    'reports.SETTINGS_GENERAL': 'Ogólne',
    'reports.SETTINGS_LOCKING': 'Locking',
    'reports.SETTINGS_QUOTE_CACHE_SIZE': 'Quote cache size setting',
    'reports.SETTINGS_QUOTE_SAVE': 'Quote save setting',
    'reports.SETTINGS_QUOTES': 'Quotes',
    'reports.SETTINGS_REGISTRATION': 'Registration',
    'reports.SETTINGS_TRADING': 'Trading',
    'reports.SettleDate': 'Expiration date',
    'reports.SettleDateForward': 'Value date',
    'reports.SettlementFee': 'Settlement fee',
    'reports.Sharia Operations Report': 'Sharia operations report',
    'reports.signedrequest': 'Signed request',
    'reports.sizeForQuoteSubscription': 'size for quote subscription',
    'reports.SL': 'Stop loss',
    'reports.SL accepted by broker': 'Zlecenie SL przyjęte przez brokera',
    'reports.SL accepted by STP': 'Zlecenie SL przyjęte przez STP',
    'reports.SL order accepted': 'Zlecenie SL zostało zaakceptowane',
    'reports.SL order cancel request': 'Dyspozycja anulowania zlecenia SL',
    'reports.SL order canceled': 'Zlecenie SL zostało anulowane',
    'reports.SL order created': 'Zlecenie SL zostało utworzone',
    'reports.SL Order Created': 'Zlecenie SL zostało utworzone',
    'reports.SL order created is off market': 'Zlecenie SL - off market',
    'reports.SL order created is unplaced': 'Zlecenie SL nie zostało przyjęte przez giełdę',
    'reports.SL Order Modified': 'Zlecenie SL zostało zmodyfikowane',
    'reports.SL order partially reject': 'Zlecenie SL zostało częściowo odrzucone',
    'reports.SL order placing request': 'Dyspozycja złożenia zlecenia SL',
    'reports.SL order received by broker': 'Zlecenie SL zostało przyjęte przez brokera',
    'reports.SL order rejected': 'Zlecenie SL zostało odrzucone',
    'reports.SL Order Removed': 'Zlecenie SL zostało usunięte',
    'reports.SL order replace request': 'Dyspozycja modyfikacji zlecenia SL',
    'reports.SL order replaced': 'Zlecenie SL zostało zmodyfikowane',
    'reports.SL price': 'Cena SL',
    'reports.SL tr. stop': 'Stop loss tr. stop',
    'reports.SL tr. stop accepted by broker': 'Zlecenie Tr. Stop przyjęte przez brokera',
    'reports.SL tr. stop accepted by STP': 'Zlecenie Tr. Stop przyjęte przez STP',
    'reports.SL tr. stop order accepted': 'Zlecenie Tr. Stop zostało zaakceptowane',
    'reports.SL tr. stop order canceled': 'Zlecenie Tr. Stop zostało anulowane',
    'reports.SL tr. stop order created': 'Zlecenie Tr. Stop zostało utworzone',
    'reports.SL tr. stop order created is off market': 'Zlecenie Tr. Stop - off market',
    'reports.SL tr. stop order created is unplaced': 'Zlecenie Tr. Stop nie zostało przyjęte przez giełdę',
    'reports.SL tr. stop order partially reject': 'Zlecenie Tr. Stop zostało częściowo odrzucone',
    'reports.SL tr. stop order received by broker': 'SL Tr. stop order received by broker',
    'reports.SL tr. stop order rejected': 'Zlecenie Tr. Stop zostało odrzucone',
    'reports.SL tr. stop order replaced': 'Zlecenie Tr. Stop zostało zmodyfikowane',
    'reports.SmartRoute(aggregator)': 'Splitting emulator',
    'reports.Sms generation error': 'Błąd generatora sms',
    'reports.SMTP server required a secure connection, or identity of the client has not been established.': 'Błąd bezpiecznego połączenia.',
    'reports.SOAP API methods are not available for execution for this user': 'Metody SOAP API nie są dostępne',
    'reports.SocketConnectHost': 'Socket connectHost',
    'reports.SocketConnectPort': 'Socket connects port',
    'reports.SocketKeyStore': 'Socket key store',
    'reports.SocketKeyStorePassword': 'Socket key store password',
    'reports.SocketUseSSL': 'Socket use SSL',
    'reports.SplitOrders': 'Split orders',
    'reports.spread': 'Spread',
    'reports.Spread Amount Report': 'Spread amount report',
    'reports.spreadpnl': 'Spread Z/S',
    'reports.Start receiving data from server...': 'Pobierz dane z serwera...',
    'reports.StartDay': 'Początkowy dzień',
    'reports.StartTime': 'Początkowy czas',
    'reports.STATIONS_RULES': 'Stations',
    'reports.Statistic Report': 'Statistic report',
    'reports.STATUS_BLOCKED': 'Blocked',
    'reports.stock_req': 'Stock orders req',
    'reports.stock_value': 'Stock value',
    'reports.StockOperation': 'Stock operation',
    'reports.stocks_liquidity': 'Stocks liquidity ',
    'reports.Stop accepted by broker': 'Zlecenie Stop przyjęte przez brokera',
    'reports.Stop accepted by STP': 'Zlecenie Stop przyjęte przez STP',
    'reports.Stop Limit accepted by broker': 'Zlecenie Stop Limit zostało zaakceptowane przez brokera',
    'reports.Stop Limit accepted by STP': 'Zlecenie Stop Limit zostało zaakceptowane przez STP',
    'reports.Stop Limit order accepted': 'Zlecenie Stop Limit zostało zaakceptowane',
    'reports.Stop Limit order activated': 'Zlecenie Stop Limit zostało aktywowane',
    'reports.Stop limit order cancel request': 'Dyspozycja anulowania zlecenia Stop Limit',
    'reports.Stop limit order canceled': 'Zlecenie Stop Limit zostało anulowane',
    'reports.Stop Limit order canceled': 'Zlecenie Stop Limit zostało anulowane',
    'reports.Stop Limit order created': 'Zlecenie Stop Limit zostało utworzone',
    'reports.Stop Limit Order Created': 'Zlecenie Stop Limit zostało utworzone',
    'reports.Stop Limit order created is off market': 'Zlecenie Stop Limit - off market',
    'reports.Stop Limit order created is unplaced': 'Zlecenie Stop Limit nie zostało przyjęte przez giełdę',
    'reports.Stop limit order is awaiting market': 'Zlecenie Stop Limit oczekuje na wykonanie',
    'reports.Stop Limit Order Modified': 'Zlecenie Stop Limit zostało zmodyfikowane',
    'reports.Stop Limit order partially reject': 'Zlecenie Stop Limit zostało częściowo odrzucone',
    'reports.Stop limit order placing request': 'Dyspozycja złożenia zlecenia Stop Limit',
    'reports.Stop Limit order received by broker': 'Zlecenie Stop Limit przyjęte przez brokera',
    'reports.Stop Limit order rejected': 'Zlecenie Stop Limit zostało odrzucone',
    'reports.Stop Limit Order Removed': 'Zlecenie Stop Limit zostało usunięte',
    'reports.Stop limit order replace request': 'Stop limit order replace request',
    'reports.Stop limit order replaced': 'Zlecenie Stop Limit zostało zmodyfikowane',
    'reports.Stop Limit order replaced': 'Zlecenie Stop Limit zostało zmodyfikowane',
    'reports.Stop Limit order restated': 'Zlecenie Stop Limit zostało zmodyfikowane',
    'reports.Stop loss order canceled': 'Zlecenie Stop Loss zostało anulowane',
    'reports.Stop Loss price is too big': 'Błędna cena dla zlecenia SL',
    'reports.Stop order accepted': 'Zlecenie Stop zostało zaakceptowane',
    'reports.Stop order activated': 'Zlecenie Stop Limit zostało aktywowane',
    'reports.Stop order cancel request': 'Dyspozycja anulowania zlecenia Stop Limit',
    'reports.Stop order canceled': 'Zlecenie Stop zostało anulowane',
    'reports.Stop order created': 'Zlecenie Stop zostało utworzone',
    'reports.Stop Order Created': 'Zlecenie Stop zostało utworzone',
    'reports.Stop order created is off market': 'Zlecenie Stop - off market',
    'reports.Stop order created is unplaced': 'Zlecenie Stop nie zostało przyjęte przez giełdę',
    'reports.Stop order is awaiting market': 'Zlecenie Stop oczekuje na wykonanie',
    'reports.Stop Order Modified': 'Stop order modified',
    'reports.Stop order partially reject': 'Zlecenie Stop zostało częściowo odrzucone',
    'reports.Stop order placing request': 'Dyspozycja złożenia zlecenia Stop',
    'reports.Stop order received by broker': 'Zlecenie Stop przyjęte przez brokera',
    'reports.Stop order rejected': 'Zlecenie Stop zostało odrzucone',
    'reports.Stop Order Removed': 'Zlecenie Stop zostało usunięte',
    'reports.Stop order replace request': 'Dyspozycja modyfikacji zlecenia Stop',
    'reports.Stop order replaced': 'Zlecenie Stop zostało zmodyfikowane',
    'reports.Stop order restated': 'Zlecenie Stop zostało zmodyfikowane',
    'reports.Stop price': 'Cena stop',
    'reports.stoploss': 'SL',
    'reports.StopOutType': 'Stop out type',
    'reports.stopprice': 'Cena stop',
    'reports.STP_Route_Change': 'Set original route name in order',
    'reports.Strike Price': 'Strike price',
    'reports.submitted': 'Pobrano',
    'reports.Subscribe': 'Subscribe',
    'reports.SUBSCRIBE_RULES': 'Subscribe plans',
    'reports.success': 'Sukces',
    'reports.Successful': 'Pomyślnie',
    'reports.sum': 'Suma',
    'reports.Sum': 'Suma',
    'reports.Summary Report': 'Summary report',
    'reports.SunGard': 'SunGard',
    'reports.SUSPEND_MARGINCALL_ON_CLOSE_MARKET': 'Suspend margin call assistant for closed markets',
    'reports.swap': 'Swap',
    'reports.swapbuy': 'Swap kupno',
    'reports.swapplan': 'Swap plan',
    'reports.swaps': 'Swaps',
    'reports.swaps_in': 'Swap in',
    'reports.swaps_out': 'Swaps out',
    'reports.swapsell': 'Swap sprzedaż',
    'reports.symbol': 'Instrument',
    'reports.Symbol': 'Instrument',
    'reports.symbol_type': 'Typ instrumentu',
    'reports.System': 'System',
    'reports.system_currency': 'Waluta systemu',
    'reports.systemMonitor.instruments': 'Instrumenty',
    'reports.systemMonitor.onlineConnections': 'Połączenia',
    'reports.systemMonitor.positions': 'Otwarte pozycje',
    'reports.systemMonitor.regusers': 'Registered users',
    'reports.systemMonitor.runningRoutes': 'Started routes from active routes',
    'reports.systemMonitor.totalQueueSize': 'Queues in services',
    'reports.TABLE_ALERT': 'Alarm',
    'reports.tablename': 'Nazwa tabeli',
    'reports.TableName': 'Nazwa tabeli',
    'reports.Take profit order canceled': 'Zlecenie Take Profit zostało anulowane',
    'reports.Take Profit price is too big': 'Cena Take Profit jest zbyt wysoka',
    'reports.takeprofit': 'TP',
    'reports.TargetCompID': 'Target computer ID',
    'reports.task.comment': 'Komentarz',
    'reports.task.day': 'Dzień',
    'reports.task.hour': 'Godzina',
    'reports.task.minute': 'Minuta',
    'reports.task.month': 'Miesiąc',
    'reports.task.timezone': 'Time zone offset',
    'reports.task.usedst': 'Use DST',
    'reports.task.year': 'Rok',
    'reports.TempFilePath': 'Temporary file path',
    'reports.Tenfore': 'Tenfore',
    'reports.terminal': 'Terminal',
    'reports.Terminal': 'Terminal',
    'reports.TERMINAL_API': 'API',
    'reports.TERMINAL_COMMUNITY': 'Community',
    'reports.TERMINAL_FIX': 'FIX Connection',
    'reports.TERMINAL_HTML': 'HTML',
    'reports.TERMINAL_JAVA': 'Java',
    'reports.TERMINAL_MOBILE': 'Mobile',
    'reports.TERMINAL_NET': '.Net',
    'reports.TERMINAL_POCKETPC': 'Pocket PC',
    'reports.TERMINAL_WEB': 'WebStation',
    'reports.terminaltype': 'Terminal type',
    'reports.TFDS Quote Receiver': 'TFDS quote receiver',
    'reports.tif': 'Ważność',
    'reports.TIF': 'Ważność',
    'reports.time': 'Czas',
    'reports.Time': 'Data/Czas',
    'reports.to': 'Do',
    'reports.toDate': 'Do',
    'reports.toDateConnAttempt': 'Do',
    'reports.toDatePwdChange': 'Do',
    'reports.tooLargeData': "Report's data is too large! Shows {1} of {2} rows",
    'reports.total': 'Suma',
    'reports.total traded amount': 'Suma',
    'reports.totalfee': 'Łączna prowizja',
    'reports.TP': 'Take profit',
    'reports.TP accepted by broker': 'Zlecenie Take Profit przyjęte przez brokera',
    'reports.TP accepted by STP': 'Zlecenie Take Profit przyjęte przez STP',
    'reports.TP order accepted': 'Zlecenie Take Profit zostało zaakceptowane',
    'reports.TP order cancel request': 'Dyspozycja anulowania zlecenia Take Profit',
    'reports.TP order canceled': 'Zlecenie Take Profit zostało anulowane',
    'reports.TP order created': 'Zlecenie Take Profit zostało utworzone',
    'reports.TP Order Created': 'Zlecenie Take Profit zostało utworzone',
    'reports.TP order created is off market': 'Zlecenie Take Profit - off market',
    'reports.TP order created is unplaced': 'Zlecenie Take Profit nie zostało przyjęte przez giełdę',
    'reports.TP Order Modified': 'Zlecenie Take Profit zostało zmodyfikowane',
    'reports.TP order partially reject': 'Zlecenie Take Profit zostało częściowo odrzucone',
    'reports.TP order placing request': 'Dyspozycja złożenia zlecenia Take Profit',
    'reports.TP order received by broker': 'Zlecenie Take Profit przyjęte przez brokera',
    'reports.TP order rejected': 'Zlecenie Take Profit zostało odrzucone',
    'reports.TP Order Removed': 'Zlecenie Take Profit zostało usunięte',
    'reports.TP order replace request': 'Dyspozycja modyfikacji zlecenia Take Profit',
    'reports.TP order replaced': 'Zlecenie Take Profit zostało zmodyfikowane',
    'reports.Tr stop loss order cancel request': 'Dyspozycja anulowania zlecenia Tr. Stop',
    'reports.Tr stop loss order placing request': 'Dyspozycja złożenia zlecenia Tr. Stop',
    'reports.Tr stop loss order replace request': 'Dyspozycja modyfikacji zlecenia Tr. Stop',
    'reports.Tr. stop accepted by broker': 'Zlecenie Tr. Stop przyjęte przez brokera',
    'reports.Tr. stop accepted by STP': 'Zlecenie Tr. Stop przyjęte przez STP',
    'reports.Tr. stop is too big': 'Błędny dystans Tr. Stop',
    'reports.Tr. stop loss order accepted': 'Zlecenie Tr. Stop zostało zaakceptowane',
    'reports.Tr. stop loss order canceled': 'Zlecenie Tr. Stop zostało anulowane',
    'reports.Tr. stop loss order created': 'Zlecenie Tr. Stop zostało utworzone',
    'reports.Tr. stop loss order created is off market': 'Zlecenie Tr. Stop - off market',
    'reports.Tr. stop loss order created is unplaced': 'Zlecenie Tr. Stop nie zostało zaakceptowane przez giełdę',
    'reports.Tr. stop loss order replaced': 'Zlecenie Tr. Stop zostało zmodyfikowane',
    'reports.Tr. stop order accepted': 'Zlecenie Tr. Stop zostało zaakceptowane',
    'reports.Tr. stop order activated': 'Zlecenie Tr. Stop zostało aktywowane',
    'reports.Tr. stop order canceled': 'Zlecenie Tr. Stop zostało anulowane',
    'reports.Tr. stop order created': 'Zlecenie Tr. Stop zostało utworzone',
    'reports.Tr. stop order created is off market': 'Zlecenie Tr. Stop - off market',
    'reports.Tr. stop order created is unplaced': 'Zlecenie Tr. Stop nie zostało zaakceptowane przez giełdę',
    'reports.Tr. stop order is awaiting market': 'Zlecenie Tr. Stop oczekuje na wykonanie',
    'reports.Tr. stop order partially reject': 'Zlecenie Tr. Stop zostało częściowo anulowane',
    'reports.Tr. stop order received by broker': 'Zlecenie Tr. Stop przyjęte przez brokera',
    'reports.Tr. stop order placing request': 'Dyspozycja złożenia zlecenia Tr. Stop',
    'reports.Tr. stop order cancel request': 'Dyspozycja anulowania zlecenia Tr. Stop',
    'reports.Tr. stop order replace request': 'Dyspozycja modyfikacji zlecenia Tr. Stop',
    'reports.Tr. stop order rejected': 'Zlecenie Tr. Stop zostało odrzucone',
    'reports.Tr. stop order replaced': 'Zlecenie Tr. Stop zostało zmodyfikowane',
    'reports.Tr. stop order restated': 'Zlecenie Tr. Stop zostało zmodyfikowane',
    'reports.Tr.SL Order Removed': 'Zlecenie Tr. Stop zostało anulowane',
    'reports.Trade executed': 'Transakcja została wykonana',
    'reports.Trade executed (Mutual)': 'Wykonano transakcje (łącznie)',
    'reports.Trade executed (SL)': 'Transakcja została wykonana (SL)',
    'reports.Trade Operations Report': 'Trade operations report',
    'reports.Trade Periods By Signal Report': 'Trade periods by signal report',
    'reports.trade.BeginString': 'Trade begin string',
    'reports.trade.CheckCompID': 'Trade check Computer ID',
    'reports.trade.CheckLatency': 'Trade check latency',
    'reports.trade.ClosedResendInterval': 'Trade closed resend interval',
    'reports.trade.ConnectionType': 'Trade connection type',
    'reports.trade.ContinueInitializationOnError': 'Trade continue initialization on error',
    'reports.trade.DataDictionary': 'Trade data dictionary',
    'reports.trade.EndDay': 'Trade end day',
    'reports.trade.EndTime': 'Trade end time',
    'reports.trade.entityId': 'Trade entity ID',
    'reports.trade.FileIncludeMilliseconds': 'Trading file includes milliseconds',
    'reports.trade.FileIncludeTimeStampForMessages': 'Trade file includes time stamp for messages',
    'reports.trade.FileLogHeartbeats': 'Trade file log heartbeats',
    'reports.trade.fxintegrate.cert.host.verify': 'Trade fx integrate cert host verify',
    'reports.trade.fxintegrate.cert.root.ca.file': 'Trade fx integrate cert root ca file',
    'reports.trade.fxintegrate.host': 'Trade fxintegrate host',
    'reports.trade.fxintegrate.logging.file': 'Trade fx integrate logging file',
    'reports.trade.fxintegrate.logging.maxfilecount': 'Trade fx integrate logging maximum file count',
    'reports.trade.fxintegrate.logging.output': 'Trade fx integrate logging output',
    'reports.trade.fxintegrate.logging.priority': 'Trade fxintegrate logging priority',
    'reports.trade.fxintegrate.port': 'Trade fx integrate port',
    'reports.trade.fxintegrate.protocol': 'Trade fx integrate protocol',
    'reports.trade.fxintegrate.usessl': 'Trade fx integrate use ssl',
    'reports.trade.HeartBtInt': 'Trade heartbeat Int',
    'reports.trade.JdbcDriver': 'Trade Jdbc driver',
    'reports.trade.JdbcPassword': 'Trade Jdbc password',
    'reports.trade.JdbcURL': 'Trade Jdbc URL',
    'reports.trade.JdbcUser': 'trade Jdbc user',
    'reports.trade.keystoreAlias': 'Trade key store Alias',
    'reports.trade.keystoreFile': 'Trade key store file',
    'reports.trade.LogFileMaxSize': 'Trade log file maximum size',
    'reports.trade.login': 'Trade login',
    'reports.trade.MaxLogsBackups': 'Trading maximum logs backups',
    'reports.trade.MillisecondsinTimeStamp': 'Milliseconds in time stamp',
    'reports.trade.MillisecondsInTimeStamp': 'Trade milliseconds in time stamp',
    'reports.trade.password': 'Trade password',
    'reports.trade.ReconnectInterval': 'Trade reconnect interval',
    'reports.trade.RefreshonLogon': 'Refresh on logon',
    'reports.trade.RefreshOnLogon': 'Trade refresh on logon',
    'reports.trade.ResetOnDisconnect': 'Trade reset on disconnect',
    'reports.trade.ResetOnLogon': 'Trade reset on logon',
    'reports.trade.ResetOnLogout': 'trade reset on logout',
    'reports.trade.saveLogs': 'Trade save logs',
    'reports.trade.ScreenLogEvents': 'Trade screen log events',
    'reports.trade.ScreenLogShowHeartBeats': 'Trade screen log show heartbeats',
    'reports.trade.ScreenLogShowIncoming': 'Trade screen log shows incoming',
    'reports.trade.ScreenLogShowOutgoing': 'Trade screen log show outgoing',
    'reports.trade.SenderCompID': 'Trade sender comp ID',
    'reports.trade.SendResetSeqNumFlag': 'Trade send reset sequent number flag',
    'reports.trade.SocketConnectHost': 'Trade socket  connect host',
    'reports.trade.SocketConnectPort': 'Trade socket connect port',
    'reports.trade.SocketKeyStore': 'Trade socket key store',
    'reports.trade.SocketKeyStorePassword': 'Trade socket key store password',
    'reports.trade.SocketUseSSL': 'Trade socket use SSL',
    'reports.trade.StartDay': 'Trading start day',
    'reports.trade.StartTime': 'Trading start time',
    'reports.trade.TargetCompID': 'Trade target Comp ID',
    'reports.trade.UseDataDictionary': 'Trade use data dictionary',
    'reports.trade.ValidateFieldsHaveValues': 'Trade validate fields have values',
    'reports.trade.ValidateFieldsOutOfOrder': 'Trade validate fields out of order',
    'reports.trade.ValidateUserDefinedFields': 'Trade validate user defined fields',
    'reports.tradeamount': 'Trade amount',
    'reports.tradeid': 'Trade ID',
    'reports.tradePassword': 'Trade password',
    'reports.trader': 'Trader',
    'reports.Trades by Signal': 'Trades by signal',
    'reports.Trades Report': 'Transakcje',
    'reports.Trades report by instrument': 'Transakcje po instrumencie',
    'reports.trades_bought': 'Trades bought',
    'reports.trades_sold': 'Trades sold',
    'reports.tradevolume': 'Trade volume',
    'reports.trading': 'Handel',
    'reports.Trading': 'Handel',
    'reports.Trading disabled by risk rules': 'Trading disabled by risk rules',
    'reports.TRADING_IDEA_CARD': 'Trading idea card',
    'reports.trading_in': 'Trading in',
    'reports.trading_out': 'Trading out',
    'reports.TRADING_RULES_ACC': 'Trading rules(account)',
    'reports.TRADING_RUSLES': 'Trading rules',
    'reports.TRADINGSYSTEMLIST': 'Strategy manager',
    'reports.Traiding out of Main session is forbidden for this account': 'Handel poza godzinami głównej sesji handlowej jest zabroniony',
    'reports.Trailing stop order cancel request': 'Dyspozycja anulowania zlecenia Tr. Stop',
    'reports.Trailing Stop Order Created': 'Zlecenie Trailing Stop zostało utworzone',
    'reports.Trailing Stop Order Modified': 'Zlecenie Trailing Stop zostało zmodyfikowane',
    'reports.Trailing stop order placing request': 'Dyspozycja złożenia zlecenia Tr. Stop',
    'reports.Trailing Stop Order Removed': 'Zlecenie Trailing Stop zostało usunięte',
    'reports.Trailing stop order replace request': 'Dyspozycja modyfikacji zlecenia Tr. Stop',
    'reports.TrailingStop': 'Trailing stop',
    'reports.Transfer': 'Transfer',
    'reports.TransferFee': 'Transfer Fee',
    'reports.True': 'Prawda',
    'reports.type': 'Typ',
    'reports.Type': 'Typ',
    'reports.Types': 'Typy',
    'reports.UBSFIXFeed': 'UBSFIXFeed',
    'reports.Unable to cancel the order since route is not active.': 'Zlecenie nie zostało anulowane - route jest nieaktywny.',
    'reports.Unblocking': 'Unblocking',
    'reports.Unplaced': 'Unplaced',
    'reports.unrealized p/l': 'Niezrealizowany Z/S',
    'reports.unrealizedpnl': 'Niezrealizowany Z/S',
    'reports.unsettled_cash': 'Unsettled cash',
    'reports.updatedtime': 'Czas aktualizacji',
    'reports.Updater': 'Aktualizacja',
    'reports.URL': 'URL',
    'reports.usage': 'Wykorzystanie',
    'reports.USE_OPEN_PRICE_IN_MARGIN': 'Use open price',
    'reports.UseDataDictionary': 'Use data dictionary',
    'reports.usePrevQuotedOrders': 'use prev. quoted orders',
    'reports.UsePriority': 'Use priority',
    'reports.user': 'Login',
    'reports.User': 'Login',
    'reports.User Authorization Error': 'Błąd autoryzacji',
    'reports.user group': 'User group',
    'reports.User Previledges Report': 'User privileges report',
    'reports.user.acc_4_signals': 'Account for signals',
    'reports.user.address': 'Address',
    'reports.user.amount': 'Amount',
    'reports.user.amountType': 'Amount type',
    'reports.user.birthday': 'Date of birth',
    'reports.user.certificate': 'Users certificate',
    'reports.user.chatOnEmail': 'Send chat on e-mail',
    'reports.user.city': 'City',
    'reports.user.comment': 'Komentarz',
    'reports.user.commission_type': 'Commission by',
    'reports.user.email': 'Email',
    'reports.user.extaccount': 'External account',
    'reports.user.firstname': 'First name',
    'reports.user.gender': 'Gender',
    'reports.user.group': 'Group',
    'reports.user.how_handle': 'How handle signals',
    'reports.user.introducebroker': 'Introducing broker account',
    'reports.user.language': 'Język',
    'reports.user.lastname': 'Last name',
    'reports.user.linkedUsers': 'Linked users',
    'reports.user.login': 'Login',
    'reports.user.middlename': 'Middle name',
    'reports.user.password': 'Nowe hasło',
    'reports.user.phonenumber': 'Phone number',
    'reports.user.phonepassword': 'Phone password',
    'reports.user.photo': 'Photo',
    'reports.user.pin': 'Pin code',
    'reports.user.showInSearch': 'Show in search',
    'reports.user.showMyContacts': 'Show my contacts',
    'reports.user.signalsOnEmail': 'Signals on e-mail',
    'reports.user.state': 'State',
    'reports.user.status': 'Status',
    'reports.user.subscribeplan': 'Subscribe plan',
    'reports.user.website': 'Web-site',
    'reports.user.zipcode': 'ZIP code',
    'reports.usergroup.group': 'Super group',
    'reports.usergroup.name': 'User group',
    'reports.USERGROUPSVIS_RULES': 'User groups visibility',
    'reports.userlogin': 'User login',
    'reports.UserLogin': 'User login',
    'reports.username': 'User',
    'reports.Users Report': 'Users report',
    'reports.Users Report(copy)': 'Users report(copy)',
    'reports.users_num': 'Users num',
    'reports.useSSL': 'use SSL',
    'reports.ValidateFieldsHaveValues': 'Validate fields have values',
    'reports.ValidateFieldsOutOfOrder': 'Validate fields out Of order',
    'reports.ValidateUserDefinedFields': 'Validate user defined fields',
    'reports.value': 'Value',
    'reports.VALUE_ADDRESS': 'Address',
    'reports.VALUE_BASE_CURRENCY': 'Base symbol part',
    'reports.VALUE_BRUTEFORCE_LOCK_MINUTS': 'Value brute force lock minutes',
    'reports.VALUE_CITY': 'City',
    'reports.VALUE_COMMISSION_ACCOUNT': 'Commission counter account',
    'reports.VALUE_COMMISSION_FOR_TRANSFER': 'Commission for transfer',
    'reports.VALUE_COMMISSION_ID': 'Revenue plan',
    'reports.VALUE_COUNTER_CURRENCY': 'Counter symbol part',
    'reports.VALUE_COUNTRY': 'Country',
    'reports.VALUE_DAY_BEGIN': 'Begin day',
    'reports.VALUE_DAY_END': 'End day',
    'reports.VALUE_DAYS_TILL_EXP': 'Days till expiration',
    'reports.VALUE_DEFAULT_BROKER': 'Default Broker',
    'reports.VALUE_DEFAULT_LANG': 'Język domyślny',
    'reports.VALUE_DERIVATIVE_TYPE': 'Derivative type',
    'reports.VALUE_HEDGE_COEFFICIENT': 'Hedge coefficient',
    'reports.VALUE_L2QUOTEGROUP_DEPTH': 'Level 2 group depth',
    'reports.VALUE_L2QUOTEGROUP_INTERVAL': 'Level 2 group interval',
    'reports.VALUE_LEVERAGE': 'Dźwignia',
    'reports.VALUE_LOT_SIZE': 'Wielkość lot',
    'reports.VALUE_LOTSTEP': 'Krok notowań',
    'reports.VALUE_MARGIN_COEFFICIENT': 'Margin coefficient(time based)',
    'reports.VALUE_MARGINCALL': 'Margin call, %',
    'reports.VALUE_MARGINTRADING': 'Stop trading level, %',
    'reports.VALUE_MARKET_RANGE': 'Market range, ticks',
    'reports.VALUE_MAX_DELTA_QUOTES': 'Maximum allowable price difference between two subsequent quotes (ticks)',
    'reports.VALUE_MAX_DELTA_TRADES': 'Maximum allowable price difference between two subsequent trades',
    'reports.VALUE_MAXIMUM_CACHE_SIZE': 'Maximum cache size',
    'reports.VALUE_MAXIMUM_FILTER': 'Max spike value',
    'reports.VALUE_MAXIMUMLOT': 'Max lot',
    'reports.VALUE_MIN_OPTION_INTERVAL': 'Minimum allowable time difference between two options quotes',
    'reports.VALUE_MIN_PRICE_DIFF_FOR_MARGIN': 'Minimal price diff for margin',
    'reports.VALUE_MINIMALLOT': 'Minimum lot',
    'reports.VALUE_MINIMUM_FILTER': 'Min spike value',
    'reports.value_nav': 'Value/Nav',
    'reports.VALUE_NO_QUOTES_MAXTIME': 'No quotes max time',
    'reports.VALUE_NUMBER_OF_SHARES': 'Number of shares',
    'reports.VALUE_ONE_COUNTER_ACCOUNT': 'Counter account',
    'reports.VALUE_PIP_SIZE': 'Tick size(minimal change)',
    'reports.VALUE_PnL_ACCOUNT': 'P/L counter account',
    'reports.VALUE_POST_SESSION_END': 'Post Session end',
    'reports.VALUE_PRE_SESSION_BEGIN': 'Pre session start',
    'reports.VALUE_PRICE_GENERATION_MODEL': 'Price generation model',
    'reports.VALUE_PWD_VERIFICATION_LEN': 'Verification password length',
    'reports.VALUE_QUOTE_CACHE_15MIN_SIZE': 'Quote cache 15min size',
    'reports.VALUE_QUOTE_CACHE_1MIN_SIZE': 'Quote cache 1min size',
    'reports.VALUE_QUOTE_CACHE_30MIN_SIZE': 'Quote cache 30min size',
    'reports.VALUE_QUOTE_CACHE_4HOUR_SIZE': 'Quote cache 4hour size',
    'reports.VALUE_QUOTE_CACHE_5MIN_SIZE': 'Quote cache 5min size',
    'reports.VALUE_QUOTE_CACHE_DAY_SIZE': 'Quote cache day size',
    'reports.VALUE_QUOTE_CACHE_HOUR_SIZE': 'Quote cache hour size',
    'reports.VALUE_QUOTE_CACHE_MONTH_SIZE': 'Quote cache month size',
    'reports.VALUE_QUOTE_CACHE_SIZE': 'Quote cache size',
    'reports.VALUE_QUOTE_CACHE_TIC_SIZE': 'Quote cache tic size',
    'reports.VALUE_QUOTE_CACHE_WEEK_SIZE': 'Quote cache week size',
    'reports.VALUE_QUOTE_CACHE_YEAR_SIZE': 'Quote cache year size',
    'reports.VALUE_QUOTE_PATH': 'Quotes cache path',
    'reports.VALUE_QUOTES_FILE_SIZE': 'Quotes file size',
    'reports.VALUE_SLIPPAGE': 'Slippage',
    'reports.VALUE_SMS_PATTERN': 'SMS Pattern',
    'reports.VALUE_SPOT_INSTRUMENT': 'Spot symbol',
    'reports.VALUE_SPREAD_ACCOUNT': 'Spread counter account',
    'reports.VALUE_STATE': 'State',
    'reports.VALUE_STOPOUT': 'Stop Out level, %',
    'reports.VALUE_SWAP_ACCOUNT': 'Swap counter account',
    'reports.VALUE_TEMPLATE_NUMERIC': 'E-mail templates',
    'reports.VALUE_TICK_COAST': 'Tick coast',
    'reports.VALUE_TIMEZONE_OFFSET': 'Time zone offset',
    'reports.VALUE_UNREPEAT_PASSWORD': 'Number of unrepeated passwords',
    'reports.VALUE_WL_ACCOUNT': 'Deposit/Withdrawal counter account',
    'reports.VALUE_WRONG_PASSWORDS_BEFORE_LOCK': 'Number of wrong passwords allowed',
    'reports.VALUE_ZIP': 'Zip code',
    'reports.ValueAddedTax': 'Value added tax',
    'reports.valuedate': 'Valuation date',
    'reports.Visibility': 'Visibility',
    'reports.VISIBILITY_BRANDING': 'Branding visibility',
    'reports.VISIBILITY_COMMISSIONSPLAN': 'Revenue plans visibility',
    'reports.VISIBILITY_EXTFIELDS': 'Extended fields visibility',
    'reports.VISIBILITY_INSTRUMENT_TYPE': 'Symbol type visibility',
    'reports.VISIBILITY_ROUTE': 'Routes visibility',
    'reports.VISIBILITY_SUBSCRIBE_PLAN': 'Subscribe plans',
    'reports.VISIBILITY_USERGROUP': 'User groups visibility',
    'reports.Wait confirm for cancel': 'Wait confirm for cancel',
    'reports.Wait confirm for execution': 'Wait confirm for execution',
    'reports.Wait confirm for modify': 'Wait confirm for modification',
    'reports.Waiting market': 'Waiting market',
    'reports.WEB_CHART_OE': 'Web chart (build in OE)',
    'reports.WEB_CHART_VISUAL': 'Web chart (visual trading)',
    'reports.WEB_MARKETDEPTH': 'Web Market depth',
    'reports.WEB_OE': 'Web OE',
    'reports.WEB_OPTION_MASTER': 'Web Option chain',
    'reports.WEB_ORDERS_PANEL_HOT_BTN': 'Zlecenia bieżące',
    'reports.WEB_OTHER': 'Web other',
    'reports.WEB_POSITIONS_PANEL_HOT_BTN': 'Otwarte pozycje',
    'reports.WEB_ALERT': 'Alert',
    'reports.SERVER': 'Server',
    'reports.weeks': 'weeks',
    'reports.Widgets Instrument Statistics Report': 'Widgets instrument statistics report',
    'reports.widgets_isr_buytrades': 'Buy trades',
    'reports.widgets_isr_commission': 'Fee',
    'reports.widgets_isr_instrumentname': 'Symbol',
    'reports.widgets_isr_netpl': 'Net P/L',
    'reports.widgets_isr_plticks': 'P/L ticks',
    'reports.widgets_isr_selltrades': 'Sell trades',
    'reports.widgets_isr_swap': 'Swap',
    'reports.widgets_isr_totaltrades': 'Total trades',
    'reports.widgets_isr_trades': 'Trades',
    'reports.widgets_isr_tradevolume': 'Volume',
    'reports.widgets_isr_winningtrades': 'Winning trades',
    'reports.widthdraw': 'Wypłata',
    'reports.Withdraw': 'Wypłata',
    'reports.Withdrawal': 'Wypłata',
    'reports.withdrawalfee': 'Withdrawal fee',
    'reports.Wrong account operation summ.': 'Wrong account operation sum',
    'reports.Wrong recovery key': 'Wrong recovery key',
    'reports.years': 'lat',
    'reports.yes': 'Tak',
    "reports.You can't trade with this account.": 'Handel zabroniony.',
    Reserve: 'Rezerwa',
    'ribbon.enviroment': 'Środowisko',
    'ribbon.enviroment.help': 'Pomoc',
    'ribbon.enviroment.logout': 'Wyloguj',
    'ribbon.enviroment.New': 'Nowy pulpit',
    'ribbon.enviroment.NotificationWindow.lockTrading': 'Handel zablokowany',
    'ribbon.enviroment.NotificationWindow.UnlockTrading': 'Handel odblokowany',
    'ribbon.enviroment.Save': 'Zapisz pulpit',
    'ribbon.enviroment.settings': 'Ustawienia',
    'ribbon.help.about': 'O platformie',
    'ribbon.help.onboarding': 'Onboarding',
    'ribbon.help.help': 'Więcej',
    'ribbon.help.home': 'Strona główna',
    'ribbon.linking.linkBlue': 'Niebieski',
    'ribbon.linking.linkEmpty': 'Brak',
    'ribbon.linking.linkGreen': 'Zielony',
    'ribbon.linking.linkOrange': 'Pomarańczowy',
    'ribbon.linking.linkPurple': 'Fioletowy',
    'ribbon.linking.linkRed': 'Czerwony',
    'ribbon.linking.linkYellow': 'Żółty',
    'ribbon.more.tradingTools': 'Narzędzia handlowe',
    'ribbon.more.brokerInfo': 'Informacje',
    'screen.brokerInfo.header': 'Informacje',
    'ribbon.tools': 'Narzędzia',
    'ribbon.tools.full_logs': 'Log',
    'ribbon.tools.grid': 'Siatka',
    'ribbon.tools.withdrawal': 'Wypłaty',
    'ribbon.tools.reports': 'Raporty',
    'ribbon.tradingTerminal': 'Narzędzia obrotu',
    'ribbon.tradingTerminal.statement': '#hidden#',
    'RiskManagement.Messages.DailyLimit': 'Daily loss limit was reached',
    'RiskManagement.Messages.MaxDailyProfit': 'Daily profit was reached',
    'RiskManagement.Messages.MaxTrailingLimit': 'Max trailing drawdown was reached',
    'RiskManagement.Messages.MaxUnrealizedLossLimit': 'Unrealized loss limit was reached',
    'RiskManagement.Messages.WeeklyLimit': 'Weekly loss limit was reached',
    'RiskManagement.Messages.MaxOrdersCountPerDay': 'Trading was disabled by risk rules. The maximum number of orders per day has been reached.',
    'screen.about.copyright': '© 2017-2024 TraderEvolution Global LTD.',
    'screen.about.dateBuild': 'Data:',
    'screen.about.title': '   ',
    'screen.about.titleWeb': '   ',
    'screen.about.versionWeb': 'Wersja aplikacji',
    'screen.additionalVerification.comment': 'Połączenie ({1}) wymaga dodatkowej autoryzacji:',
    'screen.additionalVerification.Need activation': 'Wymagana aktywacja',
    'screen.additionalVerification.Need activation.comment': 'Wprowadź KOD autoryzacji (e-mail)',
    'screen.additionalVerification.Need activation.watermark': '<Wpisz KOD e-mail>',
    'screen.additionalVerification.title': 'Dodatkowa autoryzacja',
    'screen.additionalVerification.title.Email': 'Dodatkowa autoryzacja (e-mail one-time pass)',
    'screen.additionalVerification.title.Fixed': 'Dodatkowa autoryzacja (fixed pass)',
    'screen.additionalVerification.title.Sms': 'Dodatkowa autoryzacja (SMS one-time pass)',
    'screen.additionalVerification.watermark': '<Wpisz dodatkowe hasło>',
    'screen.changepass.cancel': 'Anuluj',
    'screen.changepass.changed': 'Pomyślnie zmieniono hasło!',
    'screen.changepass.confirm': 'Potwierdź nowe hasło',
    'screen.changepass.new': 'Nowe hasło',
    'screen.changepass.ok': 'Zmień',
    'screen.changepass.old': 'Obecne hasło',
    'screen.changepass.title': 'Zmień hasło',
    'screen.changeTradingPass.title': 'Zmień hasło',
    'screen.changepass.watermark_confirm': '<Potwierdź hasło>',
    'screen.changepass.watermark_new': '<Nowe hasło>',
    'screen.changepass.watermark_old': '<Obecne hasło>',
    'screen.changepass.alert_not_match': 'Nowe hasło i potwierdzenie nie są zgodne',
    'screen.changepass.wrongVerification': 'Błędna weryfikacja',
    'screen.changepass.tooeasy': 'Twoje hasło należy do zbioru popularnych haseł i jest łatwe do odgadnięcia',
    'screen.closePosition.cancel': 'Anuluj',
    'screen.closePosition.ok': 'Zamknij',
    'screen.closePosition.title': 'Zamknij',
    'screen.closePosition.EstimatedClosingFee': 'Prowizja',
    'screen.PositionExerciseRequestScreen.title': 'Position exercise request',
    'screen.PositionExerciseRequestScreen.exercise': 'Exercise',
    'screen.PositionExerciseCancelConfirm.text': 'Cancel exercise request for position #{1} ?',
    'screen.PositionExerciseComplete.header': 'Received status "Pending exercise" for position',
    'screen.PositionExerciseComplete.text': 'Position {1} for account {2} is blocked for exercise. To unblock position exercise request should be cancelled.',
    'screen.PositionExerciseDealticket.header': 'Position exercise request',
    'screen.PositionExerciseCancelDealticket.header': 'Position cancel exercise request',
    'screen.editPosition.header': 'Edycja pozycji',
    'screen.error.title': 'Błąd',
    'screen.export.header': 'Eksport',
    'screen.export.contextMenu': 'Eksport',
    'screen.export.visibility': '#not hidden#',
    'screen.export.Export': 'Eksport',
    'screen.export.Cancel': 'Usuń',
    'screen.export.CSV': 'CSV',
    'screen.export.Excel': 'Excel',
    'screen.export.selectFormat.tt': 'Wybierz typ pliku',
    'screen.export.checkAllText': 'Sprawdź wszystko',
    'screen.licensing.emptyFieldErrors.password': 'Hasło jest puste',
    'screen.licensing.statusMessages.connecting': 'Łączenie...',
    'screen.login.change_pass': 'Hasło musi zostać zmienione',
    'screen.login.bad_branding_key': 'The application is not allowed for user',
    'screen.lookup.scripts.noitems': 'No matching items',
    'screen.lookup.scripts.showing': 'Showing {1} items',
    'screen.lookup.scripts.watermark': '<Szukaj>',
    'screen.modifyOrder.amount': 'Ilość:',
    'screen.modifyOrder.bind_to': 'Przywiązane do:',
    'screen.modifyOrder.Change order': 'Modyfikuj zlecenie',
    'screen.modifyOrder.created': 'Data/Czas:',
    'screen.modifyOrder.instrument': 'Instrument:',
    'screen.modifyOrder.market_price': 'Cena rynkowa:',
    'screen.modifyOrder.modify': 'Modyfikuj',
    'screen.modifyOrder.modifyPosition': 'Modyfikuj pozycję',
    'screen.modifyOrder.number': 'Nr pozycji:',
    'screen.modifyOrder.operation': 'Strona:',
    'screen.modifyOrder.orderNumber': 'ID zlecenia:',
    'screen.modifyOrder.ordertype': 'Typ zlecenia:',
    'screen.modifyOrder.price': 'Cena otwarcia:',
    'screen.modifyOrder.productType': 'Typ produktu:',
    'screen.modifyOrder.route': 'Route:',
    'screen.MonthCalendarPopupForm.Apply': 'Zastosuj',
    'screen.properties.cancel': 'Zamknij',
    'screen.properties.cancel.ToolTip': 'Zamknij',
    'screen.properties.common': 'properties',
    'screen.properties.ok': 'OK',
    'screen.properties.ok.ToolTip': 'OK',
    'screen.properties.title': 'Ustawienia',
    'screen.properties.unsavedChanges': 'Zmiany nie zostały zapisane - zamknąć okno?',
    'screen.LoginScreen.Connecting': 'Łączenie...',
    'screen.LoginScreen.Error.loginIdIsEmpty': "Pole 'Login' jest puste",
    'screen.LoginScreen.Error.passwordIdIsEmpty': "Pole 'Hasło' jest puste",
    'screen.LoginScreen.Error.noWorkingServers': 'Nie można znaleźć aktywnych serwerów',
    'screen.LoginScreen.ForgotLinkLabel': 'Przypomnij hasło',
    'screen.LoginScreen.ForgotLinkLabel.ToolTip': 'Odzyskaj hasło',
    'screen.LoginScreen.LoadingUserData': 'Pobieranie informacji...',
    'screen.LoginScreen.LoginButtonText': 'Zaloguj',
    'screen.LoginScreen.LoginButtonTooltip': 'Loguj do systemu',
    'screen.LoginScreen.LoginIDLabel': 'Login',
    'screen.LoginScreen.LoginIDTextBox.ToolTip': 'Login Klienta',
    'screen.LoginScreen.PasswordLabel': 'Hasło',
    'screen.LoginScreen.PasswordTextBox.ToolTip': 'Hasło Klienta',
    'screen.LoginScreen.RegistrationLinkLabel': 'Rejestracja',
    'screen.LoginScreen.RegistrationLinkLabel.ToolTip': 'Rejestracja DEMO',
    'screen.LoginScreen.SavePassCheckBox': 'Zapisz hasło',
    'screen.LoginScreen.SavePassCheckBox.ToolTip': 'Zapamiętaj dane',
    'screen.LoginScreen.Settings.LanguageLabel': 'Język',
    'screen.recconection.attemptLabelLabel': 'Attempts:',
    'screen.recconection.reconnecting': 'Łączę ponownie...',
    'screen.recconection.seconds': 'sekund...',
    'screen.recconection.statusLabelLabel': 'Status:',
    'screen.recconection.stop': 'Stop',
    'screen.recconection.title': 'Manager połączeń',
    'screen.recconection.tryNow': 'Połącz',
    'screen.recconection.waiting': 'Czekam na dane...',
    'screen.RecoveryPassForm.Cancel': 'Anuluj',
    'screen.RecoveryPassForm.Confirm': 'Potwierdź hasło',
    'screen.RecoveryPassForm.Email': 'Email:',
    'screen.RecoveryPassForm.HaveCode': 'Posiadam kod',
    'screen.RecoveryPassForm.Key': 'Kod',
    'screen.RecoveryPassForm.Login': 'Login:',
    'screen.RecoveryPassForm.New password': 'Nowe hasło',
    'screen.RecoveryPassForm.New password contains prohibited symbols': 'Nowe hasło zawiera niedozwolone znaki',
    'screen.RecoveryPassForm.Send': 'Odzyskaj',
    'screen.RecoveryPassForm.Title': 'Odzyskiwanie hasła',
    'screen.RecoveryPassForm.invalid_login': 'Błędny login',
    'screen.RecoveryPassForm.invalid_login_or_email': 'Invalid login or email to recover your password',
    'screen.RecoveryPassForm.invalid_email': 'Invalid email for this user',
    'screen.RecoveryPassForm.Your request for password recovering has sent successfully. Please check your email!': 'Your request for password recovering has been sent successfully. Please check your email!',
    'screen.registrationWizard.confirmPassword': '*Potwierdź hasło:',
    'screen.registrationWizard.confirmPassword.watermark': '<Wpisz hasło>',
    'screen.registrationWizard.email': '*Email:',
    'screen.registrationWizard.email.watermark': '<Email>',
    'screen.registrationWizard.Country': 'Kraj:',
    'screen.registrationWizard.Password': '*Hasło:',
    'screen.registrationWizard.Balance': 'Saldo:',
    'screen.registrationWizard.firstName': '*Imię:',
    'screen.registrationWizard.firstName.watermark': '<min 2 znaki>',
    'screen.registrationWizard.lastName': '*Nazwisko:',
    'screen.registrationWizard.lastName.watermark': '<min 2 znaki>',
    'screen.registrationWizard.login': 'Login:',
    'screen.registrationWizard.login.watermark': '<Pozostaw puste>',
    'screen.registrationWizard.password.watermark': '<Wpisz hasło>',
    'screen.registrationWizard.phone': 'Phone number:',
    'screen.registrationWizard.phoneNumber.watermark': '<(country code) phone number>',
    'screen.registrationWizard.registrationStatus': 'Registration status',
    'screen.registrationWizard.successMessage': 'Registration is successful!\nLogin: {1}\nPassword: {2}',
    'screen.registrationWizard.Header': 'Utwórz rachunek demo',
    'screen.registrationWizard.Register': 'Zarejestruj',
    'screen.registrationWizard.Cancel': 'Anuluj',
    'screen.renameScreen.cancel': 'Anuluj',
    'screen.renameScreen.illegalName': 'Błędna nazwa!',
    'screen.renameScreen.ok': 'OK',
    'screen.renameScreen.newList.header': 'Nazwa listy',
    'screen.renameScreen.newList.placeholder': 'Nowa lista',
    'screen.renameScreen.newTemplate.header': 'Nazwa projektu',
    'screen.renameScreen.newTemplate.placeholder': 'Nowy projekt',
    'screen.renameScreen.rename.header': 'Zmień nazwę',
    'screen.rename.alreadyExist.firstPart': 'Nazwa ',
    'screen.rename.alreadyExist.secondPart': ' już istnieje.',
    'screen.remove.lastlist': 'Powinna istnieć przynajmniej jedna lista',
    'screen.remove.title': 'Usuń',
    'screen.remove.confirmText.firstPart': 'Usunąć ',
    'screen.remove.confirmText.secondPart': ' listę?',
    'screen.remove.confirmText.secondPart.templates': ' projekt?',
    'screen.ReportMessageTooltip.CreatedAt': 'utworzony',
    'screen.ReportMessageTooltip.FilledAt': 'filled at',
    'screen.ReportMessageTooltip.MarginCall': 'Margin call',
    'screen.ReportMessageTooltip.Market': 'Market',
    'screen.ReportMessageTooltip.ModifiedAt': 'Zmodyfikowano',
    'screen.ReportMessageTooltip.RemovedAt': 'Usunięto',
    'screen.reports.actions_print': 'Drukuj',
    'screen.reports.address': 'Adres',
    'screen.reports.beginDatePicker.ButtonDropDown.ToolTip': 'Kalendarz',
    'screen.reports.beginDatePicker.ToolTip': 'Wybierz datę/czas',
    'screen.reports.brokerzip': 'Zip code',
    'screen.reports.button_printer_setup': 'Setup',
    'screen.reports.byGroup': 'By group',
    'screen.reports.byInstrumentGroup': 'By group',
    'screen.reports.byInstruments': 'By instruments',
    'screen.reports.byUser': 'By account',
    'screen.reports.byUserGroup': 'By group',
    'screen.reports.cell_data': '',
    'screen.reports.column.inner_text': '',
    'screen.reports.columns_maneger': 'Manager kolumn',
    'screen.reports.country': 'Country',
    'screen.reports.csv': 'Eksport do CSV...',
    'screen.reports.customerzip': 'City, Zip',
    'screen.reports.dataCoverage': 'Data coverage',
    'screen.reports.errSaving': 'An error occurred while saving file.',
    'screen.reports.excel': 'Eksport do Excela...',
    'screen.reports.exportToButton': 'Eksportuj do...',
    'screen.reports.filter': 'Filter manager...',
    'screen.reports.historyFor': 'History for',
    'screen.reports.html': 'Eksport do HTML...',
    'screen.reports.info': 'Information',
    'screen.reports.error': 'Error',
    'screen.reports.Reports': 'Raporty',
    'screen.reports.InstrumentAndGroupLookup.byGroupCombobox.ToolTip': 'Wybierz grupę instrumentów',
    'screen.reports.InstrumentAndGroupLookup.instrunentLookup.ToolTip': 'Wybierz instrument',
    'screen.reports.license': 'License number',
    'screen.reports.listViewEx.itemAll': 'Wszystkie',
    'screen.reports.manager': 'Manager',
    'screen.reports.minimizeButton.Hide reports parameters': 'Ukryj parametry',
    'screen.reports.minimizeButton.Show reports parameters': 'Pokaż parametry',
    'screen.reports.name': 'Nazwa',
    'screen.reports.news.daily': 'Codzienne',
    'screen.reports.news.from': 'Od',
    'screen.reports.news.hideACriterias': 'Ukryj zaawansowane',
    'screen.reports.news.invalidDateTime': "Wybierz zakres 'od' i 'do' Data/Czas",
    'screen.reports.news.monthToDate': 'Miesiąc',
    'screen.reports.news.range': 'Zakres',
    'screen.reports.news.showACriterias': 'Pokaż zaawansowane',
    'screen.reports.news.sinceInception': 'Od początku',
    'screen.reports.news.till': 'aż do',
    'screen.reports.news.yearToDate': 'Rok',
    'screen.reports.noData': 'Brak danych',
    'screen.reports.order': 'Zlecenie',
    'screen.reports.param.Name': 'Nazwa',
    'screen.reports.paramOfReport': 'Parametry',
    'screen.reports.pdf': 'Eksport do PDF...',
    'screen.reports.phone': 'Numer telefonu',
    'screen.reports.pos': 'Position',
    'screen.reports.president': 'Name of president',
    'screen.reports.print': 'Drukuj...',
    'screen.reports.processingRequset': 'Anuluj',
    'screen.reports.rangeError': '"Od" data nie może być większa od "do" daty!',
    'screen.reports.report.button_printer_setup': 'Ustawienia',
    'screen.reports.reportHeader.accnumber': 'Numer rachunku',
    'screen.reports.reportHeader.from': 'Od',
    'screen.reports.reportHeader.Text': 'Customer and broker information',
    'screen.reports.reportHeaders.account': 'Rachunek:',
    'screen.reports.reportHeaders.currancy': 'Waluta:',
    'screen.reports.reportHeaders.date': 'Data:',
    'screen.reports.reportHeaders.from': 'Od:',
    'screen.reports.reportHeaders.owner': 'Owner:',
    'screen.reports.reportHeaders.to': 'Do:',
    'screen.reports.reportHeaderText': 'Customer and Broker information',
    'screen.reports.reportParameters': 'Report parameters',
    'screen.reports.reports.Canceled': 'Operacja została anulowana',
    'screen.reports.reports.ExpingToCSV': 'Eksportuję dane do CSV...',
    'screen.reports.reports.ExpingToExcel': 'Eksportuję dane do Excel...',
    'screen.reports.reports.ExpingToHTML': 'Eksportuję dane do HTML...',
    'screen.reports.reports.ExpingToPDF': 'Eksportuję dane do PDF...',
    'screen.reports.reports.ExportingPrint': 'Eksportuję dane do wydruku...',
    'screen.reports.reports.ExpPrint': 'Dane zostały poprawnie zapisane.',
    'screen.reports.reports.ExpPrintErr': 'Błąd podczas eksportu danych!',
    'screen.reports.reports.ExpToCSV': 'Dane zostały poprawnie zapisane.',
    'screen.reports.reports.ExpToCSVErr': 'Błąd podczas eksportu danych!',
    'screen.reports.reports.ExpToExcel': 'Dane zostały poprawnie zapisane.',
    'screen.reports.reports.ExpToExcelErr': 'Błąd podczas eksportu danych!',
    'screen.reports.reports.ExpToHTML': 'Dane zostały poprawnie zapisane.',
    'screen.reports.reports.ExpToHTMLErr': 'Błąd podczas eksportu danych!',
    'screen.reports.reports.ExpToPDF': 'Dane zostały poprawnie zapisane.',
    'screen.reports.reports.ExpToPDFErr': 'Błąd podczas eksportu danych!',
    'screen.reports.reports.getReport': 'Pobierz raport',
    'screen.reports.reports.in_separate_window': 'Raport został otwarty w osobnym oknie',
    'screen.reports.reports.inCurrentWindow': 'Bieżące okno',
    'screen.reports.reports.info': 'Użyj okna Raporty, aby pobrać i wydrukować raporty.',
    'screen.reports.reports.inSeparateWindow': 'Osobne okno',
    'screen.reports.reports.loading': 'Pobieram dane...',
    'screen.reports.reports.noData': 'Brak danych do wyświetlenia.',
    'screen.reports.reports.noItemAvailable': 'Brak danych',
    'screen.reports.reportType': 'Rodzaj raportu',
    'screen.reports.reportTypeCombobox.ToolTip': 'Wybierz raport',
    'screen.reports.save': 'Zapisz',
    'screen.reports.showSummary': 'Pokaż podsumowanie',
    'screen.reports.sinceInception': 'Od pczątku rachunku',
    'screen.reports.summary': 'Podsumowanie',
    'screen.reports.table': 'Tabela',
    'screen.reports.title': 'Raporty',
    'screen.reports.to': 'Do',
    'screen.reports.trades': 'Transakcje',
    'screen.reports.UserAndGroupLookup.byGroupCombobox.ToolTip': "Select account's group",
    'screen.reports.UserAndGroupLookup.usersLookup.ToolTip': 'Wybierz rachunek',
    'screen.snapshot.header': 'Zrzut ekranu',
    'screen.snapshot.lastLabel': 'Ostatni',
    'screen.snapshot.bidLabel': 'Bid',
    'screen.snapshot.askLabel': 'Ask',
    'screen.snapshot.priceLabel': 'Cena:',
    'screen.snapshot.sizeLabel': 'Wielkość:',
    'screen.snapshot.changeLabel': 'Zmiana:',
    'screen.snapshot.highLabel': 'Max:',
    'screen.snapshot.lowLabel': 'Min:',
    'screen.snapshot.changePercentLabel': 'Zmiana, %:',
    'screen.snapshot.volumeLabel': 'Wolumen:',
    'screen.snapshot.getSnapshotBtnText': 'Wykonaj zrzut ekranu',
    'screen.snapshot.getSnapshotBtnText.minusOne.tt': 'Odśwież',
    'screen.snapshot.getSnapshotBtnText.Zero.tt': 'Osiągnięto limit',
    'screen.snapshot.getSnapshotBtnText.moreZero.tt': 'Get real-time data snapshot',
    'screen.snapshot.closeBtnText': 'Zamknij',
    'screen.PanelSettingsScreen.applyButton': 'Zapisz',
    'screen.TimeZoneSelector.title': 'Wybierz strefę czasową',
    'screen.tradingTools.header': 'Narzędzia',
    'screen.UnlockTradingScreen.lockedLabel': 'Handel zablokowany',
    'screen.UnlockTradingScreen.unlockButton': 'Odblokuj',
    'screen.withdrawal.account': 'Z rachunku',
    'screen.withdrawal.Asset': 'Aktywo',
    'screen.withdrawal.Availible funds': 'Dostępne:',
    'screen.withdrawal.avaliableNumeric.ToolTip': 'Set remaining capital',
    'screen.withdrawal.Cancel': 'Cancel',
    'screen.withdrawal.error.not_allowed': 'Account Operation with type Withdrawal is not allowed for your user, please contact your broker',
    'screen.withdrawal.error.not_enough_balance': 'Niewystarczające saldo rachunku',
    'screen.withdrawal.error.not_enough_blocked': 'Błędna wielkość',
    'screen.withdrawal.error.not_enough_margin': 'Not enough margin',
    'screen.withdrawal.error.UnableToBlock': 'Error! Unable to block.',
    'screen.withdrawal.error.WrongSum': 'Błędna suma.',
    'screen.withdrawal.errorLabel': 'Not enough money for withdrawal',
    'screen.withdrawal.Fee': 'Withdrawal fee:',
    'screen.withdrawal.from': 'from',
    'screen.withdrawal.Remains': 'Remains',
    'screen.withdrawal.RequiredAmount': 'Required amount:',
    'screen.withdrawal.ReservedWithdrawal': 'Withdrawal',
    'screen.withdrawal.usersLookup.ToolTip': 'Select account',
    'screen.withdrawal.Withdraw': 'Withdraw',
    'screen.withdrawal.Withdrawal': 'Withdrawal',
    'screen.withdrawal.withdrawalNumeric.ToolTip': 'Set withdrawing capital',
    'screen.withdrawal.Amount': 'Amount',
    'screen.confirmation.ModifyPosition': 'Potwierdź modyfikację pozycji',
    'screen.confirmation.ModifyPosition.Message': 'Czy na pewno chcesz zmodyfikować pozycję?',
    'screen.confirmation.PlaceOrder': 'Potwierdź złożenie zlecenia',
    'screen.confirmation.PlaceOrder.Message': 'Czy na pewno chcesz złożyć zlecenie?',
    'screen.confirmation.EstimatedFee': 'Prowizja',
    'screen.confirmation.positionID': 'Nr pozycji',
    'screen.confirmation.orderID': 'ID zlecenia',
    'screen.confirmation.SLprice': 'Cena SL',
    'screen.confirmation.TPprice': 'Cena TP',
    'Simple Moving Average': 'Simple moving average',
    SixMonth: '6 miesięcy',
    'Smoothed Moving Average': 'Smoothed moving average',
    'Standard Deviation': 'Standard deviation',
    'statusBar.passwordpanel.DetalTicket.Text': 'Allowed access to trading activity',
    'statusBar.passwordPanel.passwordBox.invalidPass': 'Błędne hasło.',
    Stochastic: 'Stochastic',
    'Stochastic Slow': 'Stochastic Slow',
    Stop: 'Stop',
    'Stop Limit': 'Stop Limit',
    'Stop-loss': 'Stop Loss',
    Suomi: '#hidden#',
    'Swing Index': 'Swing index',
    'Take-profit': 'Take profit',
    'TerceraAccountLookup.AccountNotExist': 'Rachunek nie istnieje',
    'TerceraAccountLookup.IncorrectAccount': 'Błędny Rachunek',
    'TerceraAccountLookup.Linked accounts': 'Połączone rachunki',
    'TerceraAccountLookup.Multy select': 'Zaznacz wszystko',
    'TerceraAccountLookup.Select': 'Wybierz',
    'TerceraAccountLookup.Select All': 'Zaznacz wszystko',
    'TerceraInstrumentLookup.InstrTypeImg.tt': 'Kliknij, aby otworzyć informacje o instrumencie',
    'textBoxWithValidating.ErrorText.NameContainsInvalidChar': 'Nazwa zawiera niedozwolone znaki',
    'textBoxWithValidating.ErrorText.NameExist': 'Nazwa już istnieje',
    ThreeMonth: '3 miesiące',
    'Tr. stop': 'Trailing stop',
    'tr. stop': 'Trailing stop loss',
    'TradeDefaultManager.Set defaults': 'Ustaw domyślne',
    'TradeDefaultManager.Types manager': 'Manager ustawień',
    'TradeTool.Amount of order': 'Wielkość zlecenia',
    'TradeTool.Cancel order': 'Usuń zlecenie',
    'TradeTool.Cancel SL': 'Usuń SL',
    'TradeTool.Cancel TP': 'Usuń TP',
    'TradeTool.Close position': 'Zamknij pozycję',
    'TradeTool.Drag to set SL order': 'Przesuń aby ustawić zlecenie SL',
    'TradeTool.Drag to set TP order': 'Przesuń aby ustawić zlecenie TP',
    'TradeTool.Order': 'Zlecenie',
    'TradeTool.Position': 'Pozycja',
    'TradeTool.Position P/L': 'Z/S brutto',
    'TradeTool.Tick and P/L': 'Z/S tiki',
    'TradeTool.Trailing Stop': 'Trailing stop',
    'TradeTool.View more actions': 'Więcej',
    'Trade order limit reached': 'Osiągnięto maksymalny limit zleceń w ciągu dnia',
    TTO: 'TTO',
    'TypesManagerScreen.DefaultLotsSetup': 'Domyślne wartości lot',
    'TypesManagerScreen.NumericLabel.Price increment (arrow), ticks': 'Zmiana kroku wartości (strzałka)',
    'TypesManagerScreen.NumericLabel.SL default offset, ticks': 'SL - domyślna odległość, tiki',
    'TypesManagerScreen.NumericLabel.TP default offset, ticks': 'TP - domyślna odległość, tiki',
    'TypesManagerScreen.NumericLabel.User quantity coefficient': 'Krok wartości zlecenia',
    'TypesManagerScreen.QuickTable.Column.Default lots': 'Domyślna wielkość',
    'TypesManagerScreen.QuickTable.Column.Enable': 'Zaznacz',
    'TypesManagerScreen.Types Manager': 'Manager ustawień',
    'UserControl.TerceraNumeric.ValueGreaterMax': 'Wartość jest większa od maksymalnej',
    'UserControl.TerceraNumeric.ValueIsEmpty': 'Wartość jest pusta',
    'UserControl.TerceraNumeric.ValueLessMin': 'Wartość jest mniejsza od minimalnej',
    'UserControl.TerceraNumeric.ValueNotMultiple': 'Wartość nie jest wieloktotnościa minimalnego kroku ',
    'UserControl.TerceraNumeric.ValueNotNumber': 'Wartość nie jest cyfrą',
    'UserControl.TerceraDQNumeric.ValueLessMin': 'Wartość nie może być mniejsza niż ',
    'UserControl.TerceraDQNumeric.ValueGreaterMax': 'Wartość nie może być większa od: ',
    'UserControl.TerceraSLTPNumeric.ValueIsEmpty': 'Błędna wartość SL/TP',
    Volume: 'Wolumen',
    'Volume Weighted Average Price': 'Volume weighted average price',
    VWAP: 'VWAP',
    allowCreateDemoAccount: '',
    allowCreatePanelsFromPanel: '',
    allowLinkSystem: '',
    allowPasswordRecovery: '',
    allowWorkspaceContextMenu: '',
    allowWorkspaceEdit: '',
    'orders.confirm.all.question1': 'Anulować wszystkie (',
    'orders.confirm.all.question2': ') zlecenia?',
    'orders.confirm.all.title': 'Anuluj wszystkie zlecenia',
    'orders.confirm.cancel.question1': 'Cancel all orders by selected symbol?',
    'positions.confirm.mutual.question1': 'Do you want to mutual close selected (',
    'positions.confirm.mutual.question2': ') pozycje?',
    'Rows.firstPart': 'Limit został osiągnięty',
    'Rows.secondPart': 'Skontaktuj się z infolinią 19 503',
    'general.messageBox.cancel.ToolTip': 'Anuluj zmiany i zamknij',
    'property.Confirmations.ToolTip': 'Ustawienia',
    'general.messageBox.ok.ToolTip': 'Zapisz zmiany i zamknij',
    'property.Defaults.ToolTip': 'Ustawienia domyślne',
    'property.View.ToolTip': 'Ustawienia',
    'property.Warnings.ToolTip': 'Ustawienia',
    'Throtling.Count.firstPart': 'Dostępny limit został osiągnięty',
    'Throtling.Count.secondPart': 'Skontaktuj się z infolinią 19 503',
    'workspace.At first you should unlock workspace to rename it': 'Odblokuj pulpit, aby zmienić jego nazwę.',
    'workspace.Clear': 'Wyczyść',
    'workspace.clear_ask': 'Wyczyścić pulpit?',
    'workspace.clear_wsp': 'Wyczyść pulpit',
    'workspace.Close': 'Zamknij',
    'workspace.close_wsp': 'Zamknij pulpit',
    'workspace.DefaultNameSpace': 'Pulpit#',
    'workspace.errorSavingFile': 'Błąd podczas zapisu.',
    'workspace.information': 'Information',
    'workspace.saved.Message': 'Zapisano pulpit',
    'workspace.Lock': 'Zablokuj',
    'workspace.Lock.Message': 'Zablokowano pulpit',
    'workspace.menu.Clone': 'Kopiuj',
    'workspace.menu.Close': 'Zamknij',
    'workspace.menu.CloseAllButThis': 'Zamknij wszystkie oprócz tego',
    'workspace.menu.Lock': 'Zablokuj',
    'workspace.newWorkspaceName': 'Nowa nazwa pulpitu',
    'workspace.numberOfWorkspace': 'Zbyt dużo otwartych pulpitów',
    'workspace.Rename': 'Zmień nazwę',
    'workspace.Unlock': 'Odblokuj',
    'workspace.Unlock.Message': 'Odblokowano pulpit',
    'InstrumentDetailsPanel.ISIN': 'ISIN',
    'InstrumentDetailsPanel.Industry': 'Industry',
    'InstrumentDetailsPanel.Industry.descr': 'Industry',
    'InstrumentDetailsPanel.Sector': 'Sector',
    'InstrumentDetailsPanel.Sector.descr': 'Sector',
    'InstrumentDetailsPanel.AccruedInterest.descr': 'Current accrued interest calculated for 1 lot',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot.descr': 'Means that for each lot in the made trade the fixed rebate value will be charged/returned (in the plan currency)',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot.descr': 'Means that for each lot in the made trade the fixed rebate value will be charged/returned (in the plan currency)',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume.descr': 'Means that charged/returned sum will be calculated as a percent from trade volume',
    'InstrumentDetailsPanel.AllowedOperations.descr': 'Shows operations allowed for given symbol',
    'InstrumentDetailsPanel.AllowedOrderTypes.descr': 'Shows order types allowed for given symbol',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday': 'Intraday allow short positions',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery': 'Delivery allow short positions',
    'InstrumentDetailsPanel.AllowShortPositions.descr': 'Shows whether allowed or not to open short positions by given symbol',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday.descr': 'Shows whether allowed or not to open short positions by given symbol',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery.descr': 'Shows whether allowed or not to open short positions by given symbol',
    'InstrumentDetailsPanel.AssetClass.descr': 'Type of the symbol traded on the market',
    'InstrumentDetailsPanel.CloseOutDeadline.descr': 'Date when the positions will be closed by each specific contract',
    'InstrumentDetailsPanel.ContactMonth.descr': 'Date of the contract expiration. Available for Options and Futures.',
    'InstrumentDetailsPanel.ContractSize.descr': 'Amount of the underlying asset for one contract',
    'InstrumentDetailsPanel.CurrentSession.descr': 'Information about current trading session',
    'InstrumentDetailsPanel.DeliveryMethod.descr': 'Type of asset delivery',
    'InstrumentDetailsPanel.DeliveryStatus.descr': 'Status of asset delivery',
    'InstrumentDetailsPanel.Description.descr': 'Detailed information about the symbol',
    'InstrumentDetailsPanel.ExerciseStyle': 'Exercise style',
    'InstrumentDetailsPanel.ExerciseStyle.descr': 'Option exercise style',
    'InstrumentDetailsPanel.FirstTradeDate.descr': 'Date when the contract can be traded firstly. Available for Options and Futures.',
    'InstrumentDetailsPanel.HiLimit.descr': 'The upper limit of the allowable price band above which delayed orders are not accepted',
    'InstrumentDetailsPanel.ISIN.descr': 'International securities identification number',
    'InstrumentDetailsPanel.KEYNextHoliday.descr': 'Shows date of the next holiday when trading is not allowed',
    'InstrumentDetailsPanel.LastTradeDate.descr': 'Date when the contract can be traded for the last time. Available for Options, Futures and Forward',
    'InstrumentDetailsPanel.LotSize.descr': 'Amount of the base asset for one lot',
    'InstrumentDetailsPanel.LotStep.descr': 'Step of the lot changes',
    'InstrumentDetailsPanel.LowLimit.descr': 'The lower limit of the allowable price band above which delayed orders are not accepted',
    'InstrumentDetailsPanel.MAIN.descr': 'Information about settings for Main session',
    'InstrumentDetailsPanel.Margin.descr': 'Shows margin requirements which depend on margin calculation type',
    'InstrumentDetailsPanel.Margin_ByAccount.descr': 'Shows margin requirements based on the account coefficients',
    'InstrumentDetailsPanel.MarginBuy.descr': 'Shows margin requirements for long positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.MarginDay.descr': 'Shows day margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginDayBuy.descr': 'Shows day coefficients of the initial and maintenance margin for the long position',
    'InstrumentDetailsPanel.MarginDaySell.descr': 'Shows day coefficients of the initial and maintenance margin for the short position',
    'InstrumentDetailsPanel.MarginOvernight.descr': 'Shows overnight margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginOvernightBuy.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of long position overnight',
    'InstrumentDetailsPanel.MarginOvernightSell.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of short position overnight',
    'InstrumentDetailsPanel.MarginSell.descr': 'Shows margin requirements for short positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.Margin.Intraday.descr': 'Shows margin requirements which depend on margin calculation type',
    'InstrumentDetailsPanel.Margin.Delivery': 'Delivery margin req.',
    'InstrumentDetailsPanel.Margin.Intraday': 'Intraday margin req.',
    'InstrumentDetailsPanel.MarginBuy.Intraday.descr': 'Shows margin requirements for long positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.MarginDay.Intraday.descr': 'Showsday margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginDayBuy.Intraday.descr': 'Showsday coefficients of the initial and maintenance margin for the long position',
    'InstrumentDetailsPanel.MarginDaySell.Intraday.descr': 'Showsday coefficients of the initial and maintenance margin for the short position',
    'InstrumentDetailsPanel.MarginOvernight.Intraday.descr': 'Shows overnight margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginOvernightBuy.Intraday.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of long position overnight',
    'InstrumentDetailsPanel.MarginOvernightSell.Intraday.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of short position overnight',
    'InstrumentDetailsPanel.MarginSell.Intraday.descr': 'Shows margin requirements for short positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.Margin.Delivery.descr': 'Shows margin requirements which depend on margin calculation type',
    'InstrumentDetailsPanel.MarginBuy.Delivery.descr': 'Shows margin requirements for long positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.MarginDay.Delivery.descr': 'Showsday margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginDayBuy.Delivery.descr': 'Showsday coefficients of the initial and maintenance margin for the long position',
    'InstrumentDetailsPanel.MarginDaySell.Delivery.descr': 'Showsday coefficients of the initial and maintenance margin for the short position',
    'InstrumentDetailsPanel.MarginOvernight.Delivery.descr': 'Shows overnight margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginOvernightBuy.Delivery.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of long position overnight',
    'InstrumentDetailsPanel.MarginOvernightSell.Delivery.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of short position overnight',
    'InstrumentDetailsPanel.MarginSell.Delivery.descr': 'Shows margin requirements for short positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.MaturityDate.descr': 'Date of the contract expiration',
    'InstrumentDetailsPanel.MaximumLot.descr': 'The largest trade allowed in lots',
    'InstrumentDetailsPanel.MaximumLot.Intraday': 'Intraday maximum lot',
    'InstrumentDetailsPanel.MaximumLot.Intraday.descr': 'The largest trade allowed in lots',
    'InstrumentDetailsPanel.MaximumLot.Delivery': 'Delivery maximum lot',
    'InstrumentDetailsPanel.MaximumLot.Delivery.descr': 'The largest trade allowed in lots',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.descr': 'Maximum sum qty of all positions per instrument in lots',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday.descr': 'Maximum sum qty of all positions per instrument in lots',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery.descr': 'Maximum sum qty of all positions per instrument in lots',
    'InstrumentDetailsPanel.MinimalLot.descr': 'The smallest part of lot for which trading is available',
    'InstrumentDetailsPanel.MinimalLot.Intraday': 'Intraday minimal lot',
    'InstrumentDetailsPanel.MinimalLot.Intraday.descr': 'The smallest part of lot for which trading is available',
    'InstrumentDetailsPanel.MinimalLot.Delivery': 'Delivery minimal lot',
    'InstrumentDetailsPanel.MinimalLot.Delivery.descr': 'The smallest part of lot for which trading is available',
    'InstrumentDetailsPanel.NextPaymentDate.descr': 'Next yield payment date',
    'InstrumentDetailsPanel.NormalMarketSize.descr': 'Normal market size',
    'InstrumentDetailsPanel.NoticeDate.descr': 'Date when the notification about soonest expiration date will be sent. Available for Futures.',
    'InstrumentDetailsPanel.OrderPerLot.descr': 'Fee paid for each lot which was traded',
    'InstrumentDetailsPanel.OrderVolume.descr': 'Fee paid per each part of order volume that was filled',
    'InstrumentDetailsPanel.PerFill.descr': 'Fee paid for each fill of the order',
    'InstrumentDetailsPanel.PerLot.descr': 'Fee paid for each lot which was traded',
    'InstrumentDetailsPanel.PerOrderVolume.descr': 'Fee paid per each part of order volume that was filled',
    'InstrumentDetailsPanel.PerPhoneTransaction.descr': 'Fee paid for each transaction which was made by Phone dealer',
    'InstrumentDetailsPanel.PerTransaction.descr': 'Fee for each transaction. Transactions: place an order, cancel an order, and modify an order',
    'InstrumentDetailsPanel.PerVolume.descr': 'Fee calculates on base of the volume of each trade, %',
    'InstrumentDetailsPanel.POSTCLOSE.descr': 'Information about settings for Post-market session',
    'InstrumentDetailsPanel.PREOPEN.descr': 'Information about settings for Pre-market session',
    'InstrumentDetailsPanel.PreviousPaymentDate.descr': 'Previous yield payment date',
    'InstrumentDetailsPanel.QuotiongCurrency.descr': 'Information about currency in which quote is calculated',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot.descr': 'Means that for each lot in the made trade the fixed rebate value will be charged/returned (in the plan currency)',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot.descr': 'Means that for each lot in the made trade the fixed rebate value will be charged/returned (in the plan currency)',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume.descr': 'Means that charged/returned sum will be calculated as a percent from trade volume',
    'InstrumentDetailsPanel.SessionStatus.descr': 'Status of the session, available values: Open, Close',
    'InstrumentDetailsPanel.SettlementDate.descr': 'Date when delivery has to take place',
    'InstrumentDetailsPanel.StrikePrice.descr': 'Price level of the underlier by which Option contract will be executed',
    'InstrumentDetailsPanel.SwapBuy.descr': 'Shows the swap value applied to overnight long positions of the symbol',
    'InstrumentDetailsPanel.SwapSell.descr': 'Shows the swap value applied to overnight short positions of the symbol',
    'InstrumentDetailsPanel.Symbol.descr': 'Name of the symbol chosen in the lookup',
    'InstrumentDetailsPanel.TickCoast.descr': 'Amount of base asset for one tick',
    'InstrumentDetailsPanel.TickSize.descr': 'Minimal change of the price',
    'InstrumentDetailsPanel.TradingBalance.descr': 'T+x settlement cycle',
    'InstrumentDetailsPanel.TradindBlockedOnSession.descr': 'Shows whether trading is blocked for current session',
    'InstrumentDetailsPanel.TradingStatus.descr': 'Trading status, available values: Open, Closed, Trading halt',
    'InstrumentDetailsPanel.Underlier.descr': 'Name of an asset which will be delivered. Available for Options and Futures.',
    'InstrumentDetailsPanel.VAT.descr': 'Value added tax paid from each fee operation',
    'InstrumentDetailsPanel.Volume.descr': 'Fee calculates on base of the volume of each trade',
    'InstrumentDetailsPanel.Yield.descr': 'Current yield value',
    'InstrumentDetailsPanel.ExchangeTrading': 'Giełda',
    'InstrumentDetailsPanel.ExchangeMarketData': 'Kwotowania',
    'InstrumentDetailsPanel.CustodialFee': 'Custody',
    'InstrumentDetailsPanel.CustodialFee.descr': 'Custody fees',
    'panel.accounts.AccountValue.descr': 'Total value of account',
    'panel.accounts.Asset.descr': 'Asset name',
    'panel.accounts.AvailableMargin.descr': 'Funds available for this account',
    'panel.accounts.Balance.descr': 'Balance of account',
    'panel.accounts.BalancePlusAllRisks.descr': 'Balance&All risks',
    'panel.accounts.BeginBalance.descr': 'Net profit/loss for today',
    'panel.accounts.BlockedByOrders.descr': 'Margin blocked for orders',
    'panel.accounts.BlockedByPamm.descr': 'Start capital invested for funds',
    'panel.accounts.CashBalance.descr': 'Cash balance',
    'panel.accounts.ClientType.descr': 'Account owner last connected from ( .Net, Java, Mobile, Html)',
    'panel.accounts.CreditValue.descr': 'Credit funds available for trading',
    'panel.accounts.CurBalance.descr': 'Current equity (balance + unrealized P/L)',
    'panel.accounts.CurMargin.descr': 'Initial margin req. in relation to Balance&All risks',
    'panel.accounts.CurrentFundCapital.descr': 'Current capital invested for funds',
    'panel.accounts.Email.descr': 'Email address',
    'panel.accounts.Estimate value.descr': 'Value calculated from other asset',
    'panel.accounts.FundCapitalGain.descr': 'Difference between current and start fund capital',
    'panel.accounts.FundsUsedPercent.descr': 'Funds used in relation to Balance&All risks',
    'panel.accounts.InterestRate.descr': 'Annual interest rate on deposit',
    'panel.accounts.Leverage.descr': "Account's initial margin leverage",
    'panel.accounts.LockedBalance.descr': 'Locked balance for further withdrawing',
    'panel.accounts.LockedBy.descr': 'Locked by broker (request for quote for traders)',
    'panel.accounts.Login.descr': 'Account name',
    'panel.accounts.MaintMargin.descr': 'Maintenance margin req. in relation to Balance&All risks',
    'panel.accounts.MarginAvailableReal.descr': 'Margin available for the account',
    'panel.accounts.MarginRequirement.descr': 'Maintenance requirement for all opened positions',
    'panel.accounts.MarginStopOut.descr': 'Maintenance margin blocked for positions and orders',
    'panel.accounts.MarginStopTrade.descr': 'Account prevented from trading if balance is less than this level',
    'panel.accounts.MarginUsedNew.descr': 'Initial margin blocked for positions and orders',
    'panel.accounts.MarginWarning.descr': 'Warning margin message occurs if balance is less than this level',
    'panel.accounts.marginWarnPercent.descr': 'Margin warning level, %',
    'panel.accounts.MaxDailyLossLimit.descr': "Maximum value of day's net loss for account",
    'panel.accounts.MaxDailyProfit.descr': 'Maximum value of daily net profit for account',
    'panel.accounts.MaxDrawdownLevel.descr': 'Indicates minimal allowed Projected balance value that the account can have',
    'panel.accounts.MaximumLot.descr': 'Maximal quantity for one order',
    'InstrumentDetailsPanel.Account.descr': 'Name of the account',
    'panel.accounts.MaxOrderAmount.descr': 'Maximal order amount',
    'panel.accounts.MaxOrderCapital.descr': 'Maximal capital for one order',
    'panel.accounts.MaxOrdersPerDay.descr': 'Number of orders that user can place during a day',
    'panel.accounts.MaxPendingOrders.descr': 'Maximal pending orders count',
    'panel.accounts.MaxPendingOrdersValue.descr': 'Maximum cumulative pending orders value available for user',
    'panel.accounts.MaxPositionQtyPerSymbol.descr': 'Maximal quantity for one position per symbol',
    'panel.accounts.MaxPositions.descr': 'Maximal positions count',
    'panel.accounts.MaxWeekLostLimit.descr': "Maximum value of week's net loss for account",
    'panel.accounts.MinMargin.descr': 'Margin blocked for positions',
    'panel.accounts.GrossPNL.descr': 'Gross profit/loss for today',
    'panel.accounts.OptionPremiumReq.descr': 'Option premium for buy orders',
    'panel.accounts.OptionValue.descr': 'Current option value in portfolio',
    'panel.accounts.Orders.descr': 'Number of orders',
    'panel.accounts.Owner.descr': "Account's owner",
    'panel.accounts.Positions.descr': 'Number of positions',
    'panel.accounts.Profit.descr': 'Profit/loss of all opened positions',
    'panel.accounts.ProfitNet.descr': 'Net profit or loss on open positions',
    'panel.accounts.Role.descr': 'Fund type',
    'panel.accounts.Status.descr': 'Account owner user status (online, offline)',
    'panel.accounts.Collateral.descr': 'Сurrent collateral value for short positions',
    'panel.accounts.StocksOrdersReq.descr': 'Stock orders requirement',
    'panel.accounts.StocksValue.descr': 'Current stocks value in portfolio',
    'panel.accounts.stopOutPercent.descr': 'Maint. margin req% when stop out occurs',
    'panel.accounts.stopTradingPercent.descr': 'Stop trading level, %',
    'panel.accounts.TodayFees.descr': 'Amount of fees paid by account',
    'panel.accounts.TodayRebates.descr': 'Sum of all funds that have been received/paid by the broker for the creation/removal of liquidity for the current day',
    'panel.accounts.TodayTrades.descr': 'Count of today trades',
    'panel.accounts.TodayTurnover.descr': 'Turnover of the funds per day for present account',
    'panel.accounts.TodayVolume.descr': "Today's traded volume",
    'panel.accounts.TotalMaxPositionsQty.descr': 'Maximum sum qty of all positions in lots',
    'panel.accounts.TrailingDrawdownLevel.descr': 'Maximum drawdown level for account',
    'panel.accounts.UnsettledCash.descr': 'Profit/Loss, which will be credited to the account in accordance with T+n value cycle',
    'panel.accounts.UserGroup.descr': 'User group',
    'panel.accounts.UserID.descr': 'ID of user',
    'panel.accounts.UserLogin.descr': 'Login of user',
    'panel.accounts.WithdrawalAvailable.descr': 'Maximum balance available for withdrawn',
    'panel.accounts.CurrentDailyLoss.descr': 'Current net daily loss limit value for account',
    'panel.accounts.CurrentWeeklyLoss.descr': 'Current net weekly loss limit value for account',
    'panel.accounts.UnrealizedLoss.descr': 'Current unrealized loss value for account',
    'panel.accounts.UnrealizedLossLimit.descr': 'Maximum value of unrealized loss for account',
    'panel.accounts.VolumeLimitForEquities.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Qty*Lot size or Qty*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForFutures.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Qty*Lot size or Qty*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForOptions.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Qty*Lot size or Qty*Tick cost/Tick size) or in money exposure',
    'panel.accountDetails.Groups.1.General': 'Podsumowanie rachunku',
    'panel.accountDetails.Groups.2.Margin': 'Ryzyko',
    'panel.accountDetails.Groups.3.AccountActivity': 'Bieżący stan rachunku',
    'panel.accountDetails.Groups.4.TodayResults': 'Dzisiejsze wyniki',
    'panel.accountDetails.Groups.5.RiskManagement': 'Zarządzanie ryzykiem',
    'panel.accountDetails.Groups.55.Plans': 'Plany',
    'panel.accountDetails.Groups.6.Info': 'Informacje',
    'panel.accountDetails.Rico risks.AVLM': 'Dostępny margin',
    'panel.accountDetails.Rico risks.BCKA': 'Zablokowana wartość',
    'panel.accountDetails.Rico risks.BKBL': 'Blokowane saldo',
    'panel.accountDetails.Rico risks.BMBM': 'BMF blocked margin',
    'panel.accountDetails.Rico risks.BMFL': 'BMF limit',
    'panel.accountDetails.Rico risks.BOPT': 'Balance options',
    'panel.accountDetails.Rico risks.BUOP': 'Balance uncovered operation',
    'panel.accountDetails.Rico risks.CED0': 'Credit entry day 0',
    'panel.accountDetails.Rico risks.CED1': 'Credit entry day 1',
    'panel.accountDetails.Rico risks.CED2': 'Credit entry day 2',
    'panel.accountDetails.Rico risks.CED3': 'Credit entry day 3',
    'panel.accountDetails.Rico risks.CRLM': 'Credit limit',
    'panel.accountDetails.Rico risks.CRLO': 'Credit limit opt',
    'panel.accountDetails.Rico risks.CULM': 'Current limit',
    'panel.accountDetails.Rico risks.D1PB': 'D1 projected balance',
    'panel.accountDetails.Rico risks.D1PD': 'D1 proj. date',
    'panel.accountDetails.Rico risks.D2PB': 'D2 projected balance',
    'panel.accountDetails.Rico risks.D2PD': 'D2 proj. date',
    'panel.accountDetails.Rico risks.D3PB': 'D3 projected balance',
    'panel.accountDetails.Rico risks.D3PD': 'D3 proj. date',
    'panel.accountDetails.Rico risks.DED0': 'Debit entry day 0',
    'panel.accountDetails.Rico risks.DED1': 'Debit entry day 1',
    'panel.accountDetails.Rico risks.DED2': 'Debit entry day 2',
    'panel.accountDetails.Rico risks.DED3': 'Debit entry day 3',
    'panel.accountDetails.Rico risks.DLBL': 'Day liq. balance',
    'panel.accountDetails.Rico risks.DLMT': 'Daily limit',
    'panel.accountDetails.Rico risks.EBVL': 'Exec. bought value',
    'panel.accountDetails.Rico risks.ESVL': 'Exec. sold value',
    'panel.accountDetails.Rico risks.FBBL': 'Final bmf balance',
    'panel.accountDetails.Rico risks.FNBL': 'Final balance',
    'panel.accountDetails.Rico risks.FNLE': 'Financial leverage',
    'panel.accountDetails.Rico risks.INBA': 'Initial balance',
    'panel.accountDetails.Rico risks.INBL': 'Initial balance',
    'panel.accountDetails.Rico risks.LEVE': 'Leverage',
    'panel.accountDetails.Rico risks.LILO': 'Leverage options limit',
    'panel.accountDetails.Rico risks.LVLM': 'Leverage limit',
    'panel.accountDetails.Rico risks.OPBL': 'Options balance',
    'panel.accountDetails.Rico risks.OVLT': 'Overdraft limit',
    'panel.accountDetails.Rico risks.PBVL': 'Pending bought value',
    'panel.accountDetails.Rico risks.POVA': 'Potencial overdraft amount',
    'panel.accountDetails.Rico risks.PRBA': 'Project balance',
    'panel.accountDetails.Rico risks.PSVL': 'Pending sold value',
    'panel.accountDetails.Rico risks.SKBL': 'Stocks balance',
    'panel.accountDetails.Rico risks.WDBL': 'Withdrawal balance',
    'panel.accountDetails.Kyrrex risks.AvailableBalance': 'Available balance',
    'panel.accountDetails.Kyrrex risks.AvailableBalance.descr': 'Available balance',
    'panel.accountDetails.Kyrrex risks.BlockedBalance.descr': 'Blocked balance',
    'panel.accountDetails.Groups.Cedro risks': 'Szczegóły rachunku',
    'panel.accountDetails.Cedro risks.AVBA': 'Available Balance',
    'panel.accountDetails.Cedro risks.DAY_1_CREDITS': 'D1',
    'panel.accountDetails.Cedro risks.DAY_2_CREDITS': 'D2',
    'panel.accountDetails.Cedro risks.PRBA': 'Project Balance',
    'panel.accountDetails.Cedro risks.TOTL': 'Total',
    'panel.accountDetails.Cedro risks.LMAL': 'Total Limit (Day Trade)',
    'panel.accountDetails.Cedro risks.LMDI': 'Total balance (Day Trade)',
    'panel.accountDetails.Cedro risks.SLDTL': 'Total balance (Position)',
    'panel.accountDetails.Cedro risks.UNQL': 'Total limit (Position)',
    'Order.OrderStatus.STATUS_UNKNOWN': 'Unknown',
    'Order.OrderStatus.STATUS_PENDING_NEW': 'Oczekujące',
    'Order.OrderStatus.STATUS_NEW': 'Utworzono',
    'Order.OrderStatus.STATUS_ACCEPTED': 'Zaakceptowano',
    'Order.OrderStatus.STATUS_PENDING_EXECUTION': 'Oczekujące wykonanie',
    'Order.OrderStatus.STATUS_REPLACED': 'Zmodyfikowane',
    'Order.OrderStatus.STATUS_PENDING_CANCEL': 'Oczekująca anulata',
    'Order.OrderStatus.STATUS_PART_FILLED': 'Częściowo wykonane',
    'Order.OrderStatus.STATUS_FILLED': 'Wykonane',
    'Order.OrderStatus.STATUS_PENDING_REPLACE': 'Oczekująca modyfikacja',
    'Order.OrderStatus.STATUS_WAITING_MARKET': 'Waiting for market',
    'Order.OrderStatus.STATUS_OFF_MARKET': 'Off market',
    'Order.OrderStatus.STATUS_UNPLACED': 'Unplaced',
    'pageNavigation.nextButton': 'Dalej',
    'pageNavigation.backButton': 'Wstecz',
    'pageNavigation.doneButton': 'OK',
    'onBoarding.slide1.header1': 'Obszar zarządzania pulpitem',
    'onBoarding.slide1.text1': 'Pulpit składa się z okien i paneli (zawiera ich konfigurację i lokalizację). Wszystkie zmiany pulpitu są zapisywane automatycznie.',
    'onBoarding.slide2.header1': 'Dodatkowe opcje pulpitu',
    'onBoarding.slide2.text1': 'Klonowanie - kopiuje bieżący pulpit \n       Zmień nazwę - pozwala na zmianę nazwy pulpitu',
    'onBoarding.slide2.text2': 'Możesz zmienić nazwę pulpitu \n dwukrotnie klikając na jego nazwę.',
    'onBoarding.slide3.header1': 'Zablokuj pulpit',
    'onBoarding.slide3.header2': 'Zamknij pulpit',
    'onBoarding.slide3.header3': 'Dodaj pulpit',
    'onBoarding.slide3.text1': 'Zablokuj lub odblokuj pulpit klikając ikonę kłódki. Jeżeli pulpit jest zablokowany nie będziesz mógł zamknąć otwartych okien ani dodać nowych.',
    'onBoarding.slide3.text2': 'Zamknij pulpit klikając przycisk “X”.',
    'onBoarding.slide3.text3': 'Stwórz nowy pulpit klikając przycisk “+”.',
    'onBoarding.slide4.header1': 'Połącz okna za pomocą tego samego koloru',
    'onBoarding.slide4.text1': 'Możesz zmienić instrument automatycznie dla wszystkich okien dokonując zmiany tylko w jednym miejscu. Kliknij ikonę łączenia i wybierz taki sam kolor dla wszystkich okien.',
    'onBoarding.slide5.header1': 'Handel myszą',
    'onBoarding.slide5.text1': 'Złóż zlecenia bezpośrednio na wykresie. Aktywuj handel myszą w prawym górnym rogu wykresu',
    'onBoarding.slide5.text2': 'Ustaw wielkość i czas wykonania zlecenia.',
    'onBoarding.slide5.text3': 'Kliknij i przesuń, aby modyfikować zlecenia i pozycje, dodać Stop Loss lub Take Profit',
    'onBoarding.slide7.header1': 'Dokowanie okien',
    'onBoarding.slide7.text1': 'Każde okno panelu może być przesunięte i połączone z innym oknem. Przesuń i upuść okno tam, gdzie chcesz je zadokować, aż pojawią się przyciski ze strzałkami.',
    'onBoarding.slide8.header1': 'Informacje o instrumentach',
    'onBoarding.slide8.text1': 'Znajdź dodatkowe informacje o każdym instrumencie, klikając ikonę typu instrumentu.',
    'onBoarding.slide9.header1': 'Szczegóły rachunku',
    'onBoarding.slide9.text1': 'Przeciągnij i upuść dowolne pole z listy, aby pokazać je w nagłówku.',
    'onBoarding.slide10.header1': 'Łączenie okien z rachunkiem',
    'onBoarding.slide10.text1': 'Połącz okna z rachunkiem',
    'onBoarding.slide10.text2': 'W ten sposób umożliwisz filtrowanie zleceń i pozycji dla różnych rachunków',
    'onBoarding.slide11.header1': 'Pomoc',
    'onBoarding.slide11.header2': 'Ustawienia',
    'onBoarding.slide11.text1': 'Kliknij, aby przeczytać pełną instrukcję.',
    'onBoarding.slide11.text2': 'Kliknij, aby zmienić ustawienia.',
    'onBoarding.slide12.header1': 'Gotowe!',
    'IndicatorGroup.Moving Average': 'Średnie kroczące',
    MD: 'MD',
    REGRESSION: 'REGRESJA',
    Regression: 'Regresja',
    SMMA: 'SMMA',
    PPMA: 'PPMA',
    SMA: 'SMA',
    EMA: 'EMA',
    'IndicatorGroup.Oscillators': 'Oscylatory',
    MACD: 'MACD',
    BB: 'BB',
    BBF: 'BBF',
    'IndicatorGroup.Volume': 'Wolumen',
    RSI: 'RSI',
    PO: 'PO',
    CCI: 'CCI',
    Aroon: 'Aroon',
    KRI: 'KRI',
    'Kairi Relative Index': 'Kairi Relative Index',
    PPO: 'PPO',
    'Percentage Price Oscillator': 'Percentage Price Oscillator',
    RLW: 'RLW',
    '%R Larry Williams': '%R Larry Williams',
    ROC: 'ROC',
    'Rate of Change': 'Rate of Change',
    QStick: 'QStick',
    TSI: 'TSI',
    'True Strength Index': 'True Strength Index',
    SI: 'SI',
    ALLIGATOR: 'ALLIGATOR',
    MMA: 'MMA',
    'IndicatorGroup.Trend': 'Trend',
    STOCHASTIC: 'STOCHASTIC',
    OBV: 'OBV',
    'IndicatorGroup.Volatility': 'Zmienność',
    ATR: 'ATR',
    CMO: 'CMO',
    SD: 'SD',
    SAR: 'SAR',
    ADX: 'ADX',
    'IndicatorGroup.Channels': 'Kanały',
    KELTNER: 'KELTNER',
    CHANNEL: 'CHANNEL',
    MAE: 'MAE',
    LWMA: 'LWMA',
    MAS3: 'MAS3',
    'IndicatorGroup.Extra': 'Extra',
    Didi: '#hidden#',
    HiloEscandina: '#hidden#',
    StopATR: '#hidden#',
    Fractals3: 'Fractals3',
    Fractals5: 'Fractals5',
    'Kaufman adaptive moving average': 'Kaufman adaptive moving average',
    'Multi EMA': 'Multi EMA',
    'Self-Adjusting Alpha with Fractals Energy': 'RSI Self-Adjusting Alpha with Fractals Energy',
    'disconnectMessageBox.Header': 'Połączenie',
    'disconnectMessageBox.Text': 'Połączenie zostanie przerwane za {0} sekund przez brak aktywności. Kliknij "Wyloguj" aby zakończyć połączenie lub "Anuluj" by pozostać zalogowanym.',
    'disconnectMessageBox.logoutButton': 'Wyloguj',
    'disconnectMessageBox.cancelButton': 'Anuluj',
    'disconnectMessageBox.logoutButton.descr': 'Kliknij, aby się wylogować.',
    'disconnectMessageBox.cancelButton.descr': 'Kliknij, aby pozostać zalogowanym.',
    ChangeValidityToTIF: '#hidden#',
    'Resources.Property.Opening market orders are forbidden': 'Zlecenia Market są zabronione',
    'Session has expired. Please login.': 'Sesja wygasła - zaloguj się ponownie',
    'potrfolio.management.headerLabel.MyPortfolio': 'Moje Portfolio',
    'potrfolio.metrics.textMetrics': 'Metrics',
    'potrfolio.metrics.textMetricsTitle': 'Portfolio growth',
    'potrfolio.metrics.addpanel.header': 'Add metrics',
    'potrfolio.metrics.addpanel.buttonText': 'Dodaj',
    'alert.event.header.created': 'Utworzono',
    'alert.event.text.created': 'Alarm {0} {1} został utworzony',
    'alert.event.header.modified': 'Modyfikacja',
    'alert.event.text.modified': 'Alarm {0} {1} został zmodyfikowany',
    'alert.event.header.stopped': 'Zatrzymanie',
    'alert.event.text.stopped': 'Alarm {0} {1} został zatrzymany',
    'alert.event.header.executed': 'Wykonanie',
    'alert.event.text.executed': 'Alarm {0} {1} został wykonany',
    'alert.event.header.expired': 'Wygaśnięcie',
    'alert.event.text.expired': 'Alarm {0} {1} wygasł',
    'alert.event.header.deleted': 'Usunięcie',
    'alert.event.text.deleted': 'Alarm {0} {1} został usunięty',
    'alert.event.header.restarted': 'Ponowne utworzenie',
    'alert.event.text.restarted': 'Alarm {0} {1} został zmodyfikowany',
    'alert.specifyOrder.Error': 'Wprowadź parametry zlecenia, aby dodać zlecenie do alarmu',
    'alert.specifyAlertType.Error': 'Dla instrumentów Forex nie można ustawić alarmu dla wolumenu',
    'alert.specifyExpirationTime.Error': 'Data wygaśnięcia jest mniejsza niż data obecna',
    'alert.specifyAlertPanelBtn.Error': 'Max liczba alarmów została osiągnięta.',
    'screen.products.Products': 'Subskrypcje',
    'screen.products.Categories': 'Subskrypcje',
    'screen.products.Request': 'Request',
    'screen.products.Request.ToolTip': 'Contains outcome subscribe and unsubscribe requests',
    'screen.products.ButtonSubscribe': 'Subskrybuj',
    'reports.Fees': 'Opłaty',
    'reports.Account performance': 'Raport zysków/strat',
    'reports.Cash deposit and withdrawal': 'Wpłaty/wypłaty',
    'reports.Trades': 'Transakcje',
    'reports.Removed orders': 'Anulowane zlecenia',
    'reports.Order history': 'Historia zleceń',
    'reports.Account statement': 'Stan rachunku',
    'report.basis': 'Szczegóły',
    'property.general.DisableInactivityPeriod.confirmation.message': 'Włącz podtrzymanie sesji.\nZnam, rozumiem oraz akceptuje ryzyko związane z podtrzymaniem sesji.',
    'screen.changepass.changed.login': 'Haslo zostalo zmienione. Prosze, zaloguj sie ponownie.',
    'system.ChangePassLow': 'Błędne hasło - nowe hasło powinno składać się z co najmniej {0} znaków i nie powinno zawierać znaku "&". Spróbuj ponownie',
    'system.ChangePassBase': 'Błędne hasło - nowe hasło powinno składać się z co najmniej {0} znaków w tym: 1 wielką literę, 1 małą literę i 1 cyfrę oraz nie powinno zawierać znaku "&". Spróbuj ponownie.',
    'system.ChangePassHigh': 'Błędne hasło - nowe hasło powinno składać się z co najmniej {0} znaków w tym: 1 wielką literę, 1 małą literę, 1 cyfrę, 1 znak specjalny (np. @#$%) oraz nie powinno zawierać znaku "&". Spróbuj ponownie',
    'system.TooLongPass': 'Maksymalna długość hasła to {0} znaków.',

    'Web.mobile.active.WorkingOrders': 'Bieżące',
    'Web.mobile.active.WorkingOrders.EmptyPlaceholder': 'Brak zamówień',
    'Web.mobile.active.FilledOrders': 'Wykonane',
    'Web.mobile.active.HistoryOrders': 'Historia',
    'Web.mobile.active.Positions': 'Pozycje',
    'Web.mobile.active.Positions.EmptyPlaceholder': 'Brak pozycji',
    'Web.mobile.active.Assets': 'Aktywa',
    'Web.mobile.active.Orders': 'Zlecenia',
    'Web.mobile.active.More': 'Więcej',
    'Web.mobile.active.MamSummary': 'Podsumowanie MAM',
    'Web.mobile.active.OptionChain': 'Łańcuch opcji',
    'Web.mobile.active.Alerts.NoAlerts': 'Brak alertów',
    'Web.mobile.active.EventLog.NoEvents': 'Brak wydarzeń',
    'Web.mobile.active.EventLog.DateTime': 'Data i godzina',
    'Web.mobile.active.MamSummary.Column.OperationType': 'Typ operacji',
    'Web.mobile.active.MamSummary.Column.Amount': 'Kwota',
    'Web.mobile.active.More.Header': 'Więcej',
    'Web.mobile.active.Settings.Defaults': 'Domyślne',
    'Web.mobile.active.Settings.QuantityInLots': 'Ilość w lotach',
    'Web.mobile.active.Settings.SetSlTpInOffset': 'Ustaw SL/TP z odchyleniem',
    'Web.mobile.active.Settings.OrderSending': 'Wysyłanie zlecenia',
    'Web.mobile.active.Settings.OrderModifying': 'Modyfikacja zlecenia',
    'Web.mobile.active.Settings.OrderExecuting': 'Wykonywanie zlecenia',
    'Web.mobile.active.Settings.OrderCancelling': 'Anulowanie zlecenia',
    'Web.mobile.active.Settings.PositionModifying': 'Modyfikacja pozycji',
    'Web.mobile.active.Table.Total.Profit': 'Łączny zysk',
    'Web.mobile.active.NewTrades.PnL': 'Zysk/Strata',
    'Web.mobile.active.OrderBook.ExecutionType': 'Typ realizacji',
    'Web.mobile.active.Assets.EmptyPlaceholder': 'Brak aktywów',
    'Web.mobile.active.Assets.DefSymbol': 'Domyślny symbol',
    'Web.mobile.active.Assets.SODQuantity': 'Ilość na koniec dnia',
    'Web.mobile.active.Assets.CurrentValueForSale': 'Aktualna wartość do sprzedaży',
    'Web.mobile.active.Positions.Profit': 'P/L',
    'Web.mobile.active.AccountDetails.LinkingTooltip': 'Ta funkcja filtruje Twoje pozycje i zlecenia według konta. Przełączanie konta jest synchroniczne we wszystkich panelach.',
    'Web.mobile.active.Watchlist.EmptyPlaceholder': 'Brak symboli',
    'Web.mobile.active.Watchlist.ListNamePlaceholder': 'Wpisz nazwę tutaj',
    'Web.mobile.active.Watchlist.Added': 'Dodane do listy obserwowanych',
    'Web.mobile.active.Watchlist.Removed': 'Usunięte z listy obserwowanych',
    'Web.mobile.active.Watchlist.MaxReached': 'Osiągnięto maksymalną liczbę',
    'Web.mobile.active.ExchangesFilter.Filter': 'Filtr',
    'Web.mobile.active.OrderEntry.Send': 'Wyślij zlecenie',
    'Web.mobile.active.OrderEntry.Price': 'Cena',
    'Web.mobile.active.OrderEntry.TrStop': 'Śledź stop',
    'Web.mobile.active.OrderEntry.SlPrice': 'Cena zlecenia stop loss',
    'Web.mobile.active.OrderEntry.SlOffset': 'Odstęp stop loss',
    'Web.mobile.active.OrderEntry.TrSlOffset': 'Odstęp śledzenia stop loss',
    'Web.mobile.active.OrderEntry.SllPrice': 'Cena limitu stop loss',
    'Web.mobile.active.OrderEntry.SllOffset': 'Odstęp limitu stop loss',
    'Web.mobile.active.OrderEntry.TpPrice': 'Cena zlecenia take profit',
    'Web.mobile.active.OrderEntry.TpOffset': 'Odstęp take profit',
    'Web.mobile.active.Login.ExploreTheApp': 'Odkryj aplikację',
    'Web.mobile.active.InstrumentInfo.Price': 'Cena',
    'Web.mobile.active.InstrumentInfo.BreakEven': 'Punkt równowagi',
    'Web.mobile.active.Asset.SellExchange': 'Giełda',
    'Web.mobile.active.Asset.StartDayQtyMargin': 'Ilość SOD dla MA',
    'Web.mobile.active.Asset.LiquidityRate': 'Stopa płynności %',
    'Web.mobile.active.Asset.TodayTradedQty': 'Ilość intraday',
    'Web.mobile.active.Asset.AvailableQtySell': 'Dostępna ilość do sprzedaży',
    'Web.mobile.active.FilledOrder.TradeVolume': 'Wolumen transakcji',
    'Web.mobile.active.FilledOrder.Date': 'Data',
    'Web.mobile.active.FilledOrder.GrossPnL': 'Z/S brutto',
    'Web.mobile.active.HistoryOrder.Date': 'Data',
    'Web.mobile.active.MamSummary.EmptyPlaceholder': 'Nie masz historii konta dla tego okresu',
    'Web.mobile.active.MamSummary.Account': 'Konto',
    'Web.mobile.active.MamSummary.OperationId': 'ID operacji',
    'Web.mobile.active.MamSummary.InstrumentName': 'Nazwa instrumentu',
    'Web.mobile.active.MamSummary.OpenPrice': 'Cena otwarcia',
    'Web.mobile.active.MamSummary.ClosePrice': 'Cena zamknięcia',
    'Web.mobile.active.MamSummary.Side': 'Strona',
    'Web.mobile.active.MamSummary.Quantity': 'Ilość',
    'Web.mobile.active.MamSummary.Buy': 'Kupno',
    'Web.mobile.active.MamSummary.Sell': 'Sprzedaż',
    'Web.mobile.active.Position.StopLoss': 'Stop loss',
    'Web.mobile.active.Position.TakeProfit': 'Take profit',
    'Web.mobile.active.Position.Date': 'Data',
    'Web.mobile.active.Order.StopLoss': 'Stop loss',
    'Web.mobile.active.Order.TrStopOffset': 'Trailing SL offset',
    'Web.mobile.active.Order.TrStopLoss': 'SL Trailing stop',
    'Web.mobile.active.Order.TakeProfit': 'Take profit',
    'Web.mobile.active.Order.QuantityFilled': 'Qty. filled',
    'Web.mobile.active.Search': 'Szukaj',
    'Web.mobile.active.CalendarPeriod.Today': 'Dziś',
    'Web.mobile.active.CalendarPeriod.LastWeek': 'Ostatni tydzień',
    'Web.mobile.active.CalendarPeriod.Last1M': 'Ostatni 1M',
    'Web.mobile.active.CalendarPeriod.Last3M': 'Ostatni 3M',
    'Web.mobile.active.CalendarPeriod.Last6M': 'Ostatni 6M',
    'Web.mobile.active.InstrumentDetails.6.SessionInfo': 'Harmonogram',
    'Web.mobile.active.OrderModify.Confirmation.Title.Modify': 'Modyfikacja zlecenia',
    'Web.mobile.active.OrderModify.Confirmation.Title.Cancel': 'Anulowanie zlecenia',
    'Web.mobile.active.OrderModify.Confirmation.Title.ExecuteAtMarket': 'Wykonać zlecenie na rynku?',
    'Web.mobile.active.OrderModify.Error.SLSmallerMin': 'Stop Loss jest niższy niż możliwa najniższa wartość',
    'Web.mobile.active.OrderModify.Error.SLGreaterMax': 'Stop Loss jest wyższy niż możliwa najwyższa wartość',
    'Web.mobile.active.OrderModify.Error.TPSmallerMin': 'Take Profit jest niższy niż możliwa najniższa wartość',
    'Web.mobile.active.OrderModify.Error.TPGreaterMax': 'Take Profit jest wyższy niż możliwa najwyższa wartość',
    'Web.mobile.active.PositionModify.Confirmation.Title': 'Modyfikacja pozycji',
    'Web.mobile.active.PositionClose.Confirmation.Lots': 'loty',
    'Web.mobile.active.Lists': 'Listy',
    'Web.mobile.active.AccountDetails.Groups.1.AccountSummary': 'Podsumowanie konta',
    'Web.mobile.active.AccountDetails.Groups.4.TodayResult': 'Wynik dzisiejszy',
    'Web.mobile.active.AccountDetails.MarginUsedPercent': 'Wymagany margines początkowy %',
    'Web.mobile.active.AccountDetails.OptionPremiumReq': 'Wymagania dotyczące premii opcji',
    'Web.mobile.active.AccountDetails.OpenGrossPL': 'Otwarty zysk/strata brutto',
    'Web.mobile.active.AccountDetails.OpenPostionsNumber': 'Otwarte pozycje',
    'Web.mobile.active.AccountDetails.OpenOrdersNumber': 'Pracujące zlecenia',
    'Web.mobile.active.AccountDetails.TodaysNetProfit': 'Dzisiejszy zysk netto',
    'Web.mobile.active.Chart.IndicatorsMenu.AddedIndicators': 'Dodane wskaźniki',
    'Web.mobile.active.Chart.Menu.ChartSyle': 'Styl wykresu',
    'Web.mobile.active.Chart.Menu.Settings': 'Ustawienia',
    'Web.mobile.active.Chart.NoDataAvailable': 'No data available for {0}',
    'Web.mobile.active.TrailingStopLoss': 'Tr.',
    'Web.mobile.active.Position.GrossPnL': 'Z/S brutto',
    'Web.mobile.active.signIn.error.emptyLogin': 'Pole "Login" jest puste',
    'Web.mobile.active.signIn.error.emptyPassword': 'Pole "Hasło" jest puste',
    'Web.mobile.active.Table.Total.GrossPnL': 'Total Gross P/L',
    'timeSelector.Hours': 'Godzina',
    'timeSelector.Minutes': 'Minuta'
};

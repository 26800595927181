import { WorkspaceDataInfo } from './WorkspaceInfoResult';

export class WorkSpaceMetaData extends WorkspaceDataInfo {
    public fromServer: boolean = false;
    public needLoad: boolean = false;
    public isActive: boolean = false;

    public FillByDataFromServer (data: WorkspaceDataInfo, isActive: boolean): void {
        this.ws_id = data.ws_id;
        this.name = data.name;
        this.updateTimeSpan = data.updateTimeSpan;
        this.isActive = isActive;
    }
}

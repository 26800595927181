// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Periods } from '../../../Utils/History/TFInfo';
import { TvBar } from '../TradingViewPrimitives/TvBar';
import { BaseInterval } from '../../../Utils/History/BaseInterval';
import { type Bar } from '../charting_library';

export class TvHistoryConvertor {
    private convertToTvBarWithTime (baseInterval, bTimeInTicks, period, timeOffset: number, timeZone: string): Bar {
        const [barTimeInTicks, barTime] = this.correctBartime(bTimeInTicks, period, timeOffset, timeZone);
        return this.createTvBarFromBaseInterval(baseInterval, barTimeInTicks, barTime, period);
    }

    private createTvBarFromBaseInterval (baseInterval, barTimeInTicks, barTime, period: number): Bar {
        const { Data, Volume } = baseInterval;
        const tvBar = new TvBar();
        tvBar.time = barTimeInTicks;
        tvBar.date = barTime;
        tvBar.open = Data[BaseInterval.OPEN_INDEX];
        tvBar.close = Data[BaseInterval.CLOSE_INDEX];
        tvBar.high = Data[BaseInterval.HIGH_INDEX];
        tvBar.low = Data[BaseInterval.LOW_INDEX];
        if (period === Periods.TIC) {
            tvBar.high = tvBar.open >= tvBar.close ? tvBar.open : tvBar.close;
            tvBar.low = tvBar.open >= tvBar.close ? tvBar.close : tvBar.open;
        }

        if (Volume && Volume > 0 && !isNaN(Volume)) {
            tvBar.volume = Volume;
        }

        return tvBar;
    }

    convertBaseIntervalsToTvBars (baseIntervals, period, timeOffset: number, timeZone: string): Bar[] {
        const tvBars: Bar[] = [];
        for (const baseInterval of baseIntervals) {
            const tvBar = this.convertToTvBar(baseInterval, period, timeOffset, timeZone);
            const length = tvBars.length;
            if (length > 0 && tvBar.time === tvBars[length - 1].time) {
                continue; // если история битая
            }

            tvBars.push(tvBar);
        }

        return tvBars;
    }

    convertToTvBar (baseInterval, period, timeOffset: number, timeZone: string): Bar {
        const barTimeInTicks = baseInterval.FLeftTimeTicks;
        return this.convertToTvBarWithTime(baseInterval, barTimeInTicks, period, timeOffset, timeZone);
    }

    private correctBartime (barTimeInTicks, period, timeOffset: number, timeZone: string): [number, Date] {
        const barTime = new Date(barTimeInTicks);
        if (period < Periods.DAY || period % Periods.SECOND === 0) {
            return [barTimeInTicks, barTime];
        }

        barTime.setMinutes(barTime.getMinutes() - timeOffset);

        const correctedBarTimeInTicks = Date.UTC(
            barTime.getFullYear(),
            barTime.getMonth(),
            barTime.getDate());

        const correctedDate = new Date(correctedBarTimeInTicks);

        // const utcDate = DateTime.fromMillis(barTimeInTicks, { zone: 'UTC' });
        // const dateInTimeZone = utcDate.setZone(timeZone);
        // const dateInTimeZoneWithOffset = dateInTimeZone.plus({ minutes: timeOffset });

        // barTime.setMinutes(barTime.getMinutes() - timeOffset);

        // const correctedBarTimeInTicks = Date.UTC(
        //     dateInTimeZoneWithOffset.year,
        //     dateInTimeZoneWithOffset.month - 1,
        //     dateInTimeZoneWithOffset.day);

        // const correctedDate = new Date(correctedBarTimeInTicks);

        return [correctedBarTimeInTicks, correctedDate];
    }
}

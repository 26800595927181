// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectReloadHistoryMessage extends DirectMessageBaseClass {
    constructor () {
        super(Message.CODE_HISTORY_CHANGED_RANGE);
    }

    public instrumentTradableID: number | null = null;
    public instrumentId: number | null = null;
    public routeID: number | null = null;
    public historyPeriod: number | null = null;
    public barsType: number | null = null;
}

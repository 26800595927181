// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CustomEvent } from '../../Utils/CustomEvents';
import { TvChartSupportedResolutionArray } from './TradingViewPrimitives/TvEnums';
import { type IDatafeedChartApi, type LibrarySymbolInfo, type ResolveCallback, type SearchSymbolResultItem, type SymbolResolveExtension, type ErrorCallback, type IDatafeedQuotesApi, type QuotesCallback, type QuotesErrorCallback, type DOMCallback, type ResolutionString, type IDestroyable } from './charting_library';
import { DataCache } from '../DataCache';
import { TvSymbolConvertor } from './Convertors/TvSymbolConvertor';
import { TvHistoryManager } from './Managers/TvHistoryManager';
import { TvSearchSymbolManager } from './Managers/TvSearchSymbolManager';
import { type Instrument } from '../cache/Instrument';
import { TvQuoteManager } from './Managers/TvQuoteManager';
import { TvInteriorIdCache } from './Caches/TvInteriorIdCache';
import { TvDomManager } from './Managers/TvDomManager';
import { TvExchangesManger } from './Managers/TvExchangesManger';
import { TvInstrumentTypesManager } from './Managers/TvInstrumentTypesManager';
import { TvHistoryResolutionHelper } from './Helpers/TvHistoryResolutionHelper';
import { TvHistoryAggregationsHelper } from './Helpers/TvHistoryAggregationsHelper';

export class TvDataFeed implements IDatafeedChartApi, IDatafeedQuotesApi, IDestroyable {
    public onReadyEvent = new CustomEvent();
    private readonly historyManager = new TvHistoryManager();
    private readonly quoteManager = new TvQuoteManager();
    private readonly searchSymbolManager: TvSearchSymbolManager = new TvSearchSymbolManager();
    private readonly domManager: TvDomManager = new TvDomManager();

    // #region TradingView DataFeed interface implementation
    public onReady (callback/*: OnReadyCallback */) {
        setTimeout(() => {
            const symbolGrouping =
            {
                futures: new RegExp('^\\[?(/[A-Z0-9_]+)[A-Z]\\d{2}\\]?$') // TODO: ASK SUPPORT
            };

            const configuration = // : DatafeedConfiguration =
            {
                supported_resolutions: TvHistoryAggregationsHelper.getSupportedResolutions(),
                exchanges: TvExchangesManger.getExchanges(),
                symbols_types: TvInstrumentTypesManager.getInstrumentTypes(),
                symbols_grouping: symbolGrouping
            };
            callback(configuration);
            this.onReadyEvent.Raise();
        }, 0);
    }

    async searchSymbols (userInput: string, exchange: string, symbolType: string, onResultReadyCallback) {
        const filteredList = await this.searchSymbolManager.searchSymbols(userInput, exchange, symbolType);
        const result: SearchSymbolResultItem[] = [];
        for (const instrument of filteredList) {
            const resultItem = TvSymbolConvertor.convertToTvSearchSymbolResultItem(instrument);
            result.push(resultItem);
        }
        onResultReadyCallback(result);
    }

    public resolveSymbol (symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback, extension: SymbolResolveExtension): void {
        try {
            setTimeout(() => {
                if (TvSymbolConvertor.isFakeSymbolName(symbolName)) // кейс, когда резолвится скрытый инпут индикатора
                {
                    const fakeInfo: LibrarySymbolInfo = TvSymbolConvertor.getFakeSymbol();
                    onResolve(fakeInfo);
                    return;
                }

                const interiorId = TvInteriorIdCache.getInteriorId(symbolName);
                const instrument = DataCache.getInstrumentByName(interiorId);
                if (!instrument) {
                    onError(`[TvDataFeed::resolveSymbol]: Instrument ${symbolName} not found`);
                    return;
                }
                const symbolInfo = TvSymbolConvertor.convertToTvSymbol(instrument, extension);
                onResolve(symbolInfo);
            }, 0); // Datafeed.resolveSymbol must be async: https://www.tradingview.com/charting-library-docs/latest/connecting_data/Datafeed-API/#asynchronous-callbacks
        } catch (err) { onError(err.message); }
    }

    async getBars (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
        await this.historyManager.getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback);
    }

    subscribeBars (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        this.historyManager.subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback);
    }

    unsubscribeBars (subscriberUID) {
        this.historyManager.unsubscribeBars(subscriberUID);
    }
    // #endregion TradingView DataFeed interface implementation

    setCurrentAccount (account) {
        this.historyManager.setCurrentAccount(account);
        this.quoteManager.setCurrentAccount(account);
        this.domManager.setCurrentAccount(account);
    }

    public getSymbolByName (nameWithExchange: string): Instrument | null {
        return this.searchSymbolManager.getSymbolByName(nameWithExchange);
    }

    // #region IDatafeedQuotesApi - works only for trading platform

    getQuotes (symbols: string[], onDataCallback: QuotesCallback, onErrorCallback: QuotesErrorCallback): void {
        this.quoteManager.getQuotes(symbols, onDataCallback, onErrorCallback);
    }

    subscribeQuotes (symbols: string[], fastSymbols: string[], onRealtimeCallback: QuotesCallback, listenerGUID: string): void {
        this.quoteManager.subscribeQuotes(symbols, fastSymbols, onRealtimeCallback, listenerGUID);
    }

    unsubscribeQuotes (listenerGUID: string): void {
        this.quoteManager.unsubscribeQuotes(listenerGUID);
    }
    // #endregion

    // #region  Level2

    public subscribeDepth (symbol: string, callback: DOMCallback): string {
        return this.domManager.subscribeDepth(symbol, callback);
    }

    public unsubscribeDepth (subscriberUID: string): void {
        this.domManager.unsubscribeDepth(subscriberUID);
    }
    // #endregion

    // #region IDestroyable

    destroy (): void {
        this.searchSymbolManager.dispose();
        this.historyManager.dispose();
    }
    // #endregion IDestroyable
}

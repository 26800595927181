// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraPanel } from './TerceraPanel';
import { TerceraAutoHidePanelTemplate } from '../../templates';
import { MathUtils } from '../../Utils/MathUtils';
import { contextMenuHandler } from '../../Utils/AppHandlers';
import { type IContextMenuItem } from '../UtilsClasses/IContextMenuItem';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';

export class TerceraAutoHidePanel extends TerceraPanel {
    private _observer: ResizeObserver;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): string { return ControlsTypes.TerceraAutoHidePanel; }

    public override oncomplete (): void {
        this.onMoreButtonClick = this.onMoreButtonClick.bind(this);
        super.oncomplete();
        this.subscribe();
    }

    public override dispose (): void {
        this.unsubscribe();
        super.dispose();
    }

    private subscribe (): void {
        this._observer = new ResizeObserver(() => {
            const hiddenElementsCountExcludingSeparators = this.getHiddenElementsCountExcludingSeparators();
            void this.set('isShowMoreButton', hiddenElementsCountExcludingSeparators > 0);
        });

        const container = super.find('.auto-hide-panel-container');
        if (!MathUtils.IsNullOrUndefined(container)) {
            this._observer.observe(container);
        }

        this.on('onMoreButtonClicked', this.onMoreButtonClick);
    }

    private unsubscribe (): void {
        const container = this.find('.auto-hide-panel-container');
        if (!MathUtils.IsNullOrUndefined(container)) {
            this._observer.unobserve(container);
        }
        this.off('onMoreButtonClicked', this.onMoreButtonClick);
    }

    private onMoreButtonClick (): void {
        const contextMenuItems = this.getContextMenuItemsFromHiddenElements();
        const rect = this.findAll('.terceraButton.js-more-button')[0].getBoundingClientRect();
        contextMenuHandler.Show(contextMenuItems, rect.left, rect.bottom);
    }

    private getHiddenElements (): HTMLElement[] {
        const hiddenElements: HTMLElement[] = [];
        const container = this.find('.auto-hide-panel-container');
        if (MathUtils.IsNullOrUndefined(container)) {
            return hiddenElements;
        }
        for (let i = 0; i < container.children.length; i++) {
            const child: HTMLElement = container.children[i] as HTMLElement;
            if (child.offsetTop > container.offsetHeight + container.offsetTop) {
                hiddenElements.push(child);
            }
        }
        return hiddenElements;
    }

    private getContextMenuItemsFromHiddenElements (): IContextMenuItem[] {
        const hiddenElements = this.getHiddenElements();
        const contextMenuItems: IContextMenuItem[] = [];

        for (const element of hiddenElements) {
            const component = (element as any)._ractive?.proxy?.ractive;
            if (!MathUtils.IsNullOrUndefined(component?.getContextMenuItem)) {
                const contextMenuItem: IContextMenuItem = component.getContextMenuItem();
                if (!MathUtils.IsNullOrUndefined(contextMenuItem)) {
                    contextMenuItems.push(contextMenuItem);
                }
            }
        }

        return contextMenuItems;
    }

    private getHiddenElementsCountExcludingSeparators (): number {
        const contextMenuItems = this.getContextMenuItemsFromHiddenElements();
        const filteredItems = contextMenuItems.filter(item => !item.separator);
        return filteredItems.length;
    }
}

TerceraPanel.extendWith(TerceraAutoHidePanel, {
    template: TerceraAutoHidePanelTemplate,
    data: function () {
        return {
            isShowMoreButton: false
        };
    }
});

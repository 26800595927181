// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Control } from './Control';
import { AccountsComboBoxTemplate } from '../../templates';
import { AccountMenuItem } from '../../Commons/AccountWidget/AccountMenuItem';
import { AccountMenuItemsHelper } from '../../Commons/AccountWidget/AccountMenuItemsHelper';
import { type Account } from '../../Commons/cache/Account';
import { type IContextMenuItem } from '../UtilsClasses/IContextMenuItem';
import { Resources } from '../../Commons/properties/Resources';
import { type TerceraAccountLookup } from './Lookup/TerceraAccountLookup';
import { type AccountSelector } from './AccountSelector';

export class AccountsComboBox extends Control {
    private static readonly WIDTH = 206;
    constructor () {
        super();
        this.onDropDownVisibleChanged = this.onDropDownVisibleChanged.bind(this);
        this.onAccountChanged = this.onAccountChanged.bind(this);
    }

    public override getType (): string { return 'AccountsComboBox'; }

    public override oncomplete (): void {
        super.oncomplete();
        void this.populateAsync().then(() => {
            this.observe('showItems', this.onDropDownVisibleChanged);
            this.observe('selectedItem', this.onAccountChanged);
        });
    }

    public override onMouseDown (event): void {
        super.onMouseDown(event);
        this.showItems();
    }

    public override lostFocus (): void {
        super.lostFocus();
        void this.set({ showItems: false });
    }

    public override getContextMenuItem (): IContextMenuItem {
        return {
            text: Resources.getResource('accountsLookup.ContextMenuItem'),
            style: 'js-button-accountsLookup',
            event: () => {
                const accLookup = (this.parent as AccountSelector)?.getLookup();
                if (accLookup != null) {
                    accLookup.showForm(this.getX(), this.getY());
                }
            }
        };
    }

    private changeSelection (newSelected: AccountMenuItem): void {
        const accMenuItems: AccountMenuItem[] = this.get('accMenuItems');
        for (const item of accMenuItems) { item.Selected = item.equal(newSelected); }
        void this.set({ accMenuItems });
    }

    private accountItemClick (item: AccountMenuItem): boolean {
        if (isNullOrUndefined(item)) {
            return;
        }
        void this.set({
            selectedItem: item.GetAccount(),
            showItems: false
        });
        return false; // cancelBubble
    }

    protected showItems (): void {
        const isShown: boolean = this.get('showItems');
        if (isShown) {
            void this.set({ showItems: false });
            return;
        }
        const myEl = this.find('.js-accountsComboBox');
        if (isNullOrUndefined(myEl)) {
            return;
        }
        const dropDownPosition = this.calculateDropdownPosition(myEl);
        void this.set({ dropdownLeft: dropDownPosition.left, dropdownTop: dropDownPosition.top, showItems: true });
    }

    private calculateDropdownPosition (myEl: HTMLElement): { left: number, top: number } {
        const isPosAbsolute: boolean = this.get('isPosAbsolute');
        let leftPos = isPosAbsolute ? myEl.clientLeft : myEl.offsetLeft;
        let topPos = isPosAbsolute ? myEl.clientTop : myEl.offsetTop;
        const height = isPosAbsolute ? myEl.clientHeight : myEl.offsetHeight;
        const rect = myEl.getBoundingClientRect();
        const overflowRight = rect.left + AccountsComboBox.WIDTH - window.innerWidth;

        if (overflowRight > 0) {
            leftPos -= overflowRight;
        }

        topPos = topPos + height + 1;
        return { left: leftPos, top: topPos };
    }

    protected onDropDownVisibleChanged (newValue: boolean, oldValue: boolean): void { /* Virutal */ }

    protected onAccountChanged (newAcc: Account, oldAcc: Account): void {
        if (isNullOrUndefined(newAcc) || newAcc === oldAcc) {
            return;
        }
        const item = new AccountMenuItem(newAcc);
        this.changeSelection(item);
        void this.set({ selectedItemView: item });
    }

    protected async populateAsync (): Promise<void> {
        const Data = AccountMenuItemsHelper.GetDataToFillOut(this.get('selectedItem'));
        await this.set({ accMenuItems: Data.Items, onlyOwns: Data.IsOnlyOwnAccounts, addtype: Data.IsAddType });
    }
}

Control.extendWith(AccountsComboBox, {
    template: AccountsComboBoxTemplate,
    data: function () {
        return {
            dropdownLeft: 0,
            dropdownTop: 0,
            isPosAbsolute: false,
            addtype: true,
            showItems: false,
            selectedItem: null,
            selectedItemView: null,
            accMenuItems: []
        };
    }
});

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { DataCacheToolType } from '../DataCacheToolEnums';
import { BaseFibonacciDataCacheTool } from './BaseFibonacciDataCacheTool';
import { DynProperty } from '../../DynProperty';

export class FibonacciTimeGoalDataCacheTool extends BaseFibonacciDataCacheTool {
    /**
     * Constructor for FibonacciTimeGoalDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.FibonacciTimeGoal;
        this.InitPoints(2);
    }

    /**
     * Initializes the block for the tool
     */
    protected InitBlock (): void {
        super.InitBlock();
        this.fibLevel = [61.8, 100, 161.8, 261.8, 423.6, 685.4];
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 2;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 2;
    }

    /**
     * Gets the properties for the tool
     * @returns {DynProperty[]} - Array of DynProperty objects
     */
    Properties (): DynProperty[] {
        const props = super.Properties();

        let prop = new DynProperty(Resources.getResource('EnableNegativeFibonacciValue'), true, DynProperty.BOOLEAN, DynProperty.FIBONACCI_PARAMETERS_GROUP);
        props.push(prop);

        prop = new DynProperty('chart.tools.fibretr.fontcolor', this.FontColor, DynProperty.COLOR, DynProperty.VISUAL_GROUP);
        props.push(prop);

        return props;
    }

    /**
     * Callback function for updating tool properties
     * @param {DynProperty[]} properties - Array of DynProperty objects
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const prop = DynProperty.getPropertyByName(properties, 'chart.tools.fibretr.fontcolor');
        if (prop != null) this.FontColor = prop.value;
    }
}

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectReloadHistoryMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { HistoryTypeConverter } from '../../../../Utils/History/HistoryTypeConverter';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { MessageProcessorBase } from './MessageProcessorBase';

export class HistoricalDataMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder?) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectReloadHistoryMessage[] {
        const message = new DirectReloadHistoryMessage();

        message.instrumentTradableID = fieldSet.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
        message.instrumentId = fieldSet.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID);
        message.routeID = fieldSet.GetValue(FieldsFactory.FIELD_ROUTE_ID);
        message.historyPeriod = fieldSet.GetValue(FieldsFactory.FIELD_HISTORY_PERIOD_TYPE);
        message.barsType = HistoryTypeConverter.GetLocalHistoryType(fieldSet.GetValue(FieldsFactory.FIELD_BARS_TYPE));
        return [message];
    }
}

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { HistoryType } from '../../Utils/History/HistoryType';
import { TimeSpan, TimeSpanFormat } from '../../Utils/Time/TimeSpan';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { MathUtils } from '../../Utils/MathUtils';
import { LOCALE_EN, Resources } from '../properties/Resources';
import { CustomEvent } from '../../Utils/CustomEvents';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { QuotingType } from '../../Utils/Instruments/QuotingType';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { PriceFormatter } from '../../Utils/Instruments/PriceFormatter';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { type Instrument } from './Instrument';
import { type Asset } from './Asset';
import { type Account } from './Account';

export class Level1Calculator {
    public instrument: Instrument | null;
    public DataCache: any;
    public UseOnlyLast = false;
    public lastQuote = null;
    public lastQuote1Message = null;
    // Trade
    public lastQuote3Message = null;
    public lastInstrumentDayBarMessage = null;
    public lastInstrumentPricesMessage = null;
    public mainCloseQuote = null;
    private lastPrQuote: any = null;
    // Время последнего апдейта инструмента
    public RealLastUpdateTime = DateTimeUtils.DateTimeUtcNowTicks();
    // TODO. Rename.
    public lastMessageTime: Date = DateTimeUtils._ZeroTime;

    public bidSize = NaN;
    public askSize = NaN;
    public lastSize = NaN;
    public change = NaN;
    public changePercent = NaN;
    public indicativeAuctionPrice = NaN;
    public indicativeAuctionVolume = NaN;

    private referencePrice = NaN;

    private tradedValue = NaN;
    public offExchangeVolume = NaN;
    private offExchangeValue = NaN;

    public auctionEndTime: any = null;
    public remainingQty: any = null;
    public remainingQtySide: any = null;

    public baseAsset: Asset | null = null;

    private cbAuctionStart: any = null;

    public PrewLastTradedTime: any = null; // #93674

    public sessionFlagForLast: any = null; // #100139

    public LPPHigh: any = null; // #113113
    public LPPLow: any = null;
    public FundingRateValue = NaN;
    public MarkPrice = NaN;
    public NextFundingTimeUTC = new Date(1970, 1, 1);

    public openInterest = NaN;

    // TODO. UGLY. Key can be changed by server.
    public NormalSizeKey = Resources.getResourceLang(
        'Instrument.Level1.ExtendedFields.NormalMarketSize',
        LOCALE_EN);

    public OnCbAuctionStartChanged = new CustomEvent();

    constructor (instrument) {
        this.instrument = instrument;
        this.DataCache = instrument.DataCache;
    }

    public getBaseAsset (): Asset | null {
        if (!this.baseAsset) {
            this.baseAsset = this.DataCache.GetAssetByName(this.DataCache.baseCurrency);
        }

        return this.baseAsset;
    }

    get lastPriceQuote (): any {
        return this.lastPrQuote;
    }

    set lastPriceQuote (value) {
        this.lastPrQuote = value;
        this.setLastTime(value);
    }

    get getLastMessageTime (): Date {
    // Attentive!!!
        return this.lastMessageTime;
    }

    get getNormalMarketSize (): any {
        const addFields = this.instrument.InstrumentAdditionalInfo;
        return addFields?.hasOwnProperty(this.NormalSizeKey)
            ? addFields[this.NormalSizeKey]
            : Level1Calculator.NAString;
    }

    get getReferencePrice (): number {
        return this.referencePrice;
    }

    get strReferencePrice (): string {
        return this.getFormatPrice(this.referencePrice);
    }

    get getTradedValue (): number {
        return this.tradedValue;
    }

    get strTradedValue (): string {
        if (isNaN(this.tradedValue)) {
            return Level1Calculator.NAString;
        }

        const baseAsset = this.getBaseAsset();
        return baseAsset
            ? baseAsset.formatPrice(this.tradedValue)
            : this.getFormatPrice(this.tradedValue);
    }

    get getOffExchangeValue (): number {
        return this.offExchangeValue;
    }

    get strOffExchangeValue (): string {
        if (isNaN(this.offExchangeValue)) {
            return Level1Calculator.NAString;
        }

        const baseAsset = this.getBaseAsset();
        return baseAsset
            ? baseAsset.formatPrice(this.offExchangeValue)
            : this.getFormatPrice(this.offExchangeValue);
    }

    public getOffExchangeVolume (showLots): number {
        return this.getDoubleVolume(this.offExchangeVolume, showLots);
    }

    public strOffExchangeVolume (showLots): string {
        return this.getFormatVolume(this.offExchangeVolume, showLots);
    }

    public strCbAuctionStart (sett): any {
        if (!this.cbAuctionStart) {
            return Level1Calculator.NAString;
        }

        return DateTimeUtils.FormatToTime(this.cbAuctionStart);
    }

    get CbAuctionStart (): Date | null {
    // Attentive!!!
        return this.cbAuctionStart
            ? this.cbAuctionStart
            : null;
    }

    set CbAuctionStart (value) {
        this.cbAuctionStart = value ? new Date(value) : null;
        this.OnCbAuctionStartChanged.Raise();
    }

    get strAuctionEndTime (): string {
        return this.auctionEndTime && this.auctionEndTime !== Level1Calculator.NAString
            ? this.auctionEndTime
            : Level1Calculator.NAString;
    }

    get strRemainingQty (): string {
        return this.remainingQty ? this.remainingQty : Level1Calculator.NAString;
    }

    get strRemainingQtySide (): string {
        return this.remainingQtySide !== null ? OrderUtils.getBuySellStr(this.remainingQtySide) : Level1Calculator.NAString;
    }

    public getLastSessionFlag (): any // #100139
    {
        return this.sessionFlagForLast;
    }

    public getBidAskSessionFlag (): any // #100139
    {
        const quote1Msg = this.lastQuote1Message;
        if (!quote1Msg) {
            return null;
        }

        return quote1Msg.SessionFlag;
    }

    public GetLastUpdate (): string {
        const millisDelta = DateTimeUtils.DateTimeUtcNowTicks() - this.RealLastUpdateTime;
        const secondsValue = TimeSpan.toSeconds(millisDelta);
        let result = '';

        if (secondsValue > 0) {
            if (secondsValue < 60) {
                result = secondsValue + ' sec';
            } else {
                result = Math.floor(secondsValue / 60) + ' min';
            }
        }

        return result;
    }

    public setLastTime (msg): void {
        if (!msg) return;

        // TODO. Correct?
        const newLastMsgTime = new Date(
            msg.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR
                ? msg.LastTime
                : msg.cTime);

        if (newLastMsgTime.getTime() - DateTimeUtils._ZeroTime.getTime() !== 0) {
            this.lastMessageTime = newLastMsgTime;
            this.sessionFlagForLast = msg.SessionFlag;
        }
    }

    // TODO. Shitty conditions. Are conditions correct?
    public GetLastPrice (account?: Account, withoutSpread = false): number {
        let lastPrice = NaN;
        const instrument = this.instrument;
        const lastPriceQuote = this.lastPriceQuote;
        const lastQuote3Message = this.lastQuote3Message;
        const lastInstrumentDayBarMessage = this.lastInstrumentDayBarMessage;
        const spreadPlan = withoutSpread ? null : this.DataCache.GetSpreadPlan(account);
        let getLastPriceIDBMByTime = false;

        if (lastInstrumentDayBarMessage && lastPriceQuote) {
            getLastPriceIDBMByTime = +lastPriceQuote.IncomeQuoteTime < +lastInstrumentDayBarMessage.IncomeQuoteTime;
        }

        if (!lastPriceQuote) {
            if (instrument.HistoryType === HistoryType.ASK) {
                lastPrice = this.GetAsk(account, withoutSpread);
            } else if (instrument.HistoryType === HistoryType.BID) {
                lastPrice = this.GetBid(account, withoutSpread);
            }
            return lastPrice;
        }
        // LastPrice is Quote1Message перенесли в InstrumentDayPriceMessage,
        // но убирать этот код нельзя, потому что
        // когда приходят Quote4Message они преобразовываются в Quote1Message
        // и для опционов и фьчерсов в не зависимости от настройки Build bars from...
        // мы всегда берем неспредированное значение LastPice из мессаджа (Tополь А.Ю.)
        // Для не опционов и фьючерсов мы всегда смотрим по чему строятся бары
        // и если они строятся по биду или по аску, то берем бид или аск
        // или спредированное значение бида или аска, если есть спред
        // Итак, мы всегда берем Last из InstrumentDayPricesMessage
        // и Quote3Message (в не зависимости от HistoryType)
        // а из Quote1Message только в том случае если
        // до этого не было IDPM и Q3M или в Q1M есть поле LastPice
        // (оно может быть если приходят Q4M, которые затем преобразовываются в Q1M)
        if (lastPriceQuote.Type === HistoryType.QUOTE_LEVEL1 &&
        !instrument.InstrumentDayBarMessageUpdateMode) {
            const lastPriceQuote1Message = lastPriceQuote;
            if (!lastQuote3Message &&
            (
                !lastInstrumentDayBarMessage ||
                !this.UseOnlyLast &&
                (
                    lastInstrumentDayBarMessage.LastPrice === null ||
                    isNaN(lastInstrumentDayBarMessage.LastPrice)
                )
            ) ||
            lastPriceQuote1Message.LastPice !== null) {
                if (lastPriceQuote1Message.LastPice !== null &&
                (
                    (
                        lastPriceQuote1Message.LastPice !== 0 &&
                        instrument.HistoryType !== HistoryType.QUOTE_LEVEL1 &&
                        instrument.HistoryType !== HistoryType.QUOTE_ASK
                    ) ||
                    instrument.isFutureOrOption()
                )) {
                    lastPrice = lastPriceQuote1Message.LastPice;
                } else if (
                    instrument.HistoryType !== HistoryType.QUOTE_TRADES &&
                !instrument.isFutureOrOption()) {
                    if (
                        instrument.HistoryType === HistoryType.QUOTE_ASK &&
                    !isNaN(lastPriceQuote1Message.Ask)) {
                        lastPrice = lastPriceQuote1Message.AskSpread_SP_Ins(spreadPlan, instrument);
                    } else if (!isNaN(lastPriceQuote1Message.Bid)) {
                        lastPrice = lastPriceQuote1Message.BidSpread_SP_Ins(spreadPlan, instrument);
                    }
                }
            }
        } else if (
            lastPriceQuote.Type === HistoryType.QUOTE_TRADES &&
        !instrument.InstrumentDayBarMessageUpdateMode) {
            lastPrice = lastPriceQuote.Price;
        }
        if (lastPriceQuote.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR || getLastPriceIDBMByTime) {
            if (lastPriceQuote.LastPrice !== null &&
            !isNaN(lastPriceQuote.LastPrice)) { lastPrice = lastPriceQuote.LastPrice; }
        }

        return lastPrice;
    }

    public GetLastPriceByDefaultChartHistoryType (account?: Account, withoutSpread = false): number {
        switch (this.instrument.DefaultChartHistoryType) {
        case HistoryType.ASK:
            return this.GetAsk(account, withoutSpread);
        case HistoryType.BID:
            return this.GetBid(account, withoutSpread);
        case HistoryType.LAST:
            return this.GetLastPrice(account, withoutSpread);
        default:
            return this.GetLastPrice(account, withoutSpread);
        }
    }

    public GetLastSize (showLots): number {
        return this.getDoubleVolume(this.lastSize, showLots);
    }

    public NewQuote (msg): void {
        if (!msg) {
            return;
        }

        this.lastQuote = msg;

        const type = msg.Type;
        if (type === HistoryType.QUOTE_LEVEL1) {
            this.lastQuote1Message = msg;
        } else if (type === HistoryType.QUOTE_TRADES) {
            this.lastQuote3Message = msg;
        } else if (type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR) {
            this.lastInstrumentDayBarMessage = msg;
        } else if (type === HistoryType.QUOTE_INSTRUMENT_PRICES) {
            this.lastInstrumentPricesMessage = msg;
        }

        this.Recalculate(msg);
    }

    public Recalculate (message): void {
        const instrument = this.instrument;
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const quote1Message = message.Type === HistoryType.QUOTE_LEVEL1
            ? message
            : null;

        const quote3Message = message.Type === HistoryType.QUOTE_TRADES
            ? message
            : null;

        const instrumentDayBarMessage = message.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR
            ? message
            : null;

        const instrumentPricesMessage = message.Type === HistoryType.QUOTE_INSTRUMENT_PRICES
            ? message
            : null;

        // Time. TODO. UTC or Local?
        if (quote1Message || quote3Message) {
            this.RealLastUpdateTime = DateTimeUtils.DateTimeUtcNowTicks();
        }

        const lastQuote1Message = this.lastQuote1Message;
        if (lastQuote1Message) {
        // AskSize.
            if (!isNaN(lastQuote1Message.AskSize)) {
                this.askSize = lastQuote1Message.AskSize;
            } else if (lastQuote1Message.LastSize !== null) {
                this.askSize = lastQuote1Message.LastSize;
            } else {
                this.askSize = NaN;
            }
            // BidSize.
            if (!isNaN(lastQuote1Message.BidSize)) {
                this.bidSize = lastQuote1Message.BidSize;
            } else if (lastQuote1Message.LastSize !== null) {
                this.bidSize = lastQuote1Message.LastSize;
            } else {
                this.bidSize = NaN;
            }
        }

        const lastQuote3Msg = this.lastQuote3Message;
        const lastInsDayBarMsg = this.lastInstrumentDayBarMessage;
        // LastPrice, LastSize.
        // мы не можем взять lastPrice, т.к. нам нужно спредированное значение,
        // а спред на аккаунте, поэтому мы просто запоминаем
        // из какого мессаджа нужно взять lastPrice
        if (
            quote1Message &&
        !instrument.InstrumentDayBarMessageUpdateMode &&
        !lastQuote3Msg && (
                !lastInsDayBarMsg || (
                    !this.UseOnlyLast && (
                        lastInsDayBarMsg.LastPrice === null ||
                    isNaN(lastInsDayBarMsg.LastPrice)
                    )
                )
            )
        ) {
            if (
                instrument.HistoryType !== HistoryType.QUOTE_TRADES &&
            !instrument.isFutureOrOption()
            ) {
                if (instrument.HistoryType === HistoryType.QUOTE_ASK) {
                    if (!isNaN(quote1Message.Ask)) {
                        this.lastPriceQuote = quote1Message;
                    }

                    const askSize = this.getAskSize(false);
                    if (!isNaN(askSize)) {
                        this.lastSize = askSize;
                    }
                } else {
                    if (!isNaN(quote1Message.Bid)) {
                        this.lastPriceQuote = quote1Message;
                    }

                    const bidSize = this.getBidSize(false);
                    if (!isNaN(bidSize)) {
                        this.lastSize = bidSize;
                    }
                }
            }
        } else if (quote3Message && !instrument.InstrumentDayBarMessageUpdateMode) {
            if (!isNaN(quote3Message.Price)) {
                this.lastPriceQuote = quote3Message;
            }
            if (!isNaN(quote3Message.Size)) {
                this.lastSize = quote3Message.Size;
            }
            if (!isNullOrUndefined(quote3Message.OpenInterest) && !isNaN(quote3Message.OpenInterest)) {
                this.openInterest = quote3Message.OpenInterest;
            }
        } else if (instrumentDayBarMessage) {
            if (!isNullOrUndefined(instrumentDayBarMessage.OpenInterest) && !isNaN(instrumentDayBarMessage.OpenInterest)) {
                this.openInterest = instrumentDayBarMessage.OpenInterest;
            }
            // hsa: обсудил с Димой Коваленко - мы мы ласт берем всегда из IDBM,
            // где 518 филд равен 1 (или отсутствует), во первых, он приходит туда самый  правильный,
            // во вторых сервер записывает его туда
            if (
                instrumentDayBarMessage.InstrumentBarType === null ||
            instrumentDayBarMessage.InstrumentBarType === HistoryType.QUOTE_TRADES
            ) {
                if (instrumentDayBarMessage.LastPrice !== null && !isNaN(instrumentDayBarMessage.LastPrice)) {
                    this.lastPriceQuote = instrumentDayBarMessage;
                }
                if (instrumentDayBarMessage.LastSize !== null && !isNaN(instrumentDayBarMessage.LastSize)) {
                    this.lastSize = instrumentDayBarMessage.LastSize;
                }
            }

            if (instrumentDayBarMessage.ReferencePrice !== null && !isNaN(instrumentDayBarMessage.ReferencePrice)) {
                this.referencePrice = instrumentDayBarMessage.ReferencePrice;
            }
        }

        if (instrumentPricesMessage) {
            if (instrumentPricesMessage.TradedValue) { this.tradedValue = instrumentPricesMessage.TradedValue; }

            if (instrumentPricesMessage.OffExchangeVolume) { this.offExchangeVolume = instrumentPricesMessage.OffExchangeVolume; }

            if (instrumentPricesMessage.OffExchangeValue) { this.offExchangeValue = instrumentPricesMessage.OffExchangeValue; }

            if (instrumentPricesMessage.AuctionEndTime) { this.auctionEndTime = instrumentPricesMessage.AuctionEndTime; }

            if (instrumentPricesMessage.RemainingQty) { this.remainingQty = instrumentPricesMessage.RemainingQty; }

            if (instrumentPricesMessage.RemainingQtySide != null) { this.remainingQtySide = instrumentPricesMessage.RemainingQtySide; }
        }

        // MainClose.
        if (
            quote1Message && (
                quote1Message.BidMainClose !== null ||
            quote1Message.AskMainClose !== null)
        ) {
            this.mainCloseQuote = quote1Message;
        } else if (
            instrumentDayBarMessage && (
                instrumentDayBarMessage.TodayClosePrice !== null ||
            instrumentDayBarMessage.AskTodayClosePrice !== null)
        ) {
            this.mainCloseQuote = instrumentDayBarMessage;
        } else {
        // берем Close по LastPrice
            this.mainCloseQuote = null;
        }

        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        if (lastInstrumentPricesMessage) {
        // IndicativeAuctionPrice
            if (lastInstrumentPricesMessage.IndicativeAuctionPrice !== null) {
                this.indicativeAuctionPrice = lastInstrumentPricesMessage.IndicativeAuctionPrice;
            }
            // IndicativeAuctionVolume
            if (lastInstrumentPricesMessage.IndicativeAuctionVolume !== null) {
                this.indicativeAuctionVolume = lastInstrumentPricesMessage.IndicativeAuctionVolume;
            }
            // LPP High #113113
            if (lastInstrumentPricesMessage.LPPHigh !== null) {
                this.LPPHigh = lastInstrumentPricesMessage.LPPHigh;
            }
            // LPP Low #113113
            if (lastInstrumentPricesMessage.LPPLow !== null) {
                this.LPPLow = lastInstrumentPricesMessage.LPPLow;
            }

            if (lastInstrumentPricesMessage.FundingRateValue !== null) {
                this.FundingRateValue = lastInstrumentPricesMessage.FundingRateValue;
            }

            if (lastInstrumentPricesMessage.MarkPrice !== null) {
                this.MarkPrice = lastInstrumentPricesMessage.MarkPrice;
            }

            if (lastInstrumentPricesMessage.NextFundingTimeUTC !== null) {
                this.NextFundingTimeUTC = lastInstrumentPricesMessage.NextFundingTimeUTC;
            }
        }

        // Change
        if (instrumentDayBarMessage) {
            if (instrumentDayBarMessage.Change !== null) {
                this.change = instrumentDayBarMessage.Change;
            }
            if (instrumentDayBarMessage.ChangeInPercent !== null) {
                this.changePercent = instrumentDayBarMessage.ChangeInPercent;
            }
        }
    }

    public GetBid (account?: Account, withoutSpread = false): number {
        const lastQuote1Message = this.lastQuote1Message;
        let bidSpread = NaN;
        const spreadPlan = withoutSpread ? null : this.DataCache.GetSpreadPlan(account);

        if (lastQuote1Message /* TODO. Waiting for a chart implementation. && account */) {
            bidSpread = lastQuote1Message.BidSpread_SP_Ins(spreadPlan, this.instrument);
        }

        return bidSpread;
    }

    public GetAsk (account?, withoutSpread = false): number {
        const lastQuote1Message = this.lastQuote1Message;
        let askSpread = NaN;
        const spreadPlan = withoutSpread ? null : this.DataCache.GetSpreadPlan(account);

        if (lastQuote1Message /* TODO. Waiting for a chart implementation. && account */) {
            askSpread = lastQuote1Message.AskSpread_SP_Ins(spreadPlan, this.instrument);
        }

        return askSpread;
    }

    public getBidSize (showLots): number {
        return this.getDoubleVolume(this.bidSize, showLots);
    }

    public getAskSize (showLots): number {
        return this.getDoubleVolume(this.askSize, showLots);
    }

    public getDoubleVolume (volume, showLots): number {
        const ins = this.instrument;

        return ins && showLots && !isNaN(volume)
        // TODO. Use method?
            ? volume / ins.LotSize
            : volume;
    }

    // TODO. Spread plan correct value.
    public GetChange (account: Account): number {
        const change = this.change;
        if (!isNaN(change)) {
            return change;
        }

        // TODO. Remove all code below?
        const last = this.GetLastPrice(account);
        const bid = this.GetBid(account);
        const prevClose = this.GetPrevClose(account);

        if ((isNaN(last) && isNaN(bid)) || isNaN(prevClose)) {
            return null;
        }

        if (!isNaN(last)) {
            return last - prevClose;
        } else {
        // господа аналитики и Качур говорят
        // что - расчет Change по биду - это какая-то устаревшая логика,
        // но выпиливать ее пока не нужно
            return bid - prevClose;
        }
    }

    // TODO. Spread plan correct value.
    public GetChangePercent (account: Account): number {
        const changePercent = this.changePercent;
        if (!isNaN(changePercent)) {
            return changePercent;
        }

        // TODO. Remove all code below?
        const last = this.GetLastPrice(account);
        const bid = this.GetBid(account);
        const prevClose = this.GetPrevClose(account);

        if ((isNaN(last) && isNaN(bid)) || isNaN(prevClose)) {
            return null;
        }

        if (prevClose === 0) {
            return 0.0;
        } else if (!isNaN(last)) {
            return ((last - prevClose) / prevClose) * 100;
        } else {
            return ((bid - prevClose) / prevClose) * 100;
        }
    }

    public GetOpen (account: Account): number {
        const open = InstrumentUtils.OpenSpread(this.DataCache.GetSpreadPlan(account), this.instrument);
        return open;
    }

    public GetHigh (account: Account): number {
        const high = InstrumentUtils.HighSpread(this.DataCache.GetSpreadPlan(account), this.instrument);
        return high;
    }

    public GetLow (account: Account): number {
        const low = InstrumentUtils.LowSpread(this.DataCache.GetSpreadPlan(account), this.instrument);
        return low;
    }

    // TODO.
    public GetClose (account: Account): number {
        return NaN;
    };

    public GetPrevClose (account?: Account): number {
        const prevClose = InstrumentUtils.PrevCloseSpread(this.DataCache.GetSpreadPlan(account), this.instrument);
        return prevClose;
    }

    // TODO.
    public GetSpread (account: Account): number | null {
        let spread = null;
        let ask = null;
        let bid = null;

        const instrument = this.instrument;
        const q1 = this.lastQuote1Message;

        if (q1) {
            const sp = this.DataCache.GetSpreadPlan(account);
            ask = q1.AskSpread_SP_Ins(sp, instrument);
            bid = q1.BidSpread_SP_Ins(sp, instrument);
        }

        if (isNaN(ask) || isNaN(bid)) {
            spread = NaN;
        } else if (instrument && q1 && ask !== null && bid !== null) {
            spread = instrument.CalculateTicks(bid, ask - bid);
            spread *= bid > ask ? -1 : 1;
        } else if (!isNaN(ask) || !isNaN(bid)) {
            spread = NaN;
        } else {
            spread = 0;
        }

        return spread;
    }

    // TODO.
    public GetSpreadPercent (account: Account): number | null {
        const lastQuote1Message = this.lastQuote1Message;
        const instrument = this.instrument;
        let spreadPercent = null;
        const spread = this.GetSpread(account);
        const ask = this.GetAsk(account);
        const tPipSize = instrument ? instrument.PipsSize : 1;
        // спред в процентах
        if (instrument && lastQuote1Message && !isNaN(ask) && !isNaN(spread)) {
            spreadPercent = MathUtils.RoundDouble(spread * tPipSize / ask * 100, 2);
        } else if (isNaN(spread)) {
            spreadPercent = NaN;
        } else {
            spreadPercent = 0;
        }

        return spreadPercent;
    }

    public GetIndicativeAuctionPrice (): number {
        return this.indicativeAuctionPrice;
    }

    public GetIndicativeAuctionVolume (showLots): number {
        return this.getDoubleVolume(this.indicativeAuctionVolume, showLots);
    }

    public GetTicks (): number {
        return this.instrument.Ticks;
    }

    public strSpread (account: Account, spread): string {
        if (isNaN(spread)) {
            return Level1Calculator.NAString;
        } else {
            return spread.toString();
        }
    }

    public strSpreadPercent (account: Account, spreadPercent): string {
        if (isNaN(spreadPercent)) {
            return Level1Calculator.NAString;
        } else {
            return spreadPercent.toFixed(2);
        }
    }

    public StrMarkPrice (): string {
        return !isNaN(this.MarkPrice) ? this.MarkPrice.toString() : Level1Calculator.NAString;
    }

    public StrNextFundingSettlement (NAString = Level1Calculator.NAString): string {
        const now = DateTimeUtils.DateTimeUtcNow();
        if (+this.NextFundingTimeUTC === +(new Date(1970, 1, 1)) || +this.NextFundingTimeUTC < +now) {
            return NAString;
        }

        return TimeSpan.ToTimeSpanString(TimeSpan.ticksToTimeSpanObject(this.NextFundingTimeUTC.getTime() - now.getTime()), TimeSpanFormat.HourMinuteSecond);
    }

    public StrFundingRateValue (fundingRateValue, NAString = Level1Calculator.NAString): string {
        let rateValue = fundingRateValue || this.FundingRateValue * 100;

        if (!isNaN(rateValue)) {
            rateValue = MathUtils.RoundToIncrement(rateValue, MathUtils.MATH_ROUND_EPSILON * 10);
        }

        return !isNaN(rateValue) ? `${this.formatPriceAsIs(rateValue)} %` : NAString;
    }

    public GetSpreadedFundingRateValue (account?: Account | null): number {
        if (isNullOrUndefined(account)) {
            account = this.instrument.DataCache.getPrimaryAccount();
        }

        return account.DataCache.FundingRateMarkupPlans.GetSpreadedFundingRateValue(account, this.instrument, this.FundingRateValue);
    }

    public GetSpreadedFundingRateValueFormatted (account: Account | null, NAString?): string {
        return this.StrFundingRateValue(this.GetSpreadedFundingRateValue(account), NAString);
    }

    public GetNextFundingSettlement (): number {
        return this.NextFundingTimeUTC.getTime() - DateTimeUtils.DateTimeUtcNow().getTime();
    }

    // TODO. Move.
    public strVolume (showLots): string {
        const ins = this.instrument;
        // #43176 если тип инструмента = Index,
        // то поля Last size, bid, bid size, ask, ask size должны отображаться как N/A
        if (isNaN(ins.TotalVolume)) {
            return Level1Calculator.NAString;
        }

        return this.getFormatVolume(ins.TotalVolume, showLots);
    }

    public GetVolume (): number {
        return this.instrument.TotalVolume;
    }

    public GetETB (): string {
        let etb = Level1Calculator.NAString;
        const additional = this.instrument.InstrumentAdditionalInfo;
        if (additional !== null) {
            const etbIndicator = additional['ETB indicator'];

            if (!!etbIndicator && this.GetAssignedRoutesDict()[this.instrument.Route]) {
                etb = etbIndicator;
            }
        }
        return etb;
    }

    public GetAssignedRoutesDict (): any {
        const assignedRoutes = {};
        const additional = this.instrument.InstrumentAdditionalInfo;
        const etbRouteId = additional['ETB Trade route ID'];

        if (etbRouteId) {
            const splited = etbRouteId.split(',');
            for (let i = 0; i < splited.length; i++) {
                assignedRoutes[parseInt(splited[i])] = true;
            }
        }

        return assignedRoutes;
    }

    // TODO. Move.
    public strIndicativeAuctionVolume (showLots): string {
        return this.getFormatVolume(
            this.GetIndicativeAuctionVolume(showLots),
            showLots);
    }

    // TODO. Move.
    public StrLastPrice (account, withoutSpread?): string {
        return this.getFormatPrice(this.GetLastPrice(account, withoutSpread));
    }

    public StrLastPriceByDefaultChartHistoryType (account, withoutSpread?): string {
        return this.getFormatPrice(this.GetLastPriceByDefaultChartHistoryType(account, withoutSpread));
    }

    // TODO. Move.
    public StrLastSize (showLots): string {
        const ins = this.instrument;
        // #43176 если тип инструмента = Index, то поля Last size, bid, bid size, ask, ask size должны отображаться как N/A
        if (ins && ins.InstrType === InstrumentTypes.INDICIES) {
            return Level1Calculator.NAString;
        }

        return this.getFormatVolume(this.GetLastSize(showLots), showLots);
    }

    public StrAskSize (showLots): string {
        const ins = this.instrument;
        // #43176 если тип инструмента = Index, то поля Last size, bid, bid size, ask, ask size должны отображаться как N/A
        if (ins && ins.InstrType === InstrumentTypes.INDICIES) {
            return Level1Calculator.NAString;
        }

        return this.getFormatVolume(this.getAskSize(showLots), showLots);
    }

    // TODO. Move.
    public StrBidSize (showLots): string {
        const ins = this.instrument;
        // #43176 если тип инструмента = Index, то поля Last size, bid, bid size, ask, ask size должны отображаться как N/A
        if (ins && ins.InstrType === InstrumentTypes.INDICIES) {
            return Level1Calculator.NAString;
        }

        return this.getFormatVolume(this.getBidSize(showLots), showLots);
    }

    // TODO. Move.
    public strChange (account: Account): string {
        const change = this.change;
        if (!isNaN(change)) {
            return this.formatPriceAsIs(change);
        }

        const tmpChange = this.GetChange(account);
        if (tmpChange === null || isNaN(tmpChange)) {
            return Level1Calculator.NAString;
        }

        return this.getFormatPrice(tmpChange);
    }

    // TODO. Move.
    public strAsk (account: Account, withoutSpread): string {
        return this.getFormatPrice(this.GetAsk(account, withoutSpread));
    }

    // TODO. Move.
    public strBid (account: Account, withoutSpread): string {
        return this.getFormatPrice(this.GetBid(account, withoutSpread));
    }

    // TODO. Move.
    public strChangePercent (account: Account, format?): string {
        format = format || 2;

        const changePercent = this.changePercent;
        if (!isNaN(changePercent)) {
            return this.formatPriceAsIs(changePercent);
        }

        const tmpChangePercent = this.GetChangePercent(account);
        if (tmpChangePercent === null || isNaN(tmpChangePercent)) {
            return Level1Calculator.NAString;
        }

        return PriceFormatter.formatPrice(tmpChangePercent, format);
    }

    public formatChangePercentTostr (innerChangePercent, calculatedChangePercent, account?: Account, format?): string {
        format = format || 2;

        if (!innerChangePercent) {
            const changePercent = this.changePercent;
            if (!isNaN(changePercent)) {
                return this.formatPriceAsIs(changePercent);
            }
        } else if (!isNaN(innerChangePercent)) {
            return this.formatPriceAsIs(innerChangePercent);
        }

        const tmpChangePercent = calculatedChangePercent || this.GetChangePercent(account);
        if (tmpChangePercent === null || isNaN(tmpChangePercent)) {
            return Level1Calculator.NAString;
        }

        return PriceFormatter.formatPrice(tmpChangePercent, format);
    }

    // TODO. Move.
    public strOpen (account: Account): string {
        return this.getFormatPrice(this.GetOpen(account));
    }

    // TODO. Move.
    public strHigh (account: Account): string {
        return this.getFormatPrice(this.GetHigh(account));
    }

    // TODO. Move.
    public strLow (account: Account): string {
        return this.getFormatPrice(this.GetLow(account));
    }

    // TODO. Move.
    public strPrevClose (account: Account): string {
        return this.getFormatPrice(this.GetPrevClose(account));
    }

    // TODO. Move.
    public strSettlementPrice (): string {
        return this.getFormatSettlementPrice(this.instrument.SettlementPrice);
    }

    // TODO. Move.
    public strPrevSettlementPrice (): string {
        return this.getFormatSettlementPrice(this.instrument.PrevSettlementPrice);
    }

    // TODO. Move.
    public strIndicativeAuctionPrice (): string {
        return this.getFormatPrice(this.GetIndicativeAuctionPrice());
    }

    // TODO. Move.
    public formatPriceAsIs (price): string {
        const prec = MathUtils.getPrecision(price);
        return PriceFormatter.formatPrice(price, prec);
    }

    // TODO. Move.
    public getFormatSettlementPrice (settlementPrice): string {
        const instrument = this.instrument;
        if (instrument.InstrType === InstrumentTypes.ETF ||
        isNaN(settlementPrice)) {
            return Level1Calculator.NAString;
        }

        if (instrument.InstrType === InstrumentTypes.INDICIES) {
            return Level1Calculator.NAString;
        }

        if (isNaN(settlementPrice)) {
            return Level1Calculator.NAString;
        }

        return this.getFormatPrice(settlementPrice);
    }

    // TODO. REMove. Dublicate with InstrumentUtils.getFormatPrice
    public getFormatPrice (price): string {
    // hsa: раньше проверяли, что цена не может быть меньше нуля,
    // но после добавления спредов я эту проверку убрал
        if (isNaN(price))
        // || price === -1)   // #87569
        {
            return Level1Calculator.NAString;
        }

        const ins = this.instrument;
        return ins
            ? ins.formatPrice(price)
            : price.toFixed(4);
    }

    // TODO. REMove. Dublicate with InstrumentUtils.getFormatVolume
    public getFormatVolume (volume, showLots, productType?): string {
        productType = productType || ProductType.General;
        if (isNaN(volume)) {
            return Level1Calculator.NAString;
        }

        const ins = this.instrument;
        return ins.DataCache.formatVolume(ins, volume, showLots, productType);
    }

    public GetNSEValue (): number {
        const ins = this.instrument;
        const volume = this.GetVolume();
        const averagePrice = this.GetAvgTradedPrice();
        let value = NaN;

        if (isNaN(averagePrice) || isNaN(volume)) {
            return value;
        }

        value = averagePrice * volume;

        if (ins.isOptionSymbol) {
            value = (ins.StrikePrice + averagePrice) * volume;
        }

        if (ins.QuotingType === QuotingType.TickCost_TickSize) {
            const LotSize = ins.FuturesTickCoast > 0 ? ins.FuturesTickCoast / ins.GetPipsSize(ins.PointSize) : 1;
            value = value * LotSize;
        }

        return value;
    }

    public GetNSEFormatted (showLots = false, NAString = Level1Calculator.NAString): string {
        const value = this.GetNSEValue();
        const formattedValue = isNaN(value) ? NAString : this.getFormatVolume(value, showLots);

        return formattedValue;
    }

    public GetDPRValue (): any {
        const ins = this.instrument;
        const limits = ins.Limits;
        const hightLimit = limits.HightLimit;
        const lowLimit = limits.LowLimit;
        let value: any = -1;

        if (lowLimit && hightLimit) {
            value = lowLimit + ' - ' + hightLimit;
        }

        return value;
    }

    // Warning! GetLastUpdateQuote is not in use now, but it may be incorrect, as was the case with StrNextFundingSettlement. Details in #117528.
    public GetLastUpdateQuote (): any {
        const lastQuote = this.lastQuote;
        if (!lastQuote) {
            return;
        }

        const difTick = DateTimeUtils.DateTimeNow().getTime() - lastQuote.IncomeQuoteTime.getTime();
        const difTime = new Date(difTick);
        const utsShiftTime = difTime.getTimezoneOffset() * 60000;
        const resultTime = new Date(difTick + utsShiftTime);
        return DateTimeUtils.formatDate(resultTime, 'HH:mm:ss');
    }

    // Message 113
    public GetAvgTradedPrice (): number {
        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        let AvgTradedPrice = NaN;

        if (lastInstrumentPricesMessage) {
            AvgTradedPrice = lastInstrumentPricesMessage.AvgTradedPrice;
        }

        return AvgTradedPrice;
    }

    public GetTotalBuyQty (): number {
        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        let TotalBuyQuantity = Number.NaN;

        if (lastInstrumentPricesMessage) {
            TotalBuyQuantity = lastInstrumentPricesMessage.TotalBuyQuantity;
        }

        return TotalBuyQuantity;
    }

    public GetTotalSellQty (): number {
        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        let TotalSellQuantity = Number.NaN;

        if (lastInstrumentPricesMessage) {
            TotalSellQuantity = lastInstrumentPricesMessage.TotalSellQuantity;
        }

        return TotalSellQuantity;
    }

    public GetHighOpenInterest (): number {
        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        let HighOpenInterest = NaN;

        if (lastInstrumentPricesMessage) {
            HighOpenInterest = lastInstrumentPricesMessage.HighOpenInterest;
        }

        return HighOpenInterest;
    }

    public GetLowOpenInterest (): number {
        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        let LowOpenInterest = NaN;

        if (lastInstrumentPricesMessage) {
            LowOpenInterest = lastInstrumentPricesMessage.LowOpenInterest;
        }

        return LowOpenInterest;
    }

    public GetInitiatorPrice (): number {
        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        let InitiatorPrice = NaN;

        if (lastInstrumentPricesMessage) {
            InitiatorPrice = lastInstrumentPricesMessage.InitiatorPrice;
        }

        return InitiatorPrice;
    }

    public GetInitiatorQty (): number {
        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        let InitiatorQuantity = -1;

        if (lastInstrumentPricesMessage) {
            InitiatorQuantity = lastInstrumentPricesMessage.InitiatorQuantity;
        }

        return InitiatorQuantity;
    }

    public GetInitiatorType (): number {
        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        let InitiatorType = -1;

        if (lastInstrumentPricesMessage) {
            InitiatorType = lastInstrumentPricesMessage.InitiatorType;
        }

        return InitiatorType;
    }

    public GetLastTradedTime (): any {
    // #93674
        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        let LastTradedTime = this.PrewLastTradedTime || -1;

        if (lastInstrumentPricesMessage?.LastTradedTime) {
            LastTradedTime = DateTimeUtils.formatDate(lastInstrumentPricesMessage.LastTradedTime, 'HH:mm:ss DD.MM.YYYY');
            this.PrewLastTradedTime = LastTradedTime;
        }

        return LastTradedTime;
    }

    public GetAuctionNumber (): number {
        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        let AuctionNumber = -1;

        if (lastInstrumentPricesMessage) {
            AuctionNumber = lastInstrumentPricesMessage.AuctionNumber;
        }

        return AuctionNumber;
    }

    public GetAuctionStatus (): number {
        const lastInstrumentPricesMessage = this.lastInstrumentPricesMessage;
        let AuctionStatus = -1;

        if (lastInstrumentPricesMessage) {
            AuctionStatus = lastInstrumentPricesMessage.AuctionStatus;
        }

        return AuctionStatus;
    }

    // Message 114
    public GetFiftyTwoWeekHighPrice (): number {
        const instrument = this.instrument;
        let FiftyTwoWeekHighPrice = NaN;

        if (instrument && instrument.FiftyTwoWeekHighPrice !== null) {
            FiftyTwoWeekHighPrice = instrument.FiftyTwoWeekHighPrice;
        }

        return FiftyTwoWeekHighPrice;
    }

    public GetFiftyTwoWeekLowPrice (): number {
        const instrument = this.instrument;
        let FiftyTwoWeekLowPrice = NaN;

        if (instrument && instrument.FiftyTwoWeekLowPrice !== null) {
            FiftyTwoWeekLowPrice = instrument.FiftyTwoWeekLowPrice;
        }

        return FiftyTwoWeekLowPrice;
    }

    public GetBidSourceName (): string {
        const lastQuote1Message = this.lastQuote1Message;
        let id = Level1Calculator.NAString;

        if (lastQuote1Message?.BidVenue != null) {
            id = lastQuote1Message.BidVenue;
        }

        return this.DataCache.GetSourceName(id);
    }

    public GetAskSourceName (): string {
        const lastQuote1Message = this.lastQuote1Message;
        let id = Level1Calculator.NAString;

        if (lastQuote1Message?.AskVenue != null) {
            id = lastQuote1Message.AskVenue;
        }

        return this.DataCache.GetSourceName(id);
    }

    public GetLastSourceName (instrumentDayBarMessageUpdateMode?): string {
        let id = Level1Calculator.NAString;
        const ins = this.instrument;
        if (!ins) return id;

        const quoteMsg = instrumentDayBarMessageUpdateMode || ins.InstrumentDayBarMessageUpdateMode
            ? this.lastInstrumentDayBarMessage
            : this.lastQuote3Message;

        if (quoteMsg?.LastVenue != null) {
            id = quoteMsg.LastVenue;
        }

        return this.DataCache.GetSourceName(id);
    }

    public GetLPP (): string {
        const LPPHigh = this.LPPHigh;
        const LPPLow = this.LPPLow;
        const NAStr = Level1Calculator.NAString;
        let resultStr = NAStr;

        if (LPPHigh || LPPLow) {
            resultStr = (LPPLow || NAStr) + ' - ' + (LPPHigh || NAStr);
        }

        return resultStr;
    }

    public static NAString = 'N/A';
}

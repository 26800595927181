// BaseFibonacciDataCacheTool.ts

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../properties/Resources';
import { DataCacheTool } from '../DataCacheTool';
import { DynProperty } from '../../DynProperty';

/**
 * Base Fibonacci Data Cache Tool
 */
export class BaseFibonacciDataCacheTool extends DataCacheTool {
    public fibLevel: any;
    protected levelsVisibility: any;

    /**
     * Constructor for BaseFibonacciDataCacheTool
     */
    constructor () {
        super();
        this.InitBlock();
    }

    /**
     * Get properties for the tool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const properties: DynProperty[] = super.Properties();

        const fibLevel = this.fibLevel;
        if (!fibLevel) return properties;

        // чтоб в группе свойств фибоначчи всегда было свойство
        properties.push(new DynProperty('IsFibbonacci', true, DynProperty.BOOLEAN, /* DynProperty.FIBONACCI_PARAMETERS_GROUP */DynProperty.FIBONACCI_PARAMETERS_GROUP));

        const levelsVisibility = this.levelsVisibility;
        // +++denis добавлено видимость
        if (levelsVisibility && levelsVisibility.length === fibLevel.length) {
            for (let i = 0; i < fibLevel.length; i++) {
                const p = new DynProperty('Level' + i, [fibLevel[i], levelsVisibility[i]], DynProperty.FIBLEVEL, /* DynProperty.FIBONACCI_PARAMETERS_GROUP */DynProperty.FIBONACCI_PARAMETERS_GROUP);
                p.propertyComment = Resources.getResource('property.Level') + i;
                p.increment = 0.01;
                p.decimalPlaces = 2;
                p.sortIndex = i * 10;
                properties.push(p);
            }
        }
        // иначе все как и было
        else {
            for (let i = 0; i < fibLevel.length; i++) {
                const p = new DynProperty('Level' + i, fibLevel[i], DynProperty.DOUBLE, /* DynProperty.FIBONACCI_PARAMETERS_GROUP */DynProperty.FIBONACCI_PARAMETERS_GROUP);
                p.propertyComment = Resources.getResource('property.Level') + i;
                p.increment = 0.01;
                p.decimalPlaces = 2;
                p.sortIndex = i * 10;
                properties.push(p);
            }
        }

        return properties;
    }

    /**
     * Callback function for handling property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const fibonacci = [];

        const propertiesLen = properties.length;
        for (let i = 0; i < propertiesLen; i++) {
            const prop = properties[i];
            if (
                prop.group === DynProperty.FIBONACCI_PARAMETERS_GROUP &&
                prop.name !== 'IsFibbonacci' &&
                prop.name !== 'EnableNegativeFibonacciValue'
            ) {
                fibonacci.push(prop);
            }
        }
        // если тулза фибоначчи и даже если у нее удалили все уровни..
        if (fibonacci.length > 0 || DynProperty.getPropertyByName(properties, 'IsFibbonacci')) {
            const fibLevel = new Array(fibonacci.length);
            let levelsVisibility = new Array(fibonacci.length);

            const fibonacciLen = fibonacci.length;
            for (let i = 0; i < fibonacciLen; i++) {
                const value = fibonacci[i].value;
                if (value === null) continue;

                if (Array.isArray(value)) {
                    fibLevel[i] = value[0];
                    levelsVisibility[i] = value[1];
                } else if (typeof value === 'number') {
                    fibLevel[i] = value;
                    levelsVisibility = null;
                }
            }
            if (fibLevel.length !== 0) {
                this.fibLevel = fibLevel;
            }

            this.levelsVisibility = levelsVisibility;
        }
    }
}

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { TerceraButtonEvents } from '../../Utils/Enums/ButtonEnums';
import { ButtonTemplate } from '../../templates';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { type IContextMenuItem } from '../UtilsClasses/IContextMenuItem';
import { Control } from './Control';

export class TerceraButton extends Control {
    onBtnClick: CustomEvent = new CustomEvent();
    lastMouseClickX: number;
    lastMouseClickY: number;
    blinkIntervalFrequency: number = 720; // (milliseconds)  for btn's counter blinking
    blinkIntervalID: NodeJS.Timeout | null; // blink set interval holder
    isMouseOnButton: boolean = false;
    Tag: any;

    get btnInGroup (): boolean {
        return this.get('btnInGroup');
    }

    set btnInGroup (value: boolean) {
        void this.set('btnInGroup', value);
    }

    getType (): string { return ControlsTypes.TerceraButton; };

    oninit (): void {
        super.oninit();
        this.onBtnClick = new CustomEvent();
        this.on('componentOver', this.private_MouseOver);
        this.on('componentLeave', this.private_MouseLeave);
        this.on('componentClick', this.private_MouseClick);
    };

    //
    //
    //
    private_MouseClick (e): void {
        if (e?.original) {
            this.lastMouseClickX = e.original.clientX;
            this.lastMouseClickY = e.original.clientY;
        }

        const canCheck: boolean = this.get('canCheck');
        const checked: boolean = this.get('checked');

        if (!this.get('enabled')) { return; }

        if (canCheck) {
            if (this.btnInGroup && checked) {} else { void this.set('checked', !checked); }
        }

        this.fire(TerceraButtonEvents.onClick);
        this.onBtnClick.Raise(this, this);
    };

    Click (): void {
        this.private_MouseClick(this);
    };

    private_MouseOver (event): void {
        if (!this.get('enabled')) { return; }
        // this.stopCounterBlinking() // not in use functionality
        this.isMouseOnButton = true;

        this.fire(TerceraButtonEvents.onHover, event);
    };

    private_MouseLeave (event): void {
        if (!this.get('enabled')) { return; }
        this.isMouseOnButton = false;
    };

    startCounterBlinking (): void {
        this.stopCounterBlinking(); // stop old setInterval

        this.blinkIntervalID = setInterval(function () {
            void this.set('counterIsHiddenWhileBlinking', !this.get('counterIsHiddenWhileBlinking'));
        }.bind(this), this.blinkIntervalFrequency);
    };

    stopCounterBlinking (): void {
        void this.set('counterIsHiddenWhileBlinking', false);

        if (this.blinkIntervalID) { clearInterval(this.blinkIntervalID); }
    };

    //
    //
    //
    setImages (newDefaultImageUrl, newHoverImageUrl, newClickImageUrl, newDisabledImageUrl, newCheckedImageUrl): void {
        const newImages = {
            defaultImageUrl: newDefaultImageUrl,
            hoverImageUrl: newHoverImageUrl,
            clickImageUrl: newClickImageUrl,
            disabledImageUrl: newDisabledImageUrl,
            checkedImageUrl: newCheckedImageUrl
        };

        void this.set({ images: newImages });
    };

    getContextMenuItem (): IContextMenuItem {
        const contextMenuItem = super.getContextMenuItem();
        contextMenuItem.tag = this.get('tag');
        contextMenuItem.style = this.get('terceraButtonStyle');
        contextMenuItem.enabled = this.get('enabled');
        contextMenuItem.canCheck = this.get('canCheck');
        contextMenuItem.checked = this.get('checked');
        contextMenuItem.event = this.Click.bind(this);
        return contextMenuItem;
    };
}

Control.extendWith(TerceraButton, {
    template: ButtonTemplate,
    data: function () {
        return {
            text: '',
            terceraButtonStyle: 'js-button-gray25',

            // height: 25, // Must be commented out or removed for the correct application of the Investing/TVTradingPlatform style
            zIndex: null,

            // сейчас картинка только без текста и только по центру!
            image: '', // url
            imageChecked: '',
            images: null,

            checked: false,
            canCheck: false,
            tag: null,
            localizeKey: '',

            isColoringButton: false,
            coloringButtonImage: '',
            coloringButtonColor: 'rgb(0,0,0)',

            counter: 0, // counter value, also visibile indicator -> if counter>0 than it is visible
            counterStyle: 'circle-counter', // adding '-plus' to style's name for counter > 99
            counterIsHiddenWhileBlinking: false, // to make blinking counter

            isRelative: false,
            btnInGroup: false
        };
    }
});

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { QuickTableRow } from './QuickTableRow';
import { SEPARATOR } from '../../../Utils/Enums/Constants';
import { DateTimeUtils } from '../../../Utils/Time/DateTimeUtils';
import { InstrumentLookupComparer } from '../../../Utils/Instruments/InstrumentLookupComparer';
import { type Instrument } from '../../../Commons/cache/Instrument';

export class InstrumentLookupAutoCompleteQuickTableRow extends QuickTableRow {
    // TODO всякое хуё маё

    public id: any | null;
    public ins: Instrument | null;

    public childIDs = []; // <- for caching, get prop: ChildIDs
    public parentID: string | null; // <- for caching, get prop: ParentID

    public collapsed = true;
    public mayContainTrees: boolean;
    public DoCollapse: () => any;
    public terceraInstrumentLookup: any;
    public SkipRequest: boolean;

    constructor (ins, id, table, mayContainTrees) {
        super(null, table);

        this.id = id || null;
        this.ins = ins || null;

        this.parentID = null || this.ParentID; // <- for caching, get prop: ParentID

        this.mayContainTrees = mayContainTrees || false;
        this.visible = true;
    }

    get Instrument (): Instrument | null { return this.ins; }

    get Childs (): any[] { return this.ChildIDs.map((e) => { return this.table.getItemById(e); }); }

    get ChildIDs (): any[] { return this.childIDs; }

    get ChildNum (): number { return this.childIDs.length; }

    get HasAnyChild (): boolean { return !(this.ChildNum === 0); }

    get IsPartOfTree (): boolean { return !!(this.HasAnyChild || this.ParentID); }

    get Level (): number {
        const p = this.Parent;
        return p ? p.Level + (p.visible ? 0 : -1) + 1 : 0; // #113220
    }

    get Parent (): any {
        const qt = this.table; const pID = this.ParentID; let result = null;
        if (qt && pID) {
            result = qt.getItemById(pID);
        }

        return result;
    }

    get ParentID (): string {
        if (isNullOrUndefined(this.parentID)) {
            this.parentID = InstrumentLookupAutoCompleteQuickTableRow.GetInsParentID(this.Instrument);
        }

        return this.parentID;
    }

    get Collapsed (): boolean { return this.collapsed; }
    set Collapsed (val) {
        this.collapsed = val;

        if (!val) // open node
        {
            this.Childs.forEach((e) => { e.visible = true; });
        } else {
            const f = (e, zeroGeneration = true): void => {
                if (!zeroGeneration) {
                    e.visible = false;
                    e.collapsed = true;
                }
                e.Childs.forEach((ee) => { f(ee, false); });
            };

            f(this);
        }
    }

    get tag (): any { // for DoCollapse event (~strikes request [420])
        const c = this.getFirstChild();
        return c ? c.ins : null;
    }

    public getFirstChild (): any {
        const ch = this.Childs;
        if (ch?.length) {
            return ch[0];
        }
    }

    public ChangeTreeCollapseState (firstGeneration = false, newVisible = null): void {
        if (this.DoCollapse) {
            this.DoCollapse();
            this.Collapsed = !this.Collapsed;
        }

        const visible = firstGeneration || newVisible !== null ? newVisible : !(this.Collapsed = !this.Collapsed);
    }

    public addChildNodesByInstrumentsArray (id: string, IInstrumentsList, currentNameFilter): void {
        IInstrumentsList = InstrumentLookupComparer.sortInstrumentList(IInstrumentsList, currentNameFilter || '');
        const lookup = this.terceraInstrumentLookup;
        const qtR = lookup.autoCompliteQuickTable;
        const qt = lookup.getQuickTable();
        let w = 0;

        this.SkipRequest = true;
        this.childIDs = [];

        for (let i = IInstrumentsList.length - 1; i >= 0; i--) // reverse order cuz of 113023
        {
            const curIt = IInstrumentsList[i];

            w = Math.max(w, lookup.AddToParent(id, curIt, qt));
        }
        this.collapsed = !this.collapsed;

        if (w > qtR.get('width')) {
            qtR.set({ width: w });
            lookup.correctQTPopupLocation();
        }

        lookup.updateQuickTableHeight();
    }

    public AddChild (ch): boolean {
        if (!ch.cells[0].InstrumentNameText) { return; }

        const id = ch ? ch.id : null;
        const add = !this.childIDs.includes(id) && this != ch;

        if (add) {
            this.childIDs.push(id);
            ch.parentID = this.id;
        }

        return add;
    }

    public getFlag (): any {
        let flag = (this.cells.length > 0) ? this.cells[0].FlagImg : null;
        const kids = this.Childs;
        if (!flag) {
            flag = (kids.length > 0) ? kids[0].getFlag() : null;
        }

        return flag;
    }

    public getExchange (): any {
        const cells = this.cells ? this.cells[0] : null;
        const res = cells.InstrumentTradingExchangeText; // #113027 // this.HasAnyChild ? this.Childs[0].getExchange() : this.InstrumentTradingExchangeText
        return res;
    }

    public getDescription (): string {
        let description = this.cells[0].InstrumentDescriptionText;

        if (!description && this.Parent) { description = this.Parent.cells[0].InstrumentDescriptionText; } // #113025 small

        // if (!description && this.HasAnyChild)
        // {
        //     let chIns = this.Childs[0].Instrument
        //     description = chIns.SourceDescription || chIns.DescriptionValue()       // XZ
        // }
        // let ins = this.Instrument,
        //     forwIns = ins.ForwardBaseInstrument

        // if (forwIns && Utils.IsNullOrUndefined(forwIns.UnderlierDescription))
        //     description = forwIns.UnderlierDescription
        // if (!description)
        //     description = this.Instrument.DescriptionValue()

        return description;
    }

    public Hovered (): boolean {
        const hoveredRowId = this.table ? this.table.hoveredRow?.id : null;
        const hovered = hoveredRowId ? hoveredRowId == this.id : false;

        return hovered;
    }

    public static GetInsParentID (instrument?: Instrument | null): string {
        if (instrument?.ForwardBaseInstrument && instrument.ExpDate) {
            const baseID = instrument.ForwardBaseInstrument.GetInteriorID();
            const expDateStr = DateTimeUtils.formatDate(instrument.ExpDate, 'DD.MM.YYYY');

            return baseID + SEPARATOR + expDateStr;
        }

        return null;
    }
}

export class WorkspaceManagerHelper {
    private _showLogs: boolean = false;
    private saveWorkspaceLocal: () => Promise<void>;
    private saveWorkspaceRemote: () => Promise<void>;

    public setShowLogs (showLogs: boolean): void {
        this._showLogs = showLogs;
    }

    public isShowLogs (): boolean {
        return this._showLogs;
    }

    public saveWorkspaceImmediately (aim: SaveWorkspaceAim): void {
        if (aim === SaveWorkspaceAim.Local) {
            if (!this.saveWorkspaceLocal) {
                console.log('saveWorkspaceLocal not initialized');
                return;
            }

            console.log('Run saveWorkspaceLocal');
            void this.saveWorkspaceLocal();
        } else if (aim === SaveWorkspaceAim.Server) {
            if (!this.saveWorkspaceRemote) {
                console.log('saveWorkspaceRemote not initialized');
                return;
            }

            console.log('Run saveWorkspaceRemote');
            void this.saveWorkspaceRemote();
        }
    }

    public __init (saveWorkspaceLocal: () => Promise<void>, saveWorkspaceRemote: () => Promise<void>): void {
        this.saveWorkspaceLocal = saveWorkspaceLocal;
        this.saveWorkspaceRemote = saveWorkspaceRemote;
    }
}

export enum SaveWorkspaceAim {
    Local = 'local',
    Server = 'server'
}

export const wmh = new WorkspaceManagerHelper();

import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { ApplicationPanelWithTable } from '../ApplicationPanelWithTable';
import { OptionPaperPositionsPanelTemplate } from '../../../templates';
import { type QuickTableRactive } from '../../elements/QuickTable/QuickTableRactive';
import { type QuickTable } from '../../elements/QuickTable/QuickTable';
import { type QuickTableRow } from '../../elements/QuickTable/QuickTableRow';
import { PaperPositionItem } from '../../cache/PaperPositionItem';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { Resources } from '../../../Commons/properties/Resources';
import { SessionSettings } from '../../../Commons/SessionSettings';
import { Quantity } from '../../../Utils/Trading/Quantity';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { OrderUtils } from '../../../Utils/Trading/OrderUtils';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { SlTpPriceType } from '../../../Utils/Enums/Constants';
import { type PaperPosition } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionPaperPosition/PaperPosition';
import { LinkedPriceType } from '../../../Commons/cache/SavedOrders/SavedOrderEnums';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { SavedOrderValidator } from '../../../Commons/cache/SavedOrders/SavedOrderValidator';
import { ThemeManager } from '../../misc/ThemeManager';
import { SavedOrdersController } from '../../../Commons/cache/SavedOrders/SavedOrdersController';
import { type OrderEditBase } from '../../../Commons/cache/OrderParams/order-edit/OrderEditBase';
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { IsAllowed } from '../../../Commons/IsAllowed';
import { PlacedFrom } from '../../../Utils/Trading/PlacedFrom';
import { Instrument } from '../../../Commons/cache/Instrument';
import { TerceraMenu } from '../../elements/TerceraMenu';
import { TradingPositionType } from '../../../Utils/Instruments/TradingPositionType';
import { Color } from '../../../Commons/Graphics';
import { DataCache } from '../../../Commons/DataCache';

export class OptionPaperPositionsPanel extends ApplicationPanelWithTable<PaperPositionItem> {
    private _isInitialized: boolean = false;
    private _quickTable: QuickTable<PaperPositionItem>;
    private _optionTrader: OptionTrader;

    constructor () {
        super();
        this.Name = 'OptionPaperPositionsPanel';
        this.headerLocaleKey = 'panel.optionPaperPositions';
    }

    public override getType (): PanelNames { return PanelNames.OptionPaperPositionsPanel; }

    public override oninit (): void {
        super.oninit();
        this.clearTable = this.clearTable.bind(this);
        this.onAfterEditItem = this.onAfterEditItem.bind(this);
        this.onAddPaperPosition = this.onAddPaperPosition.bind(this);
        this.onRemovePaperPosition = this.onRemovePaperPosition.bind(this);
        this.onUpdatePaperPosition = this.onUpdatePaperPosition.bind(this);
        this.processEditRow = this.processEditRow.bind(this);
        this.onAddUnderlierBtnClicked = this.onAddUnderlierBtnClicked.bind(this);
        this.onCancelAllBtnClicked = this.onCancelAllBtnClicked.bind(this);
        this.onPlaceSelectedBtnClicked = this.onPlaceSelectedBtnClicked.bind(this);
        super.observe('visible', this.onVisibleChanged);
        super.on('onAddUnderlierBtnClick', this.onAddUnderlierBtnClicked);
        super.on('onCancelAllBtnClick', this.onCancelAllBtnClicked);
        super.on('onPlaceSelectedBtnClick', this.onPlaceSelectedBtnClicked);
    }

    public override onteardown (): void {
        if (!isNullOrUndefined(this._optionTrader)) {
            this._optionTrader.unsubscribeOnRepopulate(this.clearTable);
            this._optionTrader.unsubscribeOnCreatePaperPosition(this.onAddPaperPosition);
            this._optionTrader.unsubscribeOnRemovePaperPosition(this.onRemovePaperPosition);
            this._optionTrader.unsubscribeOnUpdatePaperPosition(this.onUpdatePaperPosition);
            DataCache.OnUpdateInstrument.UnSubscribe(this.onUpdateInstrument, this);
        }
        if (!isNullOrUndefined(this._quickTable)) {
            this._quickTable.AfterEditItem.UnSubscribe(this.onAfterEditItem, this);
            this._quickTable.OnPaintedPictureButtonClick.UnSubscribe(this.onTableRowButtonClicked, this);
        }
        super.onteardown();
    }

    public override jbInit (): void {
        this.initializeVariables();
        super.jbInit();
        this.populateTableContextMenu();
        this._quickTable.beforeTableContextMenuShowing = this.preparePopup.bind(this);
        this._quickTable.InitializeDirect(new PaperPositionItem(null, null, null));
        this._quickTable.UpdateSortedColumns();
        this._quickTable.AfterEditItem.Subscribe(this.onAfterEditItem, this);
        this._quickTable.OnPaintedPictureButtonClick.Subscribe(this.onTableRowButtonClicked, this);
    }

    public override getQuickTable (): QuickTable { return this._quickTable; }
    public override getInstrument (): Instrument | null {
        const table = this.getQuickTable();
        if (isNullOrUndefined(table)) {
            return null;
        }
        const selectedItem = table.getSelectedTableItems()[0];
        if (isNullOrUndefined(selectedItem)) {
            return null;
        }
        return selectedItem.GetCurrentInstrument();
    }

    public override layoutTable (): void {
        super.layoutTable();
        for (const table of super.findAllComponents('quickTableRactive')) {
            super.layoutTableResize(table);
        }
    }

    public override localize (): void {
        super.localize();
        if (!this._isInitialized) {
            return;
        }
        this._quickTable.localize();
        void this.set({
            addUnderlierButtonText: Resources.getResource('panel.optionPaperPositions.addUnderlier'),
            cancelAllButtonText: Resources.getResource('panel.optionPaperPositions.cancelAll'),
            placeSelectedButtonText: Resources.getResource('panel.optionPaperPositions.placeSelected'),
            shortPositionsAreForbiddenText: Resources.getResource('panel.optionPaperPositions.shortPositionsAreForbidden')
        });
    }

    public override themeChange (): void {
        super.themeChange();
        if (!this._isInitialized) {
            return;
        }
        this._quickTable.themeChange();
    }

    public override TickAsync (): void {
        super.TickAsync();
        const isVisible: boolean = this.get<boolean>('visible');
        if (!isVisible) {
            return;
        }
        this.getQuickTable().needRedrawBackground = true;
    }

    public updateData (): void {
        let shortPositionsAreForbidden = false;
        const quickTable = this.getQuickTable();
        for (const row of quickTable.rowsArray) {
            const item: PaperPositionItem = row.item;
            if (item.savedOrder != null && item.savedOrder.Active && !item.savedOrder.isPosition() && this.IsShortPositionForbidden(item)) {
                row.BackColor = ThemeManager.CurrentTheme.OptionMasterTestOnPaperDenyShortPositionsColor;
                shortPositionsAreForbidden = true;
            } else {
                row.BackColor = Color.Empty;
            }
        }

        void this.set({ shortPositionsAreForbiddenVisibility: shortPositionsAreForbidden });
    }

    public onUpdateInstrument (instrument: Instrument): void {
        if (this._optionTrader.underlier === instrument) {
            void this.updateButtonsEnability();
        }
    }

    private IsShortPositionForbidden (item: PaperPositionItem): boolean {
        try {
            if (item.savedOrder.Operation === OperationType.Buy) { return false; }

            const instr = item.savedOrder.Instrument;
            if (instr.TradingPositionType === TradingPositionType.MultiPosition) { return true; }
            if (instr.RiskSettings.IsShortable(item.savedOrder.ProductType)) { return false; }
            let buyPaperPositionsAmount = 0;
            let sellPaperPositionsAmount = 0;

            const positions = this._optionTrader.getPaperPositionsForAnalyzer();
            for (const paperPos of positions) {
                if ((!paperPos.Active && !paperPos.isPosition()) ||
                    !Instrument.IsEqualInstrument(instr, paperPos.Instrument) ||
                    (paperPos.isPosition() && paperPos.isSubPosition())) { continue; }

                if (paperPos.Operation === OperationType.Buy) { buyPaperPositionsAmount += paperPos.QuantityLots; }

                if (paperPos.Operation === OperationType.Sell) { sellPaperPositionsAmount += paperPos.QuantityLots; }
            }

            return sellPaperPositionsAmount > buyPaperPositionsAmount;
        } catch (ex) {
            return false;
        }
    }

    public override repopulate (): void {
        super.repopulate();
        this.clearTable();
    }

    public override updateSettings (): void {
        super.updateSettings();
        const rows: QuickTableRow[] = this.getQuickTable().rowsArray;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: PaperPositionItem = rows[i].item;
            item.updateEditingInfo();
            this.processEditRow(rows[i]);
        }
    }

    public setOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
        this._optionTrader.subscribeOnRepopulate(this.clearTable);
        this._optionTrader.subscribeOnCreatePaperPosition(this.onAddPaperPosition);
        this._optionTrader.subscribeOnRemovePaperPosition(this.onRemovePaperPosition);
        this._optionTrader.subscribeOnUpdatePaperPosition(this.onUpdatePaperPosition);
        DataCache.OnUpdateInstrument.Subscribe(this.onUpdateInstrument, this);
    }

    private initializeVariables (): void {
        const quickTableRactive = super.findAllComponents('quickTableRactive')[0] as QuickTableRactive;
        this._quickTable = quickTableRactive.quickTable;
        this._isInitialized = true;
    }

    private populateTableContextMenu (): void {
        const contextMenuItems = [];
        if (!Resources.isHidden('contextMenu.Totals.visibility')) {
            const viewItem = {
                text: Resources.getResource('panel.positions.menu.View'),
                locKey: 'panel.positions.menu.View',
                tag: 'View',
                enabled: true,
                subitems: []
            };
            viewItem.subitems.push({
                text: Resources.getResource('panel.optionPaperPositions.menu.ShowTotals'),
                tag: 'ShowTotals',
                checked: false,
                enabled: true,
                canCheck: true,
                event: super.ShowTotalsStateChange.bind(this)
            });
            contextMenuItems.push(viewItem);
        }
        this.AddSymbolInfoContextMenuItemIfNeed(contextMenuItems);
        this._quickTable.setTableContextMenuItems(contextMenuItems);
        this.menuTagDict = TerceraMenu.createTagDictionary(contextMenuItems);
    }

    private onVisibleChanged (): void {
        if (!this._isInitialized) {
            return;
        }
        this.layoutTable();
    }

    private getTableRow (paperPosition: PaperPosition): QuickTableRow {
        for (let i = 0; i < this._quickTable.rowsArray.length; i++) {
            const row = this._quickTable.rowsArray[i];
            if (row.isGroupRow) {
                continue;
            }
            const item: PaperPositionItem = row.item;
            if (item.savedOrder === paperPosition) {
                return row;
            }
        }
        return undefined;
    }

    private onAddPaperPosition (paperPosition: PaperPosition): void {
        let row = this.getTableRow(paperPosition);
        if (!isNullOrUndefined(row)) {
            this.onUpdatePaperPosition(paperPosition);
            return;
        }
        row = this._quickTable.AddItem(new PaperPositionItem(this._optionTrader, paperPosition, SessionSettings));
        this.processEditRow(row);
        row.FillRowByItem(row.item, true);
        this._quickTable.redraw();
        void this.updateButtonsEnability();
    }

    private onRemovePaperPosition (paperPosition: PaperPosition): void {
        const row = this.getTableRow(paperPosition);
        if (isNullOrUndefined(row)) {
            return;
        }
        this._quickTable.RemoveItem(row.item.ItemId);
        this._quickTable.redraw();
        void this.updateButtonsEnability();
    }

    private onUpdatePaperPosition (paperPosition: PaperPosition): void {
        const row = this.getTableRow(paperPosition);
        if (isNullOrUndefined(row)) {
            return;
        }
        this.processEditRow(row);
        row.FillRowByItem(row.item, true);
        if (this._quickTable.groupByColumnIndex === PaperPositionItem.OPERATION_COL_INDEX ||
            this._quickTable.groupByColumnIndex === PaperPositionItem.QUANTITY_COL_INDEX) {
            this._quickTable.regroupAllWithSameCollapseState(); // #122848, #123026
        }
        this._quickTable.redraw();
        void this.updateButtonsEnability();
    }

    private onAfterEditItem (data): void {
        const tableItem: PaperPositionItem = data.row.item;
        switch (data.realColumnIndex) {
        case PaperPositionItem.STRIKE_COL_INDEX:
            this._optionTrader.changeInstrumentForPaperPosition(tableItem.savedOrder.Instrument, data.newValue);
            break;
        case PaperPositionItem.QUANTITY_COL_INDEX:
            if (data.newValue === 0) {
                this._optionTrader.removePaperPosition(tableItem.savedOrder.Instrument);
            } else {
                const sign = Math.sign(data.newValue);
                const lots = Quantity.convertAmountToLots(Math.abs(data.newValue), tableItem.savedOrder.Instrument);
                tableItem.savedOrder.QuantityLots = lots;
                tableItem.savedOrder.Operation = sign > 0 ? OperationType.Buy : OperationType.Sell;
                if (tableItem.savedOrder.OrderType === OrderType.Limit) {
                    tableItem.savedOrder.Price = tableItem.savedOrder.Bid;
                }
            }
            break;
        case PaperPositionItem.ORDER_TYPE_COL_INDEX:
            tableItem.savedOrder.OrderType = data.newValue;
            tableItem.savedOrder.setDefaultPrices();
            tableItem.savedOrder.setLinkedPrice();
            break;
        case PaperPositionItem.PRICE_COL_INDEX:
            tableItem.savedOrder.Price = data.newValue;
            break;
        case PaperPositionItem.STOP_PRICE_COL_INDEX:
            if (tableItem.savedOrder.OrderType === OrderType.TrailingStop) {
                tableItem.savedOrder.StopPrice = OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            } else {
                tableItem.savedOrder.StopPrice = data.newValue;
            }
            break;
        case PaperPositionItem.TIF_COL_INDEX:
            tableItem.savedOrder.TIF = data.newValue.type;
            tableItem.savedOrder.TIFExpiration = data.newValue.expirationTime;
            break;
        case PaperPositionItem.SL_PRICE_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Absolute;
            tableItem.savedOrder.SLTPHolder.StopLossPriceValue = data.newValue === 0 ? NaN : data.newValue;
            break;
        case PaperPositionItem.SLL_PRICE_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Absolute;
            tableItem.savedOrder.SLTPHolder.StopLossLimitPriceValue = data.newValue === 0 ? NaN : data.newValue;
            break;
        case PaperPositionItem.TP_PRICE_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceValue = data.newValue === 0 ? NaN : data.newValue;
            break;

        case PaperPositionItem.SL_OFFSET_COL_INDEX:
            if (tableItem.savedOrder.SLTPHolder.StopLossPriceType === SlTpPriceType.Absolute) {
                tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Offset;
            }
            if (data.newValue === 0) {
                tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Offset;
                tableItem.savedOrder.SLTPHolder.StopLossPriceValue = NaN;
            } else {
                tableItem.savedOrder.SLTPHolder.StopLossPriceValue = OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            }
            break;
        case PaperPositionItem.SLL_OFFSET_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = SlTpPriceType.Offset;
            tableItem.savedOrder.SLTPHolder.StopLossLimitPriceValue = data.newValue === 0 ? NaN : OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            break;
        case PaperPositionItem.TP_OFFSET_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceType = SlTpPriceType.Offset;
            tableItem.savedOrder.SLTPHolder.TakeProfitPriceValue = data.newValue === 0 ? NaN : OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            break;
        case PaperPositionItem.TRAILING_STOP_COL_INDEX:
            tableItem.savedOrder.SLTPHolder.StopLossPriceValue = tableItem.savedOrder.SlOffset;
            tableItem.savedOrder.SLTPHolder.StopLossPriceType = tableItem.savedOrder.SLTPHolder.isTrailingStop() ? SlTpPriceType.Offset : SlTpPriceType.TrOffset;
            break;
        case PaperPositionItem.LINKED_PRICE_OFFSET_COL_INDEX:
            tableItem.savedOrder.LinkedPriceOffset = OrderUtils.toRawTicks(data.newValue, GeneralSettings.TradingDefaults.ShowOffsetIn, tableItem.savedOrder.Instrument);
            tableItem.savedOrder.setLinkedPrice();
            break;
        case PaperPositionItem.LINK_COL_INDEX:
            tableItem.savedOrder.LinkedPriceType = data.newValue;
            tableItem.savedOrder.setLinkedPrice();
            break;

        case PaperPositionItem.LEVERAGE_COL_INDEX:
            tableItem.savedOrder.Leverage = data.newValue;
            break;

        case PaperPositionItem.SEND_COL_INDEX:
            tableItem.savedOrder.Active = !tableItem.savedOrder.Active;
            break;
        case PaperPositionItem.ANALYSE_COL_INDEX:
            tableItem.savedOrder.Analyse = !tableItem.savedOrder.Analyse;
            break;
        }
        this._optionTrader.updatePaperPosition(tableItem.savedOrder.Instrument);
        this.processEditRow(data.row);
        data.row.FillRowByItem(tableItem, true);
        this.updateButtonsEnability();
        this._quickTable.redraw();
    };

    private processEditRow (row: QuickTableRow): void {
        const savedOrder: PaperPosition = row.item.savedOrder;
        for (let i = 0; i < row.cells.length; i++) {
            if (savedOrder.isPosition() && (i !== PaperPositionItem.ANALYSE_COL_INDEX && i !== PaperPositionItem.OPERATION_COL_INDEX)) {
                row.cells[i].ReadOnly = true;
                continue;
            }

            switch (i) {
            case PaperPositionItem.STRIKE_COL_INDEX:
                row.cells[i].ReadOnly = !savedOrder.Instrument.isOptionSymbol;
                break;
            case PaperPositionItem.PRICE_COL_INDEX:
                if (savedOrder.LinkedPriceType !== LinkedPriceType.None) {
                    row.cells[i].ReadOnly = true;
                } else {
                    row.cells[i].ReadOnly = savedOrder.OrderType !== OrderType.Limit && savedOrder.OrderType !== OrderType.StopLimit && savedOrder.OrderType !== OrderType.OCO;
                }
                break;
            case PaperPositionItem.STOP_PRICE_COL_INDEX:
                if (savedOrder.LinkedPriceType !== LinkedPriceType.None) {
                    row.cells[i].ReadOnly = true;
                } else {
                    row.cells[i].ReadOnly = savedOrder.OrderType !== OrderType.Stop && savedOrder.OrderType !== OrderType.StopLimit && savedOrder.OrderType !== OrderType.TrailingStop && savedOrder.OrderType !== OrderType.OCO;
                }
                break;
            case PaperPositionItem.LINK_COL_INDEX:
                row.cells[i].ReadOnly = savedOrder.OrderType !== OrderType.Limit && savedOrder.OrderType !== OrderType.Stop;
                break;
            case PaperPositionItem.LINKED_PRICE_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = savedOrder.LinkedPriceType === LinkedPriceType.None || (savedOrder.OrderType !== OrderType.Limit && savedOrder.OrderType !== OrderType.Stop);
                break;
            case PaperPositionItem.LEVERAGE_COL_INDEX:
                row.cells[i].ReadOnly = !isNullOrUndefined(savedOrder.Instrument) && !savedOrder.Instrument.isLeverageVisible(savedOrder.Account, savedOrder.ProductType);
                break;
            case PaperPositionItem.OPERATION_COL_INDEX:
                row.cells[i].ForeColor = savedOrder.Operation === OperationType.Buy ? ThemeManager.CurrentTheme.TableLongBuyColor : ThemeManager.CurrentTheme.TableShortSellColor;
                break;

            case PaperPositionItem.SL_PRICE_COL_INDEX:
                row.cells[i].ReadOnly = (isNaN(savedOrder.SlPrice) && !SavedOrderValidator.isAllowSl(savedOrder)) || savedOrder.SLTPHolder.StopLossPriceType === SlTpPriceType.TrOffset;
                break;
            case PaperPositionItem.SL_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = isNaN(savedOrder.SlPrice) && !SavedOrderValidator.isAllowSl(savedOrder);
                break;
            case PaperPositionItem.TRAILING_STOP_COL_INDEX:
                row.cells[i].ReadOnly = isNaN(savedOrder.SlOffset);
                break;

            case PaperPositionItem.SLL_PRICE_COL_INDEX:
            case PaperPositionItem.SLL_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = isNaN(savedOrder.SllPrice) && (!SavedOrderValidator.isAllowSl(savedOrder) || !GeneralSettings.TradingDefaults.UseStopLimitInsteadStop);
                break;

            case PaperPositionItem.TP_PRICE_COL_INDEX:
            case PaperPositionItem.TP_OFFSET_COL_INDEX:
                row.cells[i].ReadOnly = isNaN(savedOrder.TpPrice) && !SavedOrderValidator.isAllowTp(savedOrder);
                break;
            }
        }
    }

    private onTableRowButtonClicked (data): void {
        const tableItem: PaperPositionItem = data.row.item;

        switch (data.realColumnIndex) {
        case PaperPositionItem.REMOVE_COL_INDEX:
            this._optionTrader.removePaperPosition(tableItem.savedOrder.Instrument);
            break;
        }
    }

    private async updateButtonsEnability (): Promise<void> {
        const rows = this._quickTable.rowsArray;
        let haveActiveRows = false;
        let isTradingAllowed = true;
        let isCancelAllEnabled = false;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: PaperPositionItem = rows[i].item;
            if (!isCancelAllEnabled && !item.savedOrder.isPosition()) {
                isCancelAllEnabled = true;
            }
            if (!item.savedOrder.Active) {
                continue;
            }
            haveActiveRows = true;
            isTradingAllowed = IsAllowed.IsTradingAllowed([item.savedOrder.Account], item.savedOrder.Instrument, item.savedOrder.OrderType).Allowed;
            if (!isTradingAllowed) {
                break;
            }
        }
        await this.set('isPlaceSelectedEnabled', haveActiveRows && isTradingAllowed);
        await this.set('isCancelAllEnabled', isCancelAllEnabled);
    }

    private onAddUnderlierBtnClicked (): void {
        MainWindowManager.Factory?.addPanel(PanelNames.AdvancedOrderEntry, null, this.onOpenOrderEntryPanel);
    }

    private onCancelAllBtnClicked (): void {
        this._optionTrader.clearPaperPositions();
    }

    private async onPlaceSelectedBtnClicked (): Promise<void> {
        const rows: QuickTableRow[] = this._quickTable.rowsArray;
        const ordersForPlace: PaperPosition[] = [];
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: PaperPositionItem = rows[i].item;
            if (item.savedOrder.Active) {
                ordersForPlace.push(item.savedOrder);
            }
        }
        await SavedOrdersController.placeOrders(ordersForPlace, PlacedFrom.WEB_OPTION_MASTER);
    }

    private readonly onOpenOrderEntryPanel = (panel: any): void => {
        panel.OnClose.Subscribe(this.onCloseOrderEntryPanel, this);
        panel.set({
            placeButtonAdditionalClass: 'alertStyle',
            placeButtonLocalizationKey: 'panel.newOrderEntry.AddPaperPosition',
            accountVisible: false,
            isEnabledInstrumentLookup: false,
            isEnabledAccountLookup: false,
            isCreateOrderParmeters: true,
            showCustomFullscreenCloud: true,
            account: this._optionTrader.account,
            instrument: this._optionTrader.underlier,
            forbiddenOrderTypes: [OrderType.OCO]
        });
        panel.NewParametrsHandler = this.onCreateSavedOrder;
    };

    private readonly onCreateSavedOrder = (orderEdit: OrderEditBase): void => {
        this._optionTrader.createPaperPositionFromOrderEdit(orderEdit);
        orderEdit.dispose();
    };

    private readonly onCloseOrderEntryPanel = (panel: any): void => {
        panel.OnClose.UnSubscribe(this.onCloseOrderEntryPanel, this);
        panel.NewParametrsHandler = null;
    };
}

ApplicationPanelWithTable.extendWith(OptionPaperPositionsPanel, {
    template: OptionPaperPositionsPanelTemplate,
    data: function () {
        return {
            addUnderlierButtonText: '',
            cancelAllButtonText: '',
            placeSelectedButtonText: '',
            shortPositionsAreForbiddenText: '',
            isPlaceSelectedEnabled: false,
            isCancelAllEnabled: false,
            shortPositionsAreForbiddenVisibility: false
        };
    }
});

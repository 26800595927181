// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection, ConntionInfo } from '../../Commons/Connection';
import { ConectionResultEnum, ConnectionStates, RegistrationMode } from '../../Commons/ConnectionEnums';
import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { IsPasteEvent, KeyCode } from '../../Commons/KeyEventProcessor';
import { TerceraBaseWindowTemplate, TerceraLoginScreenTemplate } from '../../templates';
import { AdditionalVerificationScreen } from './AdditionalVerificationScreen';
import { TerceraRecoverScreen } from '../screen/TerceraRecoverScreen';
import { TerceraWindowBase } from './TerceraWindowBase';
import { ThemeManager } from '../misc/ThemeManager';
import { BrowserUtils } from '../../Commons/UtilsClasses/BrowserUtils';
import { NewNetTraderMode } from './NewNetTraderMode';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';
import { TerceraChangePasswordScreen } from './TerceraChangePasswordScreen';
import { ApplicationInfo } from '../../Commons/ApplicationInfo';
import { LocalStorage } from '../../Commons/LocalStorage';
import { ApplicationLoader } from '../../Commons/ApplicationLoader';
import { BrandingUtils } from '../../Commons/UtilsClasses/BrandingUtils';
import { AuthenticationStateStorage } from '../../Utils/ThirdPartyAuthentication/AuthenticationStateStorage';
import { AuthenticationFactory } from '../../Utils/ThirdPartyAuthentication/AuthenticationFactory';
import { AuthenticationResponseType } from '../../Utils/ThirdPartyAuthentication/AuthenticationResponseType';
import { decryptWithDerivedKey, encryptAndDeriveKey } from '../../Utils/EncryptionUtils';
import { TerceraButtonEvents } from '../../Utils/Enums/ButtonEnums';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';
import { MessageBoxType, TerceraMessageBox } from './TerceraMessageBox';

export class TerceraLoginScreen extends TerceraWindowBase {
    public wasPaste: boolean = false;
    public forceCloseOnLogout: boolean = false;

    public override getType (): ScreensNames { return ScreensNames.TerceraLoginScreen; }

    public override oninit (): void {
        super.oninit();

        void this.set('allowPasswordRecovery', !Resources.isHidden('screen.LoginScreen.PasswordRecovery'));
        void this.set('createDemoAccountAllowed', !Resources.isHidden('screen.LoginScreen.CreateDemoAccount'));

        this.observe('connectionInfo', this.onConnectionInfoChanged);
        this.observe('selectedConnectionInfoMenuItem', this.onSelectedConnectionInfoMenuItemChanged);

        this.observe('needLogin', this.PlaceholderLoginChanged);
        this.observe('needPassword', this.PlaceholderPasswordChanged);
        this.observe('needCustomerNo', this.PlaceholderCustomerNoChanged);

        this.observe('inputMode', this.onInputModeChanged);
        this.observe('allowPasswordRecovery inputMode IsCorrectForgotLink', () => {
            void this.set('forgotPasswordVisible', this.get('allowPasswordRecovery') && this.get('inputMode') && this.get('IsCorrectForgotLink')); // updating recover link visibility
        });

        this.on('recoverClick', this.onRecoverClicked);
        this.on('exploreClick', this.onExploreClicked);
        this.on('onThirdPartyLoginBtnClick', this.onThirdPartyLoginBtnClicked);
        this.observe('AllowCustomerNo', this.onAllowCustomerNoChanged);

        this.observe('connectionParams.login', this.onConnectionParamsLoginChanged);
        this.observe('connectionParams.pass', this.onConnectionParamsPassChanged);
        this.observe('connectionParams.customerNo', this.onConnectionParamsCustomerNoChanged);
        this.observe('passwordTextBoxFocused', this.onPasswordFocused);

        this.loadCredentials();

        const findSelectedLangItem = function () {
            const items = Resources.getLanguagesCBItems();
            const cLang = Resources.GetCurrentAppLang();
            for (let i = 0; i < items.length; i++) {
                if (items[i].value === cLang) {
                    this.set('selectedlangItem', items[i]);
                    break;
                }
            }
        }.bind(this);

        this.observe('visible', (visible) => {
            if (!visible) {
                return;
            }
            if (ApplicationInfo.login_language_selector) {
                findSelectedLangItem();
            }
            this.localize();
        });

        void this.set('login_language_selector', ApplicationInfo.login_language_selector);
        if (ApplicationInfo.login_language_selector) {
            void this.set('height', this.get('height'));
            const items = Resources.getLanguagesCBItems();
            void this.set('langItems', items);
            findSelectedLangItem();
            this.observe('selectedlangItem', this.onSelectedlangItemChanged);
        }
        this.localize();

        ThemeManager.onThemeChange.Subscribe(this.onThemeChanged, this);
    }

    public override oncomplete (): void {
        Connection?.onConnectStateChange.Subscribe(this.connectionStateChange, this);
        const netTrForm = this.get('parentContainerControl');
        netTrForm.onNetTraderModeChange.Subscribe(function (newState) {
            if (newState === NewNetTraderMode.WorkMode) {
                this.set({ textErrorLabel: ' ' });
            }

            BrandingUtils.NewNetTraderModeChanged(newState === NewNetTraderMode.WorkMode);
        }, this);
        this.Controls.loginBtn.on(TerceraButtonEvents.onClick, this.startLogin.bind(this));
        // this.Controls.closeLoginScr.on(TerceraButtonEvents.onClick, this.closeLoginScrClick.bind(this))

        this.Controls.tbLogin.on('keyDown', this.onLoginKeyDown.bind(this));
        this.Controls.tbPwd.on('keyDown', this.onPasswordKeyDown.bind(this));

        this.Controls.tbLogin.getInputElement().addEventListener('input', this.trimStringIfWasPaste.bind(this));
        this.Controls.tbPwd.getInputElement().addEventListener('input', this.trimStringIfWasPaste.bind(this));

        // TODO. Ugly. Refactor.
        void this.set('connectionInfo', ConntionInfo.ConnectionInfoArray);

        void this.set({ inputMode: ApplicationInfo.LoginScreenType === 0 });
        const parsedURL = BrowserUtils.getUrlVars(location.href);
        const tokenFromURL = (parsedURL?.hasOwnProperty('token')) ? parsedURL.token : null;
        const tokenToSend = ApplicationInfo.LoginToken || tokenFromURL;
        const restoredConnection = AuthenticationStateStorage.restoreConnection(window.location.search);
        if (isValidString(restoredConnection)) {
            const connectionInfoMenuItems = this.get('connectionInfoMenuItems');
            for (let i = 0; i < connectionInfoMenuItems.length; i++) {
                if (connectionInfoMenuItems[i].text === restoredConnection) {
                    void this.set('selectedConnectionInfoMenuItem', connectionInfoMenuItems[i]).then(() => {
                        this.processThirdPartyLogin();
                    });
                    break;
                }
            }
        } else if (tokenToSend && ApplicationInfo.ExternalResourse !== -1) {
            void this.set({ inputMode: false });
            void this.onConnectionParamsCustomerIpURL(this.get('selectedConnectionInfoMenuItem').value.ipURL).then((res) => {
                void this.set('connectionParams.ipURL', res);
                this.StartConnect(null, tokenToSend);
            });
        }

        this.portfolioAutoLogin();
    }

    get ChangePasswordScreen (): TerceraChangePasswordScreen {
        return MainWindowManager.ChangePasswordScreen;
    }

    public getLoginScreenLanguage (): string {
        if (!ApplicationInfo.login_language_selector) {
            return '';
        }

        const langItem = this.get('selectedlangItem');
        return langItem ? langItem.value : '';
    }

    public onInputModeChanged (inputMode): void {
    //     // if (!inputMode)
    //     //     this.set({ height: 156, style_addition_body: 'login-screen-additional-Token' });
    //     // else
    //     //     this.set({ height: 440, style_addition_body: 'login-screen-additional-Full' });
    }

    public onAllowCustomerNoChanged (isAllow): void {
        void this.set({ customerNoIndent: (isAllow ? 47 : 0) });
    }

    public onSelectedlangItemChanged (n, o): void {
        if (n && n === o) {
            return;
        }
        Resources.setLocale(n.value);
    }

    public onConnectionInfoChanged (connectionInfo): void {
        if (!connectionInfo?.length) {
            void this.set('connectionInfoMenuItems', []);
        }

        const lastConnectionName = LocalStorage.getLastConnectionName() || '';
        let lastConnection = null;

        const menuItems = [];
        const len = connectionInfo.length;
        for (let i = 0; i < len; i++) {
            const connInfo = connectionInfo[i];
            const name = connInfo.Name;
            const newItem = { text: name, value: connInfo };
            if (lastConnectionName === name) {
                lastConnection = newItem;
            }
            menuItems.push(newItem);
        }
        void this.set('connectionInfoMenuItems', menuItems);
        if (lastConnection) {
            void this.set('selectedConnectionInfoMenuItem', lastConnection);
            void this.setCredentialsByConnName(lastConnectionName);
        }
    }

    public onSelectedConnectionInfoMenuItemChanged (selectedMenuItem): void {
        if (selectedMenuItem) {
            const selectedConnectionData = selectedMenuItem.value;
            const serverURL = selectedConnectionData.ServerAdress;
            void this.onConnectionParamsCustomerIpURL(selectedConnectionData.ipURL).then((res) => {
                if (this.get('selectedConnectionInfoMenuItem') != selectedMenuItem) {
                    return;
                }

                void this.set('connectionParams.ipURL', res);

                const isSSL = selectedConnectionData.isSSL;
                const brKey = selectedConnectionData.brandingKey;
                const regLink = selectedConnectionData.registrationLink;
                const forgotLink = selectedConnectionData.forgotURL;

                void this.set('connectionParams.connectionName', selectedConnectionData.Name);
                void this.set('connectionParams.ServerAdress', serverURL);
                void this.set('connectionParams.isSSL', isSSL);
                void this.set('connectionParams.forgotURL', forgotLink);
                void this.set('connectionParams.registrationLink', regLink);
                void this.set('connectionParams.brandingKey', brKey);
                void this.set({
                    IsCorrectForgotLink: forgotLink != -1,
                    ICFRPL: regLink != -1 && regLink,
                    AllowCustomerNo: selectedConnectionData.AllowCustomerNo,
                    needCustomerNo: false,
                    placeholderCustomerNo: Resources.getResource('screen.LoginScreen.CustomerNo')
                });
                void this.set('allowExplore', !!selectedConnectionData.exploreTheAppUser);
                void this.set('connectionParams.exploreTheAppUser', selectedConnectionData.exploreTheAppUser);
                void this.set('connectionParams.exploreTheAppPassword', selectedConnectionData.exploreTheAppPassword);
                void this.set('connectionParams.exploreTheAppLink', selectedConnectionData.exploreTheAppLink);
                void this.set('connectionParams.allowPreviewPassword', selectedConnectionData.allowPasswordPreview);
                void this.set('thirdPartyProvider', AuthenticationFactory.create(selectedConnectionData.BrandingExternalAuthenticationType, selectedConnectionData.BrandingExternalAuthenticationUrl, selectedConnectionData.BrandingExternalAuthenticationWebClientId));

                void this.setCredentialsByConnName(selectedConnectionData.Name);

                this.setChatLinkIfNeed(); // #113542

                this.setTopForSomeControls();

                this.SetWebTerminalSettings(selectedConnectionData, brKey);
            });
        }
    };

    public SetWebTerminalSettings (selectedConnectionData, brandingKey: string): void { // settings from 'Web terminal' tab of BackOffice's: Configuration -> Branding
        const mainWin = MainWindowManager.MainWindow;
        const applicationType = mainWin.getType();

        const headerPropName = BrandingUtils.GetWebHeaderPropName(applicationType);
        if (headerPropName !== '') {
            const header = selectedConnectionData[headerPropName];
            mainWin.setBrandingWebHeader(header, brandingKey);
        }

        const pageTitlePropName = BrandingUtils.GetTitlePropName(applicationType);
        BrandingUtils.SetWebPageTitle(selectedConnectionData[pageTitlePropName]);

        const faviconPropName = BrandingUtils.GetFaviconPropName(applicationType);
        BrandingUtils.SetWebFavicon(selectedConnectionData[faviconPropName], faviconPropName, brandingKey);

        this.SetSplashScreen(selectedConnectionData);
        const bannerPropName = BrandingUtils.GetLoginBannerPropName(applicationType);
        this.SetWebLoginBanner(selectedConnectionData[bannerPropName], bannerPropName, brandingKey);
        this.SetBrandingLocales(selectedConnectionData.Name);

        if (BrandingUtils.NeedToSetCustomCSS(applicationType)) {
            this.SetInvestingCustomScheme(selectedConnectionData.Name);
        }
    }

    public SetInvestingCustomScheme (connData): void {
    // implemented only for InvestingLoginScreen
    }

    public SetSplashScreen (connData): void {
        if (connData) {
            BrandingUtils.SetWebSplashScreen(connData.BrandingWebSplashScreen, connData.brandingKey);
        }
    }

    public SetBrandingLocales (conName): any {
        return ApplicationLoader(conName);
    }

    public SetWebLoginBanner (relativeImgPath, propName, brandingKey): void {
        void this.set({
            'connectionParams.webLoginBanner': relativeImgPath, // <- to not replace branding banner with default banner in onThemeChanged
            pictureUrl: BrandingUtils.GetWebLoginBanner(relativeImgPath, this.get('pictureUrl'), propName, brandingKey) // caching in BrandingUtils
        });
    }

    public onConnectionParamsLoginChanged (newVal): void {
        if (this.get('needLogin') && newVal) {
            void this.set('needLogin', false);
        }
    }

    public onConnectionParamsPassChanged (newVal): void {
        if (this.get('needPassword') && newVal) {
            void this.set('needPassword', false);
        }

        void this.set('savePasswordCheckedFromConnectionSettings', false);
    }

    public onConnectionParamsCustomerNoChanged (newVal): void {
        if (this.get('needCustomerNo') && newVal) {
            void this.set('needCustomerNo', false);
        }
    }

    public async onConnectionParamsCustomerIpURL (url): Promise<any> {
        return await new Promise((resolve, reject) => {
            if (!url) {
                resolve(null); return;
            }

            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState !== 4) {
                    return;
                }

                if (this.status !== 200) {
                    resolve(null); return;
                }

                resolve(this.responseText.trim());
            };
            xhr.send();
        });
    }

    public PlaceholderLoginChanged (newVal): void {
        if (!newVal) {
            void this.set('placeholderLogin', Resources.getResource('screen.LoginScreen.LoginIDLabel'));
        } else {
            void this.set('placeholderLogin', Resources.getResource('screen.LoginScreen.Error.loginIdIsEmpty'));
        }
    }

    public PlaceholderPasswordChanged (newVal): void {
        if (!newVal) {
            void this.set('placeholderPassword', Resources.getResource('screen.LoginScreen.PasswordLabel'));
        } else {
            void this.set('placeholderPassword', Resources.getResource('screen.LoginScreen.Error.passwordIdIsEmpty'));
        }
    }

    public PlaceholderCustomerNoChanged (newVal): void {
        if (!newVal) {
            void this.set('placeholderCustomerNo', Resources.getResource('screen.LoginScreen.CustomerNo'));
        } else {
            void this.set('placeholderCustomerNo', Resources.getResource('screen.LoginScreen.Error.CustomerNoIsEmpty'));
        }
    }

    public onLoginKeyDown (e): void {
        if (!this.get('inputMode')) {
            return;
        }

        const keyDownEvent = e.original;
        if (IsPasteEvent(keyDownEvent)) {
            this.wasPaste = true;
        } else if (keyDownEvent.keyCode === KeyCode.ENTER) {
            if (this.get('savePasswordCheckedFromConnectionSettings')) {
                this.startLogin();
                return;
            }
            // TODO. Make it work. this.Controls.tbPwd.setFocus();
            // So instead of using the method above:
            this.Controls.tbPwd.set('focused', true);
        }
    }

    public onPasswordKeyDown (e): void {
        if (!this.get('inputMode')) {
            return;
        }

        const keyDownEvent = e.original;
        if (IsPasteEvent(keyDownEvent)) {
            this.wasPaste = true;
        } else if (keyDownEvent.keyCode === KeyCode.ENTER) {
            this.startLogin();
        }
    }

    public startLogin (context?, verificationPassword?, isExploreMode?): void {
        if (this.get<boolean>('isOpenErrorMessageScreen')) { return null; }

        ApplicationInfo.isExploreMode = !!isExploreMode;
        ApplicationInfo.exploreTheAppLink = ApplicationInfo.isExploreMode ? this.get('connectionParams.exploreTheAppLink') : '';

        const noLogin = !this.get('connectionParams.login') && !ApplicationInfo.isExploreMode;
        const noPass = !this.get('connectionParams.pass') && !ApplicationInfo.isExploreMode;
        const noCustomerNo = !this.get('connectionParams.customerNo') && !ApplicationInfo.isExploreMode;

        void this.set({
            needLogin: noLogin,
            needPassword: noPass,
            needCustomerNo: noCustomerNo
        });

        const AllowCustomerNo = this.get('AllowCustomerNo');

        if (noLogin || noPass || (noCustomerNo && AllowCustomerNo)) {
            return;
        }

        if (!AllowCustomerNo) {
            void this.set('connectionParams.customerNo', '');
        }

        const state = Connection.getState();
        switch (state) {
        case ConnectionStates.CONNECTED:
            Connection.disconnect();
            void this.set({ textErrorLabel: 'Disconnect...', isDisconnect: true });
            break;
        case ConnectionStates.DISCONNECTED:
        case ConnectionStates.CONNECTION_LOST:
            this.StartConnect(verificationPassword);
            break;
        case ConnectionStates.CONNECTING:
            break;
        default:
            break;
        }
    };

    public StartConnect (verificationPassword?, token?): void {
        const connectData = this.get('connectionParams');
        if (verificationPassword && verificationPassword.getType === undefined) {
            connectData.verificationPassword = verificationPassword;
        } else {
            connectData.verificationPassword = '';
        }
        void this.set({ textErrorLabel: Resources.getResource('screen.LoginScreen.Connecting'), isDisconnect: false });
        connectData.ServerAdressesArray = connectData.ServerAdress.split(',');
        for (let i = 0, len = connectData.ServerAdressesArray.length; i < len; i++) {
            if (connectData.ServerAdressesArray[i]) {
                connectData.ServerAdressesArray[i] = connectData.ServerAdressesArray[i].trim();
            }
        }
        connectData.reconnect = false;
        if (ApplicationInfo.BuildVersionData) {
            connectData.build_date_day = ApplicationInfo.BuildVersionData.date_day;
        }

        if (token) {
            const thirdPartyProvider = this.get('thirdPartyProvider');
            if (!isNullOrUndefined(thirdPartyProvider)) {
                connectData.login = '';
                connectData.TokenID = token;
                connectData.ExternalResourse = thirdPartyProvider.authenticationType;
            } else if (ApplicationInfo.ExternalResourse !== -1) {
                connectData.login = ''; // Do not use user from local storage https://tp.traderevolution.com/entity/89399
                connectData.TokenID = token;
                connectData.ExternalResourse = ApplicationInfo.ExternalResourse; // ExternalResource.HUB;//null;
            }
        }

        if (!ApplicationInfo.myportfolio) {
            connectData.accessToken = '';
        }

        if (ApplicationInfo.isExploreMode) {
            connectData.login = connectData.exploreTheAppUser;
            connectData.pass = connectData.exploreTheAppPassword;
        }

        Connection.connect(connectData);
    }

    public connectionStateChange (): void {
        const state = Connection.getState();
        let btnText;
        switch (state) {
        case ConnectionStates.CONNECTED:
            btnText = Resources.getResource('screen.LoginScreen.LoginButtonText');
            void this.set({ textButton: btnText, textErrorLabel: Resources.getResource('screen.LoginScreen.LoadingUserData') });
            void this.handleCredentialsAfterConnect();
            void this.set('connectionParams.pass', '');
            break;
        case ConnectionStates.DISCONNECTED:{
            void this.set('reconnectConnectionParams', null);
            const savedPass = this.get('connectionParams.pass');

            const savePasswordCh = this.get('savePasswordChecked');
            if (!savePasswordCh) {
                void this.set('connectionParams.pass', '');
            }

            btnText = Resources.getResource('screen.LoginScreen.LoginButtonText');
            void this.set({ textButton: btnText, isDisconnect: true });
            const err = Connection.getLocalizedConnectionErrorString(this.get('connectionParams.login'));
            if (isValidString(err)) {
                this.openErrorMessageScreen(err);
            } else {
                void this.set('textErrorLabel', '');
                this.Controls.tbPwd.set('passwordHided', true);
            }

            void this.set('savePasswordCheckedFromConnectionSettings', savePasswordCh && !err); // to prevent the password preview from being hidden when "Save Password" is checked but the entered password is incorrect

            const lastResp = Connection.lastConnectionAttemptResponse;
            if (lastResp.connectReason === ConectionResultEnum.NeedActivation || lastResp.connectReason === ConectionResultEnum.AdditionalVerification) {
                const okCallBack = function (verificPass) { this.startLogin(undefined, verificPass); }.bind(this);
                const cancelCallBack = function () {
                    this.set('textErrorLabel', '');
                    this.set('isDisconnect', true);
                    this.set('connectionParams.pass', '');
                }.bind(this);
                void this.set('connectionParams.pass', savedPass);
                void this.set('isDisconnect', false);
                AdditionalVerificationScreen.Show(lastResp, okCallBack, cancelCallBack, this.get('connectionParams'));
            }
            if (lastResp.connectReason === ConectionResultEnum.NeedChangePassword) {
                this.openErrorMessageScreen(Resources.getResource('screen.login.change_pass'));
            }

            if (lastResp.connectReason === ConectionResultEnum.InvalidBrandingKey) {
                this.openErrorMessageScreen(Resources.getResource('screen.login.bad_branding_key'));
            }

            break;
        }

        case ConnectionStates.CONNECTION_LOST:
            WorkSpaceManager.needRestoreWS = true;
            break;
        case ConnectionStates.CONNECTING:
            break;

        case ConnectionStates.HALFCONNECTED:{
            const lastResp = Connection.lastConnectionAttemptResponse;
            const lastConnectionParams = this.get('connectionParams');

            const cancelCB = (): void => { Connection.disconnect(); };
            this.showChangePwdScreen(lastResp.UserID, lastConnectionParams.pass, cancelCB);

            break;
        }

        default:
            btnText = 'Default';
            void this.set({ textButton: btnText, textErrorLabel: '' });
            break;
        }
    }

    public showChangePwdScreen (UserID, OldPass, cancelCB, isTradingPassword?): void {
        TerceraChangePasswordScreen.show(UserID, OldPass, cancelCB, isTradingPassword);
    }

    private openErrorMessageScreen (errorText): void {
        void this.set({ textErrorLabel: '', isOpenErrorMessageScreen: true });
        const callBack = (): void => { this.closeErrorMessageScreen(); };

        TerceraMessageBox.Show(
            Resources.getResource('screen.LoginScreen.LoginError'),
            errorText,
            MessageBoxType.Warning,
            callBack,
            callBack,
            false, true, null,
            {
                style_addition_header: '',
                okText: Resources.getResource('general.messageBox.ok')
            },
            true);
    }

    private closeErrorMessageScreen (): void {
        void super.set('isOpenErrorMessageScreen', false);
    }

    public async handleCredentialsAfterConnect (): Promise<void> {
        const connectionParams = this.get('connectionParams');
        if (!this.get('reconnectConnectionParams')) {
            void this.set('reconnectConnectionParams', {
                login: connectionParams.login,
                // pass: connectionParams.pass, // commented out due to #123655
                allowPasswordPreview: connectionParams.allowPreviewPassword,
                connectionName: connectionParams.connectionName,
                customerNo: connectionParams.customerNo
            });
        }

        const key = ConnectionStates.CONNECTED + connectionParams.login;
        const encryptedPassword = this.get('savePasswordChecked') ? await encryptAndDeriveKey(connectionParams.pass, key) : null;
        LocalStorage.setConnectionSettings(connectionParams.connectionName,
            connectionParams.login,
            encryptedPassword,
            connectionParams.customerNo);
        LocalStorage.setLastConnectionName(connectionParams.connectionName);
    }

    public loadCredentials (): void {
        LocalStorage.loadConnectionSettings();
    }

    public async setCredentialsByConnName (name): Promise<void> {
        const [u, p, cN] = LocalStorage.getConnectionSettings(name);

        const allowSavePassword = this.get('selectedConnectionInfoMenuItem.value.allowSavePassword');
        const savePasswordChecked = p != null && allowSavePassword;
        const key = ConnectionStates.CONNECTED + u;
        const decryptedPassword = savePasswordChecked ? await decryptWithDerivedKey(p, key) : '';

        this.Controls.tbPwd?.set('passwordHided', true);
        void this.set({
            'connectionParams.login': u,
            'connectionParams.customerNo': cN,
            'connectionParams.pass': decryptedPassword,
            savePasswordChecked
        }).then(() => {
            void this.set('savePasswordCheckedFromConnectionSettings', decryptedPassword != '');
        });
    }

    // public override closeLoginScrClick ()
    // {
    //     var netTrForm = this.get('parentContainerControl');
    //     netTrForm.set({ netTraderState: NewNetTrader.Mode.WorkMode });
    // };

    public onRecoverClicked (): void {
        const connectionParams = this.get('connectionParams');
        const forgotURL = connectionParams.forgotURL;

        if (forgotURL === '') {
            const terceraRecoverScreen = new TerceraRecoverScreen();
            terceraRecoverScreen.setBounds(100, 100, 240, 370);
            void terceraRecoverScreen.set({
                connectionName: connectionParams.connectionName,
                valueLogin: connectionParams.login,
                allowPasswordPreview: connectionParams.allowPreviewPassword
            });
            MainWindowManager.MainWindow.Controls.loginWindowsContainer.addControl(terceraRecoverScreen);
        } else {
            window.open(forgotURL);
        } // #83944
    }

    public onExploreClicked (): void {
        this.startLogin(undefined, undefined, true);
    }

    public onThirdPartyLoginBtnClicked (): void {
        const thirdPartyProvider = this.get('thirdPartyProvider');
        AuthenticationStateStorage.saveConnection(thirdPartyProvider.uuid, this.get('connectionParams.connectionName'));
        window.location.href = thirdPartyProvider.authenticationAddress;
    }

    public processThirdPartyLogin (): void {
        const service = this.get('thirdPartyProvider');
        const search = window.location.search;
        if (isNullOrUndefined(service) || !isValidString(search)) {
            return;
        }
        const response = service.processResponse(search);
        switch (response.ResponseType) {
        case AuthenticationResponseType.Success:
            this.StartConnect(undefined, response.Result);
            break;
        case AuthenticationResponseType.Error:
            void this.set('textErrorLabel', response.Result);
            break;
        }
    }

    public setChatLinkIfNeed (): boolean // #113542 will return true if chat link was set
    {
        const chatLink = !Resources.isHidden('screen.LoginScreen.ChatLink.visible')
            ? Resources.getResource('screen.LoginScreen.ChatLink.url')
            : null;

        void this.set('connectionParams.chatLink', chatLink);
        return !!chatLink;
    }

    public setTopForSomeControls (): void {
        const data = this.get();
        const cNI = data.customerNoIndent;
        const inputMode = data.inputMode;
        const chatLinkVisible = data.connectionParams ? !!data.connectionParams.chatLink : false;
        const forgotVisible = data.forgotPasswordVisible;
        const exploreVisible = data.allowExplore && inputMode;
        const regLinkVisible = data.createDemoAccountAllowed && data.ICFRPL;

        const loginTBTop = cNI + 172; // login text box top
        const passTBTop = cNI + 219; // password text box top
        const langSelTop = cNI + 266; // language (combobox) selector top
        let forgotLinkTop = 395; // forgot password link(btn) top
        let exploreLinkTop = 395; // explore link(top) top
        let regLinkTop = 397; // demo registration link top
        let chatLinkTop = chatLinkVisible ? 395 : 0; // live chat link top #113542
        const loginBtnTop = regLinkVisible ? 334 : 354; // log in button's top
        const errorTextTop = inputMode ? 413 : 128; // text area of connection status/errors top

        if (chatLinkVisible) {
            if (forgotVisible) {
                forgotLinkTop -= 10;
                chatLinkTop += 5;
                regLinkTop -= 5;
            } else {
                regLinkTop -= 10;
            }
        }

        if (regLinkVisible) {
            forgotLinkTop -= 20;
            if (chatLinkVisible) {
                regLinkTop -= 10;
            }
        }

        if (exploreVisible) {
            if (forgotVisible) {
                forgotLinkTop -= 10;
            }
            exploreLinkTop = forgotVisible ? forgotLinkTop + 17 : forgotLinkTop;
            regLinkTop = regLinkVisible ? exploreLinkTop + 17 : regLinkTop;
        }

        void this.set({
            loginTBTop,
            passTBTop,
            languageCBTop: langSelTop,
            recoverLinkTop: forgotLinkTop,
            exploreLinkTop,
            chatLinkTop: chatLinkVisible ? chatLinkTop : 0,
            createDemoAccTop: regLinkTop,
            loginBtnTop,
            errorTextTop
        });
    }

    public override localize (): void {
        void this.set({
            textCreateDemoAccount: Resources.getResource('screen.LoginScreen.RegistrationLinkLabel'),
            textSavePassword: Resources.getResource('screen.LoginScreen.SavePassCheckBox'),
            textRecover: Resources.getResource('screen.LoginScreen.ForgotLinkLabel'),
            textExplore: Resources.getResource('screen.LoginScreen.ExploreLinkLabel'),
            textButton: Resources.getResource('screen.LoginScreen.LoginButtonText'),

            chatLinkText: Resources.getResource('screen.LoginScreen.ChatLink.label'),

            tooltipLogin: Resources.getResource('screen.LoginScreen.LoginIDTextBox.ToolTip'),
            tooltipPassword: Resources.getResource('screen.LoginScreen.PasswordTextBox.ToolTip'),
            tooltipSavePassword: Resources.getResource('screen.LoginScreen.SavePassCheckBox.ToolTip'),
            tooltipRecover: Resources.getResource('screen.LoginScreen.ForgotLinkLabel.ToolTip'),
            tooltipExplore: Resources.getResource('screen.LoginScreen.ExploreLinkLabel.ToolTip'),
            tooltipLoginBtn: Resources.getResource('screen.LoginScreen.LoginButtonTooltip'),
            tooltipCreateDemoAccount: Resources.getResource('screen.LoginScreen.RegistrationLinkLabel.ToolTip'),
            tooltipCustomerNo: Resources.getResource('screen.LoginScreen.CustomerNo'),

            placeholderLogin: Resources.getResource('screen.LoginScreen.LoginIDLabel'),
            placeholderPassword: Resources.getResource('screen.LoginScreen.PasswordLabel'),
            savePassText: Resources.getResource('screen.LoginScreen.SavePassCheckBox'),
            placeholderCustomerNo: Resources.getResource('screen.LoginScreen.CustomerNo'),

            langCBLabel: Resources.getResource('screen.LoginScreen.Settings.LanguageLabel')
        });
    }

    public trimStringIfWasPaste (event): void {
        const input = event.target;
        const insertFromPaste = this.wasPaste || event.inputType === 'insertFromPaste';
        input.value = insertFromPaste ? input.value.trim() : input.value;
        this.wasPaste = false;
    }

    public onPasswordFocused (focused): void {
        if (focused && this.get('savePasswordCheckedFromConnectionSettings')) {
            void this.set('connectionParams.pass', '');
            void this.set('savePasswordCheckedFromConnectionSettings', false);
        }
    }

    public onThemeChanged (): void {
        const connectionParams = this.get('connectionParams');

        if (!connectionParams.webLoginBanner) {
            void this.set('pictureUrl', ThemeManager.getImageFullUrlNew('g_login/login_branding.png'));
        }
    }

    public portfolioAutoLogin (): void {
        if (!ApplicationInfo.myportfolio) {
            return;
        }

        const token = LocalStorage.getTokenForPortfolio();
        if (token) {
            const connectionParams = this.get('connectionParams');
            connectionParams.accessToken = token;

            void this.set('connectionParams', connectionParams);
            void this.set('visible', false);

            LocalStorage.removeTokenForPortfolio();

            this.StartConnect();
        }
    }
}

function SubmitAsPost (token, newURL): void {
    if (!newURL) { return; }

    const postForm = document.createElement('form');
    postForm.action = newURL + '/token';
    postForm.method = 'post';
    const bodyTag = document.getElementsByTagName('body')[0];

    const inputToken = document.createElement('input');
    inputToken.setAttribute('name', 'token');
    inputToken.setAttribute('type', 'text');
    inputToken.setAttribute('value', token);

    postForm.appendChild(inputToken);
    bodyTag.appendChild(postForm);

    postForm.submit();
    bodyTag.removeChild(postForm);
}

TerceraWindowBase.extendWith(TerceraLoginScreen, {
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            movable: false,
            showHeader: false,
            showFooter: false,
            resizable: false,
            canSelect: false,
            alwaysMiddle: true,
            showFullscreenCloud: false,

            textLoginLabel: '',
            textPasswordLabel: '',
            textSavePassword: '',
            textRecover: '',
            textExplore: '',
            textButton: '',
            textCreateDemoAccount: '',

            tooltipLogin: '',
            tooltipPassword: '',
            savePassText: '',
            tooltipSavePassword: '',
            tooltipRecover: '',
            tooltipExplore: '',
            tooltipLoginBtn: '',
            tooltipCreateDemoAccount: '',
            langCBLabel: '',
            chatLinkText: '',

            style_addition_body: 'login-screen-additional',

            placeholderLogin: 'Login',
            placeholderPassword: 'Password',

            savePasswordChecked: false,
            savePasswordCheckedFromConnectionSettings: false,
            passwordTextBoxFocused: false,

            pictureUrl: ThemeManager.getImageFullUrlNew('g_login/login_branding.png'),
            comboItems: [{ value: 'Lang1', text: 'Lang1' }, { value: 'Lang2', text: 'Lang2' }, { value: 'Lang3', text: 'Lang3' }],
            textErrorLabel: '',
            connectionParams:
            {
                login: '',
                pass: '',
                connectionName: ''
            },
            reconnectConnectionParams: null,
            connectionInfo: [],
            connectionInfoMenuItems: [],
            selectedConnectionInfoMenuItem: null,

            isDisconnect: true,
            closeBtnVisible: false,

            needLogin: false,
            needPassword: false,
            needCustomerNo: false,

            allowPasswordRecovery: true,
            allowExplore: false,
            createDemoAccountAllowed: true,
            AllowCustomerNo: false,
            customerNoIndent: 0,

            loginTBTop: 0,
            passTBTop: 0,
            languageCBTop: 0,
            recoverLinkTop: 0,
            chatLinkTop: 0,
            createDemoAccTop: 0,
            loginBtnTop: 0,
            errorTextTop: 0,

            RegistrationModeEnum: RegistrationMode,
            login_language_selector: false,
            langItems: [],
            inputMode: true,

            IsCorrectForgotLink: false,
            forgotPasswordVisible: false,

            ICFRPL: false, // IsCorrectForRegistrationProcessLink,

            thirdPartyProvider: undefined,
            isOpenErrorMessageScreen: false
        };
    },
    partials: { bodyPartial: TerceraLoginScreenTemplate },
    computed: {
        loginEnabled: {
            get: function () { return this.get<boolean>('isDisconnect') && !this.get<boolean>('isOpenErrorMessageScreen'); }
        }
    }
});

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ChartDataType } from '../../Chart/Utils/ChartConstants';
import { Resources } from '../../Commons/properties/Resources';
import { DateTimeUtils } from '../Time/DateTimeUtils';
import { type SessionInfo } from './SessionInfo';

export class TFInfo {
    public Periods: number;
    public HistoryType: number;
    public ChartDataType: number;
    public AdditionalKey: string;
    public Plan: any; // ?
    public SessionInfo: SessionInfo | null;

    constructor () {
        this.Periods = 1;
        this.HistoryType = 1; // HistoryType.BID;
        // TODO. Get an actual value from TFAggregationMode.
        this.ChartDataType = ChartDataType.Default;
        this.AdditionalKey = '';
        this.Plan = null;
        this.SessionInfo = null;
    }

    public TradeAnalysisType (): number {
        // return this.AggregationMode
        //     ? this.AggregationMode.TradeAnalysisType
        //     : TradeAnalysisType.None;

        return TradeAnalysisType.None;
    }

    // PLEASE SOMEBODY ASAP TODO.
    public Copy (params?): TFInfo {
        const newTFInfo = new TFInfo();

        newTFInfo.SessionInfo = this.SessionInfo;

        if (!params) {
            newTFInfo.Periods = this.Periods;
            newTFInfo.HistoryType = this.HistoryType;
            newTFInfo.ChartDataType = this.ChartDataType;
            newTFInfo.AdditionalKey = this.AdditionalKey;
            newTFInfo.Plan = this.Plan;
            return newTFInfo;
        }
        const period = params.period;
        newTFInfo.Periods = period === undefined ? this.Periods : period;

        const historyType = params.historyType;
        newTFInfo.HistoryType = historyType === undefined ? this.HistoryType : historyType;

        const plan = params.spreadPlan;
        newTFInfo.Plan = plan === undefined ? this.Plan : plan;

        return newTFInfo;
    }

    // TODO.
    public Serialize (): string {
        return this.Periods + ';' + this.HistoryType + ';' + this.ChartDataType + ';' + JSON.stringify(this.SessionInfo);
    }

    // TODO.
    public static Parse (serializedStr: string): TFInfo {
        if (!serializedStr) return new TFInfo();

        const tfInfo = new TFInfo();

        const splits = serializedStr.split(';');
        if (splits.length > 0) {
            tfInfo.Periods = parseInt(splits[0]);
            tfInfo.HistoryType = parseInt(splits[1]);
            tfInfo.ChartDataType = parseInt(splits[2]);
            if (splits[3]) { tfInfo.SessionInfo = JSON.parse(splits[3]); }
        }

        return tfInfo;
    }

    public Equals (tfi: TFInfo): boolean {
        return this.Periods === tfi.Periods && this.HistoryType === tfi.HistoryType &&
        this.SessionInfo && tfi.SessionInfo && this.SessionInfo.OnlyMainSession === tfi.SessionInfo.OnlyMainSession &&
        (this.Plan && tfi.Plan && this.Plan.Id === tfi.Plan.Id);
    }
}

class _Periods {
    // Интрадей периоды - основные
    public readonly TIC = 0;
    public readonly MIN = 1; // базовый интрадей период
    public readonly MIN5 = 5 * this.MIN;
    public readonly MIN15 = 15 * this.MIN;
    public readonly MIN30 = 30 * this.MIN;
    public readonly HOUR = 60 * this.MIN;
    public readonly HOUR4 = 4 * this.HOUR;

    // Основные экстрадей периоды
    public readonly DAY = 24 * this.HOUR; // базовый экстрадей период
    public readonly WEEK = 7 * this.DAY;
    public readonly MONTH = 30 * this.DAY;
    public readonly MONTH_3 = 3 * this.MONTH;
    public readonly MONTH_6 = 6 * this.MONTH;
    public readonly YEAR = Math.floor(365.25 * this.DAY);

    public readonly RANGE = 2011; // специально подобрал константу - четырехзначное простое число, чтобы не было случайных совпадений
    public readonly SECOND = 10093;// 10000;//+++denis поменял константу, чтобы небыло боков

    public readonly CUSTOM_Periods = -2;

    public IsStandardPeriod (period: number): boolean {
        if (period === this.MIN ||
            period === this.MIN5 ||
            period === this.MIN15 ||
            period === this.MIN30 ||
            period === this.HOUR ||
            period === this.HOUR4 ||
            period === this.DAY ||
            period === this.WEEK ||
            period === this.MONTH ||
            period === this.YEAR) {
            return true;
        } else {
            return false;
        }
    }

    public getTicNameShort (tic: number): string {
        if (tic === this.TIC) { return 'Tick'; }// Resources.getResource("chart.tic.short.1tic.name");
        else if (tic === this.MIN) { return '1m'; }// Resources.getResource("chart.tic.short.1min.name");
        else if (tic === this.HOUR) { return '1H'; }// Resources.getResource("chart.tic.short.1hour.name");
        else if (tic === this.MIN5) { return '5m'; }// Resources.getResource("chart.tic.short.5min.name");
        else if (tic === this.DAY) { return '1D'; }// Resources.getResource("chart.tic.short.1day.name");
        else if (tic === this.WEEK) { return '1W'; }// Resources.getResource("chart.tic.short.week.name");
        else if (tic === this.MONTH) { return '1M'; }// Resources.getResource("chart.tic.short.month.name");
        else if (tic === this.MIN15) { return '15m'; }// Resources.getResource("chart.tic.short.15min.name");
        else if (tic === this.MIN30) { return '30m'; }// Resources.getResource("chart.tic.short.30min.name");
        else if (tic === this.HOUR4) { return '4H'; }// Resources.getResource("chart.tic.short.4hour.name");
        else if (tic === this.YEAR) { return '1Y'; }// Resources.getResource("chart.tic.short.year.name");
        else if (tic === this.SECOND) { return '1s'; }// Resources.getResource("chart.tic.short.second.name");
        else if (tic === this.RANGE) { return 'Rg'; }// Resources.getResource("chart.tic.short.range.name");

        // Это тик-интервальная история
        if (tic < this.TIC) {
            return (-tic).toString() + 'Tick';// Resources.getResource("chart.shortPeriodName.ticks");
        }
        // Это тики
        else if (tic == this.TIC) {
            return 'Tick';// Resources.getResource("chart.shortPeriodName.ticks");
        }
        // Это расширенный формат времени
        else if (tic <= this.HOUR) {
            // Интрачас период
            return tic + 'm';// Resources.getResource("chart.shortPeriodName.m");
        } else if (tic <= this.DAY) {
            // Интрадей период
            const remain = tic % this.HOUR;
            return remain != 0 ? (tic / this.HOUR + 'H' + remain + 'm') : (tic / this.HOUR + 'H');
        } else if (tic % this.SECOND == 0) {
            const sec = tic / this.SECOND;
            return sec + 's';
        } else {
            return tic / this.DAY + 'D';
        }
    }

    public GetBasePeriod (period: number): number {
        if (period < this.TIC) {
            // Базовый период - 0
            return this.TIC;
        } else if (period === this.TIC || period === this.RANGE) {
            // Базовый период - тики
            return this.TIC;
        }
        // Начинаем деление с самого большого базового периода
        else if (period % this.SECOND === 0) {
            // Базовый период - тики
            return this.TIC;
        } else if (period % this.YEAR === 0 ||
            period % this.MONTH === 0 ||
            period % this.WEEK === 0 ||
            period % this.DAY === 0) {
            // Базовым для этого периода являеся день
            return this.DAY;
        } else {
            // Во всех остальных случаях базовым будет 1 минутный бар
            return this.MIN;
        }
    }

    /// <summary>
    /// Трансляция в константу базового периода
    /// </summary>
    /// <param name="period"></param>
    /// <returns></returns>
    public TranslateToServerPeriod (period: number): number {
        switch (period) {
        case this.TIC:
            return 8;
        case this.MIN:
            return 0;
        case this.MIN5:
            return 1;
        case this.MIN15:
            return 2;
        case this.MIN30:
            return 9;
        case this.HOUR:
            return 3;
        case this.HOUR4:
            return 10;
        case this.DAY:
            return 4;
        case this.WEEK:
            return 5;
        case this.MONTH:
            return 6;
        default:
            return 7; // YEAR
        }
    };

    public TranslateToParsePeriod (period: number): number {
        /*
        public const int TIC=8;
        public const int MIN=0;
        public const int MIN5=1;
        public const int MIN15=2;
        public const int MIN30=9;
        public const int HOUR=3;
        public const int HOUR4=10;
        public const int DAY=4;
        public const int WEEK=5;
        public const int MONTH=6;
        public const int YEAR=7;
         */

        // TODO MBS
        switch (period) {
        case this.TIC:
            return this.TIC;
        case this.MIN:
        case this.MIN5:
        case this.MIN15:
        case this.MIN30:
        case this.HOUR:
        case this.HOUR4:
            return this.MIN;
        case this.DAY:
        case this.WEEK:
        case this.MONTH:
        case this.YEAR:
            return this.DAY;
        default:
            return 7;
        }
    };

    public IsBarAggregation (period: number) {
        if (period <= Periods.TIC) { return false; }

        if (period % Periods.SECOND === 0) { return true; }

        if (period % Periods.RANGE === 0) { return false; }

        if (period === Periods.MIN ||
            period === Periods.DAY) // это базовые агрегации
        { return false; }

        return true;
    }

    public PeriodsFormatWithConvert (t, period: number, instrument): string {
        let res = '';

        let localTime = t;
        const formatDate = 'DD.MM.YYYY';
        let format: string | null = null;

        if (period < this.TIC || (period > 0 && (period % this.SECOND === 0))) {
            format = formatDate + ' HH:mm:ss';
        } else if (period === this.TIC || period === this.RANGE) {
            format = formatDate + ' HH:mm:ss:SSS';
        } else if (period > this.TIC && period < this.DAY) {
            format = formatDate + ' HH:mm';
        }
        // else if (tictype >= Periods.HOUR && tictype < Periods.DAY)   // в связи с https://tp.traderevolution.com/entity/82373 возникает возможность построения бара от начала сессии, которое может начаться не в 00 минут
        // {
        //     format = formatDate + " HH:00";
        // }
        else if (period >= this.DAY && period < this.MONTH) {
            format = formatDate;
        } else if (period >= this.MONTH && period < this.YEAR) {
            format = 'MMMM-YYYY';
        } else if (period > this.MONTH) {
            format = 'YYYY';
        }

        if (instrument && period >= this.DAY) {
            localTime = this.ConvertToDisplayedTimeForDayAggregations(t, instrument, period);
        }

        if (format) {
            res = DateTimeUtils.formatDate(localTime, format);
        }

        return res;
    };

    public ConvertToDisplayedTimeForDayAggregations (date, instrument, period) {
        const defaultPeriod = instrument.TradingSessionsList[0];

        if (period === Periods.DAY) {
            const left = defaultPeriod.ConvertTimeUtcToSessionDateTime(date);
            const right = left.plus({ days: 1 });

            // не все дневные бары соотвествую сессии
            // таким образом мы понимаем в какой день сесси
            // попадает большая часть неправильного бара
            const middle = (left.toJSDate().getTime() + right.toJSDate().getTime()) / 2;
            return new Date(middle);
        }

        const dtInSessionTz = defaultPeriod.ConvertTimeUtcToSessionDateTime(date);

        const year = dtInSessionTz.year;
        const month = dtInSessionTz.month;
        const day = dtInSessionTz.day;
        const hour = dtInSessionTz.hour;
        const minute = dtInSessionTz.minute;
        const second = dtInSessionTz.second;
        const millisecond = dtInSessionTz.millisecond;

        const jsDate = new Date(year, month - 1, day, hour, minute, second, millisecond);
        return jsDate;
    }

    public ToLocalizedShortPeriod (period): string {
        let periodKey: string = '';

        switch (period) {
        case this.TIC:
            // http://tp.pfsoft.net/entity/59253
            periodKey = '1T';
            break;
        case this.MIN:
            periodKey = '1M';
            break;
        case this.MIN5:
            periodKey = '5M';
            break;
        case this.MIN15:
            periodKey = '15M';
            break;
        case this.MIN30:
            periodKey = '30M';
            break;
        case this.HOUR:
            periodKey = '1H';
            break;
        case this.HOUR4:
            periodKey = '4H';
            break;
        case this.DAY:
            periodKey = '1D';
            break;
        case this.WEEK:
            periodKey = '1W';
            break;
        case this.MONTH:
            periodKey = 'Month';
            break;
        case this.YEAR:
            periodKey = '1Y';
            break;
        }

        return periodKey ? Resources.getResource('chart.AllowedPeriods.' + periodKey) : period;
    }
}

export const Periods: _Periods = new _Periods();

export class PeriodDesc {
    public Period: number;
    public Koef: number;

    constructor (koef: number, period?: number) {
        this.Period = period || Periods.DAY;
        this.Koef = koef;
    }

    public toString (): string {
        const Period = this.Period;
        const Koef = this.Koef;
        if (Period == Periods.MIN) {
            return Koef + ' ' + Resources.getResource(Koef === 1 ? 'period.Min' : 'period.Mins');
        } else if (Period == Periods.DAY && (Koef < 30 || Koef % 30 != 0)) {
            return Koef + ' ' + Resources.getResource(Koef === 1 ? 'period.Day' : 'period.Days');
        } else if (Period == Periods.DAY && Koef >= 30 && Koef < 360) {
            return Koef / 30 + ' ' + Resources.getResource(Koef / 30 === 1 ? 'period.Month' : 'period.Months');
        } else if (Period == Periods.MONTH) {
            return Koef + ' ' + Resources.getResource(Koef === 1 ? 'period.Month' : 'period.Months');
        } else if (Period == Periods.YEAR) {
            return Koef + ' ' + Resources.getResource(Koef === 1 ? 'period.Year' : 'period.Years');
        } else if (Period == Periods.HOUR) {
            return Koef + ' ' + Resources.getResource(Koef === 1 ? 'period.Hour' : 'period.Hours');
        } else if (Period == Periods.WEEK) {
            return Koef + ' ' + Resources.getResource(Koef === 1 ? 'period.Week' : 'period.Weeks');
        } else { return ''; }
    }

    public equals (obj): boolean {
        return this === obj ||
            (this.Period === obj.Period && this.Koef === obj.Koef);
    }
}

class _ChartAvailablePeriods {
    private readonly table: Map<number, PeriodDesc[]> = new Map<number, PeriodDesc[]>();
    private readonly tablePeriodDefaults: Map<number, PeriodDesc> = new Map<number, PeriodDesc>();

    constructor () {
        this.initTable();
        this.initTablePeriodDefaults();
    }

    initTable (): void {
        this.table.set(Periods.TIC, [new PeriodDesc(1, Periods.MIN), new PeriodDesc(5, Periods.MIN), new PeriodDesc(15, Periods.MIN), new PeriodDesc(30, Periods.MIN), new PeriodDesc(60, Periods.MIN), new PeriodDesc(100, Periods.MIN)]);
        this.table.set(Periods.SECOND, [new PeriodDesc(1, Periods.MIN), new PeriodDesc(5, Periods.MIN), new PeriodDesc(15, Periods.MIN), new PeriodDesc(30, Periods.MIN), new PeriodDesc(60, Periods.MIN), new PeriodDesc(120, Periods.MIN), new PeriodDesc(180, Periods.MIN), new PeriodDesc(240, Periods.MIN), new PeriodDesc(300, Periods.MIN)]);
        this.table.set(Periods.MIN, [new PeriodDesc(1), new PeriodDesc(3), new PeriodDesc(5), new PeriodDesc(10), new PeriodDesc(15), new PeriodDesc(20)]);
        this.table.set(Periods.MIN5, [new PeriodDesc(1), new PeriodDesc(3), new PeriodDesc(5), new PeriodDesc(10), new PeriodDesc(15), new PeriodDesc(20), new PeriodDesc(30)]);
        this.table.set(Periods.MIN15, [new PeriodDesc(1), new PeriodDesc(3), new PeriodDesc(5), new PeriodDesc(10), new PeriodDesc(15), new PeriodDesc(20), new PeriodDesc(30), new PeriodDesc(60)]);
        this.table.set(Periods.MIN30, [new PeriodDesc(1), new PeriodDesc(3), new PeriodDesc(5), new PeriodDesc(10), new PeriodDesc(15), new PeriodDesc(20), new PeriodDesc(30), new PeriodDesc(60)]);
        this.table.set(Periods.HOUR, [new PeriodDesc(10), new PeriodDesc(15), new PeriodDesc(20), new PeriodDesc(30), new PeriodDesc(60), new PeriodDesc(90), new PeriodDesc(180), new PeriodDesc(1, Periods.YEAR)]);
        this.table.set(Periods.HOUR4, [new PeriodDesc(30), new PeriodDesc(60), new PeriodDesc(90), new PeriodDesc(180), new PeriodDesc(1, Periods.YEAR)]);
        this.table.set(Periods.DAY, [new PeriodDesc(3, Periods.MONTH), new PeriodDesc(6, Periods.MONTH), new PeriodDesc(1, Periods.YEAR), new PeriodDesc(2, Periods.YEAR), new PeriodDesc(3, Periods.YEAR), new PeriodDesc(5, Periods.YEAR), new PeriodDesc(10, Periods.YEAR), new PeriodDesc(15, Periods.YEAR), new PeriodDesc(20, Periods.YEAR)]);
        this.table.set(Periods.WEEK, [new PeriodDesc(3, Periods.MONTH), new PeriodDesc(6, Periods.MONTH), new PeriodDesc(1, Periods.YEAR), new PeriodDesc(2, Periods.YEAR), new PeriodDesc(3, Periods.YEAR), new PeriodDesc(5, Periods.YEAR), new PeriodDesc(10, Periods.YEAR), new PeriodDesc(15, Periods.YEAR), new PeriodDesc(20, Periods.YEAR)]);
        this.table.set(Periods.MONTH, [new PeriodDesc(1, Periods.YEAR), new PeriodDesc(2, Periods.YEAR), new PeriodDesc(3, Periods.YEAR), new PeriodDesc(4, Periods.YEAR), new PeriodDesc(5, Periods.YEAR), new PeriodDesc(10, Periods.YEAR), new PeriodDesc(15, Periods.YEAR), new PeriodDesc(20, Periods.YEAR)]);
        this.table.set(Periods.YEAR, [new PeriodDesc(5, Periods.YEAR), new PeriodDesc(10, Periods.YEAR), new PeriodDesc(20, Periods.YEAR), new PeriodDesc(50, Periods.YEAR), new PeriodDesc(100, Periods.YEAR)]);

        // +++ Андрей сказал увеличить
        // table[Periods.RANGE] = new ChartAvailablePeriodDescription(new List<PeriodDesc>() { new PeriodDesc(1, Periods.MIN), new PeriodDesc(5, Periods.MIN), new PeriodDesc(15, Periods.MIN), new PeriodDesc(30, Periods.MIN), new PeriodDesc(60, Periods.MIN), new PeriodDesc(100, Periods.MIN) });
        this.table.set(Periods.RANGE, [new PeriodDesc(1), new PeriodDesc(2), new PeriodDesc(3), new PeriodDesc(4), new PeriodDesc(5), new PeriodDesc(10)]);
    }

    initTablePeriodDefaults (): void {
        this.tablePeriodDefaults.set(Periods.TIC, new PeriodDesc(1, Periods.MIN));
        this.tablePeriodDefaults.set(Periods.SECOND, new PeriodDesc(1, Periods.MIN));
        // tablePeriodDefaults[Periods.SECOND] = new PeriodDesc(1, Periods.DAY);

        this.tablePeriodDefaults.set(Periods.MIN, new PeriodDesc(3));
        this.tablePeriodDefaults.set(Periods.MIN5, new PeriodDesc(5));
        this.tablePeriodDefaults.set(Periods.MIN15, new PeriodDesc(10));
        this.tablePeriodDefaults.set(Periods.MIN30, new PeriodDesc(30));
        this.tablePeriodDefaults.set(Periods.HOUR, new PeriodDesc(30));
        this.tablePeriodDefaults.set(Periods.HOUR4, new PeriodDesc(90));
        this.tablePeriodDefaults.set(Periods.DAY, new PeriodDesc(6, Periods.MONTH));
        this.tablePeriodDefaults.set(Periods.WEEK, new PeriodDesc(2, Periods.YEAR));
        this.tablePeriodDefaults.set(Periods.MONTH, new PeriodDesc(10, Periods.YEAR));
        this.tablePeriodDefaults.set(Periods.YEAR, new PeriodDesc(20, Periods.YEAR));
    }

    public GetAvaialbleCounts (period: number): PeriodDesc[] {
        // +++denis Андрей попросил
        if (period < 0) {
            period = Periods.MIN;
        } else if (period % Periods.SECOND === 0) {
            period = Periods.SECOND;
        }

        if (this.table.has(period)) {
            return this.table.get(period);
        }

        return [];
    }

    public GetDefaultValueOfPeriod (period: number): PeriodDesc | undefined {
        // +++denis Андрей попросил
        if (period < 0) {
            period = Periods.MIN;
        }

        if (this.tablePeriodDefaults.has(period)) {
            return this.tablePeriodDefaults.get(period);
        }

        return undefined;
        // else
        // {
        //    var basePeriod = 0;

        //    if (Periods.GetLargestBasePeriodForInterval(period, ref basePeriod))
        //        period = basePeriod;

        //    if (tablePeriodDefaults.ContainsKey(period))
        //        return tablePeriodDefaults[period];
        //    else
        //    {
        //        var listDesc = ChartAvailablePeriods.GetAvaialbleCounts(period);
        //        if (listDesc != null && listDesc.Count > 0)
        //            return listDesc[0];
        //        else
        //            return null;
        //    }

        // }
    }
}

export const ChartAvailablePeriods: _ChartAvailablePeriods = new _ChartAvailablePeriods();

export const TradeAnalysisType = { None: 0, Cluster: 1, MarketProfile: 2 };

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import './GlobalDeclaration';
import { App } from './Commons/Application';
import { logs } from './Controls/panels/FullLogsPanel';
import { Resources } from './Commons/properties/Resources';
import { type WorkspaceManagerHelper, wmh } from './Commons/UtilsClasses/WorkspaceManagerHelper';
export * from './ControlsImport';
export * from './ScreensImport';
export * from './Extensions';

declare global{
    interface Window {
        ShowAllLocalizationKeys: any
        logs: any
        wmh: WorkspaceManagerHelper
    }
}

window.addEventListener('load', function () {
    const me = document.getElementById('build_time');
    App.Initialize();

    window.ShowAllLocalizationKeys = (isShow) => { Resources.ShowAllLocalizationKeys(isShow); };
    window.logs = logs;
    window.wmh = wmh;
});

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type Instrument } from '../../Commons/cache/Instrument';
import { InstrumentSpecificType } from './InstrumentSpecificType';

export class _InstrumentLookupComparer {
    public sortInstrumentList (instrumentList: Instrument[], pattern: string): Instrument[] {
        instrumentList.sort(this.compareInstruments.bind(this, pattern.toLowerCase()));
        return instrumentList;
    }

    public sortOptionList (instrumentList: Instrument[], pattern: string): Instrument[] {
        instrumentList.sort(this.compareByPattern.bind(this, pattern.toLowerCase()));
        return instrumentList;
    }

    public compareInstruments (pattern: string, x: Instrument, y: Instrument): number {
        const aIsOptionSymbol = x.isOptionSymbol;
        const bIsOptionSymbol = y.isOptionSymbol;

        let result = Number(aIsOptionSymbol) - Number(bIsOptionSymbol);

        if (result === 0) {
            const aIsFutureSymbol = x.isFuturesSymbol;
            const bIsFutureSymbol = y.isFuturesSymbol;
            if (aIsOptionSymbol && bIsOptionSymbol) {
                if (x.ForwardBaseInstrument != null && y.ForwardBaseInstrument != null) {
                    result = this.getInstrumentDisplayName(x.ForwardBaseInstrument).localeCompare(this.getInstrumentDisplayName(y.ForwardBaseInstrument));
                    if (result !== 0) {
                        return this.compareByPattern(pattern, x, y);
                    }
                }

                if (result === 0 && x.ExpDate != null && y.ExpDate != null) {
                    result = x.ExpDate.getTime() - y.ExpDate.getTime();
                }

                if (result === 0) {
                    result = x.StrikePrice - y.StrikePrice;
                }

                if (result === 0) {
                    result = x.PutCall - y.PutCall;
                }
            } else if (aIsFutureSymbol && bIsFutureSymbol) {
                result = x.SourceName?.localeCompare(y.SourceName || '') || 0;

                if (result === 0) {
                    result = Number(x.InstrumentSpecificType) - Number(y.InstrumentSpecificType);
                }

                if (result === 0 && x.ExpDate != null && y.ExpDate != null) {
                    result = x.ExpDate.getTime() - y.ExpDate.getTime();
                }
            } else {
                return this.compareByPattern(pattern, x, y);
            }
        }

        return result;
    }

    public compareByPattern (pattern: string, x: Instrument, y: Instrument): number {
        const aDisplayName = this.getInstrumentDisplayName(x);
        const bDisplayName = this.getInstrumentDisplayName(y);
        if (pattern !== '') {
            const aDescription = this.getInstrumentDescription(x);
            const bDescription = this.getInstrumentDescription(y);

            let compareResult = this.tryCompareByFullMatch(pattern, aDisplayName, bDisplayName);
            if (compareResult.success) {
                return compareResult.result;
            }
            compareResult = this.tryCompareByPartialMatch(pattern, aDisplayName, bDisplayName);
            if (compareResult.success) {
                return compareResult.result;
            }
            compareResult = this.tryCompareByFullMatch(pattern, aDescription, bDescription);
            if (compareResult.success) {
                return compareResult.result;
            }
            compareResult = this.tryCompareByPartialMatch(pattern, aDescription, bDescription);
            if (compareResult.success) {
                return compareResult.result;
            }
            return aDisplayName.localeCompare(bDisplayName);
        } else {
            return aDisplayName.localeCompare(bDisplayName);
        }
    }

    private tryCompareByFullMatch (pattern: string, a: string, b: string): { success: boolean, result: number } {
        const aIsFullMatch = a === pattern;
        const bIsFullMatch = b === pattern;
        const result = Number(bIsFullMatch) - Number(aIsFullMatch);
        const success = (result === 0 && aIsFullMatch) || result !== 0;
        return { success, result };
    }

    private tryCompareByPartialMatch (pattern: string, a: string, b: string): { success: boolean, result: number } {
        const aIsStartWith = a ? a.startsWith(pattern) : false;
        const bIsStartWith = b ? b.startsWith(pattern) : false;

        let result = Number(bIsStartWith) - Number(aIsStartWith);

        if (result === 0 && aIsStartWith) {
            result = a.localeCompare(b);
            return { success: true, result };
        }

        let aIsContains = false;
        if (result === 0) {
            aIsContains = a && a.includes(pattern);
            const bIsContains = b && b.includes(pattern);
            result = Number(bIsContains) - Number(aIsContains);
        }

        if (result === 0 && aIsContains) {
            result = a.localeCompare(b);
            return { success: true, result };
        }
        return { success: result !== 0, result };
    }

    private getInstrumentDisplayName (instrument: Instrument): string {
        if (instrument == null) {
            return '';
        }
        let displayName: string;
        if (instrument.InstrumentSpecificType === InstrumentSpecificType.ContinuousContract) {
            displayName = instrument.ContinuousContractName;
        } else {
            displayName = instrument.DisplayName();
        }
        return isValidString(displayName) ? displayName.toLowerCase() : '';
    }

    private getInstrumentDescription (instrument: Instrument): string {
        if (instrument == null) {
            return '';
        }
        return isValidString(instrument.Description) ? instrument.Description.toLowerCase() : '';
    }

    public sortBySymbolDescription (firstSymbolName: string,
        secondSymbolName: string,
        firstSymbolDescription: string,
        secondSymbolDescription: string,
        searchText): number {
        const symbolNameSortResult = this.sortName(firstSymbolName, secondSymbolName, searchText);
        if (symbolNameSortResult < 0) {
            return -1;
        } else if
        (symbolNameSortResult > 0) {
            return 1;
        } else {
            const descriptionSortResult = this.sortName(firstSymbolDescription, secondSymbolDescription, searchText);
            if (descriptionSortResult < 0) {
                return -1;
            } else if (descriptionSortResult > 0) {
                return 1;
            } else {
                return 0;
            }
        }
    }

    public sortName (first: string, second: string, searchText: string): number {
        if (first === second) {
            return 0;
        } else if (first === searchText) {
            return -1;
        } else if (second === searchText) {
            return 1;
        }

        const firstIncludes = first.includes(searchText);
        const secondIncludes = second.includes(searchText);
        if (firstIncludes && !secondIncludes) {
            return -1;
        } else if (!firstIncludes && secondIncludes) {
            return 1;
        } else if (firstIncludes && secondIncludes) {
            const firstStartsWith = first.startsWith(searchText);
            const secondStartsWith = second.startsWith(searchText);
            if (firstStartsWith && !secondStartsWith) {
                return -1;
            } else if (!firstStartsWith && secondStartsWith) {
                return 1;
            } else {
                return first.localeCompare(second);
            }
        } else {
            return 0;
        }
    }
}

export const InstrumentLookupComparer = new _InstrumentLookupComparer();

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from '../../Commons/Connection';
import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraBaseWindowTemplate, TerceraChangePasswordScreenTemplate } from '../../templates.js';
import { AdditionalVerificationScreen } from '../screen/AdditionalVerificationScreen';
import { TerceraMessageBox, MessageBoxType } from './TerceraMessageBox';
import { TerceraWindowBase } from './TerceraWindowBase';
import { DataCache } from '../../Commons/DataCache';
import { TerceraButtonStyle } from '../../Utils/Enums/ButtonEnums';

export class TerceraChangePasswordScreen extends TerceraWindowBase {
    public sessionId: string = '';
    public forceCloseOnLogout: boolean = false;
    public UserID: any;
    public OldPass: any;
    public cancelCallBack: any;
    public tradingPassword: any;

    public override oninit (): void {
        super.oninit();

        void this.set({

            curPwdLabel: Resources.getResource('screen.changepass.old'),
            newPwdLabel: Resources.getResource('screen.changepass.new'),
            newPwdConfirmLabel: Resources.getResource('screen.changepass.confirm'),

            curPwdPlaceholder: Resources.getResource('screen.changepass.watermark_old'),
            newPwdPlaceholder: Resources.getResource('screen.changepass.watermark_new'),
            newPwdConfirmPlaceholder: Resources.getResource('screen.changepass.watermark_confirm'),

            okText: Resources.getResource('screen.changepass.ok'),
            cancelText: Resources.getResource('screen.changepass.cancel'),

            allowPreviewPassword: DataCache.AllowPreviewPassword
        });

        this.on('ok', this.onInputDataConfirmed);
        this.on('cancel', this.close);
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.center();
    }

    public onInputDataConfirmed (): void {
        const newPwd = this.get('newPwd');
        const newPwdConfirm = this.get('newPwdConfirm');

        if (!newPwd) {
            this.ShowMsg(Resources.getResource('BusinessRejectMessage.203'), () => { this.clearFields(); });
            return;
        }
        if (newPwd !== newPwdConfirm) {
            this.ShowMsg(Resources.getResource('screen.changepass.alert_not_match'), () => { this.clearFields(); });
            return;
        }

        this.tradingPassword ? this.changeTradingPassword() : this.changePassword();
    }

    public changePassword (): void {
        Connection.ChangePassword(this.get('curPwd'), this.get('newPwd'), this.UserID, /* verificationPassword */ null)
            .then(this.changePasswordRusult.bind(this));
    }

    public changePasswordRusult (result): void {
        if (result === null) {
            return;
        }

        if (result.RejectCode) {
            const rjKeY = 'BusinessRejectMessage.' + result.RejectCode;
            let msg = result.errText;
            if (Resources.IsResourcePresentEN(rjKeY)) {
                msg = Resources.getResource(rjKeY);
            }

            this.ShowMsg(msg, () => { this.clearFields(); });
            return;
        }

        const status = result.Status;
        switch (status) {
        case ChangePasswordStatusEnum.STATUS_ACCEPTED:
            this.ShowMsg(Resources.getResource('screen.changepass.changed.login'));

            // TODO Ugly
            Connection.lastConnectionAttemptResponse = { text: '' };

            this.close(this, true);
            Connection.disconnect();
            return;

        case ChangePasswordStatusEnum.STATUS_NEED_VERIFICATION:{
            const okCallBack = function (verificationPassword) {
                Connection.ChangePassword(this.get('curPwd'), this.get('newPwd'), this.UserID, verificationPassword)
                    .then(this.changePasswordRusult.bind(this));
            }.bind(this);

            const cancelCallBack = function () { this.close(); }.bind(this);
            AdditionalVerificationScreen.Show(null, okCallBack, cancelCallBack, { allowPreviewPassword: this.get('allowPreviewPassword') });
            return;
        }

        case ChangePasswordStatusEnum.STATUS_WRONG_VERIFICATION:
            this.ShowMsg(Resources.getResource('screen.changepass.wrongVerification'));
            this.close();
            // Connection.disconnect();    // #99859 (commented out due to #115329)
            return;

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_LOW:{
            const MsgLow = Resources.getResource('system.ChangePassLow').replace('{0}', result.MinLength);
            this.ShowMsg(MsgLow, () => { this.clearFields(); });
            return;
        }

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_BASE:{
            const MsgBase = Resources.getResource('system.ChangePassBase').replace('{0}', result.MinLength);
            this.ShowMsg(MsgBase, () => { this.clearFields(); });
            return;
        }

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_HIGH:{
            const MsgHigh = Resources.getResource('system.ChangePassHigh').replace('{0}', result.MinLength);
            this.ShowMsg(MsgHigh, () => { this.clearFields(); });
            return;
        }

        case ChangePasswordStatusEnum.STATUS_LOCKED_AFTER_BRUT_FORCE:
            this.ShowMsg(Resources.getResource('screen.changepass.lockedAfterBrut'), () => { this.clearFields(); });
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_OLD_OR_NEW_PASSWORD:
            this.ShowMsg(Resources.getResource('screen.changepass.wrongOldOrNewPassword'), () => { this.clearFields(); });
            return;

        case ChangePasswordStatusEnum.STATUS_PWD_MUST_DE_DIFFERENT:
            this.ShowMsg(Resources.getResource('screen.changepass.pwdMustBeDifferent'), () => { this.clearFields(); });
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_MAX_LENGTH:
            this.ShowMsg(Resources.getResource('system.TooLongPass').replace('{0}', result.MaxLength), () => { this.clearFields(); });
            return;

        case ChangePasswordStatusEnum.STATUS_PWD_IS_TOO_EASY:
            this.ShowMsg(Resources.getResource('screen.changepass.tooeasy'), () => { this.clearFields(); });
        }
    }

    public clearFields (): void {
        void this.set({
            curPwd: '',
            newPwd: '',
            newPwdConfirm: ''
        });
    }

    public changeTradingPassword (): void {
        Connection.ChangeTradingPassword(this.get('curPwd'), this.get('newPwd'))
            .then((result) => {
                this.clearFields();

                if (result === null) {
                    this.close(this, true);
                    return;
                }

                if (result.RejectCode) {
                    this.ShowMsg(Resources.getResource('BusinessRejectMessage.' + result.RejectCode));
                    return;
                }

                if (result.Status === ChangePasswordStatusEnum.STATUS_ACCEPTED) {
                    this.ShowMsg(Resources.getResource('screen.changepass.changed'));
                }

                if (result.Status === ChangePasswordStatusEnum.STATUS_WRONG_MAX_LENGTH) {
                    this.ShowMsg(Resources.getResource('system.TooLongPass').replace('{0}', result.MaxLength));
                }

                this.close(this, true);
            });
    }

    public override close (sender?, fromOk = false): void {
        if (!fromOk && this.cancelCallBack) {
            this.cancelCallBack();
        }

        super.close();
    }

    public setScreenData (UserID, OldPass, cancelCB, isTradingPassword): void {
        this.on('teardown', () => { MainWindowManager.ChangePasswordScreen = null; });

        void this.set({
            visible: true,
            header: isTradingPassword
                ? Resources.getResource('screen.changeTradingPass.title')
                : Resources.getResource('screen.changepass.title')
        });

        const mainAcc = DataCache.MainAccountNew;
        if (UserID === '' && mainAcc != null) {
            UserID = mainAcc.userID;
        }

        this.UserID = UserID ?? DataCache.getUserID();
        this.OldPass = OldPass;
        this.cancelCallBack = cancelCB;
        this.tradingPassword = isTradingPassword || false;
    }

    public static show (UserID?, OldPass?, cancelCB?, isTradingPassword?): void {
        if (MainWindowManager.ChangePasswordScreen) {
            MainWindowManager.ChangePasswordScreen.setFocus();
        } else {
            const scr = new TerceraChangePasswordScreen();
            MainWindowManager.ChangePasswordScreen = scr;
            scr.setScreenData(UserID, OldPass, cancelCB, isTradingPassword);
            MainWindowManager.MainWindow.addControl(scr);
        }
    }

    public ShowMsg (text, closeHandler?): void {
        if (!closeHandler) {
            closeHandler = null;
        }

        const additionalData = { okText: Resources.getResource('general.messageBox.ok') };

        TerceraMessageBox.Show(this.get('header'), text, MessageBoxType.Info, closeHandler, closeHandler, false, true, null, additionalData, true);
    }
}

export enum ChangePasswordStatusEnum {
    STATUS_UNKNOWN_ERROR = -1,
    STATUS_ACCEPTED = 0, // "accepted"
    STATUS_NEED_VERIFICATION = 1, // needVerification
    STATUS_WRONG_VERIFICATION = 2, // wrongVerification
    STATUS_STRONG_PAS_LOW = 3, // Character register and number required
    STATUS_STRONG_PAS_BASE = 4, // Character register and number required + number from server(819 field) char at least
    STATUS_STRONG_PAS_HIGH = 5, // Character register and number required + number from server(819 field) char at least + special char requirement
    STATUS_LOCKED_AFTER_BRUT_FORCE = 6,
    STATUS_WRONG_OLD_OR_NEW_PASSWORD = 7,
    STATUS_WRONG_KEY = 8,
    STATUS_PWD_MUST_DE_DIFFERENT = 9,
    STATUS_ERROR_EMAIL = 10,
    STATUS_WRONG_LOGIN = 11,
    STATUS_WRONG_MAX_LENGTH = 12,
    STATUS_PWD_IS_TOO_EASY = 13 // #112524 -> #113076
}

TerceraWindowBase.extendWith(TerceraChangePasswordScreen, {
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            movable: false,
            showFooter: false,
            resizable: false,
            alwaysMiddle: true,
            focused: true,

            left: 0,
            top: 0,
            width: 401,
            height: 195,

            curPwd: '',
            newPwd: '',
            newPwdConfirm: '',
            allowPreviewPassword: false,
            zIndex: 299,

            btnOKStyle: TerceraButtonStyle.Blue,
            btnCancelStyle: TerceraButtonStyle.Standard
        };
    },
    partials: {
        bodyPartial: TerceraChangePasswordScreenTemplate
    }
});

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MathUtils } from '../MathUtils';
import { OffsetModeViewEnum } from './OffsetModeViewEnum';
import { OperationType } from './OperationType';
import { OrderType } from './OrderType';
import { OrderStatus } from './OrderStatus';
import { OrderExecutionType } from './OrderExecutionType';
import { type Instrument } from '../../Commons/cache/Instrument';
import { Resources } from '../../Commons/properties/Resources';

export class OrderUtils {
    public static getBuySellStr (buySell: OperationType): string {
        switch (buySell) {
        case OperationType.Buy:
            return 'Buy'; // Buy Long
        case OperationType.Sell:
            return 'Sell'; // Sell Short
        }
        return 'BuySell: ' + buySell;
    }

    // TODO. Rename.
    // offsetType === null - result is an absolute price.
    // tickOffset - positive/negative integer.
    public static ConvertTickOffset (instrument: Instrument, offsetType, basePrice, tickOffset, allowNegative = false): number {
        basePrice = basePrice || 0; // NaN fix.

        const pointSize = instrument.GetPointSize(basePrice);
        const precision = instrument.Precision;
        // Returns an absolute value
        if (offsetType === null) {
            const result = instrument.CalculatePrice(basePrice, tickOffset, true);
            return MathUtils.fixFloatPrecision(result, precision);
        }

        let result = tickOffset;

        switch (offsetType) {
        case OffsetModeViewEnum.Points:
            result = MathUtils.fixFloatPrecision(tickOffset * pointSize, precision);
            break;
        case OffsetModeViewEnum.TicksFractionalForForex:
            if (instrument.AllowUseFractinalTicksForForex()) {
                result = MathUtils.fixFloatPrecision(tickOffset * 0.1, 1);
            }

            break;
        }

        if (!allowNegative) {
            result = Math.abs(result);
        }
        return result;
    }

    // Converts from view-specific ticks to integer ticks
    // E.g.: 0.5 fract ticks -> 5 integer ticks.
    public static toRawTicks (srcTicks, srcTicksViewMode, instrument: Instrument): number {
        let result = srcTicks;

        switch (srcTicksViewMode) {
        case OffsetModeViewEnum.Points:
            result = srcTicks / instrument.GetPointSize(null);
            break;
        case OffsetModeViewEnum.TicksFractionalForForex:
            if (instrument.AllowUseFractinalTicksForForex()) {
                result = srcTicks * 10;
            }
            break;
        }

        return Math.round(result);
    }

    // TODO. Move to OrderExecutor?
    public static getOrderTypeLocalizationKey (orderType): string {
        switch (orderType) {
        case OrderType.Market:
            return 'Market';
        case OrderType.Limit:
            return 'Limit';
        case OrderType.Stop:
            return 'Stop';
        case OrderType.StopLimit:
            return 'Stop Limit';
        case OrderType.TrailingStop:
            return 'Tr. stop';
        case OrderType.OCO:
            return 'OCO';
        case OrderType.SLTPLimit:
            return 'Take-profit';
        case OrderType.SLTPStop:
            return 'Stop-loss';
        case OrderType.TRStop:
            return 'Tr. stop loss';
        case OrderType.Manual:
            return 'Manual';
        }
        return 'type: ' + orderType;
    }

    public static GetFormattedLeverage (leverageValue, inShortFormat = false): string {
        if (!leverageValue) {
            return '';
        }

        return inShortFormat ? leverageValue : leverageValue + ' x';
    }

    /// <summary>
    /// #45746 - для Stop и Trailing Stop ореров мы должны отображать цену в колонке StopPrice
    /// </summary>
    /// double limitPrice, double stopPrice, PriceType selectedPriceType, int orderType = 0
    public static GetCorrectPriceForOrderType (limitPrice, stopPrice, selectedPriceType, orderType = 0): number {
        const PriceType = OrderUtils.PriceType;

        const isStopORTrailingStop =
            orderType === OrderType.Stop ||
            orderType === OrderType.TrailingStop ||
            orderType === OrderType.SLTPStop;

        if (selectedPriceType === PriceType.LimitPrice && isStopORTrailingStop) {
            return NaN;
        }

        if (selectedPriceType === PriceType.StopPrice && isStopORTrailingStop) {
            return limitPrice;
        }

        if (selectedPriceType === PriceType.LimitPrice) {
            return limitPrice;
        }

        if (selectedPriceType === PriceType.StopPrice) {
            if (orderType === OrderType.Limit || // #94328
                orderType === OrderType.Market ||
                orderType === OrderType.SLTPLimit) {
                return NaN;
            }

            return stopPrice;
        }

        return limitPrice;
    }

    public static PriceType = { LimitPrice: 1, StopPrice: 2 };

    public static ConvertOrderStatusToExecutionType (orderStatus: OrderStatus): OrderExecutionType {
        switch (orderStatus) {
        case OrderStatus.REPLACED:
            return OrderExecutionType.REPLACED;

        case OrderStatus.PENDING_NEW:
            return OrderExecutionType.PENDING_NEW;

        case OrderStatus.PENDING_EXECUTION:
            return OrderExecutionType.PENDING_EXECUTION;

        case OrderStatus.PENDING_CANCEL:
            return OrderExecutionType.PENDING_CANCEL;

        case OrderStatus.PENDING_REPLACE:
            return OrderExecutionType.PENDING_REPLACE;

        case OrderStatus.NEW:
            // вобщем-то нет соответствия, но мы делаем заглушки:
        case OrderStatus.OFF_MARKET:
        case OrderStatus.UNPLACED:
            return OrderExecutionType.NEW;

        case OrderStatus.ACCEPTED:
            return OrderExecutionType.ACCEPTED;

        case OrderStatus.PART_FILLED:
            return OrderExecutionType.PART_FILLED;

        case OrderStatus.FILLED:
            return OrderExecutionType.FILLED;

        case OrderStatus.CANCELED:
            return OrderExecutionType.CANCELED;

        case OrderStatus.REFUSED:
            return OrderExecutionType.REFUSED;

        case OrderStatus.WAITING_MARKET:
            return OrderExecutionType.ACTIVATED;

        case OrderStatus.UNKNOWN:
        case OrderStatus.PENDING_REPLACE_NOT_ACTIVE:
        default:
            return OrderExecutionType.UNKNOWN;
        }
    }
}

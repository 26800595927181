// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum AppNames {
    NewNetTrader = 'NewNetTrader',
    Investing = 'Investing',
    TradingPlatform = 'TradingPlatform'
}

export enum PanelNames {
    PositionsPanel = 'POSITIONSPANEL',
    OrdersPanel = 'ORDERSPANEL',
    EventsPanel = 'EVENTSPANEL',
    OrderHistoryPanel = 'NAME_ORDERHISTORY_PANEL',
    FilledOrdersPanel = 'NEWTRADESPANEL',
    AssetBalancesPanel = 'ASSET_BALANCES_PANEL',
    InformerPanel = 'INFORMERPANEL',
    AlertPanel = 'ALERTPANEL',
    CreateAlertPanel = 'CREATEALERTPANEL',
    OrderEntry = 'NAME_ORDERCOMPOSIT_PANEL', // с клиента взял
    AdvancedOrderEntry = 'NAME_ADVANCED_ORDER_ENTRY_PANEL',
    ReportsPanel = 'REPORTSPANEL',
    RiskCalculator = 'RISKCALCULATOR',
    ProductsPanel = 'PRODUCTSPANEL',
    TimeSalesPanel = 'TRADESPANEL',
    AccountDetailsPanel = 'ACCOUNTDETAILSPANEL',
    SymbolInfoPanel = 'INSTRUMENTDETAILSPANEL',
    InstrumentHolidaysScreen = 'INSTRUMENT_HOLIDAYS_SCREEN',
    MarketDepthPanel = 'LEVEL2PANEL',
    ExternalScreen = 'EXTERNALSCREEN',
    ChartPanel = 'CHARTPANEL',
    TradingViewChartPanel = 'TRADINGVIEW_CHARTPANEL',

    PortfolioTable = 'PORTFOLIOTABLE',
    MarginOEControl = 'MARGIN_OE_CONTROL',

    InvestingAdvancedChartPanel = 'InvestingAdvancedChartPanel',

    TypesManagerScreen = 'TYPES_MANAGER_SCREEN',

    GridPanel = 'GRIDPANEL',
    FullLogsPanel = 'FULLLOGSPANEL',
    SavedOrdersPanel = 'SAVEDORDERSPANEL',
    StatementPanel = 'STATEMENTPANEL',
    TradingCentral = 'TRADINGCENTRAL',
    IdeasPanel = 'TRADINGIDEAS',
    NotificationsPanel = 'NOTIFICATIONSPANEL',
    AssetsPanel = 'ASSETSPANEL',
    PositionsBalancePanel = 'POSITIONSBALANCEPANEL',
    NewsPanel = 'NEWSPANEL',
    VideoWidget = 'VIDEO_WIDGET',

    MarketConsensusPanel = 'ALGORITHM_MARKET_CONSENSUS_PANEL',
    GainersLosersPanel = 'GAINERS_LOSERS_PANEL',
    HighLowPanel = 'HIGH_LOW_PANEL',

    ClosePositionsMutually = 'CLOSE_POSITIONS_MUTUALLY',
    HeatmapPanel = 'HEATMAP_PANEL',

    MamSummary = 'MAM_SUMMARY',

    OptionMasterPanel = 'OPTION_MASTER_PANEL',
    OptionChainPanel = 'OPTION_CHAIN_PANEL',
    OptionAnalyzerPanel = 'OPTION_ANALYZER_PANEL',
    OptionVolatilityLabPanel = 'OPTION_VOLATILITY_LAB_PANEL',
    OptionPositionsPanel = 'OPTION_POSITIONSPANEL',
    OptionOrdersPanel = 'OPTION_ORDERSPANEL',
    OptionPaperPositionsPanel = 'OPTION_PAPERPOSITIONSPANEL',

    NewAccountDetailsPanel = 'NEWACCOUNTDETAILSPANEL',
    NewSymbolInfoPanel = 'NEWSYMBOLINFOPANEL'
}

export const PanelLocKeys = {
    [PanelNames.OrderEntry]: 'panel.orderEntry',
    [PanelNames.AdvancedOrderEntry]: 'panel.orderEntry',
    [PanelNames.ChartPanel]: 'panel.chart',
    [PanelNames.InformerPanel]: 'panel.informer',
    [PanelNames.AlertPanel]: 'panel.alert',
    [PanelNames.CreateAlertPanel]: 'panel.alert',
    [PanelNames.MarketDepthPanel]: 'panel.level2',
    [PanelNames.TimeSalesPanel]: 'panel.timeSales',
    [PanelNames.PositionsPanel]: 'panel.positions',
    [PanelNames.OrdersPanel]: 'panel.orders',
    [PanelNames.OrderHistoryPanel]: 'panel.orderHistory',
    [PanelNames.FilledOrdersPanel]: 'panel.trades',
    [PanelNames.EventsPanel]: 'panel.log',
    [PanelNames.ReportsPanel]: 'ribbon.tools.reports',
    [PanelNames.ProductsPanel]: 'ribbon.tools.products',
    [PanelNames.NewAccountDetailsPanel]: 'panel.AccountDetailsPanel',
    [PanelNames.SymbolInfoPanel]: 'panel.instrumentsInfo',
    [PanelNames.AssetBalancesPanel]: 'panel.accounts',
    [PanelNames.GridPanel]: 'ribbon.tools.grid',
    [PanelNames.SavedOrdersPanel]: 'panel.savedOrders',
    [PanelNames.StatementPanel]: 'panel.statement',
    [PanelNames.FullLogsPanel]: 'ribbon.tools.full_logs',
    [PanelNames.AssetsPanel]: 'panel.assets',
    [PanelNames.PositionsBalancePanel]: 'panel.PositionsBalance',
    [PanelNames.NewsPanel]: 'panel.news',
    [PanelNames.RiskCalculator]: 'panel.riskCalculator',
    [PanelNames.MarketConsensusPanel]: 'panel.AlgorithmMarketConsensusPanel',
    [PanelNames.GainersLosersPanel]: 'panel.GainersLosersPanel',
    [PanelNames.HighLowPanel]: 'panel.HighLowPanel',
    [PanelNames.ClosePositionsMutually]: 'panel.ClosePositionsMutually',
    [PanelNames.HeatmapPanel]: 'panel.HeatmapPanel',
    [PanelNames.OptionMasterPanel]: 'panel.optionMaster',
    [PanelNames.OptionChainPanel]: 'panel.optionChain',
    [PanelNames.OptionAnalyzerPanel]: 'panel.optionAnalyzer',
    [PanelNames.OptionVolatilityLabPanel]: 'panel.optionVolatilityLab',
    [PanelNames.OptionPositionsPanel]: 'panel.optionPositions',
    [PanelNames.OptionOrdersPanel]: 'panel.optionOrders',
    [PanelNames.OptionPaperPositionsPanel]: 'panel.optionPaperPositions',
    [PanelNames.NewSymbolInfoPanel]: 'panel.instrumentsInfo'
};

export enum ControlsTypes {
    CreateAlertAccountLookup = 'CreateAlertAccountLookup',
    CreateAlertWidget = 'CREATEALERTWIDGET',
    CreateAlertMessageSelector = 'CREATE_ALERT_MESSAGE_SELECTOR',
    InstrumentTotalInfo = 'INSTRUMENTTOTALINFO',
    TerceraChartToolsPanel = 'TerceraChartToolsPanel',
    OEQuote1Viewer = 'OEQuote1Viewer',
    OEAccountLookup = 'OEAccountLookup',
    TerceraAccountLookup = 'TerceraAccountLookup',
    TerceraButton = 'TerceraButton',
    TerceraLevel1Panel = 'TERCERALEVEL1PANEL',
    TerceraTimeZoneSelector = 'TERCERA_TIME_ZONE_SELECTOR',
    TerceraTicketViewer = 'TERCERA_TICKET_VIEWER',
    TerceraSideBarButton = 'TerceraSideBarButton',
    TerceraCheckBox = 'TerceraCheckBox',
    TerceraCheckBoxEvent = 'TerceraCheckBoxEvent',
    TerceraTextBox = 'TerceraTextBox',
    TerceraNumeric = 'TerceraNumeric',
    TerceraQuantityNumeric = 'TerceraQuantityNumeric',
    OEQuantity = 'OEQuantity',
    SLTPTriggerControl = 'SLTPTriggerControl',
    TerceraRadioButtonComponent = 'TerceraRadioButtonComponent',
    TerceraRadioComboItemGroup = 'TerceraRadioComboItemGroup',
    ChartWindow = 'ChartWindow',
    AdvancedChartOrderEntry = 'AdvancedChartOrderEntry',
    TerceraInstrumentLookupTextBox = 'TerceraInstrumentLookupTextBox',
    AlertsButton = 'AlertsButton',
    IdeasButton = 'IdeasButton',
    InvestingButton = 'InvestingButton',
    InvestingCheckBox = 'InvestingCheckBox',
    InvestingPasswordTextBox = 'InvestingPasswordTextBox',
    InvestingTextBox = 'InvestingTextBox',
    InvestingNumeric = 'InvestingNumeric',
    InvestingQuantityNumeric = 'InvestingQuantityNumeric',
    NotificationsButton = 'NotificationsButton',
    VideoWidgetButton = 'VideoWidgetButton',
    PasswordTextBox = 'PasswordTextBox',
    PanelSideBarBase = 'PanelSideBarBase',
    AlertItemSideBar = 'AlertItemSideBar',
    AlertPanelSideBar = 'AlertPanelSideBar',
    IdeasItemSideBar = 'IdeasItemSideBar',
    IdeasPanelSideBar = 'IdeasPanelSideBar',
    NotificationsItemSideBar = 'NotificationsItemSideBar',
    NotificationsPanelSideBar = 'NotificationsPanelSideBar',
    VideoWidgetItemSideBar = 'VideoWidgetItemSideBar',
    VideoWidgetPanelSideBar = 'VideoWidgetPanelSideBar',
    NewAccountDetailsPanel = 'NewAccountDetailsPanel',
    NewAccountDetailsGroup = 'NewAccountDetailsGroup',
    NewAccountDetailsItem = 'NewAccountDetailsItem',
    DPSLTPControl = 'DPSLTPControl',
    TradingViewOrderTool = 'TradingViewOrderTool',
    VerticalDetailsGroup = 'VerticalDetailsGroup',
    VerticalDetailsItem = 'VerticalDetailsItem',
    TerceraSeparator = 'TerceraSeparator',
    TerceraAutoHidePanel = 'TerceraAutoHidePanel'
}

export enum ScreensNames {
    // TODO. Remove.
    // InvestingMessageBox = 'InvestingMessageBox',
    InvestingForgotPasswordScreen = 'InvestingForgotPasswordScreen',
    InvestingChangePasswordScreen = 'InvestingChangePasswordScreen',
    TerceraMessageBox = 'TerceraMessageBox',
    AlertMessageBox = 'AlertMessageBox',
    TerceraLoginScreen = 'TerceraLoginScreen',
    InvestingLoginScreen = 'InvestingLoginScreen',
    ModifyScreen = 'MODIFYSCREEN',
    EditPositionScreen = 'EDITPOSITIONSCREEN',
    ClosePositionScreen = 'CLOSEPOSITIONSCREEN',
    ModifyPositionScreen = 'MODIFYPOSITIONSCREEN',
    ModifyOrderScreen = 'MODIFYORDERSCREEN',
    PositionExerciseRequestScreen = 'POSITIONEXERCISEREQUESTSCREEN',
    BrokerMessageScreen = 'BROKERMESSAGESCREEN',
    WithdrawalScreen = 'WITHDRAWALSCREEN',
    PopupErrorScreen = 'PopupErrorScreen',
    SnapshotScreen = 'SnapshotScreen',
    ConfirmationScreen = 'ConfirmationScreen',
}

export const PanelsWithAccountLookup = [
    PanelNames.NewAccountDetailsPanel,
    PanelNames.AdvancedOrderEntry,
    PanelNames.AssetsPanel,
    PanelNames.ChartPanel,
    PanelNames.CreateAlertPanel,
    PanelNames.IdeasPanel,
    PanelNames.MarketDepthPanel,
    PanelNames.MarginOEControl,
    PanelNames.PositionsBalancePanel,
    PanelNames.ReportsPanel,
    PanelNames.RiskCalculator,
    ScreensNames.WithdrawalScreen
];

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ContainerControl } from './ContainerControl';
import { AccountSelectorTemplate } from '../../templates';
import { AccountMenuItemsHelper } from '../../Commons/AccountWidget/AccountMenuItemsHelper';
import { DataCache } from '../../Commons/DataCache';
import { type TerceraAccountLookup } from './Lookup/TerceraAccountLookup';
import { type AccountsComboBox } from './AccountsComboBox';
import { AccountWidgetAccountsController } from '../../Commons/AccountWidget/AccountWidgetAccountsController';
import { TerceraLookupEvents } from './Lookup/TerceraLookup';

export class AccountSelector extends ContainerControl {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override oncomplete (): void {
        const IsOnlyOwnAccounts = AccountMenuItemsHelper.GetDataToFillOut(null).IsOnlyOwnAccounts;
        const isLookupForAccounts = this.get('isMultiSelectMode') as boolean || !IsOnlyOwnAccounts;
        if (!(this.get('visible') as boolean)) { return; }
        const visible = DataCache.getNumberOfAccounts() > 1;
        void this.set({ isLookupForAccounts });
        const ignoreForceLinkingByAccount: boolean = this.get('ignoreForceLinkingByAccount');
        const enableForceLinkingByAccount: boolean = DataCache.EnableForceLinkingByAccount();
        if (enableForceLinkingByAccount && (!ignoreForceLinkingByAccount || IsOnlyOwnAccounts)) { void this.set({ visible: false }); } else { void this.set({ visible }); }
        this.setStartValue(ignoreForceLinkingByAccount, enableForceLinkingByAccount);
        this.observe('selectedItem', this.onSelectedItemChanged, { init: false });
    }

    public getLookup (): TerceraAccountLookup {
        return this.Controls.accountLookup;
    }

    public getComboBox (): AccountsComboBox {
        return this.Controls.comboBox;
    }

    private setStartValue (ignoreForce: boolean, enableForce: boolean): void {
        if (!ignoreForce) return;

        const onlyOwnAccounts: boolean = this.get('onlyOwnAccounts');
        const accs = onlyOwnAccounts && enableForce ? DataCache.getOwnedAccounts() : DataCache.getAccounts();
        const acc = AccountWidgetAccountsController.Account;
        if (!isNullOrUndefined(accs[acc.AcctNumber])) { void this.set('selectedItem', acc); } else { void this.set('selectedItem', DataCache.getPrimaryAccount()); }
    }

    private onSelectedItemChanged (newValue, oldValue): void {
        this.fire(TerceraLookupEvents.SelectedItemChanged, newValue);
    }
}

ContainerControl.extendWith(AccountSelector, {
    template: AccountSelectorTemplate,
    data: function () {
        return {
            selectedItem: null,
            isLookupForAccounts: false,
            enabled: true,
            ignoreForceLinkingByAccount: false,
            onlyOwnAccounts: false,
            visible: true,
            excludeMultiAsset: false,
            isMultiSelectMode: false // TMP, always TerceraAccountLookup show
        };
    }
});

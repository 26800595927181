// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// allowed chart timeframes
export const TvChartSupportedResolutionArray =
    [
        '1',
        '2',
        '3',
        '5',
        '15',
        '30',
        /* '45', */
        '60',
        /* '120',
        '180', */
        '240',
        '1D',
        '1W',
        '1M',
        '12M'
    ];
Object.freeze(TvChartSupportedResolutionArray);

export enum TvSessionIdEnum {
    regular = 'regular',
    extended = 'extended',
    premarket = 'premarket',
    postmarket = 'postmarket'
}

export enum VisiblePlotsSetEnum {
    ohlcv = 'ohlcv',
    ohlc = 'ohlc',
    c = 'c',
}

export enum SeriesFormatEnum {
    price = 'price',
    volume = 'volume',
}

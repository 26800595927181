import { GeneralSettings } from '../../../../../Utils/GeneralSettings/GeneralSettings';
import { OperationType } from '../../../../../Utils/Trading/OperationType';
import { TIF } from '../../../../../Utils/Trading/OrderTif';
import { OrderTif } from '../../../../../Utils/Trading/OrderTifEnum';
import { OrderType } from '../../../../../Utils/Trading/OrderType';
import { TradingUtils } from '../../../../../Utils/Trading/TradingUtils';
import { UUID } from '../../../../../Utils/UUID';
import { SessionSettings } from '../../../../SessionSettings';
import { type Account } from '../../../Account';
import { type Instrument } from '../../../Instrument';
import { type Order } from '../../../Order';
import { type Position } from '../../../Position';
import { PaperPosition } from './PaperPosition';

export class PaperPositionsCache {
    private readonly _paperPositions: PaperPosition[] = [];
    public getPaperPositions (): PaperPosition[] {
        return [...this._paperPositions];
    }

    public getPaperPosition (option: Instrument): PaperPosition {
        if (isNullOrUndefined(option)) {
            return undefined;
        }
        for (let i = 0; i < this._paperPositions.length; i++) {
            const paperPosition = this._paperPositions[i];
            if (paperPosition.isPosition()) {
                continue;
            }
            if (paperPosition.Instrument === option) {
                return paperPosition;
            }
        }
        return undefined;
    }

    public getPaperPositionsForAnalyzer (): PaperPosition[] {
        const paperPositions: PaperPosition[] = [];
        for (let i = 0; i < this._paperPositions.length; i++) {
            const paperPosition = this._paperPositions[i];
            if (!paperPosition.Analyse) {
                continue;
            }
            paperPositions.push(paperPosition);
        }
        return paperPositions;
    }

    public getPaperPositionForOrder (order: Order): PaperPosition {
        if (isNullOrUndefined(order)) {
            return undefined;
        }
        for (let i = 0; i < this._paperPositions.length; i++) {
            const paperPosition = this._paperPositions[i];
            if (isValidString(order.Comment) && order.Comment.startsWith('SavedOrder.') && order.Comment.substring('SavedOrder.'.length) === paperPosition.Id) {
                return paperPosition;
            }
        }
        return undefined;
    }

    public getPaperPositionForPosition (position: Position): PaperPosition {
        if (isNullOrUndefined(position)) {
            return undefined;
        }
        for (let i = 0; i < this._paperPositions.length; i++) {
            const paperPosition = this._paperPositions[i];
            if (paperPosition.isAttachedPosition(position)) {
                return paperPosition;
            }
            if (isValidString(position.Comment) && position.Comment.startsWith('SavedOrder.') && position.Comment.substring('SavedOrder.'.length) === paperPosition.Id) {
                return paperPosition;
            }
        }
        return undefined;
    }

    public createPaperPosition (account: Account, option: Instrument): PaperPosition {
        if (isNullOrUndefined(account) || isNullOrUndefined(option)) {
            return undefined;
        }
        const paperPosition = this.initializePaperPosition(account, option);
        this._paperPositions.push(paperPosition);
        return paperPosition;
    }

    public removePaperPosition (option: Instrument): PaperPosition {
        const paperPosition = this.getPaperPosition(option);
        this.removePaperPositionFromCache(paperPosition);
        return paperPosition;
    }

    public removePaperPositionForOrder (order: Order): PaperPosition {
        const paperPosition = this.getPaperPositionForOrder(order);
        this.removePaperPositionFromCache(paperPosition);
        return paperPosition;
    }

    public removePaperPositionForPosition (position: Position): PaperPosition {
        const paperPosition = this.getPaperPositionForPosition(position);
        this.removePaperPositionFromCache(paperPosition);
        return paperPosition;
    }

    public changeInstrumentForPaperPosition (oldValue: Instrument, newValue: Instrument): PaperPosition {
        if (isNullOrUndefined(oldValue) || isNullOrUndefined(newValue)) {
            return undefined;
        }
        const paperPosition = this.removePaperPosition(oldValue);
        if (isNullOrUndefined(paperPosition)) {
            return undefined;
        }
        paperPosition.Instrument = newValue;
        this._paperPositions.push(paperPosition);
        return paperPosition;
    }

    public clear (onlyPositions: boolean): void {
        if (onlyPositions) {
            const paperPositionsForRemove = this._paperPositions.filter(paperPosition => paperPosition.isPosition());
            for (let i = 0; i < paperPositionsForRemove.length; i++) {
                const index = this._paperPositions.indexOf(paperPositionsForRemove[i]);
                this._paperPositions.splice(index, 1);
            }
        } else {
            this._paperPositions.splice(0, this._paperPositions.length);
        }
    }

    private initializePaperPosition (account: Account, option: Instrument): PaperPosition {
        const paperPosition = new PaperPosition(UUID.generateUUIDv5());
        paperPosition.Analyse = true;
        paperPosition.Active = true;
        paperPosition.Account = account;
        paperPosition.Instrument = option;
        paperPosition.Operation = OperationType.Buy;
        const orderTypes = TradingUtils.getAllowedOrderTypesForTrading(account, option);
        if (!isValidArray(orderTypes) || orderTypes.Contains(OrderType.Limit)) {
            paperPosition.OrderType = OrderType.Limit;
        } else {
            paperPosition.OrderType = orderTypes[0];
        }
        const defaultTif = SessionSettings.getDefaultTifForOrderType(paperPosition.OrderType);
        const allowedTifs = TradingUtils.getAllowedTifsForTrading(option, paperPosition.OrderType);
        paperPosition.TIF = allowedTifs.Contains(defaultTif) ? defaultTif : allowedTifs[0];
        if (paperPosition.TIF === OrderTif.GTD) {
            paperPosition.TIFExpiration = TIF.getDefaultExpireTimeForOrderType(paperPosition.OrderType, option);
        }

        return paperPosition;
    }

    private removePaperPositionFromCache (paperPosition: PaperPosition): void {
        if (isNullOrUndefined(paperPosition)) {
            return;
        }
        const index = this._paperPositions.indexOf(paperPosition);
        this._paperPositions.splice(index, 1);
    }
}

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraSeparatorTemplate } from '../../templates';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { type IContextMenuItem } from '../UtilsClasses/IContextMenuItem';
import { Control } from './Control';

export class TerceraSeparator extends Control {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.TerceraSeparator; }

    public override getContextMenuItem (): IContextMenuItem {
        return { separator: true };
    }
}

Control.extendWith(TerceraSeparator, {
    template: TerceraSeparatorTemplate,
    data: () => ({ isVertical: false })
});

// Copyright TraderEvolution Global LTD. © 2017-2023. All rights reserved.
import { HistoryType } from '../../Utils/History/HistoryType';
import { MathUtils } from '../../Utils/MathUtils';
import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { Resources, CurrentLang } from '../properties/Resources';
import { CustomEvent } from '../../Utils/CustomEvents';

import { OperationType } from '../../Utils/Trading/OperationType';
import { OrderType } from '../../Utils/Trading/OrderType';
import { TradingMode } from '../../Utils/Instruments/TradingMode';
import { TradingPositionType } from '../../Utils/Instruments/TradingPositionType';
import { QuotingType } from '../../Utils/Instruments/QuotingType';
import { CurPriceSource } from '../../Utils/Instruments/CurPriceSource';
import { CouponCycle } from '../../Utils/Instruments/CouponCycle';
import { type CrossRateForPositions, SessionOperations } from '../../Utils/Enums/Constants';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { AccountTradeStatus } from '../../Utils/Account/AccountTradeStatus';
import { PriceFormatter } from '../../Utils/Instruments/PriceFormatter';
import { InstrumentTradingBalance } from '../../Utils/Instruments/InstrumentTradingBalance';
import { InstrumentSpecificType } from '../../Utils/Instruments/InstrumentSpecificType';
import { DayPeriods, type HolidayDescription, type TradingSession } from '../../Utils/Session/Sessions';
import { Periods } from '../../Utils/History/TFInfo';
import { QuoteValid } from '../../Utils/Quotes/QuoteValid';
import { OffsetModeViewEnum } from '../../Utils/Trading/OffsetModeViewEnum';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { RiskSettings } from './RiskSettings';
import { Limits } from './Limits';
import { Level1Calculator } from './Level1Calculator';
import { NumericUtils } from '../../Utils/NumericUtils';
import { InterTraderBtnStatus } from '../../Utils/Instruments/InterTraderBtnStatus';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { type Account } from './Account';
import { VariableTick } from '../../Utils/Instruments/VariableTick';
import { EXT_INSTRUMENT_MESSAGE, ExerciseStyle, VariableTickMovemementType } from './InstrumentConstants';
import { type RiskPlanSettings } from './RiskPlanSettings';
import { WarningForChangeFromLastPrice } from './WarningForChangeFromLastPrice';
// need convert DirectMessages to ts for using import { DirectInstrumentDayBarMessage, DirectInstrumentMessage } from '../../Utils/DirectMessages/DirectMessagesImport';
import { DirectInstrumentDayBarMessage } from '../../Utils/DirectMessages/DirectInstrumentDayBarMessage';
import { DirectInstrumentMessage } from '../../Utils/DirectMessages/DirectInstrumentMessage';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { GeneralSettingsWrapper } from '../../WebMobileReact/Models/GeneralSettingsWrapper';
import { WMSymbolLookupViewItemHelper } from '../../WebMobileReact/Views/Components/Elements/SymbolLookup/WMSymbolLookupViewItemHelper';
import { GlobalFlags } from '../UtilsClasses/GlobalFlags';
import { OptionPutCall } from '../../Utils/Instruments/OptionPutCall';
import { InstrumentDayInfo } from '../../Utils/Instruments/InstrumentDayInfo';
import { VolumeHistoryMode } from '../../Utils/Volume/VolumeConstantsAndEnums';
import { type DaysPerType } from '../../Utils/Instruments/InstrumentFeatureEnum';
import { PositionsTotalInfo } from './PositionsTotalInfo';
import { DataCache } from '../DataCache';
import { type RiskPlanItem } from '../../Utils/RiskPlan/RiskPlanItem';
import { type SwapType } from '../../Utils/Instruments/SwapType';
// TODO. Multi select ItemALL refactoring.

// TODO.TODO.TODO.TODO.TODO.TODO.TODO.
export class Instrument {
    public DataCache: DataCache;
    public UseOptionDescription: boolean | null = null;
    public ForwardBaseInstrument: Instrument = null;
    public ForwardBaseInstrumentId: number | undefined = undefined;
    public ExpDate: any = null;
    public _cachedInteriorID: string | null | undefined = null;
    public Level1: Level1Calculator;
    public DataSourceLevel1: Level1Calculator;
    public InstrumentDayInfo: InstrumentDayInfo;
    public Id: any;
    public Description: string;
    public UnderlierDescription: string = '';
    public PointSize: number;
    public Precision: number = -1;
    public Exp1: string;
    public Exp2: string;
    public AssetName: any = null;
    public InstrType: InstrumentTypes;
    public TypeId: any;
    public ShortSwapInfo: string;
    public SwapBuy: number;
    public SwapSell: number;
    public SwapType: SwapType;
    public ValueCrossRateForPositions: CrossRateForPositions;

    // то, что пришло от сервера, т.е. константа серверная. При вызове
    // HistoryType заменяется на протрейдеровскую
    public HistoryType: any = 0;

    // настройка для отображения чарта
    public DefaultChartHistoryType: any = 0;
    public RoutesSupported: any;
    public RoutesSupportedArray: any[];
    public TradableRoutes: any;
    public TradableRoutesArray: any;
    public VariableTickList: VariableTick[] | null = null;
    public TradingSessionsId: any;
    private lotStep: any;
    private readonly lotStepPrecisionCache = new Map<string, number>();
    public LotSize: number;
    public MinLot: number;
    public MaxLot: number;
    public FuturesTickCoast: any;
    public ContractMultiplier: any;
    public TradingMode: TradingMode = TradingMode.FullyClosed;
    public TradingPositionType: TradingPositionType;
    public AllExpDateLoaded: any;
    public AllContractsLoaded: any;
    public ShortName: string;
    public FullName: any;
    public routeName: string = '';
    public DisplayFullName: string = '';
    public CurPriceSource: CurPriceSource = CurPriceSource.Level1;

    public BidOpen: number = Number.NaN;
    public BidHigh: number = Number.NaN;
    public BidLow: number = Number.NaN;
    public BidPrevClose: number = Number.NaN;

    public AskOpen: number = Number.NaN;
    public AskHigh: number = Number.NaN;
    public AskLow: number = Number.NaN;
    public AskPrevClose: number = Number.NaN;

    public BidPreOpen: number = 0; // Not Used
    public BidHighGeneral: number = 0; // Not Used
    public BidLowGeneral: number = 0; // Not Used
    public BidPostClose: number = 0; // Not Used

    public AskPreOpen: number = 0; // Not Used
    public AskHighGeneral: number = 0; // Not Used
    public AskLowGeneral: number = 0; // Not Used
    public AskPostClose: number = 0; // Not Used

    public Ticks: number = 0;
    public TicksPreMarket: number = 0;
    public TicksPostMarket: number = 0;
    public VolumePreMarket: number = 0;
    public VolumePostMarket: number = 0;
    public OpenInterest: number = 0;

    public SettlementPrice: number = NaN;
    public PrevSettlementPrice: number = NaN;
    public BidMainClose: number = NaN;
    public AskMainClose: number = NaN;

    public Open: number = Number.NaN;
    public Close: number = Number.NaN;
    public High: number = Number.NaN;
    public Low: number = Number.NaN;
    public Volume: number = 0;
    public TotalVolume: number = Number.NaN;

    public OHLC_BidHigh: number = Number.NaN; // Обработка месседжа котировки дополнительно происходит в Instrument.OHLCCalculator, появилось с необходимостью получения корректных значений DayHigh/Low #91760
    public OHLC_BidLow: number = Number.NaN;
    public OHLC_AskHigh: number = Number.NaN;
    public OHLC_AskLow: number = Number.NaN;
    public OHLC_TradeHigh: number = Number.NaN;
    public OHLC_TradeLow: number = Number.NaN;

    public FiftyTwoWeekHighPrice: any = null;
    public FiftyTwoWeekLowPrice: any = null;
    public High13Week: any = null;
    public Low13Week: any = null;
    public High26Week: any = null;
    public Low26Week: any = null;

    // TODO. Refactor.
    public FirstQuote: any = null;
    public LastQuote: any = null;
    public LastInvalidQuote: any = null;

    public exchangeSessionName: any = null;

    public TradingSessionsList: any[] = [];
    public NeedCheckSession: boolean = false;

    public CurrentTradingSession: TradingSession = null;
    public BlockTradingBySession: boolean = false;

    public TradeSessionStatusId: any = null;
    public QuotingType: QuotingType = QuotingType.None;
    public Limits: Limits;
    public InstrumentAdditionalInfo: Record<string, any> = {};

    public pipsSizeSpecified: any = null;
    public InstrumentTradableID: number | null = null;

    public FLanguageAliases: any = null;
    public TradingBalance: number = 0;
    public ExchangeSessionNameUpdated: CustomEvent = new CustomEvent();
    public NewQuote: CustomEvent = new CustomEvent();

    public RiskSettings: RiskSettings;
    public RiskSettingsUpdated: CustomEvent = new CustomEvent();
    public ExpDateReal: Date = DateTimeUtils._ZeroTime;
    public InstrumentSpecificType: number = InstrumentSpecificType.None;
    public HideAsset: boolean = true;

    public Industry: string = '';
    public Sector: string = '';
    public IndustryCustomListId: number | null = null;
    public SectorCustomListId: number | null = null;
    public ShortPositionInterest: number | null = null;
    public DailyAvailableForShort: any = null;
    public SaveQuotesHistory: boolean | null = null;
    public UseSessionPeriodForIntradayChart: boolean | null = null;

    public ISIN: string = '';
    public LogoAddress: any = null;
    public MarketCap: number = 0;
    public ContractSize: number = 1;

    public ExchangeId: any = null;
    public TradingExchange: any = null;
    public MarketDataExchange: any = null;
    public CountryId: number = -1;

    public ExerciseStyle: any = null;
    public SeriesDescription: string = '';
    public WarningForChangeFromLastPrice: any = null; // https://tp.traderevolution.com/entity/108864
    public IsAllowCustomSLTPTriggerPrice: any = null; // https://tp.traderevolution.com/entity/109798

    public AllowExerciseForItmOption: boolean = false;
    public PrevPaymentDate: Date = DateTimeUtils._ZeroTime;
    public ContractMonthDate: Date = DateTimeUtils._ZeroTime;
    public FirstTradeDate: Date = DateTimeUtils._ZeroTime;
    public NoticeDate: Date = DateTimeUtils._ZeroTime;

    public PutCall: OptionPutCall = OptionPutCall.OPTION_NONE;
    public StrikePrice: number;

    public SettlementDate: any = null;
    public HightLimit: any = undefined;
    public LowLimit: any = undefined;
    public isHideRouteMode: boolean;
    public asset_exp2: any;
    public CFD: boolean;
    public DeliveryMethod: any;
    public InstrDateSettingsList: any;
    public isOptionSymbol: boolean;
    public isFuturesSymbol: boolean;
    public OptionTradingStyle: any;
    public LastTradeDate: Date;
    public MaturityDate: Date;
    public FaceValue: any;
    public CouponRate: any;
    public CouponCycle: any;
    public AccruedInterest: any;
    public IsHighLimitFrontEndValidationEnabled: any;
    public IsLowLimitFrontEndValidationEnabled: any;
    public PriceLimitMeasure: number;
    public SeriesGroupName: any;
    public AdditionalFields: any;
    public Locked: any;
    public VolumeHistoryMode: number;
    public IssueDate: any;
    public SourceName: any;
    public NextPaymentDate: any;
    public MainClose: number;
    public DaysPerMonth: DaysPerType;
    public DaysPerYear: DaysPerType;

    // задержка котировок
    public QuoteDelay: number = 0;
    public QuoteCanGetSnapshot: boolean | null = null;
    public HasLvl1SubscriptionErrorCode: boolean | null = null;

    public static readonly Delivery_Physical = 0;
    public static readonly Delivery_Cash = 1;

    public static readonly FAKE_NONFIXED_RESPONSE = 'fakeNonFixedInstrument';

    public static saveMode = false;

    public static CONTINUOUS_CONTRACT_POSTFIX = '_C';

    public readonly Route: any;

    private DataSourceTradableId: number | null | undefined = null;
    private DataSourceRouteId: number | null | undefined = null;
    public DataSourceInstrument: any = null;
    private _instrumentDayBarMessageUpdateMode: boolean;

    // #region InstrDateSettings_properties
    // Поля, які стосуються InstrDateSettings
    public DeliveryStatus: number;
    public ExpirationDate: any;
    public AutoCloseDate: any;
    public IsContinuous: boolean;
    public ShowDayForFutures: boolean;
    public UnderlierDate: any;
    public CheckIntrinsic: any;
    public StrikePricesList: any;
    public ContractID: number;
    public minLot: any;
    public maxLot: any;
    public VariableTicks: any;
    public FutureAliasName: any;
    public useAliasName: boolean;
    // #endregion InstrDateSettings_properties

    // #region StrikePriceSettings_properties
    // Поля, які стосуються StrikePriceSettings
    public PutEnabled: any;
    public CallEnabled: any;
    public PutTicker: any;
    public CallTicker: any;
    public LowLimitPut: number;
    public LowLimitCall: number;
    public HighLimitPut: number;
    public HighLimitCall: number;
    public IsLowLimitFrontEndValidationEnabledPut: any;
    public IsLowLimitFrontEndValidationEnabledCall: any;
    public IsHighLimitFrontEndValidationEnabledPut: any;
    public IsHighLimitFrontEndValidationEnabledCall: any;
    public minLotPut: number;
    public minLotCall: number;
    public maxLotPut: number;
    public maxLotCall: number;
    public PriceLimitMeasurePut: any;
    public PriceLimitMeasureCall: any;
    public DescriptionPut: any;
    public DescriptionCall: any;
    public ExtFieldsPut: any;
    public ExtFieldsCall: any;
    public TradeSessionStatusIdPut: any;
    public TradeSessionStatusIdCall: any;
    public ExchangeSessionNamePut: any;
    public ExchangeSessionNameCall: any;
    public ContractIdPut: any;
    public ContractIdCall: any;
    public ISINPut: string;
    public ISINCall: string;
    public ContinuousContractName: any;
    public SourceDescription: any;
    public ForwardBaseInstruments: any[];
    public isFakeOption: boolean = false;
    public PositionsTotalInfo: PositionsTotalInfo = new PositionsTotalInfo();
    // #endregion StrikePriceSettings_properties

    private _yieldRate: number;

    constructor (dataCache, mess, route) {
        this.DataCache = dataCache;

        this.Level1 = new Level1Calculator(this);
        this.DataSourceLevel1 = new Level1Calculator(this);
        this.InstrumentDayInfo = new InstrumentDayInfo(this);

        this.ShortName = mess.Name || '';
        this.FullName = InstrumentUtils.GetFullName(this.ShortName, route);
        this.Route = route;

        // TODO.
        this.InstrumentDayBarMessageUpdateMode = false;
        let r = dataCache.getRouteById(route);
        if (r !== null) {
            this.InstrumentDayBarMessageUpdateMode = r.InstrumentDayBarMessageUpdateMode;
            this.RouteName = r.Name;
            this.FullName = InstrumentUtils.GetFullName(this.ShortName, r.Name);

            // 53234 - уточнил у Качура, для случая когда один роут инфо, а второй торговый, копируем настройку с инфо роута
            // исходя из комента выше, нужно добавить поиск роута с котировками в нашем случае
            if (r.QuoteRouteId !== r.RouteId) {
                r = dataCache.getRouteById(r.QuoteRouteId);
            }
            if (r !== null) {
                this.InstrumentDayBarMessageUpdateMode = r.InstrumentDayBarMessageUpdateMode;
            }
        }

        this.Limits = new Limits(this);

        this.RiskSettings = new RiskSettings(this);

        this.StrikePrice = DateTimeUtils._ZeroTime.getTime();

        this.UpdateByMessage(mess);
    }

    public get MinDelta (): number {
        if (this.PointSize > 0) return this.MinimumPointSize();
        return 1;
    }

    get RouteName (): string {
        return this.routeName;
    }

    set RouteName (value: string) {
        this.routeName = value;
        this.DisplayFullName = InstrumentUtils.GetFullName(this.ShortName, value);
    }

    public get InstrumentDayBarMessageUpdateMode (): boolean {
        return this._instrumentDayBarMessageUpdateMode;
    }

    public set InstrumentDayBarMessageUpdateMode (value: boolean) {
        this._instrumentDayBarMessageUpdateMode = value;
        this.InstrumentDayInfo.InstrumentDayBarMessageUpdateMode = value;
    }

    get YieldRate (): number {
        return this._yieldRate;
    }

    set YieldRate (value: number) {
        if (!isValidNumber(value)) {
            return;
        }

        this._yieldRate = MathUtils.RoundDouble(value, 4);
    }

    get LocalizedIndustry (): string {
        return DataCache.customInstrumentListsCache.getInstrumentIndustry(this);
    }

    get LocalizedSector (): string {
        return DataCache.customInstrumentListsCache.getInstrumentSector(this);
    }

    public DisplayName (): string {
        let alias = '';
        if (this.FLanguageAliases) {
            const languageAlias = this.FLanguageAliases[CurrentLang];
            if (languageAlias) { alias = languageAlias.Name; }
        }

        return alias || (this.isHideRouteMode ? this.ShortName : (this.DisplayFullName ? this.DisplayFullName : this.FullName));
    }

    public DisplayShortName (): string {
        let alias = '';
        if (this.FLanguageAliases) {
            const languageAlias = this.FLanguageAliases[CurrentLang];
            if (languageAlias) { alias = languageAlias.Name; }
        }

        return alias || this.ShortName;
    }

    public LogoName (): string {
        let alias: any = null;
        if (this.FLanguageAliases) { alias = this.FLanguageAliases[CurrentLang]; }

        if (alias?.Description) { return alias.Description; };

        if (this.Description) { return this.Description; }

        if (alias?.Name) { return alias.Name; }

        const name = this.isHideRouteMode ? this.ShortName : (this.DisplayFullName ? this.DisplayFullName : this.FullName);
        if (this.InstrumentSpecificType === InstrumentSpecificType.ContinuousContract) { return name[1]; }

        return name;
    }

    public DescriptionValue (): string {
        let langDesc = '';
        if (this.FLanguageAliases) {
            const languageAlias = this.FLanguageAliases[CurrentLang];
            if (languageAlias) { langDesc = languageAlias.Description; }
        }
        return langDesc || this.Description;
    }

    public getInstrumentTypeName (): string {
        const t = this.DataCache.getInstrumentTypeById(this.TypeId);
        if (t !== null) { return t.DisplayName(); } else { return ''; }// return "Undefined";
    }

    public getInstrumentGroupId (): number {
        const t = this.DataCache.getInstrumentTypeById(this.TypeId);
        if (t != null) {
            return t.TypeId;
        } else {
            return -1;
        }
    }

    public setPrecision (mess): void {
        this.Precision = mess.Precision;
        if (!this.Precision) {
            this.Precision = Instrument.CalculatePrecision(mess.PointSize, mess.VariableTickList);
        }
    }

    // TODO. Add remaining stuff.
    public UpdateByMessage (mess: DirectInstrumentMessage): void {
        if (mess.HightLimit >= 0) { this.Limits.HightLimit = mess.HightLimit; }
        if (mess.LowLimit >= 0) { this.Limits.LowLimit = mess.LowLimit; }
        if (mess.PriceLimitMeasure >= 0) { this.Limits.PriceLimitMeasure = mess.PriceLimitMeasure; }

        if (mess.IsHighLimitFrontEndValidationEnabled !== null) { this.Limits.IsHighLimitFrontEndValidationEnabled = mess.IsHighLimitFrontEndValidationEnabled; }
        if (mess.IsLowLimitFrontEndValidationEnabled !== null) { this.Limits.IsLowLimitFrontEndValidationEnabled = mess.IsLowLimitFrontEndValidationEnabled; }

        this.Level1.UseOnlyLast = mess.UseOnlyLast;

        this.Id = mess.Id;

        this.Description = mess.Descr;
        this.UnderlierDescription = mess.UnderlierDescription;

        this.PointSize = mess.PointSize;

        this.setPrecision(mess);

        this.PointSize = MathUtils.RoundDouble(this.PointSize, this.Precision);
        this.pipsSizeSpecified = mess.PipsSizeSpecified;

        this.AssetName = mess.AssetName;

        this.ShortSwapInfo = mess.ShortSwapInfo;
        this.SwapBuy = mess.SwapBuy;
        this.SwapSell = mess.SwapSell;
        this.SwapType = mess.SwapType;

        this.InstrType = mess.InstrType;
        this.TypeId = mess.TypeId;

        this.Exp1 = this.getExp1(mess.Exp1, mess.Exp2);
        this.Exp2 = this.getExp2(mess.Exp2);
        this.asset_exp2 = this.DataCache.GetAssetByName(this.Exp2);
        this.ValueCrossRateForPositions = mess.ValueCrossRateForPositions;

        this.RiskSettings.Update(mess);

        this.HistoryType = mess.HistoryType;
        if (this.HistoryType === 0) { this.HistoryType = HistoryType.QUOTE_LEVEL1; }

        this.DefaultChartHistoryType = mess.DefaultChartHistoryType;
        if (this.DefaultChartHistoryType === 0) { this.DefaultChartHistoryType = this.HistoryType; }

        this.RoutesSupported = mess.RoutesSupported;
        this.RoutesSupportedArray = this.RoutesSupported;// Instrument.getSupportedRoutes(this.RoutesSupported);

        this.TradableRoutes = mess.TradableRoutes;
        this.TradableRoutesArray = this.TradableRoutes;// Instrument.getSupportedRoutes(this.TradableRoutes);

        if (mess.VariableTickList?.length) {
            this.VariableTickList = mess.VariableTickList;
        } else {
            this.VariableTickList = [
                new VariableTick({
                    lowLimit: -Infinity,
                    highLimit: Infinity,
                    allowLimit: true,
                    pointSize: this.PointSize,
                    tickCost: 1.0
                })
            ];
        }

        // TODO. Sessions? Maybe "session"?
        this.TradingSessionsId = mess.TradingSessionsId;

        const sc = this.DataCache.GetSession(mess.TradingSessionsId);
        if (sc) {
            this.TradingSessionsList = sc.Sessions;
            this.NeedCheckSession = !(this.GetTradingSessionsList().length === 0);
            this.CurrentTradingSession = sc.GetSessionByID(sc.CurrentSessionId);
            this.BlockTradingBySession = sc.BlockTrading;
        }

        if (mess.ExchangeId !== null && mess.ExchangeId !== undefined) { this.ExchangeId = mess.ExchangeId; }

        this.TradingExchange = mess.TradingExchange;
        this.MarketDataExchange = mess.MarketDataExchange;
        this.CountryId = mess.CountryId;

        this.ExerciseStyle = mess.ExerciseStyle;

        this.lotStep = mess.LotStep;
        this.LotSize = mess.LotSize;

        this.MinLot = mess.MinimalLot;
        this.MaxLot = mess.MaxLot;

        // TODO. Coast? Maybe "cost"?
        this.FuturesTickCoast = mess.FuturesTickCoast;
        this.ContractMultiplier = mess.ContractMultiplier || 1;

        this.TradingMode = mess.TradingMode;
        this.TradingPositionType = mess.TradingPositionType;

        // TODO.
        /* this.forwardBaseInstrument = mess.ForwardBaseInstrument;
        */
        this.UseOptionDescription = mess.UseOptionDescription;
        this.ForwardBaseInstrumentId = mess.ForwardBaseInstrumentID;

        // TODO. Wrong message field. Use mess.InstrumentAdditionalInfo instead.
        if (mess.AdditionalFields) {
            const additionalFields = mess.AdditionalFields;
            for (const key in additionalFields) { this.InstrumentAdditionalInfo[key] = additionalFields[key]; };
        }

        this.AllExpDateLoaded = mess.AllExpDateLoaded;
        this.AllContractsLoaded = mess.AllContractsLoaded;

        // if (mess.QuotingType !== QuotingType.None)       // ДУБЛИКАТ
        //     this.QuotingType = mess.QuotingType;
        // else
        //     this.QuotingType = (this.InstrType === Instrument.FUTURES || this.InstrType === Instrument.CFD_FUTURES || this.InstrType === Instrument.OPTIONS || this.InstrType === Instrument.SPREADBET || this.InstrType === Instrument.FORWARD) ? QuotingType.TickCost_TickSize : QuotingType.LotSize;

        this.CFD = this.InstrType === InstrumentTypes.EQUITIES_CFD || this.InstrType === InstrumentTypes.CFD_FUTURES;

        this.CurPriceSource = mess.curPriceSource;

        this.TradingBalance = mess.TradingBalance;
        this.DeliveryMethod = mess.DeliveryMethod;

        if (mess.ContractSize !== null) { this.ContractSize = mess.ContractSize; }

        this.TradeSessionStatusId = mess.TradeSessionStatusId || -1;

        this.ExchangeSessionName = mess.ExchangeSessionName;

        this.InstrDateSettingsList = mess.InstrDateSettingsList;

        if (mess.LanguageAliases !== null) {
            this.FLanguageAliases = Instrument.CloneLangAliases(mess.LanguageAliases);
        }

        // TODO ?
        this.isOptionSymbol = this.InstrType === InstrumentTypes.OPTIONS;
        this.isFuturesSymbol = this.InstrType === InstrumentTypes.FUTURES || this.InstrType === InstrumentTypes.CFD_FUTURES;
        this.UpdateHideRouteMode();

        this.OptionTradingStyle = mess.OptionTradingStyle;

        const insType = this.InstrType;

        if (mess.QuotingType !== QuotingType.None) {
            this.QuotingType = mess.QuotingType;
        } else {
            this.QuotingType =
                insType === InstrumentTypes.FUTURES ||
                    insType === InstrumentTypes.CFD_FUTURES ||
                    insType === InstrumentTypes.OPTIONS ||
                    insType === InstrumentTypes.SPREADBET ||
                    insType === InstrumentTypes.FORWARD
                    ? QuotingType.TickCost_TickSize
                    : QuotingType.LotSize;
        }

        if (mess.InstrumentTradableID) { this.InstrumentTradableID = mess.InstrumentTradableID; }

        if (mess.DataSourceTradableId) {
            this.DataSourceTradableId = mess.DataSourceTradableId;
            this.DataSourceInstrument = null;
        }
        if (mess.DataSourceRouteId) {
            this.DataSourceRouteId = mess.DataSourceRouteId;
        }

        if (mess.LastTradeDate) { this.LastTradeDate = mess.LastTradeDate; }
        if (mess.MaturityDate) { this.MaturityDate = mess.MaturityDate; }
        if (mess.FaceValue) { this.FaceValue = mess.FaceValue; }
        if (isValidNumber(mess.CouponRate)) { this.CouponRate = mess.CouponRate; }
        if (isValidNumber(mess.CouponCycle)) { this.CouponCycle = mess.CouponCycle; }
        if (isValidNumber(mess.YieldRate)) { this.YieldRate = mess.YieldRate; }
        if (mess.AccruedInterest) { this.AccruedInterest = mess.AccruedInterest; }
        if (mess.DaysPerMonth != null) { this.DaysPerMonth = mess.DaysPerMonth; }
        if (mess.DaysPerYear != null) { this.DaysPerYear = mess.DaysPerYear; }
        this.FindPaymentDates(mess.IssueDate, mess.CouponCycle);
        this.ISIN = mess.ISIN;
        this.LogoAddress = mess.LogoAddress;
        this.MarketCap = mess.MarketCap;

        if (mess.Industry) { this.Industry = mess.Industry; }
        if (mess.Sector) { this.Sector = mess.Sector; }

        if (mess.IndustryCustomListId) { this.IndustryCustomListId = mess.IndustryCustomListId; }
        if (mess.SectorCustomListId) { this.SectorCustomListId = mess.SectorCustomListId; }

        // if (mess.ShortPositionInterest)                                   // 95609
        this.ShortPositionInterest = mess.ShortPositionInterest;
        // if (mess.DailyAvailableForShort)
        this.DailyAvailableForShort = mess.DailyAvailableForShort;
        this.SaveQuotesHistory = mess.SaveQuoteHistory;
        this.UseSessionPeriodForIntradayChart = mess.UseSessionPeriodForIntradayChart;

        const warningForChangeFromLastPrice = this.GetWarningForChangeFromLastPrice(mess.WarningForChangeFromLastPriceRanges);
        if (warningForChangeFromLastPrice) { this.WarningForChangeFromLastPrice = warningForChangeFromLastPrice; };

        if (mess.IsAllowCustomSLTPTriggerPrice !== null) {
            this.IsAllowCustomSLTPTriggerPrice = mess.IsAllowCustomSLTPTriggerPrice;
        }

        if (!isNullOrUndefined(mess.IsAllowExerciseForItmOption)) {
            this.AllowExerciseForItmOption = mess.IsAllowExerciseForItmOption;
        }

        if (this.InstrType === InstrumentTypes.FORWARD) {
            if (mess.LastTradeDate) { this.LastTradeDate = mess.LastTradeDate; }
            if (mess.MaturityDate) { this.ExpDateReal = mess.MaturityDate; }
        }

        this.VolumeHistoryMode = mess.InstrType === InstrumentTypes.FOREX || mess.InstrType === InstrumentTypes.EQUITIES_CFD || mess.InstrType === InstrumentTypes.CFD_FUTURES || mess.InstrType === InstrumentTypes.SPREADBET ? VolumeHistoryMode.Ticks : VolumeHistoryMode.Trades;
        // if (this.QuotingType === QuotingType.TickCost_TickSize)          // Ilya,Denys: закомментировали в связи с 88187 добавлено было с 87206 но такой же пересчет LotSize происходит позже, проверили вроде не отпадет 87206
        //     this.LotSize = this.FuturesTickCoast / this.PointSize;
    }

    public UpdateHideRouteMode (): void {
        // TODO
        // let routesSupported = this.RoutesSupportedArray.slice(); //#43596
        // for (let j = 0; j < this.TradableRoutesArray.length; j++)
        // {
        //    if (routesSupported.Contains(this.TradableRoutesArray[j]))
        //        routesSupported.splice(routesSupported.indexOf(this.TradableRoutesArray[j]), 1);
        // }

        if (this.TradableRoutesArray.length > 1 || this.RoutesSupportedArray.length > 1) {
            this.isHideRouteMode = false;
        } else {
            this.isHideRouteMode = true;
        }

        this.UpdateHideRouteModeLangAliases();
    }

    public NeedToHide (): boolean // true если инструмент НЕ нужно отображать в лукапе, TerceraInstrumentLookupDropDownForm и т.д.
    {
        if (this.isFakeOption) {
            return false;
        }
        if (!this.TradableRoutesArray || !this.RoutesSupportedArray) return true; // возможно излишняя перестраховка

        const result = this.TradableRoutesArray.length === 1 && this.Route != this.TradableRoutesArray[0] && this.RoutesSupportedArray.length === 1; // правила по которым скрываем/отображаем инструменты в лукапе можно почитать в комментариях к тикету: https://tp.traderevolution.com/entity/97055

        return result;
    }

    public static CloneLangAliases (alliases): any {
        if (!alliases) { return; }

        const result = {};
        const keys = Object.keys(alliases);
        for (let i = 0; i < keys.length; i++) {
            result[keys[i]] = { Name: alliases[keys[i]].Name, Description: alliases[keys[i]].Description };
        }

        return result;
    }

    public UpdateHideRouteModeLangAliases (): void {
        if (!this.FLanguageAliases) { return; }

        const keys = Object.keys(this.FLanguageAliases);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            const isFullName = InstrumentUtils.IsFullName(this.FLanguageAliases[key].Name);

            if (isFullName && this.isHideRouteMode) {
                this.FLanguageAliases[key].Name = InstrumentUtils.RemoveRouteName(this.FLanguageAliases[key].Name);
            } else if (!isFullName && !this.isHideRouteMode) {
                this.FLanguageAliases[key].Name = InstrumentUtils.GetFullNameUnsafe(this.FLanguageAliases[key].Name, this.RouteName);
            }
        }
    }

    public IsPositionCloseOnly (productType, account: Account): boolean {
        const riskSettings = this.RiskSettings;

        return riskSettings.IsPositionCloseOnly(productType, account) ||
            account.AccountTradeStatus == AccountTradeStatus.LIQUIDATION_ONLY_STATUS ||
            this.TradingMode === TradingMode.LiquidationOnly; // #113662
    }

    public getLotStep (productType?: ProductType, account?: Account): number {
        return this.RiskSettings.getLotStep(productType, account) ?? this.lotStep;
    }

    public getMinLot (productType: ProductType, account: Account | undefined | null = undefined): number {
        const riskSettings = this.RiskSettings;

        return riskSettings.getMinLot(productType, account) !== -1 // сперва смотрим есть ли в Risk Plan
            ? riskSettings.getMinLot(productType, account)
            : this.MinLot; // MinLot есть всегда т.к. отключить настройку Minimum lot на инструменте возможности нет
    }

    public getMaxLot (productType: ProductType, account: Account | undefined | null = undefined): number {
        const riskSettings = this.RiskSettings;

        return riskSettings.getMaxLot(productType, account) !== -1 // сперва смотрим есть ли в Risk Plan
            ? riskSettings.getMaxLot(productType, account)
            : (this.MaxLot !== -1 // иначе смотрим есть ли в инструменте,
                ? this.MaxLot
                : NumericUtils.MAXVALUE); // если и там нет, то по такому инструменту ограничение не накладывается
    }

    public getExp1 (exp1, exp2): string {
        this.HideAsset = true; // #88334
        // используем валюту из мессаджа (если exp1==exp2 - это сток нашего сервера)
        if (exp1 && exp1 !== exp2) {
            this.HideAsset = false;

            return exp1;
        }

        // используем имя инструмента
        if (!this.isForexSymbol()) { return this.ShortName; }

        // форексный - парсим и возврщаем CCY1
        const index = this.ShortName.indexOf('/');
        return (index === -1)
            ? this.DataCache.baseCurrency
            : this.ShortName.substring(0, index);
    }

    public getExp2 (exp2): any {
        // используем валюту из мессаджа
        if (exp2) { return exp2; }

        // нефорексный - используем базовую
        if (!this.isForexSymbol()) { return this.DataCache.baseCurrency; }

        // форексный - парсим и возврщаем CCY2
        const index = this.ShortName.indexOf('/');
        return index === -1
            ? this.DataCache.baseCurrency
            : this.ShortName.substring(index + 1);
    }

    public isForexSymbol (): boolean {
        return this.InstrType === InstrumentTypes.FOREX;
    }

    // TODO
    public isFutureOrOption (): boolean {
        return this.InstrType === InstrumentTypes.FUTURES ||
            this.InstrType === InstrumentTypes.OPTIONS;
    }

    public toString (): string {
        return this.isHideRouteMode ? this.ShortName : (this.DisplayFullName ? this.DisplayFullName : this.FullName);
    }

    public toStringWithDescr (): string {
        const name = this.toString();

        let res = name;
        if (this.Description) { res = res + ' (' + this.Description + ')'; }
        return res;
    }

    public RouteIsTradable (): boolean {
        const route = this.DataCache.getRouteByName(this.Route);
        return route ? route.IsTradable : false;
    }

    public getRoute (): any {
        return this.Route;
    }

    public IsFakeNonFixed (): boolean {
        return this.Exp2 === Instrument.FAKE_NONFIXED_RESPONSE;
    }

    public getTypeString (): string {
        return Instrument.getTypeString(this.InstrType, this.CFD);
    }

    // TODO. Refactor.
    public static getTypeString (instrumentType, cfd?): string {
        return InstrumentUtils.getInstrumentTypeStringLocalized(instrumentType, cfd);
    }

    public static getSupportedRoutes (supportedRoutes): any[] {
        const resultArr: any[] = [];

        const splits = supportedRoutes.split(/,|;/);
        const len = splits.length;

        for (let i = 0; i < len; i++) {
            const split = splits[i];
            if (split) resultArr.push(split);
        }

        return resultArr;
    }

    public truncatePriceToPrecision (price: number): number {
        if (isNaN(price)) { price = 0; };

        const point = this.getPrecision(price);

        return MathUtils.TruncateDouble(price, point);
    }

    // TODO. Optimize. Instrument.format() NumberFormat creation + getPrecision().
    public formatPrice (price: number, useVariableTickSize = true, withRounding = false): string {
        return PriceFormatter.formatPrice(
            price,
            useVariableTickSize ? this.getPrecision(price) : this.Precision, withRounding);
    }

    public getDoubleVolume (volume, showLots): any {
        return showLots && !isNaN(volume)
            ? volume / this.getLotSize()
            : volume;
    }

    public AllowUseFractinalTicksForForex (): boolean {
        return this.InstrType == InstrumentTypes.FOREX;
    }

    public formatOffset (ticks: number): any {
        if (GeneralSettings.TradingDefaults?.ShowOffsetIn == OffsetModeViewEnum.Points) { return this.formatPrice(ticks * this.GetPointSize(null)); } else if (this.AllowUseFractinalTicksForForex() && GeneralSettings.TradingDefaults?.IsTicksFractionalForForex()) {
            const factor = Math.pow(10, NumericUtils.getNumericsOffsetModeViewParams(this, true).numericsPrec);
            return (MathUtils.trunc(ticks * factor) / factor).toFixed(1);
        } else {
        // var factor = Math.pow(10, Utils.getNumericsOffsetModeViewParams(this, true).numericsPrec);
        // return MathUtils.trunc(ticks * factor) / factor;
            return MathUtils.formatDoubleValueUsePrecision(ticks, NumericUtils.getNumericsOffsetModeViewParams(this, true).numericsPrec).toString();
        }
    }

    public formatOffsetCorrect (ticks: number): string {
        if (GeneralSettings.TradingDefaults?.ShowOffsetIn === OffsetModeViewEnum.Points) {
            return this.formatPrice(ticks * this.GetPointSize(null));
        } else if (this.AllowUseFractinalTicksForForex() && GeneralSettings.TradingDefaults?.IsTicksFractionalForForex()) {
            return (ticks * 0.1).toFixed(1);
        } else {
            return ticks.toString();
        }
    }

    public roundPrice (price): number {
        const pointSize = this.GetPointSize(price);
        const roundedValue = MathUtils.RoundToIncrement(price, pointSize);
        // #52711
        return MathUtils.RoundDouble(roundedValue, this.Precision);
    }

    public getAmountPrecision (inLotsPrefferedValue = null, account?: Account, productType: ProductType = ProductType.General): number {
        const inLots = inLotsPrefferedValue === null ? GeneralSettings.View?.DisplayQuantityInLots : inLotsPrefferedValue;
        const isSpreadBetOrCryptoCCY = this.InstrType === InstrumentTypes.SPREADBET || this.InstrType === InstrumentTypes.CRYPTO;
        return MathUtils.getPrecision(this.getLotStep(productType, account) * (isSpreadBetOrCryptoCCY || inLots ? 1 : this.LotSize));
    }

    // TODO. Optimize. Use MathUtils.GetValuePrecision()?
    public getPrecision (price: number | undefined = undefined): number {
        if (!price) { return this.getDefaultPrecision(); };

        if (this.VariableTickList.length > 1) {
            const tick = this.FindVariableTick(price);
            const tickSize = tick.PointSize;
            let prec = 0;
            let floor = Math.abs(Math.floor(tickSize) - tickSize);

            while (floor != 0) {
                floor *= 10;
                floor = Math.abs(Math.floor(floor) - floor);
                prec++;
            }
            return prec;
        } else { return this.getDefaultPrecision(); }
    }

    public getDefaultPrecision (): number {
    // TODO.
    // if (this.Precision >= 0)
        return this.Precision;

    // TODO.
    // return getInstrumentTypeSttings().DecimalPlaces;
    }

    public getLotStepPrecision (productType?: ProductType, account?: Account): number {
        const cacheKey = `${productType ?? 'null'}:${account != null ? account.AcctNumber : 'null'}`;

        if (!this.lotStepPrecisionCache.has(cacheKey)) {
            const lotStep = this.getLotStep(productType, account);
            const precision = MathUtils.getPrecision(lotStep);
            this.lotStepPrecisionCache.set(cacheKey, precision);
        }

        return this.lotStepPrecisionCache.get(cacheKey);
    }

    public FindVariableTick (price): any {
        for (let i = this.VariableTickList.length - 1; i >= 0; i--) {
            const tick = this.VariableTickList[i];
            if (tick.CheckPrice(price)) { return tick; }
        }

        console.log('Fail to find VariableTick');
        return null;
    }

    public CreateVariableTick (arrayOfVariableTickObjects): any[] {
        const res: any[] = [];
        const len = arrayOfVariableTickObjects.length;
        for (let i = 0; i < len; i++) { res.push(new VariableTick(arrayOfVariableTickObjects[i])); }
        return res;
    }

    // #region VariableTickSize
    /// <summary>
    /// Округлить цену, до тиксайза соответствующего диапозона
    /// </summary>
    public RoundPriceToNearestPointSize (price): any {
        const tick = this.FindVariableTick(price);
        if (tick) { return MathUtils.RoundToIncrement(price, tick.PointSize); } else { return MathUtils.RoundToIncrement(price, -1); }
    }

    public MinimumPointSize (): number {
    // Тополь сказал, что первый всегда минимальный
        return this.VariableTickList[0].PointSize;
    }

    public MinimumPipsSize (ignoreFractionalTicks = false): number {
        return this.GetPipsSize(this.MinimumPointSize, ignoreFractionalTicks);
    }

    /// <summary>
    /// Сдвигаем цену (basisPrice) на количество тиков (numberOfTicks) в пределах диапозона (tick)
    /// </summary>
    public ProcessTicks (tick, moveType: VariableTickMovemementType, /* ref */ basisPrice, /* ref */numberOfTicks, ignoreFractionalTicks): void {
        const price = basisPrice.value + (moveType === VariableTickMovemementType.Right ? 1 : -1) * numberOfTicks.value * this.GetPipsSize(tick.PointSize, ignoreFractionalTicks);
        if (tick.CheckPrice(price)) {
            numberOfTicks.value = 0;
            basisPrice.value = price;
        } else {
            const border = (moveType === VariableTickMovemementType.Right ? tick.RightBorder : tick.LeftBorder);
            const ticksToBorder = Math.abs(border - basisPrice.value) / this.GetPipsSize(tick.PointSize);
            numberOfTicks.value = numberOfTicks.value - ticksToBorder;
            basisPrice.value = border;
        }
    }

    public CalculatePriceAction (tick, moveType: VariableTickMovemementType, /* ref */ basisPrice, numberOfTicks, ignoreFractionalTicks): void {
    // здесь мы получаем количество оставшихся тиков и новую базовую цену
        this.ProcessTicks(tick, moveType, basisPrice, numberOfTicks, ignoreFractionalTicks);

        // если у на остались тики переходим к следующему интервалу
        if (numberOfTicks.value > 0) {
            const newTick = moveType === VariableTickMovemementType.Left ? this.getPrevInterval(tick) : this.getNextInterval(tick);

            this.CalculatePriceAction(newTick, moveType, basisPrice, numberOfTicks, ignoreFractionalTicks);
        }
    }

    /// <summary>
    /// метод предназначен для нахождения цены для инструмента с переменным тик сайзом
    /// кол-во тиков должено быть передано со знаком, для то чтобы мы понимали в какую стороную его нужно откладывать
    /// </summary>
    public CalculatePrice (basisPrice: number, numberOfTicks: number, ignoreFractionalTicks: boolean = false): any {
        const moveType = numberOfTicks > 0 ? VariableTickMovemementType.Right : VariableTickMovemementType.Left;
        const basisPriceDec = this.RoundPriceToNearestPointSize(basisPrice);
        const tick = this.FindVariableTick(basisPriceDec);

        if (numberOfTicks === 0) { return basisPriceDec; }

        const basisPriceDecObj = { value: basisPriceDec };
        const numberOfTicksObj = { value: Math.abs(numberOfTicks) };

        this.CalculatePriceAction(tick, moveType, basisPriceDecObj, numberOfTicksObj, ignoreFractionalTicks);

        return basisPriceDecObj.value;
    }

    /// <summary>
    /// абсолютный оффест - разница между базовой ценой и ценой SL/TP  ордера
    /// количество тиков должно быть передано со знаком, т.к. для инструмента с переменным тиксайзом оффет зависит от того в какую сторону мы его откладываем
    /// </summary>
    public CalculateOffset (basisPrice, numberOfTicks, ignoreFractionalTicks): number {
        return Math.abs(basisPrice - this.CalculatePrice(basisPrice, numberOfTicks, ignoreFractionalTicks));
    }

    /// <summary>
    /// абсолютный оффест должен быть передан со знаком, чтобы мы понимали, в какую сторону он откладывался
    /// </summary>
    public CalculateTicks (basisPrice, absoluteOffset: number, ignoreFractionalTicks: any = undefined): number // RENAME everywhere TO CalculateTicks
    {
        const basisPriceDec = this.RoundPriceToNearestPointSize(basisPrice);
        const moveType = absoluteOffset > 0 ? VariableTickMovemementType.Right : VariableTickMovemementType.Left;
        const tick = this.FindVariableTick(basisPriceDec);

        const numberOfTicks = { value: 0 };
        const basisPriceObj = { value: basisPriceDec };
        const absoluteOffsetObj = { value: Math.abs(absoluteOffset) };

        this.CalculateOffsetAction(tick, moveType, basisPriceObj, absoluteOffsetObj, /* ref */numberOfTicks, ignoreFractionalTicks);

        return MathUtils.RoundDouble(numberOfTicks.value, 1);
    }

    public CalculateOffsetAction (tick, moveType, basisPrice, absoluteOffset, /* ref */numberOfTicks, ignoreFractionalTicks): void {
    // здесь мы получаем количество новую базовую цену, остаток оффсета и кол-во уже отложенных тиков
        this.ProcessOffsset(tick, moveType, /* ref */basisPrice, /* ref */ absoluteOffset, /* ref */numberOfTicks, ignoreFractionalTicks);

        // если у нас остался оффсет - переходим к следующему интервалу
        if (absoluteOffset.value > 0) {
            const newTick = moveType == VariableTickMovemementType.Left ? this.getPrevInterval(tick) : this.getNextInterval(tick);

            this.CalculateOffsetAction(newTick, moveType, basisPrice, absoluteOffset, /* ref */ numberOfTicks, ignoreFractionalTicks);
        }
    }

    public ProcessOffsset (tick, moveType, /* ref */basisPrice, /* ref */absoluteOffset, /* ref */numberOfTicks, ignoreFractionalTicks): void {
        let tickCount;

        const price = basisPrice.value + (moveType == VariableTickMovemementType.Right ? 1 : -1) * absoluteOffset.value;
        if (tick.CheckPrice(price)) {
            basisPrice.value = price;
            tickCount = absoluteOffset.value / this.GetPipsSize(tick.PointSize, ignoreFractionalTicks);
            absoluteOffset.value = 0;
        } else {
            const border = (moveType == VariableTickMovemementType.Right ? tick.RightBorder : tick.LeftBorder);
            const offset = Math.abs(border - basisPrice.value);
            absoluteOffset.value -= offset;
            basisPrice.value = border;
            tickCount = offset / this.GetPipsSize(tick.PointSize, ignoreFractionalTicks);
        }

        numberOfTicks.value += tickCount;
    }

    public getNextInterval (tick): any {
        let currentIndex = this.VariableTickList.indexOf(tick);
        return currentIndex < this.VariableTickList.length - 1 ? this.VariableTickList[++currentIndex] : this.VariableTickList[this.VariableTickList.length - 1];
    }

    public getPrevInterval (tick): any {
        let currentIndex = this.VariableTickList.indexOf(tick);
        return currentIndex > 0 ? this.VariableTickList[--currentIndex] : this.VariableTickList[0];
    }

    get ExchangeSessionName (): any {
        return this.exchangeSessionName;
    }

    set ExchangeSessionName (value) {
        this.exchangeSessionName = value;
        // захаркодили для кувейта
        this.Level1.CbAuctionStart =
                this.exchangeSessionName === 'CB Auction'
                    ? DateTimeUtils.DateTimeUtcNow()
                    : null;

        this.ExchangeSessionNameUpdated.Raise();
    }

    get PipsSize (): any {
        if (this.pipsSizeSpecified !== null) { return this.pipsSizeSpecified; };

        return this.GetPipsSize(this.PointSize);
    }

    // #endregion VariableTickSize

    public GetPipsSize (pointSize, ignoreFractionalTicks = false): number {
    // +++ http://tp3.pfsoft.lan/entity/23544
        if (GeneralSettings.TradingDefaults?.ShowOffsetIn !== OffsetModeViewEnum.TicksFractionalForForex || ignoreFractionalTicks) { return pointSize; }

        // +++ http://tp.pfsoft.net/Project/QA/Bug/View.aspx?BugID=5873&acid=74FA48A38CBEE17F6445F76C9BEC721B
        if (!this.AllowUseFractinalTicksForForex()) { return pointSize; }

        if ((Math.round((Math.log(pointSize) / Math.log(10)))) % 2 === 0) {
            return pointSize;
        } else {
            return pointSize * 10;
        }
    }

    public GetPointSize (price: number | null = null): any // pass price to get PointSize for variable ticksize ins
    {
        if (this.VariableTickList == null || this.VariableTickList.length === 0) { return this.PointSize; }

        // for First PointSize Case
        if (price === null && this.VariableTickList[0]) { return this.VariableTickList[0].PointSize; }

        const tick = this.FindVariableTick(price);
        return (tick != null) ? tick.PointSize : this.PointSize;
    }

    public getCompanyName (): string {
        let data = '';
        if (this.Description != null && this.Description.length > 0) { data = this.Description; }

        return data;
    }

    public UpdateHigh (high: number, curPrice: number): number {
        if (curPrice > high || isNaN(high)) { return curPrice; };

        return high;
    }

    public UpdateLow (low: number, curPrice: number): number {
        if (curPrice < low || isNaN(low)) { return curPrice; };

        return low;
    }

    public roundProfit (sum: number): number {
        if (this.asset_exp2 !== null) {
            const d = Math.pow(10, this.asset_exp2.Point);
            return Math.round(sum * d) / d;
        }

        if (this.PointSize >= 0.01) {
            const p = (1 / this.PointSize);
            return Math.round(sum * p) / p;
        } else {
            return Math.round(sum);
        }
    }

    // #region NewQuote. TODO.
    public newQuote (msg): void {
        this.OHLCCalculator(msg);

        this.Level1.NewQuote(msg);
        this.InstrumentDayInfo.NewQuote(msg);

        const dsInsArr = this.DataCache.GetInstrumentsByDataSource(this);
        for (let i = 0; i < dsInsArr.length; i++) { dsInsArr[i].DataSourceLevel1.NewQuote(msg); };
        this.DataSourceLevel1.NewQuote(msg);

        switch (msg.Type) {
        case HistoryType.QUOTE_LEVEL1:
            this.processQuote1Message(msg);
            break;
        case HistoryType.QUOTE_INSTRUMENT_DAY_BAR:
            this.processInstrumentDayBarMessage(msg);
            break;
        case HistoryType.QUOTE_TRADES:
            this.processQuoteTradeMessage(msg);
            break;
        }
    }

    public processQuote1Message (quote1Msg): void {
    /* TODO. */
        if (this.InstrumentDayBarMessageUpdateMode) {
            this.SetLastQuote1Message(quote1Msg);
            return;
        }

        const currentSession = /* TODO. */ this.CurrentTradingSession;

        let updateBidLow = false; let updateAskLow = false; // #42918
        let updateBidHigh = false; let updateAskHigh = false; // #42918

        if (quote1Msg.Open !== null) { this.BidOpen = quote1Msg.Open; }

        // <0
        if (isNaN(this.BidOpen) && this.HistoryType !== HistoryType.QUOTE_TRADES) { this.BidOpen = quote1Msg.Bid; }

        if (quote1Msg.High !== null) {
            this.BidHigh = quote1Msg.High;
        } else {
            this.BidHigh = this.UpdateHigh(this.BidHigh, quote1Msg.Bid);
            if (this.BidHigh === quote1Msg.Bid) { updateBidHigh = true; }
        }

        if (quote1Msg.Low !== null) {
            this.BidLow = quote1Msg.Low;
        } else {
            this.BidLow = this.UpdateLow(this.BidLow, quote1Msg.Bid);
            if (this.BidLow === quote1Msg.Bid) { updateBidLow = true; }
        }

        if (quote1Msg.PrevClose !== null) { this.BidPrevClose = quote1Msg.PrevClose; }

        if (quote1Msg.AskOpen !== null) { this.AskOpen = quote1Msg.AskOpen; }
        // < 0
        if (isNaN(this.AskOpen) && this.HistoryType !== HistoryType.QUOTE_TRADES) { this.AskOpen = quote1Msg.Ask; }

        if (quote1Msg.AskHigh !== null) {
            this.AskHigh = quote1Msg.AskHigh;
        } else {
            this.AskHigh = this.UpdateHigh(this.AskHigh, quote1Msg.Ask);
            if (this.AskHigh === quote1Msg.Ask) { updateAskHigh = true; }
        }

        if (quote1Msg.AskLow !== null) {
            this.AskLow = quote1Msg.AskLow;
        } else {
            this.AskLow = this.UpdateLow(this.AskLow, quote1Msg.Ask);
            if (this.AskLow === quote1Msg.Ask) { updateAskLow = true; }
        }

        if (quote1Msg.AskPrevClose !== null) { this.AskPrevClose = quote1Msg.AskPrevClose; }

        if (isNaN(this.AskOpen)) { this.AskOpen = quote1Msg.Ask; }

        if (isNaN(this.BidOpen)) { this.BidOpen = quote1Msg.Bid; }

        /* TODO. Remaining fields */
        if (quote1Msg.BidPreOpen !== null) { this.BidPreOpen = quote1Msg.BidPreOpen; }

        if (quote1Msg.BidHighGeneral !== null) {
            this.BidHighGeneral = quote1Msg.BidHighGeneral;
        } else if (
            !isNaN(quote1Msg.Bid) &&
        (!this.NeedCheckSession || (currentSession && currentSession.DayPeriod !== DayPeriods.MAIN))) {
            this.BidHighGeneral = this.UpdateHigh(this.BidHighGeneral, quote1Msg.Bid);
        }

        if (quote1Msg.BidLowGeneral !== null) {
            this.BidLowGeneral = quote1Msg.BidLowGeneral;
        } else if (
            !isNaN(quote1Msg.Bid) &&
        (!this.NeedCheckSession || (currentSession && currentSession.DayPeriod !== DayPeriods.MAIN))) {
            this.BidLowGeneral = this.UpdateLow(this.BidLowGeneral, quote1Msg.Bid);
        }

        if (quote1Msg.BidPostClose !== null) { this.BidPostClose = quote1Msg.BidPostClose; }

        if (quote1Msg.AskPreOpen !== null) { this.AskPreOpen = quote1Msg.AskPreOpen; }

        if (quote1Msg.AskHighGeneral !== null) {
            this.AskHighGeneral = quote1Msg.AskHighGeneral;
        } else if (
            !isNaN(quote1Msg.Ask) &&
        (!this.NeedCheckSession || (currentSession && currentSession.DayPeriod !== DayPeriods.MAIN))) {
            this.AskHighGeneral = this.UpdateHigh(this.AskHighGeneral, quote1Msg.Ask);
        }

        if (quote1Msg.AskLowGeneral !== null) {
            this.AskLowGeneral = quote1Msg.AskLowGeneral;
        } else if (
            !isNaN(quote1Msg.Ask) &&
        (!this.NeedCheckSession || (currentSession && currentSession.DayPeriod !== DayPeriods.MAIN))) {
            this.AskLowGeneral = this.UpdateLow(this.AskLowGeneral, quote1Msg.Ask);
        }

        if (quote1Msg.AskPostClose !== null) { this.AskPostClose = quote1Msg.AskPostClose; }

        if (quote1Msg.Ticks !== null) {
            this.Ticks = quote1Msg.Ticks;
        } else if (
            this.HistoryType !== HistoryType.QUOTE_TRADES // &&
        // currentSession //&&
        /* currentSession.DayPeriod === DayPeriods.MAIN */) {
            const hType = this.HistoryType;

            if ((hType === HistoryType.QUOTE_ASK && !isNaN(quote1Msg.Ask)) || // Ask price
            (hType === HistoryType.QUOTE_BIDASK_AVG && !isNaN(quote1Msg.Ask) && !isNaN(quote1Msg.Bid)) || // (Bid+Ask)/2
            (hType === HistoryType.QUOTE_LEVEL1 && !isNaN(quote1Msg.Bid)) || // Bid price
            (hType === HistoryType.QUOTE_BIDASK_SUM && !isNaN(quote1Msg.Ask) && !isNaN(quote1Msg.Bid))) // Low & High
            { this.Ticks++; }
        }

        if (quote1Msg.TicksPreMarket !== null) { this.TicksPreMarket = quote1Msg.TicksPreMarket; } else if (this.HistoryType !== HistoryType.QUOTE_TRADES && currentSession && currentSession.DayPeriod === DayPeriods.PRE_OPEN) { this.TicksPreMarket++; }

        if (quote1Msg.TicksPostMarket !== null) { this.TicksPostMarket = quote1Msg.TicksPostMarket; } else if (this.HistoryType !== HistoryType.QUOTE_TRADES && currentSession && currentSession.DayPeriod === DayPeriods.POST_CLOSE) { this.TicksPostMarket++; }

        if (quote1Msg.VolumePreMarket !== null) { this.VolumePreMarket = quote1Msg.VolumePreMarket; }

        if (quote1Msg.VolumePostMarket !== null) { this.VolumePostMarket = quote1Msg.VolumePostMarket; }

        if (quote1Msg.PrevSettlementPrice !== null) { this.PrevSettlementPrice = quote1Msg.PrevSettlementPrice; }

        if (quote1Msg.BidMainClose !== null) { this.BidMainClose = quote1Msg.BidMainClose; }

        if (quote1Msg.AskMainClose !== null) { this.AskMainClose = quote1Msg.AskMainClose; }

        // есть у нас биды и аски. Выбираем, что показывать.
        // для аска -аски, для трейдов - ничего (левел3 обновит), для остальных - биды
        if (this.HistoryType === HistoryType.QUOTE_ASK) {
        // #48594 - сменилась сессия, Open -  был сброшен в -1 в Idpm // теперь в NaN а не -1
            if (quote1Msg.AskOpen !== null || isNaN(this.Open)) { this.Open = this.AskOpen; }
            if (quote1Msg.AskHigh !== null || updateAskHigh) { this.High = this.AskHigh; }
            if (quote1Msg.AskLow !== null || updateAskLow) { this.Low = this.AskLow; }
            if (quote1Msg.AskPrevClose !== null) { this.Close = this.AskPrevClose; }
        } else if (this.HistoryType !== HistoryType.QUOTE_TRADES) {
            if (quote1Msg.Open !== null /* || this.Open < 0 */ || isNaN(this.Open)) { this.Open = this.BidOpen; }
            if (quote1Msg.High !== null || updateBidHigh) { this.High = this.BidHigh; }
            if (quote1Msg.Low !== null || updateBidLow) { this.Low = this.BidLow; }
            if (quote1Msg.PrevClose !== null) { this.Close = this.BidPrevClose; }
        } else {
        // TODO EBY KAK TYT
            if (!isNaN(this.BidOpen)) { this.Open = this.BidOpen; }
            if (!isNaN(this.BidPrevClose)) { this.Close = this.BidPrevClose; }

            // их нельзя переопределять л1, их переопределит трейдмессадж (см. ниже). Доверяю только оригинальному, присланному в мессадже
            if (quote1Msg.High !== null) { this.High = this.BidHigh; }
            if (quote1Msg.Low !== null) { this.Low = this.BidLow; }
        }

        // если сервер прислал вольюмТотал, я обновляю мой теущий суммарный вольюм.
        if (quote1Msg.volumeTotal !== null) { this.Volume = quote1Msg.volumeTotal; }
        // если вольюм не приходит, инкрементим вольюм, но только не для трейда, для трейда инкремент только по квот3 мессаджу из сайза
        else if (this.HistoryType !== HistoryType.QUOTE_TRADES && quote1Msg.QuoteAdditionalInfo) { this.Volume += quote1Msg.QuoteAdditionalInfo.AccumulatedVolume; }

        // если сервер прислал вольюмТотал, я обновляю мой теущий суммарный вольюм.
        if (quote1Msg.TotalVolume !== null) { this.TotalVolume = quote1Msg.TotalVolume; } else if (this.HistoryType !== HistoryType.QUOTE_TRADES && quote1Msg.QuoteAdditionalInfo) { this.TotalVolume += quote1Msg.QuoteAdditionalInfo.AccumulatedVolume; }

        this.SetLastQuote1Message(quote1Msg);
    }

    public processInstrumentDayBarMessage (msg): void {
    // Новая схема, когда приходит несколько InstrumentDayBarMessage'й
    // если инструмент строится по трейдам,
    // то мы берем только значения из мессаджа
    // с InstrumentBarType == QuoteMessage.QUOTE_TRADES
    // если не по трейдам то мы берем значения из мессаджей с асками и бидами,
    // чтобы их можно было получить потом спредированные значения
        if (msg.InstrumentBarType === HistoryType.QUOTE_TRADES && this.HistoryType === HistoryType.QUOTE_TRADES ||
        msg.InstrumentBarType === HistoryType.QUOTE_LEVEL1 && this.HistoryType !== HistoryType.QUOTE_TRADES) {
            if (msg.Open !== null) { this.BidOpen = msg.Open; };
            if (msg.High !== null) { this.BidHigh = msg.High; };
            if (msg.Low !== null) { this.BidLow = msg.Low; };
            if (msg.PreviousClosePrice !== null) { this.BidPrevClose = msg.PreviousClosePrice; };
            if (msg.TodayClosePrice !== null) { this.BidMainClose = msg.TodayClosePrice; };
        }
        // для всех остальных типов инструментов мы берем значения из месаджей
        // где приходят биды и аски, чтобы можно  было их спредировать
        else if (msg.InstrumentBarType === HistoryType.QUOTE_ASK && this.HistoryType !== HistoryType.QUOTE_TRADES) {
            if (msg.Open !== null) { this.AskOpen = msg.Open; };
            if (msg.High !== null) { this.AskHigh = msg.High; };
            if (msg.Low !== null) { this.AskLow = msg.Low; };
            if (msg.PreviousClosePrice !== null) { this.AskPrevClose = msg.PreviousClosePrice; };
            if (msg.TodayClosePrice !== null) { this.AskMainClose = msg.TodayClosePrice; };
        }

        if (msg.InstrumentBarType === HistoryType.QUOTE_ASK && this.HistoryType === HistoryType.QUOTE_ASK) {
            if (msg.Open !== null) { this.Open = this.AskOpen; };
            if (msg.High !== null) { this.High = this.AskHigh; };
            if (msg.Low !== null) { this.Low = this.AskLow; };
            if (msg.PreviousClosePrice !== null) { this.Close = this.AskPrevClose; };
        } else {
            if (msg.Open !== null) { this.Open = this.BidOpen; };
            if (msg.High !== null) { this.High = this.BidHigh; };
            if (msg.Low !== null) { this.Low = this.BidLow; };
            if (msg.PreviousClosePrice !== null) { this.Close = this.BidPrevClose; };
        }

        if (msg.InstrumentBarType === this.HistoryType) {
            if (msg.Ticks !== null) { this.Ticks = msg.Ticks; };

            if (msg.Volume !== null && !isNaN(msg.Volume)) { this.TotalVolume = msg.Volume; };

            if (msg.OpenInterest !== null) { this.OpenInterest = msg.OpenInterest; };

            if (msg.SettlementPrice !== null) { this.SettlementPrice = msg.SettlementPrice; };

            if (msg.PrevSettlementPrice !== null) { this.PrevSettlementPrice = msg.PrevSettlementPrice; };

            if (msg.FiftyTwoWeekHighPrice !== null) { this.FiftyTwoWeekHighPrice = msg.FiftyTwoWeekHighPrice; };

            if (msg.FiftyTwoWeekLowPrice !== null) { this.FiftyTwoWeekLowPrice = msg.FiftyTwoWeekLowPrice; };

            if (msg.High13Week !== null) { this.High13Week = msg.High13Week; };

            if (msg.Low13Week !== null) { this.Low13Week = msg.Low13Week; };

            if (msg.High26Week !== null) { this.High26Week = msg.High26Week; };

            if (msg.Low26Week !== null) { this.Low26Week = msg.Low26Week; };
        }

        if (msg.Delay !== null) { this.QuoteDelay = msg.Delay; };
    }

    public processQuoteTradeMessage (message): void {
    // #61359 обновление Opent Interest приоритетнее,
    // чем флаг instrumentDayBarMessageUpdateMode
        if (message.OpenInterest !== null) { this.OpenInterest = message.OpenInterest; };

        if (this.InstrumentDayBarMessageUpdateMode) { return; };

        if (this.HistoryType !== HistoryType.QUOTE_TRADES) { return; };

        // let currentSession = Instrument.FindSession(this, message.cTime);
        // TODO. Ugly. Optimization?
        const currentSession = message.TradingSession;

        // всегда увеличиваю объем, но только для трейдов.
        if (this.DataCache.IgnoreTradeSession // ||
        /* currentSession && currentSession.DayPeriod === DayPeriods.MAIN */) {
            this.Ticks++;
            this.Volume += message.Size;
        } else if (currentSession && currentSession.DayPeriod === DayPeriods.PRE_OPEN) {
            this.TicksPreMarket++;
            this.VolumePreMarket += message.Size;
        } else if (currentSession && currentSession.DayPeriod === DayPeriods.POST_CLOSE) {
            this.TicksPostMarket++;
            this.VolumePostMarket += message.Size;
        }

        // #55011: Сервер прислыает изначально NaN, чтобы клиент локализировал в N/A,
        // но по факту счетчик на клиенте должен начинатся с 0.
        // Если сейчас totalVolume = 0, то объемом будет объем из котировки
        if (isNaN(this.TotalVolume)) { this.TotalVolume = message.Size; } else { this.TotalVolume += message.Size; }

        // if (!this.NeedCheckSession //||
        //     /*currentSession && currentSession.DayPeriod === DayPeriods.MAIN*/)
        // {
        this.High = this.UpdateHigh(this.High, message.Price);
        this.Low = this.UpdateLow(this.Low, message.Price);

        if (isNaN(this.Open)) { this.Open = message.Price; };
    // }
    }

    public SetLastQuote1Message (msg): void {
        if (msg.IsValid) {
            if (!this.FirstQuote) { this.FirstQuote = msg; }

            this.LastQuote = msg;
            this.LastInvalidQuote = null;
        } else {
            this.LastInvalidQuote = msg;
            this.NewQuote.Raise(msg);
        }
    }

    public GetLastQuote (valid: QuoteValid): any {
        switch (valid) {
        case QuoteValid.Last:
            return this.LastInvalidQuote || this.LastQuote;

        case QuoteValid.Valid:
            return this.LastQuote;

        case QuoteValid.Invalid:
            return this.LastInvalidQuote;
        }

        return null;
    }

    public OHLCCalculator (message): void {
        if (message.Type === HistoryType.QUOTE_LEVEL1) {
            if (this.InstrumentDayBarMessageUpdateMode) { return; }

            if (message.High != null) { this.OHLC_BidHigh = message.High; } else { this.OHLC_BidHigh = this.UpdateHigh(this.OHLC_BidHigh, message.Bid); }

            if (message.Low != null) { this.OHLC_BidLow = message.Low; } else { this.OHLC_BidLow = this.UpdateLow(this.OHLC_BidLow, message.Bid); }

            if (message.AskHigh != null) { this.OHLC_AskHigh = message.AskHigh; } else { this.OHLC_AskHigh = this.UpdateHigh(this.OHLC_AskHigh, message.Ask); }

            if (message.AskLow != null) { this.OHLC_AskLow = message.AskLow; } else { this.OHLC_AskLow = this.UpdateLow(this.OHLC_AskLow, message.Ask); }
        } else if (message.Type === HistoryType.QUOTE_TRADES) {
            if (this.InstrumentDayBarMessageUpdateMode) { return; }

            this.OHLC_TradeHigh = this.UpdateHigh(this.OHLC_TradeHigh, message.Price);
            this.OHLC_TradeLow = this.UpdateLow(this.OHLC_TradeLow, message.Price);
        } else if (message.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR) // #41123
        {
        // #region Cтарая схема, когда приходит один InstrumentDayBarMessage
        // обновляем данные только если тип заданный в мессадже совпадает с типом инструмента (или вообще не пришел)
            if (!message.InstrumentBarType /* || (int)message.InstrumentBarType == FHistoryType */) {
                if (message.High != null) { this.OHLC_TradeHigh = this.OHLC_BidHigh = message.High; }

                if (message.Low != null) { this.OHLC_TradeLow = this.OHLC_BidLow = message.Low; }
            }
            // #endregion Cтарая схема, когда приходит один InstrumentDayBarMessage

            // #region Новая схема, когда приходит несколько InstrumentDayBarMessage'й
            else // message.InstrumentBarType.HasValue
            {
                if (message.InstrumentBarType == HistoryType.QUOTE_TRADES) {
                    if (message.High != null) { this.OHLC_TradeHigh = message.High; }

                    if (message.Low != null) { this.OHLC_TradeLow = message.Low; }
                } else if (message.InstrumentBarType == HistoryType.QUOTE_ASK) {
                    if (message.High != null) { this.OHLC_AskHigh = message.High; }

                    if (message.Low != null) { this.OHLC_AskLow = message.Low; }
                } else // bid
                {
                    if (message.High != null) { this.OHLC_BidHigh = message.High; }

                    if (message.Low != null) { this.OHLC_BidLow = message.Low; }
                }
            }
        }
    }

    public GetHigh (type): number {
        switch (type) {
        case HistoryType.ASK:
            return this.OHLC_AskHigh;
        case HistoryType.LAST:
            return this.OHLC_TradeHigh;
        case HistoryType.BID:
        default:
            return this.OHLC_BidHigh;
        }
    }

    public GetLow (type): number {
        switch (type) {
        case HistoryType.ASK:
            return this.OHLC_AskLow;
        case HistoryType.LAST:
            return this.OHLC_TradeLow;
        case HistoryType.BID:
        default:
            return this.OHLC_BidLow;
        }
    }

    public GetInterTraderButtonStatus (forTradeButton): any // #87891
    {
        const orderType = forTradeButton ? OrderType.Market : OrderType.Limit;
        const tradingStatus = this.TradingMode;
        const fullyOpen = tradingStatus === TradingMode.FullyOpen;
        const fullyClosed = tradingStatus === TradingMode.FullyClosed;
        const tradingHalt = tradingStatus === TradingMode.TradingHalt;
        const allowedBySession = this.Check_Session_OrderType(orderType);

        const result = {
            status: InterTraderBtnStatus.Open,
            tooltip: forTradeButton ? 'panel.watchlist.Trade.tt' : 'panel.watchlist.Order.tt'
        };

        if (fullyOpen && allowedBySession) { return result; };

        if ((fullyOpen && !allowedBySession) || this.BlockTradingBySession || fullyClosed) {
            result.status = InterTraderBtnStatus.Closed;
            result.tooltip = 'InstrumentDetailsPanel.Closed';
            return result;
        }

        if (tradingHalt) {
        // let quote = this.LastQuote                                                                        //закомментировал в связи с https://tp.traderevolution.com/entity/88592
        // let minutesForCallStatus = this.InstrumentAdditionalInfo['Time for Call status']
            const callNumber = this.InstrumentAdditionalInfo['Call status_telephone number'];

            result.status = InterTraderBtnStatus.Call;
            result.tooltip = callNumber;

            // if (quote && minutesForCallStatus !== undefined)                                                 //закомментировал в связи с https://tp.traderevolution.com/entity/88592
            // {
            //     let minutesAfterLastQuote = (Date.now() - quote.Time.getTime()) / TimeSpan.TicksPerMinute

        //     if (minutesAfterLastQuote > parseInt(minutesForCallStatus))
        //     {
        //         result.status = InterTraderBtnStatus.Call
        //         result.tooltip = callNumber
        //         return result
        //     }
        // }
        }

        return result;
    }

    // #endregion

    public static TickValue (instrument: Instrument, lastPrice, openPrice, signOper): number {
        if (openPrice === 0 || isNaN(lastPrice) || isNaN(openPrice)) { return 0; }

        let ticks = 0;
        try {
            const inst = instrument;
            const offset = lastPrice - openPrice;

            if (inst != null && inst.VariableTickList.length > 1) {
                if (instrument != null && instrument.PointSize != 0) {
                    ticks = instrument.CalculateTicks(openPrice, offset, undefined) * signOper * (offset > 0 ? 1 : -1);
                } else {
                    ticks = (lastPrice - openPrice) * signOper;
                }
            } else {
            // Пока для оптимизации здесь
                ticks = InstrumentUtils.Get_Ticks_From_RealOffsetPrice(instrument, offset * signOper);
            }

            if (instrument?.AllowUseFractinalTicksForForex()) {
                const presision = instrument.getPrecision();
                if ((presision && presision % 2) !== 0 && GeneralSettings.TradingDefaults?.ShowOffsetIn === OffsetModeViewEnum.TicksFractionalForForex) // #38563 - UseFractinalTicksForForex
                {
                    ticks = MathUtils.Round(ticks, 1);
                } else {
                    ticks = MathUtils.Round(ticks, 0);
                }
            } else if (instrument?.isOptionSymbol) {
                ticks = MathUtils.Round(ticks, 3);
            } else {
                ticks = MathUtils.Round(ticks, 0);
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        // Utils.log(ex);
        }

        return ticks;
    }

    public getLotSize (): number {
        return this.LotSize;
    }

    public isProductTypeVisible (account?: Account): boolean {
        const riskPlanSettings = this.getRiskPlanSettingsForAccount(account);

        if (riskPlanSettings.availableProductTypes.length > 0) {
            return !riskPlanSettings.cache.hasOwnProperty(ProductType.General);
        } else {
            return false;
        }
    }

    public getRiskPlanSettingsForAccount (account: Account): RiskPlanSettings {
        let result = this.RiskSettings.RiskPlanSettings;
        if (!account?.RiskPlan) { return result; };

        result = account.RiskPlan.GetRisksForInstrument(this.GetInteriorID()) || result;

        return result;
    }

    public getRiskPlanItemForAccountAndProductType (account: Account, productType): RiskPlanItem | null {
        const riskPlanSettings = this.getRiskPlanSettingsForAccount(account);

        if (!isNullOrUndefined(riskPlanSettings)) {
            return riskPlanSettings.get(productType);
        }

        return null;
    }

    public isHedgingNettingType (): boolean {
        return this.TradingPositionType === TradingPositionType.MultiPosition;
    }

    public isMultiPositionPerSide (): boolean {
        return this.TradingPositionType === TradingPositionType.MultiPositionPerSide;
    }

    public isLeverageVisible (account: Account, productType): boolean {
        const riskPlanItem = this.getRiskPlanItemForAccountAndProductType(account, productType);
        const isHedgingNettingType = this.isHedgingNettingType();
        const visible = riskPlanItem ? riskPlanItem.IsLeverageVisible() && isHedgingNettingType : false;

        return visible;
    }

    public getLeverages (account: Account, productType: ProductType): number[] {
        const riskPlanItem = this.getRiskPlanItemForAccountAndProductType(account, productType);
        const result = !isNullOrUndefined(riskPlanItem) ? riskPlanItem.GetLeverages() : [];

        return result;
    }

    // TODO. Refactor. Optimize.
    // formatPrice с большей точностью(+2)
    public formatPricePrecision (price: number, withRounding: boolean = false, useVariableTickSize: boolean = true) {
        if (!isFinite(price) || isNaN(price)) { price = 0; };

        // если настройка включена используем стандартный вывод
        if (GeneralSettings.View?.RoundedAverageOpenPrice) {
            return this.formatPrice(price, useVariableTickSize, withRounding);
        } else {
            return PriceFormatter.formatPrice(price, InstrumentUtils.MAX_PRECISION_ON_SERVER, withRounding);
        }

        // let precInstr = this.getPrecision()
        // let precNeed = precInstr

        // // #46163 - отображаем цену с такой же точностью как и на сервере
        // for (let i = precInstr; i < Instrument.MAX_PRECISION_ON_SERVER; i++)
        // {
        //     let dVal = price * Math.pow(10, precNeed) + MathUtils.MATH_ROUND_EPSILON
        //     let fVal = price * Math.pow(10, Instrument.MAX_PRECISION_ON_SERVER) + MathUtils.MATH_ROUND_EPSILON
        //     fVal = Math.round(fVal) / Math.pow(10, Instrument.MAX_PRECISION_ON_SERVER - precNeed)
        //     if (fVal - Math.trunc(dVal) > 0)
        //         precNeed++
        //     else
        //         break
        // }

    // return Instrument.formatPrice(price, precNeed)
    }

    public GetTradeSessionStatusId (): number {
        let tradeSessionStatusId = this.TradeSessionStatusId;
        const forwardBaseInstrument = this.ForwardBaseInstrument;

        if (tradeSessionStatusId === -1 && forwardBaseInstrument) {
            tradeSessionStatusId = forwardBaseInstrument.TradeSessionStatusId;
        };

        return tradeSessionStatusId;
    }

    public AllowFilterBarsBySession (period): boolean {
        if (period < Periods.MIN || period >= Periods.DAY) { return false; }

        return this.UseSessionPeriodForIntradayChart || false;
    }

    public FindSession (dateTime): TradingSession {
        const tradingSessionsList = this.GetTradingSessionsList();

        if (tradingSessionsList.length === 0) { return null; }

        // сначала проверяем текущую сессию
        // over99% котировок принадлежат ей - для производительности
        const current = this.CurrentTradingSession;

        // TODO. Ugly.
        // fix: Часто попадают невалидные котировки
        if (!dateTime || dateTime.getUTCFullYear() <= 1970) { return current; }

        if (current?.CheckSession?.(dateTime)) { return current; }

        const tsArr = tradingSessionsList;
        for (let i = 0, len = tsArr.length; i < len; i++) {
            const session = tsArr[i];
            // сессия меняется сервером только,
            // я буду использовать собственный механизм определения только для чарта и т.п.
            // CurrentTradingSession пусть как и раньше меняется сервером
            if (session.CheckSession?.(dateTime)) { return session; }
        }

        return null;
    }

    public static GetAllowedOperations (instrument: Instrument): number {
        let allowedOperations = SessionOperations.None;

        const tradeSessionStatusId = instrument.GetTradeSessionStatusId();

        if (tradeSessionStatusId !== -1) {
            const tradeSessionStatus = instrument.DataCache.GetTradeSessionStatus(tradeSessionStatusId);
            if (tradeSessionStatus) { allowedOperations = tradeSessionStatus.AllowedOperations; };
        }

        return allowedOperations;
    }

    public static GetCurrentTradingSession (currentInstrument: Instrument): any {
        const tradingSession = currentInstrument.CurrentTradingSession;
        //    //если есть этот рул, то берем данные из main сессии
        //    if (BaseApplication.App.MultiDataCache.getRuleFloatValue(RulesSet.FUNCTION_IGNORE_TRADE_SESSION_TIME, null) == 1d)
        //    {
        //        foreach (TradingSession session in currentInstrument.TradingSessionsList)
        //            if (session.IsMain)
        //    {
        //                tradingSession = session;
        //        break;
        //    }
        // }

        return tradingSession;
    }

    public static IsDecreaseOnlyPositionCount (instrument: Instrument): boolean {
        let decreaseOnlyPositionCount = false;

        const tradeSessionStatusId = instrument.GetTradeSessionStatusId();

        if (tradeSessionStatusId !== -1) {
            const tradeSessionStatus = instrument.DataCache.GetTradeSessionStatus(tradeSessionStatusId);
            if (tradeSessionStatus) { decreaseOnlyPositionCount = tradeSessionStatus.IsDecreaseOnlyPositionCount; };
        } else {
            const tradingSession = Instrument.GetCurrentTradingSession(instrument);
            if (tradingSession) { decreaseOnlyPositionCount = tradingSession.DecreaseOnlyPositionCount; };
        }

        return decreaseOnlyPositionCount;
    }

    public static CalculatePrecision (pointSize, variableTickList): number {
        return MathUtils.GetDecimalPlaces(
            variableTickList?.length
                ? variableTickList[0].PointSize
                : pointSize,
            20) ?? 0;
    }

    public CreateInstrumentMessage (): DirectInstrumentMessage {
    // if (_message != null)
    //    return (InstrumentMessage)_message.CreateCopy(); //так делать нельзя, но также нельзя отдавать ссылку, иначе по сслыке измениться закешированное значение

        const mess = new DirectInstrumentMessage();
        mess.Exp1 = this.Exp1;
        mess.Exp2 = this.Exp2;
        mess.AssetName = this.AssetName;

        mess.Id = this.Id;

        mess.Descr = this.Description;
        mess.UnderlierDescription = this.UnderlierDescription;
        mess.RoutesSupported = this.RoutesSupported;

        mess.Name = InstrumentUtils.RemoveRouteName(this.FullName);
        mess.LotSize = this.LotSize;
        mess.ContractSize = this.ContractSize;
        mess.TypeId = this.TypeId;
        mess.PointSize = this.PointSize;
        mess.Precision = this.Precision ?? -1;
        mess.PipsSizeSpecified = this.pipsSizeSpecified;

        // TODO типа
        // RiskSettings.InitMessage(mess);

        mess.ShortSwapInfo = this.ShortSwapInfo;
        mess.SwapBuy = this.SwapBuy;
        mess.SwapSell = this.SwapSell;

        mess.TradingMode = this.TradingMode;
        mess.TradingPositionType = this.TradingPositionType;
        mess.HistoryType = this.HistoryType;
        mess.DefaultChartHistoryType = this.DefaultChartHistoryType;
        mess.TradableRoutes = this.TradableRoutes;

        mess.SwapType = this.SwapType;

        mess.TradingSessionsList = this.TradingSessionsList;
        mess.InstrDateSettingsList = this.InstrDateSettingsList;
        mess.BlockTradingBySession = this.BlockTradingBySession;

        mess.LotStep = this.lotStep;
        mess.FuturesTickCoast = this.FuturesTickCoast;

        mess.DeliveryMethod = this.DeliveryMethod;
        mess.TradingBalance = this.TradingBalance;
        mess.ExchangeId = this.ExchangeId;
        mess.TradingExchange = this.TradingExchange;
        mess.MarketDataExchange = this.MarketDataExchange;
        mess.CountryId = this.CountryId;
        mess.HightLimit = this.HightLimit;
        mess.LowLimit = this.LowLimit;
        mess.IsHighLimitFrontEndValidationEnabled = this.IsHighLimitFrontEndValidationEnabled;
        mess.IsLowLimitFrontEndValidationEnabled = this.IsLowLimitFrontEndValidationEnabled;
        mess.PriceLimitMeasure = this.PriceLimitMeasure;
        mess.SeriesGroupName = this.SeriesGroupName;

        mess.MinimalLot = this.MinLot;
        mess.MaxLot = this.MaxLot;

        mess.VariableTickList = this.VariableTickList;
        mess.AdditionalFields = this.AdditionalFields;

        mess.InstrType = this.InstrType;
        mess.LockedBy = this.Locked;
        mess.TradingSessionsId = this.TradingSessionsId;

        mess.ContractMultiplier = this.ContractMultiplier;

        mess.ExerciseStyle = this.ExerciseStyle;

        mess.OptionTradingStyle = this.OptionTradingStyle;

        mess.InstrumentAdditionalInfo = this.InstrumentAdditionalInfo; // hsa: копировать не по ссылке? используем для сохранения в БД Алго

        mess.VolumeHistoryMode = this.VolumeHistoryMode;

        mess.QuotingType = this.QuotingType;

        mess.TradeSessionStatusId = this.TradeSessionStatusId;
        mess.ExchangeSessionName = this.ExchangeSessionName;

        // #region Bonds
        mess.LastTradeDate = this.LastTradeDate;
        mess.MaturityDate = this.MaturityDate;
        mess.FaceValue = this.FaceValue;
        mess.CouponRate = this.CouponRate;
        mess.CouponCycle = this.CouponCycle;
        mess.YieldRate = this.YieldRate;
        mess.AccruedInterest = this.AccruedInterest;
        mess.IssueDate = this.IssueDate;
        mess.DaysPerMonth = this.DaysPerMonth;
        mess.DaysPerYear = this.DaysPerYear;
        mess.InstrumentTradableID = this.InstrumentTradableID;
        mess.curPriceSource = this.CurPriceSource;

        if (this.UnderlierDescription || this.ForwardBaseInstrument) {
            mess.ForwardBaseInstrument = this.UnderlierDescription || this.ForwardBaseInstrument.UnderlierDescription;
        }

        if (this.Limits) {
            mess.HightLimit = this.Limits.HightLimit;
            mess.LowLimit = this.Limits.LowLimit;
            mess.PriceLimitMeasure = this.Limits.PriceLimitMeasure;
            mess.IsHighLimitFrontEndValidationEnabled = this.Limits.IsHighLimitFrontEndValidationEnabled;
            mess.IsLowLimitFrontEndValidationEnabled = this.Limits.IsLowLimitFrontEndValidationEnabled;
        }

        if (this.FLanguageAliases !== null) {
            mess.LanguageAliases = Instrument.CloneLangAliases(this.FLanguageAliases);
        }

        return mess;
    }

    public IsAllowShortPositions (productType): boolean // #91969 - документация
    {
        if (!this.RiskSettings.IsShortable(productType)) { return false; };

        if (this.DailyAvailableForShort === null || isNaN(this.DailyAvailableForShort)) { return true; };

        return this.DailyAvailableForShort > 0;
    }

    public CalcPriceOnTrade (): boolean {
        if (this.CurPriceSource === CurPriceSource.Level1)
        // всегда л1
        { return false; }

        if (this.CurPriceSource === CurPriceSource.Trades)
        // всегда трейд
        { return true; }

        if (this.CurPriceSource === CurPriceSource.Level1MainOnly) {
            const currSession = this.CurrentTradingSession;// com.pfsoft.proftrading.cache.Instrument.FindSession(instrument, quoteMessage.cTime);
            return (currSession === null || !currSession.IsMain());
        }

        return false;
    }

    // TODO. Optimize allowed trading session orders.
    public IsAllowOrderType (orderType): any {
        const allowedOrderTypes = Instrument.GetAllowedOrderTypes(this);
        return allowedOrderTypes.includes(orderType);
    }

    public static GetAllowedOrderTypes (instrument: Instrument): any {
        let result = [];

        const tradeSessionStatusId = instrument.GetTradeSessionStatusId();

        if (tradeSessionStatusId !== -1) {
            const sessionStatus = instrument.DataCache.GetTradeSessionStatus(tradeSessionStatusId);
            if (!isNullOrUndefined(sessionStatus)) {
                result = sessionStatus.AllowedOrderTypes;
            }
        }

        return result;
    }

    public Check_Session_OrderType (lOrderType): boolean {
        const allowedOrderTypes = Instrument.GetAllowedOrderTypes(this);

        return allowedOrderTypes.Contains(lOrderType) || lOrderType == OrderType.TrailingStop && this.DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_DONT_USE_ROUTE_SETTING_FOR_CLOSE_TS_ORDER) ||
        (lOrderType == OrderType.OCO && allowedOrderTypes.Contains(OrderType.Limit) && allowedOrderTypes.Contains(OrderType[GeneralSettings.TradingDefaults?.UseStopLimitInsteadStop ? 'StopLimit' : 'Stop']));
    }

    public static GetAssetNameCorrect (instrument: Instrument, isBuy: boolean): any {
        if (instrument == null) { return null; }

        if (instrument.TradingBalance == InstrumentTradingBalance.SETTLEMENT_IMMEDIATE) {
            return isBuy ? instrument.Exp2 : instrument.Exp1;
        } else {
            return instrument.Exp2;
        }
    }

    public static IsEqualInstrument (i1: Instrument | null, i2: Instrument | null): boolean {
        if (isNullOrUndefined(i1) || isNullOrUndefined(i2)) {
            return false;
        }

        if (i1 === i2) {
            return true;
        }

        if (i1.isHideRouteMode && i2.isHideRouteMode && i1.ShortName === i2.ShortName) {
            return true;
        }

        if (!isNullOrUndefined(i1.SourceName) &&
            !isNullOrUndefined(i2.SourceName) &&
            !isNullOrUndefined(i1.ExpDate) &&
            !isNullOrUndefined(i2.ExpDate) &&
            i1.SourceName === i2.SourceName &&
            i1.ExpDate.getTime() === i2.ExpDate.getTime()) {
            return true;
        }

        return false;
    }

    public static IsEqualOptionInstruments (i1: Instrument, i2: Instrument): boolean {
        if (isNullOrUndefined(i1) || isNullOrUndefined(i2)) {
            return false;
        }

        if (i1 === i2) {
            return true;
        }

        if (i1.Id !== i2.Id || i1.Route !== i2.Route) {
            return false;
        }

        if (i1.ForwardBaseInstrument !== i2.ForwardBaseInstrument) {
            return false;
        }

        return true;
    }

    public static GetMonthsCountByCouponCycle (couponCycle): number {
        switch (couponCycle) {
        case CouponCycle.ThreeMonth:
            return 3;
        case CouponCycle.SixMonth:
            return 6;
        case CouponCycle.Year:
            return 12;
        case CouponCycle.OneMonth:
        default:
            return 1;
        }
    }

    public static FormatCouponCycle (cycle): string {
        switch (cycle) {
        case CouponCycle.OneMonth:
            return Resources.getResource('bonds.couponCycle.1m');
        case CouponCycle.ThreeMonth:
            return Resources.getResource('bonds.couponCycle.3m');
        case CouponCycle.SixMonth:
            return Resources.getResource('bonds.couponCycle.6m');
        case CouponCycle.Year:
            return Resources.getResource('bonds.couponCycle.1y');
        default:
            return '';
        }
    }

    public FindPaymentDates (issueDate, couponCycle): void {
        if (!issueDate || !isValidNumber(couponCycle)) { return; }

        const utcNow = DateTimeUtils.DateTimeUtcNow();
        const monthCount = Instrument.GetMonthsCountByCouponCycle(couponCycle);

        const AddMonth = (date, months): Date => {
            const d = new Date(date);
            d.setMonth(d.getMonth() + months);
            return d;
        };

        if (+issueDate > +utcNow) {
            this.PrevPaymentDate = DateTimeUtils._ZeroTime;
            this.NextPaymentDate = issueDate;
        } else if (+issueDate == +utcNow) {
            const date = AddMonth(issueDate, monthCount);
            this.PrevPaymentDate = issueDate;
            this.NextPaymentDate = date;
        } else {
            let date = AddMonth(issueDate, monthCount);
            let prevDate = new Date(issueDate);

            while (date < utcNow) {
                prevDate = date;
                date = AddMonth(date, monthCount);
            }

            this.PrevPaymentDate = prevDate;
            this.NextPaymentDate = AddMonth(prevDate, monthCount);
        }
    }

    public updateDelayAndCanGetSnapshot (delay: number, canGetSnapshot: boolean, errorCode: boolean): void {
        this.QuoteDelay = delay;
        this.QuoteCanGetSnapshot = canGetSnapshot;
        this.HasLvl1SubscriptionErrorCode = errorCode;

        this.RiskSettingsUpdated.Raise();
    }

    public GetDelayedIconTooltip (): string {
        let result = '';
        const delayValue = this.GetDelay();
        const delayedTooltipLocKey = 'TerceraInstrumentLookup.DelayedIco.tt';
        const specialTooltipLocKey = 'TerceraInstrumentLookup.DelayedIco.NotPluralDelay.tt'; // ключ с текстом для случая единственного числа слова "минут" ~ etc. Delayed for 1 minute но Delayed for 5 minutes (для рус.локализации поскольку есть еще доп. вариант окончания когда число оканчивается цифрами 2,3,4 минутЬІ, не добавлял доп. ключа, аналогично десктопу сократим минут до мин. - универсально для всех кейсов, кроме англ.и русс. переводов пока не добавлялось)

        if (delayValue > 0) {
            const needCommonLocKey = delayValue > 1 || !Resources.IsResourcePresent(specialTooltipLocKey); // для правильного окончания в случае задержки в одну минуту (а не Delayed for 1 minutes) (в русском еще сложнее - разн. окончания когда число заканчивается на 1 (минутУ), 2-3-4 (минутЬІ), 5-9,0 (минут) - потому пока аналогично десктопу в русс. один ключ локализации для всех случаев, в котором используется сокращение "мин." )
            const tooltipLocKey = needCommonLocKey ? delayedTooltipLocKey : specialTooltipLocKey;

            result = Resources.getResource(tooltipLocKey).replace('{0}', delayValue.toString());
        }

        return result;
    }

    public GetDataSourceTradableId (): number | null | undefined {
        return this.DataSourceTradableId;
    }

    public GetDataSourceRouteId (): number | null | undefined {
        return this.DataSourceRouteId;
    }

    public IsInfoRouteForDataSourceAvailable (): boolean // #102769
    {
        if (this.DataSourceTradableId === -1 && this.DataSourceRouteId === -1 && !this.RoutesSupported.length) {
            return false;
        }

        return true;
    }

    public IsDataSourceEnable (): boolean {
        if (!this.IsInfoRouteForDataSourceAvailable()) { return false; }

        return !!(this.DataSourceTradableId && this.DataSourceRouteId);
    }

    public GetDelay (): number {
        return this.QuoteDelay;
    }

    public HasDelay (): boolean {
        return this.QuoteDelay > 0;
    }

    public CanGetSnapshot (): boolean {
        return this.QuoteCanGetSnapshot;
    }

    public IsAmericanExerciseStyle (): boolean {
        return this.ExerciseStyle === ExerciseStyle.AMERICAN;
    }

    public GetLocalizedExerciseStyle (): any {
        let localKey: any = null;

        switch (this.ExerciseStyle) {
        case ExerciseStyle.EUROPEAN:
            localKey = 'Instrument.ExerciseStyle.EUROPEAN';
            break;
        case ExerciseStyle.AMERICAN:
            localKey = 'Instrument.ExerciseStyle.AMERICAN';
            break;
        }

        return Resources.getResource(localKey);
    }

    public GetAnyTradingSession (): any {
        return this.CurrentTradingSession || this.GetZeroTradingSession();
    }

    public GetZeroTradingSession (): TradingSession | null {
        return (this.TradingSessionsList.length > 0) ? this.TradingSessionsList[0] : null;
    }

    public GetTradingSessionsList (): TradingSession[] // return TradingSessionsList сonsidering special in shortened day holiday #106782
    {
        let result = this.TradingSessionsList;

        const holiday = this.GetTodayHoliday();
        if (holiday) {
            const list = holiday.GetTradingSessionsList();
            if (list?.length) {
                result = list;
            }
        }

        return result;
    }

    public GetNextHoliday (): HolidayDescription // return the HolidayDescription (or null) of the next holiday, ignoring today's
    {
        const ts = this.GetZeroTradingSession();
        return ts ? ts.GetNextHoliday() : null;
    }

    public GetTodayHoliday (): HolidayDescription // return the HolidayDescription if today is a holiday, otherwise -> null
    {
        const ts = this.GetAnyTradingSession();
        return ts ? ts.GetTodayHoliday() : null;
    }

    public IsShowVolume (): boolean {
        return this.HistoryType === HistoryType.QUOTE_TRADES;
    }

    public GetInteriorID (continuousContractSaveMode: any = undefined): string {
        if (isValidString(this._cachedInteriorID)) {
            return this._cachedInteriorID;
        }

        let resultSTR = '';

        if (this.InstrumentSpecificType === InstrumentSpecificType.ContinuousContract) {
            let strID = this.InstrumentTradableID + Instrument.CONTINUOUS_CONTRACT_POSTFIX + InstrumentUtils.SEPARATOR + this.Route;
            if (continuousContractSaveMode || GlobalFlags.InstrumentSaveMode) {
                resultSTR = strID += '|' + this.Id;
            } else {
                resultSTR = strID;
            }
        } else {
            resultSTR = this.InstrumentTradableID + InstrumentUtils.SEPARATOR + this.Route;
        }

        this._cachedInteriorID = resultSTR;
        return this._cachedInteriorID;
    }

    public static IsWorkingInstrument (insRef): boolean {
        if (!insRef || insRef.IsEmpty) return false;

        return true;
    }

    public GetExtInsType (): string {
        let value = '';
        if (this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.INSTRUMENT_TYPE]) {
            value = this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.INSTRUMENT_TYPE];
        }

        return value;
    }

    public GetExtSymbol (): string {
        let value = '';
        if (this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.SYMBOL]) {
            value = this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.SYMBOL];
        }

        return value;
    }

    public GetExtToken (): string {
        let value = '';
        if (this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.TOKEN]) {
            value = this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.TOKEN];
        }

        return value;
    }

    public GetExtMarketType (): string {
        let value = '';
        if (this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.MARKET_TYPE]) {
            value = this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.MARKET_TYPE];
        }

        return value;
    }

    public GetExtSeries (): string {
        let value = '';
        if (this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.SERIES]) {
            value = this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.SERIES];
        }

        return value;
    }

    public GetExtTradingUnit (): string {
        let value = '';
        if (this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.TRADINGUNIT]) {
            value = this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.TRADINGUNIT];
        }

        return value;
    }

    public GetExtPriceQuoteUnit (): string {
        let value = '';
        if (this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.PRICEQUOTEUNIT]) {
            value = this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.PRICEQUOTEUNIT];
        }

        return value;
    }

    public GetExtDeliveryUnit (): string {
        let value = '';
        if (this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.DELIVERYUNIT]) {
            value = this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.DELIVERYUNIT];
        }

        return value;
    }

    public GetExtLotSize (): string {
        let value = '';
        if (this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.LOTSIZE]) {
            value = this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.LOTSIZE];
        }

        return value;
    }

    public GetExtTenderPeriodStartDate (): string {
        let value = '';
        if (this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.TENDERPERIODSTARTDATE]) {
            value = this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.TENDERPERIODSTARTDATE];
        }

        return value;
    }

    public GetExtTenderPeriodEndDate (): string {
        let value = '';
        if (this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.TENDERPERIODENDDATE]) {
            value = this.InstrumentAdditionalInfo[EXT_INSTRUMENT_MESSAGE.TENDERPERIODENDDATE];
        }

        return value;
    }

    public GetExtSMFlags (): any // also receive it using extended field groups, storaging in InstrumentAdditionalInfo #114474
    {
        const EXT = EXT_INSTRUMENT_MESSAGE;
        const possibleFlags = [EXT.FLAG_GSM, EXT.FLAG_ESM, EXT.FLAG_LONG_ASM, EXT.FLAG_SHORT_ASM];

        const result = {};
        for (let i = 0; i < possibleFlags.length; i++) {
            const flag = possibleFlags[i];
            const flagValue = this.InstrumentAdditionalInfo[flag];
            if (Instrument.ShownSMFlagValue(flagValue, flag)) { result[flag] = flagValue; }
        }

        return result;
    }

    public GetExtSMFlagByKey (flagKey): any // #114474
    {
        const splitArr = flagKey.split('.');
        const flag = splitArr.length ? splitArr[splitArr.length - 1] : flagKey;
        const flagValue = this.InstrumentAdditionalInfo[flag] || null;

        if (!Instrument.ShownSMFlagValue(flagValue, flag)) { return ''; }

        const localKey = 'FlagSM.' + flag + '.' + flagValue;
        return Resources.getResource(localKey);
    }

    public static ShownSMFlagValue (flagValue, flag): boolean {
        if (!flagValue || !flag) { return false; }

        const flagExistInLocale = Resources.IsResourcePresent('FlagSM.' + flag + '.' + flagValue); // #115760

        return flagExistInLocale; // && flagValue != '1100' && flagValue != '20';
    }

    public GetMarketPrice (account, side): any {
        if (MathUtils.IsNullOrUndefined(account) || MathUtils.IsNullOrUndefined(side)) { return null; };

        const isBuy = side == OperationType.Buy;
        const askOrBid = isBuy ? 'AskSpread_SP_Ins' : 'BidSpread_SP_Ins';

        const quote = this.GetLastQuote(QuoteValid.Last);
        if (quote) {
            return quote[askOrBid](this.DataCache.GetSpreadPlan(account), this);
        }

        return null;
    }

    public static GetFutureMonthCode (month): string {
        switch (month) {
        case 1: return 'F';
        case 2: return 'G';
        case 3: return 'H';
        case 4: return 'J';
        case 5: return 'K';
        case 6: return 'M';
        case 7: return 'N';
        case 8: return 'Q';
        case 9: return 'U';
        case 10: return 'V';
        case 11: return 'X';
        case 12: return 'Z';
        }
        return '';
    }

    public GetDisclosedQuantityMinCoefficient (): number {
        const instrumentType = this.DataCache.getInstrumentTypeById(this.TypeId);

        if (isNullOrUndefined(instrumentType)) {
            return null;
        }

        return instrumentType.DisclosedQuantityMinCoefficient();
    }

    public GetWarningForChangeFromLastPrice (ranges): WarningForChangeFromLastPrice | null {
        if (!ranges?.length) {
            return null;
        }

        return new WarningForChangeFromLastPrice(ranges);
    }

    public GeneratedInstrumentDayBarMessages (): DirectInstrumentDayBarMessage[] {
        const now = DateTimeUtils.DateTimeNow();

        const tradableId = this.InstrumentTradableID;
        const routeId = this.Route;
        const instrumentId = this.GetInteriorID();

        // по трейдам
        const idbmTrade = new DirectInstrumentDayBarMessage();
        idbmTrade.InstrumentBarType = HistoryType.QUOTE_TRADES;
        idbmTrade.TradableId = tradableId;
        idbmTrade.QuoteRouteId = routeId;
        idbmTrade.Time = now;
        idbmTrade.TargetInstrumentName = instrumentId;

        if (!isNaN(this.Open)) {
            idbmTrade.Open = this.Open;
        }

        if (!isNaN(this.High)) {
            idbmTrade.High = this.High;
        }

        if (!isNaN(this.Low)) {
            idbmTrade.Low = this.Low;
        }

        if (!isNaN(this.Close)) {
            idbmTrade.PreviousClosePrice = this.Close;
        }

        if (!isNaN(this.MainClose)) {
            idbmTrade.TodayClosePrice = this.MainClose;
        }

        if (this.Ticks !== 0) {
            idbmTrade.Ticks = this.Ticks;
        }

        if (!isNaN(this.TotalVolume)) {
            idbmTrade.Volume = this.TotalVolume;
        }

        idbmTrade.LastTime = this.Level1.getLastMessageTime;

        let last = NaN;
        if (!isNullOrUndefined(this.Level1.lastPriceQuote)) {
            last = this.Level1.GetLastPrice(null);
        }
        if (!isNaN(last)) {
            idbmTrade.LastPrice = last;
        }

        const lastSize = this.Level1.GetLastSize(false);
        if (!isNaN(lastSize)) {
            idbmTrade.LastSize = lastSize;
        }

        // if (!string.IsNullOrEmpty(tradeVenue))
        //     idbmTrade.LastVenue = tradeVenue;

        // по аскам
        const idbmAsk = new DirectInstrumentDayBarMessage();
        idbmAsk.InstrumentBarType = HistoryType.QUOTE_ASK;
        idbmAsk.TradableId = tradableId;
        idbmAsk.QuoteRouteId = routeId;
        idbmAsk.Time = now;
        idbmAsk.TargetInstrumentName = instrumentId;

        if (!isNaN(this.AskOpen)) {
            idbmAsk.Open = this.AskOpen;
        }

        if (!isNaN(this.AskHigh)) {
            idbmAsk.High = this.AskHigh;
        }

        if (!isNaN(this.AskLow)) {
            idbmAsk.Low = this.AskLow;
        }

        if (!isNaN(this.AskPrevClose)) {
            idbmAsk.PreviousClosePrice = this.AskPrevClose;
        }

        if (!isNaN(this.AskMainClose)) {
            idbmAsk.TodayClosePrice = this.AskMainClose;
        }

        // по бидам
        const idbmBid = new DirectInstrumentDayBarMessage();
        idbmBid.InstrumentBarType = HistoryType.QUOTE_LEVEL1;
        idbmBid.TradableId = tradableId;
        idbmBid.QuoteRouteId = routeId;
        idbmBid.Time = now;
        idbmBid.TargetInstrumentName = instrumentId;

        if (!isNaN(this.BidOpen)) {
            idbmBid.Open = this.BidOpen;
        }

        if (!isNaN(this.BidHigh)) {
            idbmBid.High = this.BidHigh;
        }

        if (!isNaN(this.BidLow)) {
            idbmBid.Low = this.BidLow;
        }

        if (!isNaN(this.BidPrevClose)) {
            idbmBid.PreviousClosePrice = this.BidPrevClose;
        }

        if (!isNaN(this.BidMainClose)) {
            idbmBid.TodayClosePrice = this.BidMainClose;
        }

        let mainIDBM;
        if (this.HistoryType == HistoryType.QUOTE_TRADES) {
            mainIDBM = idbmTrade;
        } else if (this.HistoryType == HistoryType.QUOTE_ASK) {
            mainIDBM = idbmAsk;
        } else {
            mainIDBM = idbmBid;
        }

        mainIDBM.OpenInterest = this.OpenInterest;
        mainIDBM.SettlementPrice = this.SettlementPrice;
        mainIDBM.PrevSettlementPrice = this.PrevSettlementPrice;
        mainIDBM.FiftyTwoWeekHighPrice = this.FiftyTwoWeekHighPrice;
        mainIDBM.FiftyTwoWeekLowPrice = this.FiftyTwoWeekLowPrice;
        mainIDBM.High13Week = this.High13Week;
        mainIDBM.Low13Week = this.Low13Week;
        mainIDBM.High26Week = this.High26Week;
        mainIDBM.Low26Week = this.Low26Week;

        return [idbmAsk, idbmBid, idbmTrade];
    }

    public UseWarningForChangeFromLastPrice (): boolean {
        return !!this.WarningForChangeFromLastPrice;
    }

    public GetChangeByPrice (price): any // https://tp.traderevolution.com/entity/108864
    {
        return this.WarningForChangeFromLastPrice
            ? this.WarningForChangeFromLastPrice.GetChangeByPrice(price)
            : null;
    }

    public GetTickCost (): number {
    // TODO
        let ticCost = this.QuotingType === QuotingType.TickCost_TickSize && this.FuturesTickCoast > 0 ? this.FuturesTickCoast / this.PipsSize : 1.0;

        ticCost *= this.ContractMultiplier;

        return ticCost;
    }

    public getMinOffset (): any {
        let offsetType = GeneralSettingsWrapper.offsetType;
        if (offsetType === OffsetModeViewEnum.TicksFractionalForForex && !this.AllowUseFractinalTicksForForex()) { offsetType = OffsetModeViewEnum.Ticks; }
        return OrderUtils.ConvertTickOffset(this, offsetType, null, 1);
    }

    public getOffsetPrecision (): number {
        const minOffset = this.getMinOffset();
        const decimalPlaces = minOffset.toString().split('.')[1]?.length || 0;
        return decimalPlaces;
    }

    public equals (instrument: Instrument): boolean {
        return this === instrument;
    }

    get instrumentTypeShort (): string { return WMSymbolLookupViewItemHelper.getInstrumentTypeShortForInstrument(this); }
    get instrumentTypeColor (): string { return WMSymbolLookupViewItemHelper.getInstrumentTypeColorForInstrument(this); }

    public AllowOptionsExerciseOnLastTradeDate (): boolean {
        const session = this.CurrentTradingSession ?? this.FindSession(new Date());

        if (session == null || this.LastTradeDate == null) return false;

        const isSameDay = (date1: Date, date2: Date): boolean =>
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();

        const sameDay = isSameDay(session.ServerTodayDate, this.LastTradeDate);

        if (session.AllowOptionsExerciseOnLastTradeDate && sameDay) return true;

        return false;
    }
}

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources } from '../../Commons/properties/Resources';
import { ModifyPositionScreenTemplate, OrderEditControlsTemplate, OrderEditParameterVisibilityTemplate } from '../../templates.js';
import { Control } from '../elements/Control';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';
import { OrderEditViewBase } from '../panels/OrderEditViewBase';
import { Quantity } from '../../Utils/Trading/Quantity';
import { DataCache } from '../../Commons/DataCache';
import { PositionEdit } from '../../Commons/cache/OrderParams/PositionEdit';
import { TradingNumericErrorChecker } from '../../Commons/Trading/TradingNumericErrorChecker';
import { type Position } from '../../Commons/cache/Position';

// TODO. Refactor. Duplicate code of ModifyOrderScreen.
export class ModifyPositionScreen extends OrderEditViewBase {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): ScreensNames {
        return ScreensNames.ModifyPositionScreen;
    }

    public override oninit (): void {
        super.oninit();
        this.observe('position', this.onPosition);
        this.observe('tradingAllowed', this.onTradingAllowed);
        // TODO. Refactor.
        // TickAsync/Localize doesn't work for panels added with addScreen method.
        Resources.onLocaleChange.Subscribe(this.localize, this);
        Control.Ticker.Subscribe(this.TickAsync, this);
    }

    // TODO. Refactor.
    public override oncomplete (): void {
        super.oncomplete();

        this.onTradingAllowed(this.get('tradingAllowed'));
        this.localize();
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);
        Control.Ticker.UnSubscribe(this.TickAsync, this);

        super.dispose();
    }

    public override localize (): void {
        this.updatePanelHeader();
        void this.set({
            modifyBtnText: Resources.getResource('screen.modifyOrder.modify'),
            cancelBtnText: Resources.getResource('general.messageBox.cancel')
        });
    }

    public onPosition (position: Position): void {
        if (isNullOrUndefined(position)) return;

        this.setOrderEdit(new PositionEdit({
            dataCache: DataCache,
            position,
            quantity: new Quantity(position.Amount, true)
        }));

        this.updatePanelHeader();
    }

    public onTradingAllowed (allowed: boolean): void {
        const modifyBtn = this.Controls.modifyBtn;
        if (!isNullOrUndefined(modifyBtn)) {
            modifyBtn.set('enabled', allowed);
        }
    }

    public override updatePanelHeader (): void {
        const position = this.get('position');
        if (isNullOrUndefined(position)) return;

        void this.set('header',
            Resources.getResource('screen.modifyOrder.modifyPosition') +
        ' (' +
        position.PositionId +
        ')');
    }

    public override layout (): void {
    // tODO. Refactor.
        this.updateOrderEditParametersVisibility();
        this.fire('onFillByPosition');
    }

    public modify (): void {
        if (TradingNumericErrorChecker.HasErrors(this)) {
            return;
        }

        const oEd = this.orderEdit;
        oEd.placedFrom = this.get('placedFrom');
        oEd.UseSkipNoChange = true;
        const ordExec = DataCache.FOrderExecutor;
        let handler = async () => await ordExec.modifyPositionPromise(oEd);
        if (oEd instanceof PositionEdit && oEd.IsQuantityModified()) {
            handler = async () => await ordExec.modifyPositionProductType(oEd);
        }
        handler()
            .then((confirmed) => {
                if (confirmed) {
                    this.closeScreen();
                }
            })
            .catch((err) => {
                if (err === 'cancell clicked') {
                    return;
                }
                const ex = new CustomErrorClass('ModifyPositionScreen error', 'ModifyPositionScreen.modify', 'modify -> modifyPositionPromise');
                ErrorInformationStorage.GetException(ex);
            });
    }

    public closeScreen (): void {
        const parentContainerControl = this.get('parentContainerControl');

        if (!isNullOrUndefined(parentContainerControl)) {
            parentContainerControl.close();
        }
    }
}

OrderEditViewBase.extendWith(ModifyPositionScreen, {
    data: function () {
        return {
            resizable: false,
            width: 340,
            // height: 500,
            dockablePanel: false,
            showHeader: false,
            showFooter: true,
            header: '',
            showBorder: false,
            infoViewBottom: 0,
            // showFullscreenCloud: true, //TODO 83829

            position: null,
            quantity: null,
            SLNumericLink: null,
            TPNumericLink: null,
            MQNumericLink: null,
            qSLTPParams:
            {
                width: 170,
                left: 130,
                margin: 30
            }
        };
    },
    template: ModifyPositionScreenTemplate,
    partials: {
        // TODO. Refactor.
        orderEditControls: OrderEditControlsTemplate,
        orderEditParameterVisibility: OrderEditParameterVisibilityTemplate
    }
});

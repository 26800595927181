// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

// TODO. Refactor.
// Base class for binding orderEdit object and user input.

import { DynProperty } from '../../Commons/DynProperty';
import { Resources } from '../../Commons/properties/Resources';
import { MessageBoxType, TerceraMessageBox } from '../screen/TerceraMessageBox';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { Quantity } from '../../Utils/Trading/Quantity';
import { OrderEditUpdateData } from '../../Utils/Trading/OrderEditUpdateData';
import { type IWarningNumeric, OrderEditBase } from '../../Commons/cache/OrderParams/order-edit/OrderEditBase';
import { type SLTPDynProperty } from '../../Commons/SLTPDynProperty';
import { type TerceraNumeric } from '../elements/TerceraNumeric';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type TIF } from '../../Utils/Trading/OrderTif';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';

export class OrderEditViewBase extends ApplicationPanelWithTable<any> {
    public orderEdit: OrderEditBase | null = null;
    public parameterObserverHandler: any = null;
    public needLayout: boolean;
    public numericToActivate: any;
    public obsName: string | null;

    public override getInstrument (): Instrument {
        return this.get<Instrument>('instrument');
    }

    public override getType (): any { throw new Error('Not implemented'); }

    public override dispose (): void {
        this.disposeOrderEdit();
        super.dispose();
    }

    public disposeOrderEdit (): void {
        const parameterObserverHandler = this.parameterObserverHandler;

        if (!isNullOrUndefined(parameterObserverHandler)) {
            parameterObserverHandler.cancel();
        }

        this.parameterObserverHandler = null;

        void this.set('orderEditParameterArray', null);

        const orderEdit = this.orderEdit;
        if (isNullOrUndefined(orderEdit)) return;

        this.orderEdit = null;

        orderEdit.ParametersChanged.UnSubscribe(
            this.onOrderEditParametersChanged,
            this);

        orderEdit.ValidStateChanged.UnSubscribe(
            this.onValidStateChanged,
            this);

        orderEdit.QuantityChanged.UnSubscribe(this.onQuantityChanged, this);

        orderEdit.dispose();
    }

    // TODO. Refactor.
    public setOrderEdit (orderEdit: OrderEditBase): void {
        if (isNullOrUndefined(orderEdit)) {
            return;
        }

        this.disposeOrderEdit();

        this.orderEdit = orderEdit;

        void this.set(
            'orderEditParameterArray',
            orderEdit.createDynPropertyArrayFromParameters()).then(function () {
            this.onrender();
        }.bind(this));

        orderEdit.ParametersChanged.Subscribe(
            this.onOrderEditParametersChanged,
            this);

        orderEdit.ValidStateChanged.Subscribe(
            this.onValidStateChanged,
            this);

        // TODO. Refactor.
        this.onValidStateChanged(orderEdit.valid());

        this.parameterObserverHandler = this.observe(
            'orderEditParameterArray.*',
            this.onOrderEditParameterChangedByUser,
            { init: false });

        orderEdit.QuantityChanged.Subscribe(this.onQuantityChanged, this);

        // TODO. UGLY. Refactor.
        this.deferLayout();
    }

    public onQuantityChanged (qtyValue): void { }

    public onValidStateChanged (valid: boolean): void {
        void this.set('tradingAllowed', valid);
    }

    // orderEdit parameters' -> parameters' controls.
    public onOrderEditParametersChanged (dpDict: Record<string, DynProperty>): void {
        const orderEdit = this.orderEdit;
        const orderEditParameterArray: DynProperty[] = this.get('orderEditParameterArray');

        if (isNullOrUndefined(dpDict) || isNullOrUndefined(orderEdit) || !isValidArray(orderEditParameterArray)) {
            return;
        }

        const updatedArray = orderEditParameterArray.map(param => dpDict[param.name] ?? param);
        void this.set('orderEditParameterArray', updatedArray);

        // TODO. UGLY. Refactor.
        this.deferLayout();
    }

    // parameters' controls -> orderEdit parameters'.
    public onOrderEditParameterChangedByUser (dp: DynProperty): void {
        const orderEdit = this.orderEdit;
        if (isNullOrUndefined(orderEdit) || isNullOrUndefined(dp)) return;

        const dpDict = {};
        dpDict[dp.name] = dp;

        orderEdit.updateParameters(new OrderEditUpdateData(dpDict));

        if (this.get<boolean>('positionSizingChecked')) {
            orderEdit.recalcQtyForPositionSizing();
        }
    }

    public deferLayout (): void {
        this.needLayout = true;
    }

    public override TickAsync (): void {
        if (!this.needLayout) {
            return;
        }

        this.needLayout = false;
        this.layout();
        this.activateNumeric_proc();
    }

    public activateNumeric (numericLinkName: string): void {
        const observerName = 'observerOf' + numericLinkName;
        this[observerName] = this.observe(numericLinkName, function (link, obsName, n) {
            if (!isNullOrUndefined(n)) {
                this.numericToActivate = n;
                this.obsName = obsName;
            }
        }.bind(this, numericLinkName, observerName));
    }

    public activateNumeric_proc (): void {
        if (!isNullOrUndefined(this.numericToActivate)) {
            this.numericToActivate.setFocus(true, true);
            this.numericToActivate = null;

            const oName = this.obsName;
            if (!isNullOrUndefined(this[oName])) {
                this[oName].cancel();
                this[oName] = null;
                this.obsName = null;
            }
        }
    }

    public layout (): void { }

    // TODO. Refactor. Remove?
    public updateOrderEditParametersVisibility (): void {
        const orderEditParameterArray: DynProperty[] = this.get('orderEditParameterArray');
        if (!isValidArray(orderEditParameterArray)) return;

        for (let i = 0, len = orderEditParameterArray.length; i < len; i++) {
            const dp = orderEditParameterArray[i];
            if (dp?.visible) {
                this.getOrderEditParameterVisibility(dp);
            }
        }
    }

    // TODO. Refactor. Remove?
    public getOrderEditParameterVisibility (dp: DynProperty): boolean {
        let result = false;

        switch (dp.type) {
        case DynProperty.DOUBLE:
            result = true;
            break;

        case DynProperty.POSITION_SIZING:
            if (dp.positionSizingIsVisible) { result = true; }
            break;

        case DynProperty.SLTP:{
            const sltpDp = dp as SLTPDynProperty;
            if (sltpDp.allowedSL) { result = true; }
            if (sltpDp.allowedTP) { result = true; }
            if (sltpDp.sl.enabled === true) { result = true; }
            if (sltpDp.tp.enabled === true) { result = true; }
            if (sltpDp.SLTriggerVisible() || sltpDp.TPTriggerVisible()) { result = true; } // #109798
            break;
        }

        case DynProperty.COMBOBOX_COMBOITEM:
            result = true;
            break;

        case DynProperty.PRODUCT_TYPE_AND_MQ:
            if (dp.visible) { result = true; }
            break;

        case DynProperty.ATTENTION_MSG:
            if (dp.visible) { result = true; }
            break;
        }

        return result;
    }

    public focusWarningNumeric (): void { // for numeric focusing when it has warning after click button in confirmation screen of place/modify order
        let warningNumericData: IWarningNumeric | null = null;
        if (!isNullOrUndefined(this.orderEdit)) {
            warningNumericData = this.orderEdit.getWarningNumeric();
        }

        if (isNullOrUndefined(warningNumericData)) {
            return;
        }

        const numericLinkKey = warningNumericData.numericLinkKey;
        const dpControlName = warningNumericData.dpControlName;

        if (isValidString(numericLinkKey)) {
            this.activateNumeric(numericLinkKey);
            void this.set(numericLinkKey, this.tryGetDynPropertyControlTradingNumeric(dpControlName));
            this.deferLayout();
        }
    }

    public tryGetDynPropertyControlTradingNumeric (dpControlName: string): TerceraNumeric {
        const dpControl = !isNullOrUndefined(this.Controls) ? this.Controls[dpControlName] : null;
        if (!isNullOrUndefined(dpControl)) {
            return !isNullOrUndefined(dpControl.Controls) ? dpControl.Controls.TradingNumeric : null;
        }

        return null;
    }

    public addDisclosedQuantityIfNeed (key?: string): void {
        const instrument: Instrument = this.get('instrument');
        const orderEdit = this.orderEdit;
        const tif: TIF = this.get('tif');

        if (isNullOrUndefined(instrument) || isNullOrUndefined(orderEdit) || isNullOrUndefined(tif)) {
            return;
        }

        const needToAdd = OrderEditBase.isDisclosedQuantityNeed(instrument, orderEdit.getOrderTypeId(), tif);

        void this.set('disclosedQuantityShow', needToAdd && !Resources.isHidden('panel.newOrderEntry.disclosedLabel'));

        // if (needToAdd) {
        this.setDisclosedQuantityParams(key);
        // }
    }

    public setDisclosedQuantityParams (key: string): void {
        const inLots = GeneralSettings.View.displayAmountInLots();

        const oldDQ = this.get('disclosedQuantity');
        const quantity: Quantity = this.get('quantity');
        const ins: Instrument = this.get('instrument');

        if (isValidNumber(quantity?.value) && quantity?.value !== 0) {
            const decimalPlaces = ins.getAmountPrecision(inLots, this.orderEdit.account, this.orderEdit.productType);
            const lotStep = ins.getLotStep(this.orderEdit.productType, this.orderEdit.account);
            const increment = inLots ? lotStep : lotStep * ins.LotSize;
            const disclosedQuantityMinCoefficient = ins.GetDisclosedQuantityMinCoefficient();
            const minValue = isValidNumber(disclosedQuantityMinCoefficient) && disclosedQuantityMinCoefficient !== 0 ? quantity.value * disclosedQuantityMinCoefficient : increment;
            let minValueDeFacto = parseFloat(minValue.toFixed(decimalPlaces));

            if (minValueDeFacto < minValue) {
                minValueDeFacto += increment;
            }

            void this.set({
                dqMinValue: minValueDeFacto,
                dqMaxValue: quantity.value
            });
        }

        if (oldDQ?.value && key !== 'quantity') {
            return;
        }

        const order = this.get('order');
        const val = order?.DisclosedQty && key !== 'quantity'
            ? Quantity.convertQuantityValue(new Quantity(order.DisclosedQty, true), ins, inLots, this.orderEdit.account, this.orderEdit.productType)
            : quantity.value;

        void this.set('disclosedQuantity', new Quantity(val, inLots));
    }

    public static CheckNumericsErrors (numericsErrors): boolean {
        const keys = Object.keys(numericsErrors);
        const len = keys.length;
        for (let i = 0; i < len; i++) {
            const numericValidationStateInfo = numericsErrors[keys[i]];
            if (numericValidationStateInfo?.enabled && isValidString(numericValidationStateInfo.errorText)) {
                TerceraMessageBox.Show(
                    Resources.getResource('screen.error.title'),
                    numericValidationStateInfo.errorText,
                    MessageBoxType.Info,
                    null, null, false, true);

                return false;
            }
        }

        return true;
    }
}

ApplicationPanelWithTable.extendWith(OrderEditViewBase, {
    data: function () {
        return {
            orderEditParameterArray: null,
            quantity: null,
            tradingAllowed: false,
            isAllowedResponce: null, // backup result of calling IsAllowed.IsTradingAllowed - IsAllowedResponce type object
            tradingForbiddenReason: ''
        };
    }
});

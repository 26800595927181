// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from './Commons/Connection';
import { type SettingsDataResult } from './Commons/Settings/SettingsDataResult';
import { BrowserUtils } from './Commons/UtilsClasses/BrowserUtils';
import { Md5 } from 'ts-md5';
import { type WorkspaceData, type WorkspaceInfoResult } from './Controls/WorkSpace/WorkspaceInfoResult';

class BaseRoute {
    protected createRequestPromise: any;
    constructor (createRequestPromise) {
        this.createRequestPromise = createRequestPromise;
    }
}

class Settings extends BaseRoute {
    public async get (): Promise<SettingsDataResult> {
        return this.createRequestPromise('GET', 'settings');
    }

    public async post (settings: SettingsDataResult): Promise<any> {
        if (!isValidString(UserWebStorage.authData)) { await Promise.resolve(); return; }

        return this.createRequestPromise('POST', 'settings', settings).catch(async function (e) {
            return await Promise.reject(e);
        });
    }

    public async getPortfolioSettings (): Promise<any> {
        return this.createRequestPromise('GET', 'settings/portfolio').catch(async function (e) {
            return await Promise.reject(e);
        });
    }

    public async postPortfolioSettings (settings): Promise<any> {
        if (!isValidString(UserWebStorage.authData)) { await Promise.resolve(); return; }

        return this.createRequestPromise('POST', 'settings/portfolio', settings).catch(async function (e) {
            return await Promise.reject(e);
        });
    }
}

class Workspaces extends BaseRoute {
    public async getInitData (): Promise<WorkspaceInfoResult | null> {
        return this.createRequestPromise('GET', 'workspaces/init-data').catch(async function (e) {
            return await Promise.reject(e);
        });
    }

    public async getDefaultData (): Promise<WorkspaceInfoResult | null> {
        return this.createRequestPromise('GET', 'workspaces/default').catch(async function (e) {
            return await Promise.reject(e);
        });
    }

    public async get (id): Promise<any> {
        return this.createRequestPromise('GET', 'workspaces/' + id).catch(async function (e) {
            return await Promise.reject(e);
        });
    }

    public async update (id, obj: WorkspaceData): Promise<any> {
        return this.createRequestPromise('PUT', 'workspaces/' + id, obj).catch(async function (e) {
            return await Promise.reject(e);
        });
    }

    public async create (id, obj): Promise<any> {
        return this.createRequestPromise('POST', 'workspaces/' + id, obj).catch(async function (e) {
            return await Promise.reject(e);
        });
    }

    public async clone (srcId, destId): Promise<any> {
        return this.createRequestPromise('POST', 'workspaces/' + srcId + '/' + destId).catch(async function (e) {
            return await Promise.reject(e);
        });
    }

    public async setActiveWorkspaceId (wsId: string): Promise<any> {
        return this.createRequestPromise('PUT', 'workspaces/active/' + wsId).catch(async function (e) {
            return await Promise.reject(e);
        });
    }

    public async delete (ids): Promise<any> {
        return this.createRequestPromise('DELETE', 'workspaces/', ids).catch(async function (e) {
            return await Promise.reject(e);
        });
    }
}

class TvWorkspaces extends BaseRoute {
    public async get (id): Promise<any> {
        return this.createRequestPromise('GET', 'tv-workspaces/' + id).catch(async function (e) {
            if (e === 204) {
                // first logon no workspace
                return await Promise.resolve(null);
            }

            return await Promise.reject(e);
        });
    }

    public async put (id, obj): Promise<any> {
        return this.createRequestPromise('PUT', 'tv-workspaces/' + id, obj).catch(async function (e) {
            return await Promise.reject(e);
        });
    }
}

class Branding extends BaseRoute {
    public async locales (connectionName): Promise<any> {
        return this.createRequestPromise('GET', 'branding/locales/' + connectionName);
    }

    public async investingCustomScheme (connectionName): Promise<any> {
        return this.createRequestPromise('GET', 'branding/investingCustomScheme/' + connectionName);
    }
}

class Connections extends BaseRoute {
    public async get (): Promise<any> {
        return this.createRequestPromise('GET', 'connections');
    }
}
/**
 * https://tp.traderevolution.com/entity/86122
 */
class ExternalResourse extends BaseRoute {
    public async post (url): Promise<any> {
        const encodedURL = encodeURIComponent(url);
        return this.createRequestPromise('POST', 'external-resourse', { encodedURL }).catch(async function (e) {
            return await Promise.reject(e);
        });
    }
}

/**
 * https://tp.traderevolution.com/entity/114288
 */
class YoutubePageParser extends BaseRoute {
    public async post (url, videoId): Promise<any> {
        return this.createRequestPromise('POST', 'youtube-parser', { url, videoId }).catch(async function (e) {
            return await Promise.reject(e);
        });
    }
}

/* ================== TODO. Refactor. Rename. ================== */

export class UserWebStorage {
    static RestApiNotWork: boolean = false;
    static authData: string = null;

    public settings: Settings;
    public workspaces: Workspaces;
    public readonly tvWorkspaces: TvWorkspaces;
    public branding: Branding;
    public connections: Connections;
    public externalResourse: ExternalResourse;
    public youtubePageParser: YoutubePageParser;
    public urlRoot: string | null;

    constructor () {
        const createRequestPromise = this.createRequestPromise.bind(this);
        this.settings = new Settings(createRequestPromise);
        this.workspaces = new Workspaces(createRequestPromise);
        this.tvWorkspaces = new TvWorkspaces(createRequestPromise);
        this.branding = new Branding(createRequestPromise);
        this.connections = new Connections(createRequestPromise);
        this.externalResourse = new ExternalResourse(createRequestPromise); // https://tp.traderevolution.com/entity/86122
        this.youtubePageParser = new YoutubePageParser(createRequestPromise); // https://tp.traderevolution.com/entity/114288
        this.urlRoot = null;

        UserWebStorage.authData = null;
        UserWebStorage.RestApiNotWork = false; // if 400 responce
    }

    public reinit (userSessionId: string, connectionName: string): void {
        if (!isValidString(userSessionId) || !isValidString(connectionName)) { throw Error('Insufficient auth data.'); }

        UserWebStorage.authData = Md5.hashStr(connectionName);
    }

    public clear (): void {
        UserWebStorage.authData = null;
    }

    public async createRequestPromise (type, url, body): Promise<any> {
        const authData = UserWebStorage.authData;
        const urlRoot = this.urlRoot;
        const contentTypeJson = 'application/json';
        let token = '';
        if (Connection?.ConnectResultData) { token = Connection.ConnectResultData.ConnectResultData.accessToken; }

        return await new Promise(function (resolve, reject/*, onCancel */) {
            const xhr = new XMLHttpRequest();
            // onCancel(function () { xhr.abort(); });

            let resUrl = urlRoot ? urlRoot + url : url;

            // IE ignores etag and forcibly caches get requests. Screw ie.
            if (BrowserUtils.isIE() && type === 'GET') { resUrl += '?req_id=' + new Date().getTime(); }

            xhr.open(type, resUrl);

            xhr.setRequestHeader('Auth-Data', authData);
            if (token) { xhr.setRequestHeader('Auth-Token', token); }
            xhr.setRequestHeader('Content-Type', contentTypeJson);

            xhr.onreadystatechange = function () {
                if (this.readyState !== /* DONE */4) { return; }

                if (this.status !== 200) { reject(this.status); return; }

                const contentType = this.getResponseHeader('Content-Type') || '';
                resolve(contentType.includes(contentTypeJson)
                    ? JSON.parse(this.responseText)
                    : null);
            };

            xhr.send(body ? JSON.stringify(body) : null);
        });
    }
}

export const UserWebStorageInstance = new UserWebStorage();

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from '../../Commons/Connection';
import { ConnectionErrorsEnum, ConnectionStates } from '../../Commons/ConnectionEnums';
import { CustomEvent } from '../../Utils/CustomEvents';
import { CustomErrorClass, ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources, CurrentLang } from '../../Commons/properties/Resources';
import { datePickerDropDownHandler, multiComboBoxPopupMenuHandler, contextMenuHandler, popupErrorHandler, contextMenuWithOverHandler, messageBoxHandler } from '../../Utils/AppHandlers';
import { LinkedSystem } from '../misc/LinkedSystem';
import { TradingToolViewBase } from '../../Chart/Tools/TradingTools/TradingToolViewBase';
import { KeyCode } from '../../Commons/KeyEventProcessor';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';

import { NewNetTraderTemplate } from '../../templates.js';
import { DockSystemInstance } from '../DockSystem';
import { ControlEvents } from '../elements/Control';
import { TerceraPopupBaloon } from '../elements/TerceraPopupBaloon';
import { TerceraTabStripItem } from '../elements/TerceraTabStrip';
import { TerceraTextBox } from '../elements/TerceraTextBox';
import { AppNames, PanelNames } from '../UtilsClasses/FactoryConstants';
import { BOTTOM_WINDOWS_MARGIN, TOP_WINDOWS_MARGIN } from '../UtilsClasses/SizeConstants';
import { ExternalScreen } from './ExternalScreen';
import { TerceraAboutScreen } from './TerceraAboutScreen';
import { TerceraBrokerInfoScreen } from './TerceraBrokerInfoScreen';
import { MessageBoxType, TerceraMessageBox } from './TerceraMessageBox';
import { TerceraOnboardingScreen } from './TerceraOnboardingScreen';
import { TerceraReconnectScreen } from './TerceraReconnectScreen';
import { TerceraTimeoutMessageBox } from './TerceraTimeoutMessageBox';
import { TerceraTradingPassword } from './TerceraTradingPassword';
import { MainWindowBase } from './MainWindowBase';
import { TradingToolsScreen } from './TradingToolsScreen';
import { ThemeManager } from '../misc/ThemeManager';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { SoundManager } from '../../Utils/SoundManager';
import { TradingLockUtils } from '../../Utils/TradingLockUtils';
import { UserWebStorage, UserWebStorageInstance } from '../../user-web-storage';
import { DataCache } from '../../Commons/DataCache';
import { SettingsLoggingManager } from '../../Commons/SettingsLoggingManager';
import { SessionSettings } from '../../Commons/SessionSettings';
import { ExternalScreenStorage } from '../../Commons/cache/ExternalScreenStorage';
import { PanelDisplayMode } from '../../Commons/CustomerAccess/PanelDisplayMode';
import { CustomerAccess } from '../../Commons/CustomerAccess/CustomerAccess';
import { Factory } from '../panels/Factory';
import { NewNetTraderMode } from './NewNetTraderMode';
import { MAX_WORKSPACE_COUNT, WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';
import { PropertySetupScreen } from './PropertySetupScreen';
import { SymbolInfoPanel } from '../panels/SymbolInfoPanel';
import { GlobalAnimationFrameHandler } from '../../Commons/GlobalAnimationFrameHandler';
import { BrandingUtils } from '../../Commons/UtilsClasses/BrandingUtils';
import { ApplicationInfo } from '../../Commons/ApplicationInfo';
import { WorkSpaceDBManager } from '../WorkSpace/WorkSpaceDBManager';
import { WorkSpaceUserKey } from '../../Commons/UtilsClasses/WorkSpaceUserKey';
import { SideBarButtonsHelper } from '../../Commons/SideBar/SideBarButtonsHelper';
import $ from 'jquery';
import { ExternalLink } from '../../Commons/cache/ExternalLink';
import { externalLinkManager } from '../../Utils/Managers/ExternalLinkManager';
import { AccountWidgetMenu } from '../elements/AccountWidget/AccountWidgetMenu';
import { type ApplicationPanel } from '../panels/ApplicationPanel';
import { type ConnectionAttemptResponse } from '../../Commons/ConnectionAttemptResponse';
import { SettingsDBManager } from '../../Commons/Settings/SettingsDBManager';

export class NewNetTrader extends MainWindowBase {
    // alertPanel: null,
    // ideasPanel: null,
    // notificationsPanel: null,
    // videoWidget: null,
    public activePanelRootDiv = null;
    public onNetTraderModeChange = new CustomEvent();
    public onMouseMoveEvt = new CustomEvent();
    public onDisconnectCalled = new CustomEvent();
    public terminalMenu = [];
    public toolsMenu = [];
    public moreMenu = [];
    public TimeoutCounterCycle = null;
    public TimeoutCounterCycleTime = -1;
    public ExternalScreenStorage = null;
    public backupDefaultWebHeader = null;
    public backupDefaultFaviconHref = null;
    public backupDefaultPageTitle = null;
    public brandingWebHeader = null;

    public width: number;
    public height: number;
    public lastMouseMoveX: number;
    public lastMouseMoveY: number;

    public NeedRestoreLockTradingByPassword: boolean;

    public lastMouseMoveEvent: any;
    public mouseMoveAnimationFrameId: any;
    public NonFocusedAttractionLoop: any;
    public WorkSpaceTabStrip: any;
    public pingInterval: any;
    public mySymbolInfo: any;

    public readonly IsNewNetTrader = true;
    public getType (): string { return AppNames.NewNetTrader; };

    //
    // Вызывается один раз
    //
    public Initialize (): void {
    // Pens, brushes init except images.
        TradingToolViewBase.ThemeChanged();
        ThemeManager.preloadImagesAsync(NewNetTrader.preloadImagesAsyncCallback);
        DataCache.OnReintialize.Subscribe(NewNetTrader.Reinitialize);

        // this.initToolstripWorkMode();
        this.initWorkSpaceTabStrip(this.Controls.WorkSpacesList);
        //
        this.InitilizeThread();

        TradingLockUtils.TradingLock.TradingLockChanged.Subscribe(this.updateTradingLockState, this);
    };

    static preloadImagesAsyncCallback (): void {
        TradingToolViewBase.ThemeChanged();
        MainWindowManager.MainWindow.themeChange();
    };

    public updateTradingLockState (): void {
        MainWindowManager.MainWindow.set('tradingLocked', TradingLockUtils.TradingLock.tradingLocked);
    };

    //
    // Вызывается каждый раз после релогина
    //
    static Reinitialize (): void {
        try {
            const self = MainWindowManager.MainWindow;

            self.addToolsMenuItems();

            self.updateToolstrip();

            NewNetTrader.populateWorkSpaceTabStrip();
            self.Controls.ticketViewer.tryRegisterUpdates();
            SoundManager.tryPlaySound(SoundManager.SoundKeys.Welcome);

            if (!WorkSpaceManager.needRestoreWS) { LinkedSystem.Clear(); }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    };

    public oninit (): void {
        MainWindowBase.prototype.oninit.apply(this);
        MainWindowManager.Factory = Factory;

        void this.set('maxWorkspaceCount', MAX_WORKSPACE_COUNT);

        $(window).on('resize', this.updateSize.bind(this));
        this.updateSize();

        this.observe('activeControl', this.onActiveControlChanged);
        this.observe('connectedStatus', function (newValue: boolean) {
            if (newValue) {
                void this.set({
                    connectedStatusText: Resources.getResource('general.connection.status.Connected'),
                    connectedStatusSrc: 'current_theme/dark/darkNew/iconPanel/connected.png'
                });
            } else {
                void this.set({
                    connectedStatusText: Resources.getResource('general.connection.status.Reconnecting'),
                    connectedStatusSrc: 'current_theme/dark/darkNew/iconPanel/reconnecting.png'
                });
            }
            SideBarButtonsHelper.OnConnectedStatusChangedEmit(newValue);
        });

        this.on('leftPictureSizeChanged', this.updateToolstrip);

        // TODO. 1 event subscription.
        this.on('maxWorkspaceCountReached', this.onMaxWorkspaceCountReached);
        WorkSpaceManager.WorkspacesLimitReached.Subscribe(
            this.onMaxWorkspaceCountReached, this);
        this.on('lockTradingClick', this.OnLockTradingClick);

        Connection.onConnectProcessError.Subscribe(this.onConnectProcessError, this);
    };

    private onConnectProcessError (errContext: ConnectionAttemptResponse): void {
        if (isNullOrUndefined(errContext)) { return; }

        switch (errContext.ErrorCode) {
        case ConnectionErrorsEnum.ERROR_MULTI_POLICY:
            if (!isNullOrUndefined(messageBoxHandler.Show)) {
                messageBoxHandler.Show(Resources.getResource('panel.EventLog.eventType.disconnect'), Resources.getResource(errContext.text), messageBoxHandler.msgType.Warning, null, null, false, true, '', { okText: Resources.getResource('general.messageBox.ok') }, true);
            }
            break;
        case ConnectionErrorsEnum.MAX_CONCURRENT_CONNECTIONS:
            if (!isNullOrUndefined(messageBoxHandler.Show)) {
                messageBoxHandler.Show(Resources.getResource('Max. concurrent connections'), Resources.getResource('Max. concurrent connections reason'), messageBoxHandler.msgType.Warning, null, null, false, true, '', { okText: Resources.getResource('general.messageBox.ok') }, true);
            }
            break;
        }
    }

    public baseResizibleBTN (): void {
        const leftPicture = this.Controls.leftPicture;
        const terminal = this.Controls.Terminal;
        const tools = this.Controls.Tools;
        const more = this.Controls.More;
        let leftTerminal = 0; let widthTerminal = 0;
        let leftTools = 0; let widthTools = 0;
        let leftMore = 0; let widthMore = 0;

        let leftPictureData = null;
        if (leftPicture) { leftPictureData = leftPicture.get(); }

        let curerntLeft = 0;

        if (terminal) {
            leftTerminal = leftPictureData ? +leftPictureData.left + (leftPictureData.width || 0) + 5 : 5;
            widthTerminal = terminal.get('text').length * 8 + 10;
            void this.set({
                leftTerminal,
                widthTerminal
            });
            curerntLeft = leftTerminal + widthTerminal;
        }

        if (tools?.get('visible')) {
            leftTools = curerntLeft;
            widthTools = tools.get('text').length * 8 + 10;
            void this.set({
                leftTools,
                widthTools
            });
            curerntLeft += widthTools;
        }

        if (more) {
            leftMore = curerntLeft;
            widthMore = more.get('text').length * 9 + 10;
            void this.set({
                leftMore,
                widthMore
            });
        }
    };

    public createMainMenuActions (): void {
        this.on('btnOverTerminal', (btnContext) => {
            contextMenuWithOverHandler.handlerOnMainBtnOver(btnContext, this.terminalMenu);
        });

        this.on('btnOverTools', (btnContext) => {
            contextMenuWithOverHandler.handlerOnMainBtnOver(btnContext, this.toolsMenu);
        });

        this.on('btnOverMore', (btnContext) => {
            contextMenuWithOverHandler.handlerOnMainBtnOver(btnContext, this.moreMenu);
        });

        this.on('btnOverExternalMenu', (el, itemIndex) => {
            const menuItems = this.get('externalMenuItems')[itemIndex].items;
            contextMenuWithOverHandler.handlerOnMainBtnOver(el, menuItems);
        });

    // this.on("alertClick", function ()
    // {
    //     this.showAlertPanel();
    // })
    // this.on("ideasClick", function ()
    // {
    //     this.showIdeasPanel();
    // })
    // this.on("notificationsClick", function ()
    // {
    // this.showNotificationsPanel();
    // })
    // this.on("videoWidgetBtnClick", function ()
    // {
    // this.onVideoWidgetBtnClick();
    // });
    // this.on("settingsClick", function ()
    // {
    //     this.showGeneralSetting();
    // })
    // this.on("disconnectClick", function ()
    // {
    //     this.disconnectClick();
    // })
    };

    public oncomplete (): void {
        this.OnResize = new CustomEvent();

        this.ExternalScreenStorage = new ExternalScreenStorage();

        void this.set({ netTraderState: NewNetTraderMode.LoginMode });
        this.observe('netTraderState', this.netTraderStateChange);
        Connection.onConnectStateChange.Subscribe(this.netTraderConnectChange, this);
        Connection.onGetToken.Subscribe(this.onGetToken, this);

        // В этот метод перенести всю инициализацию??
        this.Initialize();

        Resources.onLocaleChange.Subscribe(this.localize, this);
        ThemeManager.onThemeChange.Subscribe(this.themeChange, this);

        this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
        this.baseResizibleBTN();
        this.createMainMenuActions();
        // let counterUpdate = function ()
        // {
        //     let count = DataCache.AlertManager.GetActiveCount()
        //     MainWindowManager.MainWindow.Controls.alerts.set({ counter: count })
        // }
        // DataCache.AlertManager.OnAlertAdded.Subscribe(counterUpdate, this);
        // DataCache.AlertManager.OnAlertRemoved.Subscribe(counterUpdate, this);
        // DataCache.AlertManager.OnAlertUpdated.Subscribe(counterUpdate, this);

        DataCache.ExternalLinksCache.OnAddLink.Subscribe(this.addExternalMenuItem, this);
        DataCache.ExternalLinksCache.OnUpdateLink.Subscribe(this.updateExternalMenuItem, this);
        DataCache.ExternalLinksCache.OnDeleteLink.Subscribe(this.deleteExternalMenuItem, this);

        // this.set('alertsBtnVisible', !Resources.isHidden("screen.Alerts.visibility"))
    };

    public onActiveControlChanged (newVal): void {
        this.activePanelRootDiv = newVal ? newVal.find('div') : null;
    };

    public onMouseUp (event): void {
        const activeControl = this.get('activeControl');
        if (!activeControl) return;

        if (activeControl.get('movable')) {
        // TODO MB
            const activeControlactiveControl = activeControl.get('activeControl');
            if (activeControlactiveControl) {
                activeControlactiveControl.set('onProcessMove', false);
            }

            activeControl.set('onProcessMove', false);
            delete activeControl.movingParameters;

            const lastMouseMoveX = this.lastMouseMoveX;
            const lastMouseMoveY = this.lastMouseMoveY;

            if (lastMouseMoveX !== undefined && lastMouseMoveY !== undefined) {
                activeControl.setLocation(lastMouseMoveX, lastMouseMoveY);
                delete this.lastMouseMoveX;
                delete this.lastMouseMoveY;
                delete this.lastMouseMoveEvent;
            }
            this.ExternalScreenStorage.EnableAllScreens();
        }

        if (activeControl.get('resizable')) {
            activeControl.onProcessResize = false;
            delete activeControl.onProcessResize;
            // Inform controls about end resizing
            this.onResizeEnd();
            this.ExternalScreenStorage.EnableAllScreens();
        }
    };

    public onMouseMove (event): void {
        this.lastMouseMoveEvent = event;
        // In order to increase smoothness of panel's movement.
        cancelAnimationFrame(this.mouseMoveAnimationFrameId);
        this.mouseMoveAnimationFrameId = requestAnimationFrame(() => { this.mouseMoveHandler(); });
    };

    public mouseMoveHandler (): void {
        if (!this.get) { return; }

        contextMenuWithOverHandler.TryToCloseMenu(this.lastMouseMoveEvent);

        const event = this.lastMouseMoveEvent;
        if (!event) return;

        this.onMouseMoveEvt.Raise(event);

        const activeControl = this.get('activeControl');
        if (!activeControl) return;

        if (activeControl.get('onProcessMove') || activeControl.onProcessResize) {
            this.ExternalScreenStorage.DisableAllScreens();
        }

        if (activeControl.get('onProcessMove') === true) {
            const moveControl = activeControl.getControl();
            const myParams = activeControl.movingParameters;
            const ev = event.original;
            const X_Shift = myParams.startMoveCoordX - ev.pageX;
            const Y_Shift = myParams.startMoveCoordY - ev.pageY;

            let lastMouseMoveX = myParams.startMyX - X_Shift;
            let lastMouseMoveY = myParams.startMyY - Y_Shift;

            // let windowSideBordersW = WINDOW_SIDE_BORDERS_WIDTH;
            // New window left.
            const activeControlW = moveControl.offsetWidth;
            const mainWindowW = this.width;

            if (lastMouseMoveX + activeControlW > mainWindowW) { lastMouseMoveX = mainWindowW - activeControlW; }

            if (lastMouseMoveX < 0) { lastMouseMoveX = 0; }

            // New window top.
            const activeControlH = moveControl.offsetHeight;
            const topWindowsMargin = TOP_WINDOWS_MARGIN;
            const bottomWindowsMargin = this.height - BOTTOM_WINDOWS_MARGIN;

            if (lastMouseMoveY + activeControlH > bottomWindowsMargin) { lastMouseMoveY = bottomWindowsMargin - activeControlH; }

            if (lastMouseMoveY < topWindowsMargin) { lastMouseMoveY = topWindowsMargin; }

            this.lastMouseMoveX = lastMouseMoveX;
            this.lastMouseMoveY = lastMouseMoveY;

            // Workaround to avoid "heavy" ractive code.
            const rootPanelDiv = this.activePanelRootDiv;
            rootPanelDiv.style.left = lastMouseMoveX + 'px';
            rootPanelDiv.style.top = lastMouseMoveY + 'px';
        }

        if (activeControl.onProcessResize) { activeControl.onResizing(event); }
    };

    public updateSize (): void {
        const $window = $(window);
        // I don't use ractive.set here for quicker reading in future.
        this.width = $window.outerWidth();
        this.height = $window.outerHeight();

        this.updateLeftToolStripWidth();
    };

    public netTraderStateChange (newState, oldState, key): void {
        this.onNetTraderModeChange.Raise(newState);
        this.localize();
    };

    public onGetToken (): void {
    // Wait for finishblocktransfer
        const langValue = this.Controls.loginScreen.getLoginScreenLanguage();
        void this.LoadingStarted(langValue);
    };

    public netTraderConnectChange (newState, oldState, key): void {
        let newMode = this.get('netTraderState');
        switch (newState.connectionState) {
        case ConnectionStates.CONNECTED:

            // TODO. Refactor all this nightmare. So many entry points...
            const vendor = Connection.vendor;
            SessionSettings.ClearSaveStack();
            // App.UserWebStorage.reinit(vendor.getUserSessionId(), this.Controls.loginScreen.get('connectionParams.connectionName'));
            UserWebStorageInstance.reinit(vendor.GetLogin(), this.Controls.loginScreen.get('connectionParams.connectionName'));
            const WorkSpaceUser = new WorkSpaceUserKey(vendor.GetLogin(), this.Controls.loginScreen.get('connectionParams.connectionName'));
            WorkSpaceDBManager.InitUserStorage(WorkSpaceUser.getKey());
            SettingsDBManager.InitUserStorage(WorkSpaceUser.getKey());

            this.set({ connectedStatus: true });

            break;
        case ConnectionStates.DISCONNECTED:
            DataCache.cleanup();
            UserWebStorage.RestApiNotWork = false;
            SoundManager.tryPlaySound(SoundManager.SoundKeys.Finish);
            // this.alertPanel = null;
            // this.ideasPanel = null;
            // this.notificationsPanel = null;
            // this.videoWidget = null;
            this.StopTimeoutCounter();
            this.tryToCloseAllMenus();

            const finishFunction = () => {
                this.RemoveAllControls();
                WorkSpaceManager.clearCurrentWorkSpace();

                newMode = NewNetTraderMode.LoginMode;
                this.set({ netTraderState: newMode });
            };

            // Closing of dialog screens.
            if (MainWindowManager.AsyncCloseObject?.AsyncClosePromise) { MainWindowManager.AsyncCloseObject.AsyncClosePromise.then(finishFunction); } else { finishFunction(); }

            TradingLockUtils.ResetBeforeConnection();
            Resources.setNeedForceUpdateLocale();

            WorkSpaceManager.needRestoreWS = false;
            WorkSpaceManager.stopSaveLoop();

            this.StopPingLoop();
            UserWebStorageInstance.clear();

            this.ExternalScreenStorage.Clear();
            void this.set({ connectedStatus: false });
            break;
        case ConnectionStates.CONNECTION_LOST:
            SoundManager.tryPlaySound(SoundManager.SoundKeys.ConnectionLost);

            this.NeedRestoreLockTradingByPassword = true;
            void this.set({ connectedStatus: false });
            TerceraReconnectScreen.startReconnection();

            WorkSpaceManager.clearCurrentWorkSpace();
            WorkSpaceManager.stopSaveLoop();

            DataCache.Loaded = false;
            DataCache.cleanup();

            UserWebStorage.RestApiNotWork = false;
            newMode = NewNetTraderMode.WorkMode;
            Resources.setNeedForceUpdateLocale();

            this.StopPingLoop();
            void this.set({ pingText: '...' });
            break;
        }

        void this.set({ netTraderState: newMode });
    };

    private tryToCloseAllMenus (): void {
        if (contextMenuHandler.isShowed()) {
            contextMenuHandler.Hide();
        }

        if (contextMenuWithOverHandler.isShowed()) {
            contextMenuWithOverHandler.Close();
        }

        if (AccountWidgetMenu.screenInstance?.isShowed) {
            AccountWidgetMenu.screenInstance.Close();
        }
    }

    public getClientPanel () {
        return this.Controls.topWindowBody.getClientPanel();
    };

    public setToolstripItems (itemsLeft, itemsRight): void {
        const leftToolstrip = this.Controls.leftToolStip.Controls.leftToolStip;
        if (leftToolstrip && itemsLeft) {
            leftToolstrip.set('toolStipItems', itemsLeft);
            this.updateToolstrip();
        }

        const rightToolstrip = this.Controls.rigthToolStip.Controls.rigthToolStip;
        if (rightToolstrip && itemsRight) { rightToolstrip.set('toolStipItems', itemsRight); }
    };

    public getAllowedTerminalMenu (): void {
        const tMenu = [];
        const panelsVisibility = CustomerAccess.GetPanelsVisibility();
        const clickItemTerminal = function (sender) { Factory.addPanel(sender.type); };

        if (panelsVisibility[PanelNames.ChartPanel] && !Resources.isHidden('ribbon.tradingTerminal.terceraChart')) { tMenu.push({ style: 'js-list-chart', name: 'chart', locKey: 'panel.terceraChart', event: clickItemTerminal, type: PanelNames.ChartPanel }); }
        if (panelsVisibility[PanelNames.TradingViewChartPanel] && !Resources.isHidden('ribbon.tradingTerminal.tradingViewChart')) { tMenu.push({ style: 'js-list-tradingview-chart', name: 'tradingViewChart', locKey: 'panel.tradingViewChart', event: clickItemTerminal, type: PanelNames.TradingViewChartPanel }); }
        if (panelsVisibility[PanelNames.InformerPanel] && !Resources.isHidden('ribbon.tradingTerminal.watchlist')) { tMenu.push({ style: 'js-list-watchlist', name: 'watchlist', locKey: 'panel.watchlist', event: clickItemTerminal, type: PanelNames.InformerPanel }); }
        if (panelsVisibility[PanelNames.MarketDepthPanel] && !Resources.isHidden('ribbon.tradingTerminal.level2')) { tMenu.push({ style: 'js-list-marketdepth', name: 'marketdepth', locKey: 'panel.level2', event: clickItemTerminal, type: PanelNames.MarketDepthPanel }); }
        if (panelsVisibility[PanelNames.TimeSalesPanel] && !Resources.isHidden('ribbon.tradingTerminal.timeSales')) { tMenu.push({ style: 'js-list-timeandsale', name: 'timeandsale', locKey: 'panel.timeSales.MenuName', event: clickItemTerminal, type: PanelNames.TimeSalesPanel }); }
        if (panelsVisibility[PanelNames.GridPanel] && !Resources.isHidden('ribbon.tools.grid')) { tMenu.push({ style: 'js-list-grid', name: 'grid', locKey: 'ribbon.tools.grid', event: clickItemTerminal, type: PanelNames.GridPanel }); }
        if (panelsVisibility[PanelNames.RiskCalculator] && !Resources.isHidden('ribbon.tradingTerminal.riskCalculator')) { tMenu.push({ style: 'js-list-riskcalculator', name: 'riskcalculator', locKey: 'panel.riskCalculator', event: clickItemTerminal, type: PanelNames.RiskCalculator }); }
        if (!Resources.isHidden('ribbon.tradingTerminal.neworder') || !Resources.isHidden('panel.optionMaster')) {
            tMenu.push({ separator: true });
            if (!Resources.isHidden('ribbon.tradingTerminal.neworder')) {
                tMenu.push({ style: 'js-list-orderentry', name: 'orderentry', locKey: 'panel.neworder', event: clickItemTerminal, type: PanelNames.AdvancedOrderEntry });
            }
            if (panelsVisibility[PanelNames.OptionMasterPanel] && !Resources.isHidden('panel.optionMaster')) {
                tMenu.push({ style: 'js-list-optionmaster', isBeta: true, name: 'optionmaster', locKey: 'panel.optionMaster', event: clickItemTerminal, type: PanelNames.OptionMasterPanel });
            }
            tMenu.push({ separator: true });
        }
        if (panelsVisibility[PanelNames.PositionsPanel] && !Resources.isHidden('ribbon.tradingTerminal.positions')) { tMenu.push({ style: 'js-list-positions', name: 'positions', locKey: 'panel.positions', event: clickItemTerminal, type: PanelNames.PositionsPanel }); }
        if (panelsVisibility[PanelNames.OrdersPanel] && !Resources.isHidden('ribbon.tradingTerminal.orders')) { tMenu.push({ style: 'js-list-workingorders', name: 'workingorders', locKey: 'panel.orders', event: clickItemTerminal, type: PanelNames.OrdersPanel }); }
        if (panelsVisibility[PanelNames.OrderHistoryPanel] && !Resources.isHidden('ribbon.tradingTerminal.orderHistory')) { tMenu.push({ style: 'js-list-ordershistory', name: 'ordershistory', locKey: 'panel.orderHistory', event: clickItemTerminal, type: PanelNames.OrderHistoryPanel }); }
        if (panelsVisibility[PanelNames.StatementPanel] && !Resources.isHidden('ribbon.tradingTerminal.statement')) { tMenu.push({ style: 'js-list-statement', name: 'statement', locKey: 'panel.statement', event: clickItemTerminal, type: PanelNames.StatementPanel }); }
        if (panelsVisibility[PanelNames.FilledOrdersPanel] && !Resources.isHidden('ribbon.tradingTerminal.trades')) { tMenu.push({ style: 'js-list-filledorders', name: 'filledorders', locKey: 'panel.trades', event: clickItemTerminal, type: PanelNames.FilledOrdersPanel }); }
        if (panelsVisibility[PanelNames.AssetsPanel] && !Resources.isHidden('ribbon.tradingTerminal.positions')) { tMenu.push({ style: 'js-list-assets', name: 'assets', locKey: 'panel.assets', event: clickItemTerminal, type: PanelNames.AssetsPanel }); }
        if (panelsVisibility[PanelNames.PositionsBalancePanel] && !Resources.isHidden('ribbon.tradingTerminal.positionsBalance')) { tMenu.push({ style: 'js-list-positionsBalance', name: 'positionsBalance', locKey: 'panel.positionsBalance', event: clickItemTerminal, type: PanelNames.PositionsBalancePanel }); }
        if (panelsVisibility[PanelNames.SavedOrdersPanel] && !Resources.isHidden('panel.savedOrders')) { tMenu.push({ style: 'js-list-savedorders', name: 'savedorders', locKey: 'panel.savedOrders', event: clickItemTerminal, type: PanelNames.SavedOrdersPanel }); }

        this.terminalMenu = tMenu;
    };

    public updateToolstrip (): void {
        const Controls = this.Controls;
        if (!Controls.Terminal || !Controls.More) { return; }

        const imW = this.get('leftTerminal');
        let butW = this.get('widthTerminal');
        Controls.Terminal.set('left', imW + 10);

        if (this.toolsMenu.length > 0) {
            Controls.Tools.set('left', imW + butW + 10);
            butW += this.get('widthTools');
        }

        Controls.More.set('left', imW + butW + 10);

        this.getAllowedTerminalMenu();
    };

    public getLeftToolStripX () {
        const leftPic = this.Controls.leftPicture;
        if (!leftPic) return 0;

        return (leftPic.get('left') || 0) + (leftPic.get('width') || 0) + 10;
    };

    public updateLeftToolStripWidth (): void {
        const Controls = this.Controls;
        if (!Controls.leftToolStip?.Controls.leftToolStip) { return; }

        const ts = Controls.leftToolStip.Controls.leftToolStip; ;
        const tsLeft = this.getLeftToolStripX();

        const tsMarginRight = 440;

        // Taken from css class .js-main-top.
        const minWindowW = 1024;
        const windowW = this.width < minWindowW ? minWindowW : this.width;

        let tsW = windowW - tsLeft - tsMarginRight;
        if (tsW < 0) tsW = 0;
        ts.set('width', tsW);
    };

    // public restoreToolstripWorkMode = function ()
    // {
    //     // var itemsObj = this.generateToolstripWorkModeItems();
    //     this.setToolstripItems(
    //         itemsObj.itemsLeft,
    //         itemsObj.itemsRight);

    //     // http://tp.pfsoft.lan/entity/56946
    //     var rightToolstrip = this.Controls.rigthToolStip.Controls.rigthToolStip;
    //     rightToolstrip.set('left', 360);
    // };

    public disconnectClick (): void {
        this.disconnect(false);
    };

    public RemoveAllControls (): void {
        const controls = this.Controls;
        for (const key in controls) {
            const control = controls[key];
            if (control.forceCloseOnLogout) { this.removeControl(control); }
        }
    };

    public async disconnect (isNoNActivity): Promise<void> {
        if (MainWindowManager.ChangePasswordScreen) { MainWindowManager.ChangePasswordScreen.close(); }
        void this.set({ netTraderState: NewNetTraderMode.LoginMode });
        const obj = WorkSpaceManager.getWsDataForSave(WorkSpaceManager.currentWorkspace);
        const name = WorkSpaceManager.currentWorkspace.fileName;
        WorkSpaceManager.stopSaveLoop();
        WorkSpaceManager.clearCurrentWorkSpace();

        if (this.Controls.ticketViewer) { this.Controls.ticketViewer.ClearQueue(); }

        const settingsWait = SessionSettings.saveOnLogout();
        const wsWait = WorkSpaceManager.saveWorkSpaceToIndexedDb(name, obj);

        await Promise.all([settingsWait, wsWait]);

        const finalPart = async (): Promise<void> => {
            if (TradingLockUtils.TradingLock.LockTradingByPassword) {
                TradingLockUtils.TradingLock.LockTradingByPassword = false;
                TradingLockUtils.TradingLock.updateTradingLock(false, true);
            }
            Resources.setNeedForceUpdateLocale();
            WorkSpaceManager.needRestoreWS = false;
            MainWindowManager.MainWindow.StopPingLoop();

            MainWindowManager.MainWindow.RemoveAllControls();

            DataCache.cleanup(); // очистить DCache (в частности отписаться алертами от котировок) надо до App.Connection.disconnect, в противном случае в PFSVendor.prototype.DisconnectProcessPromise произойдет зачистка this.quoteStreams => после чего в PFSVendor.prototype.UnsubscribeSymbol уже не удастся достать котировочный поток для отписки
            Connection.disconnect(isNoNActivity);
            UserWebStorageInstance.clear();
            LinkedSystem.Clear();
            this.onDisconnectCalled.Raise();
            await Promise.resolve(); // новая схема типо
        };

        if (!SessionSettings.executeAfterSaveEnd(finalPart)) { void finalPart(); } // дожидаемся окончания SessionSettings.save() (подробности в обсуждении https://tp.traderevolution.com/entity/90138)
    };

    public aspAdminDisconnect (): void {
        void this.set('netTraderState', NewNetTraderMode.LoginMode);
    // this.restoreToolstripWorkMode();
    };

    public onChangePassLogout (): void {
        Connection.disconnect();
        WorkSpaceManager.clearCurrentWorkSpace();

        void this.set('netTraderState', NewNetTraderMode.LoginMode);
        this.Controls.loginScreen.loadCredentials();
    };

    // public onDisconnectCancelCallback    (showNextTime)
    // {
    //     SessionSettings.updateWarningOnApplicationClose(showNextTime);
    // };

    public localize (): void {
        if (this.Controls) {
            if (this.Controls.loginScreen) { this.Controls.loginScreen.localize(); }

            if (this.Controls.accountWidget?.myAccountDetailsPanel) { this.Controls.accountWidget.myAccountDetailsPanel.localize(); }
        }

        WorkSpaceManager.localize();
        datePickerDropDownHandler.Init();
        multiComboBoxPopupMenuHandler.Init();
        datePickerDropDownHandler.localize();

        void this.set({
        // localkeyAlert: Resources.getResource('screen.Alerts'),
        // localkeyIdeas: Resources.getResource('panel.Ideas'),
        // localkeyNotifications: Resources.getResource('panel.Notifications'),
        // localkeyVideoWidget: Resources.getResource('panel.VideoWidget'),
            localkeyTerminal: Resources.getResource('ribbon.tradingTerminal'),
            localkeyTools: Resources.getResource('ribbon.tools'),
            localkeyMore: Resources.getResource('ribbon.help.help')
        // localkeyHelp: Resources.getResource('ribbon.enviroment.help'),
        // localkeySettings: Resources.getResource('ribbon.enviroment.settings'),
        // localkeyLogout: Resources.getResource('ribbon.enviroment.logout'),
        // localizedSettingsDisabledTT: Resources.getResource('ribbon.enviroment.settings.disabled.tt'),
        // isHelpVisible: !Resources.isHidden("ribbon.enviroment.help")
        });

        this.baseResizibleBTN();
        const menuItemTerminal = this.terminalMenu;
        if (!menuItemTerminal) return;

        for (let i = 0; i < menuItemTerminal.length; i++) {
            const menuItem = menuItemTerminal[i];
            menuItem.text = Resources.getResource(menuItem.locKey);
        }

        const menuItemMore = this.moreMenu;
        if (!menuItemMore) return;

        for (let i = 0; i < menuItemMore.length; i++) {
            this.setMenuItemLocalized(menuItemMore[i]);
        }

        const toolMenu = this.toolsMenu;
        for (let i = 0; i < toolMenu.length; i++) {
            this.setMenuItemLocalized(toolMenu[i]);
        }

        const externalMenuItem = this.get('externalMenuItems');
        const localExternalKeys = []; const externalMenusLeft = []; const externalMenusWidth = [];
        let left = this.Controls.More ? (this.get('leftMore') + this.get('widthMore')) : 0;

        for (let i = 0; i < externalMenuItem.length; i++) {
            const externalMenu = externalMenuItem[i];
            const externalItems = externalMenu.items;
            let menuText = null;
            for (let k = 0; k < externalItems.length; k++) {
                menuText = this.getExternalLinkLocalized(externalItems[k].link, true);
                this.setMenuItemLocalized(externalItems[k]);
            }

            externalMenu.text = menuText || Resources.getResource(externalMenu.locKey);
            localExternalKeys.push(externalMenu.text);

            const w = externalMenu.text.length * 7 + 10;
            externalMenusWidth.push(w);
            externalMenusLeft.push(left);
            left += w;
        }
        void this.set({
            externalMenusWidth,
            externalMenusLeft,
            localkeyExternalMenu: localExternalKeys
        });

        if (this.get('connectedStatus')) {
            void this.set({
                connectedStatusText: Resources.getResource('general.connection.status.Connected'),
                connectedStatusSrc: 'current_theme/dark/darkNew/iconPanel/connected.png'
            });
        } else {
            void this.set({
                connectedStatusText: Resources.getResource('general.connection.status.Reconnecting'),
                connectedStatusSrc: 'current_theme/dark/darkNew/iconPanel/reconnecting.png'
            });
        }
    };

    public setMenuItemLocalized (menuItem): void {
        let toolName = null;
        if (menuItem instanceof ExternalLink) {
            toolName = this.getExternalLinkLocalized(menuItem);
        } else if (menuItem.aliases) {
            const aliases = menuItem.aliases;
            for (let j = 0; j < aliases.length; j++) {
                if (aliases[j].lang === CurrentLang) { toolName = aliases[j].toolName; }
            }
        }
        menuItem.text = toolName || Resources.getResource(menuItem.locKey);
        if (menuItem.icotxt) { menuItem.icotxt = externalLinkManager.getIconAbbrName(menuItem.text); }
    };

    public getExternalLinkLocalized (link: ExternalLink, isTabName = false): string | null {
        if (isTabName) {
            const tabName = link?.getLocalizedTabNameOrNull();
            if (!isNullOrUndefined(tabName)) {
                return tabName;
            }
        }

        const toolName = link?.getLocalizedToolNameOrNull();
        return toolName;
    };

    public themeChange (): void {
        WorkSpaceManager.themeChange();

        if (!BrandingUtils.IsWebHeaderFromBranding()) { void this.set({ leftPictureUrl: ThemeManager.getImageFullUrl('darkNew/b_toolbar/logo.png') }); }
    };

    public setBrandingWebHeader (brandingWebHeaderURL, brandingKey): void {
        const brandingWebHeader = BrandingUtils.GetBrandingWebHeader(brandingWebHeaderURL, this.get('leftPictureUrl'), brandingKey);
        if (brandingWebHeader) { void this.set('leftPictureUrl', brandingWebHeader); }
    };

    // #region WorkSpaceTabStrip

    public initWorkSpaceTabStrip (container): void {
        this.WorkSpaceTabStrip = container;

        WorkSpaceManager.workSpaceListChanged.Subscribe(
            NewNetTrader.populateWorkSpaceTabStrip);

        this.WorkSpaceTabStrip.onTabCreate.Subscribe(
            NewNetTrader.createWorkSpace);

        this.WorkSpaceTabStrip.onTabRemove.Subscribe(
            NewNetTrader.closeWorkSpace);

        this.WorkSpaceTabStrip.onTabRemovePrepare.Subscribe(
            NewNetTrader.closeWorkSpaceByBtn);

        this.WorkSpaceTabStrip.onTabChange.Subscribe(
            NewNetTrader.changeWorkSpace);

        this.WorkSpaceTabStrip.onTabLock.Subscribe(
            NewNetTrader.changeWorkSpaceLockState);

        this.WorkSpaceTabStrip.onTabStripShowMoreActionMenu.Subscribe(
            NewNetTrader.showWorkSpaceMenu);

        this.WorkSpaceTabStrip.onTabStripDoubleClick.Subscribe(
            NewNetTrader.renameCurrentWorkSpace);
    };

    public onMaxWorkspaceCountReached (): void {
        TerceraMessageBox.Show(
            Resources.getResource('workspace.information'),
            Resources.getResource('workspace.numberOfWorkspace'),
            MessageBoxType.Info,
            null,
            null,
            false,
            true);
    };

    static showWorkSpaceMenu (event): void {
        if (Resources.isHidden('allowWorkspaceContextMenu')) { return; }

        contextMenuHandler.Show(
            MainWindowManager.MainWindow.getWorkspaceContextMenu(),
            event.original.clientX,
            event.original.clientY, { width: 210 });
    };

    static populateWorkSpaceTabStrip (): void {
    // TODO.
        if (!WorkSpaceManager.currentWorkspace) { return; }

        const wsList = WorkSpaceManager.wsList;

        const fileNames = Object.keys(wsList);
        // fileNames.sort(NewNetTrader.workSpaceCompare);

        const len = fileNames.length;
        const items = [];
        const oldItems = MainWindowManager.MainWindow.WorkSpaceTabStrip.get('items');
        const curWs = WorkSpaceManager.currentWorkspace;
        const curWsFileName = curWs.fileName;
        const curWsLocked = curWs.locked;

        for (let i = 0; i < len; i++) {
            const curFileName = fileNames[i];
            const it = oldItems[i];
            const newItem = new TerceraTabStripItem();
            newItem.text = wsList[curFileName];
            newItem.data = curFileName;
            newItem.locked = it ? it.locked : (curWsFileName === curFileName && curWsLocked);
            newItem.visible = true;
            items.push(newItem);
        }

        MainWindowManager.MainWindow.WorkSpaceTabStrip.set({
            allowUserEdit: !Resources.isHidden('allowWorkspaceEdit') && !ApplicationInfo.isExploreMode,
            allowMoreActionMenu: !Resources.isHidden('allowWorkspaceContextMenu') && !ApplicationInfo.isExploreMode,
            allowCloseTab: !Resources.isHidden('allowWorkspaceCloseFromButton') && !ApplicationInfo.isExploreMode,
            allowAddNewTab: !Resources.isHidden('allowAddWorkspaceFromButton') && !ApplicationInfo.isExploreMode
        });

        MainWindowManager.MainWindow.WorkSpaceTabStrip.set('items', items);
        MainWindowManager.MainWindow.WorkSpaceTabStrip.selectItem(curWsFileName);

        MainWindowManager.MainWindow.WorkSpaceTabStrip.updateView();
    };

    static saveWorkSpace (): void {
        WorkSpaceManager.saveWorkSpace().then(function () {
            MainWindowManager.MainWindow.Controls.popupBaloon.show(
                TerceraPopupBaloon.States.WorkspaceSaved);
        })
            .catch(function (err) {
                const ex = new CustomErrorClass('TODO popup baloon saving failed', 'NewNetTrader.saveWorkSpace', 'saveWorkSpace -> WorkSpaceManager.saveWorkSpace');
                ErrorInformationStorage.GetException(ex);

                console.error('TODO popup baloon saving failed: ' + err);
            });
    };

    static createWorkSpace (): void {
        WorkSpaceManager.AddWorkSpace();
    };

    static changeWorkSpaceLockState (): void {
        const locked = WorkSpaceManager.toggleLock();

        MainWindowManager.MainWindow.WorkSpaceTabStrip.setLockedStateForSelectedItem(locked);

        MainWindowManager.MainWindow.Controls.popupBaloon.show(
            locked
                ? TerceraPopupBaloon.States.WorkspaceLocked
                : TerceraPopupBaloon.States.WorkspaceUnlocked);

        SoundManager.tryPlaySound(locked
            ? SoundManager.SoundKeys.Lock
            : SoundManager.SoundKeys.Unlock);
    };

    static changeWorkSpace (data): void {
        void WorkSpaceManager.changeWorkSpace(data);
    };

    static closeWorkSpaceByBtn (cb, data): void {
        if (NewNetTrader.needToShowCloseWorkSpaceConfrimation(data)) {
            const header = Resources.getResource('workspace.close_wsp');
            NewNetTrader.closeWorkSpaceShowMsg(
                header,
                header + ' ' + WorkSpaceManager.wsList[data] + '?',
                cb);
        } else cb();
    };

    static closeWorkSpace (data, skipConfirm): void {
        if (NewNetTrader.needToShowCloseWorkSpaceConfrimation(data) && !skipConfirm) {
            const header = Resources.getResource('workspace.close_wsp');
            NewNetTrader.closeWorkSpaceShowMsg(
                header,
                header + ' ' + WorkSpaceManager.wsList[data] + '?',
                WorkSpaceManager.closeWorkSpace.bind(WorkSpaceManager, data));
        } else WorkSpaceManager.closeWorkSpace(data);
    };

    static needToShowCloseWorkSpaceConfrimation (workSpaceName) {
        return WorkSpaceManager.isDirty(workSpaceName);
    };

    static closeWorkSpaceShowMsg (headText, bodyText, callBack): void {
        TerceraMessageBox.Show(
            headText,
            bodyText,
            MessageBoxType.Question,
            callBack,
            null,
            false,
            false,
            '',
            {
                cancelText: Resources.getResource('general.messageBox.no'),
                okText: Resources.getResource('general.messageBox.yes')
            });
    };

    static closeCurrentWorkSpace (): void {
        NewNetTrader.closeWorkSpace(WorkSpaceManager.currentWorkspace.fileName, true);
    };

    static closeAllWorkSpacesButThis (): void {
        const wsCount = Object.keys(WorkSpaceManager.wsList).length;
        if (wsCount <= 1) return;

        TerceraMessageBox.Show(
            Resources.getResource('workspace.close_wsp'),
            Resources.getResource('workspace.menu.CloseAllButThis') + '?',
            MessageBoxType.Question,
            function () { WorkSpaceManager.closeAllWorkSpacesButCurrent(); });
    };

    static clearCurrentWorkSpace (): void {
        if (!WorkSpaceManager.canClearCurrentWorkSpace()) { return; }

        TerceraMessageBox.Show(
            Resources.getResource('workspace.clear_wsp'),
            Resources.getResource('workspace.clear_ask'),
            MessageBoxType.Question,
            function () { WorkSpaceManager.clearAndSaveCurrentWorkSpace(); });
    };

    static renameCurrentWorkSpace (): void {
        const ws = WorkSpaceManager.currentWorkspace;
        if (!ws) return;
        if (ws.locked) {
            TerceraMessageBox.Show(
                Resources.getResource('workspace.newWorkspaceName'),
                Resources.getResource('workspace.At first you should unlock workspace to rename it'),
                MessageBoxType.Info,
                null, null, false, true, '');
            return;
        }
        if (ApplicationInfo.isExploreMode) {
            return;
        }
        const tb = new TerceraTextBox();
        const elementParams = MainWindowManager.MainWindow.WorkSpaceTabStrip.getActiveElementSizeParams();
        const posX = elementParams.offset.left;
        const posY = elementParams.offset.top;
        const widthElement = elementParams.width;

        void tb.set({ left: posX, top: posY, isPosAbsolute: true, width: widthElement, height: 21, text: ws.visibleName, elementStyle: 'js-workspace-rename-screen js-input-container zIndexClass10000' });
        MainWindowManager.MainWindow.addControl(tb);
        tb.setFocus();

        const RenameProcess = (newName) => {
            tb.off(ControlEvents.LostFocus, lf);
            if (newName) { NewNetTrader.changeWorkSpaceName(ws.fileName, newName); }
            popupErrorHandler.Hide(tb);
            MainWindowManager.MainWindow.removeControl(tb);
            tb.dispose();
        };
        const lf = () => {
            const newName = tb.get('text');
            const getError = WorkSpaceManager.checkText(tb, newName);
            RenameProcess(getError ? '' : newName);
        };
        tb.on(ControlEvents.LostFocus, lf);
        tb.on('keyUp', function (event) {
            const newName = tb.get('text');
            const getError = WorkSpaceManager.checkText(tb, newName);
            // TODO: 'keyCode' is deprecated, need to update
            if ((event.original as KeyboardEvent).keyCode === KeyCode.ENTER) {
                if (getError) { RenameProcess(''); } else { RenameProcess(newName); }
            }
        });
    };

    static changeWorkSpaceName (wsFileName, newName): void {
        if (!WorkSpaceManager.tryChangeWorkSpaceVisibleName(wsFileName, newName)) { return; }
        MainWindowManager.MainWindow.WorkSpaceTabStrip.setTabNameByData(wsFileName, newName);
    };

    static cloneCurrentWorkSpace (): void {
        WorkSpaceManager.saveWorkSpace().then(function () {
            WorkSpaceManager.cloneCurrentWorkSpace();
        });
    };

    public getWorkspaceContextMenu () {
        const ws = WorkSpaceManager.currentWorkspace;
        const locked = ws ? ws.locked : false;

        const result = [
        /* {
            text: Resources.getResource('ribbon.enviroment.New'),
            event: NewNetTrader.createWorkSpace
        },
        { separator: true },
        {
            text: Resources.getResource('ribbon.enviroment.Save'),
            event: NewNetTrader.saveWorkSpace
        },
        {
            text: Resources.getResource('workspace.Close'),
            event: NewNetTrader.closeCurrentWorkSpace
        }, */
            /* {
            text: Resources.getResource('workspace.menu.CloseAllButThis'),
            event: NewNetTrader.closeAllWorkSpacesButThis
        },
        {
            text: Resources.getResource('workspace.Clear'),
            event: NewNetTrader.clearCurrentWorkSpace,
            enabled: !locked
        },
        { separator: true }, */
        /* {
            text: Resources.getResource('workspace.menu.Lock'),
            event: NewNetTrader.changeWorkSpaceLockState,
            canCheck: true,
            checked: locked
        } */
        ];

        if (!Resources.isHidden('workspace.Rename')) {
            result.push({
                text: Resources.getResource('workspace.Rename'),
                event: NewNetTrader.renameCurrentWorkSpace,
                enabled: !locked
            });
        }

        if (!Resources.isHidden('workspace.menu.Clone')) {
            result.push({
                text: Resources.getResource('workspace.menu.Clone'),
                event: NewNetTrader.cloneCurrentWorkSpace
            });
        }

        if (!Resources.isHidden('workspace.menu.Reset')) {
            result.push({
                text: Resources.getResource('workspace.menu.Reset'),
                tooltip: Resources.getResource('workspace.menu.Reset.tt'),
                event: WorkSpaceManager.resetCurrentWithDefault.bind(WorkSpaceManager)
            });
        }

        return result;
    };

    // #endregion

    public showGeneralSetting (): void {
        PropertySetupScreen.editProperty(SessionSettings, 'screen.properties.title');
    };

    // public showAlertPanel    ()
    // {
    //     if (!this.alertPanel || this.alertPanel && this.alertPanel.torndown)
    //         this.alertPanel = Factory.addPanel(PanelNames.AlertPanel);

    // let mWindow = $(window);
    // let wWidth = mWindow.width();
    // MainWindowManager.MainWindow.Controls.alerts.set("focused", true);
    // this.alertPanel.set({ left: document.getElementsByName("alerts")[0].offsetLeft, top: TOP_WINDOWS_MARGIN, visible: true });
    // this.alertPanel.setFocus();
    // };

    // public showIdeasPanel    ()
    // {
    //     if (!this.ideasPanel || this.ideasPanel && this.ideasPanel.torndown)
    //         this.ideasPanel = Factory.addPanel(PanelNames.IdeasPanel);

    //     let mWindow = $(window);
    //     let wWidth = mWindow.width();
    //     this.ideasPanel.set({ left: document.getElementsByName("ideas")[0].offsetLeft, top: TOP_WINDOWS_MARGIN, visible: true });
    //     this.ideasPanel.setFocus();
    // };

    // public showNotificationsPanel    ()
    // {
    //     if (!this.notificationsPanel || this.notificationsPanel && this.notificationsPanel.torndown)
    //         this.notificationsPanel = Factory.addPanel(PanelNames.NotificationsPanel)

    // }

    public addAttachedExternalScree (): ApplicationPanel {
        return Factory.addPanel(PanelNames.ExternalScreen);
    };

    // public onVideoWidgetBtnClick    ()
    // {
    //     let vW = this.videoWidget;
    //     let visible = true;
    //     if (!vW || vW && vW.torndown)
    //     {
    //         let oldBroadcastingLiveNowNumber = 0;
    //         if (vW)
    //         {
    //             oldBroadcastingLiveNowNumber = vW.broadcastingLiveNowNumber;
    //             visible = vW.visibilityBeforeTorndown;
    //         }
    //         this.videoWidget = vW = Factory.addPanel(PanelNames.VideoWidget);
    //         vW.broadcastingLiveNowNumber = oldBroadcastingLiveNowNumber;
    //         vW.set({ callerBtn: this.Controls.videoWidgetBtn, cacheReference: DataCache.ExternalLinksCache, externalLinks: DataCache.GetYouTubeExternalLinks() });
    //     }

    //     vW.set({ left: document.getElementsByName("videoWidgetBtn")[0].offsetLeft, top: TOP_WINDOWS_MARGIN, visible: visible });
    //     vW.setFocus();
    // }

    // #endregion

    public showAboutScreen (): void {
        MainWindowManager.MainWindow.addControl(new TerceraAboutScreen());
    };

    public showBrokerInfoScreen (): void {
        MainWindowManager.MainWindow.addControl(new TerceraBrokerInfoScreen());
    };

    public showOnboardingScreen (): void {
        TerceraOnboardingScreen.show();
    };

    public showHomePage (): void {
        window.open(Resources.getResource('homePage.link'));
    };

    public openLiveAccount (): void {
        window.open(Resources.getResource('ribbon.liveAccount.link'));
    };

    public openTradingToolsPanel (): void {
        TradingToolsScreen.show();
    };

    public openAutochartistIFrame (tool): void {
        const method = TradingToolsScreen.getAutochartist_iFrameLink;
        const link = method(tool);

        if (!tool || !link) { return; }

        if (tool.useInternalBrowser) { ExternalScreen.Show({ Text: link, GetRefreshURL: method.bind(null, tool), ClientTab: tool.clientTab, ScreenName: tool.name, Aliases: null, ClientUseInternalBrowser: tool.useInternalBrowser, Tool: tool }, true); } else { window.open(link); }
    };

    public addTradingToolsMenuItem (): void // #87660 вставляет в меню More перед сепаратором пункт Trading tools если есть хотя бы одна линка TCRP или ACRP
    {
        if (!DataCache.ExternalLinksCache.TradingCentralExternalTool && !DataCache.ExternalLinksCache.AutoChartistExternalTool) { return; }

        const menu = this.moreMenu;
        const locKey = 'ribbon.more.tradingTools';
        const menuItem = { style: 'js-list-tradingtools', name: 'tradingtools', text: Resources.getResource(locKey), locKey, event: this.openTradingToolsPanel };

        for (let i = 0; i < menu.length; i++) {
            if (menu[i].separator) {
                menu.splice(i, 0, menuItem);
                return;
            }
        }
    };

    public addBrokerInfoMenuItem (): void // #87238 вставляет в меню More перед About вызов экрана BrokerInfo
    {
        if (!DataCache.BrokerInformation) { return; }

        const menu = this.moreMenu;
        const locKey = 'ribbon.more.brokerInfo';
        const menuItem = { style: 'js-list-brokerinfo', name: 'brokerinfo', text: Resources.getResource(locKey), locKey, event: this.showBrokerInfoScreen };

        for (let i = 0; i < menu.length; i++) {
            if (menu[i].name === 'about') {
                menu.splice(i, 0, menuItem);
                return;
            }
        }
    };

    public recreateMainMenu (): void {
        this.createMoreMenu();

        this.set('externalMenuItems', []);

        this.createToolsMenu();
    };

    public navigateToKnowledgeBase (): void {
        const kbDiv = document.getElementById('kb');
        if (!kbDiv) return;

        const kbUrl = kbDiv.dataset.kb;
        if (!kbUrl) return;

        const win = window.open(kbUrl, '_blank');
        if (win) win.focus();
    };

    public InitilizeThread (): void {
        GlobalAnimationFrameHandler();
    };

    public onContextMenuOpen (event): boolean {
        if (this.get('netTraderState') !== NewNetTraderMode.WorkMode) { return false; }
        if (!event.original.path) { return false; }
        const firstNodeInEventPath = event.original.path[0];
        if (!firstNodeInEventPath || firstNodeInEventPath.className !== 'tab-content') { return false; }

        // NewNetTrader.showWorkSpaceMenu(event);
        return false;
    };

    private async loadBrandingRules (brandingKey: string): Promise<void> {
        await DataCache.GetBrandingRules(brandingKey);
        this.addBrokerInfoMenuItem();
        this.Controls.WorkSpacesList.setPoweredByTextAndLink(DataCache.PoweredByName, DataCache.PoweredByURL);
    }

    public async LoadingStarted (loginScreenLang): Promise<void> {
    // TODO  84288 84326
    // Подумать над правильным удалением панелей и чистке предыдущих кешей

        const brandingKey = Connection.vendor.loginParams.brandingKey; //  #86122 #85820 #86776 #87660 #87238
        this.recreateMainMenu();
        if (isValidString(brandingKey)) {
            void this.loadBrandingRules(brandingKey);
        }

        WorkSpaceManager.currentWorkspace = null;
        DockSystemInstance.clearDocking();
        const DCWaiter = DataCache.StartLoadingWaiting();

        const settingsJson = await SessionSettings.load();
        const startWSLoadig = WorkSpaceManager.initWorkspaces();
        await DCWaiter;
        await SessionSettings.afterLoad(settingsJson, loginScreenLang);
        await SessionSettings.RestoreSettings(settingsJson, loginScreenLang);
        const result = await startWSLoadig;
        const [loaded, activeName, activeWsLoadingHandler] = result;

        const finishHandler = async () => {
            WorkSpaceManager.getNewCurrentWs(activeName);
            await WorkSpaceManager.RestoreWorkspaces();
        };

        if (!isNullOrUndefined(activeWsLoadingHandler)) {
            await activeWsLoadingHandler;
        }

        await finishHandler();
        this.StartTimeoutCounter(DataCache.getUserSessionTimeout());
        MainWindowManager.MainWindow.set('netTraderState', NewNetTraderMode.WorkMode);
        if (MainWindowManager.MainWindow.NeedRestoreLockTradingByPassword && TradingLockUtils.TradingLock.LockTradingByPassword) {
            MainWindowManager.MainWindow.NeedRestoreLockTradingByPassword = false;
            TradingLockUtils.TradingLock.LockTradingByPassword = false;
            TradingLockUtils.TradingLock.updateTradingLock(false);
        }
        WorkSpaceManager.startSaveLoop();
        MainWindowManager.MainWindow.StartPingLoop();
        WorkSpaceManager.needRestoreWS = false;
        if (!SessionSettings.OnBoardingWasShown()) { TerceraOnboardingScreen.show(); } else { TerceraOnboardingScreen.ReleaseSubscription(); }

        MainWindowManager.MainWindow.sendSettingsLoggingSnapshotIfNeed();
    };

    public StartPingLoop (): void {
        const loop = function () {
            Connection.SendPing().then(function (value) {
                MainWindowManager.MainWindow.set({ pingText: value.toFixed(0) + ' ms' });
            });
        };
        loop();
        this.pingInterval = setInterval(loop, 10000);
    };

    public StopPingLoop (): void {
        clearInterval(this.pingInterval);
    };

    public ShowSymbolInfo (instrument, account, onCloseCallback/*, coordinates */): void {
        if (!instrument || instrument.isEmpty) { return; }

        let symbolInfo = this.mySymbolInfo;

        if (!symbolInfo || symbolInfo?.torndown) {
            symbolInfo = Factory.addPanel(PanelNames.SymbolInfoPanel, null);
        }

        symbolInfo.symbolLink_In(instrument.GetInteriorID());
        if (account != null) { symbolInfo.set('account', account); }

        // if (coordinates)
        // {
        // let newCoord = TerceraMenu.CorrectPopupLocation(new Rectangle(coordinates.X + 15, coordinates.Y - 50, 350, 365), null);
        const newCoord = {
            newX: (window.innerWidth - SymbolInfoPanel.PANEL_WIDTH) / 2,
            newY: (window.innerHeight - SymbolInfoPanel.PANEL_HEIGHT) / 2
        };

        symbolInfo.set({ left: newCoord.newX, top: newCoord.newY });

        const onClose = () => {
            if (onCloseCallback != null) { onCloseCallback(); }
            symbolInfo.OnClose.UnSubscribe(onClose, this);
        };
        symbolInfo.OnClose.Subscribe(onClose, this);
    // }
    };

    public ShowOrderEntry (instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }
        const orderEntry = Factory.addPanel(PanelNames.AdvancedOrderEntry, null);
        orderEntry.symbolLink_In(instrument.GetInteriorID());
    };

    public ShowChart (instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }
        const orderEntry = Factory.addPanel(PanelNames.ChartPanel, null);
        orderEntry.symbolLink_In(instrument.GetInteriorID());
    };

    // public initIdeasPanel    ()
    // {
    //     let ideasVisible = CustomerAccess.GetDisplayMode(PanelNames.IdeasPanel) === PanelDisplayMode.Allowed && !Resources.isHidden("panel.Ideas")
    //     this.set('ideasBtnVisible', ideasVisible)
    //     if (ideasVisible)
    //         DataCache.SendTradingSignalRequest()
    // }

    // public initNotificationsPanel    ()
    // {
    //     let notificationsVisible =
    //         CustomerAccess.GetDisplayMode(PanelNames.NotificationsPanel) === PanelDisplayMode.Allowed
    //         && !Resources.isHidden("panel.Notifications")

    //     this.set('notificationsBtnVisible', notificationsVisible)
    // }

    // public initVideoWidget    ()
    // {
    //     const videoWidgetVisible = CustomerAccess.GetDisplayMode(PanelNames.VideoWidget) === PanelDisplayMode.Allowed
    //         && !Resources.isHidden("panel.VideoWidget");

    //     this.set('videoWidgetBtnVisible', videoWidgetVisible);

    //     if (!videoWidgetVisible)
    //         return

    //     const videoWidgetBtn = this.Controls.videoWidgetBtn;
    //     if (videoWidgetBtn)
    //         DataCache.ExternalLinksCache.UpdateYouTubeLIVECounter.Subscribe(videoWidgetBtn.ChangeBlinkStatus, videoWidgetBtn);
    // }

    public createToolsMenu (): void {
        this.toolsMenu = [];

        if (this.toolsMenu.length === 0) {
            this.set('toolsMenuVisible', false);
            this.updateToolstrip();
        }
    };

    public createMoreMenu (): void {
        this.moreMenu = [];
        if (!Resources.isHidden('ribbon.help.home')) { this.moreMenu.push({ style: 'js-list-homepage', name: 'homepage', locKey: 'ribbon.help.home', event: this.showHomePage }); }
        if (Resources.IsResourcePresent('ribbon.more.liveaccount')) { this.moreMenu.push({ style: 'js-list-liveaccount', name: 'liveaccount', locKey: 'ribbon.more.liveaccount', event: this.openLiveAccount }); }
        this.moreMenu.push({ separator: true });
        if (!Resources.isHidden('ribbon.help.onboarding')) { this.moreMenu.push({ style: 'js-list-onboarding', name: 'onboarding', locKey: 'ribbon.help.onboarding', event: this.showOnboardingScreen.bind(this) }); }
        if (!Resources.isHidden('ribbon.help.about')) { this.moreMenu.push({ style: 'js-list-about', name: 'about', locKey: 'ribbon.help.about', event: this.showAboutScreen.bind(this) }); }
    };

    public getExternalAccountMenuItems () {
        const result = [];
        const links = DataCache.ExternalLinksCache.AllLinks;

        for (const i in links) {
            const link = links[i];

            if (link.HasTabSetting() && link.clientTab === 'Account') {
                const toolName = link.getLocalizedToolNameOrNull() || link.name;

                result.push({
                    name: 'externaltool',
                    text: toolName,
                    style: 'js-list-externallink-icon',
                    icotxt: externalLinkManager.getIconAbbrName(toolName),
                    event: link.GetEventOnClick(this),
                    link
                });
            }
        }

        return result;
    };

    public addExternalMenuItemsLinks (): void {
        const tools = DataCache.ExternalLinksCache.AllLinks;

        for (const i in tools) {
            const tool = tools[i];
            this.ExternalScreenStorage.AddItem(tool);
        }
    };

    public addExternalMenuItems (): void {
        const tools = DataCache.ExternalLinksCache.AllLinks;
        let needUpdateToolStrip = false;

        for (const i in tools) {
            const tool = tools[i];
            needUpdateToolStrip = this.addExternalMenuItem(tool) || needUpdateToolStrip;
        }

        if (needUpdateToolStrip) { this.updateToolstrip(); }
    };

    static insertMenuItemInOrderById (menu, item): void // #103169
    {
        if (!menu || !item) return;

        for (let i = 0; i < menu.length; i++) {
            const id = menu[i] ? menu[i].id : null;
            if (id && item.id < id) {
                menu.splice(i, 0, item);
                return;
            }
        }

        menu.push(item);
    };

    public addExternalMenuItem (link: ExternalLink) {
        let needUpdateToolStrip = false;

        if (!link) { return needUpdateToolStrip; }

        this.ExternalScreenStorage.AddItem(link);

        if (link.HasTabSetting()) {
            const eventF = link.GetEventOnClick(this);
            const icoTxt = externalLinkManager.getIconAbbrName(this.getExternalLinkLocalized(link) || link.name);
            const menuItem = { name: 'externaltool', style: 'js-list-externallink-icon', icotxt: icoTxt, id: link.id, text: link.name, locKey: link.name, aliases: link.getAliases(), event: eventF, link };

            if (link.clientTab === 'Tools') {
                NewNetTrader.insertMenuItemInOrderById(this.toolsMenu, menuItem);

                this.set('toolsMenuVisible', true);
                needUpdateToolStrip = true;
            } else if (link.clientTab === 'More' || link.clientTab === 'Help') {
                NewNetTrader.insertMenuItemInOrderById(this.moreMenu, menuItem);
            } else if (link.clientTab !== 'Account') {
                const externalMenu = this.get('externalMenuItems');
                const existMenu = externalLinkManager.getExternalMenu(externalMenu, link);

                existMenu
                    ? NewNetTrader.insertMenuItemInOrderById(existMenu.items, menuItem)
                    : externalMenu.push({ name: 'externaltool', id: link.id, text: link.clientTab, locKey: link.clientTab, items: [menuItem] });

                needUpdateToolStrip = true;

                this.set('externalMenuItems', externalMenu);
            }

            this.localize();
        }

        return needUpdateToolStrip;
    };

    public updateExternalMenuItem (link: ExternalLink): void {
        this.deleteExternalMenuItem(link, true);
        this.addExternalMenuItem(link);
    };

    public deleteExternalMenuItem (link: ExternalLink, update): void {
        if (update) { this.ExternalScreenStorage.UpdateLinkItem(link); } else { this.ExternalScreenStorage.DeleteItem(link); }

        for (let i = 0; i < this.toolsMenu.length; i++) {
            if (this.toolsMenu[i].id == link.id) {
                this.toolsMenu.splice(i, 1);
                this.updateToolstrip();
                if (this.toolsMenu.length === 0) { this.set('toolsMenuVisible', false); }
                return;
            }
        }

        for (let i = 0; i < this.moreMenu.length; i++) {
            if (this.moreMenu[i].id == link.id) {
                this.moreMenu.splice(i, 1);
                this.updateToolstrip();
                return;
            }
        }

        const externalMenu = this.get('externalMenuItems'); let existMenu = null; let jIndex = null;
        for (let j = 0; j < externalMenu.length; j++) {
            if (externalMenu[j].items) {
                const items = externalMenu[j].items;
                for (let q = 0; q < items.length; q++) {
                    if (items[q].id == link.id) {
                        jIndex = j;
                        existMenu = externalMenu[j];
                    }
                }
            }
        }

        const container = existMenu ? existMenu.items : externalMenu;
        for (let i = 0; i < container.length; i++) {
            if (container[i].id == link.id) {
                if (existMenu) { existMenu.items.splice(i, 1); } else { externalMenu.splice(i, 1); }

                if (!container.length && jIndex != null) { externalMenu.splice(jIndex, 1); }

                this.set('externalMenuItems', externalMenu);
                this.updateToolstrip();
                this.localize();
                return;
            }
        }
    };

    static NEWS_MENU_ITEM;

    public addToolsMenuItems (): void {
        let atLeastOne = false;

        if (CustomerAccess.GetDisplayMode(PanelNames.MarketConsensusPanel) === PanelDisplayMode.Allowed && !Resources.isHidden('panel.AlgorithmMarketConsensusPanel')) {
            this.toolsMenu.push({ style: 'js-Algorithm-MarketConsensus', name: PanelNames.MarketConsensusPanel, locKey: 'panel.AlgorithmMarketConsensusPanel', event: function (sender) { Factory.addPanel(sender.type); }, type: PanelNames.MarketConsensusPanel });
            atLeastOne = true;
        }

        if (CustomerAccess.GetDisplayMode(PanelNames.HighLowPanel) === PanelDisplayMode.Allowed && !Resources.isHidden('panel.HighLowPanel')) {
            this.toolsMenu.push({ style: 'js-Algorithm-HighLow', name: PanelNames.HighLowPanel, locKey: 'panel.HighLowPanel', event: function (sender) { Factory.addPanel(sender.type); }, type: PanelNames.HighLowPanel });
            atLeastOne = true;
        }

        if (CustomerAccess.GetDisplayMode(PanelNames.GainersLosersPanel) === PanelDisplayMode.Allowed && !Resources.isHidden('panel.GainersLosersPanel')) {
            this.toolsMenu.push({ style: 'js-Algorithm-GainersLosers', name: PanelNames.GainersLosersPanel, locKey: 'panel.GainersLosersPanel', event: function (sender) { Factory.addPanel(sender.type); }, type: PanelNames.GainersLosersPanel });
            atLeastOne = true;
        }

        if (CustomerAccess.GetDisplayMode(PanelNames.TradingCentral) === PanelDisplayMode.Allowed && !Resources.isHidden('ribbon.tradingTerminal.tradingCentral')) {
            this.toolsMenu.push({ style: 'js-list-tradingcentral', name: 'tradingcentral', locKey: 'panel.tradingCentral', event: function (sender) { Factory.addPanel(sender.type); }, type: PanelNames.TradingCentral });
            atLeastOne = true;
        }

        if (CustomerAccess.GetDisplayMode(PanelNames.HeatmapPanel) === PanelDisplayMode.Allowed && !Resources.isHidden('panel.HeatmapPanel')) {
            this.toolsMenu.push({ style: 'js-list-heatmap', name: 'heatmap', locKey: 'panel.HeatmapPanel', event: function (sender) { Factory.addPanel(sender.type); }, type: PanelNames.HeatmapPanel });
            atLeastOne = true;
        }

        if (CustomerAccess.GetDisplayMode(PanelNames.NewsPanel) === PanelDisplayMode.Allowed && !Resources.isHidden('ribbon.tradingTerminal.news')) {
            if (!NewNetTrader.NEWS_MENU_ITEM) { NewNetTrader.NEWS_MENU_ITEM = { style: 'js-list-news', name: 'news', locKey: 'panel.news', event: function (sender) { Factory.addPanel(sender.type); }, type: PanelNames.NewsPanel }; }

            const menuItem = NewNetTrader.NEWS_MENU_ITEM;

            if (!this.toolsMenu.includes(menuItem)) // #100159
            { this.toolsMenu.push(menuItem); }

            atLeastOne = true;
        }

        if (atLeastOne) { this.set('toolsMenuVisible', true); }
    };

    public sendSettingsLoggingSnapshotIfNeed (): void {
        const sess = SessionSettings;
        if (sess.LogSnapshotHasBeenSent()) { return; }

        const props = sess.Properties();
        SettingsLoggingManager.LogChange(props, props, true);
    };

    public OnLockTradingClick (): void {
        if (TradingLockUtils.TradingLock.LockTradingByPassword) { this.addControl(new TerceraTradingPassword()); } else { TradingLockUtils.TradingLock.updateTradingLock(!TradingLockUtils.TradingLock.tradingLocked); }
    };

    public TimeoutEventsHandler (): void {
        const w = MainWindowManager.MainWindow;
        if (w.TimeoutCounterCycle) {
            clearTimeout(w.TimeoutCounterCycle);
            if (w.TimeoutCounterCycleTime !== -1) { w.TimeoutCounterCycle = setTimeout(w.TimeoutMessage.bind(w), w.TimeoutCounterCycleTime); }
        }
    };

    public TimeoutMessage (): void {
        if (GeneralSettings.General.DisableInactivityPeriod || !Connection.isConnectionCorrect()) {
            this.TimeoutEventsHandler();
            return;
        }

        const scr = TerceraTimeoutMessageBox.Show();
        scr.OK_Handler.then(this.disconnect.bind(this, true));
        scr.NO_Handler.then(this.TimeoutEventsHandler.bind(this));
        this.NonFocusedAttraction();
    };

    public StartTimeoutCounter (time): void {
        if (time === -1 || isNaN(time) || !time) { return; }

        document.addEventListener('keydown', this.TimeoutEventsHandler);
        document.addEventListener('mousedown', this.TimeoutEventsHandler);

        this.TimeoutCounterCycleTime = 1000 * 60 * time;
        this.TimeoutCounterCycle = setTimeout(this.TimeoutMessage.bind(this), this.TimeoutCounterCycleTime);
    };

    public StopTimeoutCounter (): void {
        document.removeEventListener('keydown', this.TimeoutEventsHandler);
        document.removeEventListener('mousedown', this.TimeoutEventsHandler);

        this.TimeoutCounterCycleTime = -1;
        if (this.TimeoutCounterCycle) { clearTimeout(this.TimeoutCounterCycle); }
    };

    public NonFocusedAttraction (): void {
        const title = document.title;
        const symbol = '\u200E';
        const z = () => {
            if (document.hasFocus()) {
                document.title = title;
                if (this.NonFocusedAttractionLoop) { clearInterval(this.NonFocusedAttractionLoop); }
                return;
            }

            if (document.title !== symbol) { document.title = symbol; } else { document.title = title; }
        };
        if (this.NonFocusedAttractionLoop) { clearInterval(this.NonFocusedAttractionLoop); }
        this.NonFocusedAttractionLoop = setInterval(z.bind(this), 300);
    };

    public GetAvaliableHeight () {
        return this.height - TOP_WINDOWS_MARGIN - BOTTOM_WINDOWS_MARGIN;
    };

    public GetFirstControlByType (type) // по типу панели или скрина ищет первое вхождение сперва в контролах mainWindow затем в панелях текущего воркспейса, удобно для дебага
    {
        const controls = this.Controls;
        for (const prop in controls) {
            const el = controls[prop];
            if (el?.getType && el.getType() == type) { return el; }
        }

        const panels = WorkSpaceManager?.currentWorkspace?.allPanels ? WorkSpaceManager.currentWorkspace.allPanels : null;
        for (const prop in panels) {
            const el = panels[prop];
            if (el?.getType && el.getType() == type) { return el; }
        }

        return null;
    };
}

MainWindowBase.extendWith(NewNetTrader, {
    el: '#mainWindow',
    template: NewNetTraderTemplate,
    data: function () {
        return {
            movable: false,
            showHeader: false,
            showFooter: false,
            resizable: false,
            canSelect: false,
            leftPictureUrl: '',
            rightPictureUrl: '',
            netTraderState: 'LM',
            inValue: 0,
            outValue: 0,
            currentTime: '10:57:38',
            currentTimeZoneOffset: '(UTC+02:00)',
            maxWorkspaceCount: 0,
            connectedStatus: false,
            pingText: '...',
            tradingLocked: false,
            externalMenuItems: [],
            externalMenusLeft: [],
            externalMenusWidth: [],
            // alertsBtnVisible: true,
            toolsMenuVisible: false,
            // ideasBtnVisible: false,
            // notificationsBtnVisible: false,
            // videoWidgetBtnVisible: false,

            leftTerminal: 0,
            widthTerminal: 0,
            leftTools: 0,
            widthTools: 0,
            leftMore: 0,
            widthMore: 0
        };
    }
});

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Rectangle } from '../../Commons/Geometry';
import { DrawPointer, DrawPointerTypeEnum } from '../Utils/DrawPointer';
import { HistoryType } from '../../Utils/History/HistoryType';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { Color, type Font, Line, Pen, PolyLine, PolyRect, SolidBrush } from '../../Commons/Graphics';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { CashItem } from '../../Commons/cache/History/CashItem';
import { ColorStyleWidth, DynProperty, DynPropertyRelationType } from '../../Commons/DynProperty';
import { TerceraChartDrawingType } from '../Utils/ChartConstants';
import { Resources } from '../../Commons/properties/Resources';
import { VolumeColoringMode } from '../../Utils/Volume/VolumeConstantsAndEnums';
import { type MovingAverageMapType, type IVolumeBarsMovingAverage } from '../../Utils/Volume/IVolumeBarsMovingAverage';
import { VolumeBarsMovingAverage } from '../../Utils/Volume/VolumeBarsMovingAverage';
import { type TerceraChartCashItemSeries } from '../Series/TerceraChartCashItemSeries';
import { ControlsUtils } from '../../Controls/UtilsClasses/ControlsUtils';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type TerceraChartCashItemSeriesSettings } from '../Series/TerceraChartCashItemSeriesSettings';
import { type TerceraChart } from '../TerceraChart';

export class TerceraChartVolumeBarsRenderer extends TerceraChartBaseRenderer<TerceraChart> {
    constructor (private readonly cashSettings: TerceraChartCashItemSeriesSettings, chart: TerceraChart) {
        super(chart);
        this.cashSettings = cashSettings;
        this.SetClassName('TerceraChartVolumeBarsRenderer');
    }

    private subscribeHistoryExpanded: boolean = false;
    private showVolumeMarker: boolean = true;
    private readonly barLabelPaddingX: number = 8;
    private readonly barLabelPaddingY: number = 4;
    private VolumeHeightPercent: number = 30;
    private VolumeColoringMode: VolumeColoringMode = VolumeColoringMode.Fixed;
    private readonly volumeFixedBrush: SolidBrush = new SolidBrush(Color.Empty);
    private readonly volumeUpBrush: SolidBrush = new SolidBrush(Color.Empty);
    private readonly volumeDownBrush: SolidBrush = new SolidBrush(Color.Empty);
    private readonly volumeBarFontBrush: SolidBrush = new SolidBrush(Color.Empty);
    private readonly volumeBarFont: Font = ThemeManager.Fonts.Font_10F_regular;
    private maxVol: number = Number.NEGATIVE_INFINITY;

    private macAbortController: AbortController | null = null;
    private readonly movingAverageCalculator: IVolumeBarsMovingAverage = new VolumeBarsMovingAverage();
    private readonly movingAverageLine: ColorStyleWidth = new ColorStyleWidth(ThemeManager.CurrentTheme.Chart_VolumeMovingAverageLineColor, Pen.csSimpleChart, 1);
    private movingAveragePen: Pen = new Pen(this.movingAverageLine.Color, this.movingAverageLine.Width, this.movingAverageLine.Style);
    private movingAverageActive: boolean = false;
    private movingAveragePeriod: number = 21;
    private movingAverageMap: MovingAverageMapType;

    getInstrument = (): Instrument => this.chart.Instrument();

    get Visible (): boolean { return this.cashSettings?.showVolume ?? false; }
    set Visible (value: boolean) {
        super.Visible = value;
        if (this.cashSettings != null) { this.cashSettings.showVolume = value; }
    }

    SetMovingAveragePeriod (period: number): void {
        this.movingAveragePeriod = period;
        this.movingAverageCalculator.setMovingAveragePeriod(period);

        if (this.subscribeHistoryExpanded) { this.RecalcMovingAverageMap(); }
    }

    NoVolumeBarsData = (): boolean => this.maxVol <= 0;

    VolumeBarFontColorGet = (): string => this.volumeBarFontBrush.Color;
    VolumeBarFontColorSet = (value: string): void => { this.volumeBarFontBrush.Color = value; };

    IsNeedDraw = (numberOfLayer: number): boolean => this.assignLayer === numberOfLayer || LayersEnum.Quotes === numberOfLayer;

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null): void {
        if (!this.Visible) { return; }

        const param = advParams;

        if (param?.TerceraChart == null) { return; }
        const isQuotesLayer = param.layerId === LayersEnum.Quotes;
        const chartDrawingType = param.TerceraChart.model.chartDrawingType;

        if (param.TerceraChart.TimeFrameInfo().Periods === 0 && param.TerceraChart.TimeFrameInfo().HistoryType !== HistoryType.LAST) { return; }

        if (chartDrawingType === TerceraChartDrawingType.LinesBreak || chartDrawingType === TerceraChartDrawingType.Renko ||
            chartDrawingType === TerceraChartDrawingType.Kagi || chartDrawingType === TerceraChartDrawingType.TicTac) { return; }

        const cashItemSeries = param.TerceraChart.MainCashItemSeries();

        this.SubscribeRecalcMovingAverage(); // one-time subscription

        const clientRect = window.ClientRectangle.copy();
        const screenData = cashItemSeries.ChartScreenData.Storage;

        gr.save();
        gr.beginPath();
        gr.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
        gr.clip();

        this.maxVol = Number.NEGATIVE_INFINITY;
        for (let kk = 0; kk < screenData.length; kk++) {
            if (screenData[kk].Volume > this.maxVol) { this.maxVol = screenData[kk].Volume; }
        }

        if (this.NoVolumeBarsData()) {
            gr.restore();
            return;
        }

        const scX = window.XScale;
        const barWidth = window.XScale;
        const lastVolumeK = (clientRect.Height * this.VolumeHeightPercent / 100) / this.maxVol;

        let curX = clientRect.X + clientRect.Width - scX;

        const brushesPolyMap: Record<string, PolyLine | PolyRect> = {};
        const clientBottom = clientRect.Y + clientRect.Height;
        const volumeWidth: number = barWidth > 1 ? barWidth - 1 : 1;
        const volumeBarIsALine: boolean = false; // volumeWidth === 1; // Need remove the logic related to volumeBarIsALine due to bug #123477.
        const polyPropName = volumeBarIsALine ? 'lines' : 'rects';
        const drawPolyMethodName = volumeBarIsALine ? 'DrawPolyLine' : 'DrawPolyRect';
        const volumeBarsLabels: VolumeBarLabel[] = [];
        const movingAverageLines = new PolyLine();

        for (let i = screenData.length - 1; i >= 0; i--) {
            if (screenData[i].NotShowExtendedSession === true) {
                curX -= scX;
                continue;
            }

            const currentVolume = screenData[i].Volume;
            const nextVolume = i > 0 ? screenData[i - 1].Volume : 0;

            const yy = (clientBottom - Math.abs(currentVolume) * lastVolumeK);

            const barHeight = clientBottom - yy;
            if (barHeight === 0) {
                curX -= scX;
                continue;
            }

            let movingAverageValue: number = 0;
            if (this.movingAverageActive || this.VolumeColoringMode === VolumeColoringMode.ByMovingAverage) {
                movingAverageValue = this.movingAverageMap?.get(screenData[i].BaseIntervalIndex) ?? 0;
            }

            if (this.movingAverageActive) {
                const prevIndex = i > 0 ? i - 1 : 0;
                const prevValue = this.movingAverageMap?.get(screenData[prevIndex].BaseIntervalIndex) ?? 0;

                movingAverageLines.lines.push(new Line(
                    curX - barWidth / 2,
                    clientBottom - prevValue * lastVolumeK,
                    curX + barWidth / 2,
                    clientBottom - movingAverageValue * lastVolumeK
                ));
            }

            let lineOrRect: any; // Line | Rectangle;
            if (volumeBarIsALine) {
                lineOrRect = new Line(curX, clientBottom, curX, clientBottom - barHeight);
            } else {
                lineOrRect = new Rectangle(curX, yy, volumeWidth, barHeight);
            }

            if (!volumeBarIsALine) { volumeBarsLabels.push({ bar: lineOrRect, volume: currentVolume }); }

            const currentBrushPropName: string = this.GetCurBrushPropName(screenData[i].Open, screenData[i].Close, nextVolume, currentVolume, movingAverageValue);
            if (!(currentBrushPropName in brushesPolyMap)) {
                brushesPolyMap[currentBrushPropName] = new (volumeBarIsALine ? PolyLine : PolyRect)();
            }

            brushesPolyMap[currentBrushPropName][polyPropName].push(lineOrRect);

            curX -= scX;
            if (isQuotesLayer) { break; }
        }

        for (const brushPropName in brushesPolyMap) {
            gr[drawPolyMethodName](this[brushPropName], brushesPolyMap[brushPropName]);
        }

        if (this.movingAveragePen != null && this.movingAverageActive) {
            gr.DrawPolyLine(this.movingAveragePen, movingAverageLines);
        }

        this.RecalcMovingAverageMap();

        this.DrawVolumeMarker(isQuotesLayer, cashItemSeries, clientBottom, lastVolumeK, param);

        this.DrawLabels(gr, volumeBarsLabels);

        gr.restore();
    }

    DrawLabels (gr, volumeBarsLabels: VolumeBarLabel[]): void {
        volumeBarsLabels.forEach(({ bar, volume }) => {
            const strVolume = InstrumentUtils.getFormatVolume(this.getInstrument(), volume);
            const size = ControlsUtils.GetTextWidth(strVolume, this.volumeBarFont);

            if (size + this.barLabelPaddingX <= bar.Width && this.volumeBarFont.Height + this.barLabelPaddingY <= bar.Height) {
                const x = bar.X + bar.Width / 2 - size / 2;
                const y = bar.Y + bar.Height / 2 - this.barLabelPaddingY;
                gr.DrawString(strVolume, this.volumeBarFont, this.volumeBarFontBrush, x, y);
            }
        });
    }

    DrawVolumeMarker (isQuotesLayer: boolean, cashItemSeries, clientBottom: number, lastVolumeK: number, advParams): void {
        const barIndex = cashItemSeries.Count() - 1; // last bar
        if (barIndex >= 0 && this.showVolumeMarker && isQuotesLayer) {
            const value = cashItemSeries.GetValue(barIndex, CashItem.VOLUME_INDEX);
            if (advParams != null) {
                const text = InstrumentUtils.getFormatVolume(this.getInstrument(), value);
                let yy = (clientBottom - Math.abs(value) * lastVolumeK);

                if (clientBottom - yy < 6) { yy = clientBottom - 6; }

                advParams.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.Indicator, NaN, this.GetCurrentBarBrush(cashItemSeries), text, undefined, undefined, undefined, yy));
            }
        }
    }

    GetCurrentBarBrush (cashItemSeries): SolidBrush {
        const barIndex = cashItemSeries.Count() - 1;
        const curVolume = cashItemSeries.GetValue(barIndex, CashItem.VOLUME_INDEX);
        const prevBarVolume = this.VolumeColoringMode === VolumeColoringMode.ByDifference ? cashItemSeries.GetValue(barIndex - 1, CashItem.VOLUME_INDEX) : null;

        const needOpenAndClose = this.VolumeColoringMode === VolumeColoringMode.ByBar;
        const curOpen = needOpenAndClose ? cashItemSeries.GetValue(barIndex, CashItem.OPEN_INDEX) : null;
        const curClose = needOpenAndClose ? cashItemSeries.GetValue(barIndex, CashItem.CLOSE_INDEX) : null;

        const needMovingAverage = this.VolumeColoringMode === VolumeColoringMode.ByMovingAverage;
        const MAValue = needMovingAverage ? this.movingAverageMap?.get(barIndex) ?? 0 : null;

        return this[this.GetCurBrushPropName(curOpen, curClose, prevBarVolume, curVolume, MAValue)];
    }

    GetCurBrushPropName (open: number, close: number, nextVolume: number, currentVolume: number, movingAverageValue): string {
        let current = 'volumeFixedBrush';
        if (this.VolumeColoringMode === VolumeColoringMode.Fixed) {
            // Do nothing, use the fixed brush
        } else if (this.VolumeColoringMode === VolumeColoringMode.ByBar) {
            if (open > close) { current = 'volumeDownBrush'; } else if (open < close) { current = 'volumeUpBrush'; }
        } else if (this.VolumeColoringMode === VolumeColoringMode.ByDifference) {
            if (currentVolume > nextVolume) { current = 'volumeUpBrush'; } else if (currentVolume < nextVolume) { current = 'volumeDownBrush'; }
        } else if (this.VolumeColoringMode === VolumeColoringMode.ByMovingAverage) {
            if (currentVolume > movingAverageValue) { current = 'volumeUpBrush'; } else if (currentVolume < movingAverageValue) { current = 'volumeDownBrush'; }
        }
        return current;
    }

    SubscribeRecalcMovingAverage (): void {
        const cashItemSeries: TerceraChartCashItemSeries = this.chart?.MainCashItemSeries();
        if (!this.subscribeHistoryExpanded) {
            cashItemSeries?.HistoryExpanded.Subscribe(this.RecalcMovingAverageMap, this);
            this.subscribeHistoryExpanded = true;
            this.RecalcMovingAverageMap();
        }
    }

    ThemeChanged (): void {
        super.ThemeChanged();

        this.volumeFixedBrush.Color = ThemeManager.CurrentTheme.Chart_VolumeFixedColor;
        this.volumeUpBrush.Color = ThemeManager.CurrentTheme.Chart_VolumeUpColor;
        this.volumeDownBrush.Color = ThemeManager.CurrentTheme.Chart_VolumeDownColor;

        this.VolumeBarFontColorSet(ThemeManager.CurrentTheme.Chart_VolumeBarFontColor);

        Pen.ProcessPen(this.movingAveragePen, this.movingAverageLine.Style);
    }

    RecalcMovingAverageMap (): void {
        this.macAbortController?.abort();

        this.macAbortController = new AbortController();
        const signal = this.macAbortController.signal;

        this.movingAverageCalculator.calculateMovingAverage(this.chart?.MainCashItemSeries(), signal)
            .then((movingAverageMap: MovingAverageMapType) => {
                if (movingAverageMap !== null) {
                    this.movingAverageMap = movingAverageMap;
                } else {
                // The calculation was aborted, handle accordingly (if needed)
                    console.log('Moving Average Calculation Aborted');
                }
            })
            .catch((error) => {
                console.error('Error during moving average calculation:', error);
            });
    }

    Dispose (): void {
        const cashItemSeries: TerceraChartCashItemSeries = this.chart?.MainCashItemSeries();
        if (cashItemSeries != null) { cashItemSeries.HistoryExpanded.UnSubscribe(this.RecalcMovingAverageMap, this); }
        super.Dispose();
    }

    Properties (): DynProperty[] {
        const properties = super.Properties();
        let prop = new DynProperty('showVolume', this.Visible, DynProperty.BOOLEAN, DynProperty.VOLUME_GROUP);
        prop.sortIndex = 10;
        prop.assignedProperty = ['VolumeHeightPercent', 'VolumeOpacityPercent', 'VolumeColoringMode', 'VolumeFixedColor', 'VolumeUpColor', 'VolumeDownColor', 'showVolumeMarker', 'Volume.FontColor', 'VolumeBars.MovingAverage.Active', 'VolumeBars.MovingAverage.Period'];
        properties.push(prop);

        prop = new DynProperty('VolumeHeightPercent', this.VolumeHeightPercent, DynProperty.INTEGER, DynProperty.VOLUME_GROUP);
        prop.sortIndex = 20;
        prop.enabled = this.Visible;
        prop.minimalValue = 0;
        prop.maximalValue = 100;
        prop.decimalPlaces = 0;
        properties.push(prop);

        prop = new DynProperty('VolumeColoringMode', this.VolumeColoringMode, DynProperty.COMBOBOX_COMBOITEM, DynProperty.VOLUME_GROUP);
        prop.sortIndex = 30;
        prop.enabled = this.Visible;
        prop.assignedProperty = ['VolumeUpColor', 'VolumeDownColor'];
        prop.DynPropertyRelationType = DynPropertyRelationType.Visibility;
        prop.objectVariants = [
            {
                text: Resources.getResource('chart.volumeColoringMode.Fixed'),
                value: VolumeColoringMode.Fixed
            },
            {
                text: Resources.getResource('chart.volumeColoringMode.ByBar'),
                value: VolumeColoringMode.ByBar
            },
            {
                text: Resources.getResource('chart.volumeColoringMode.ByDifference'),
                value: VolumeColoringMode.ByDifference
            },
            {
                text: Resources.getResource('chart.volumeColoringMode.ByMovingAverage'),
                value: VolumeColoringMode.ByMovingAverage
            }
        ];
        properties.push(prop);

        prop = new DynProperty('VolumeFixedColor', this.volumeFixedBrush.Color, DynProperty.COLOR, DynProperty.VOLUME_GROUP);
        prop.sortIndex = 40;
        prop.enabled = this.Visible;
        properties.push(prop);

        prop = new DynProperty('VolumeUpColor', this.volumeUpBrush.Color, DynProperty.COLOR, DynProperty.VOLUME_GROUP);
        prop.sortIndex = 50;
        prop.enabled = this.Visible;
        properties.push(prop);

        prop = new DynProperty('VolumeDownColor', this.volumeDownBrush.Color, DynProperty.COLOR, DynProperty.VOLUME_GROUP);
        prop.sortIndex = 60;
        prop.enabled = this.Visible;
        properties.push(prop);

        prop = new DynProperty('Volume.FontColor', this.VolumeBarFontColorGet(), DynProperty.COLOR, DynProperty.VOLUME_GROUP);
        prop.sortIndex = 65;
        prop.enabled = this.Visible;
        properties.push(prop);

        prop = new DynProperty('VolumeBars.MovingAverage.Active', this.movingAverageActive, DynProperty.BOOLEAN, DynProperty.VOLUME_GROUP);
        prop.sortIndex = 70;
        prop.enabled = this.Visible;
        prop.assignedProperty = ['VolumeBars.MovingAverage.Line'];
        prop.DynPropertyRelationType = DynPropertyRelationType.Visibility;
        properties.push(prop);

        const MALine = this.movingAverageLine;
        prop = new DynProperty('VolumeBars.MovingAverage.Line', new ColorStyleWidth(MALine.Color, MALine.Style, MALine.Width), DynProperty.COLOR_STYLE_WIDTH, DynProperty.VOLUME_GROUP);
        prop.enabled = this.Visible;
        prop.sortIndex = 80;
        properties.push(prop);

        prop = new DynProperty('VolumeBars.MovingAverage.Period', this.movingAveragePeriod, DynProperty.INTEGER, DynProperty.VOLUME_GROUP);
        prop.minimalValue = 1;
        prop.sortIndex = 90;
        prop.enabled = this.Visible;
        properties.push(prop);

        prop = new DynProperty('showVolumeMarker', this.showVolumeMarker, DynProperty.BOOLEAN, DynProperty.VOLUME_GROUP);
        prop.sortIndex = 100;
        prop.enabled = this.Visible;
        properties.push(prop);

        return properties;
    }

    callBack (properties: any): void {
        super.callBack(properties);

        let dp = DynProperty.getPropertyByName(properties, 'showVolume');
        if (dp != null) this.Visible = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'VolumeHeightPercent');
        if (dp != null) this.VolumeHeightPercent = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'VolumeFixedColor');
        if (dp != null) this.volumeFixedBrush.Color = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'VolumeUpColor');
        if (dp != null) this.volumeUpBrush.Color = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'VolumeDownColor');
        if (dp != null) this.volumeDownBrush.Color = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'Volume.FontColor');
        if (dp != null) this.VolumeBarFontColorSet(dp.value);

        dp = DynProperty.getPropertyByName(properties, 'VolumeColoringMode');
        if (dp != null) this.VolumeColoringMode = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'VolumeBars.MovingAverage.Active');
        if (dp != null) this.movingAverageActive = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'VolumeBars.MovingAverage.Line');
        if (dp != null) {
            const csw = dp.value;
            this.movingAverageLine.Color = csw.Color;
            this.movingAverageLine.Style = csw.Style;
            this.movingAverageLine.Width = csw.Width;

            this.movingAveragePen = new Pen(csw.Color, csw.Width, csw.Style);
            Pen.ProcessPen(this.movingAveragePen, csw.Style);
        }

        dp = DynProperty.getPropertyByName(properties, 'VolumeBars.MovingAverage.Period');
        if (dp != null) this.SetMovingAveragePeriod(dp.value);

        dp = DynProperty.getPropertyByName(properties, 'showVolumeMarker');
        if (dp != null) this.showVolumeMarker = dp.value;
    }
}

interface VolumeBarLabel {
    bar: Rectangle
    volume: number
}

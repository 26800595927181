// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../../Commons/properties/Resources';
import { InstrumentLookupQuickTreeNode } from '../QuickTree/InstrumentLookupQuickTreeNode';
import { QuickTableUtils } from '../QuickTable/QuickTableUtils';
import { InstrumentTypes, InstrumentTypesImageFileNameMap } from '../../../Utils/Instruments/InstrumentTypes';
import { DateTimeUtils } from '../../../Utils/Time/DateTimeUtils';
import { ThemeManager } from '../../misc/ThemeManager';
import { Instrument } from '../../../Commons/cache/Instrument';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { DataCache } from '../../../Commons/DataCache';
import { InsDefSettings } from '../../../Commons/cache/InstrumentDefaults';
import { InstrumentLookupComparer } from '../../../Utils/Instruments/InstrumentLookupComparer';
import { type QuickTreeNode } from '../QuickTree/QuickTreeNode';
import { type QuickTree } from '../QuickTree/QuickTree';
import { InstrumentLookupType, type InstrumentLookupProperties } from './InstrumentLookupManagerProps';

class _InstrumentLookupManager {
    public _cachedStrikeList = {};

    public GetNodeLVL (node: QuickTreeNode, lvl: number): number {
        if (!isNullOrUndefined(node.parentNode)) {
            lvl = this.GetNodeLVL(node.parentNode, ++lvl);
        }

        return lvl;
    }

    public IsSmallLookup (context: CanvasRenderingContext2D): boolean // Объединил две затычки в одну :) Теперь можно подумать над улучшением правками данного метода
    { // FYI: на существование context,context.canvas, context.canvas.width проверок не добавлял в соответствии с первоисточником и чтоб не увеличивать время выполнения, добавлением доп. проверок ...
        return context.canvas.width <= 200; // TODO затычка подумать.
    }

    public PopulateNode (node: InstrumentLookupQuickTreeNode, quickTree: QuickTree, instrumentItem: Instrument, isOptionMasterMode: boolean): void {
        const context: CanvasRenderingContext2D = quickTree.DrawingContext;
        const small = this.IsSmallLookup(context) || quickTree.isSmall;
        const nodeLVL = this.GetNodeLVL(node, 0);

        const IsFuckingHieroglyph = Resources.isHieroglyph();

        if (!IsFuckingHieroglyph) {
            node.InstrumentDrawingFont = node.InstrumentFontBold;
        }

        context.font = node.InstrumentDrawingFont;
        node.InstrumentNameText = instrumentItem.DisplayName();
        node.InstrumentNameTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentNameText));

        if (!small) {
            if (isOptionMasterMode && instrumentItem.ForwardBaseInstrument != null) {
                node.InstrumentTradingExchangeText = instrumentItem.ForwardBaseInstrument.TradingExchange;
            } else {
                node.InstrumentTradingExchangeText = instrumentItem.TradingExchange;
            }
            node.InstrumentTradingExchangeTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentTradingExchangeText));
            node.FlagImg = DataCache.CountryCache.GetFlagImage(instrumentItem.CountryId);
        }

        let descr = instrumentItem.DescriptionValue();
        if (!descr && !isNullOrUndefined(node.parentNode)) {
            descr = node.parentNode.InstrumentDescriptionText;
        } // #113025 for big

        node.InstrumentDescriptionText = descr;
        if (node.InstrumentDescriptionText) {
            context.font = node.InstrumentDescriptionFont;
            node.InstrumentDescriptionTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentDescriptionText));
        }
        node.InstrumentTypeImg = this.getInstrumentTypeImage(instrumentItem.InstrType, instrumentItem.CFD);

        if (small) { // I suppose, here we can replace 4 next rows with 1:
            this.PopulateSmallNode(node, context, nodeLVL); // ~ return this.PopulateSmallNode(node, context, nodeLVL)
            return; // but PopulateSmallNode doesn't return any value, that is not pretty good. Neither does current method(this.PopulateNode)
        }

        const maxNameDescrWidth = 200 + 17 * (7 - nodeLVL);
        const maxExchangeWidth = 150;

        const maxTextWidth = node.InstrumentNameTextWidth;

        const finalNameTextWidth = maxTextWidth;
        let finalExchangeTextWidth = node.InstrumentTradingExchangeTextWidth;
        const toRemoveLetersCount = 5;

        if (maxTextWidth > maxNameDescrWidth) {
            let availableSize = 0;
            if (finalExchangeTextWidth < maxExchangeWidth) {
                availableSize = maxExchangeWidth - finalExchangeTextWidth;
            }

            let finalAvailable = availableSize + maxNameDescrWidth;

            if (maxTextWidth > finalAvailable) {
                this._function1_NameDescr(node, context, finalAvailable, toRemoveLetersCount);
                finalAvailable = node.InstrumentNameTextWidth;
            }
        } else if (node.InstrumentDescriptionTextWidth > finalNameTextWidth) {
            let finalAvailable = maxNameDescrWidth;
            if (node.InstrumentNameTextWidth < maxNameDescrWidth && finalExchangeTextWidth > maxExchangeWidth) {
                const avaliblefromtext = maxNameDescrWidth - node.InstrumentNameTextWidth;
                const needForExchange = finalExchangeTextWidth - maxExchangeWidth;

                if (avaliblefromtext > needForExchange) {
                    finalAvailable = avaliblefromtext - needForExchange + node.InstrumentNameTextWidth;
                } else {
                    finalAvailable = node.InstrumentNameTextWidth;
                }
            } else if (finalExchangeTextWidth < maxExchangeWidth) {
                finalAvailable = maxExchangeWidth - finalExchangeTextWidth + maxNameDescrWidth;
            }

            if (node.InstrumentDescriptionTextWidth > finalAvailable) {
                this._function3_DescrCut(node, context, finalAvailable, toRemoveLetersCount);
            }
        }

        if (finalExchangeTextWidth > maxExchangeWidth) {
            const maxExchangeTextWidth = finalExchangeTextWidth;
            const maxNamePartTextWidth = node.InstrumentNameTextWidth;

            let availableSize = 0;
            if (maxNamePartTextWidth < maxNameDescrWidth) {
                availableSize = maxNameDescrWidth - maxNamePartTextWidth;
            }

            let finalAvailable = availableSize + maxExchangeWidth;
            if (maxExchangeTextWidth > finalAvailable) {
                this._function4_Excange(node, context, finalExchangeTextWidth, finalAvailable, toRemoveLetersCount);
                finalAvailable = node.InstrumentTradingExchangeTextWidth;
            }
            finalExchangeTextWidth = finalAvailable;
        }

        if (node.textPattern) {
            this._function2_Pattern(node, context);
        }
    }

    public PopulateNodeFutureOptionGroupNode (node: InstrumentLookupQuickTreeNode, quickTree: QuickTree, name: string, description: string, instrumentItem: Instrument): void {
        const context: CanvasRenderingContext2D = quickTree.DrawingContext;
        const small = this.IsSmallLookup(context) || quickTree.isSmall;
        const nodeLVL = this.GetNodeLVL(node, 0);

        context.font = node.InstrumentDrawingFont;
        node.InstrumentNameText = name;
        node.InstrumentNameTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentNameText));

        node.InstrumentDescriptionText = description;
        if (node.InstrumentDescriptionText) {
            context.font = node.InstrumentDescriptionFont;
            node.InstrumentDescriptionTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentDescriptionText));
        }
        node.InstrumentTypeImg = this.getInstrumentTypeImage(instrumentItem.InstrType, instrumentItem.CFD);

        const baseIns = instrumentItem.ForwardBaseInstrument;
        if (!isNullOrUndefined(baseIns)) {
            node.InstrumentTradingExchangeText = baseIns.TradingExchange;
            node.InstrumentTradingExchangeTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentTradingExchangeText)) + 12;
            node.FlagImg = DataCache.CountryCache.GetFlagImage(baseIns.CountryId);
        }

        const maxNameDescrWidth = (small ? 54 : 200) + 15 * (6 - nodeLVL);
        const maxTextWidth = node.InstrumentNameTextWidth;

        const toRemoveLetersCount = 5;
        if (maxTextWidth > maxNameDescrWidth) {
            const availableSize = 0;
            const finalAvailable = availableSize + maxNameDescrWidth;

            if (maxTextWidth > finalAvailable) {
                this._function1_NameDescr(node, context, finalAvailable, toRemoveLetersCount);
            }
        }
    }

    public _function1_NameDescr (node: InstrumentLookupQuickTreeNode, context: CanvasRenderingContext2D, finalAvailable: number, toRemoveLetersCount: number): void {
        const descrTxt = node.InstrumentDescriptionText ?? '';
        // кофициенты размера букв
        const NC = Math.round(node.InstrumentNameTextWidth / node.InstrumentNameText.length);
        const DC = Math.round(node.InstrumentDescriptionTextWidth / descrTxt.length);

        // количество букв - toRemoveLetersCount для точек
        const NLC = Math.floor(finalAvailable / NC) - toRemoveLetersCount;
        const DLC = Math.floor(finalAvailable / DC) - toRemoveLetersCount;

        const newNameText = node.InstrumentNameTextWidth < finalAvailable ? node.InstrumentNameText : node.InstrumentNameText.substring(0, NLC) + '...';
        const newDescrText = node.InstrumentDescriptionTextWidth < finalAvailable ? descrTxt : descrTxt.substring(0, DLC) + '...';

        node.InstrumentNameText = newNameText;
        context.font = node.InstrumentDrawingFont;
        node.InstrumentNameTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentNameText));

        node.InstrumentDescriptionText = newDescrText;
        context.font = node.InstrumentDescriptionFont;
        node.InstrumentDescriptionTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentDescriptionText));
    }

    public _function2_Pattern (node: InstrumentLookupQuickTreeNode, context: CanvasRenderingContext2D): void {
        const upN = node.InstrumentNameText.toUpperCase();
        const upD = node.InstrumentDescriptionText.toUpperCase();
        const upF = node.textPattern.toUpperCase();

        const indexN = upN.indexOf(upF);
        const indexD = upD.indexOf(upF);
        if (indexN !== -1) {
            const substrN = node.InstrumentNameText.substring(0, indexN);
            context.font = node.InstrumentDrawingFont;
            node.FilterNamePos = QuickTableUtils.GetWidth(context, substrN);
            node.FilterNameText = node.InstrumentNameText.substring(indexN, indexN + node.textPattern.length);
        }
        if (indexD !== -1) {
            const substrD = node.InstrumentDescriptionText.substring(0, indexD);
            context.font = node.InstrumentDescriptionFont;
            node.FilterDescrPos = QuickTableUtils.GetWidth(context, substrD);
            node.FilterDescrText = node.InstrumentDescriptionText.substring(indexD, indexD + node.textPattern.length);
        }
    }

    public _function3_DescrCut (node: InstrumentLookupQuickTreeNode, context: CanvasRenderingContext2D, finalAvailable: number, toRemoveLetersCount: number): void {
        const DC = Math.round(node.InstrumentDescriptionTextWidth / node.InstrumentDescriptionText.length);
        const DLC = Math.floor(finalAvailable / DC) - toRemoveLetersCount + 3;

        const newDescrText = node.InstrumentDescriptionText.substring(0, DLC) + '...';

        node.InstrumentDescriptionText = newDescrText;
        context.font = node.InstrumentDescriptionFont;
        node.InstrumentDescriptionTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentDescriptionText)); // new_descr_width
    }

    public _function4_Excange (node: InstrumentLookupQuickTreeNode, context: CanvasRenderingContext2D, finalExchangeTextWidth: number, finalAvailable: number, toRemoveLetersCount: number): void {
        // кофициенты размера букв
        const EC = Math.round(finalExchangeTextWidth / node.InstrumentTradingExchangeText.length);
        // количество букв - toRemoveLetersCount для точек
        const ELC = Math.floor(finalAvailable / EC) - toRemoveLetersCount;

        let newExchangeText = node.InstrumentTradingExchangeText.substring(0, ELC) + '...';

        node.InstrumentTradingExchangeText = newExchangeText;
        context.font = node.InstrumentFont;
        node.InstrumentTradingExchangeTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentTradingExchangeText));
        if (node.InstrumentTradingExchangeTextWidth > finalAvailable) {
            const dif = node.InstrumentTradingExchangeTextWidth - finalAvailable;
            const count = Math.round(dif / EC) + 1;
            newExchangeText = node.InstrumentTradingExchangeText.substring(0, node.InstrumentTradingExchangeText.length - (count + 5)) + '...';
            node.InstrumentTradingExchangeText = newExchangeText;
            node.InstrumentTradingExchangeTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.InstrumentTradingExchangeText));
        }
    }

    public PopulateNormalSmallNode (node: InstrumentLookupQuickTreeNode, context: CanvasRenderingContext2D): void {
        const lvl = this.GetNodeLVL(node, 0);
        const maxNameDescrWidth = 54 + 15 * (6 - lvl);
        context.font = node.InstrumentDrawingFont;
        const maxTextWidth = Math.floor(QuickTableUtils.GetWidth(context, node.nodeText));
        const toRemoveLetersCount = 5;

        if (maxTextWidth > maxNameDescrWidth) {
            const availableSize = 0;
            const finalAvailable = availableSize + maxNameDescrWidth;

            if (maxTextWidth > finalAvailable) {
                // кофициенты размера букв
                const NC = Math.round(maxTextWidth / node.nodeText.length);
                // количество букв - toRemoveLetersCount для точек
                const NLC = Math.floor(finalAvailable / NC) - toRemoveLetersCount;
                const newNameText = maxTextWidth < finalAvailable ? node.nodeText : node.nodeText.substring(0, NLC) + '...';
                node.nodeText = newNameText;
            }
        }
    }

    public PopulateSmallNode (node: InstrumentLookupQuickTreeNode, context: CanvasRenderingContext2D, lvl: number): void {
        const maxNameDescrWidth = 54 + 15 * (6 - lvl);
        const maxTextWidth = node.InstrumentNameTextWidth;
        const toRemoveLetersCount = 5;

        if (maxTextWidth > maxNameDescrWidth) {
            const availableSize = 0;
            const finalAvailable = availableSize + maxNameDescrWidth;

            if (maxTextWidth > finalAvailable) {
                this._function1_NameDescr(node, context, finalAvailable, toRemoveLetersCount);
            }
        }
    }

    public PopulateNodeByStrikes (parentNode, cachedList: Instrument[], currentNameFilter: string, quickTree: QuickTree, changeCollapsed: boolean = true): boolean {
        // TODO Refactoring UGLY ILACQTR.addChildNodesByInstrumentsArray
        if (parentNode.addChildNodesByInstrumentsArray) {
            return parentNode.addChildNodesByInstrumentsArray(parentNode.id, cachedList, currentNameFilter, quickTree);
        }

        cachedList = InstrumentLookupComparer.sortInstrumentList(cachedList, '');
        parentNode.RemoveAllChildNodes();
        const childNodes = [];
        for (let i = 0, len = cachedList.length; i < len; i++) {
            const curIt: Instrument = cachedList[i];

            if (curIt.NeedToHide()) { continue; }

            const node = new InstrumentLookupQuickTreeNode(curIt.GetInteriorID());
            node.tag = curIt;

            if (quickTree.isMultiSelect && (parentNode.checked || parentNode.selected)) // #113317
            {
                node.checked = node.selected = true;
            }
            if (currentNameFilter) {
                node.textPattern = currentNameFilter;
            }

            this.PopulateNode(node, quickTree, curIt, false);

            if (!node.InstrumentDescriptionText) {
                node.InstrumentDescriptionText = parentNode.InstrumentDescriptionText;
            } // 113025+

            if (!currentNameFilter || node.FilterNameText || node.FilterDescrText) // 113013 big
            {
                childNodes.push(node);
            }
        }

        parentNode.AddChildNodeRange(childNodes);
        if (changeCollapsed) {
            parentNode.collapsed = !parentNode.collapsed;
        }
        parentNode.skipAdding = true;
        quickTree.Draw(true);

        return true;
    }

    public OptionProcess (currentWorkNode: { node: InstrumentLookupQuickTreeNode }, optionInstrument: Instrument, props: InstrumentLookupProperties): { node: InstrumentLookupQuickTreeNode } {
        const quickTree = props.quickTree;
        const underlier = optionInstrument.ForwardBaseInstrument;
        const instrumentName = underlier.SourceName ?? underlier.DisplayName();
        const instrumentDescription = underlier.SourceDescription ?? underlier.UnderlierDescription ?? '';
        const newNode = this.checkCreateAddNode(currentWorkNode, instrumentName);
        if (!isNullOrUndefined(newNode)) {
            newNode.tag = 'group';
            newNode.sortingStop = true;
            newNode.InstrumentTypeImg = this.getInstrumentTypeImage(underlier.InstrType, underlier.CFD);
            if (props.isInstrumentDefaults) {
                this.PopulateNormalSmallNode(newNode, quickTree.DrawingContext);
            } else {
                this.PopulateNodeFutureOptionGroupNode(newNode, quickTree, instrumentName, instrumentDescription, optionInstrument);
            }
        }

        currentWorkNode = currentWorkNode[instrumentName];
        return this.AddOptionEvent(currentWorkNode, optionInstrument, props);
    }

    public AddOptionEvent (currentWorkNode: { node: InstrumentLookupQuickTreeNode }, optionInstrument: Instrument, props: InstrumentLookupProperties): { node: InstrumentLookupQuickTreeNode } {
        const underlier = optionInstrument.ForwardBaseInstrument;
        const quickTree = props.quickTree;
        const dataProvider = props.dataProvider;
        const currentNameFilter = props.currentNameFilter;
        const instrumentTypeImage = this.getInstrumentTypeImage(optionInstrument.InstrType, optionInstrument.CFD);
        const parentNode = currentWorkNode;
        // если у опциона родитель фьючерс
        if (!isNullOrUndefined(underlier) && (underlier.isFuturesSymbol || underlier.IsFakeNonFixed())) {
            const nodeName = underlier.DisplayName();
            // существует ли он в дереве?
            if (nodeName !== currentWorkNode.node.nodeText) {
                const node = this.checkCreateAddNode(currentWorkNode, nodeName);
                if (!isNullOrUndefined(node)) {
                    node.InstrumentTypeImg = instrumentTypeImage;
                    node.sortingStop = true;
                    if (props.isInstrumentDefaults) {
                        this.PopulateNormalSmallNode(node, quickTree.DrawingContext);
                    } else {
                        this.PopulateNodeFutureOptionGroupNode(node, quickTree, nodeName, underlier.DescriptionValue(), optionInstrument);
                    }
                }
                // спускаемся ниже ExchangeName->InstrType->instrGroup->Option parentInstrument -> Option parentInstrument (Future)
                currentWorkNode = currentWorkNode[nodeName];
            }
        }

        if (props.isOptionMaster) {
            const node = currentWorkNode.node;
            node.textPattern = props.currentNameFilter;
            node.tag = optionInstrument;
            if (isValidString(node.textPattern)) {
                this._function2_Pattern(node, quickTree.DrawingContext);
            }
            return parentNode;
        }

        if (optionInstrument.isFakeOption) {
            const node = currentWorkNode.node;
            node.tag = optionInstrument;
            node.AddChildNode(new InstrumentLookupQuickTreeNode('null'));
            node.DoCollapse = () => {
                if (!node.SkipRequest) {
                    node.RemoveAllChildNodes();
                    node.SkipRequest = true;
                    void dataProvider.getOption(optionInstrument).then((option) => {
                        const strikeList = dataProvider.getStrikesList(option);
                        const strikesByExpdates: Map<Date, Instrument[]> = new Map<Date, Instrument[]>();
                        for (let i = 0; i < strikeList.length; i++) {
                            let strikes = strikesByExpdates.get(strikeList[i].ExpDate);
                            if (isNullOrUndefined(strikes)) {
                                strikes = [];
                                strikesByExpdates.set(strikeList[i].ExpDate, strikes);
                            }
                            strikes.push(strikeList[i]);
                        }
                        for (const expDate of strikesByExpdates.keys()) {
                            const strikes = strikesByExpdates.get(expDate);
                            const date = DateTimeUtils.formatDate(expDate, 'DD.MM.YYYY');
                            const newNode = this.checkCreateAddNode(currentWorkNode, date);
                            newNode.missAtUnCollapse = true;
                            newNode.sortingStop = true;
                            newNode.InstrumentTypeImg = instrumentTypeImage;
                            this.PopulateNodeFutureOptionGroupNode(newNode, quickTree, date, option.SeriesDescription, option);
                            newNode.InstrumentTradingExchangeText = newNode.FlagImg = null;
                            this.PopulateNodeByStrikes(newNode, strikes, currentNameFilter, quickTree, false);
                        }
                        node.collapsed = !node.collapsed;
                        quickTree.Draw(true);
                    });
                } else {
                    node.collapsed = !node.collapsed;
                }
            };
            return parentNode;
        }

        const date = DateTimeUtils.formatDate(optionInstrument.ExpDate, 'DD.MM.YYYY');
        // теперь дата экспирациив
        const newNode = this.checkCreateAddNode(currentWorkNode, date);
        if (!isNullOrUndefined(newNode)) {
            newNode.missAtUnCollapse = true;
            newNode.sortingStop = true;
            newNode.InstrumentTypeImg = instrumentTypeImage;
            if (props.isInstrumentDefaults) {
                this.PopulateNormalSmallNode(newNode, quickTree.DrawingContext);
            } else {
                this.PopulateNodeFutureOptionGroupNode(newNode, quickTree, date, optionInstrument.SeriesDescription, optionInstrument);
            }

            newNode.InstrumentTradingExchangeText = newNode.FlagImg = null; // не нужен флаг и exchng поскольку серия - неторговая сущность #113027
        }
        // спускаемся ниже ExchangeName->InstrType->instrGroup->Option parentInstrument -> (parentInstrument.isFuturesSymbol? Option parentInstrument(Future) : "without")->ExpDate
        currentWorkNode = currentWorkNode[date];
        let needOpenStrike = false; // #113021
        if (!isNullOrUndefined(newNode)) {
            const cachedList = this.tryGetCachedStrikeList(optionInstrument);
            if (!isNullOrUndefined(cachedList) && props.instrumentLookupType !== InstrumentLookupType.Small) {
                needOpenStrike = this.PopulateNodeByStrikes(newNode, cachedList, currentNameFilter, quickTree);
            }
            const lookupManager = this;
            newNode.DoCollapse = function () {
                const me = this; // TODO check: #93065 !me.childNodes.length  //#92878 !this.SkipRequest
                if (me.collapsed && dataProvider && DataCache.NonFixedList && !me.SkipRequest) {
                    const ins = me.tag;
                    const cachedList = lookupManager.tryGetCachedStrikeList(ins);

                    if (!isNullOrUndefined(cachedList)) {
                        lookupManager.PopulateNodeByStrikes(me, cachedList, currentNameFilter, quickTree);
                    } else {
                        void dataProvider.GetNonFixedInstrumentStrikes(ins).then((list: Instrument[]) => {
                            lookupManager.cacheStrikeList(list, ins);
                            lookupManager.PopulateNodeByStrikes(me, list, currentNameFilter, quickTree);
                        });
                    }
                } else {
                    me.collapsed = !me.collapsed;
                }
            };

            newNode.tag = optionInstrument;
        }

        const isLoadStrikesNode = (optionInstrument.StrikePrice === -1 || optionInstrument.StrikePrice === 0) && optionInstrument.IsFakeNonFixed() && !!optionInstrument.InstrDateSettingsList.length;
        const needOpen = (isValidString(props.currentNameFilter) && !isLoadStrikesNode) || needOpenStrike;

        currentWorkNode.node.collapsed = !needOpen;
        currentWorkNode.node.SkipRequest = needOpen;

        return currentWorkNode;
    }

    public tryGetCachedStrikeList (ins): Instrument[] {
        const ID = ins ? ins.ContractID : null;
        let res = this._cachedStrikeList[ID] ?? null;

        res = res ?? DataCache.GetInstrumentsByContractID(ID); // #113518,#113548

        return res;
    }

    public cacheStrikeList (InsList: Instrument[], seriaIns): boolean {
        let success = false;
        const ID = seriaIns ? seriaIns.ContractID : null;
        if (ID) {
            success = !!(this._cachedStrikeList[ID] = InsList);
        }
        return success;
    }

    public fillTree (properties: InstrumentLookupProperties): void {
        const items = properties.instruments ?? [];
        const selectedItem = properties.selectedInstruments ?? null;
        const currentNameFilter = properties.currentNameFilter ?? null;
        const quickTree = properties.quickTree;

        const selectedItemsMap: Record<string, Instrument> = {};
        const selectedItemsIsArray = selectedItem instanceof Array;
        if (selectedItemsIsArray) {
            for (let i = 0; i < selectedItem.length; i++) {
                selectedItemsMap[selectedItem[i].GetInteriorID()] = selectedItem[i];
            }
        }

        let selectedNode = null;
        const nodeRangeTree: Record<string, { node: InstrumentLookupQuickTreeNode }> = {};

        const hasFilter = currentNameFilter !== null;

        let filterString = '';
        if (hasFilter) {
            filterString = currentNameFilter.toLowerCase();
        }

        const insTypeNodeArr = [];

        const allExchangeIdText = Resources.getResource('ExchangeId.All');
        if (properties.isInstrumentDefaults) {
            const allExchangesNode = new InstrumentLookupQuickTreeNode(allExchangeIdText);
            allExchangesNode.collapsed = false;
            const allExchangesNodeTreeObj = { node: allExchangesNode };

            nodeRangeTree[allExchangeIdText] = allExchangesNodeTreeObj;
            const existingInstrumentTypes = properties.existingInstrumentTypes;
            for (let i = 0; i < existingInstrumentTypes.length; i++) {
                const instrumentType = existingInstrumentTypes[i];
                const insTypeText = Instrument.getTypeString(instrumentType);
                const insTypeNode = new InstrumentLookupQuickTreeNode(insTypeText);
                const insTypeImg = this.getInstrumentTypeImage(instrumentType);
                insTypeNode.tag = InsDefSettings.INSTRUMENT_TYPE + instrumentType;
                insTypeNode.InstrumentTypeImg = insTypeImg;

                allExchangesNodeTreeObj[insTypeText] = {
                    node: insTypeNode, groups: {}
                };
                allExchangesNode.AddChildNode(insTypeNode);
                insTypeNodeArr.push(insTypeNode);
            }
        }

        const len = items.length;
        for (let i = 0; i < len; i++) {
            const curItem: Instrument = items[i];

            // instrGroup
            const instrGroup = curItem.getInstrumentTypeName();
            // ExchangeName   TradingExchange
            const curItemExchangeValue = properties.isInstrumentDefaults ? allExchangeIdText : curItem.isOptionSymbol ? curItem.ForwardBaseInstrument.TradingExchange : curItem.TradingExchange;
            // InstrType
            const curItemInstrTypeValue = curItem.CFD ? InstrumentTypes.EQUITIES_CFD : curItem.InstrType;
            const curItemInstrTypeText = curItem.getTypeString();

            let curItemText = curItem.DisplayName();
            const descr = curItem.DescriptionValue();
            if (descr) {
                curItemText += (' (' + descr + ')');
            }
            if (hasFilter && (!DataCache.NonFixedList || properties.isInstrumentDefaults)) {
                const curItemFindStr = curItemText.toLowerCase();
                if (!curItemFindStr.includes(filterString)) { continue; }
            }

            // todo blizzard sc2
            const fullContains = curItem.DisplayShortName().toLocaleLowerCase() === filterString;

            // нода в которую закинем инструмент
            let currentWorkNode: { node: InstrumentLookupQuickTreeNode } = null;
            // проверяем ExchangeName ,если нет добавляем
            this.checkCreateAddNode(nodeRangeTree, curItemExchangeValue, true);

            // сейчас это ExchangeName нода
            currentWorkNode = nodeRangeTree[curItemExchangeValue];
            currentWorkNode.node.tag = 'exchange';

            // проверяем InstrType ,если нет добавляем

            const insTypeImg = this.getInstrumentTypeImage(curItem.InstrType, curItem.CFD);

            let newNode: InstrumentLookupQuickTreeNode | null = null;
            newNode = this.checkCreateAddNode(currentWorkNode, curItemInstrTypeText);
            if (!isNullOrUndefined(newNode)) {
                // set images
                newNode.InstrumentTypeImg = insTypeImg;
                newNode.tag = curItemInstrTypeValue;
                if (properties.isInstrumentDefaults) {
                    this.PopulateNormalSmallNode(newNode, quickTree.DrawingContext);
                }
                // заполняем массив всех InstrType
                insTypeNodeArr.push(newNode);
            }

            // спускаемся ниже ExchangeName->InstrType
            currentWorkNode = currentWorkNode[curItemInstrTypeText];

            // смотрим группу. нету? добавляем.
            newNode = this.checkCreateAddNode(currentWorkNode, instrGroup);
            if (!isNullOrUndefined(newNode)) {
                if (fullContains) {
                    newNode.FullContainsCount++;
                }
                newNode.tag = 'group';
                newNode.SpecificTag = 'instgroup';
                newNode.InstrumentTypeImg = insTypeImg;
                newNode.sortingStop = true;
                if (properties.isInstrumentDefaults) {
                    this.PopulateNormalSmallNode(newNode, quickTree.DrawingContext);
                }
            } else {
                const tmpNode = currentWorkNode[instrGroup];
                if (!isNullOrUndefined(tmpNode) && fullContains) {
                    tmpNode.node.FullContainsCount++;
                }
            }

            // спускаемся ниже ExchangeName->InstrType->instrGroup
            currentWorkNode = currentWorkNode[instrGroup];

            if (curItem.isFuturesSymbol) {
                const name = curItem.SourceName;
                const description = isValidString(curItem.SourceDescription) ? curItem.SourceDescription : '';
                const newNode = this.checkCreateAddNode(currentWorkNode, name);
                if (!isNullOrUndefined(newNode)) {
                    newNode.tag = 'group';
                    newNode.sortingStop = true;
                    newNode.InstrumentTypeImg = insTypeImg;
                    if (properties.isInstrumentDefaults) {
                        this.PopulateNormalSmallNode(newNode, quickTree.DrawingContext);
                    } else {
                        this.PopulateNodeFutureOptionGroupNode(newNode, quickTree, name, description, curItem);
                    }
                }
                currentWorkNode = currentWorkNode[name];
            } else if (curItem.isOptionSymbol && !isNullOrUndefined(curItem.ForwardBaseInstrument) && (!properties.isOptionMaster || curItem.ForwardBaseInstrument.isFuturesSymbol)) {
                currentWorkNode = this.OptionProcess(currentWorkNode, curItem, properties);
            }

            // создаём ноду инструмента
            const nodeText = curItem.isFakeOption || properties.isOptionMaster ? curItem.DisplayName() : curItem.GetInteriorID();
            let node = null;

            const parentNode = currentWorkNode.node;
            const alreadyIndex = parentNode.childNodes.findIndex((e) => e.nodeText === nodeText); // #113551
            if (alreadyIndex < 0) {
                node = new InstrumentLookupQuickTreeNode(nodeText);
                if (currentNameFilter) {
                    node.textPattern = currentNameFilter;
                }
                node.tag = curItem;
                // добавляем её в ноду которую (нашли||сформировали)
                if (!parentNode.skipAdding || node.nodeText.indexOf('null') === -1) // if тут для того чтоб не добавлять времен. опцион. инстр. когда в кеше уже имеем список страйков из [421]
                {
                    parentNode.AddChildNode(node);
                }

                this.PopulateNode(node, quickTree, curItem, properties.isOptionMaster);
            } else {
                node = parentNode.childNodes[alreadyIndex];
            }

            if (selectedItemsIsArray) {
                if (!isNullOrUndefined(selectedItemsMap[nodeText])) {
                    quickTree.unCollapseAllParents(node);
                    quickTree.addNodeToDict(node, true);
                }
            } else if (selectedItem === curItem || (properties.isOptionMaster && Instrument.IsEqualOptionInstruments(curItem, selectedItem))) {
                selectedNode = node;
                node.selected = true;
            }
        }

        const keys = Object.keys(nodeRangeTree);
        const arrToWork: InstrumentLookupQuickTreeNode[] = [];

        for (let i = 0; i < keys.length; i++) {
            const node = nodeRangeTree[keys[i]].node;
            node.sortingtoLowerCaseKey = node.nodeText?.toLowerCase() ?? '';

            if (!node.nodeLevel || !currentNameFilter || node.FilterDescrText || node.FilterNameText) {
                arrToWork.push(node);
            }
        }
        arrToWork.sort(function (a, b) {
            return a.sortingtoLowerCaseKey > b.sortingtoLowerCaseKey ? 1 : -1;
        });

        this.sortInstrumentTypeChildNodes(arrToWork);

        // TODO check after all
        // if (!isInstrumentsDefaultsMode)
        this.removeUnnecessaryGroupChildNodes(insTypeNodeArr);

        quickTree.Clear();
        quickTree.AddNodeRange(arrToWork);

        if (!selectedItemsIsArray) {
            if (hasFilter) {
                quickTree.unCollapseAllPosible(DataCache.NonFixedList);
            } else {
                quickTree.setSelectedNode(selectedNode);
                quickTree.goToSelectedNode();
            }
        }
    }

    public checkCreateAddNode (dictionary: Record<string, any>, key: string, skipAdd?: boolean): InstrumentLookupQuickTreeNode | null {
        let newNode: InstrumentLookupQuickTreeNode | null = null;
        if (isNullOrUndefined(dictionary[key])) {
            dictionary[key] = {};
            newNode = new InstrumentLookupQuickTreeNode(key);
            dictionary[key].node = newNode;
            if (!skipAdd) {
                dictionary.node.AddChildNode(newNode);
            }
        }
        return newNode;
    }

    public removeUnnecessaryGroupChildNodes (insTypeNodes: InstrumentLookupQuickTreeNode[]): void {
        const len = !isNullOrUndefined(insTypeNodes) ? insTypeNodes.length : 0;
        for (let i = 0; i < len; i++) {
            const insTypeNode = insTypeNodes[i];
            const groupNodes = insTypeNode.childNodes;

            if (groupNodes.length === 1) {
                const instrumentNodes = groupNodes[0].childNodes;
                insTypeNode.childNodes = instrumentNodes;
            }
        }
    }

    public sortInstrumentTypeChildNodes (exchangeNodes: InstrumentLookupQuickTreeNode[]): void {
        const len = isValidArray(exchangeNodes) ? exchangeNodes.length : 0;
        for (let i = 0; i < len; i++) {
            const childNodes = exchangeNodes[i].childNodes;
            const firstChild = (childNodes.length > 0) ? childNodes[0] : null;

            if (!isNullOrUndefined(firstChild)) {
                childNodes.sort(firstChild.SpecificTag
                    ? this.instrumentSubGroupsNodeComparator
                    : this.instrumentTypeNodeComparator);

                if (!firstChild.sortingStop) {
                    this.sortInstrumentTypeChildNodes(childNodes);
                }
            }
        }
    }

    public instrumentSubGroupsNodeComparator (n1: InstrumentLookupQuickTreeNode, n2: InstrumentLookupQuickTreeNode): 1 | -1 {
        if (n1.FullContainsCount === n2.FullContainsCount) {
            return n1.nodeText > n2.nodeText ? 1 : -1;
        } // Check! Perhaps better use String.prototype.localeCompare() ? Not sure cuz lots of differences: 'a' > 'A' == true => return 1, but 'a'.localeCompare('A') == -1;     'a' > 'A' == true(1), but 'a'.localeCompare('A') == -1;    'Aa' > 'aA' == false(in old way such returns -1), but 'Aa'.localeCompare('aA') == 1;        'a' > 'a' == false (=> returns -1), but 'a'.localeCompare('a') == 0 (however, no matter here, I think);         'aa' > 'aAa' == true(returns 1), but 'aa'.localeCompare('aAa') == -1  etc.        Despite it has lots of cases with equal results such as 'Aaa'.localeCompare('Aa') == 1 == 'Aaa' > 'Aa' also returns 1;             'a'.localeCompare('b') == -1 == ('a' > 'b' ? 1 : -1)    etc.

        return n1.FullContainsCount > n2.FullContainsCount ? -1 : 1; // May replace for Math.sign(n2.FullContainsCount - n1.FullContainsCount) ? see example below
    }
    // this.instrumentSubGroupsNodeComparator = function (n1, n2)
    // {
    //     let sign = Math.sign(n2.FullContainsCount - n1.FullContainsCount)
    //     if (!sign)
    //         return n1.nodeText > n2.nodeText ? 1 : -1                // check, perhaps better use String.prototype.localeCompare() ? Not sure cuz lots of differences: 'a' > 'A' == true => return 1, but 'a'.localeCompare('A') == -1;     'a' > 'A' == true(1), but 'a'.localeCompare('A') == -1;    'Aa' > 'aA' == false(in old way such returns -1), but 'Aa'.localeCompare('aA') == 1;        'a' > 'a' == false (=> returns -1), but 'a'.localeCompare('a') == 0 (however, no matter here, I think);         'aa' > 'aAa' == true(returns 1), but 'aa'.localeCompare('aAa') == -1  etc.        Despite it has lots of cases with equal results such as 'Aaa'.localeCompare('Aa') == 1 == 'Aaa' > 'Aa' also returns 1;             'a'.localeCompare('b') == -1 == ('a' > 'b' ? 1 : -1)    etc.

    //     return sign
    // }

    public instrumentTypeNodeComparator (n1: InstrumentLookupQuickTreeNode, n2: InstrumentLookupQuickTreeNode): 1 | -1 {
        return n1.nodeText > n2.nodeText ? 1 : -1;
    }

    public getInstrumentTypeImageFileName (instrumentType: InstrumentTypes, cfd: any): string {
        const IDsInSprite = InstrumentTypesImageFileNameMap;

        if (cfd) {
            return IDsInSprite[InstrumentTypes.EQUITIES_CFD];
        }

        if (instrumentType === InstrumentTypes.SPOT) {
            return IDsInSprite[InstrumentUtils.UseFuturesInsteadSpot() ? InstrumentTypes.FUTURES : InstrumentTypes.SPOT];
        } else {
            return IDsInSprite[instrumentType];
        }
    }

    public getInstrumentTypeImage (instrumentType: InstrumentTypes, cfd?: boolean): string {
        const fileName = this.getInstrumentTypeImageFileName(instrumentType, cfd);
        return ThemeManager.CurrentTheme[fileName];
    }
}

export const InstrumentLookupManager = new _InstrumentLookupManager();

// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { DataCacheToolRayType, DataCacheToolType } from '../DataCacheToolEnums';
import { DataCacheTool } from '../DataCacheTool';
import { BaseFibonacciDataCacheTool } from './BaseFibonacciDataCacheTool';
import { DynProperty } from '../../DynProperty';

export class FibonacciExpansionDataCacheTool extends BaseFibonacciDataCacheTool {
    /**
     * Constructor for FibonacciExpansionDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.FibonacciExpansion;
        this.InitPoints(3);
    }

    /**
     * Initializes the block for the tool
     */
    InitBlock (): void {
        super.InitBlock();
        this.fibLevel = [61.8, 100, 161.8];
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 3;
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 3;
    }

    /**
     * Properties for FibonacciExpansionDataCacheTool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const props = super.Properties();

        props.push(new DynProperty('chart.tools.fibretr.fontcolor', this.FontColor, DynProperty.COLOR, DynProperty.VISUAL_GROUP));
        props.push(new DynProperty(Resources.getResource('EnableNegativeFibonacciValue'), true, DynProperty.BOOLEAN, DynProperty.FIBONACCI_PARAMETERS_GROUP));

        let p = new DynProperty('Left ray',
            this.RayType === DataCacheToolRayType.LeftRay || this.RayType === DataCacheToolRayType.DoubleRay,
            DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        p.sortIndex = 0;
        props.push(p);

        p = new DynProperty('Right ray',
            this.RayType === DataCacheToolRayType.RightRay || this.RayType === DataCacheToolRayType.DoubleRay,
            DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        p.sortIndex = 1;
        props.push(p);

        return props;
    }

    /**
     * Callback function for handling dynamic property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const prop = DynProperty.getPropertyByName(properties, 'chart.tools.fibretr.fontcolor');
        if (prop != null) this.FontColor = prop.value;

        const rayProp = DynProperty.getPropertyByName(properties, 'Ray style');
        if (rayProp != null) this.StyleRay = rayProp.value.Style;

        this.RayType = DataCacheTool.GetRayType(properties);
    }
}

import { ScrollUtils } from '../../UtilsClasses/ScrollUtils';
import { type TerceraDateSelectorDropDown } from './TerceraDateSelectorDropDown';

export class TimeSlider {
    public static readonly POINTHALFWIDTH = 4;

    public ParentLiteralValue: string;
    public step: number;
    public ParentLiteralPoint: string;
    public maxValue: number;
    public parent: TerceraDateSelectorDropDown;
    public width: number;
    public MovingNow: boolean;
    public currentMoveType: number;

    constructor (parent: TerceraDateSelectorDropDown, isH: boolean, parentLiteral: string) {
        let HorM = 'H';
        this.ParentLiteralValue = parentLiteral;
        this.step = 1;
        if (isH) {
            this.ParentLiteralPoint = 'pointH';
            this.maxValue = 23;
            this.step = 8;
        } else {
            this.ParentLiteralPoint = 'pointM';
            this.maxValue = 59;
            HorM = 'M';
            this.step = 3.18;
        }

        parent.on('onMouseDown' + HorM, this.onMouseDown.bind(this));
        parent.on('onMouseUp' + HorM, this.onMouseUp.bind(this));
        parent.on('onMouseMove' + HorM, this.onMouseMove.bind(this));
        parent.on('onMouseEnter' + HorM, this.MouseEntre.bind(this));
        parent.on('onMouseLeave' + HorM, this.MouseLeave.bind(this));
        parent.on('onMouseWheel' + HorM, this.MouseWheel.bind(this));

        this.parent = parent;
        this.width = 180;
        this.MovingNow = false;

        parent.observe(parentLiteral, function (n) {
            let value = this.step * n;
            if (n >= this.maxValue) {
                value = this.width;
            }
            if (n <= 0) {
                value = 0;
            }
            this.parent.set(this.ParentLiteralPoint, value - TimeSlider.POINTHALFWIDTH);
        }.bind(this));
    }

    public onMouseDown (evt): void {
        this.MovingNow = true;
        this.processEVT(evt);
    }

    public onMouseUp (evt): void {
        this.MovingNow = false;
        this.processEVT(evt);
    }

    public onMouseMove (evt): void {
        if (!this.MovingNow) { return; }
        this.processEVT(evt);
    }

    public MouseEntre (): void {
    // TODO MB
        this.MovingNow = false;
    }

    public MouseLeave (): void {
    // TODO MB
        this.MovingNow = false;
    }

    public MouseWheel (context): void {
    // TODO MB
        const isUp = ScrollUtils.IsScrollUp(context.event.deltaY);
        this.currentMoveType = isUp ? 1 : -1;
        const value = this.parent.get(this.ParentLiteralValue) + this.currentMoveType;
        void this.parent.set(this.ParentLiteralValue, value);
    }

    public processEVT (evt): void {
        const ev = evt.original;
        if (ev.currentTarget !== ev.target) { return; }
        this.setScrPointValue(ev.offsetX);
    }

    public setScrPointValue (X): void {
        const value = Math.round(X / this.step);
        void this.parent.set(this.ParentLiteralValue, value);
    }
}

import { Periods } from '../../../Utils/History/TFInfo';
import { type ResolutionString } from '../charting_library';

class _TvHistoryResolutionHelper {
    convertPeriodToResolution (period): ResolutionString {
        if (period >= Periods.YEAR) {
            const yearsNum = period / Periods.YEAR;
            return yearsNum * 12 + 'M' as ResolutionString;
        }
        if (period >= Periods.MONTH) {
            const monthsNum = period / Periods.MONTH;
            return monthsNum + 'M' as ResolutionString;
        }
        if (period >= Periods.WEEK) {
            const weeksNum = period / Periods.WEEK;
            return weeksNum + 'W' as ResolutionString;
        }
        if (period >= Periods.DAY) {
            const daysNum = period / Periods.DAY;
            return daysNum + 'D' as ResolutionString;
        }

        return period.toString(); // minutes period equals to minutes resolution
    }

    public convertResolutionToPeriod (resolution: string): number {
        const num = parseInt(resolution) || 1;
        const basePeriod = this.getBasePeriodFromResolution(resolution);

        let res = num * basePeriod;
        if (res > 500000) { res = Periods.YEAR; }

        return res;
    }

    private getBasePeriodFromResolution (resolution): number {
        const r = resolution; // resolution.toUpperCase();
        if (isNaN(r)) {
            if (r.indexOf('D') !== -1) { return Periods.DAY; }
            if (r.indexOf('W') !== -1) { return Periods.WEEK; }
            if (r.indexOf('M') !== -1) { return Periods.MONTH; }
            if (r.indexOf('Y') !== -1) { return Periods.YEAR; }
            if (r.indexOf('S') !== -1) { return Periods.SECOND; }
            if (r.indexOf('T') !== -1) { return Periods.TIC; }
        }

        return Periods.MIN;
    }
}

export const TvHistoryResolutionHelper = new _TvHistoryResolutionHelper();
